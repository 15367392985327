import { datetimeExtensions } from '../extensions'
import {
  enumType, FORMAT_ONLY_DATE, FORMAT_ONLY_DATE_3S, FORMAT_ONLY_DATE_REVERSE
} from '../constants'
import { hotelUtils } from '../utils'
import moment from 'moment'

export default {
  initBundleListData(data) {
    const {
      startPoint,
      startPointId,
      startAirportCode,
      endPoint,
      endPointId,
      endAirportCode,
      departureDate,
      returnDate,
      roomOccupancy,
      room,
      promotionCode
    } = data

    return {
      // StartPoint: startPoint || '',
      StartLocationId: startPointId || '',
      StartAirportCode: startAirportCode || '',
      // EndPoint: endPoint || '',
      EndLocationId: endPointId || '',
      EndAirportCode: endAirportCode || '',
      DepartureDate: departureDate ? moment(departureDate, FORMAT_ONLY_DATE_3S).format(FORMAT_ONLY_DATE_REVERSE) : '',
      ReturnDate: returnDate ? moment(returnDate, FORMAT_ONLY_DATE_3S).format(FORMAT_ONLY_DATE_REVERSE) : '',
      RoomOccupancy: roomOccupancy || '1_0_0',
      // Room: roomOccupancy.split(',').length || 1,
      Room: room,
      PromotionCode: promotionCode,
    }
  },

  initBundleDetailData(data) {
    const {
      dataSession,
      hotelId,
      roomTypeId,
      ratePlanId,
      departSession,
      returnSession,
      departFareOptionSession,
      returnFareOptionSession,
      roomRates,
      roomOccupancy,
      promotionCode
    } = data
    return {
      DataSession: dataSession || '',
      HotelId: hotelId || '',
      RoomTypeId: roomTypeId || '',
      RatePlanId: ratePlanId || '',
      DepartSession: departSession || '',
      ReturnSession: returnSession || '',
      DepartFareOptionSession: String(departFareOptionSession),
      ReturnFareOptionSession: String(returnFareOptionSession),
      RoomRates: roomRates || '',
      RoomOccupancy: roomOccupancy || '',
      PromotionCode: promotionCode || '',
    }
  },

  getBundleParamsFromData(data) {
    return {
      startPointId: data.startPointId || data.startLocationId,
      startPointName: data.startPointName,
      startAirportCode: data.startAirportCode,
      endPointId: data.endPointId || data.endLocationId,
      endPointName: data.endPointName,
      endAirportCode: data.endAirportCode,
      departureDate: data.departureDate && moment(data.departureDate).format(FORMAT_ONLY_DATE_3S),
      returnDate: data.returnDate && moment(data.returnDate).format(FORMAT_ONLY_DATE_3S),
      adult: data.adult,
      child: data.child || data.children,
      infant: data.infant,
      room: data.room,
      roomOccupancy: data.roomOccupancy,
      promotionCode: data.promotionCode,
    }
  },

  parseBundleParams(params, endPoint) {
    const { numberOfRoom, passenger } = params.roomOccupancy ? hotelUtils.parsePassenger(params.roomOccupancy) : ''
    return {
      ...params,
      startPoint: params.startPointId ? { id: params.startPointId, name: params.startPointName, airportCode: params?.startAirportCode } : undefined,
      endPoint: params.endPointId ? { id: params.endPointId, name: params.endPointName, airportCode: params?.endAirportCode } : (endPoint ? { id: endPoint.id, name: endPoint.label, airportCode: params?.endAirportCode } : undefined),
      departureDate: params.departureDate ? moment(params.departureDate, FORMAT_ONLY_DATE_3S).format(FORMAT_ONLY_DATE) : '',
      returnDate: params.departureDate ? moment(params.returnDate, FORMAT_ONLY_DATE_3S).format(FORMAT_ONLY_DATE) : '',
      passenger: params.roomOccupancy ? passenger : [
        {
          adult: 1,
          child: 0,
          infant: 0
        }
      ],
      numberOfRoom: params.room || numberOfRoom || 1,
      promotionCode: params.promotionCode || '',
    }
  },

}