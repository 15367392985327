import React from 'react'
import { FormattedMessage } from 'react-intl.macro'
import {
  datetimeExtensions,
  numberExtensions,
  objectExtensions
} from '../extensions'
import { enumType, MAX_PASSENGER_QUANTITY, FORMAT_ONLY_DATE, FORMAT_ONLY_DATE_3S } from '../constants'
import { groupBy, omit, cloneDeep } from 'lodash'
import moment from 'moment'

/**
 * init general passenger info
 * @param price
 * @param quantity
 * @param fee
 * @param tax
 * @returns {{quantity: (*|number), price: (*|number), fee: (*|number), tax: (*|number)}}
 */
const initPassengerDetail = (price, quantity, fee, tax) => {
  return {
    price: price || 0,
    quantity: quantity || 0,
    fee: fee || 0,
    tax: tax || 0,
    totalPrice: (price + fee + tax)
  }
}

/**
 * get default birthday
 * @param type
 * @returns {null|*|any}
 */
const getDefaultBirthDay = (type) => {
  // here
  switch (type) {
    case enumType.passenger.Children:
      return datetimeExtensions.formatVnDate(
        datetimeExtensions.initStartDateOfPreviousYear(2)
      )
    case enumType.passenger.Infant:
      return datetimeExtensions.formatVnDate(
        datetimeExtensions.initStartDateOfPreviousYear(0)
      )
    default:
      return null
  }
}


/**
 * init passenger info
 * @param type
 * @param quantity
 * @returns {[]}
 */
const initPassengerInfo = (type, quantity) => {
  let passengers = []
  const birthday = getDefaultBirthDay(type)
  for (let index = 0; index < quantity; index++) {
    passengers.push({
      isEditing: true,
      isViewLess: true,
      type: type,
      index: index,
      gender: enumType.gender.Male,
      firstName: null,
      lastName: null,
      birthday: birthday,
      baggageDeparture: null,
      baggageReturn: null,
      airlineMemberCard: null,
    })
  }

  return passengers
}

const formatBaggageItemState = (baggageItem) => {
  const removeFields = ['label', 'name', 'text']
  return cloneDeep(omit(baggageItem, removeFields))
}

const formatPassengerListState = (listPassengersState = [], keepBaggage = true) => {
  return (listPassengersState || []).map(item => {
    const baggageDeparture = formatBaggageItemState(item.baggageDeparture);
    const baggageReturn = formatBaggageItemState(item.baggageReturn);
    return (Object.assign(item, {
      baggageDeparture: Object.keys(baggageDeparture).length && keepBaggage ? baggageDeparture : undefined,
      baggageReturn: Object.keys(baggageReturn).length && keepBaggage ? baggageReturn : undefined,
    }))
  })
}

export default {
  /**
   * get list passenger
   * @param birthday
   * @param type
   * @param props
   * @returns {boolean}
   */
  checkAgeSuccessFlight(birthday, type, currentFlight = {}) {
    if (birthday) {
      const currFlight = currentFlight;
      const getDate = currFlight && currFlight.departureDate;
      // const compareCurrentDate = moment(new Date(), FORMAT_ONLY_DATE)
      const compareDate = getDate ? moment(getDate, FORMAT_ONLY_DATE) : moment();
      const diffYear = Math.abs(moment(birthday, FORMAT_ONLY_DATE).diff(compareDate, 'years', true));
      switch (type) {
        case enumType.passenger.Infant:
          return diffYear < 2
        case enumType.passenger.Children:
          return 2 <= diffYear && diffYear < 12
        default:
          return true
      }
    }
    return true
  },

  /**
   * get list passenger
   * @param fare
   * @param passenger
   * @returns {[]}
   */
  getListPassenger(fare, passenger) {
    const {
      priceAdult,
      priceChild,
      priceInfant,
      feeAdult,
      feeChild,
      feeInfant,
      taxAdult,
      taxChild,
      taxInfant,
      serviceFee,
      issueFee,
      vptServiceFee
    } = fare

    let listPassenger = []

    const {
      adult, child, infant
    } = passenger

    if (adult > 0) {
      listPassenger.push(
        {
          ...objectExtensions.getObjectFromEnum(enumType.passenger.Adult,
            enumType.passengerType),
          ...initPassengerDetail(priceAdult, adult, feeAdult, taxAdult)
        }
      )
    }

    if (child > 0) {
      listPassenger.push(
        {
          ...objectExtensions.getObjectFromEnum(enumType.passenger.Children,
            enumType.passengerType),
          ...initPassengerDetail(priceChild, child, feeChild, taxChild)
        }
      )
    }

    if (infant > 0) {
      listPassenger.push(
        {
          ...objectExtensions.getObjectFromEnum(enumType.passenger.Infant,
            enumType.passengerType),
          ...initPassengerDetail(priceInfant, infant, feeInfant, taxInfant)
        }
      )
    }
    const totalFee = Number(fare.issueFee) + Number(fare.serviceFee) + Number(fare.vptServiceFee)
    if (totalFee > 0) {
      listPassenger.push({
        label: <FormattedMessage
          id="flight.Page.OtherFees"
          defaultMessage='Phí khác'
        />,
        quantity: Number(adult) + Number(child),
        totalPrice: totalFee
      })
    }

    return listPassenger
  },

  /**
   *
   * @param passenger
   * @returns {unknown[]}
   */
  getListPassengerInfo(passenger = {}) {
    let listPassenger = []
    const {
      adult,
      children,
      infant
    } = passenger
    if (adult > 0) {
      listPassenger.push(
        <FormattedMessage
          id="Label.Passenger.NumberAdult"
          defaultMessage={`{count} người lớn`}
          values={{
            count: adult
          }}
        />
      )
    }
    if (children > 0) {
      listPassenger.push(
        <FormattedMessage
          id="Label.Passenger.NumberChild"
          defaultMessage={`{count} trẻ em`}
          values={{
            count: children
          }}
        />
      )
    }
    if (infant > 0) {
      listPassenger.push(
        <FormattedMessage
          id="Label.Passenger.NumberInfant"
          defaultMessage={`{count} em bé`}
          values={{
            count: infant
          }}
        />
      )
    }
    if (listPassenger.length === 0) {
      listPassenger = [
        <FormattedMessage
          id="Label.Passenger.NumberAdult"
          defaultMessage={`{count} adult`}
          values={{
            count: 1
          }}
        />
      ]
    }
    return listPassenger.map(
      (passenger, index) => (
        <span
          key={index}
          className='btn-list-passenger'
        >
          {passenger}
        </span>
      )
    )
  },

  getTaxesAndFees(fare, adult, children, infant) {
    const totalTax = (fare.taxAdult * adult) + (fare.taxChild * children) + (fare.taxInfant * infant)
    const totalFee = (fare.feeAdult * adult) + (fare.feeChild * children) + (fare.feeInfant * infant)
    const totalIssueFee = fare.issueFee * (adult + children + infant)
    const totalServiceFee = fare.serviceFee * (adult + children + infant)
    const vptFee = fare.vptServiceFee * (adult + children); // except vpt fee for infant only
    return totalTax + totalFee + totalIssueFee + totalServiceFee + vptFee
  },

  formatPassengerListState,
  formatBaggageItemState,
  /**
   * get list passenger: use in form book flight
   * @param adult
   * @param child
   * @param infant
   * @returns {[]}
   */
  getListPassengerForm(adult, child, infant, listPassengersState = [], keepBaggage = true) {
    let listPassengers = []
    listPassengersState = formatPassengerListState(listPassengersState || [], keepBaggage);
    const groupPassengerState = listPassengersState.length ? groupBy(listPassengersState, 'type') : {};
    const getPassengersInfo = (type, quantity, array = listPassengers) => {
      if (quantity > 0) {
        if (groupPassengerState[type] && quantity === groupPassengerState[type].length) {
          return array.concat(groupPassengerState[type].map(item => Object.assign(item, { isEditing: true, isViewLess: true })))
        } else {
          return array.concat(initPassengerInfo(type, quantity))
        }
      }
      return array
    }

    if (adult > 0) {
      listPassengers = getPassengersInfo(enumType.passenger.Adult, adult)
    }

    if (child > 0) {
      listPassengers = getPassengersInfo(enumType.passenger.Children, child)
    }

    if (infant > 0) {
      listPassengers = getPassengersInfo(enumType.passenger.Infant, infant)
    }

    return listPassengers
  },

  /**
   * check valid params in flight page
   * @param params
   * @returns {boolean}
   */
  checkInvalidParams(params) {
    const listValidParams = [
      'startPoint',
      'endPoint',
      'departureDate',
      'returnDate',
      'adult',
      'children',
      'infant']

    return listValidParams.every(props => Object.keys(params).includes(props))
  },

  checkChangeParams(currentParams, futureParams) {
    const listParams = [
      'startPoint',
      'endPoint',
      'adult',
      'children',
      'infant',
    ]

    const newParams = {
      ...futureParams,
      children: futureParams.passenger.child,
      adult: futureParams.passenger.adult,
      infant: futureParams.passenger.infant
    }

    return listParams.some(
      props => currentParams[props] !== newParams[props])
  },

  /**
   * get max passenger quantity
   * @param type
   * @param passenger
   * @returns {number}
   */
  getMaxPassengerQuantity(type, passenger) {
    switch (type) {
      case enumType.passenger.Adult:
        return MAX_PASSENGER_QUANTITY - passenger.child
      case enumType.passenger.Children:
        return passenger.adult - 1 > MAX_PASSENGER_QUANTITY - passenger.adult
          ? MAX_PASSENGER_QUANTITY - passenger.adult
          : passenger.adult - 1
      case enumType.passenger.Infant:
        return passenger.adult - 1
      default:
        return MAX_PASSENGER_QUANTITY
    }
  },

  /**
   * get min passenger quantity
   * @param type
   * @returns {number}
   */
  getMinPassengerQuantity(type) {
    switch (type) {
      case enumType.passenger.Adult:
        return 1
      case enumType.passenger.Children:
      case enumType.passenger.Infant:
        return 0
      default:
        return 0
    }
  },

  getButtonPassengerContent(passenger) {
    let listPassengers = []

    const {
      adult,
      child,
      infant
    } = passenger

    if (adult > 0) {
      listPassengers.push(
        <FormattedMessage
          id="Label.Passenger.NumberAdult"
          defaultMessage={`{count} người lớn`}
          values={{
            count: adult
          }}
        />
      )
    }
    if (child > 0) {
      listPassengers.push(
        <FormattedMessage
          id="Label.Passenger.NumberChild"
          defaultMessage={`{count} trẻ em`}
          values={{
            count: child
          }}
        />
      )
    }

    if (infant > 0) {
      listPassengers.push(
        <FormattedMessage
          id="Label.Passenger.NumberInfant"
          defaultMessage={`{count} em bé`}
          values={{
            count: infant
          }}
        />
      )
    }

    if (listPassengers.length === 0) {
      listPassengers = [
        <FormattedMessage
          id="Label.Passenger.NumberAdult"
          defaultMessage={`{count} adult`}
          values={{
            count: 1
          }}
        />
      ]
    }

    return listPassengers.map(
      (passenger, index) => (
        <span
          key={index}
          className='btn-list-passenger'
        >
          {passenger}
        </span>
      )
    )
  },

  /**
   * adult + child + infant <= 7
   * @param adult
   * @param child
   * @param infant
   * @returns {boolean}
   */
  checkTotalPassenger(adult, child, infant) {
    return adult + child + infant <= MAX_PASSENGER_QUANTITY
  },

  /**
   * child + infant <= 2 * adult
   * @param adult
   * @param child
   * @param infant
   * @returns {boolean}
   */
  checkTotalChildAndInfant(adult, child, infant) {
    return child + infant <= 2 * adult
  },

  /**
   * infant <= adult
   * @param adult
   * @param infant
   * @returns {boolean}
   */
  checkTotalInfant(adult, infant) {
    return infant <= adult
  },

  /**
   * check adult condition
   * @param adult
   * @param child
   * @param infant
   * @returns {*|boolean}
   */
  checkAdultPassenger(adult, child, infant) {
    return this.checkTotalPassenger(adult, child, infant) &&
      this.checkTotalChildAndInfant(adult, child, infant) &&
      this.checkTotalInfant(adult, infant)
  },

  /**
   * check adult condition
   * @param groupClass
   * @param class
   * @returns {string}
   */

  formatClassFlight(groupClass, childClass) {
    const textClass = childClass ? `(${childClass})` : '';
    return `${groupClass} ${textClass}`
  },

  /**
   * check children condition
   * @param adult
   * @param child
   * @param infant
   * @returns {*|boolean}
   */
  checkChildrenPassenger(adult, child, infant) {
    return this.checkTotalPassenger(adult, child, infant) &&
      this.checkTotalChildAndInfant(adult, child, infant)
  },

  /**
   * check infant condition
   * @param adult
   * @param child
   * @param infant
   * @returns {*|boolean}
   */
  checkInfantPassenger(adult, child, infant) {
    return this.checkTotalPassenger(adult, child, infant) &&
      this.checkTotalChildAndInfant(adult, child, infant) &&
      this.checkTotalInfant(adult, infant)
  },

  /**
   * disable button quantity
   * @param type
   * @param passenger
   * @returns {{disableMinus: *, disablePlus: *}}
   */
  checkDisableQuantityButton(type, passenger) {
    let disableMinus = false
    let disablePlus = false
    const { adult, child, infant } = passenger
    switch (type) {
      case enumType.passenger.Adult:
        disableMinus = passenger.adult <= 1
        disablePlus = !this.checkAdultPassenger(adult + 1, child, infant)
        break
      case enumType.passenger.Children:
        disableMinus = passenger.child <= 0
        disablePlus = !this.checkChildrenPassenger(adult, child + 1, infant)
        break
      case enumType.passenger.Infant:
        disableMinus = passenger.infant <= 0
        disablePlus = !this.checkInfantPassenger(adult, child, infant + 1)
        break
      default:
        break
    }
    return {
      disableMinus,
      disablePlus
    }
  },

  /**
   * get quantity
   * @param type
   * @param passenger
   * @param isPlus
   * @returns {{adult: *, infant: *, child: *}}
   */
  getQuantityPassenger(type, passenger, isPlus) {
    let adult = Number(passenger.adult) || 1
    let child = Number(passenger.child) || 0
    let infant = Number(passenger.infant) || 0
    let quantity = 0

    switch (type) {
      case enumType.passenger.Adult:
        quantity = numberExtensions.updateQuantity(adult, isPlus)
        quantity = quantity > 1 ? quantity : 1
        if (this.checkAdultPassenger(quantity, child, infant)) {
          adult = quantity
        }
        break
      case enumType.passenger.Children:
        quantity = numberExtensions.updateQuantity(child, isPlus)
        if (this.checkChildrenPassenger(adult, quantity, infant)) {
          child = quantity
        }
        break
      case enumType.passenger.Infant:
        quantity = numberExtensions.updateQuantity(infant, isPlus)
        if (this.checkInfantPassenger(adult, child, quantity)) {
          infant = quantity
        }
        break
      default:
        break
    }
    return {
      adult,
      child,
      infant
    }
  },
  /**
   * disable button quantity
   * @param type
   * @param passenger
   * @returns {{plus: *, minus: *}}
   */
  getPassengerValidateMessage(type, passenger) {
    const { adult, child, infant } = passenger;
    const message = {};
    if ((adult + child + infant) >= MAX_PASSENGER_QUANTITY) {
      message.plus = <FormattedMessage
        id="Enum.Passenger.Quatity.Max"
        defaultMessage='Tổng số hành khách tối đa là {max}'
        values={{ max: MAX_PASSENGER_QUANTITY }}
      />
    }
    switch (type) {
      case enumType.passenger.Adult:
        if (passenger.adult <= 1) {
          message.minus = <FormattedMessage
            id="Enum.Passenger.Adult.Min"
            defaultMessage='Số hành khách tối thiểu là {min}'
            values={{ min: 1 }}
          />
        }
        break
      case enumType.passenger.Children:
        break
      case enumType.passenger.Infant:
        if (infant >= adult) {
          message.plus = <FormattedMessage
            id="Enum.Passenger.Infant.Max"
            defaultMessage='Tổng số trẻ sơ sinh không được vượt quá số người lớn'
          />
        }
        break
      default:
        break
    }
    return message
  },

  /**
   * get total baggage price
   * @param passengers
   * @returns {{baggageDeparture: *, baggageReturn: *}}
   */
  getBaggageTotal(passengers) {
    let baggageDeparture = 0
    let baggageReturn = 0

    if (passengers && passengers.length > 0) {
      passengers.forEach(passenger => {
        if (passenger.baggageDeparture) {
          baggageDeparture += (passenger.baggageDeparture.price || 0)
        }
        if (passenger.baggageReturn) {
          baggageReturn += (passenger.baggageReturn.price || 0)

        }
      })
    }
    return {
      baggageDeparture: baggageDeparture,
      baggageReturn: baggageReturn
    }
  },
  isInCombo(path = window.location.pathname) {
    const regex = /.*(combo).*/gs
    return regex.test(path)
  },

  getFlightFee({
    fare = {},
    adult,
    children,
    infant
  }) {
    const totalPrice = (fare.priceAdult || 0) * adult + (fare.priceChild || 0) * children + (fare.priceInfant || 0) * infant
    if (0 < totalPrice && totalPrice < 1000) {
      totalPrice = 1000;
    }
    const totalTax = (fare.taxAdult || 0) * adult + (fare.taxChild || 0) * children +
      (fare.taxInfant || 0) * infant
    const totalFee = (fare.feeAdult || 0) * adult + (fare.feeChild || 0) * children +
      (fare.feeInfant || 0) * infant
    const totalIssueFee = (fare.issueFee || 0) * (adult + children)
    const totalServiceFee = (fare.serviceFee || 0) * (adult + children)
    const totalVptServiceFee = fare.vptServiceFee || 0
    return {
      totalPrice,
      totalTax,
      totalFee,
      totalIssueFee,
      totalServiceFee,
      totalVptServiceFee
    }
  },

  parseSearchParams: (params = {}) => ({
    ...params,
    adult: Number(params.adult || 1),
    child: Number(params.children || 0),
    infant: Number(params.infant || 0),
    startPoint: params.startPoint || undefined,
    endPoint: params.endPoint || undefined,
    departureDate: params.departureDate ? moment(params.departureDate, FORMAT_ONLY_DATE_3S).format(FORMAT_ONLY_DATE) : '',
    returnDate: params.returnDate ? moment(params.returnDate, FORMAT_ONLY_DATE_3S).format(FORMAT_ONLY_DATE) : '',
    itineraryType: params.returnDate ? enumType.itineraryType.Return : enumType.itineraryType.OneWay,
    clientVia: params.clientVia || '',
    flightFilter: params.flightFilter || '',
    searchMonth: params.searchMonth
  }),
}