import React, { useEffect, useState } from 'react'
import { withRouter } from '@utils/router'
import { nav } from '../../constants'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Menu } from 'antd';
import { useGlobalStore } from '../../hooks/useGlobal'
import { headerMenu } from '../../constants/nav'
import "./Menu.scss"
import LoginContainer from './LoginContainer';
import Currency from './Currency';
import Language from './Language';
import { useDeviceInfo } from 'src/hooks/deviceInfo';
import { selectHotelsByLocation } from 'src/reselect/hotelSelector';
import { useSelector } from 'react-redux';
import { loadHotelsByLocation } from 'src/hooks/hotel';
import { hotelUtils } from '@utils/index';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-use';

const REACT_APP_DISPLAYED_HOTEL_LOCATIONS = process.env.REACT_APP_DISPLAYED_HOTEL_LOCATIONS;
const REACT_APP_DISPLAYED_HOMESTAY_LOCATIONS = process.env.REACT_APP_DISPLAYED_HOMESTAY_LOCATIONS;

const getExternalLink = (menu, mapLinkHref = 'route', isCurrency) => {
  const { state } = useGlobalStore();
  let linkreal = menu.link;
  if (mapLinkHref === 'route') {
    linkreal = `/${state.lang}${isCurrency ? `-${state.currencyOnl}` : ''}${menu[mapLinkHref]}`;
  } else {
    if (menu.externalLink) {
      linkreal = `${menu.externalLink}/${state.lang}${isCurrency ? `-${state.currencyOnl}` : ''}${menu.link}`
    }
  }

  return linkreal;
}

const CustomDropdownMenu = (props) => {
  const { menuName, selectedMenu, menuList, mapLinkHref = 'route', mapLinkTitle = 'title', isCurrency = false } = props
  const menuItems = menuList.map((menu, index) => {
    return (
        <Menu.ItemGroup key={index} title={
            <a href={getExternalLink(menu, mapLinkHref, isCurrency)}>{menu[mapLinkTitle]}</a>
          }>
            {
              menu.children && !!menu.children.length &&
              menu.children.map((item) => (
                <Menu.Item key={item} className="s43-sub-menu" >
                  {
                    mapLinkHref === false ? 
                    <a  href={item.link}>{item[mapLinkTitle]}</a> :
                    <a  href={getExternalLink(item, mapLinkHref, isCurrency)}>{item[mapLinkTitle]}</a>
                  }
                </Menu.Item>
              ))
            }
        </Menu.ItemGroup>
      
    )
  })

  const menuListForMobile = (
    <Menu
      mode='inline'
      selectedKeys={selectedMenu}
      motion={{ enter: null, leave: null }}
      expandIcon={(e) => {
        return e.isOpen ? <img alt='' src={require("@assets/include/svg/s43/fi-rr-angle-small-up.svg").default} /> :
          <img alt='' src={require("@assets/include/svg/s43/fi-rr-angle-small-down.svg").default} />
      }}
    >
      <Menu.SubMenu
        className='custom-inline-menu-dropdown'
        title={menuName}
      >
        {menuItems}
      </Menu.SubMenu>
    </Menu>
  )
  return menuListForMobile;
}

const MainMenu = (props) => {
  const {
    router = {},
    onToggleMenu
  } = props

  const { isMobileMedium } = useDeviceInfo();
  const { pathname, search } = useLocation()

  const [loadHotelLocationCallback] = loadHotelsByLocation()

  const hotelLocations = useSelector(selectHotelsByLocation())

  const [hotelsData, setHotelData] = useState([])
  
  useEffect(() => {
    if (isEmpty(hotelLocations) && !pathname.includes("/staynfun") && !(pathname.includes("/hotel") && !isEmpty(search))){
      loadHotelLocationCallback({ Get_list_hotels: false, LocationIds: REACT_APP_DISPLAYED_HOTEL_LOCATIONS })
    }
  }, [pathname])

  useEffect(() => {
  setHotelData(hotelUtils.formatHotelData(hotelLocations))
  }, [hotelLocations])

  const activeMenu = nav.filter(menu => !menu.disabled)
  const selectedMenu = nav.filter(menu => router?.asPath === menu.route).map(menu => menu.key)

  return (
    <div className="s43-main-header g-font-EBGaramond">
      <div className="nav-container">
        <div className="nav-top">
          <div className='nav-top-item'>
            <div className='nav-top-item-right'>
              {
                isMobileMedium && (
                  <>
                    {<LoginContainer containerSelector=".nav-top-item-right" onToggleMenu={onToggleMenu} />}
                    <span className='s43-header-divider'>/</span>
                    {<Currency containerSelector=".nav-top-item" />}
                    {<Language containerSelector=".nav-top-item-right" />}
                  </>
                )
              }
            </div>

            <img className='close-btn' src={require('@assets/include/svg/s43/fi-rr-cross.svg').default} alt="Close" onClick={onToggleMenu} />
          </div>
        </div>
        <nav className="main-nav">
          <ul className="main-nav-list">
            <li className="nav-item" id='for-dropdown-1'>
              <CustomDropdownMenu
                menuName={<a className="nav-item-link">
                  <FormattedMessage
                    id="Nav.Header.OrderService"
                    defaultMessage='Đặt dịch vụ'
                  />
                </a>}
                isCurrency={true}
                menuList={activeMenu}
                selectedMenu={selectedMenu}
              />
            </li>
            <li className="nav-item" id='for-dropdown-1'>
              <CustomDropdownMenu
                menuName={<a className="nav-item-link">
                  <FormattedMessage
                    id="Nav.Header.Hotel"
                    defaultMessage='Khách sạn'
                  />
                </a>}
                isCurrency={true}
                menuList={hotelsData}
                selectedMenu={selectedMenu}
                mapLinkHref={false}
                mapLinkTitle='title'
              />
            </li>
            {headerMenu.map((menu, index) => {
              const menuText = <a id={menu.id} className="nav-item-link" href={getExternalLink(menu, 'link')}>{menu.title}</a>
              return menu.children && !!menu.children.length ? (
                <li key={index} className="nav-item">
                  <CustomDropdownMenu
                    menuName={menuText}
                    menuList={menu.children}
                    selectedMenu={selectedMenu}
                    mapLinkHref='link'
                    mapLinkTitle='title'
                  />
                </li>
              ) :
                (
                  <li key={index} className="nav-item">
                    {menuText}
                  </li>
                )
            })}
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default withRouter(injectIntl(MainMenu))