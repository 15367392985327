import React, { useEffect, useState, memo } from "react";
import iconStarFull from "@assets/include/svg/star_full.svg";
import iconStarNone from "@assets/include/svg/star_none.svg";
import iconStarHaft from "@assets/include/svg/star_haft.svg";
import { FormattedMessage } from "react-intl";
import { useDeviceInfo } from "src/hooks/deviceInfo";
import { getDownloadAppCallback } from "src/hooks/footer";
import { useGlobalStore } from "src/hooks/useGlobal";
import { selectDownloadAppBanner } from "src/reselect/bannerSelector";
import { useSelector } from "react-redux";
import { numberExtensions } from "src/extensions";

const DownloadAppBanner = ({ setHasDownloadApp }) => {
    const { isAppleDevice } = useDeviceInfo();
    const { state } = useGlobalStore();
    const loadDownloadAppBanner = getDownloadAppCallback();
    const data = useSelector(selectDownloadAppBanner());

    useEffect(() => {
        loadDownloadAppBanner(state.lang || 'vi');
    }, [state])

    useEffect(() => {
        if(setHasDownloadApp){
            setHasDownloadApp(!!data);
        }
    }, [data])

    const renderStar = (startPoint) => {
        let startLevel = [1, 2, 3, 4, 5];
        return startLevel.map((level) => {
            if (Math.ceil(startPoint) >= level) {
                if (Math.floor(startPoint) === level - 1 && Math.ceil(startPoint) === level) {
                    return <img alt="" src={iconStarHaft} />
                }

                return <img alt="" src={iconStarFull} />
            }

            return <img alt="" src={iconStarNone} />
        });
    }

    const formatStar = (star) => {
        return (star + "").replace('.', ',');
    }

    return (
        <>

            {!!data && (
                <div className="app-banner">
                    <div className="app-left">
                        <div className="app-icon">
                            <img alt="" src={data.icon} />
                        </div>

                        <div className="app-info">
                            <div className="app-title">
                                {data.title}
                            </div>
                            <div className="app-rate">
                                <span className="star-wapper">
                                    {renderStar(data.star)}
                                </span>
                                <span>
                                    {formatStar(data.star)}
                                    {" • "}
                                    <FormattedMessage
                                        id="Experience.Search.TicketInfo.Review"
                                        defaultMessage="{reviewCount} đánh giá"
                                        values={{ reviewCount: numberExtensions.formatNumberWithDot(data.review) }}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>

                    <a
                        href={isAppleDevice ? data.ios_link : data.android_link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div className="btn-install">
                            <FormattedMessage
                                id="HomePage.Banner.Install"
                                defaultMessage="Cài đặt"
                            />
                        </div>
                    </a>
                </div>
            )}
        </>
    )
}

export default memo(DownloadAppBanner);