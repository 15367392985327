import React from 'react';
import {
  axiosProviderAuth,
  axiosProviderLogin,
  axiosProviderPromotion,
  axiosProviderVin3SDomain
} from "./api/axiosProvider";

import {
  startFetchingAction,
  stopFetchingAction,
  startFetchingAuthAction,
  stopFetchingAuthAction,
  loadFail,
  loadSuccess,
  startFetchingAuthLoginVptAction,
  stopFetchingAuthLoginVptAction,
  startFetchingRegister,
  stopFetchingRegister,
  startFetchingForgotPwd,
  stopFetchingForgotPwd,
  startFetchingSendOTP,
  stopFetchingSendOTP,
  startFetchingCheckUser,
  stopFetchingCheckUser,
  startFetchingLinkAccount,
  stopFetchingLinkAccount,
  stopFetchingRemoveOldPhone,
  startFetchingRemoveOldPhone,
  startFetchingUpdateUser,
  stopFetchingUpdateUser,
  startFetchingUploadAvata,
  stopFetchingUploadAvata,
} from "./commonAction";
import { savePathRedirect } from "./bundleAction";
import actionTypes from "../actionTypes";
import { getLogoutAuth0 } from "../extensions/querystring";

import { enumType, LOCAL_STORAGE_KEY, routes } from "../constants";

import {
  setCookiesLogin,
  setCookieProfile,
  removeCookiesLogout,
  getAuthCookies
} from "../utils/auth";
import { insUtils } from "../utils";
import { getAuthorizeSSOData, getLogin } from "../services/AuthService";
import config, { getConfig } from "../config";
import { message } from "antd";
import moment from "moment";
import Urls from "src/api/Urls";
import { LocalStorage } from "@utils/";
import { modalUtils } from "./../utils";
import { get } from "lodash";
import gtmAuth from "src/hooks/gtm/gtmAuth";
import { gtmEvent, gtmType } from "src/helpers/gtmEvent";
import { CheckCircleOutlined } from "@ant-design/icons";
import Router from '@utils/router'
import { convertLinkLocale } from '@utils/common';
import { InsiderModel } from 'src/model/insiderModel/InsiderUser.model';
import { setAcceptCookie } from '@utils/cookie';

const REACT_APP_API_BASE_VIN_3S_URL = process.env.REACT_APP_API_BASE_VIN_3S_URL;
const REACT_APP_API_BASE_VINWONDERS_URL = process.env.REACT_APP_API_BASE_VINWONDERS_URL;

// import mockUserProfile from "src/mock/userProfile.json";

const {
  publicRuntimeConfig: { HOME_PAGE }
} = getConfig();

export const errorActions = (errors, patch, dispatch) => {
  const callbacks = [
    removeCookiesLogout(),
    loadSuccess(actionTypes.USER_LOGOUT_SUCCESS, {})
  ];
  dispatch(loadFail(errors.response, patch, callbacks));
};

/**
 *
 // * @param username
 // * @param password
 // * @param googleCaptchaResponse
 * @returns {Function}
 * @param accessToken
 */
export const login = (accessToken, refreshPage = true) => {
  // export const login = (username, password, googleCaptchaResponse, refreshPage = true) => {
  return async dispatch => {
    let returnValue = undefined;
    dispatch(startFetchingAction());
    try {
      const response = await axiosProviderAuth.post(
        `/frontend/externallogin/authzero`,
        // `/frontend/useraccount/login`,
        { accessToken }
        // {
        //   username: username,
        //   password: password,
        //   googleCaptchaResponse: googleCaptchaResponse
        // }
      );
      if (response.status === 200 && !response.errors) {
        // dispatch(
        //   loadSuccess(
        //     actionTypes.USER_LOGIN_SUCCESS,
        //     response.data,
        //     null,
        //     refreshPage ? '/' : null, // refreshPage -> true -> redirect
        //     [
        //       setCookiesLogin(response.data),
        //       getProfile(response.data.accessToken)
        //     ]
        //   )
        // )
        // setCookiesLogin(response.data.data)
        // dispatch(loginSuccess(response.data.data, response.data.data.accessToken, refreshPage))
        returnValue = response.data.data;
      } else {
        dispatch(loadFail(response.errors[0].message, "/"));
      }
    } catch (errors) {
      returnValue = "FAIL";
      errorActions(errors, null, dispatch);
    }
    dispatch(stopFetchingAction());
    return returnValue;
  };
};

export const loginFaceBook = (tokenId, refreshPage = true) => {
  return async dispatch => {
    let returnValue = undefined;
    dispatch(startFetchingAction());
    try {
      const tokenBlob = new Blob(
        [JSON.stringify({ accessToken: tokenId }, null, 2)],
        { type: "application/json" }
      );
      const response = await axiosProviderAuth.post(
        `/frontend/externallogin/facebook`,
        tokenBlob
      );
      if (response.status === 200 && !response.errors) {
        // dispatch(
        //   loadSuccess(
        //     actionTypes.USER_LOGIN_SUCCESS,
        //     response.data.data,
        //     null,
        //     refreshPage ? '/' : null, // redirect to homepage
        //     [
        //       setCookiesLogin(response.data.data),
        //       getProfile(response.data.data.accessToken)
        //     ]
        //   )
        // )
        setCookiesLogin(response.data.data);
        dispatch(
          loginSuccess(
            response.data.data,
            response.data.data.accessToken,
            refreshPage
          )
        );
        returnValue = true;
      } else {
        dispatch(loadFail(response.errors[0].message));
      }
    } catch (errors) {
      errorActions(errors, null, dispatch);
    }
    dispatch(stopFetchingAction());
    return returnValue;
  };
};

export const loginGoogle = (tokenId, refreshPage = true) => {
  return async (dispatch, getState) => {
    let returnValue = undefined;
    dispatch(startFetchingAction());
    try {
      const tokenBlob = new Blob(
        [JSON.stringify({ accessToken: tokenId }, null, 2)],
        { type: "application/json" }
      );
      const response = await axiosProviderAuth.post(
        `/frontend/externallogin/google`,
        tokenBlob
      );

      if (response.status === 200 && !response.errors) {
        // dispatch(
        //   loadSuccess(
        //     actionTypes.USER_LOGIN_SUCCESS,
        //     response.data.data,
        //     null,
        //     refreshPage ? '/' : null, // redirect to homepage
        //     [
        //       setCookiesLogin(response.data.data),
        //       getProfile(response.data.data.accessToken)
        //     ]
        //   )
        // )
        setCookiesLogin(response.data.data);
        dispatch(
          loginSuccess(
            response.data.data,
            response.data.data.accessToken,
            refreshPage
          )
        );
        returnValue = true;
      } else {
        dispatch(loadFail(response.errors[0].message));
      }
    } catch (e) {
      errorActions(e, null, dispatch);
    }
    dispatch(stopFetchingAction());
    return returnValue;
  };
};

export const logout = (
  accessToken,
  withoutAuth0 = false,
  refreshPage = true
) => {
  return async (dispatch, getState) => {
    LocalStorage.remove(LOCAL_STORAGE_KEY.SAVE_PASSWORD);
    sessionStorage.setItem(LOCAL_STORAGE_KEY.API_SUPPORT_PROMOTION_CODE, "")
    sessionStorage.setItem(LOCAL_STORAGE_KEY.API_SUPPORT_PROMOTION_CODE_HOMESTAY, "")
    const { combo = {} } = getState();
    const pathRedirect = withoutAuth0
      ? null
      : typeof refreshPage === "string"
      ? refreshPage
      : refreshPage
      ? combo && combo.pathRedirect
      : null; // redirect to homepage
    dispatch(savePathRedirect(pathRedirect));
    if (!withoutAuth0) dispatch(startFetchingAction());
    try {
      const response = await axiosProviderAuth.get(
        `/frontend/useraccount/logout`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (response.status === 204) {
        const callBackUrl = window?.location?.search || null;
        const callbacks = [removeCookiesLogout()];
        dispatch(
          loadSuccess(
            actionTypes.USER_LOGOUT_SUCCESS,
            {},
            null,
            callBackUrl,
            callbacks
          )
        );
        // window.location.href = routes.LOGOUT
      } else {
        dispatch(loadFail(response.errors[0].message));
      }
    } catch (errors) {
      errorActions(errors, pathRedirect, dispatch);
    }
    if (!withoutAuth0) dispatch(stopFetchingAction());
    // if (!withoutAuth0) window.location = getLogoutAuth0(pathRedirect);
    const language = LocalStorage.get(LOCAL_STORAGE_KEY.LANGUAGE_WESBITE)
    const currency = LocalStorage.get(LOCAL_STORAGE_KEY.CURRENCY_WESBITE)
    const paramsRedirectGeneral = '/login-vpt'
    if (!withoutAuth0) Router.push(convertLinkLocale(language.data, paramsRedirectGeneral, currency.data), undefined, { shallow: false })
  };
};

const loginSuccess = (resData, accessToken, refreshPage) => {
  return async (dispatch, getState) => {
    const { combo = {} } = getState();
    const pathRedirect =
      typeof refreshPage === "string"
        ? refreshPage
        : refreshPage
        ? combo && combo.pathRedirect
        : null; // redirect to homepage
    dispatch(
      loadSuccess(actionTypes.USER_LOGIN_SUCCESS, resData, null, pathRedirect, [
        setCookiesLogin(resData),
        getProfile(accessToken, true, true)
      ])
    );
  };
};

/**
 * register
 * @param email
 * @param phoneNumber
 * @param password
 * @param firstName
 * @param lastName
 * @returns {Function}
 */
export const register = (email, phoneNumber, password, firstName, lastName) => {
  return async dispatch => {
    dispatch(startFetchingAction());
    try {
      const response = await axiosProviderAuth.post(
        `/frontend/useraccount/register`,
        {
          email: email,
          phoneNumber: phoneNumber,
          password: password,
          firstname: firstName,
          lastname: lastName,
          avatar: ""
        }
      );
      if (response.status === 201) {
        dispatch(
          loadSuccess(
            actionTypes.USER_REGISTER_SUCCESS,
            {},
            null,
            "/register-complete"
          )
        );
      } else {
        dispatch(loadFail(response.errors[0].message));
      }
    } catch (errors) {
      errorActions(errors, null, dispatch);
    }
    dispatch(stopFetchingAction());
  };
};

/**
 * confirm email
 * @param userId
 * @param activationCode
 * @returns {Function}
 */
export const confirmEmail = (userId, activationCode) => {
  return async dispatch => {
    dispatch(startFetchingAction());
    try {
      const response = await axiosProviderAuth.get(
        `/frontend/useraccount/confirmemail`,
        {
          params: {
            userId: userId,
            activationCode: activationCode
          }
        }
      );
      if (response.status === 200 && !response.errors) {
      } else {
        dispatch(loadFail(response.errors[0].message));
      }
    } catch (errors) {
      errorActions(errors, "/", dispatch);
    }
    dispatch(stopFetchingAction());
  };
};

/**
 * Reset password action
 * @param userId
 * @param password
 * @param token
 * @returns {Function}
 */
export const resetPassword = (userId, password, token) => {
  return async dispatch => {
    dispatch(startFetchingAction());
    try {
      const response = await axiosProviderAuth.post(
        `/frontend/useraccount/resetpassword`,
        {
          userId: userId,
          password: password,
          token: token
        }
      );
      if (response.status === 204 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.USER_CHANGE_PASSWORD_SUCCESS,
            {},
            null,
            "/reset-password-complete"
          )
        );
      } else {
        dispatch(loadFail(response.errors[0].message));
      }
    } catch (errors) {
      errorActions(errors, "/", dispatch);
    }
    dispatch(stopFetchingAction());
  };
};

export const updatePassword = (oldPassword, newPassword, token) => {
  return async dispatch => {
    dispatch(startFetchingAuthAction());
    try {
      const response = await axiosProviderAuth.post(
        `/frontend/useraccount/updatepassword`,
        {
          oldPassword: oldPassword,
          newPassword: newPassword
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.status === 204 && !response.errors) {
        const callback = [removeCookiesLogout()];
        dispatch(
          loadSuccess(
            actionTypes.USER_CHANGE_PASSWORD_SUCCESS,
            {},
            null,
            "/update-password-complete",
            callback
          )
        );
      } else {
        dispatch(loadFail(response.errors[0].message));
      }
    } catch (errors) {
      errorActions(errors, "/", dispatch);
    }
    dispatch(stopFetchingAuthAction());
  };
};

/**
 * Refresh token action
 * @param refreshToken
 * @returns {Function}
 */
export const refresh = refreshToken => {
  return async dispatch => {
    dispatch(startFetchingAction());
    try {
      // console.log('run refresh.........')
      const response = await axiosProviderAuth.post(
        `/frontend/useraccount/refresh`,
        {
          refreshToken: refreshToken
        }
      );
      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.USER_REFRESH_SUCCESS,
            response.data,
            null,
            null,
            [
              setCookiesLogin(response.data),
              getProfile(response.data.accessToken)
            ]
          )
        );
      } else {
        dispatch(loadFail(response.errors[0].message));
      }
    } catch (errors) {
      errorActions(errors, "/", dispatch);
    }
    dispatch(stopFetchingAction());
  };
};

/**
 *
 * @param {*} accessToken
 */
export const getProfile = (accessToken, pushIns = false, isSignin = false, isRemember = false, callBackUrl = null) => {
  return async dispatch => {
    try {
      const {auth} = getAuthCookies();
      const response = await axiosProviderAuth.get(
        `/frontend/useraccount/profilev2?isGetCdp=true`,
        {
          headers: {
            Authorization: `Bearer ${accessToken || auth.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        sessionStorage.removeItem('prevent_autofill');
        let profile = response.data.data;

        // profile = mockUserProfile.data;

        // mapping new model to old model
        if(profile.firstName){
          profile.firstname = profile.firstName;
        }

        if(profile.lastName){
          profile.lastname = profile.lastName;
        }
        // end - mapping new model to old model

        if (isSignin) {
          await gtmAuth({ dataUser: profile, type: gtmType.LOGIN });
        }
        if (pushIns) {
          const insiderData = {...response.data.data.userInfoCdp};

          let benefitResponse = await axiosProviderPromotion.get(
            `/promotion/my-promotions`,
            {
              headers: {
                Authorization: `Bearer ${accessToken || auth.accessToken}`
              }
            }
          )

          insiderData.benefit = benefitResponse?.data?.data || [];
          insiderData.memberShipCode = profile.memberShipCode
          insiderData.memberShipCard = profile.memberShipCard
          insiderData.propertyIds = profile.propertyIds

          window.insider_object = window.insider_object || {};
          window.insider_object.user = new InsiderModel(insiderData).exportJson();

          // insUtils.pushUserInsObject(profile);
        }
        dispatch(
          loadSuccess(actionTypes.USER_GET_PROFILE_SUCCESS, profile, null, null, [
            setCookieProfile(profile, isRemember)
          ])
        );
      } else {
        dispatch(loadFail(response.errors[0].message));
      }
    } catch (error) {
      if (error.message == 'Network Error' && accessToken) {
        throw error;
      } else {
        errorActions(error, '/', dispatch);
      }
    } finally {
      if (callBackUrl) {
        if(callBackUrl.indexOf("/") === -1) {
          callBackUrl = "/" + callBackUrl;
        }
        window.location = callBackUrl
      }
    }
  };
};

/**
 *
 * @param firstName
 * @param lastName
 * @param phone
 * @param avatar
 * @param accessToken
 * @returns {Function}
 */
// const updateProfile = (firstName, lastName, phoneNumber, avatar, accessToken) => {
//   return async dispatch => {
//     dispatch(startFetchingAction())
//     try {
//       const response = await axiosProviderAuth.put(
//         `/frontend/useraccount/updateprofile`,
//         {
//           firstname: firstName,
//           lastname: lastName,
//           phoneNumber,
//           avatar: avatar
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${accessToken}`
//           }
//         }
//       )
//       if (response.status === 204) {
//         dispatch(loadSuccess(
//           actionTypes.USER_UPDATE_PROFILE_SUCCESS,
//           {}, null, '/user-profile', [
//           getProfile(accessToken)
//         ]
//         ))
//       }
//     } catch (errors) {
//       errorActions(errors, '/', dispatch)
//     }
//     dispatch(stopFetchingAction())
//   }
// }

const updateProfile = (newProfile, accessToken, msg) => {
  return async dispatch => {
    dispatch(startFetchingUpdateUser());
    dispatch(loadSuccess(actionTypes.UPDATE_USER_SUCCESS, false));
    try {
      const response = await axiosProviderAuth.post(
        Urls.UPDATE_USER,
        newProfile,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        }
      );
      if (response.status === 200 && response.data.isSuccess) {
        message.open({
          icon: null, content: <div className="vw-update-user-profile-success">
            <CheckCircleOutlined className="mr-2 vw-xxlarge" /> {msg || 'Đã cập nhật thông tin'}
          </div>
        });
        dispatch(
          loadSuccess(actionTypes.UPDATE_USER_SUCCESS, true, null, null, [
            getProfile(accessToken, true)
          ])
        );
      } else{
        const error = response.data.errors[0];
        dispatch(loadSuccess(actionTypes.UPDATE_USER_ERROR, error));
      }
    } catch (errors) {
      dispatch(stopFetchingUpdateUser());
    }
    dispatch(stopFetchingUpdateUser());
  };
};

const uploadAvata = (avatarUpload, accessToken) => {
  return async dispatch => {
    dispatch(startFetchingUploadAvata());
    try {
      const avataData = new FormData();
      avataData.append("Avatar", avatarUpload);
      const response = await axiosProviderAuth.post(
        Urls.UPLOAD_AVATA,
        avataData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        }
      );
      if (response.status === 200 && response.data.isSuccess) {
        dispatch(
          loadSuccess(
            actionTypes.UPLOAD_AVATA_SUCCESS,
            response.data.data
          )
        );
      } else{
        const error = response.data.errors[0];
        dispatch(loadSuccess(actionTypes.UPLOAD_AVATA_ERROR, error));
      }
    } catch (errors) {
      dispatch(stopFetchingUploadAvata());
    }
    dispatch(stopFetchingUploadAvata());
  };
};

export const checkAuth = () => {
  return async (dispatch, getState) => {
    const { auth, auth0 } = getAuthCookies();
    if (!!auth && !auth0) {
      // dispatch(logout(auth.accessToken, false, String(window.location)))
      return
    }
    if (!auth && !!auth0) {
      removeCookiesLogout()
      return
    }

    dispatch(startFetchingAuthAction());
    const isLocalLogin = LocalStorage.get(LOCAL_STORAGE_KEY.SAVE_PASSWORD);
    try {
      if (!!auth && isLocalLogin) {
        dispatch(stopFetchingAuthAction());
        return;
      }
      // const codeResponse = await getAuthorizeSSOData({
      //   responseType: config.AUTH0_RESPONSE_TYPE
      // });
      // // console.log('===== checkAuth getAuthorizeSSOData', codeResponse)
      // if (!auth && codeResponse?.code) {
      //   const tokenData = await getLogin({
      //     accessToken: codeResponse?.code,
      //     redirectUri: config.AUTH0_RETURN_URL
      //   });
      //   // console.log('===== checkAuth tokenData', tokenData)
      //   if (tokenData?.accessToken) {
      //     dispatch(loginSuccess(tokenData, tokenData.accessToken, false));
      //   }
      // } else if (
      //   !!auth &&
      //   codeResponse?.error === enumType.apiErrorCode.AUTH0_LOGIN_REQUIRED
      // ) {
      //   // dispatch(logout(auth?.accessToken, true, false))
      // }
    } catch (errors) {
      // console.log('checkAuth Error', errors)
    } finally {
      dispatch(stopFetchingAuthAction());
    }
    dispatch(stopFetchingAuthAction());
  };
};

export const signUpVptFunction = (body = {}) => {
  return async dispatch => {
    let returnValue = undefined;
    dispatch(startFetchingRegister());
    dispatch(loadSuccess(actionTypes.SIGN_UP_VPT, true));
    try {
      const response = await axiosProviderAuth.post(Urls.ACCOUNT_SIGNUP, { ...body });
      if (
        response.status === 200 &&
        !response.errors &&
        response.data.isSuccess
      ) {
        setAcceptCookie();
        dispatch(loadSuccess(actionTypes.SIGN_UP_VPT_SUCCESS, true));
      } else {
        dispatch(loadFail(response.data.errors[0].message));
        const error = response.data.errors[0];
        dispatch(loadSuccess(actionTypes.SIGN_UP_VPT_ERROR, error));
      }
    } catch (errors) {
      const response = errors.response;
      if (response && response.status === 400) {
        dispatch(loadFail(response.data.errors[0].message));
        const dataError = {
          data: { errors: [{ code: response.data.errors[0].message }] }
        };
        const errorModel = modalUtils.getApiMessageError(dataError, true);
        dispatch(loadSuccess(actionTypes.SIGN_UP_VPT_ERROR, errorModel));
      } else {
        returnValue = "FAIL";
        errorActions(errors, null, dispatch);
      }
      dispatch(stopFetchingRegister());
    } finally {
      dispatch(loadSuccess(actionTypes.SIGN_UP_VPT, false));
      dispatch(stopFetchingAuthLoginVptAction());
    }
    dispatch(stopFetchingRegister());
    return returnValue;
  };
};

export const signUpVptFunctionCloseRegisterConfirm = () => {
  return async dispatch => {
    dispatch(loadSuccess(actionTypes.SIGN_UP_VPT_SUCCESS, false))
  }
}

export const changePasswordVptFunction = ({ body = {}, onSuccess }) => {
  return async dispatch => {
    let returnValue = undefined;
    dispatch(loadSuccess(actionTypes.LOGIN_VPT_ERROR, null));
    dispatch(startFetchingAuthLoginVptAction());
    try {
      const response = await axiosProviderLogin.post(
        Urls.CHANGE_PASSWORD_VPT,
        body
      );
      if (
        response.status === 200 &&
        !response.errors &&
        response.data.isSuccess
      ) {
        onSuccess();
      } else {
        dispatch(loadFail(response.data.errors[0].message));
        const dataError = {
          data: { errors: [{ code: response.data.errors[0].message }] }
        };
        const errorModel = modalUtils.getApiMessageError(dataError);
        dispatch(loadSuccess(actionTypes.LOGIN_VPT_ERROR, errorModel));
      }
    } catch (errors) {
      returnValue = "FAIL";
      errorActions(errors, null, dispatch);
      dispatch(stopFetchingAuthLoginVptAction());
      const dataError = {
        data: { errors: [{ code: response.data.errors[0].message }] }
      };
      const errorModel = modalUtils.getApiMessageError(dataError);
      dispatch(loadSuccess(actionTypes.LOGIN_VPT_ERROR, errorModel));
    } finally {
      dispatch(stopFetchingAuthLoginVptAction());
    }
    dispatch(stopFetchingAuthLoginVptAction());
    return returnValue;
  };
};

export const loginModalFunction = ({
  body = {},
  onSuccess,
  isLoginVpt = false,
  callbackUrl = undefined,
  isRemember,
  isReload
}) => {
  return async dispatch => {
    dispatch(startFetchingAuthLoginVptAction());
    dispatch(loginAuth0v2(body, onSuccess, isLoginVpt, callbackUrl, isRemember, isReload));
  };
};

export const loginAuth0v2 = (
  body = {},
  onSuccess,
  isLoginVpt = false,
  callbackUrl = undefined,
  isRemember = false,
  isReload = true,
) => {
  return async dispatch => {
    let returnValue = undefined;
    dispatch(startFetchingAuthAction());
    dispatch(loadSuccess(actionTypes.LOGIN_VPT_ERROR, null));
    try {
      const response = await axiosProviderAuth.post(
        Urls.ACCOUNT_LOGIN_VPT,
        { ...body, redirectUrl: HOME_PAGE }
      );
      if (response.status === 200 && !response.errors) {
        setAcceptCookie();
        dispatch(
          loginModalSuccess(
            response.data.data,
            response.data.data.accessToken,
            true,
            isLoginVpt,
            callbackUrl,
            isRemember,
            isReload,
          )
        );
        onSuccess();
        returnValue = response.data.data;
        sessionStorage.setItem("NEED_TO_RELOAD_CREDIT_DATA", true)
      } else {
        const error = response.data.errors[0];
        dispatch(loadSuccess(actionTypes.LOGIN_VPT_ERROR, error));
      }
    } catch (errors) {
      returnValue = "FAIL";
      // errorActions(errors, null, dispatch);
      dispatch(stopFetchingAuthAction());
      dispatch(stopFetchingAuthLoginVptAction());
      const errorCore = get(errors, "response.data.errors[0].code");
      const dataError = {
        data: { errors: [{ code: errorCore }] }
      };
      const errorModel = modalUtils.getApiMessageError(dataError, false, { email: body?.username || ""});
      dispatch(loadSuccess(actionTypes.LOGIN_VPT_ERROR, errorModel));
    } finally {
      dispatch(stopFetchingAuthAction());
      dispatch(stopFetchingAuthLoginVptAction());
    }
    dispatch(stopFetchingAuthAction());
    dispatch(stopFetchingAuthLoginVptAction());
    return returnValue;
  };
};

export const loginModalSuccess = (
  resData,
  accessToken,
  refreshPage,
  isLoginVpt = false,
  callbackUrl = undefined,
  isRemember,
  isReload = true
) => {
  return async (dispatch, getState) => {
    const { combo = {} } = getState();
    if (callbackUrl) {
      dispatch(
        loadSuccess(actionTypes.USER_LOGIN_SUCCESS, resData, null, null, [
          setCookiesLogin(resData, isRemember),
          getProfile(accessToken, true, true, isRemember, callbackUrl),
          // wait for getprofile success before redirect
          // () => {
          //   window.location = callbackUrl
          // }
        ])
      );
    } else {
      let pathRedirect = null;
      if(isReload) {
        pathRedirect = "modal";

        if (isLoginVpt) {
          pathRedirect =
            typeof refreshPage === "string"
              ? refreshPage
              : refreshPage
              ? combo && combo.pathRedirect
              : "/"; // redirect to homepage
          dispatch(savePathRedirect(pathRedirect));
        }
      }

      dispatch(
        loadSuccess(actionTypes.USER_LOGIN_SUCCESS, resData, null, pathRedirect, [
          setCookiesLogin(resData, isRemember),
          getProfile(accessToken, true, true)
        ])
      );
    }
  };
};

export const forgotPassModalFunction = ({
  body = {},
  sumitting,
  onSuccess
}) => {
  return async dispatch => {
    let returnValue = undefined;
    dispatch(startFetchingAuthAction());
    dispatch(startFetchingAuthLoginVptAction());
    sumitting(true);
    try {
      const response = await axiosProviderLogin.post(
        Urls.FORGOT_PASSWORD_VPT,
        body
      );
      if (response.status === 200) {
        // dispatch(loadFail({
        //   data: {errors: [{code: enumType.apiErrorCode.SEND_EMAIL_RESET_PASSWORD_SUCCESS}]}
        // }));
        onSuccess();
      } else {
        dispatch(
          loadFail({
            data: { errors: [{ code: response.data.errors[0].message }] }
          })
        );
      }
    } catch (errors) {
      errorActions(errors, null, dispatch);
    } finally {
      sumitting(false);
      dispatch(stopFetchingAuthAction());
      dispatch(stopFetchingAuthLoginVptAction());
    }
    dispatch(stopFetchingAuthAction());
    dispatch(stopFetchingAuthLoginVptAction());
    return returnValue;
  };
};

const checkUpgradePearlClub = ({status = false}) => {
  return async dispatch => {
    dispatch(dispatch({
      type: actionTypes.CHECK_PEARL_CLUB,
      status
    }))
  }
}

/**
 * confirm email
 * @param data
 * @returns {Function}
 */
 export const upsertUser = (data) => {
  return async dispatch => {
    dispatch(startFetchingAction());
    try {
      await axiosProviderLogin.post(
        `/mobile-vinpearl/public/account/upsert_user`,
        data
      );
    } catch (errors) {
    } finally {
      if(data.channel === "vinwonders"){
        window.location.href = `${REACT_APP_API_BASE_VINWONDERS_URL}?signup_success=true`;
      }else{
        window.location.href = `${REACT_APP_API_BASE_VIN_3S_URL}?signup_success=true`;
      }
    }
    dispatch(stopFetchingAction());
  };
};
export const resetforgotPassword = () => {
  return dispatch => {
    dispatch(loadSuccess(actionTypes.FORGOT_PASSWORD, false));
    dispatch(loadSuccess(actionTypes.FORGOT_PASSWORD_ERROR, false));
  }
}

export const forgotPassword = (data = {}) => {
  return async dispatch => {
    dispatch(startFetchingForgotPwd());
    dispatch(loadSuccess(actionTypes.FORGOT_PASSWORD, true));
    try {
      const response = await axiosProviderAuth.post(
        Urls.FORGOT_PASSWORD,
        { ...data }
      );
      if (response.status === 200 && response.data.isSuccess) {
        dispatch(
          loadSuccess(
            actionTypes.FORGOT_PASSWORD_SUCCESS,
            response.data.data
          )
        );
      } else {
        const error = response.data.errors[0];
        dispatch(loadSuccess(actionTypes.FORGOT_PASSWORD_ERROR, error));
        dispatch(loadFail(response.data.errors[0].message));
      }
    } catch (errors) {
      dispatch(stopFetchingForgotPwd());
      errorActions(errors, null, dispatch);
    }
    dispatch(stopFetchingForgotPwd());
  };
};

export const resetSendOtp = () => {
  return dispatch => {
    dispatch(loadSuccess(actionTypes.SEND_OTP, false));
    dispatch(loadSuccess(actionTypes.SEND_OTP_ERROR, false));
  }
}

export const sendOTP = (data = {}) => {
  return async dispatch => {
    dispatch(startFetchingSendOTP());
    try {
      const response = await axiosProviderAuth.post(
        Urls.SEND_OTP,
        { ...data }
      );
      if (response.status === 200 && response.data.isSuccess ) {
        dispatch(
          loadSuccess(
            actionTypes.SEND_OTP,
            response.data.data
          )
        )
      } else {
        const error = response.data.errors[0];
        dispatch(loadSuccess(actionTypes.SEND_OTP_ERROR, error));
      }
    } catch (errors) {
      errorActions(errors, null, dispatch);
    }
    dispatch(stopFetchingSendOTP());
  };
};

export const resetCheckUser = () => {
  return dispatch => {
    dispatch(loadSuccess(actionTypes.CHECK_USER_SUCCESS, false));
    dispatch(loadSuccess(actionTypes.CHECK_USER_ERROR, false));
  }
}

export const checkUser = (username) => {
  return async dispatch => {
    dispatch(startFetchingCheckUser());
    dispatch(loadSuccess(actionTypes.CHECK_USER, true));
    try {
      const response = await axiosProviderAuth.post(
        Urls.CHECK_USER,
        {username}
      );
      if (response.status === 200 && response.data.isSuccess === true) {
        dispatch(
          loadSuccess(
            actionTypes.CHECK_USER_SUCCESS,
            response.data.data
          )
        );
        dispatch(loadSuccess(actionTypes.CHECK_USER_ERROR, false));
      } else {
        let error = response.data.errors[0];
        if(error.code = enumType.apiErrorCode.ID_GET_PROFILE_FAIL){
          const dataError = {
            data: { errors: [{ code: error.code }] }
          };
          error = modalUtils.getApiMessageError(dataError); 
        }
        dispatch(loadSuccess(actionTypes.CHECK_USER_SUCCESS, false));
        dispatch(loadSuccess(actionTypes.CHECK_USER_ERROR, error));
        dispatch(stopFetchingCheckUser());
      }
    } catch (errors) {
      errorActions(errors, null, dispatch);
      dispatch(loadSuccess(actionTypes.CHECK_USER_ERROR, errors));
      dispatch(stopFetchingCheckUser());
    }
    dispatch(stopFetchingCheckUser());
  };
};

export const resetLinkAccount = () => {
  return dispatch => {
    dispatch(loadSuccess(actionTypes.LINK_ACCOUNT, false));
    dispatch(loadSuccess(actionTypes.LINK_ACCOUNT_SUCCESS, false));
    dispatch(loadSuccess(actionTypes.LINK_ACCOUNT_ERROR, false));
  }
}
 
export const linkAccount = (data, accessToken) => {
  return async dispatch => {
    dispatch(startFetchingLinkAccount());
    dispatch(loadSuccess(actionTypes.LINK_ACCOUNT, true));
    try {
      const response = await axiosProviderAuth.post(
        Urls.LINK_ACCOUNT,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        }
      );
      if (response.status === 200 && response.data.isSuccess) {
        dispatch(
          loadSuccess(
            actionTypes.LINK_ACCOUNT_SUCCESS,
            response.data.data
          )
        )
      } else {
        const error = response.data.errors[0];
        dispatch(loadSuccess(actionTypes.LINK_ACCOUNT_ERROR, error));
        // dispatch(loadFail(response.data.errors[0].message));
      }
    } catch (errors) {
      const dataError = {
        data: { errors: [{ code: response.data.errors[0].code }] }
      };
      const errorModel = modalUtils.getApiMessageError(dataError);
      dispatch(loadSuccess(actionTypes.LINK_ACCOUNT_ERROR, errorModel));
      dispatch(stopFetchingLinkAccount());
      errorActions(errors, null, dispatch);
    }
    dispatch(stopFetchingLinkAccount());
  };
};

export const removeOldPhone = (email, accessToken) => {
  return async dispatch => {
    dispatch(startFetchingRemoveOldPhone());
    dispatch(loadSuccess(actionTypes.REMOVE_OLD_PHONE, true));
    try {
      const response = await axiosProviderAuth.post(
        Urls.REMOVE_OLD_PHONE,
        email,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        }
      );
      if (response.status === 200 && response.data.isSuccess) {
        dispatch(
          loadSuccess(
            actionTypes.REMOVE_OLD_PHONE_SUCCESS,
            response.data.data, null, null,
            [ getProfile(accessToken) ]
          )
        )
      } else {
        const error = response.data.errors[0];
        dispatch(loadSuccess(actionTypes.REMOVE_OLD_PHONE_ERROR, error));
      }
    } catch (errors) {
      const dataError = {
        data: { errors: [{ code: response.data.errors[0].code }] }
      };
      const errorModel = modalUtils.getApiMessageError(dataError);
      dispatch(loadSuccess(actionTypes.REMOVE_OLD_PHONE_ERROR, errorModel));
      dispatch(stopFetchingRemoveOldPhone());
      errorActions(errors, null, dispatch);
    }
    dispatch(stopFetchingRemoveOldPhone());
  };
};

export default {
  login,
  logout,
  register,
  confirmEmail,
  resetforgotPassword,
  forgotPassword,
  resetPassword,
  updatePassword,
  refresh,
  getProfile,
  updateProfile,
  uploadAvata,
  loginGoogle,
  loginFaceBook,
  loginSuccess,
  checkAuth,
  loginAuth0v2,
  loginModalFunction,
  loginModalSuccess,
  forgotPassModalFunction,
  signUpVptFunction,
  signUpVptFunctionCloseRegisterConfirm,
  changePasswordVptFunction,
  checkUpgradePearlClub,
  upsertUser,
  resetSendOtp,
  resetCheckUser,
  sendOTP,
  checkUser,
  resetLinkAccount,
  linkAccount,
  removeOldPhone,
};
