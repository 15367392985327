import React, { useEffect, useState } from 'react'
//lib
import PropTypes from 'prop-types'
// component
import { Icon } from 'antd'

const Quantity = ({ quantity, min, max, onChange, disabled }) => {

  const [countQuantity, setCountQuantity] = useState(quantity || 0)

  /**
   * update state when props change
   */
  useEffect(
    () => {
      setCountQuantity(Number(quantity))
    },
    [quantity]
  )

  /**
   * change quantity
   * @param isPlus
   */
  const handleChangeCount = (isPlus) => {
    if(disabled) return
    let quantity = isPlus
      ? countQuantity + 1
      : countQuantity - 1

    if ((max || max === 0) && quantity > max) {
      quantity = max
    }

    if ((min || min === 0) && quantity < min) {
      quantity = min
    }

    setCountQuantity(quantity)

    if (onChange) {
      onChange(quantity)
    }
  }

  return (
    <div className="form-group">
      <div className="input-group u-quantity-v1 g-width-125 g-brd-primary--focus">
        <a
          onClick={() => handleChangeCount(false)}
          className="input-group-prepend g-width-30 g-height-30">
          <span className="input-group-text w-100 vpt-brd-v5 rounded-0">
            <Icon
              type="minus"
            />
          </span>
        </a>
        <input
          className="form-control text-center rounded-0  g-py-7 g-px-0 g-font-size-16 font-weight-bold g-height-30 vpt-brd-right-v0"
          type="text"
          value={countQuantity}
          readOnly
        />
        <a
          onClick={() => handleChangeCount(true)}
          className="input-group-append g-width-30 g-height-30">
          <span className="input-group-text w-100 vpt-brd-v0 rounded-0">
            <Icon
              type="plus"/>
          </span>
        </a>
      </div>
    </div>
  )
}

Quantity.propTypes = {
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func
}

export default Quantity