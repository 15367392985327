import actionTypes from '../actionTypes'

const initState = {
  historiesPoint: null,
  vHistoriesPoint: null,
  historiesTransaction: null,
  offer: null,
  benefit: null,
  myBenefit: null,
  register: null,
  faq: null,
  collaborators: null
}

const orderReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.RESET_HISTORY_POINT_SUCCESS:
      return Object.assign({}, state, {
        historiesPoint: null
      })
    case actionTypes.V_RESET_HISTORY_POINT_SUCCESS:
      return Object.assign({}, state, {
        vHistoriesPoint: null
    })
    case actionTypes.RESET_TRANSACTION_HISTORY_SUCCESS:
        return Object.assign({}, state, {
            historiesTransaction: null
        })
    case actionTypes.RESET_OFFER_SUCCESS:
      return Object.assign({}, state, {
          offer: null
      })
    case actionTypes.RESET_BENEFIT_SUCCESS:
      return Object.assign({}, state, {
          benefit: null
      })
    case actionTypes.RESET_MY_BENEFIT_SUCCESS:
      return Object.assign({}, state, {
          myBenefit: null
      })
    case actionTypes.REGISTER_LOYALTY_SUCCESS:
      return Object.assign({}, state, {
        register: null
      })
    case actionTypes.FETCHING_HISTORY_POINT_SUCCESS:
        return Object.assign({}, state, {
            historiesPoint: action.payload.data.data
        })
    case actionTypes.V_FETCHING_HISTORY_POINT_SUCCESS:
      return Object.assign({}, state, {
        vHistoriesPoint: action.payload.data
      })
    case actionTypes.FETCHING_TRANSACTION_HISTORY_SUCCESS:
        return Object.assign({}, state, {
            historiesTransaction: action.payload.data
        })
    case actionTypes.FETCHING_OFFER_SUCCESS:
      return Object.assign({}, state, {
          offer: action.payload.data.data ? action.payload.data.data : []
      })
    case actionTypes.FETCHING_BENEFIT_SUCCESS:
      return Object.assign({}, state, {
          benefit: action.payload.data.data
      })
    case actionTypes.FETCHING_MY_BENEFIT_SUCCESS:
      return Object.assign({}, state, {
          myBenefit: action.payload.data.data
      })
    case actionTypes.FETCHING_REGISTER_LOYALTY_SUCCESS:
      return Object.assign({}, state, {
          register: action.payload.data.data
      })
    case actionTypes.SET_ACCUMULATED_BALANCE:
      return Object.assign({}, state, {
          accumulatedBalance: action.payload.data
      })
    case actionTypes.FETCHING_FAQ_SUCCESS:
      return Object.assign({}, state, {
          faq: action.payload.data
      })
    case actionTypes.FETCHING_COLLABORATORS_SUCCESS:
      return Object.assign({}, state, {
        collaborators: action.payload.data
      })
    default:
      return state
  }
}

export default orderReducer
