import routes from './routes'

const topNav = [
  // {
  //   route: routes.DISCOUNT,
  //   title: 'Khuyến mại',
  //   icon: require('@assets/img/icons/discount.png').default
  // },
  // {
  //   route: routes.MAIL,
  //   title: 'Hộp thư của tôi',
  //   icon: require('@assets/img/icons/mail.png').default
  // },
  {
    route: routes.MY_RESERVE,
    title: 'Đặt chỗ của tôi',
    icon: require('@assets/include/svg/my-reservation.svg').default
  }
]

export default topNav
