import queryString from '@utils/querystring'
import ApiService from './../api/ApiService'
import Urls from './../api/Urls'
import config from './../config'
import { compactObj } from './../helpers/functions'
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, MAX_STAR } from '../constants'
import { numberExtensions, objectExtensions } from '../extensions'
import { initHeaderAxiosWithLanguage } from './index'
import VinWonder from '../model/VinWonder'

export function getVinWonders({ params = {}, context }) {
    params = params || {}
    return new ApiService({
        endpoint: Urls.VOUCHER_LIST,
        baseURL: config.BASE_URL_VOUCHER_API,
        params: compactObj(params),
        headers: initHeaderAxiosWithLanguage({ context: context })
    }).get()
}

export function getVinWonderDetail(slug, context) {
    return new ApiService({
        endpoint: `${Urls.VOUCHER_DETAIL}/${slug}`,
        baseURL: config.BASE_URL_VOUCHER_API,
        headers: initHeaderAxiosWithLanguage({ context: context })
    }).get()
}

export function getLocations(params) {
    return new ApiService({
        endpoint: Urls.LOCATION_LIST_ALL,
        baseURL: config.BASE_URL_VOUCHER_API,
        params: params
    }).get()
}

export function getCountryByIP(params) {
    return new ApiService({
        endpoint: Urls.COUNTRY_BY_IP,
        baseURL: config.BASE_URL_BLOG_API,
        params
    }).get()
}

const parseVinWondersListData = (data) => ({
    ...data,
    result: (data.result || []).map(item => new VinWonder(item))
})

const parseVinWonderDetailData = (data) => new VinWonder(data.data);

export default {
    initQueryVinWonderList(value = {}) {
        const params = {
            SearchTerm: value.keyword || '',
            SiteCode: value.place || '',
            CategoryIds: value.categoryId || [],
            PageSize: value.pageSize || DEFAULT_PAGE_SIZE,
            PageIndex: value.pageIndex || DEFAULT_PAGE_INDEX,
            OrderByDesc: value.orderByDesc || false,
            OrderBy: value.orderBy,
        }

        objectExtensions.deleteEmptyProps(params)
        return queryString.stringify(params)
    },
    parseVinWondersListData,
    parseVinWonderDetailData,
}
