import queryString from '@utils/querystring'
import ApiService from './../api/ApiService'
import Urls from './../api/Urls'
import config from './../config'
import { compactObj } from './../helpers/functions'
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, MAX_STAR } from '../constants'
import { numberExtensions, objectExtensions } from '../extensions'
import {AutocompleteListing, ListingItem, OutstandingProductListing} from "./../model/Listing"
import { getAuthCookies, getClientId } from '@utils/auth'


export function getListing (queryClause) {
  return new ApiService({
    endpoint: `${Urls.LISTING}?${queryClause}`,
    baseURL: config.BASE_URL_VOUCHER_API,
    params: {Channel: 1},
    parser: listParser
  }).get()
}

export function getSpecialAndTopSeller (isSpecial = false) {
  if (isSpecial) {
    return getUserRecommended();
  }
  return new ApiService({
    endpoint: `${Urls.VOUCHER_LIST}/specialandtopseller`,
    baseURL: config.BASE_URL_VOUCHER_API,
    params: {
      IsSpecial: isSpecial
    },
    parser: (data) => (data || []).map(item => new OutstandingProductListing(item))
  }).get()
}


export function getRecommendationTour(body) {
  const clientId = getClientId();
  const authInfo = getAuthCookies();
  let bodyWithClientId = {...body, ClientId: clientId}; 
  let headers;
  if (authInfo && authInfo?.auth && authInfo?.auth?.accessToken) {
      headers = {
          Authorization: `Bearer ${authInfo.auth.accessToken}`,
      }
  }
  return new ApiService({
    endpoint: `${Urls.RECOMMENDATION_TOUR}`,
    baseURL: config.BASE_URL_TOUR_API,
    params: {},
    headers,
  }).post(bodyWithClientId)
}

export function getProductRecommended(body) {
  return new ApiService({
    endpoint: `${Urls.PRODUCT_RECOMMENDED}`,
    baseURL: config.BASE_URL_TOUR_API,
  }).post(body)
}

export function getUserRecommended() {
  const authInfo = getAuthCookies();
  let headers;
  if (authInfo && authInfo.auth && authInfo.auth.accessToken) {
      headers = {
          Authorization: `Bearer ${authInfo.auth.accessToken}`,
      }
  }

  const clientId = getClientId();

  return new ApiService({
    endpoint: `${Urls.TOUR_LIST}/user-recommended`,
    baseURL: config.BASE_URL_TOUR_API,
    params: {
      pageIndex: 1,
      pageSize: 6,
      ClientId: clientId
    },
    headers,
    parser: (data) => (data?.result || []).map(item => new OutstandingProductListing(item))
  }).get()
}

export function getListingDetail (slug) {
  return new ApiService({
    endpoint: Urls.VOUCHER_DETAIL,
    endpointParams: { slug },
    baseURL: config.BASE_URL_VOUCHER_API,
  }).get()
}



export function getLocations (params) {
  return new ApiService({
    endpoint: Urls.LOCATION_LIST_ALL,
    baseURL: config.BASE_URL_VOUCHER_API,
    params: params
  }).get()
}

export async function getExchangeRate() {
  return new ApiService({
    endpoint: `${Urls.EXCHANGERATE_LIST}/`,
    baseURL: config.BASE_URL_VOUCHER_API,
  }).get()
}

export function getAutocomplete (params) {
  return new ApiService({
    endpoint: Urls.SKU_SEARCH_AUTOCOMPLETE,
    baseURL: config.BASE_URL_VOUCHER_API,
    parser: listAutocompleteParser,
    params: params
  }).get()
}

const listAutocompleteParser = (data) => ({
  result: (data.result || []).map(item => new AutocompleteListing(item)),
  pageIndex: data.pageIndex,
  pageSize: data.pageSize,
  totalCount: data.totalCount || data.total,
});

const listParser = (data) => ({
  result: (data.result || []).map(item => new ListingItem(item)),
  pageIndex: data.pageIndex,
  pageSize: data.pageSize,
  totalCount: data.totalCount || data.total,
});

export const getRatingVoucher = (ratings = {}) => {

  const ratingList = Array(MAX_STAR).fill(null).map((index, value) => ({
    star: value + 1,
    review: 0,
    percentage: 0
  }))

  if (ratings) {
    const {
      ratingValue = {},
      totalCount = 0
    } = ratings

    if (ratingValue?.one) {
      ratingList[0].review = ratingValue.one
      ratingList[0].percentage = numberExtensions.getPercentage(ratingValue.one,
        totalCount)
    }

    if (ratingValue?.two) {
      ratingList[1].review = ratingValue.two
      ratingList[1].percentage = numberExtensions.getPercentage(ratingValue.two,
        totalCount)
    }

    if (ratingValue?.three) {
      ratingList[2].review = ratingValue.three
      ratingList[2].percentage = numberExtensions.getPercentage(
        ratingValue.three, totalCount)
    }

    if (ratingValue?.four) {
      ratingList[3].review = ratingValue.four
      ratingList[3].percentage = numberExtensions.getPercentage(
        ratingValue.four, totalCount)
    }

    if (ratingValue?.five) {
      ratingList[4].review = ratingValue.five
      ratingList[4].percentage = numberExtensions.getPercentage(
        ratingValue.five, totalCount)
    }

  }

  return ratingList.reverse()
}

export default {
  initQueryListing (value = {}) {
    const params = {
      Keyword: value.keyword || '',
      // TagGroupIds: value.tagGroupIds || [],
      PageSize: value.pageSize || DEFAULT_PAGE_SIZE,
      PageIndex: value.pageIndex || DEFAULT_PAGE_INDEX,
      OrderByDesc: value.orderByDesc || false,
      OrderBy: value.orderBy,
      MinPrice: value.minPrice,
      MaxPrice: value.maxPrice,
      // LocationId: value.locationId,
      TagGroupDestinations: value.destination,
      TagGroupLocations: value.location,
      TagGroupProductTypes: value.product_type,
      TagGroupDepartures: value.departure,
      TagGroupLengthOfStays: value.length_of_stay,
      TagGroupSuitablePeople: value.suitable_people,
      TagGroupTypeOfStays: value.type_of_stay,
    }
    objectExtensions.deleteEmptyProps(params)
    return queryString.stringify(params)
  }
}
