import React, { useEffect, useState } from 'react'
import { withRouter } from '@utils/router'
import { useSelector } from 'react-redux'
import LazyImage from '../../vendor/lazy-image/LazyImage'
import { routes, LOCAL_STORAGE_KEY } from '../../constants'
//hook
import { selectPageBanner } from '../../reselect/bannerSelector'
import { Icon } from 'antd'

const closedKeyStorage = LOCAL_STORAGE_KEY.PREFIX + LOCAL_STORAGE_KEY.CLOSED_STICKY_ADS;

const StickyAds = ({ router, position = 'bottom' }) => {

  const banners = useSelector(selectPageBanner())
  const [bgStickyAds, setBgStickyAds] = useState(null)
  const [bgStickyAdsMobi, setBgStickyAdsMobi] = useState(null)
  const [linkBanner, setLinkBanner] = useState(null)
  const [isClosed, setIsClosed] = useState(
    typeof sessionStorage !== 'undefined' && sessionStorage.getItem(closedKeyStorage + position) === 'true'
  )

  useEffect(() => {
    if (banners) {
      getBgAds(banners, router)
      getBgAdsMobi(banners, router)
    }
  }, [banners])

  const onClose = (e) => {
    e.preventDefault();
    setIsClosed(true);
    if (typeof sessionStorage !== 'undefined') sessionStorage.setItem(closedKeyStorage + position, 'true')
  }

  const getImageId = (name, position = 'bottom', isMobile=false) => {
    return `${name}_sticky_${position}_ads${isMobile ? '_mobile' : ''}`
  }

  const getBgAds = (banners, router) => {
    if (banners) {
      for (let item of banners) {
        switch (router.route) {
          case routes.HOTEL:
            if (item.imageId === getImageId('hotel', position)) {
              setBgStickyAds(item.imageView.fileUri)
              if (item.url && item.url.trim().length > 0) {
                setLinkBanner(item.url)
              } else {
                setLinkBanner(null)
              }
            }
            break
          case routes.COMBO:
            if (item.imageId === getImageId('combo', position)) {
              setBgStickyAds(item.imageView.fileUri)
              if (item.url && item.url.trim().length > 0) {
                setLinkBanner(item.url)
              } else {
                setLinkBanner(null)
              }
            }
            break
          case routes.VOUCHER:
            if (item.imageId === getImageId('voucher', position)) {
              setBgStickyAds(item.imageView.fileUri)
              if (item.url && item.url.trim().length > 0) {
                setLinkBanner(item.url)
              } else {
                setLinkBanner(null)
              }
            }
            break
          case routes.EXPERIENCE:
            if (item.imageId === getImageId('experience', position)) {
              setBgStickyAds(item.imageView.fileUri)
              if (item.url && item.url.trim().length > 0) {
                setLinkBanner(item.url)
              } else {
                setLinkBanner(null)
              }
            }
            break
          case routes.HOME:
            if (item.imageId === getImageId('home', position)) {
              setBgStickyAds(item.imageView.fileUri)
              if (item.url && item.url.trim().length > 0) {
                setLinkBanner(item.url)
              } else {
                setLinkBanner(null)
              }
            }
            break
          default:
            setBgStickyAds(null)
            setLinkBanner(null)
        }
      }
    }
  }

  const getBgAdsMobi = (banners, router) => {
    if (banners) {
      for (let item of banners) {
        switch (router.route) {
          case routes.HOTEL:
            if (item.imageId === getImageId('hotel', position, true)) {
              setBgStickyAdsMobi(item.imageView.fileUri)
            }
            break
          case routes.COMBO:
            if (item.imageId === getImageId('combo', position, true)) {
              setBgStickyAdsMobi(item.imageView.fileUri)
            }
            break
          case routes.VOUCHER:
            if (item.imageId === getImageId('voucher', position, true)) {
              setBgStickyAdsMobi(item.imageView.fileUri)
            }
            break
          case routes.EXPERIENCE:
            if (item.imageId === getImageId('experience', position, true)) {
              setBgStickyAdsMobi(item.imageView.fileUri)
            }
            break
          case routes.HOME:
            if (item.imageId === getImageId('home', position, true)) {
              setBgStickyAdsMobi(item.imageView.fileUri)
            }
            break
          default:
            setBgStickyAdsMobi(null)
        }
      }
    }
  }

  const content = (
    <div className="d-flex g-flex-centered align-items-center content">
      <picture>
        <source
          media="(max-width: 576px)"
          srcSet={(bgStickyAdsMobi && bgStickyAds) ? `https://${bgStickyAdsMobi}` : `https://${bgStickyAds}`} />
        <LazyImage
          src={(bgStickyAds) ? `https://${bgStickyAds}` : null}
          className='img-fluid w-100'
        />
        <Icon className="btn-close" type="close" onClick={onClose} />
      </picture>
    </div>
  )

  return (!!(bgStickyAds || bgStickyAdsMobi) && !isClosed &&
    <section className="vpt-sticky-ads bottom">
      <div className="vpt-bg-v6">
        {linkBanner ? <a href={linkBanner}>{content}</a> : content}
      </div>
    </section>
  )
}

export default withRouter(StickyAds)
