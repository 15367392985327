import config from "./../config"
export default {
  /**
   * HOME
   */
  HOME: '/',

  ERROR: '/error',
  /**
   * FLIGHT
   */
  FLIGHT: '/',
  FLIGHT_BOOKING: '/booking',
  /**
   * FLIGHT
   */
  CART: '/cart',
  // Flight Index
  SEARCH: '/search',
  // Flight hotel
  SEARCH_HOTEL: '/search-hotel',

  // Cart & hotel
  CART_WO_HOTEL: '/cart-wo-hotel',
  CART_W_HOTEL: '/cart-w-hotel',

  // Index/One Way
  ONE_WAY: '/one-way',
  SELECT_FLIGHT: '/select-flight',

  // Index/Return
  RETURN: '/return',
  SELECT_FLIGHT_1: '/select-flight-1',
  SELECT_FLIGHT_2: '/select-flight-2',

  // Payment
  PAYMENT_INFO: '/payment-info',
  PAYMENT_CONFIRM: '/payment-confirm',
  PAYMENT_METHOD: '/payment-method',
  PAYMENT_SUCCESS: '/payment-success',
  PAYMENT_RESULT: '/payment/result',

  /**
   * TOUR
   */
  TOUR: '/tour/search',
  TOUR_DETAIL_SLUG: '/tour/:slug',
  TOUR_DETAIL: '/tour',

  /**
   * DISCOUNT
   */
  DISCOUNT: '/discount',

  /**
   * MAIL
   */
  MAIL: '/mail',

  /**
   * MY_RESERVE
   */
  MY_RESERVE: '/cart',

  /**
   * HOTEL
   */
  HOTEL: '/hotel',
  HOTEL_CART: '/hotel/cart',
  HOTEL_DETAIL_SLUG: '/hotel/:hotelId',
  HOTEL_DETAIL: '/hotel',
  HOTEL_BOOKING: '/hotel/booking',

  /**
   * COMBO
   */
  COMBO: '/combo',
  COMBO_SEARCH: '/combo/search',
  COMBO_FLIGHT: '/combo/flight',
  COMBO_BOOKING: '/combo/booking',
  COMBO_DETAIL_SLUG: '/combo/:hotelId',
  COMBO_DETAIL: '/combo',
  COMBO_CART: '/combo/cart',

  /**
   * VOUCHER
   */
  VOUCHER: '/voucher/search',
  VOUCHER_DETAIL_SLUG: '/voucher/:slug',
  VOUCHER_DETAIL: '/voucher',

  /**
   * EXPERIENCE
   */
  EXPERIENCE: '/experience/search',
  EXPERIENCE_DETAIL_SLUG: '/experience/:slug',
  EXPERIENCE_DETAIL: '/experience',

  /**
   * MEMBER
   */
  MEMBER: '/member',

  /**
   * BLOG
   */
  BLOG: '/blog',
  BLOG_DETAIL_SLUG: '/blog/:slug',
  BLOG_DETAIL: '/blog',

  /**
   * REGULATIONS
   */
  REGULATIONS: '/dieu-khoan',
  REGULATIONS_DETAIL: '/dieu-khoan/:slug',

  GENERAL_TERMS: '/general-terms',
  GENERAL_TERMS_DETAIL: '/general-terms/:slug',

  /**
   * Auth
   */
  LOGIN: `/authorize`,
  LOGIN_VPT: `/login-vpt`,
  // LOGIN: '/login',
  REGISTER: `/authorize`,
  LOGOUT: `/logout`,
  // REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_COMPLETE: '/forgot-password-complete',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_COMPLETE: '/reset-password-complete',

  /**
   * USER PROFILE
   */
  USER_PROFILE: '/user-profile',
  MY_ORDER: '/my-order',
  MY_LOYALTY_POINT: '/my-loyalty-point',
  POINT_USAGE_HISTORY: '/point-usage-history',
  NIGHT_USAGE_HISTORY: '/night-usage-history',
  TRANSACTION_HISTORY: '/transaction-history',
  PEARL_CLUB_BENEFIT: '/pearl-club-benefit',
  MY_OFFER: '/my-offer',
  REFERRAL_MEMBER : '/referral-member',
  COLLABORATORS : '/collaborators',
  FAQ : '/faq',

  /**
   * USER PROFILE
   */
  PAGE: '/page',
  PAGE_DETAIL_SLUG: '/page/:slug',
  PAGE_DETAIL: '/page',


  TOUR_AND_EXPERIENCE: '/tours-and-experiences',
  TOUR_AND_EXPERIENCE_SEARCH: '/tours-and-experiences/search',
  TOUR_AND_EXPERIENCE_SLUG: '/tours-and-experiences/:slug',
  TOUR_AND_EXPERIENCE_DETAIL: '/tours-and-experiences',

  STAYNFUN: '/staynfun',
  STAYNFUN_DETAIL_SLUG: '/staynfun/:hotelId',
}
