import { each, findIndex } from "lodash";
import CartItem from "./CartItem";
import Coupon from './Coupon';
import { PAYMENT_GATEWAY } from '../constants/enumType';
import { objectExtensions } from "../extensions";
export default class Cart {
    constructor(params) {
        params = params || {};
        this.vouchers = [];
        each(params.vouchers, (voucher) => {
            this.vouchers.push(new CartItem(voucher))
        })
        this.tours = [];
        each(params.tours, (tour) => {
            this.tours.push(new CartItem(tour))
        })
        this.vinWonders = (params.vinWonders || []).map(item => new CartItem(item));
        this.isValid = typeof params.isValid === 'boolean' ? params.isValid : true;
        this.id = params.id || undefined;
        this.fullname = params.fullname || "";
        this.firstname = params.firstname || localStorage.getItem("firstname");
        this.lastname = params.lastname || localStorage.getItem("lastname");
        this.email = params.email || localStorage.getItem("email");
        this.phoneNumber = params.phoneNumber || localStorage.getItem("phoneNumber");
        this.gatewayId = params.gatewayId || null;
        this.clientId = params.clientId || undefined;
        this.couponCode = params.couponCode || undefined;
        this.cartCode = params.cartCode || localStorage.getItem("cartID") || undefined;
        this.note = params.note || undefined;
    }

    removeVoucher(cartItem) {
        let idx = findIndex(this.vouchers, ["productVariantId", cartItem.productVariantId]);
        if (idx >= 0) this.vouchers.splice(idx, 1);
    }

    removeTour(cartItem) {
        let idx = findIndex(this.tours, ["productVariantId", cartItem.productVariantId]);
        if (idx >= 0) this.tours.splice(idx, 1);
    }

    removeVinWonder(cartItem) {
        let idx = findIndex(this.vinWonders, ["productVariantId", cartItem.productVariantId]);
        if (idx >= 0) this.vinWonders.splice(idx, 1);
    }

    updateGateway(gateway) {
        this.gatewayId = gateway.id;

        const vnPayType = gateway && gateway.name && gateway.name.endsWith('VNPAY') ? PAYMENT_GATEWAY.TYPE.VNPAY : undefined;
        this.paymentType = gateway.type || vnPayType;
    }

    isEmpty() {
        return this.vouchers.length == 0 && this.tours.length == 0;
    }

    getTotalPrice(discount = 0) {
        let totalPrice = 0;
        each(this.tours, (tour) => {
            totalPrice += parseInt(tour.quantity) * parseInt(tour.salePrice) + parseInt(tour.childQuantity) * parseInt(tour.childSalePrice)
        })
        each(this.vouchers, (voucher) => {
            totalPrice += parseInt(voucher.quantity) * parseInt(voucher.salePrice)
        })
        return totalPrice - (discount || 0);
    }

    getItemCount() {
        return this.tours.length + this.vouchers.length;
    }

    getProductIds() {
        return objectExtensions.getCartProductIds(this);
    }

    setCoupon(coupon = undefined) {
        if (typeof coupon === 'string') {
            this.couponCode = coupon;
        } else if (coupon instanceof Coupon) {
            this.couponCode = coupon.code;
        } else {
            this.couponCode = undefined;
        }
    }
}

const dummyItems = [
    {
        "productVariantId": "abf8c130-6757-11ea-ad5d-02ab66e0a2c8",
        "vinWonderName": "Vé vui chơi giải trí VinPearl Land Nha Trang",
        "vinWonderTicketName": "Vé vào cửa trực tiếp với Di chuyển 2 chiều bằng cáp treo",
        "cartItemType": 5,
        "quantity": 1,
        "originalPrice": 0,
        "salePrice": 1000000,
        "childOriginalPrice": 0,
        "childSalePrice": 820000,
        "childQuantity": 1,
        "infantOriginalPrice": 0,
        "infantSalePrice": 0,
        "infantQuantity": 0,
        "seniorOriginalPrice": 0,
        "seniorSalePrice": 820000,
        "seniorQuantity": 2,
        "ticketItemId": "2f16f11f-6757-11ea-ad5d-02ab66e0a2c8",
        "childTicketItemId": "301d4d53-6757-11ea-ad5d-02ab66e0a2c8",
        "seniorTicketItemId": "30bd00d7-6757-11ea-ad5d-02ab66e0a2c8",
        "usingDate": "2020-03-26T17:00:00.000Z",
        "siteCode": "7601",
        "cartCode": "62b41be99a8d456baf784528063a92ec",
        "thumImage": {
            "filename": "1ab7d7dee77b458fafbf58d8d775f379_vp_webpnet-resizeimage-5-1120x750-c.jpg",
            "fileUri": "vin-evoucher-static-development.s3-ap-southeast-1.amazonaws.com/vinwonder_thumb/1ab7d7dee77b458fafbf58d8d775f379_vp_webpnet-resizeimage-5-1120x750-c.jpg"
        }
    },
    {
        "productVariantId": "3af10b77-69c2-11ea-ad5d-02ab66e0a2c8",
        "vinWonderName": "Vé vui chơi giải trí TimeCity",
        "vinWonderTicketName": "Vé Thủy Cung",
        "cartItemType": 5,
        "quantity": 1,
        "originalPrice": 0,
        "salePrice": 170000,
        "childOriginalPrice": 0,
        "childSalePrice": 0,
        "childQuantity": 0,
        "infantOriginalPrice": 0,
        "infantSalePrice": 0,
        "infantQuantity": 0,
        "seniorOriginalPrice": 0,
        "seniorSalePrice": 0,
        "seniorQuantity": 0,
        "ticketItemId": "f9378d82-69c3-11ea-ad5d-02ab66e0a2c8",
        "usingDate": "2020-03-26T17:00:00.000Z",
        "siteCode": "7014",
        "cartCode": "62b41be99a8d456baf784528063a92ec",
        "thumbImage": {
            "filename": "531c95f3977b4018a3ea4be28d0fd554_42360708_1164189373718951_1587106036391608320_o.jpg",
            "fileUri": "vin-evoucher-static-development.s3-ap-southeast-1.amazonaws.com/vinwonder_thumb/531c95f3977b4018a3ea4be28d0fd554_42360708_1164189373718951_1587106036391608320_o.jpg"
        },
    },
]