import React, { memo } from 'react'
import { Menu, Dropdown, Icon, Row, Col } from 'antd'
// constant
import {
  currencyEnum
} from '../../constants/currencyUnit'
import { LocalStorage } from '../../utils'
import { LOCAL_STORAGE_KEY } from '../../constants'
import { getCurrentCurrencyOnl, setCurrencyOnlCookie } from '../../utils/lang'
import { objectExtensions } from '../../extensions'
import { useGlobalStore } from '../../hooks/useGlobal'
import clsx from 'clsx'
import { convertLinkLocale } from '@utils/common'
import Router from '@utils/router'
import { FormattedMessage } from 'react-intl.macro'
import './Currency.scss'

const CurrencyUnit = ({ className, containerSelector, isHomepage = false }) => {

  const { state, dispatch } = useGlobalStore();
  // functions
  const handleChangeCurrency = ({ key }) => {
    if (key !== currency) {
      setCurrencyOnlCookie(key)
      dispatch({ type: 'currencyOnl', data: key });
      LocalStorage.set(LOCAL_STORAGE_KEY.CURRENCY_WESBITE, { type: 'currencyOnl', data: key })
      const lang = state.lang;
      const languagePath = convertLinkLocale(lang, null, key, true)
      Router.push(languagePath)
    //   window.location.reload()
    }
  }
  // init variables before render
  const currency = state.currencyOnl || getCurrentCurrencyOnl()
  const menu = (
    <Menu
      selectable={false}
      onClick={handleChangeCurrency}
      // className="g-mt-7 g-mt-0--md scroll-bar-currency"
    >
      <Menu.ItemGroup className='currency-group-wrap' title={
        <FormattedMessage
          id="S43.Header.Currency"
          defaultMessage="Tiền tệ"
        />
      }>
          {currencyEnum.map((currencyItem) => 
          // !currency.hide && 
          (
              <Menu.Item
                key={currencyItem.value}
                className='currency-group-wrap_content'
              >
                <div className='currency-group-wrap_content__container'>
                  <div className={clsx({
                    'currency-group-wrap_content__container-label': true,
                    'cur-onl': currency === currencyItem?.value
                  })} >
                    {currencyItem.country}
                  </div>
                  <div className='currency-group-wrap_content__container-value'>
                    {currencyItem.label} {currencyItem.label.props.defaultMessage === currencyItem.icon ? '' : currencyItem.icon} - {currencyItem.symbol}
                  </div>
                </div>
              </Menu.Item>
          ))
          }
      </Menu.ItemGroup>
    </Menu>
  )
  // render
  return (
    <div className={clsx("d-inline-block ", className)}>
      <Dropdown overlay={menu} trigger={['hover']} placement="bottomRight" overlayClassName='s43-currency-dropdown' getPopupContainer={triggerNode => triggerNode.closest(containerSelector)}>
        <strong>
          <a className="ant-dropdown-link d-flex flex-row justify-content-center align-items-center">
            <p
              className={clsx('s43-currency-btn',{'text-white': isHomepage})}>{objectExtensions.getIconFromValue(currency, currencyEnum)}</p>
          </a>
        </strong>
      </Dropdown>
    </div>
  )
}

export default memo(CurrencyUnit)
