import actionTypes from '../actionTypes'

const initState = {
  provinces: [],
  tourData: {},
  specialTours: [],
  tickets: [],
  tourDetail: null,
  tourLineData: {},
  tourTypes: [],
}

const tourReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_TOUR_SUCCESS:
      return Object.assign({}, state, {
        tourData: action.payload.data || {},
      })
    case actionTypes.FETCHING_TOUR_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        tourDetail: action.payload.data || null,
      })
    case actionTypes.FETCHING_TOUR_SPECIAL_SUCCESS:
      return Object.assign({}, state, {
        specialTours: action.payload.data || [],
      })
    case actionTypes.FETCHING_TOUR_STATE_PROVINCE_SUCCESS:
      return Object.assign({}, state, {
        provinces: action.payload.data || [],
      })
    case actionTypes.FETCHING_TOUR_LINE_ACTION:
      return Object.assign({}, state, {
        tourLineData: {},
      })
    case actionTypes.FETCHING_TOUR_LINE_SUCCESS:
      return Object.assign({}, state, {
        tourLineData: action.payload.data || {},
      })
    case actionTypes.CLEAR_TOUR_LINE_DATA:
      return Object.assign({}, state, {
        tourLineData: {},
      })
    case actionTypes.FETCHING_TOUR_TICKET_SUCCESS:
      return Object.assign({}, state, {
        tickets: action.payload.data || [],
      })
    case actionTypes.FETCHING_TOUR_VINWONDER_TICKET_SUCCESS:
      return Object.assign({}, state, {})
    case actionTypes.FETCHING_TOUR_TYPE_SUCCESS:
      return Object.assign({}, state, {
        tourTypes: action.payload.data || [],
      })
    default:
      return state
  }
}

export default tourReducer
