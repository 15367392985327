import ApiService from './../api/ApiService';
import Urls from './../api/Urls';
import config from "./../config";
import Cart from "./../model/Cart";
import { enumType } from '../constants';
import { formatPhoneNumber } from '../utils/common';
import { getAuthCookies } from '../utils/auth';
import {notification} from 'antd';

export async function addToCart(params) {
    params.cartCode = localStorage.getItem("cartID") || undefined;
    let data = await new ApiService({
        endpoint: Urls.ADD_TO_CART,
        baseURL: config.BASE_URL_CART_API,
        receiveErrorModel: true,
    }).post(params)
    const message = data.message;
    if (data.success === false) {
        const errorCode = data.errorCode;
        data = null;
        if (errorCode === enumType.apiErrorCode.CART_WAS_PAID) {
            params.cartCode = undefined;
            localStorage.removeItem("cartID")
            return addToCart(params)
        }
    } else if (data && data.cartCode) {
        localStorage.setItem("cartID", data.cartCode)
    }
    if (!data && message){
        notification.error({
            key: 'tour-error',
            message: 'Lỗi',
            description: message,
        });
    }
    return data;
}

export async function getCart() {
    return Promise.resolve().then(async () => {
        const cartCode = localStorage.getItem("cartID") || undefined;
        let data = null;
        if (cartCode) {
            data = await new ApiService({
                endpoint: `${Urls.GET_CART}/${cartCode}`,
                baseURL: config.BASE_URL_CART_API,
                parser: itemParser
            }).get()
        }
        return data;
    })
}

export async function getCartCount() {
    return Promise.resolve().then(async () => {
        const cartCode = localStorage.getItem("cartID") || undefined;
        let data = null;
        if (cartCode) {
            data = await new ApiService({
                endpoint: `${Urls.GET_CART_COUNT}/${cartCode}`,
                baseURL: config.BASE_URL_CART_API,
            }).get()
        }
        return data;
    })
}

const itemParser = (resCart) => {
    const cart = new Cart(resCart);
    return cart;
};

export async function updateCartItem(cartItem) {
    return Promise.resolve().then(async () => {
        const cartCode = localStorage.getItem("cartID") || undefined;
        let data = {};
        if (cartCode) {
            cartItem.cartCode = cartCode;
            data = await new ApiService({
                endpoint: `${Urls.UPDATE_ITEM_CART}`,
                baseURL: config.BASE_URL_CART_API
            }).put(cartItem)
        }
        return data;
    })
}

export async function deleteCartItem(cartItem) {
    return Promise.resolve().then(async () => {
        const cartCode = localStorage.getItem("cartID") || undefined;
        let data = {};
        if (cartCode && cartItem.productVariantId) {
            cartItem.cartCode = cartCode;
            data = await new ApiService({
                endpoint: `${Urls.DELETE_ITEM_CART}/${cartCode}/item/${cartItem.productVariantId}`,
                baseURL: config.BASE_URL_CART_API
            }).delete()
        }
        return data;
    })
}

export async function getPaymentMethods() {
    return Promise.resolve().then(async () => {
        return await new ApiService({
            endpoint: Urls.GET_PAYMENT_METHOD,
            baseURL: config.BASE_URL_PAYMENT_API,
        }).get()
    })
}

export async function checkout(cart) {
    const cartCode = localStorage.getItem("cartID") || undefined;
    localStorage.setItem("firstname", cart.firstname)
    localStorage.setItem("lastname", cart.lastname)
    localStorage.setItem("phoneNumber", cart.phoneNumber)
    localStorage.setItem("email", cart.email)
    const authInfo = getAuthCookies();
    let headers;
    if (authInfo && authInfo.auth && authInfo.auth.accessToken) {
        headers = {
            Authorization: `Bearer ${authInfo.auth.accessToken}`,
        }
    }
    return Promise.resolve().then(async () => {
        return await new ApiService({
            endpoint: Urls.CHECKOUT,
            receiveErrorModel: true,
            forceNotifyError: true,
            baseURL: config.BASE_URL_CART_API,
            headers: headers,
        }).post({
            cartCode: cartCode,
            gatewayId: cart.gatewayId,
            customerId: cart.customerId,
            fullname: cart.firstname + ` ` + cart.lastname,
            firstname: cart.firstname,
            lastname: cart.lastname,
            email: cart.email,
            phoneNumber: formatPhoneNumber(cart.phoneNumber),
            note: cart.note || "",
            clientId: cart.clientId,
            couponCode: cart.couponCode,
            paymentType: cart.paymentType,
        })
    })
}
