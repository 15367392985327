import { language } from '../constants/language'
import { getLanguageCookie, getDisplayCurrency, getCurrencyCookie, getAcceptLanguage } from './lang'
import { enumType, FORMAT_ONLY_DATE, routes, VINWONDER_ENUM } from '../constants'
import { datetimeExtensions } from '../extensions'
import { cartUtils, commonUtils, flightUtils, hotelUtils } from './index'
import moment from 'moment';
import { get, set, concat, find, merge, forEach, sumBy, cloneDeep } from 'lodash';
import { useCallback } from 'react'
import { currencyUnit } from '../constants/currencyUnit'
import { getConfig } from '@config'
import { getMomentDate } from './common'
import queryString, { parse } from '@utils/querystring'
import object from '../extensions/object'
import { globalStore } from '../hooks/useGlobal'
import hotel from './hotel'
import { createGtmComboImpression, createGtmFlightImpression, createHotelProduceImpresion, createProductImpressions } from './gtmEventConvert'
import {gtmEvent, gtmType } from 'src/helpers/gtmEvent'
import _findIndex from "lodash/findIndex";
import _uniqBy from "lodash/uniqBy";
import { decryptAES256, isGtmDisabled } from './gtmHelpers'
import { DDAddAction } from 'src/hooks/DD_RUM'

const pageTypeParser = (activity) => {
  let pagePath = window.location.pathname.substring(1);
  const pathArr = pagePath.split('/');
  const testSlug = pathArr[pathArr.length - 1];
  const regexDetailSlug = /^[a-z0-9]+(?:-[a-z0-9]+)*$/
  if (testSlug && regexDetailSlug.test(testSlug) && testSlug.indexOf('-') >= 0) {
    pathArr[pathArr.length - 1] = 'detail'
  } else if (window.location.pathname === routes.PAYMENT_RESULT) {
    const params = queryString.parse(window.location.search.replace('?', ''))
    if (params && params.result) {
      pathArr[pathArr.length - 1] = params.result
    }
  }
  pagePath = pathArr.join('_')
  return pagePath || 'home';
}

export const dataLayerParsers = {
  productImpressions: (impressions = [], currencyCode = getDisplayCurrency()) => ({
    event: 'productImpressions',
    ecommerce: { currencyCode, impressions },
  }),
  productDetail: (products, itemName = 'product', action = 'detail', position = null, callback = (...params) => { }) => ({
    event: commonUtils.snakeToCamel('product_' + action),
    ecommerce: {
      [action]: {
        actionField: { 'list': itemName + `_search_results` },
        products: products.map(item => merge(item, {
          position,
          list: item.list || (position !== null ? (itemName + `_search_results`) : undefined)
        })),
      }
    },
    eventCallback: callback,
  }),
  productCart: (products, itemName = 'product', action = 'add', position = null, quantity = 1) => ({
    event: commonUtils.snakeToCamel('product_' + action + (action === 'add' ? '_to' : '_from') + '_cart'),
    ecommerce: {
      [action]: {
        products: products.map(item => merge(item, {
          position, quantity,
          list: item.list || (position !== null ? (itemName + `_search_results`) : undefined)
        }))
      }
    },
  }),
  checkout: (products, itemName = 'product', option = null, step = 1, callback = (...params) => { }) => ({
    event: 'checkout',
    currencyCode: getDisplayCurrency(),
    ecommerce: {
      checkout: {
        actionField: { step, option },
        products: products.map(item => merge(item, {
          quantity: item.quantity || 1,
          list: item.list || (itemName + `_search_results`),
        })),
      }
    },
    eventCallback: callback,
  }),
  purchase: (products, order = {}) => ({
    event: 'purchase',
    currencyCode: getDisplayCurrency(),
    ecommerce: {
      purchase: {
        actionField: { ...order },
        products: products.map(item => merge(item, {
          quantity: item.quantity || 1,
          list: item.list || `purchase_list`,
        })),
      }
    }
  }),
  mergeBundleProductLayerData: (layer, eventName = 'checkout', staticFields = {}) => {
    const productsKey = `ecommerce.${eventName}.products`
    set(layer, productsKey, (get(layer, productsKey) || [])
      .map((item) => merge(item, {
        ...staticFields,
        id: staticFields.id || item.id,
        category: 'Bundle',
      })))
    return layer;
  },
  getData: (data, parser) => {
    if (Array.isArray(data)) {
      return data.map((item, index) => parser(item, index));
    }
    return data && [parser(data, -1)];
  },
  getDateDuration: (start, end) => {
    if (!!start && !!end) {
      return Math.round(moment.duration(moment.utc(end).diff(moment.utc(start))).asDays());
    }
    return null;
  },
  bundleHotels: (bundleData = {}, rooms = []) => {
    const hotel = Array.isArray(bundleData.hotels) ? bundleData.hotels[0] : bundleData.hotel || {};
    // console.log('===== bundleHotels', bundleData, rooms)
    const hotelParser = (hotel, room) => {
      if (!get(room, 'roomtype') && typeof get(room, 'roomTypes') === 'object') {
        set(room, 'roomtype', get(room, 'roomTypes'))
      }
      return {
        hotel: {
          ...hotel,
          id: hotel.id || hotel.hotelId,
          price: bundleData.totalPrice,
        },
        rate: {
          ...(room || {}),
          averageAmount: {
            amount: {
              amount: bundleData.pricePerson
            }
          },
          totalAmount: {
            amount: {
              amount: bundleData.totalPrice
            }
          },
        },
      }
    }
    if (rooms && rooms.length) {
      return rooms.map(room => hotelParser(hotel, room))
    }
    return hotelParser(hotel)
  },
  bundleFlight: (flightData) => {
    return {
      ...flightData,
    }
  },
  isDisabled: () => {
    let { publicRuntimeConfig: {
      NODE_ENV
    } } = getConfig()
    return NODE_ENV === 'development' || NODE_ENV === 'staging' || typeof window === 'undefined'
  },
  executeCallback: (params) => {
    if (params.length && typeof params[params.length - 1] === 'function') {
      return params[params.length - 1]();
    }
  },
  remarketingPush: (data = [], parser = () => { }, activity, ...params) => {
    if (!Array.isArray(data)) {
      data = [data]
    }
    forEach((data || []), (item => {
      const dataItem = parser(item, activity, ...params);
      if (dataItem) {
        window.dataLayer.push({
          'event': 'dremkt',
          'remarketing': dataItem
        })
      }
    }))
  },
  remarketingParser: {
    voucher: (voucher, activity) => voucher && ({
      're.productID': voucher.id,
      're.pageType': pageTypeParser(activity),
      're.totalValue': cartUtils.isInCartPage() ? get(globalStore, 'state.cartTotalPrice') : voucher.price || voucher.salePrice,
      're.startDate': voucher.saleStartDate,
      're.endDate': voucher.saleEndDate,
      'revoucher_prodid': voucher.id,
      'revoucher_pagetype': pageTypeParser(activity),
      'revoucher_totalvalue': voucher.price || voucher.salePrice,
    }),
    tour: (tour, activity) => tour && ({
      're.productID': tour.id,
      're.pageType': pageTypeParser(activity).replace('voucher', 'tour'),
      're.totalValue': cartUtils.isInCartPage() ? get(globalStore, 'state.cartTotalPrice') : tour.price || tour.adultSalePrice,
      're.originID': tour.departure && tour.departure.id,
      're.startDate': tour.saleStartDate,
      're.endDate': tour.saleEndDate,
      'revoucher_prodid': tour.id,
      'revoucher_pagetype': pageTypeParser(activity),
      'revoucher_totalvalue': tour.price || tour.adultSalePrice,
    }),
    experience: (experience, activity) => ({
      're.productID': experience.id,
      're.pageType': pageTypeParser(activity),
      're.totalValue': cartUtils.isInCartPage() ? get(globalStore, 'state.cartTotalPrice') : experience.price || experience.salePrice,
      're.originID': null,
      're.startDate': experience.saleStartDate,
      're.endDate': experience.saleEndDate,
      'revoucher_prodid': experience.id,
      'revoucher_pagetype': pageTypeParser(activity),
      'revoucher_totalvalue': experience.price || experience.salePrice,
    }),
    hotel: ({ hotel, rate = {}, roomRates }, activity, params = {}) => {
      // const roomOccupancy = params.roomOccupancy || {};
      // const otherOccupencies = roomOccupancy.otherOccupancies || [];
      // const childOccQty = (find(otherOccupencies, item => item.otherOccupancyRefCode === 'CHILD') || {}).quantity;
      // const infantOccQty = (find(otherOccupencies, item => item.otherOccupancyRefCode === 'INFANT') || {}).quantity;
      const dateIn = params.arrivalDate ? moment(params.arrivalDate).format('DD/MM/YYYY') : null;
      const startDate = dateIn || null;
      const endDate = datetimeExtensions.addVnDate(dateIn, parseInt(params.lengthOfStay) || 2, 'days')
      return {
        're.productID': hotel.id,
        're.pageType': pageTypeParser(activity),
        're.totalValue': cartUtils.isInCartPage() ? get(globalStore, 'state.cartTotalPrice') : rate.totalPrice || null,
        're.originID': null,
        're.startDate': startDate,
        're.endDate': endDate,
        // 'hotel_id': hotel.id,
        // 'hotel_totalvalue': rate.totalPrice || null,
        // 'hotel_startdate': startDate,
        // 'hotel_enddate': endDate,
        // 'hotel_name': hotel.name,
        // 'hotel_location': hotel.address || null,
        // 'hotel_address': hotel.address || null,
        // 'hotel_description': hotel.description,
        // 'hotel_roomtypename': rate.nameRoom || null,
        // 'hotel_roomtypecode': rate.roomCode || rate.code || null,
        // 'hotel_roomdescription': rate.description || null,
        // 'hotel_roomnight': roomOccupancy.lengthOfStay || (params.hs ? Number(params.hs) : null),
        // 'hotel_roomavailability': rate.maxOccupancy || null,
        // 'hotel_adults': roomOccupancy.numberOfAdult || Number(params.ha || 0),
        // 'hotel_children': childOccQty || Number(params.hc || 0),
        // 'hotel_infant': infantOccQty || Number(params.hi || 0),
        // 'hotel_language': getAcceptLanguage(),
        // 'hotel_couponid': params.promotionCode || null,
        // 'hotel_selection_link': routes.HOTEL_BOOKING,
        // 'hotel_image_link': get(hotel, 'media.url'),
        // 'hotel_category1': null,
        // 'hotel_best_roomrate': null,
        // 'hotel_public_roomrate': null,
        // 'hotel_roomrate': rate.totalPrice || null,
        // 'hotel_sale_roomrate': rate.totalPrice || null,
        // 'hotel_star': hotel.star || null,
        // 'hotel_cart_link': routes.HOTEL_CART,
        'rehotel_prodid': hotel.id,
        'rehotel_pagetype': null,
        'rehotel_totalvalue': null,
        'rehotel_startdate': startDate || null,
        'rehotel_enddate': endDate || null,
      }
    },
    flight: (flight, activity, params, flightData) => {
      const fareOptions = get(flight, 'fareOptions[0]') || get(flight, 'fare') || {};
      const segment = get(flight, 'listSegment[0]') || get(flight, 'segments[0]') || {};
      const { totalServiceFee, totalTax, totalFee, totalVptServiceFee } = flightUtils.getFlightFee({
        fare: fareOptions,
        infant: flightData.infant,
        children: flightData.children,
        adult: flightData.adult
      });
      const flightId = (segment.startPoint || flight.startPoint) + '-' + (segment.endPoint || flight.endPoint)
      const flightName = (flightData.startPointName || segment.startPointName) + ' - ' + (flightData.endPointName || segment.endPointName);
      return {
        're.productID': flightId,
        're.pageType': pageTypeParser(activity),
        're.totalValue': cartUtils.isInCartPage() ? get(globalStore, 'state.cartTotalPrice') : totalFee || null,
        're.originID': flight.departure || flightData.startPoint || fareOptions.startPoint || null,
        're.startDate': flight.departureDate || flightData.departureDate || null,
        're.endDate': flight.returnDate || flightData.returnDate || null,
        'reflight_destid': flight.destination || flightData.endPoint || fareOptions.endPoint || null,
        'reflight_pagetype': pageTypeParser(activity),
        'reflight_totalvalue': cartUtils.isInCartPage() ? get(globalStore, 'state.cartTotalPrice') : totalFee || null,
        'reflight_originid': flight.departure || flightData.startPoint || fareOptions.startPoint || null,
        'reflight_startdate': flight.departureDate || flightData.departureDate || null,
        'reflight_enddate': flight.returnDate || flightData.returnDate || null,
        // 'flight_tid': flightId,
        // 'flight_totalvalue': totalFee || null,
        // 'flight_bestprice': totalFee || null,
        // 'flight_departure': flight.departure || flightData.startPoint || fareOptions.startPoint || null,
        // 'flight_departureairportname': flight.startPointName || segment.startPointName || null,
        // 'flight_departureairportcode': flight.startPoint || segment.startPoint || null,
        // 'flight_departureCityID': segment.startPointCityName || null,
        // 'flight_arrival': flight.destination || flightData.endPoint || fareOptions.endPoint || null,
        // 'flight_arrivalairportname': flight.endPointName || segment.endPointName || null,
        // 'flight_arrivalairportcode': flight.endPoint || segment.endPoint || null,
        // 'flight_arrivalCityID': segment.endPointCityName || null,
        // 'flight_airportcode': flight.endPoint || segment.endPoint || null,
        // 'flight_planid': flight.flightNumber || segment.flightNumber || null,
        // 'flight_brand': segment.airlineName || null,
        // 'flight_ticketclass': segment.class || null,
        // 'flight_startdatetime': segment.startDate || null,
        // 'flight_enddatetime': segment.endDate || null,
        // 'flight_duration': flight.duration || null,
        // 'flight_farerules': null,
        // 'flight_adults': flightData.adult || 0,
        // 'flight_children': flightData.children || 0,
        // 'flight_infant': flightData.adult || 0,
        // 'flight_language': getAcceptLanguage(),
        // 'flight_name': flightName || null,
        // 'flight_stops': flight.stops,
        // 'flight_luggage': params.baggage || fareOptions.baggages || null,
        // 'flight_selection_link': routes.FLIGHT_BOOKING,
        // 'flight_image_link': segment.airlineImage || null,
      }
    },
  },
};

export const pushGtmDataLayerFlight = (flightData, activity, ...params) => {
  if (!flightData || !activity || dataLayerParsers.isDisabled()) return dataLayerParsers.executeCallback(params);

  const getFlightDataLayer = (flight, index) => {
    const fareOptions = get(flight, 'fareOptions[0]') || get(flight, 'fare') || {};
    const segment = get(flight, 'listSegment[0]') || get(flight, 'segments[0]') || {};
    const { totalServiceFee, totalTax, totalFee, totalVptServiceFee } = flightUtils.getFlightFee({
      fare: fareOptions,
      infant: flightData.infant,
      children: flightData.children,
      adult: flightData.adult
    })
    return {
      id: (segment.startPoint || flight.startPoint) + '-' + (segment.endPoint || flight.endPoint),
      name: (flightData.startPointName || segment.startPointName) + ' - ' + (flightData.endPointName || segment.endPointName),
      price: fareOptions.totalPrice || null,
      brand: segment.airlineName || null,
      category: 'Flight',
      variant: segment.flightNumber,
      list: index >= 0 ? 'flight_search_results' : null,
      position: index >= 0 ? index : null,
      // VPT Custom fields
      'flightLanguage': flight.language || getLanguageCookie() || language.Vietnamese,
      'flightType': flight.type || (flightData.returnDate
        ? enumType.itineraryType.Return
        : enumType.itineraryType.OneWay) || null,
      'flightDepatureID': flight.departure || flightData.startPoint || fareOptions.startPoint || null,
      'flightDestinationID': flight.destination || flightData.endPoint || fareOptions.endPoint || null,
      'flightDepatureName': flightData.startPointName || fareOptions.startPointName || null,
      'flightDestinationName': flightData.endPointName || fareOptions.endPointName || null,
      'flightDepartureDate': flight.departureDate || flightData.departureDate || null,
      'flightReturnDate': flight.returnDate || flightData.returnDate || null,
      'flightStartTime': segment.startDate,
      'flightEndTime': segment.endDate,
      'flightDuration': datetimeExtensions.convertMinToTime(flight.duration || fareOptions.duration || segment.duration, ' ', ' '),
      'flightStopPoint': null,
      'flightNumberOfAdults': flightData.adult || 0,
      'flightNumberOfChild': flightData.children || 0,
      'flightNumberOfInfant': flightData.infant || 0,
      'flightBrand': segment.airlineName,
      'flightBookingID': flightData.dataSession || null,
      'flightFlightID': segment.flightNumber,
      'flightAmount': fareOptions.totalPrice || null,
      'flightTicketPrice': fareOptions.priceAdult || null,
      'flightTax': totalTax || null,
      'flightAirportCharge': totalFee || null,
      'flightServiceCharge': totalServiceFee || null,
      'flightTicketCharge': totalVptServiceFee || null,
      'flightLuggage': params.baggage || fareOptions.baggages || null
    }
  }

  try {
    // console.log('=====> pushGtmDataLayerFlight', flightData, activity, data, ...params)
    const flights = concat(flightData.departureFlights || [], flightData.returnFlights || []);
    const data = dataLayerParsers.getData(flights, getFlightDataLayer)
    const layer = dataLayerParsers[activity](data, ...params);
    dataLayerParsers.remarketingPush(flights, dataLayerParsers.remarketingParser.flight, activity, params, flightData);
    // console.log('layer ===>', layer);
    return activity === 'checkout' || activity === 'purchase' ? layer : window.dataLayer.push(layer);
  } catch (error) {
    console.warn('GtmDataLayerFlight ERROR:', error);
    return dataLayerParsers.executeCallback(params)
  }
}

export const pushGtmDataLayerHotel = (hotelData, activity, ...funcParams) => {
  if (!hotelData || !hotelData.hotels || !activity || dataLayerParsers.isDisabled()) return dataLayerParsers.executeCallback(funcParams);
  const { hotels } = hotelData;
  const params = hotelData.params || {};
  const dateIn = params.arrivalDate ? moment(params.arrivalDate).format('DD/MM/YYYY') : null;
  const { passenger, numberOfRoom } = hotel.parsePassenger(params.roomOccupancy)
  const { adult, child, infant } = hotel.getTotalQuantity(passenger)

  const getHotelDataLayer = ({ hotel = {}, rate = {}, roomRates }, index) => {
    return {
      id: hotel.id,
      name: hotel.name,
      price: activity === 'checkout' || activity === 'purchase'
        ? (get(rate, 'rate.totalAmount.amount.amount') || null)
        : (get(rate, 'averageAmount.amount.amount') || null),
      brand: rate.nameRoom || null,
      category: rate.name || null,
      variant: rate.hotelStar || null,
      list: index >= 0 ? 'hotel_search_results' : null,
      position: index >= 0 ? index : null,
      // VP 3S CUSTOM
      'hotelName': hotel.name || null,
      'hotelID': hotel.id || null,
      'hotelPrice': get(rate, 'averageAmount.amount.amount') || null,
      'hotelLocation': params.hsp || null,
      'hotelAddress': hotel.address || null,
      'hotelCategory': hotel.name || null,
      'hotelPagetype': activity === 'productImpressions' ? 'hotel_search_results' : 'hotel_detail',
      'hotelPosition': index >= 0 ? index : null,
      'hotelPropertyCode': null,
      'hotelArrivalDate': dateIn || null,
      'hotelDepartureDate': datetimeExtensions.addVnDate(dateIn, parseInt(params.lengthOfStay) || 2, 'days'),
      'hotelRoomNight': parseInt(params.lengthOfStay) || 0,
      'hotelNumberOfRoom': parseInt(params.numberOfRoom) || 0,
      'hotelNumberOfAdults': adult || 0,
      'hotelNumberOfChild': child || 0,
      'hotelNumberOfInfant': infant || 0,
      'hotelRoomID': get(rate, 'roomtype.id') || null,
      'hotelRoomName': get(rate, 'roomtype.name') || null,
      'hotelRoomType': get(rate, 'roomtype') || null,
      'hotelRateplanID': get(rate, 'rate.ratePlan.ratePlanId') || null,
      'hotelRateplanName': get(rate, 'rate.ratePlan.name') || null,
      'hotelRateplanPrice': get(rate, 'rate.totalAmount.amount.amount') || 0,
      'hotelTotalPrice': get(rate, 'averageAmount.amount.amount') || 0,
      'hotelTotalPriceBeforeTax': null,
      'hotelTaxPrice': null,
      'hotelBrand': null,
      'hotelURL': hotel.urlSlug || null,
      'hotelTransactionID': null,
      'hotelBookingID': null
    }
  }


  try {
    const data = dataLayerParsers.getData(hotels, getHotelDataLayer)
    // console.log(hotelData, activity, data, ...funcParams)
    const layer = dataLayerParsers[activity](data, ...funcParams);
    dataLayerParsers.remarketingPush(hotels, dataLayerParsers.remarketingParser.hotel, activity, params);
    // console.log('layer ===>', layer);
    return activity === 'checkout' || activity === 'purchase' ? layer : window.dataLayer.push(layer);
  } catch (error) {
    console.warn('GtmDataLayerHotel ERROR:', error);
    return dataLayerParsers.executeCallback(funcParams)
  }
}

export const pushGtmDataLayerVoucher = (vouchers, activity, ...params) => {
  if (!vouchers || !activity || dataLayerParsers.isDisabled()) return dataLayerParsers.executeCallback(params);

  const getVoucherDataLayer = (voucher, index) => ({
    id: voucher.id,
    name: voucher.name,
    price: voucher.price || voucher.salePrice || null,
    brand: (voucher.properties || []).map(item => get(item, 'name') || item).join(','),
    category: pageTypeParser(activity),
    variant: (voucher.products || []).map(item => get(item, 'name') || item).join(','),
    list: index >= 0 ? 'voucher_search_results' : null,
    position: index >= 0 ? index : null,
    quantity: cartUtils.getTotalQuantity(voucher) || null,
    // VPT Custom fields
    'voucherLanguage': voucher.language || getLanguageCookie() || language.Vietnamese,
    'voucherID': voucher.id || null,
    'voucherName': voucher.name || null,
    'voucherPageType': voucher.type || null,
    'voucherType': voucher.type || voucher.voucherType || null,
    'voucherAudience': null,
    'voucherPosition': index >= 0 ? index : null,
    'voucherLocation': (voucher.properties || []).map(item => typeof item === 'string' ? item : item.name).join(','),
    'voucherPrice': voucher.price || voucher.salePrice || null,
    'voucherDiscount': voucher.originalPrice ? 100 - (voucher.originalPrice * 100 / voucher.salePrice) : null,
    'voucherBrand': null,
    'voucherDurationSelling': dataLayerParsers.getDateDuration(voucher.saleStartDate, voucher.saleEndDate),
    'voucherDurationRedeem': dataLayerParsers.getDateDuration(voucher.startDate, voucher.endDate),
    'voucherTotalPrice': null,
    'voucherTaxPrice': null,
    'voucherTransactionID': null,
    'voucherPromotionCode': null,
    'voucherURL': `${routes.VOUCHER_DETAIL}/${voucher.urlSlug || voucher.slug || ''}`,
  });

  try {
    const data = dataLayerParsers.getData(vouchers, getVoucherDataLayer)
    // console.log(vouchers, activity, data, ...params)
    const layer = dataLayerParsers[activity](data, ...params);
    dataLayerParsers.remarketingPush(vouchers, dataLayerParsers.remarketingParser.voucher, activity);
    // console.log('layer ===>', layer)
    return activity === 'checkout' || activity === 'purchase' ? layer : window.dataLayer.push(layer);
  } catch (error) {
    console.warn('GtmDataLayerVoucher ERROR:', error)
    return dataLayerParsers.executeCallback(params)
  }
}

export const pushGtmDataLayerTour = (tours, activity, ...params) => {
  if (!tours || !activity || dataLayerParsers.isDisabled()) return dataLayerParsers.executeCallback(params);

  const getTourDataLayer = (tour, index) => {

    const totalPrice = sumBy(enumType.tourTicketTypeEnum, item =>
      (get(tour, `ticketPrice.${item.value}Quantity`) || 0) * (get(tour, `ticketPrice.${item.value}SalePrice`) || 0))

    return {
      id: tour.id,
      name: get(tour, 'ticketDetail.name') || tour.name,
      price: totalPrice || tour.price || tour.salePrice || null,
      category: pageTypeParser(activity),
      list: index >= 0 ? 'tour_search_results' : null,
      position: index >= 0 ? index : null,
      quantity: cartUtils.getTotalQuantity(tour) || null,
      // VPT Custom fields
      'voucherLanguage': tour.language || getLanguageCookie() || language.Vietnamese,
      'voucherID': tour.id || null,
      'voucherName': get(tour, 'ticketDetail.name') || tour.name || null,
      'voucherPageType': tour.type || null,
      'voucherType': pageTypeParser(activity),
      'voucherAudience': null,
      'voucherPosition': index >= 0 ? index : null,
      'voucherLocation': tour.departure && tour.departure.name,
      'voucherPrice': tour.price || tour.salePrice || null,
      'voucherDiscount': tour.originalPrice ? 100 - (tour.originalPrice * 100 / tour.salePrice) : null,
      'voucherBrand': null,
      'voucherDurationSelling': dataLayerParsers.getDateDuration(tour.saleStartDate, tour.saleEndDate),
      'voucherDurationRedeem': dataLayerParsers.getDateDuration(tour.startDate, tour.endDate),
      'voucherTotalPrice': null,
      'voucherTaxPrice': null,
      'voucherTransactionID': null,
      'voucherPromotionCode': null,
      'voucherURL': `${routes.TOUR_DETAIL}/${tour.urlSlug || tour.slug || ''}`,
    }
  };

  try {
    const data = dataLayerParsers.getData(tours, getTourDataLayer)
    // console.log(tours, activity, data, ...params)
    const layer = dataLayerParsers[activity](data, ...params);
    dataLayerParsers.remarketingPush(tours, dataLayerParsers.remarketingParser.tour, activity);
    // console.log('layer ===>', layer)
    return activity === 'checkout' || activity === 'purchase' ? layer : window.dataLayer.push(layer);
  } catch (error) {
    console.warn('GtmDataLayerTour ERROR:', error)
    return dataLayerParsers.executeCallback(params)
  }

}

export const pushGtmDataLayerVinWonder = (vinWonders, activity, ...params) => {
  if (!vinWonders || !activity || dataLayerParsers.isDisabled()) return dataLayerParsers.executeCallback(params);

  const getTotalPrice = (vinWonder) =>
    (vinWonder.salePrice || 0) * (vinWonder.quantity || 0)
    + (vinWonder.childSalePrice || 0) * (vinWonder.childQuantity || 0)
    + (vinWonder.seniorSalePrice || 0) * (vinWonder.seniorQuantity || 0);

  const getVinWonderDataLayer = (vinWonder, index) => ({
    id: vinWonder.id,
    name: vinWonder.name,
    price: getTotalPrice(vinWonder) || vinWonder.salePrice || vinWonder.price,
    brand: vinWonder.name,
    category: pageTypeParser(activity),
    variant: vinWonder.vinWonderTicketName,
    list: index >= 0 ? 'vinwonder_search_results' : null,
    position: index >= 0 ? index : null,
    quantity: cartUtils.getTotalQuantity(vinWonder) || null,
    // VPT Custom fields
    'voucherLanguage': vinWonder.language || getLanguageCookie() || language.Vietnamese,
    'voucherCurrency': vinWonder.currencyCode || getCurrencyCookie() || currencyUnit.VietnameseDong,
    'voucherID': vinWonder.id || null,
    'voucherName': vinWonder.name || null,
    'voucherPageType': vinWonder.type || null,
    'voucherType': vinWonder.type || vinWonder.voucherType || null,
    'voucherAdults': vinWonder.quantity || 0,
    'voucherChildren': vinWonder.childQuantity || 0,
    'voucherSeniors': vinWonder.seniorQuantity || 0,
    'voucherAudience': null,
    'voucherPosition': index >= 0 ? index : null,
    'voucherLocation': vinWonder.departure && vinWonder.departure.name,
    'voucherPrice': getTotalPrice(vinWonder) || vinWonder.salePrice || vinWonder.price,
    'voucherDiscount': vinWonder.originalPrice ? 100 - (vinWonder.originalPrice * 100 / vinWonder.salePrice) : null,
    'voucherBrand': null,
    'voucherDurationSelling': dataLayerParsers.getDateDuration(vinWonder.saleStartDate, vinWonder.saleEndDate),
    'voucherDurationRedeem': dataLayerParsers.getDateDuration(vinWonder.startDate, vinWonder.endDate),
    'voucherTotalPrice': null,
    'voucherTaxPrice': null,
    'voucherTransactionID': null,
    'voucherPromotionCode': null,
    'voucherURL': `${routes.EXPERIENCE_DETAIL}/${vinWonder.urlSlug || vinWonder.slug || ''}`,
    'voucherUsingDate': datetimeExtensions.formatVnDate(vinWonder.usingDate),
    'voucherInventory': vinWonder.inventory,
    'voucherSiteCode': vinWonder.siteCode,
  });



  try {
    const data = dataLayerParsers.getData(vinWonders, getVinWonderDataLayer)
    // console.log('===== pushGtmDataLayerVinWonder', vinWonders, activity, data, ...params)
    const layer = dataLayerParsers[activity](data, ...params);
    dataLayerParsers.remarketingPush(vinWonders, dataLayerParsers.remarketingParser.experience, activity);
    // console.log('layer ===>', layer)
    return activity === 'checkout' || activity === 'purchase' ? layer : window.dataLayer.push(layer);
  } catch (error) {
    console.warn('GtmDataLayerVinWonder ERROR:', error)
    return dataLayerParsers.executeCallback(params)
  }
}

export const pushGtmDataLayerCheckout = (productData = {}, ...params) => {
  if (dataLayerParsers.isDisabled()) return dataLayerParsers.executeCallback(params)
  
  const { vouchers, tours, flights, hotels, vinWonders, isBundleBooking } = productData;
  try {
    const voucherLayer = vouchers ? pushGtmDataLayerVoucher(vouchers, 'checkout', 'voucher', ...params) : {};
    const tourLayer = tours ? pushGtmDataLayerTour(tours, 'checkout', 'tour', ...params) : {};
    const flightLayer = flights ? pushGtmDataLayerFlight(flights, 'checkout', 'flight', ...params) : {};
    const hotelLayer = hotels ? pushGtmDataLayerHotel(hotels, 'checkout', 'hotel', ...params) : {};
    const vinWonderLayer = vinWonders ? pushGtmDataLayerVinWonder(vinWonders, 'checkout', 'vinWonder', ...params) : {};

    const layer = merge(voucherLayer, merge(tourLayer, flightLayer, hotelLayer, vinWonderLayer));
    if (isBundleBooking) {
      set(layer, 'ecommerce.checkout.products', (get(hotelLayer, 'ecommerce.checkout.products') || []).concat((get(flightLayer, 'ecommerce.checkout.products') || [])))
      dataLayerParsers.mergeBundleProductLayerData(layer, 'checkout', {
        // id: get(hotelLayer, 'ecommerce.checkout.products[0].id'),
        price: hotels.totalPrice,
        list: 'bundle_search_results',
      });
    }
    window.dataLayer.push(layer);
  } catch (error) {
    console.warn('GtmDataLayerCheckout ERROR:', error);
    return dataLayerParsers.executeCallback(params)
  }
}

export const pushGtmDataLayerPurchase = (productData = {}, orderData = {}, ...params) => {
  if (dataLayerParsers.isDisabled()) return dataLayerParsers.executeCallback(params)

  // console.log('===== pushGtmDataLayerPurchase', productData, orderData)
  const { vouchers, tours, flights, hotels, vinWonders, isBundleBooking } = productData;

  const order = {
    ...orderData,
    id: orderData.orderCode,
    affiliation: orderData.paymentType,
    revenue: orderData.subTotal,
    tax: null,
    shipping: null,
    coupon: orderData.couponCode
  };
  try {
    const voucherLayer = vouchers ? pushGtmDataLayerVoucher(vouchers, 'purchase', order, ...params) : {};
    const tourLayer = tours ? pushGtmDataLayerVoucher(tours, 'purchase', order, ...params) : {};
    const flightLayer = flights ? pushGtmDataLayerFlight(flights, 'purchase', order, ...params) : {};
    const hotelLayer = hotels ? pushGtmDataLayerHotel(hotels, 'purchase', order, ...params) : {};
    const vinWonderLayer = vinWonders ? pushGtmDataLayerVinWonder(vinWonders, 'purchase', 'vinWonder', ...params) : {};

    const layer = merge(voucherLayer, merge(tourLayer, flightLayer, hotelLayer, vinWonderLayer));
    if (isBundleBooking) {
      set(layer, 'ecommerce.purchase.products', (get(hotelLayer, 'ecommerce.purchase.products') || []).concat((get(flightLayer, 'ecommerce.purchase.products') || [])))
      dataLayerParsers.mergeBundleProductLayerData(layer, 'purchase', {
        // id: get(hotelLayer, 'ecommerce.purchase.products[0].id'),
        price: hotels.totalPrice,
        list: 'bundle_search_results',
      });
    }
    window.dataLayer.push(layer);
  } catch (error) {
    console.warn('GtmDataLayerPurchase ERROR:', error)
  }
}

export const webVitalsHandle = (metric) => {
  if (dataLayerParsers.isDisabled()) return;
  try {
    if (window.gtag) {
      window.gtag('event', metric.name, {
        event_category: metric.label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
        value: Math.round(metric.name === 'CLS' ? metric.value * 1000 : metric.value), // values must be integers
        event_label: metric.id, // id unique to current page load
        non_interaction: true, // avoids affecting bounce rate.
      })
    }
  } catch (error) {
    console.warn('webVitalsHandle ERROR:', error)
  }
}

const findIndexOfDataLayer = ({ event }) => {
  const layer = window.dataLayer;
  if (!event) return -1;
  return _findIndex(layer, (e) => {
    return e.event == event;
  });
};

export const gtmProductImpressions = ({
  dataProductImpressions = [],
  hotelData = {},
  roomData = {},
  flightData = {},
  comboInfo = {},
  type = gtmType.TOUR_AND_EXPERIENCE,
  listName
}) => {
  // if (isGtmDisabled()) return;
  const layer = window.dataLayer || [];
  let ecommerceObject = {};
  const productUrl = window.location.href;
  const hotelThumbnailUrl = hotelData?.hotel?.media[0]?.url

  switch (type) {
    case gtmType.HOTEL:
      ecommerceObject = createHotelProduceImpresion({
        dataHotelProduceImpresion: dataProductImpressions,
        hotelData,  
        roomData,
      });
      const ecommerceObjectInsider = createHotelProduceImpresion({
        dataHotelProduceImpresion: dataProductImpressions,
        hotelData,  
        roomData,
      });
      break;
    case gtmType.FLIGHT_IMPRESSIONS:
      ecommerceObject = createGtmFlightImpression({
        dataFlightProduceImpresion: dataProductImpressions,
        flightData,
        listName
      });
      break;
    case gtmType.COMBO_IMPRESSIONS:
      ecommerceObject = createGtmComboImpression({
        dataComboImpresion: dataProductImpressions,
        comboInfo
      });
      break;
    case gtmType.TOUR_AND_EXPERIENCE:
    default:
      ecommerceObject = createProductImpressions(dataProductImpressions, listName);
      break;
  }
  // const indexData = findIndexOfDataLayer({
  //   event: gtmEvent.PRODUCT_IMPRESSIONS,
  // });

  // if (indexData > -1) {
  //   const currentImpressions = layer[indexData].ecommerce?.impressions || [];
  //   const newImpressions = _uniqBy(
  //     [...currentImpressions, ...ecommerceObject.ecommerce.impressions],
  //     "id"
  //   );
  //   let newEcommerceObjects = {
  //     ...ecommerceObject,
  //     ecommerce: {
  //       ...ecommerceObject.ecommerce,
  //       impressions: newImpressions,
  //     },
  //   };
  //   layer[indexData] = newEcommerceObjects;
  // } else {
  //   layer.push(ecommerceObject);
  // }
  /**
   * START
   * https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.g107f249d424_0_416
   */
    // Insider Product Impressions
    const ecommerceObjectInsider = cloneDeep(ecommerceObject);
    const productImpressionArray = ecommerceObjectInsider && ecommerceObjectInsider?.ecommerce?.impressions
    const productImpressionArrayResult = productImpressionArray && productImpressionArray.map((el) => ({
      ...el,
      dimension81: productUrl,
      dimension82: hotelThumbnailUrl
    }))
    var currentTime = new Date();    
    if(window?.Insider) {
      window.Insider.track('events', productImpressionArrayResult.map(e => ({
        "event_name": "product_impressions",
        "timestamp": currentTime.toISOString().slice(0, -5)+"Z",
        "event_params": {
          source: "web",
          custom: e
        }
      })))
    };

    layer.push(ecommerceObject);

   /**
    * ---END---
    */
};

export const gtmPushDataProduceClick = ({
  produceClickData = {
    ecommerce: { click: { products: [] } },
  },
}) => {
  // if (isGtmDisabled()) return;
  const layer = window.dataLayer;
  // const isHasDataFindIndex = findIndexOfDataLayer({
  //   event: gtmEvent.PRODUCT_CLICK,
  // });
  // if (isHasDataFindIndex > 0) {
  //   const item = produceClickData.ecommerce.click.products[0];
  //   layer[isHasDataFindIndex].ecommerce.click.products.push(item);
  // } else {
  //   layer.push(produceClickData);
  // }
  // DDAddAction('gtmPushDataProduceClick', produceClickData)
  layer.push(produceClickData);

};

export const gtmPushDataProduceCart = ({
  produceClickData = {
    ecommerce: { add: { products: [] } },
  },
  actionType = "add",
  type = "",
}) => {
  // if (isGtmDisabled()) return;
  const layer = window.dataLayer;
  // const isHasDataFindIndex = findIndexOfDataLayer({
  //   event:
  //     (actionType === type) === gtmType.FLIGHT_ADD_TO_CART
  //       ? gtmEvent.PRODUCT_ADD_TO_CART
  //       : gtmEvent.PRODUCT_CART,
  // });
  // if (isHasDataFindIndex > 0) {
  //   if (actionType === "add") {
  //     const item = produceClickData.ecommerce.add.products[0];
  //     layer[isHasDataFindIndex].ecommerce.add.products.push(item);
  //   }
  //   if (actionType === "remove") {
  //     const item = produceClickData.ecommerce.remove.products[0];
  //     layer[isHasDataFindIndex].ecommerce.remove.products.push(item);
  //   }
  // } else {
  //   layer.push(produceClickData);
  // }
  // DDAddAction('gtmPushDataProduceCart', produceClickData)
  layer.push(produceClickData);
};

// GA360 Produce checkout
export const gtmPushDataProduceCheckout = ({
  produceCheckoutData = {
    ecommerce: { checkout: { products: [] } },
  },
  actionType = "checkout",
}) => {
  // if (isGtmDisabled()) return;
  const layer = window.dataLayer;
  // const isHasDataFindIndex = findIndexOfDataLayer({
  //   event: gtmEvent.PRODUCT_CHECKOUT,
  // });
  // if (isHasDataFindIndex > 0) {
  //   if (actionType === "checkout") {
  //     const item = produceCheckoutData.ecommerce.checkout.products[0];
  //     layer[isHasDataFindIndex].ecommerce.checkout.products.push(item);
  //   }
  //   if (actionType === "cart_checkout") {
  //     layer[isHasDataFindIndex] = produceCheckoutData;
  //   }
  // } else {
  //   layer.push(produceCheckoutData);
  // }
  // DDAddAction('gtmPushDataProduceCart', produceCheckoutData)
  layer.push(produceCheckoutData);
};

export const gtmPushDataSelect = ({ dataSelect = {} }) => {
  // if (isGtmDisabled()) return;
  // DDAddAction('gtmPushDataSelect', dataSelect)
  window.dataLayer.push(dataSelect);
};

export const gtmPushDataNull = () => {
  window.dataLayer.push({ecommerce: null});
};

export const gtmPushDataProduceDetail = ({
  produceDetailData = {
    ecommerce: { detail: { products: [] } },
  },
}) => {
  // if (isGtmDisabled()) return;
  const layer = window.dataLayer;
  // const haveDataDetailIndex = findIndexOfDataLayer({
  //   event: gtmEvent.PRODUCT_DETAIL,
  // });

  // if (haveDataDetailIndex > 0) {
  //   const item = produceDetailData.ecommerce.detail.products[0];
  //   layer[haveDataDetailIndex].ecommerce.detail.products.push(item);
  // } else {
  //   layer.push(produceDetailData);
  // }
  /**
   * START
   * Slide 24
   * https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.gcff0126cdb_0_52
   */
  // DDAddAction('gtmPushDataProduceDetail', produceDetailData)
  layer.push(produceDetailData);
  /**
   * ---END---
   */
};

export const gtmPushDataPurchase = async ({
  purchaseData = {},
}) => {
  // if (isGtmDisabled()) return;
  const layer = window.dataLayer;
  // await DDAddAction('gtmPushDataPurchase', purchaseData)
  layer.push(purchaseData);
};

export const gtmPushProductViewCart = ({
  productViewCart = {}
}) => {
  // if (isGtmDisabled()) return;
  const layer = window.dataLayer;
  // DDAddAction('gtmPushProductViewCart', productViewCart)
  layer.push(productViewCart)
}

export const gtmPushDataSearch = ({
  productSearch = {},
}) => {
  // if (isGtmDisabled()) return;
  const layer = window.dataLayer;
  // DDAddAction('gtmPushDataSearch', productSearch)
  layer.push(productSearch)
};

export const gtmPushDataPageView = ({
  dataPageView = {},
}) => {
  // if (isGtmDisabled()) return;
  const layer = window.dataLayer;
  // DDAddAction('gtmPushDataPageView', dataPageView)
  layer.push(dataPageView)
};
export const gtmPushDataUser = async ({
  dataUser = {},
}) => {
  // if (isGtmDisabled()) return;
  const layer = window.dataLayer;
  if (dataUser?.event === 'login') {
    let dataUserEncrypt = cloneDeep(dataUser);
    dataUserEncrypt.user_property_1_decrypt = decryptAES256({data: dataUser.user_property_1 })
    dataUserEncrypt.user_property_2_decrypt = decryptAES256({data: dataUser.user_property_2 })
    dataUserEncrypt.user_property_3_decrypt = decryptAES256({data: dataUser.user_property_3 })
    // await DDAddAction('gtmPushDataUser', dataUserEncrypt)
  }
  layer.push(dataUser)
};

export const gtmPushViewItem = ({ data = {} }) => {
  window.dataLayer.push(data);
};

export default {
  pushGtmDatalayerFlight: pushGtmDataLayerFlight,
  pushGtmDatalayerHotel: pushGtmDataLayerHotel,
  pushGtmDatalayerVoucher: pushGtmDataLayerVoucher,
  pushGtmDataLayerTour: pushGtmDataLayerTour,
  pushGtmDataLayerVinWonder: pushGtmDataLayerVinWonder,
  pushGtmDataLayerCheckout: pushGtmDataLayerCheckout,
  pushGtmDataLayerPurchase: pushGtmDataLayerPurchase,

  webVitalsHandle: webVitalsHandle,
  dataLayerParsers: dataLayerParsers,

  pushFlightHomePageGTM(data = {}) {
    pushGtmDataLayerFlight({
      language: getLanguageCookie(),
      type: data.returnDate
        ? enumType.itineraryType.Return
        : enumType.itineraryType.OneWay,
      departure: data.startPoint,
      destination: data.endPoint,
      returnDate: data.returnDate,
      departureDate: data.departureDate,
      adults: data.passenger ? Number(data.passenger.adult) : 0,
      children: data.passenger ? Number(data.passenger.child) : 0,
      infant: data.passenger ? Number(data.passenger.infant) : 0
    })
  },

  pushFlightAddToCartGTM(
    {
      flight = {},
      params = {},
      bookingId = null,
      baggage = null
    }) {
    if (flight) {
      const segment = flight.segments && flight.segments.length > 0
        ? flight.segments[0] || {}
        : {}

      const fare = flight.fare || {}

      const adult = params.adult ? Number(params.adult) : 0
      const children = params.children ? Number(params.children) : 0
      const infant = params.infant ? Number(params.infant) : 0

      const {
        totalServiceFee,
        totalTax,
        totalFee,
        totalVptServiceFee
      } = flightUtils.getFlightFee({
        fare: fare,
        infant: infant,
        children: children,
        adult: adult
      })

      pushGtmDataLayerFlight({
        language: getLanguageCookie(),
        type: params.returnDate
          ? enumType.itineraryType.Return
          : enumType.itineraryType.OneWay,
        departure: segment.startPoint,
        destination: segment.endPoint,
        departureDate: datetimeExtensions.formatVnDate(segment.startDate),
        returnDate: datetimeExtensions.formatVnDate(segment.endDate),
        departureTime: datetimeExtensions.formatOnlyTime(segment.startDate),
        returnTime: datetimeExtensions.formatOnlyTime(segment.endDate),
        adults: adult,
        children: children,
        infant: infant,
        branch: segment.airlineName,
        bookingId: bookingId,
        flightNumber: segment.flightNumber,
        amount: fare.totalPrice,
        ticketPrice: fare.priceAdult,
        tax: totalTax,
        airportCharge: totalFee,
        serviceCharge: totalServiceFee,
        ticketCharge: totalVptServiceFee,
        baggage: baggage
      })
    }
  },

  pushFlightPaymentGTM(currentFlight = {}, dataSubmit = {}) {
    const {
      baggageDeparture,
      baggageReturn
    } = flightUtils.getBaggageTotal(dataSubmit.listPassengers)

    const {
      departureFlights,
      returnFlights
    } = currentFlight

    // 1. push departure flight
    if (departureFlights) {
      this.pushFlightAddToCartGTM({
        flight: {
          ...departureFlights,
          segments: departureFlights.listSegment,
          fare: departureFlights.fareOptions &&
            departureFlights.fareOptions.length > 0
            ? departureFlights.fareOptions[0]
            : null
        },
        params: currentFlight,
        bookingId: currentFlight.dataSession,
        baggage: baggageDeparture
      })
    }

    // 2. push return flight
    if (returnFlights) {
      this.pushFlightAddToCartGTM({
        flight: {
          ...returnFlights,
          segments: returnFlights.listSegment,
          fare: returnFlights.fareOptions && returnFlights.fareOptions.length > 0
            ? returnFlights.fareOptions[0]
            : null
        },
        params: currentFlight,
        bookingId: currentFlight.dataSession,
        baggage: baggageReturn
      })
    }
  },

  pushHotelHomePageGTM(data = {}) {
    const startPoint = data.startPoint || {}
    const hotelId = startPoint.type === enumType.locationType.Hotel
      ? startPoint.hotelId
      : null
    // pushGtmDataLayerHotel({
    //   language: getLanguageCookie(),
    //   adults: data.passenger ? Number(data.passenger.adult) : 0,
    //   children: data.passenger ? Number(data.passenger.child) : 0,
    //   infant: data.passenger ? Number(data.passenger.infant) : 0,
    //   dateIn: data.departureDate,
    //   dateOut: data.returnDate,
    //   hotelId: hotelId,
    //   hotelName: startPoint.type === enumType.locationType.Hotel
    //     ? startPoint.hotelName
    //     : null,
    //   hotelLocation: startPoint.locationName,
    //   roomNight: Number(data.lengthOfStay)
    // })
  },

  pushHotelDetailGTM(
    {
      params = {},
      hotel = {},
      rate = {}
    }) {
    // pushGtmDataLayerHotel({
    //   language: getLanguageCookie(),
    //   adults: Number(params.ha),
    //   children: Number(params.hc),
    //   infant: Number(params.hi),
    //   dateIn: params.hdd,
    //   dateOut: datetimeExtensions.addVnDate(params.hdd, params.hs, 'days'),
    //   hotelId: hotel.id,
    //   hotelLocation: hotel.address,
    //   hotelName: hotel.name,
    //   roomNight: Number(params.hs),
    //   roomName: rate.nameRoom,
    //   type: rate.roomTypeId,
    //   amount: rate.totalPrice
    // })
  },

  pushVoucherHomePageGTM(data = {}) {
    // pushGtmDataLayerVoucher({
    //   language: getLanguageCookie(),
    //   ...data
    // })
  },

  pushComboHomePageGTM(data = {}) {
    const {
      hotel,
      flight
    } = data
    this.pushFlightHomePageGTM(flight)
    // this.pushHotelHomePageGTM(hotel)
  }
}
