export const convertCurrencyFollow = (money = 1, to = 'USD', rateCurrencies = {})=>{
    if(!rateCurrencies){
        return null;
    }
    let from = 'VND';
    if(to === 'VND'){
        return null;
    }
    const rateUsdFrom = rateCurrencies[from];
    const rateUsdTo = rateCurrencies[to];
    if(rateUsdFrom && rateUsdTo){
        return Math.round(money*(rateUsdTo/rateUsdFrom));
    }
    return null;
}

export default {
    convertCurrencyFollow: convertCurrencyFollow
}