import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { DEFAULT_FORMAT_DATE } from '../../constants'
import localeVi from 'antd/lib/locale/vi_VN'
import localeEn from 'antd/lib/locale/en_US'
import localeKo from 'antd/lib/locale/ko_KR'
import localeZh from 'antd/lib/locale/zh_CN';
import { useGlobalStore } from 'src/hooks/useGlobal'
import { language } from 'src/constants/language'
import "./RangePicker.scss";

const range = (start, end) => {
  const result = []
  for (let index = start; index < end; index++) {
    result.push(index)
  }
  return result
}

const RangeTimePicker = (props, ref) => {
  const {
    customClass,
    value,
    disabled,
    onChange,
    onBlur,
    timePicker,
    minDate,
    maxDate,
    formatDateInput,
    formatDateOutput,
    allowClear,
    placeholder,
    onFocus,
    icon = require('@assets/include/svg/calendar.svg').default,
    separator,
    getPopupContainer,
    dropdownClassName,
    renderExtraFooter,
    onOpenChange,
    isMobileMedium
  } = props

  const [currentDate, setCurrentDate] = useState(null)
  const [currentDateDeparture, setCurrentDateDeparture] = useState(null)
  const [currentDateReturn, setCurrentDateReturn] = useState(null)
  const dateRangeRef = useRef(null)
  const { state } = useGlobalStore();

  /**
   * update state when props change
   */
  useEffect(
    () => {
      setCurrentDateDeparture(formatDateSelected(value[0]))
      setCurrentDateReturn(formatDateSelected(value[1]))
    }, [value])

  /**
   * reformat date selected
   * @param dateSelected
   */
  const formatDateSelected = dateSelected => {
    return dateSelected
      ? moment(dateSelected, formatDateOutput)
      : null
  }

  /**
   * handle change date
   * @param value
   */
  const handleChangeDate = (values, datestring) => {

    const dateDepart = formatDateSelected(values[0])
    const dateReturn = formatDateSelected(values[1])

    setCurrentDateDeparture(dateDepart)
    setCurrentDateReturn(dateReturn)

    if (onChange) {
      if (dateDepart && dateReturn) {
        onChange(moment(dateDepart, datestring).format(formatDateOutput), moment(dateReturn, datestring).format(formatDateOutput))
      } else {
        onChange(null)
      }
    }
  }

  /**
   * handle blur date
   */
  const handleBlurDate = () => {
    if (onBlur) {
      onBlur()
    }
  }

  const handleFocusDate = () => {
    if (onFocus) {
      onFocus()
    }
  }

  /**
   * disable date
   * @param current
   */
  const disabledDate = current => {
    // return false is enable
    // return true is disable

    if (!maxDate && !minDate) {
      return false
    }

    // can not select days before min day
    if (minDate && !maxDate) {
      return current < moment(minDate).startOf('days')
    }

    // can not select days after max day
    if (maxDate && !minDate) {
      return current > moment(maxDate).endOf('days')
    }

    return !(
      current >= moment(minDate).startOf('days') &&
      current <= moment(maxDate).endOf('days')
    )
  }

  /**
   * disable time
   */
  const disabledDateTime = () => {
    let enableHour = []
    let enableMinute = []
    let enableSecond = []

    if (minDate) {
      const momentMinDate = moment(minDate)

      enableHour.push(momentMinDate.hour())
      enableMinute.push(momentMinDate.minute())
      enableSecond.push(momentMinDate.second())
    }

    if (maxDate) {
      const momentMaxDate = moment(maxDate)

      enableHour.push(momentMaxDate.hour())
      enableMinute.push(momentMaxDate.minute())
      enableSecond.push(momentMaxDate.second())
    }

    const hourRange = range(0, 24)
    const enableHourRange = hourRange.splice(enableHour)

    const minuteRange = range(0, 60)
    const enableMinuteRange = minuteRange.splice(enableMinute)

    const secondRange = range(0, 60)
    const enableSecondRange = secondRange.splice(enableSecond)

    return {
      disabledHours: () =>
        hourRange.filter(hour => !enableHourRange.includes(hour)),
      disabledMinutes: () => [],
      disabledSeconds: () => []
    }
  }

  useImperativeHandle(ref, () => ({
    open: () => {
      if (dateRangeRef.current && !document.getElementsByClassName('vpt-date-range')?.length) {
        dateRangeRef.current.focus();
        dateRangeRef.current.picker?.picker?.lastChild?.firstChild?.click();
      };
    }
  }));

  const customDateFormat = formatDateInput ? formatDateInput : DEFAULT_FORMAT_DATE

  const locale = useMemo(() => {
    let _locale;
    switch (state.lang) {
      case language.Vietnamese:
        _locale = localeVi.DatePicker;
        break;
      case language.English:
        _locale = localeEn.DatePicker;
        break;
      case language.Korean:
        _locale = localeKo.DatePicker;
        break;
      case language.Chinese:
        _locale = localeZh.DatePicker;
        break;
      default:
        _locale = localeVi.DatePicker;
    }

    _locale.lang.monthFormat = "MMMM";
    _locale.lang.dayFormat = "DD";
    moment.updateLocale('en', {
      weekdaysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    });
    return _locale;
  }, [state.lang])

  // custom trigger next month button for mobile when only show one month in RangePicker
  const registerNextMonthForMobile = () => {
    setTimeout(() => {
      const classBtn = "ant-calendar-next-month-btn";
      const nextBtns = document.getElementsByClassName(classBtn);
      if (nextBtns?.length) {
        nextBtns[nextBtns.length - 1].addEventListener("click", (e) => {
          setTimeout(() => {
            document.getElementsByClassName(classBtn)?.[0]?.click();
          }, 100);
        });
      }
    }, 200);
  }
  const handleOpenChange = (e) => {
    if (e && isMobileMedium) registerNextMonthForMobile();
    if (onOpenChange) onOpenChange(e);
  }

  return (
    <DatePicker.RangePicker
      ref={dateRangeRef}
      placeholder={placeholder}
      className={clsx({
        'vpt-date-picker': true,
      }, customClass)}
      dropdownClassName={clsx('vpt-date-picker__calendar vpt-date-range', dropdownClassName, { "vpt-date-picker-mobile": isMobileMedium })}
      locale={locale}
      disabled={disabled}
      value={[currentDateDeparture, currentDateReturn]}
      format={customDateFormat}
      allowClear={allowClear}
      showTime={timePicker === true}
      suffixIcon={icon ? <img src={icon} alt='calendar' /> : <div></div>}
      onChange={handleChangeDate}
      onBlur={handleBlurDate}
      disabledDate={disabledDate}
      disabledTime={disabledDateTime}
      separator={separator || "-"}
      getCalendarContainer={getPopupContainer}
      renderExtraFooter={renderExtraFooter}
      onOpenChange={handleOpenChange}
    />
  )
}

RangeTimePicker.propTypes = {
  value: PropTypes.array,
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
  formatDateOutput: PropTypes.string,
  formatDateInput: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  timePicker: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  allowClear: PropTypes.bool,
  placeholder: PropTypes.any,
  onFocus: PropTypes.func,
  getPopupContainer: PropTypes.func,
  dropdownClassName: PropTypes.string,
  renderExtraFooter: PropTypes.func,
  onOpenChange: PropTypes.func,
  isMobileMedium: PropTypes.bool
}

export default forwardRef(RangeTimePicker)
