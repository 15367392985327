import { sumBy } from "lodash";
import moment from "moment";
import { enumType, FORMAT_ONLY_DATE } from "src/constants";
import { gtmEvent } from "src/helpers/gtmEvent";

export const createHotelSearch = ({ dataSearch = {} }) => {
  let search_hotel_name = "";
  let search_destination = "";
  let search_term = dataSearch?.startPointName;
  if (dataSearch?.startPoint?.type === enumType.locationType.Hotel) {
    search_hotel_name = dataSearch?.startPoint?.hotelName;
    search_term = dataSearch?.startPoint?.hotelName;
  }
  if (dataSearch?.startPoint?.type === enumType.locationType.Location) {
    search_destination = dataSearch?.startPoint?.locationName;
    search_term = dataSearch?.startPoint?.locationName;
  }
  return {
    event: gtmEvent.SEARCH_HOTEL,
    search_term,
    search_hotel_name,
    search_destination,
    search_start_date: moment(dataSearch?.departureDate).isValid()
      ? moment(dataSearch?.departureDate).format(FORMAT_ONLY_DATE)
      : dataSearch?.departureDate,
    search_end_date: moment(dataSearch?.returnDate).isValid()
      ? moment(dataSearch?.returnDate).format(FORMAT_ONLY_DATE)
      : dataSearch?.returnDate,
    search_no_of_adults: sumBy(dataSearch?.passenger, (el) => Number(el.adult)),
    search_no_of_children: sumBy(dataSearch?.passenger, (el) =>
      Number(el.child)
    ),
    search_no_of_infants: sumBy(dataSearch?.passenger, (el) =>
      Number(el.infant)
    ),
    search_no_of_rooms: dataSearch?.numberOfRoom,
    search_room_night: dataSearch?.lengthOfStay,
    search_promotion_code: dataSearch?.promotionCode,
  };
};

export const createSKUSearch = ({ dataSearch = {} }) => {
  let search_hotel_name = "";
  let search_destination = "";
  if(dataSearch.currentLocation.code === enumType.locationCode.Location){
    search_hotel_name = dataSearch.currentLocation.name; 
    search_destination = '';
  }
  if(dataSearch.currentLocation.code === enumType.locationCode.Destination){
    search_hotel_name = ''; 
    search_destination = dataSearch.currentLocation.name;
  }
  return {
    event: gtmEvent.SEARCH_TOUR,
    search_term: dataSearch.keyword,
    search_hotel_name,
    search_destination,
    // search_destination: dataSearch.currentLocation.name,
  };
};
export const createComboSearch = ({ dataSearch = {} }) => {
  return {
    event: gtmEvent.SEARCH_COMBO,
    search_origin: dataSearch?.startPoint.name,
    search_destination: dataSearch?.endPoint.name,
    search_start_date: dataSearch?.departureDate,
    search_end_date: dataSearch?.returnDate,
    search_no_of_adults: sumBy(dataSearch?.passenger, (el) => Number(el.adult)),
    search_no_of_children: sumBy(dataSearch?.passenger, (el) =>
      Number(el.child)
    ),
    search_no_of_infants: sumBy(dataSearch?.passenger, (el) =>
      Number(el.infant)
    ),
    search_no_of_rooms: dataSearch?.numberOfRoom,
    search_room_night: dataSearch?.lengthOfStay,
  };
};
export const createFlightSearch = ({ dataSearch = {} }) => {
  return {
    event: gtmEvent.SEARCH_FLIGHT,
    search_origin: dataSearch?.startPoint,
    search_destination: dataSearch?.endPoint,
    search_start_date: dataSearch?.departureDate,
    search_end_date: dataSearch?.returnDate,
    search_no_of_adults: dataSearch?.passenger?.adult,
    search_no_of_children: dataSearch?.passenger?.child,
    search_no_of_infants: dataSearch?.passenger?.infant,
    search_lowest_fare: dataSearch?.searchMonth,
    search_journey_type:
      dataSearch?.itineraryType === enumType.itineraryType.OneWay
        ? "Một chiều"
        : "Khứ hồi",
  };
};
