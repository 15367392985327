/**
 * Hotel
 * ---------------------------------------------
 * */

import _get from "lodash/get";
import moment from "moment";
import { FORMAT_ONLY_DATE, FORMAT_ONLY_DATE_DOT } from "src/constants";
import { gtmEvent, TBD } from "src/helpers/gtmEvent";
import { hotelUtils } from "..";
import { createRoomOccupancy, getMinPriceRate } from "../gtmHelpers";
import { ENUM_HOTEL } from "./enumGtm";

export const createHotelProduceImpresion = ({
  dataHotelProduceImpresion = [],
  hotelData = {},
  roomData = {},
}) => {
  const hotel = hotelData?.hotel;
  const hotelParams = hotelData?.params;
  // const hotelFilter = hotelData?.filter;
  const arrivalDate = moment(hotelParams?.arrivalDate);
  const departureDate = moment(hotelParams?.arrivalDate).add(
    hotelParams?.lengthOfStay || 0,
    "days"
  );
  const bookingRoomNight = hotelUtils.getLengthOfStay(
    arrivalDate.format(FORMAT_ONLY_DATE_DOT),
    departureDate.format(FORMAT_ONLY_DATE_DOT)
  );

  /**
   * Fixed 30/12/2021
   * https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.gcff0126cdb_0_66
   */
  const { adult, child, infant, passenger } = createRoomOccupancy({
    roomOccupancy: hotelParams?.roomOccupancy,
  });
  const productImpressionArray = dataHotelProduceImpresion.map((elm, index) => {
    const price = getMinPriceRate(elm.rates);
    return {
      /**
       * START
       * Slide 29
       * https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.gcff0126cdb_0_2
       */
      name: `Hotel: ${hotel?.name}-${_get(elm, "roomtype.name")}`,
      // ---END---
      id: _get(elm, "roomtype.hotelId"),
      price,
      brand: hotel?.name,
      category: ENUM_HOTEL.category,
      list: ENUM_HOTEL.list,
      position: elm?.position,
      dimension29: hotelParams?.hsp,
      /**
       * START
       * https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.gcff0126cdb_0_27
       */
      dimension30: TBD,
      /**
       * ---END---
       */
      dimension31: price,
      dimension32: 0, // "product_discount",
      dimension39: arrivalDate.format(FORMAT_ONLY_DATE),
      dimension40: departureDate.format(FORMAT_ONLY_DATE),
      dimension41: hotel?.name,
      dimension42: hotel?.id,
      dimension43: hotel?.name,
      dimension44: hotel?.rating,
      dimension45: hotel?.address,
      dimension46: _get(elm, "roomtype.name"),
      dimension47: _get(elm, "roomtype.id"),
      dimension48: 0, // "room_area"
      dimension49: _get(elm, "roomtype.defaultOccupancy"),
    };
  });

  const ecommerceObject = {
    ecommerce: { impressions: productImpressionArray },
    event: gtmEvent.PRODUCT_IMPRESSIONS,
    booking_service_type: ENUM_HOTEL.category,
    booking_destination: hotel?.name,
    booking_start_date: arrivalDate.format(FORMAT_ONLY_DATE),
    booking_end_date: departureDate.format(FORMAT_ONLY_DATE),
    booking_no_of_adults: adult,
    booking_no_of_children: child,
    booking_no_of_infants: infant,
    booking_no_of_rooms: hotelParams?.numberOfRoom,
    booking_room_night: bookingRoomNight,
  };
  return ecommerceObject;
};

export const createHotelProduceHotelClick = ({
  dataRoom = {
    rates: [],
    roomOccupancy: {},
    roomtype: {},
  },
  index = 0,
  hotelData = {
    hotel: {},
    urlParam: {},
  },
}) => {
  const hotel = hotelData?.hotel;
  const hotelParams = hotelData?.urlParam;
  const roomData = dataRoom?.roomOccupancy;

  const arrivalDate = moment(hotelParams?.arrivalDate);
  const departureDate = moment(hotelParams?.arrivalDate).add(
    hotelParams?.lengthOfStay || 0,
    "days"
  );
  const bookingRoomNight = hotelUtils.getLengthOfStay(
    arrivalDate.format(FORMAT_ONLY_DATE_DOT),
    departureDate.format(FORMAT_ONLY_DATE_DOT)
  );

  /**
   * Fixed 30/12/2021
   * https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.gcff0126cdb_0_66
   */
  const { adult, child, infant, passenger } = createRoomOccupancy({
    roomOccupancy: hotelParams?.roomOccupancy,
  });

  /**
   * fix price
   * 06/01/2022
   */
  const price = getMinPriceRate(dataRoom.rates || []);

  const productClickedArray = [
    {
      name: `Hotel: ${hotel?.name}-${_get(dataRoom, "roomtype.name")}`,
      id: _get(dataRoom, "roomtype.hotelId") || null,
      price,
      brand: hotel?.name,
      category: ENUM_HOTEL.category,
      list: ENUM_HOTEL.list,
      position: index + 1,
      dimension29: hotelParams?.hsp,
      // https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.gcff0126cdb_0_27
      dimension30: TBD,
      //"product_original_price",
      dimension31: price,
      dimension32: 0,
      dimension39: arrivalDate.format(FORMAT_ONLY_DATE),
      dimension40: departureDate.format(FORMAT_ONLY_DATE),
      dimension41: hotel?.name,
      dimension42: hotel?.id,
      dimension43: hotel?.name,
      dimension44: hotel?.rating,
      dimension45: hotel?.address,
      dimension46: _get(dataRoom, "roomtype.name"),
      dimension47: _get(dataRoom, "roomtype.id"),
      dimension48: 0,
      dimension49: _get(dataRoom, "roomtype.defaultOccupancy"),
    },
  ];

  const ecommerceObject = {
    ecommerce: {
      click: {
        products: productClickedArray,
        actionField: { list: ENUM_HOTEL.list },
      },
    },
    event: gtmEvent.PRODUCT_CLICK,
    booking_service_type: ENUM_HOTEL.category,
    booking_destination: hotel?.name,
    booking_start_date: arrivalDate.format(FORMAT_ONLY_DATE),
    booking_end_date: departureDate.format(FORMAT_ONLY_DATE),
    booking_no_of_adults: adult,
    booking_no_of_children: child,
    booking_no_of_infants: infant,
    booking_no_of_rooms: hotelParams?.numberOfRoom,
    booking_room_night: bookingRoomNight,
  };
  return ecommerceObject;
};

export const createGtmHotelProduceDetail = ({
  dataRoom = {
    rates: [],
    roomOccupancy: {},
    roomtype: {},
  },
  index = 0,
  hotelData = {
    hotel: {},
    urlParam: {},
  },
}) => {
  const hotel = hotelData?.hotel;
  const hotelParams = hotelData?.urlParam;
  const roomData = dataRoom?.roomOccupancy;

  const arrivalDate = moment(hotelParams?.arrivalDate);
  const departureDate = moment(hotelParams?.arrivalDate).add(
    hotelParams?.lengthOfStay || 0,
    "days"
  );

  /**
   * Fixed 30/12/2021
   * https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.gcff0126cdb_0_66
   */
  const { adult, child, infant, passenger } = createRoomOccupancy({
    roomOccupancy: hotelParams?.roomOccupancy,
  });

  const bookingRoomNight = hotelUtils.getLengthOfStay(
    arrivalDate.format(FORMAT_ONLY_DATE_DOT),
    departureDate.format(FORMAT_ONLY_DATE_DOT)
  );
  const price = getMinPriceRate(dataRoom.rates || []);

  const productDetailArray = [
    {
      name: `Hotel: ${hotel?.name}-${_get(dataRoom, "roomtype.name")}`,
      id: _get(dataRoom, "roomtype.hotelId") || null,
      price,

      brand: hotel?.name,
      category: ENUM_HOTEL.category,
      dimension29: hotelParams?.hsp,
      // https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.gcff0126cdb_0_27
      dimension30: TBD,
      //"product_original_price",
      dimension31: price,
      dimension32: 0,
      dimension39: arrivalDate.format(FORMAT_ONLY_DATE),
      dimension40: departureDate.format(FORMAT_ONLY_DATE),
      dimension41: hotel?.name,
      dimension42: hotel?.id,
      dimension43: hotel?.name,
      dimension44: hotel?.rating,
      dimension45: hotel?.address,
      dimension46: _get(dataRoom, "roomtype.name"),
      dimension47: _get(dataRoom, "roomtype.id"),
      dimension48: 0,
      dimension49: _get(dataRoom, "roomtype.defaultOccupancy"),
    },
  ];

  const ecommerceObject = {
    event: gtmEvent.PRODUCT_DETAIL,
    booking_service_type: ENUM_HOTEL.category,
    booking_destination: hotel?.name,
    booking_start_date: arrivalDate.format(FORMAT_ONLY_DATE),
    booking_end_date: departureDate.format(FORMAT_ONLY_DATE),
    booking_no_of_adults: adult,
    booking_no_of_children: child,
    booking_no_of_infants: infant,
    booking_no_of_rooms: hotelParams?.numberOfRoom,
    booking_room_night: bookingRoomNight,
    ecommerce: {
      detail: {
        products: productDetailArray,
      },
    },
  };
  return ecommerceObject;
};

export const createHotelCart = ({ data = {}, action = "add" }) => {
  let productAddToCartArray = [];
  let ecommerceObject = {};

  if (!!data && typeof data === "object") {
    const hotelParams = data?.urlParam || {};
    const hotel = data?.hotel;
    const { adult, child, infant, passenger } = createRoomOccupancy({
      roomOccupancy: hotelParams?.roomOccupancy,
    });

    const arrivalDate = moment(hotelParams?.arrivalDate);
    const departureDate = moment(hotelParams?.arrivalDate).add(
      hotelParams?.lengthOfStay || 0,
      "days"
    );
    const bookingRoomNight = hotelUtils.getLengthOfStay(
      arrivalDate.format(FORMAT_ONLY_DATE_DOT),
      departureDate.format(FORMAT_ONLY_DATE_DOT)
    );
    const arrData = Object.values(data?.dataRooms);
    productAddToCartArray = arrData.map((elm) => {
      return {
        name: `Hotel: ${hotel?.name}-${_get(elm, "roomtype.name")}`,
        id: _get(elm, "roomtype.hotelId") || null,
        price: _get(elm, "rate.averageAmount.amount"),
        brand: _get(elm, "roomtype.name"),
        category: ENUM_HOTEL.category,
        /**
         * START
         * Slide 34
         * https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.gcff0126cdb_0_85
         */
        variant: elm?.rate?.ratePlan?.description || null,
        /*** ---END---*/
        /**
         * SLIDE 37-38
         * 05/01/2022
         * https://docs.google.com/presentation/d/1PurF7QwvK8BmFessuAeaL0LiLiDLEMoxhdKZ_sqdUeY/edit#slide=id.gf7b9c8776b_0_1176
         */
        quantity: bookingRoomNight,
        // END
        dimension29: hotel?.address,
        // https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.gcff0126cdb_0_27
        dimension30: TBD,
        dimension31: _get(elm, "rate.averageAmount.amount"),
        dimension32: 0, // "product_discount",
        dimension39: arrivalDate.format(FORMAT_ONLY_DATE),
        dimension40: departureDate.format(FORMAT_ONLY_DATE),
        dimension41: hotel?.name,
        dimension42: hotel?.id,
        dimension43: hotel?.name,
        dimension44: hotel?.rating,
        dimension45: hotel?.address,
        dimension46: _get(elm, "roomtype.name"),
        dimension47: _get(elm, "roomtype.id"),
        dimension48: elm.roomtype.area, // "room_area"
        dimension49: _get(elm, "roomtype.defaultOccupancy"),
      };
    });

    let ecommerce = {};
    switch (action) {
      case "checkout":
        ecommerce = {
          checkout: {
            products: productAddToCartArray,
            actionField: { step: 1 },
          },
        };
        break;
      case "remove":
        ecommerce = {
          remove: {
            products: productAddToCartArray,
          },
        };
        break;
      case "add":
      default:
        ecommerce = {
          add: {
            products: productAddToCartArray,
          },
        };
        break;
    }

    ecommerceObject = {
      event:
        action === "checkout"
          ? gtmEvent.PRODUCT_CHECKOUT
          : gtmEvent.PRODUCT_ADD_TO_CART,
      // booking_service_type: "booking_service_type",
      booking_service_type: ENUM_HOTEL.category,
      // booking_destination: "booking_destination",
      booking_destination: hotel?.name,
      // booking_start_date: "booking_start_date",
      booking_start_date: arrivalDate.format(FORMAT_ONLY_DATE),
      // booking_end_date: "booking_end_date",
      booking_end_date: departureDate.format(FORMAT_ONLY_DATE),
      // booking_no_of_adults: "booking_no_of_adults",
      booking_no_of_adults: adult,
      // booking_no_of_children: "booking_no_of_children",
      booking_no_of_children: child,
      // booking_no_of_infants: "booking_no_of_infants",
      booking_no_of_infants: infant,
      // booking_no_of_rooms: "booking_no_of_rooms",
      booking_no_of_rooms:
        hotelParams?.numberOfRoom || productAddToCartArray.length,
      // booking_room_night: "booking_room_night",
      booking_room_night: bookingRoomNight,
      ecommerce,
    };
  }

  return ecommerceObject;
};
