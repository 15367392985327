import React from "react"

// lib
import PropTypes from "prop-types"
import { connectModal } from "redux-modal"

// component
import { Button, Modal } from "antd"

const ErrorModal = props => {
  const { show, handleHide, message } = props

  return (
    <Modal
      title={null}
      footer={null}
      maskClosable={false}
      closable={false}
      wrapClassName='vpt-error-modal'
      visible={show}
      onOk={handleHide}
      onCancel={handleHide}
    >
      <div className='text-center vpt-form'>
        <div className='g-mb-15'>{message}</div>
        <Button
          className='g-bg-primary g-brd-primary g-color-white'
          onClick={handleHide}
        >
          <FormattedMessage
            id="Modal.ErrorModal.Buttom.Closed"
            defaultMessage='Đóng'
          />
        </Button>
      </div>
    </Modal>
  )
}

ErrorModal.propTypes = {
  handleHide: PropTypes.func.isRequired
}

export default connectModal({ name: "authErrorModal", destroyOnHide: true })(ErrorModal)
