import React from 'react'
import { FormattedMessage } from 'react-intl.macro'

export const language = {
  Vietnamese: 'vi',
  English: 'en',
  Korean: 'ko',
  Chinese: 'zh'
}

export const country = {
  Vietnamese: 'VN',
  English: 'US',
  Korean: 'KR'
}

export const languagePath = ['vi', 'en', 'ko', 'zh']

export const languageEnum = [
  {
    value: language.Vietnamese,
    label: <FormattedMessage
      id="Lang.Vietnamese"
      defaultMessage="Tiếng Việt"
    />,
    icon: require('@assets/include/svg/vietnamese.svg').default
  },
  {
    value: language.English,
    label: <FormattedMessage
      id="Lang.English"
      defaultMessage="English"
    />,
    icon: require('@assets/include/svg/united-kingdom.svg').default
  },
  {
    value: language.Korean,
    label: <FormattedMessage
      id="Lang.Korean"
      defaultMessage="Tiếng Hàn"
    />,
    icon: require('@assets/include/svg/south-korea.svg').default
  },
  {
    value: language.Chinese,
    label: <FormattedMessage
      id="Lang.Chinese"
      defaultMessage="Tiếng Trung Quốc"
    />,
    icon: require('@assets/include/svg/chinese.svg').default
  }
]