import Router from '@utils/router'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Link from '@utils/router/link'
import { Icon, Menu, Dropdown, Spin } from 'antd'
import clsx from 'clsx'
import { GoogleLogout } from 'react-google-login'
import { FormattedMessage } from 'react-intl.macro'
import { getConfig } from '@config'
// actions
import { loadCartAction } from '../../actions/carts'
import { getSiteInfos, getIpLocale } from '../../actions/siteInfoAction'
import { getCurrencyRate } from '../../actions/currencyAction'

//constant
import { featureFlags, nav, routes, TOKEN_TIMEOUT, LOCAL_STORAGE_KEY, PageToShowLoginPopups } from '../../constants'

// hook
import { logout, refresh } from '../../actions/authAction'
import { usePrevious } from '../../hooks/usePrevious'
import {
  savePathRedirect
} from '../../hooks/bundle'
import { getProfileCallback } from 'src/hooks/auth'

// reselect
import { selectIsChangeToken } from '../../reselect/authSelector'

// extension
import datetimeExtensions from '../../extensions/datetime'

// utils
import { getAuthCookies } from '../../utils/auth'
import { setActiveLink } from 'react-scroll/modules/mixins/scroller'
import { navMyProfile } from '../../constants/nav'
import { getLoginAuth0, getRegisterAuth0 } from "../../extensions/querystring";
import { selectLoadingAuth } from '../../reselect/fetchingSelector'

import { LocalStorage } from '@utils/'
import gtmAuth from 'src/hooks/gtm/gtmAuth'
import { gtmType } from 'src/helpers/gtmEvent'

import { useGlobalStore } from '../../hooks/useGlobal';
import { language } from '../../constants/language';
import { checkRoleCollaborators } from '@utils/common'
import signOutIcon from 'src/assets/img/icons/signOutIcon.svg'
import './LoginContainer.scss'
import { useLocation } from 'react-router-dom'
import ModalSendSuccess from '../LoginGetPromotion/ModalSendSuccess'
import ForgotPasswordModal from '../LoginGetPromotion/ForgotPasswordModal'
import ModalLogin from 'src/pages/login-vpt/ModalLogin'

const {
  publicRuntimeConfig: {
    GOOGLE_CLIENT_ID
  } // Available both client and server side
} = getConfig()

let isGetProfile = false

class MenuItem extends React.Component {
  constructor(props) {
    super(props)
    this.logoutGoogle = this.logoutGoogle.bind(this)
  }

  logoutGoogle = (res) => {
    // const {item} = this.props
  }

  render() {
    const { item } = this.props
    const content = (
      <a
        id={item.id}
        className={clsx({
          'u-link-v5 g-my-5 g-my-0--md w-100 d-flex text-nowrap': true,
          'vpt-height-auto g-width-110--md g-rounded-2 g-py-2': item.isButton,
          [`${item.customClass}`]: item.customClass
        })}
        onClick={() => {
          if (item.onClick) {
            item.onClick()
          } else if (item.route) {
            Router.push(item.route)
          }
        }}
      >
        {
          item.icon
            ? <div className={clsx('g-mr-5 g-mr-10', item.iconClass)}>
              {item.icon}
            </div>
            : null
        }
        {
          item.iconMenuMobile
            ? <div className={
              clsx({
                'd-md-none': true,
                'g-mr-10': !!item.iconMenuMobile
              })
            }>
              {item.iconMenuMobile}
            </div>
            : null
        }

        {!!item.flag
          ? <span
            className="u-badge-v1 g-color-white g-bg-red g-rounded-50x flag-number-cart">
            {String(item.flag || '')}
          </span>
          : null
        }
        {item.title}
      </a>
    )
    return (
      <div
        className={clsx({
          'rounded-0': true,
          [`${item.menuClass}`]: !!item.menuClass
        })}
      >
        {
          item.key === 'logout'
            ? (
              <GoogleLogout
                clientId={GOOGLE_CLIENT_ID}
                buttonText="Logout"
                render={renderProps => (
                  <div onClick={renderProps.onClick}>
                    {content}
                  </div>
                )}
                onLogoutSuccess={this.logoutGoogle}
              >
              </GoogleLogout>
            )
            : (content)
        }
      </div>
    )
  }
}

const LoginContainer = ({ containerSelector, onToggleMenu, isHomepage = false, isHomstay = false }) => {

  const [authInfo, setAuthInfo] = useState({})
  const [refreshToken, setRefreshToken] = useState(null)
  const [visible, setVisible] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);
  const [visibleSuccess, setVisibleSuccess] = useState(false);
  const [isLoginPopup, setIsLoginPopup] = useState(false);
  const location = useLocation();

  const isChangeToken = useSelector(selectIsChangeToken())
  const isLoadingAuth = useSelector(selectLoadingAuth)
  const isLoggedIn = !!(authInfo.auth && authInfo.user);

  const dispatch = useDispatch()

  const [savePathRedirectCallback] = savePathRedirect()
  const loadProfile = getProfileCallback()
  const { state } = useGlobalStore()

  const fetchData = () => {
    return dispatch => {
      // dispatch(loadCartAction());
      dispatch(getSiteInfos());
      // dispatch(getIpLocale());
      dispatch(getCurrencyRate());
    }
  }

  const refreshTokenCallback = useCallback(
    (refreshToken) => dispatch(refresh(refreshToken)),
    [dispatch]
  )
  const logoutCallback = useCallback(
    (...params) => dispatch(logout(...params)),
    [dispatch]
  )

  useEffect(() => {
    dispatch(fetchData())
  }, [])

  //check to show login popup
  useEffect(() => {
    const isShow = PageToShowLoginPopups.map(path => `/${state.lang}-${state.currencyOnl}/${path}`).some(path => location.pathname.startsWith(path));
    setIsLoginPopup(isShow);
  }, [location])

  useEffect(
    () => {
      const data = getAuthCookies()
      setAuthInfo(data)
      if (data.auth && data.auth.accessToken && !isGetProfile) {
        loadProfile(data.auth.accessToken, true)
        isGetProfile = true
      }
    },
    [isChangeToken]
  )

  const previousAuth = usePrevious(authInfo.auth)
  useEffect(
    () => {
      const authInfoChanged = !!(authInfo.auth &&
        JSON.stringify(previousAuth) !== JSON.stringify(authInfo.auth))
      if (authInfoChanged) {
        handleCheckUserRefreshToken()
      }
    },
    [authInfo.auth]

  )

  const handleCheckUserRefreshToken = () => {
    if (datetimeExtensions.checkIsPreviousDate(authInfo.auth.expires,
      new Date())) {
      // logout
      // handleLogout()
    } else {
      // let timeOut = datetimeExtensions.calculateDateDiff(authInfo.auth.expires,
      //   new Date())
      // if (timeOut > TOKEN_TIMEOUT) {
      //   timeOut = timeOut - TOKEN_TIMEOUT
      //   // timeOut = 10 * 1000
      //   const refreshToken = handleRefreshToken(timeOut)
      //   setRefreshToken(refreshToken)
      // } else {
      //   refreshTokenCallback(authInfo.auth.refreshToken)
      // }
    }
  }

  const handleRefreshToken = (timeOut) => {
    return setTimeout(() => {
      refreshTokenCallback(authInfo.auth.refreshToken)
    }, timeOut)
  }

  const handleLogout = () => {
    gtmAuth({ type: gtmType.LOG_OUT })
    logoutCallback(authInfo.auth.accessToken, false, String(window.location))
    clearTimeout(refreshToken)
    setRefreshToken(null)
    LocalStorage.remove(LOCAL_STORAGE_KEY.SAVE_PASSWORD);
  }

  const handleLogin = (routes) => {
    const { pathname, search } = window.location
    savePathRedirectCallback(pathname + search)
    LocalStorage.remove(LOCAL_STORAGE_KEY.SAVE_PASSWORD);
    Router.push(routes)
  }

  const generateUserMenu = (authInfo) => {

    const renderProfileDropdown = (profile) => {
      const menu = (
        <Menu>
          {navMyProfile.filter(item => checkRoleCollaborators(item.key, authInfo?.user?.roleName)).map((item, index, arr) => (
            (item.showOnlyMemberShip === '' || (item.showOnlyMemberShip && profile?.memberShipCode)) && <Menu.Item key={item.key}>
              {/* {item.route === routes.MY_ORDER ? (
                <a className="" href={item.route}>{item.title}</a>
              ) : (
                  <Link href={item.route}>
                    <a className="">{item.title}</a>
                  </Link>
                )} */}
              <Link href={item.route}>
                <a className={clsx({
                  'auth-info_item': true,
                  "auth-info-signIn_item": true,
                  "last-auth-info": index === arr.length - 1
                })}>{item.title}</a>
              </Link>
            </Menu.Item>
          ))}
          <div className='line-divide' />
          <Menu.Item onClick={handleLogout}>
            <a className="auth-info_item auth-info-logout" >
              <FormattedMessage
                id="Nav.Logout"
                defaultMessage="Đăng xuất"
              />
              <img className='signOutIcon' src={signOutIcon} alt="signOutIcon" />
            </a>
          </Menu.Item>
        </Menu>
      );
      return (
        <Dropdown overlayClassName='s43-login-dropdown' overlay={menu} placement="bottomCenter" getPopupContainer={triggerNode => triggerNode.closest(containerSelector)}>
          <div className='d-flex'>
            <span className={clsx("g-flex-centered s43-user-btn",{"text-white": isHomepage})}>
              {state.lang !== language['English'] ?
                `${profile?.lastName} ${profile?.firstName}` :
                `${profile?.firstName} ${profile?.lastName}`
              }
            </span>
            {isHomepage ?
              <img alt='' className='g-ml-4' src={require("@assets/include/svg/fi-rr-angle-small-right.svg").default} /> :
              isHomstay ?
                <img alt='' className='g-ml-4' src={require("@assets/include/svg/fi-rr-angle-small-right-red.svg").default} /> :
                <img alt='' className='g-ml-4' src={require("@assets/include/svg/fi-rr-angle-small-right-gray.svg").default} />
            }
          </div>
        </Dropdown>
      )
    }

    let userMenu = []
    if (isLoggedIn) {
      userMenu.push(
        {
          title: renderProfileDropdown(authInfo.user),
          key: 'user',
          route: '',
          menuClass: ` vpt-color-dark-v7 g-color-primary--hover g-font-size-14 g-font-weight-500`,
          id: 'vp-tracking_menu-header-sign-in',
          icon: null,
          iconClass: 'g-width-15 g-flex-centered g-font-size-22',
        }
      )
    } else {
      userMenu.push(
        {
          title: <span className= {'g-flex-centered g-font-size-16 g-font-weight-600 '+ `${isHomepage ? 'g-color-white' : 'g-color-darkgray_3'}`}>
            <FormattedMessage id="Nav.Login" defaultMessage="Đăng nhập" />
            {isHomepage ?
              <img alt='' style={{width: 18}} src={require("@assets/include/svg/fi-rr-angle-small-right.svg").default} /> :
              isHomstay ?
                <img alt='' src={require("@assets/include/svg/fi-rr-angle-small-right-red.svg").default} /> :
                <img alt='' src={require("@assets/include/svg/fi-rr-angle-small-right-gray.svg").default} />
            }
          </span>,
          key: 'login',
          route: '/login-vpt',
          menuClass: 'vpt-color-dark-v7 g-color-primary--hover g-font-size-14 g-font-weight-500',
          id: 'vp-tracking_menu-header-sign-in',
          icon: null,
          iconClass: 'g-width-15 g-flex-centered g-font-size-22',
          onClick: isLoginPopup ?  () => {
            setVisible(true);
            if(onToggleMenu) onToggleMenu(false);
          } : undefined
        },
      )
    }
    return userMenu
  }

  return !featureFlags.HIDE_AUTH && (
    <>
      <nav
        className="js-mega-menu navbar navbar-expand-lg g-py-0 g-px-0 vpt-navigation__container vpt-navigation__top-nav s43-login-container">
        <div className="navbar-collapse align-items-center flex-sm-row g-pt-0">
          {/* <Spin spinning={isLoadingAuth}> */}
          <ul
            className="d-flex align-items-start mr-auto g-font-weight-600 u-main-nav-v1 u-sub-menu-v1 mb-0 g-pl-0">
            {
              generateUserMenu(authInfo).map((menu, index) => (
                <li
                  key={index}
                  className="nav-item vpt-navigation__item w-100">
                  {menu.route && !isLoadingAuth
                    ? <Link
                      href={menu.route}
                      onClick={() => {
                        if (menu.key === 'register') {
                          LocalStorage.remove(LOCAL_STORAGE_KEY.SAVE_PASSWORD)
                        }
                      }}
                    >
                      <MenuItem
                        item={menu}
                      />
                    </Link>
                    : <>
                      <MenuItem
                        item={menu}
                      />
                    </>
                  }
                </li>
              ))
            }
          </ul>
          {/* </Spin> */}
        </div>
      </nav>

      {
        isLoginPopup && (
          <>
            <ModalLogin
              visible={visible}
              onCancel={() => setVisible(false)}
              isReload={false}
            />
            <ForgotPasswordModal
              visible={openForgot}
              onCancel={() => setOpenForgot(false)}
              onSuccessSent={() => {
                setOpenForgot(false);
                setVisibleSuccess(true);
                setTimeout(() => {
                  setVisibleSuccess(false);
                }, 2000);
              }}
            />
            <ModalSendSuccess visible={visibleSuccess} />
          </>
        )
      }
    </>
  )
}

export default LoginContainer
