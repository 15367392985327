import React from 'react'
import clsx from 'clsx'

const Vin3SLogo = ({
  className,
  imageClass,
  src = require('@assets/img/hotel-logos/vin3s-logo/vin3s-logo.svg').default,
  title = 'homepage',
  alt = '#vin3s',
  href, // = '//vin3s.vn',
}) => {
  return (
    <div className={clsx("logo vin3s-logo", className)}>
      <a href={href} title={title}>
        <picture>
          <img className={clsx(imageClass)} src={src} alt={alt} />
        </picture>
      </a>
    </div>
  )
}

export default Vin3SLogo
