import {
  createComboProductClick,
  createGtmAddToCartCombo,
  createGtmComboHotelProductDetail,
  createGtmComboImpression,
  createGtmComboProductDetail,
  createGtmSelectComboRoomRateHotel,
  createGtmAddFlightToCartCombo,
  createGtmComboProductViewTrip,
  createGtmComboProductCheckout,
} from "./gtmConvert/comboConvert";
import {
  createFlightCheckout,
  createFlightProductClick,
  createFlightProductDetail,
  createGtmFlightCart,
  createGtmFlightImpression,
  createGtmFlightProductViewCart,
  createGtmFlightTransactionSuccess,
} from "./gtmConvert/flightConvert";
import {
  createGtmHotelProduceDetail,
  createHotelCart,
  createHotelProduceHotelClick,
  createHotelProduceImpresion,
} from "./gtmConvert/hotelConvert";
import {
  createGtmSelectBookingHotel,
  createGtmSelectRoomRateHotel,
} from "./gtmConvert/selectConvert";
import {
  createDataTransaction,
  createGtmComboTransacsionSuccess,
  createGtmHotelTransacsionSuccess
} from "./gtmConvert/transactionConvert";
import {
  actionCartSKUClick,
  createCartProductCheckout,
  createGtmExperiencePageView,
  createGtmTourPageView,
  createProductClick,
  createProductImpressions,
  removeFromCartSKUClick,
  createGtmSelectDateSKU,
  createSelectTourOption
} from "./gtmConvert/tourAndExperienceConvert";

import {
  createHotelSearch,
  createSKUSearch,
  createComboSearch,
  createFlightSearch
} from "./gtmConvert/searchConvert";

import {
  createVirtualPageView,
  createDefaultPageView
} from "./gtmConvert/pageViewConvert";

export {
  // Tours and Experience (SKU)
  actionCartSKUClick,
  createCartProductCheckout,
  createGtmExperiencePageView,
  createGtmTourPageView,
  createProductClick,
  createProductImpressions,
  removeFromCartSKUClick,
  createGtmSelectDateSKU,
  createSelectTourOption,
  // SELECT
  createGtmSelectBookingHotel,
  createGtmSelectRoomRateHotel,
  // HOTEL
  createGtmHotelProduceDetail,
  createHotelCart,
  createHotelProduceHotelClick,
  createHotelProduceImpresion,
  // TRANSACTION
  createDataTransaction,
  createGtmComboTransacsionSuccess,
  createGtmHotelTransacsionSuccess,
  // FLIGHT
  createFlightCheckout,
  createFlightProductClick,
  createFlightProductDetail,
  createGtmFlightCart,
  createGtmFlightImpression,
  createGtmFlightProductViewCart,
  createGtmFlightTransactionSuccess,
  // COMBO (BUNDLE)
  createComboProductClick,
  createGtmAddToCartCombo,
  createGtmComboHotelProductDetail,
  createGtmComboImpression,
  createGtmComboProductDetail,
  createGtmSelectComboRoomRateHotel,
  createGtmAddFlightToCartCombo,
  createGtmComboProductViewTrip,
  createGtmComboProductCheckout,
  // SEARCH
  createHotelSearch,
  createSKUSearch,
  createComboSearch,
  createFlightSearch,
  // PAGE VIEW
  createVirtualPageView,
  createDefaultPageView
};
