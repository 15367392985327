import utils from "@utils/";
import { Button, Input, Modal } from "antd";
import { ErrorMessage, FastField, Form, Formik } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { yupExtensions } from "src/extensions";
import * as Yup from "yup";
import FormItem from "antd/lib/form/FormItem";
import { axiosProviderLogin } from "src/actions/api/axiosProvider";
import Urls from "src/api/Urls";
import { useDispatch } from "react-redux";
import { getConfig } from "@config/";
import { forgotPassModalCallback } from "src/hooks/auth";

const {
  publicRuntimeConfig: { VIN3S_CLIENT_ID },
} = getConfig();

const FormInputCustom = ({
  fieldName,
  errors,
  touched,
  values,
  setFieldValue,
  setFieldTouched,
}) => {
  return (
    <FormItem
      help={
        utils.checkFieldError(fieldName, errors, touched)
          ? errors[fieldName]
          : ""
      }
      validateStatus={
        utils.checkFieldError(fieldName, errors, touched) ? "error" : undefined
      }
      colon={false}
      required={true}
      // label={}
    >
      <Input
        placeholder={fieldName === "password" ? "Mật khẩu / Password" : "Email"}
        className="login-input-custom"
        value={values[fieldName]}
        onChange={(event) => setFieldValue(fieldName, event.target.value)}
        onBlur={() => setFieldTouched(fieldName, true)}
      />
    </FormItem>
  );
};

const ForgotPasswordModal = ({ visible, onCancel, onSuccessSent = () => {} }) => {
  const dispatch = useDispatch();
  const onForgotPassModal = forgotPassModalCallback();
  return (
    <Modal
      className="login-modal-container"
      title={
        <span className="modal-title">
          <FormattedMessage
            id="Login.ForgotPassword"
            defaultMessage="Quên mật khẩu / Forgot password"
          />
        </span>
      }
      visible={visible}
      destroyOnClose
      maskClosable={false}
      footer={null}
      onCancel={onCancel}
    >
      <Formik
        initialValues={{
          client_id: VIN3S_CLIENT_ID,
          email: "",
          connection: "Username-Password-Authentication",
        }}
        onSubmit={(value, { setSubmitting }) => {
          onForgotPassModal({
            body: value,
            sumitting: setSubmitting,
            onSuccess: onSuccessSent,
          });
        }}
        validationSchema={Yup.object().shape({
          email: yupExtensions.emailRequired,
        })}
      >
        {({
          errors,
          touched,
          values,
          setFieldValue,
          handleSubmit,
          setFieldTouched,
          isSubmitting,
        }) => {
          return (
            <div className="t-break-spaces">
              <FormattedMessage
                id="Login.PleaseProvideEmail"
                defaultMessage={
                  "Vui lòng cung cấp email đăng nhập\nPlease provide email"
                }
              />
              <div className="mt-4">
                <FormInputCustom
                  fieldName="email"
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  errors={errors}
                />
              </div>
              <div className="row mt-4">
                <div className="col-6">
                  <Button
                    type="button"
                    className="ant-btn g-brd-primary g-color-primary g-font-weight-500 g-py-10 vpt-height-auto w-100"
                    onClick={onCancel}
                  >
                    <FormattedMessage
                      id="Login.Back"
                      defaultMessage="Quay lại"
                    />
                  </Button>
                </div>
                <div className="col-6">
                  <Button
                    loading={isSubmitting}
                    type="button"
                    className="ant-btn g-bg-primary g-brd-primary g-color-white g-font-weight-500 g-py-10 vpt-height-auto w-100 button-loading"
                    onClick={handleSubmit}
                  >
                    <span>
                      <FormattedMessage
                        id="Login.Send"
                        defaultMessage="Gửi / Send"
                      />
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ForgotPasswordModal;
