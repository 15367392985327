import React, { useState, useCallback } from 'react'
import { connectModal } from 'redux-modal'
import { useDispatch, useSelector  } from 'react-redux'
import { getConfig } from '@config'
import { Button, Modal, Icon } from 'antd'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { FormattedMessage } from 'react-intl.macro'

// hooks
import { selectIsLoading } from '../../reselect/fetchingSelector'
import { notifyLogin } from '../../hooks/rating'
import authActions from '../../actions/authAction'

import LoginForm from '../../components/Auth/LoginForm'

const {
    publicRuntimeConfig: {
      RECAPTCHA_KEY
    } // Available both client and server side
  } = getConfig()

const LoginModal = (props) => {

  const {show, handleHide, message, children} = props
  const [recaptchaToken, setRecaptchaToken] = useState('')
  const { executeRecaptcha } = useGoogleReCaptcha()
  const showMessageLogin = notifyLogin()
  const dispatch = useDispatch()

  const isLoading = useSelector(selectIsLoading)

  const onLogin = useCallback(
    (username, password, token, refreshPage) => dispatch(authActions.login(username, password, token, refreshPage)),
    [dispatch]
  )

  const handleSubmit = async (values, captchaResult) => {
    const result = await onLogin(values.username, values.password, captchaResult || recaptchaToken, false)
    if(result) {
        handleHide()
        showMessageLogin(<FormattedMessage
          id="Voucher.Detail.RatingForm.LoginSuccess"
          defaultMessage='Đăng nhập thành công. Bạn có thể quay lại đánh giá'
        />)
    }
  }

  const clickHandler = async values => {
    if (!executeRecaptcha) {
      return
    }
    let captchaResult
    if (RECAPTCHA_KEY) {
      captchaResult = await executeRecaptcha('login_page')
    }
    setRecaptchaToken(captchaResult)
    handleSubmit(values, captchaResult)
  }

  return (
    <Modal
      title={ null }
      footer={ null }
      maskClosable={ false }
      closable={ false }
      wrapClassName='vpt-login-modal'
      visible={ show }
      onOk={ handleHide }
      onCancel={ handleHide }
    >
      <Button
        className='b-close g-color-primary'
        onClick={ handleHide }
      >
        <Icon type="close-circle"/>
      </Button>
      <LoginForm
        recapchaToken={recaptchaToken}
        onLogin={clickHandler}
        isLoading={isLoading}
        handleHide={handleHide}
        refreshPage={false}
      />
      
    </Modal>
  )
}


export default connectModal ({name: 'loginModal', destroyOnHide: true}) (LoginModal)
