/**
 * FLIGHT
 */

import _filter from "lodash/filter";
import _includes from "lodash/includes";
import _sumBy from "lodash/sumBy";
import moment from "moment";
import {
  FORMAT_DATE_TIME_AFTER,
  FORMAT_ONLY_DATE,
  LOCAL_STORAGE_KEY,
} from "src/constants";
import { gtmEvent, TBD } from "src/helpers/gtmEvent";
import { ENUM_FLIGHT } from "./enumGtm";
import * as LocalStorage from "../localStorage";
import {
  bookingFlight,
  calcQuantityFlightTicket,
  calcTax,
  createFlightName,
  flightTicketPrice,
} from "@utils/gtmHelpers";
import { flightUtils } from "../../utils";

export const createGtmFlightImpression = ({
  dataFlightProduceImpresion = [],
  flightData = {},
  listName,
}) => {
  const allPassenger =
    (Number(flightData?.adult) || 0) +
    (Number(flightData?.child) || 0) +
    (Number(flightData?.infant) || 0);
  const { departureName, returnName } = createFlightName({
    startPoint: flightData.startPoint,
    endPoint: flightData.endPoint,
  });
  const productImpressionArray = dataFlightProduceImpresion.map((elm) => ({
    name: listName === ENUM_FLIGHT.list_depart ? departureName : returnName,
    id: elm?.flightNumber,
    price: flightTicketPrice(elm, allPassenger),
    brand: elm?.listSegment?.[0]?.airlineName,
    category: ENUM_FLIGHT.category,
    list: listName || ENUM_FLIGHT.list,
    position: elm?.position,
    dimension30: TBD,
    dimension31: flightTicketPrice(elm, allPassenger),
    dimension32: 0,
    dimension33: elm.startDate
      ? moment(elm.startDate).format(FORMAT_DATE_TIME_AFTER)
      : null,
    dimension34: elm.endDate
      ? moment(elm.endDate).format(FORMAT_DATE_TIME_AFTER)
      : null,
    dimension35: elm.startPoint,
    dimension36: elm.endPoint,
    dimension37: elm.duration,
    dimension38: TBD,
  }));

  const ecommerceObject = {
    ecommerce: { impressions: productImpressionArray },
    event: gtmEvent.PRODUCT_IMPRESSIONS,
    // Add these custom dimensions
    booking_service_type: ENUM_FLIGHT.bookingServiceType,
    booking_origin: flightData?.startPoint || null,
    booking_destination: flightData?.endPoint || null,
    booking_start_date: flightData?.departureDate || null,
    booking_end_date: flightData?.returnDate || null,
    booking_no_of_adults: flightData?.adult || 0,
    booking_no_of_children: flightData?.child || 0,
    booking_no_of_infants: flightData?.infant || 0,
    booking_flight: bookingFlight(flightData),
  };
  return ecommerceObject;
};

export const createFlightProductClick = ({
  index = 0,
  flightData = {},
  flightInfo = {},
}) => {
  const list = flightInfo.isReturn
    ? ENUM_FLIGHT.list_return
    : ENUM_FLIGHT.list_depart;
  const allPassenger =
    (Number(flightData?.passenger?.adult) || flightInfo.urlParams.adult || 0) +
    (Number(flightData?.passenger?.child) || flightInfo.urlParams.child || 0) +
    (Number(flightData?.passenger?.infant) || flightInfo.urlParams.infant || 0);
  const { departureName, returnName } = createFlightName({
    startPoint: flightInfo.startPoint,
    endPoint: flightInfo.endPoint,
  });

  const productClickedArray = [
    {
      name: flightInfo?.isReturn ? returnName : departureName,
      id: flightInfo?.flightNumber,
      price: flightTicketPrice(flightInfo, allPassenger),
      brand: ENUM_FLIGHT.brand,
      category: ENUM_FLIGHT.category,
      list,
      position: index + 1,
      dimension30: TBD,
      dimension31: flightTicketPrice(flightInfo, allPassenger),
      dimension32: 0,
      // Fix as below
      dimension33: flightInfo.startDate
        ? moment(flightInfo.startDate).format(FORMAT_DATE_TIME_AFTER)
        : null,
      dimension34: flightInfo.endDate
        ? moment(flightInfo.endDate).format(FORMAT_DATE_TIME_AFTER)
        : null,
      dimension35: flightInfo.startPoint,
      dimension36: flightInfo.endPoint,
      dimension37: flightInfo.duration,
      dimension38: TBD,
    },
  ];

  const ecommerceObject = {
    ecommerce: {
      click: {
        products: productClickedArray,
        actionField: { list },
      },
    },
    event: gtmEvent.PRODUCT_CLICK,
    booking_service_type: ENUM_FLIGHT.bookingServiceType,
    booking_origin: flightInfo?.startPoint || null,
    booking_destination: flightInfo?.endPoint || null,
    booking_start_date: flightInfo.startDate
      ? moment(flightInfo.startDate).format(FORMAT_DATE_TIME_AFTER)
      : null,
    booking_end_date: flightInfo.endDate
      ? moment(flightInfo.endDate).format(FORMAT_DATE_TIME_AFTER)
      : null,
    booking_no_of_adults:
      Number(flightData?.passenger?.adult) || flightInfo.urlParams.adult || 0,
    booking_no_of_children:
      Number(flightData?.passenger?.child) || flightInfo.urlParams.child || 0,
    booking_no_of_infants:
      Number(flightData?.passenger?.infant) || flightInfo.urlParams.infant || 0,
    booking_flight: bookingFlight(flightInfo),
  };
  if (!flightInfo.isReturn) {
    delete ecommerceObject.booking_end_date;
  }
  return ecommerceObject;
};

export const createFlightProductDetail = ({ productDetail = {} }) => {
  const allPassenger =
    (Number(productDetail?.passenger?.adult) || 0) +
    (Number(productDetail?.passenger?.child) || 0) +
    (Number(productDetail?.passenger?.infant) || 0);
  const { departureName, returnName } = createFlightName({
    startPoint: productDetail.startPoint,
    endPoint: productDetail.endPoint,
  });
  const productDetailArray = [
    {
      name: productDetail?.isReturn ? returnName : departureName,
      id: productDetail?.flightNumber,
      price: flightTicketPrice(productDetail, allPassenger),
      brand: ENUM_FLIGHT.brand,
      category: ENUM_FLIGHT.category,
      dimension30: TBD,
      dimension31: flightTicketPrice(productDetail, allPassenger),
      dimension32: 0,
      // Fix as below
      dimension33: productDetail.startDate
        ? moment(productDetail.startDate).format(FORMAT_DATE_TIME_AFTER)
        : null,
      dimension34: productDetail.endDate
        ? moment(productDetail.endDate).format(FORMAT_DATE_TIME_AFTER)
        : null,
      dimension35: productDetail.startPoint,
      dimension36: productDetail.endPoint,
      dimension37: productDetail.duration,
      dimension38: TBD,
    },
  ];

  const ecommerceObject = {
    event: gtmEvent.PRODUCT_DETAIL,
    booking_service_type: ENUM_FLIGHT.bookingServiceType,
    booking_origin: productDetail?.startPoint || null,
    booking_destination: productDetail?.endPoint || null,
    booking_start_date: productDetail.startDate
      ? moment(productDetail.startDate).format(FORMAT_DATE_TIME_AFTER)
      : null,
    booking_end_date: productDetail.endDate
      ? moment(productDetail.endDate).format(FORMAT_DATE_TIME_AFTER)
      : null,
    booking_no_of_adults: Number(productDetail?.passenger?.adult) || 0,
    booking_no_of_children: Number(productDetail?.passenger?.child) || 0,
    booking_no_of_infants: Number(productDetail?.passenger?.infant) || 0,
    booking_flight: bookingFlight(productDetail),
    ecommerce: {
      detail: {
        products: productDetailArray,
      },
    },
  };
  if (!productDetail.isReturn) {
    delete ecommerceObject.booking_end_date;
  }
  return ecommerceObject;
};

export const createGtmFlightCart = ({ productDetail = {}, action = "add" }) => {
  const allPassenger =
    Number(productDetail?.passenger?.adult || productDetail?.adult || 0) +
    Number(productDetail?.passenger?.child || productDetail?.child || 0) +
    Number(productDetail?.passenger?.infant || productDetail?.infant || 0);
  const { departureName, returnName } = createFlightName({
    startPoint: productDetail.startPoint,
    endPoint: productDetail.endPoint,
  });
  const price = flightTicketPrice(productDetail, allPassenger);
  const isReturn = productDetail?.isReturn;
  const productCartArray = [
    {
      name: isReturn ? returnName : departureName,
      id: productDetail?.flightNumber,
      price,
      brand: productDetail?.airlineCode,
      category: ENUM_FLIGHT.category,
      dimension30: TBD,
      dimension31: price,
      dimension32: 0, // "product_discount",
      // Change value of variant
      variant: productDetail?.listSegment?.[0]?.class || null,
      // Already had
      quantity: calcQuantityFlightTicket({ productDetail }),
      // Fix as below
      dimension33: productDetail?.startDate
        ? moment(productDetail?.startDate).format(FORMAT_DATE_TIME_AFTER)
        : null,
      dimension34: productDetail?.endDate
        ? moment(productDetail?.endDate).format(FORMAT_DATE_TIME_AFTER)
        : null,
      dimension35: isReturn
        ? productDetail?.endPoint
        : productDetail?.startPoint,
      dimension36: isReturn
        ? productDetail?.startPoint
        : productDetail?.endPoint,
      dimension37: productDetail?.duration,
      dimension38: TBD,
    },
  ];

  let ecommerceObject = {
    booking_service_type: ENUM_FLIGHT.bookingServiceType,
    booking_origin: productDetail?.startPoint || null,
    booking_destination: productDetail?.endPoint || null,
    booking_start_date: productDetail?.departureDate,
    // ? moment(productDetail.departureDate, FORMAT_ONLY_DATE).format(
    //     FORMAT_DATE_TIME_AFTER
    //   )
    // : null,
    booking_end_date: productDetail?.date,
    // ? moment(productDetail.date, FORMAT_ONLY_DATE).format(FORMAT_DATE_TIME_AFTER)
    // : null
    booking_no_of_adults:
      productDetail?.passenger?.adult || productDetail?.adult || 0,
    booking_no_of_children:
      productDetail?.passenger?.child || productDetail?.child || 0,
    booking_no_of_infants:
      productDetail?.passenger?.infant || productDetail?.infant || 0,
    booking_flight: bookingFlight(productDetail),
  };

  if (action === "add") {
    ecommerceObject = {
      event: gtmEvent.PRODUCT_ADD_TO_CART,
      ...ecommerceObject,
      ecommerce: {
        add: {
          products: productCartArray,
        },
      },
    };
  } else {
    ecommerceObject = {
      event: gtmEvent.PRODUCT_REMOVE_FROM_CART,
      ...ecommerceObject,
      ecommerce: {
        remove: {
          products: productCartArray,
        },
      },
    };
  }

  if (!isReturn) {
    delete ecommerceObject.booking_end_date;
  }

  return ecommerceObject;
};

export const createGtmFlightProductViewCart = ({
  dataFlightCart = {},
  departureFlight = {},
  returnFlight = {},
}) => {
  const booking_flight = bookingFlight(dataFlightCart);
  const ecommerceObject = {
    event: gtmEvent.PRODUCT_VIEW_CART,
    booking_service_type: ENUM_FLIGHT.bookingServiceType,
    booking_origin: dataFlightCart?.startPoint,
    booking_destination: dataFlightCart?.endPoint,
    booking_start_date: dataFlightCart?.departureDate,
    booking_end_date: dataFlightCart?.returnDate,
    booking_no_of_adults: dataFlightCart?.adult,
    booking_no_of_children: dataFlightCart?.child,
    booking_no_of_infants: dataFlightCart?.infant,
    booking_flight,
  };
  if (dataFlightCart?.itineraryType === '1') {
    delete ecommerceObject.booking_end_date;
  }
  return ecommerceObject;
};

export const createFlightCheckout = ({
  step = 1,
  flightData = {},
  flightInfo = {},
  contactInfo = {},
}) => {
  // GA360 M3S-2014 [Flight] Purchase (Slide 60-61)
  // LocalStorage.set(LOCAL_STORAGE_KEY.GA360_ORDER_FLIGHT, {
  //   flightData,
  //   flightInfo,
  // });

  const { departureName, returnName } = createFlightName({
    startPoint: flightData.startPoint,
    endPoint: flightData.endPoint,
  });

  const flightTicket = [flightData?.departureFlights];
  if (!!flightData?.returnFlights) flightTicket.push(flightData?.returnFlights);

  const productCheckoutArray = flightTicket.map((productDetail, flIdx) => {
    const { totalTax, totalFee, totalServiceFee, vptFee } = calcTax({
      productDetail,
      flightInfo,
    });
    const allPassenger =
      Number(flightInfo?.adult || 0) +
      Number(flightInfo?.child || 0) +
      Number(flightInfo?.infant || 0);
    const price = flightTicketPrice(productDetail, allPassenger);
    let returnProduct = {
      name: flIdx === 0 ? departureName : returnName,
      id: productDetail?.flightNumber,
      price,
      brand: productDetail?.airlineCode,
      category: ENUM_FLIGHT.category,
      dimension30: TBD,
      dimension31: price,
      dimension32: 0, // "product_discount",

      variant: productDetail?.listSegment?.[0]?.class || null,
      // Already had
      quantity: calcQuantityFlightTicket({ productDetail: flightInfo }),
      // Fix as below
      dimension33: productDetail?.startDate
        ? moment(productDetail?.startDate).format(FORMAT_DATE_TIME_AFTER)
        : null,
      dimension34: productDetail?.endDate
        ? moment(productDetail?.endDate).format(FORMAT_DATE_TIME_AFTER)
        : null,
      dimension35: productDetail?.startPoint,
      dimension36: productDetail?.endPoint,
      dimension37: productDetail?.duration,
      dimension38: TBD,

      metric1: totalTax,
      metric2: totalFee,
      metric3: totalServiceFee,
      metric4: vptFee,
    };
    if (step === 2) {
      const { baggageReturn, baggageDeparture } = flightUtils.getBaggageTotal(
        contactInfo.listPassengers
      );
      returnProduct.metric5 = flIdx === 0 ? baggageDeparture : baggageReturn;
    }
    return returnProduct;
  });

  const ecommerceObject = {
    event: gtmEvent.PRODUCT_CHECKOUT,
    booking_service_type: ENUM_FLIGHT.bookingServiceType,
    booking_origin: flightInfo?.startPoint,
    booking_destination: flightInfo?.endPoint,
    booking_start_date: flightInfo?.departureDate,
    booking_end_date: flightInfo?.returnDate,
    booking_no_of_adults: flightInfo?.adult,
    booking_no_of_children: flightInfo?.child,
    booking_no_of_infants: flightInfo?.infant,
    booking_flight: bookingFlight(flightInfo),
    ecommerce: {
      checkout: {
        products: productCheckoutArray,
        actionField: { step },
      },
    },
  };

  if (flightInfo?.itineraryType === "1") {
    delete ecommerceObject.booking_end_date
  }

  return ecommerceObject;
};

export const createGtmFlightTransactionSuccess = ({
  transactionData = {
    siteInfo: {},
    order: {},
    objectDataTransaction: {},
  },
}) => {
  const { siteInfo, order, objectDataTransaction } = transactionData;

  // GA360 M3S-2014 [Flight] Purchase (Slide 60-61)
  const {
    flightData = {},
    flightInfo = {},
    paymentMethod,
    contactInfo,
  } = objectDataTransaction;

  const { departureName, returnName } = createFlightName({
    startPoint: flightData.startPoint,
    endPoint: flightData.endPoint,
  });

  const flightTicket = [flightData?.departureFlights];
  if (!!flightData?.returnFlights) flightTicket.push(flightData?.returnFlights);
  const { baggageReturn, baggageDeparture } = flightUtils.getBaggageTotal(
    contactInfo?.listPassengers
  );

  let baggageArr = [];
  (contactInfo?.listPassengers || []).forEach((element) => {
    baggageArr = [
      ...baggageArr,
      ...[element.baggageDeparture, element.baggageReturn],
    ].filter((e) => e);
  });

  const baggages = (baggageArr || []).map((baggageData) => {
    return {
      // Baggage
      name: "Ancillary: Baggage", // Name or ID is required.
      id: baggageData?.id,
      price: baggageData?.price,
      brand: baggageData?.airlineCode,
      category: "Ancillary",
      quantity: 1,
      dimension30: "Baggage",
    };
  });
  const productPurchasedArray = flightTicket.map((productDetail, flIdx) => {
    const { totalTax, totalFee, totalServiceFee, vptFee } = calcTax({
      productDetail,
      flightInfo,
    });
    const allPassenger =
      Number(flightInfo?.adult || 0) +
      Number(flightInfo?.child || 0) +
      Number(flightInfo?.infant || 0);
    const price = flightTicketPrice(productDetail, allPassenger);
    return {
      name: flIdx === 0 ? departureName : returnName,
      id: productDetail?.flightNumber,
      price,
      brand: productDetail?.airlineCode,
      category: ENUM_FLIGHT.category,
      dimension30: TBD,
      dimension31: price,
      dimension32: 0,
      variant: productDetail?.listSegment?.[0]?.class || null,
      quantity: calcQuantityFlightTicket({ productDetail: flightInfo }),
      dimension33: productDetail?.startDate
        ? moment(productDetail?.startDate).format(FORMAT_DATE_TIME_AFTER)
        : null,
      dimension34: productDetail?.endDate
        ? moment(productDetail?.endDate).format(FORMAT_DATE_TIME_AFTER)
        : null,
      dimension35: productDetail?.startPoint,
      dimension36: productDetail?.endPoint,
      dimension37: productDetail?.duration,
      dimension38: TBD,

      metric1: totalTax,
      metric2: totalFee,
      metric3: totalServiceFee,
      metric4: vptFee,
      metric5: flIdx === 0 ? baggageDeparture : baggageReturn,
    };
  });

  const actionField = {
    id: order.orderCode,
    affiliation: null,
    revenue: order?.totalAmount,
    tax: _sumBy(productPurchasedArray, "metric1"),
    shipping: 0,
    coupon: order?.couponCode,
  };

  const ecommerceObject = {
    event: gtmEvent.TRANSACTION_SUCCESS,
    // Add these custom dimensions
    payment_method: order?.paymentType,
    payment_card: paymentMethod,
    booking_service_type: ENUM_FLIGHT.bookingServiceType,
    booking_origin: flightInfo?.startPoint,
    booking_destination: flightInfo?.endPoint,
    booking_start_date: flightInfo?.departureDate,
    booking_end_date: flightInfo?.returnDate,
    booking_no_of_adults: flightInfo?.adult,
    booking_no_of_children: flightInfo?.child,
    booking_no_of_infants: flightInfo?.infant,
    booking_flight: bookingFlight(flightInfo),
    ecommerce: {
      currencyCode: order?.currencyCode,
      purchase: {
        products: [...productPurchasedArray, ...baggages],
        actionField: actionField,
      },
    },
  };

  if (flightInfo?.itineraryType === "1") {
    delete ecommerceObject.booking_end_date
  }

  return ecommerceObject;
};
