import actionTypes from '../actionTypes'

const initState = {
  ratings: null,
  ratingCreated: null,
  replyRating: null
}

const ratingReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_REVIEW_SUCCESS:
      return Object.assign({}, state, {
        ratings: action.payload.data
      })
    case actionTypes.CREATE_REVIEW_SUCCESS:
      return Object.assign({}, state, {
        replyRating: action.payload.data.data
      })
    default:
      return state
  }
}

export default ratingReducer
