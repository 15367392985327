export default {
  SKU_SEARCH_AUTOCOMPLETE: '/api/frontend/experience/autocomplete',
  VOUCHER_LIST: '/api/frontend/group', // VOUCHER
  LOCATION_LIST_ALL: '/api/property/all',
  VOUCHER_DETAIL:'/api/frontend/group/:slug',
  TOUR_LIST: '/api/frontend/tour',
  TOUR_DETAIL:'/api/frontend/tour',
  TOUR_LINES: '/api/frontend/tourlineitem/tour',
  TOUR_TICKETS: '/api/frontend/tourlineitem/ticket',
  TOUR_STATE_PROVINCE: '/api/stateprovince',
  TOUR_VINWONDER_TICKETS: '/api/bwc/vinwonder/vinwonderticketdetail',
  ADD_TO_CART: '/api/cart/addtocart',
  GET_CART: '/api/cart',
  GET_CART_COUNT: '/api/cart/count',
  UPDATE_ITEM_CART: '/api/cart',
  DELETE_ITEM_CART: '/api/cart',
  GET_PAYMENT_METHOD: 'api/payment/list',
  CHECKOUT: '/api/cart/checkout',
  ORDER_DETAIL: '/api/frontend/order',
  ORDER_COUPON: '/api/frontend/order/checkcoupon',
  TOUR_COLLECTION: '/api/frontend/tour/collection',
  BLOG_LIST: '/api/frontend/post',
  BLOG_DETAIL: '/api/frontend/post/:slug',
  OFFER_LIST: '/api/special-offer',
  OFFER_DETAIL: '/api/special-offer/:slug',
  PAGE_DETAIL: '/api/frontend/page',
  TOUR_TYPE_LIST: '/api/admin/tourtype/all',
  EXCHANGERATE_LIST: '/api/frontend/exchangerate',
  LISTING: '/api/frontend/experience',
  TAG_GROUP: '/api/frontend/taggroup',
  AUTH_LOGIN: '/frontend/externallogin/authzero',
  AUTH0_LOGIN: '/login',
  AUTH0_AUTHORIZE: '/authorize',
  ORDER_INFO: '/api/frontend/order/:orderId/orderItem',
  GET_ORDER: '/order/:id',
  BOOKING_DETAIL: '/api/booking/details',
  ACCOUNT_LOGIN: 'mobile-vinpearl/public/account/login',
  ACCOUNT_LOGIN_VPT: 'frontend/externallogin/login/',
  FORGOT_PASSWORD: 'frontend/externallogin/forgot-password',
  FORGOT_PASSWORD_VPT: 'mobile-vinpearl/public/account/send-email-otp',
  ACCOUNT_SIGNUP: 'frontend/externallogin/sign-up/',
  ACCOUNT_SIGNUP2: 'mobile-vinpearl/public/account/sign-up2',
  CHANGE_PASSWORD_VPT: 'mobile-vinpearl/public/account/change-password-with-otp',
  RECOMMENDATION_TOUR: '/api/frontend/get-rulebase-recomendation',
  PRODUCT_RECOMMENDED: '/api/frontend/tour/product-recommended',
  ORDER_ITEM: '/api/frontend/order/:orderId/OrderItem',
  REFRESH_TOKEN: 'frontend/useraccount/refresh',
  COUNTRY_BY_IP: '/api/frontend/ipaddress/get-language-code',
  PROMOTION_LIST: '/promotion/my-promotions',
  FILTER_RATE: '/api/frontend/taggroup/get-rate-filter',
  SEND_OTP: 'frontend/externallogin/send-otp',
  CHECK_USER: 'frontend/externallogin/check-user',
  UPDATE_USER: 'frontend/useraccount/update-profile',
  UPLOAD_AVATA: 'frontend/useraccount/upload-avatar',
  LINK_ACCOUNT: 'frontend/useraccount/link-account',
  REMOVE_OLD_PHONE: 'frontend/useraccount/remove-old-phone',
  GET_CROSS_SELL: '/api/frontend/order/link-cross-sale',
  LOGGING_DATA: '/api/frontend/logging',
}