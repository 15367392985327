import { gtmPushDataPageView, gtmPushDataSearch } from "@utils/gtm";
import {
  createVirtualPageView,
  createDefaultPageView,
} from "@utils/gtmEventConvert";
import { CATEGORY, CATEGORY_PRODUCT, isGtmDisabled } from "@utils/gtmHelpers";
import { gtmType } from "src/helpers/gtmEvent";

const gtmPageView = ({ dataPage = {}, type = gtmType.DEFAULT_PAGE_VIEW, isHomePage = false }) => {
  // if (isGtmDisabled() || true) return;
  let dataPageView;
  switch (type) {
    case gtmType.PAGE_VIEW:
      dataPageView = createVirtualPageView({ 
        dataPage,
        type: gtmType.PAGE_VIEW,
        isHomePage: isHomePage,
      });
      break;
    case gtmType.VIRTUAL_PAGE_VIEW:
      dataPageView = createVirtualPageView({ 
        dataPage,
        type: gtmType.VIRTUAL_PAGE_VIEW,
        isHomePage: isHomePage,
      });
      break;
    case gtmType.DEFAULT_PAGE_VIEW:
    default:
      dataPageView = createDefaultPageView({ dataPage });
      break;
  }
  // return dataPageView;
  gtmPushDataPageView({ dataPageView });
  if (type === gtmType.PAGE_VIEW) {
    return dataPageView
  }
};
export default gtmPageView;
