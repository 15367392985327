
import { FORMAT_ONLY_DATE_REVERSE, enumType, PAYMENT_ENUM } from '../constants'
import moment from 'moment'
import string from './string'

const formatObjectSelect = (item, valueField, labelField,isCombineKey) => {
  if (!item) {
    return undefined
  }
  if(isCombineKey){
    return {
      ...item,
      key: item[`${valueField}`]+" "+item[`${labelField}`],
      value: item[`${valueField}`]+" "+item[`${labelField}`],
      label: item[`${labelField}`],
      text: item[`${labelField}`],
    }
  }
  return {
    ...item,
    key: item[`${valueField}`],
    value: item[`${valueField}`],
    label: item[`${labelField}`],
    text: item[`${labelField}`],
  }
}

const isString = value => typeof value === 'string';

export default {
  /**
   * get value selected in select
   * @param data
   * @param originValue
   * @returns {undefined|*}
   */
  getValueOption(data, originValue) {
    if (data && data.length > 0) {
      switch (typeof (originValue)) {
        case 'string':
          // 1. type of value is string
          // find option whose value is equal value selected
          return data.find(({ value }) => value === originValue) || undefined
        case 'object':
          // 2. type of value is object
          // check if value is array
          // reformat value selected
          if (Array.isArray(originValue)) {
            const optionSelected = originValue.map(item => {
              if (item.id) {
                return formatObjectSelect(item, 'id', 'name')
              }
              if (item.value) {
                return formatObjectSelect(item, 'value', 'label')
              }
              if (item.key) {
                return formatObjectSelect(item, 'key', 'label')
              }
              return data.find(({ value }) => value === item) || undefined
            })
            return optionSelected.filter(item => item && item.value)
          }
          // value if object
          // find option whose value is equal value selected
          if (!originValue) {
            return undefined
          }
          return data.find(({ value }) => value === originValue.id || value === originValue.value || value === originValue.key)
            || undefined
        default:
          return undefined
      }
    }
    return undefined
  },

  /**
   * get label from enum type
   * @param value
   * @param enumData
   * @returns {null}
   */
  getLabelFromValue(value, enumData) {
    if (!value || !enumData || enumData.length === 0) {
      return null
    }

    const findValue = enumData.find(item => item.value === value)

    return findValue ? findValue.label : null
  },

  getIconFromValue(value, enumData) {
    if (!value || !enumData || enumData.length === 0) {
      return null
    }

    const findValue = enumData.find(item => item.value === value)

    return findValue ? findValue.icon : null
  },

  /**
   * get object from enum
   * @param value
   * @param enumData
   * @returns {null|*}
   */
  getObjectFromEnum(value, enumData) {
    if (!value || !enumData || enumData.length === 0) {
      return null
    }

    return enumData.find(item => item.value === value)
  },

  /**
   * init options to select
   * @param rawData
   * @param valueField
   * @param labelField
   * @param currentValue
   * @returns {[]}
   */
  initOptions(rawData, valueField, labelField, currentValue, isCombineKey = false) {
    let options = []
    // 1. init search result to select
    if (rawData && rawData.length > 0) {
      options = rawData.map(item => formatObjectSelect(item, valueField, labelField,isCombineKey))
    }

    // 2. init current value to options
    // if options not include current value, add current value to options
    if (currentValue) {
      if (typeof (currentValue) === 'object') {
        // case 1. current value is array
        // check each value in array
        if (Array.isArray(currentValue) && currentValue.length > 0) {
          currentValue.forEach(item => {
            const findItem = options.find(({ value }) => value === item.value)
            if (!findItem) {
              options.push(item)
            }
          })
        } else {
          // case 2. current value is object
          const findItem = options.find(({ value }) => value === currentValue.value)

          if (!findItem) {
            options.push(currentValue)
          }
        }
      }
    }
    return options.filter(item => !item.hide)
  },

  /**
   * get value of object
   * @param data
   * @returns {string|*}
   */
  getValueObject(data) {
    if (!data) {
      return ''
    }
    if (typeof (data) === 'object') {
      return data.value
        ? data.value
        : ''
    }
    return data
  },

  deleteEmptyProps(obj) {
    return Object.keys(obj).forEach((key) => (obj[key] == null || obj[key] === '') && delete obj[key])
  },
  getCartProductIds(data) {
    const vinWondersPromotion = (data.vinWonderTour || data.vinWonders || []).map(item => {
      const result = {
        id: item.tourId ? item.tourId : item.vinWonderId,
        useDate: moment(item.usingDate).format(FORMAT_ONLY_DATE_REVERSE),
        count: parseInt(item.quantity || 0) + parseInt(item.childQuantity || 0) + parseInt(item.seniorQuantity || 0),
        price: item?.price || 0,
      }
      if (!(item.usingDate || item.departureDate).includes('9999')) {
        result.returnedDate = moment(item.usingDate || item.departureDate)
          .add(Math.floor(item.lengthOfTour || 1) - 1, "days")
          .format(FORMAT_ONLY_DATE_REVERSE);
      }
      return result;
    });
    const toursPromotion = ([...data.tours, ...data.vouchers] || []).filter(item => item.checked !== false).map(item => {
      const result = {
        id: item.tourId,
        useDate: moment(item.usingDate || item.departureDate).format(FORMAT_ONLY_DATE_REVERSE),
        count: parseInt(item.quantity || 0) + parseInt(item.childQuantity || 0) + parseInt(item.infantQuantity || 0),
        price: item?.price || 0,
      }
      if (!(item.usingDate || item.departureDate).includes('9999')) {
        result.returnedDate = moment(item.usingDate || item.departureDate)
          .add(Math.floor(item.lengthOfTour || 1) - 1, "days")
          .format(FORMAT_ONLY_DATE_REVERSE);
      }
      return result;
    })
    return {
      vouchers: (data.vouchers || []).filter(item => item.checked !== false).map(item => ({
        id: (isString(item) ? item : item.productVariantId),
        count: parseInt(item.quantity || 0),
        price: item?.price || 0
      })),
      tours: (data.tours || []).filter(item => item.checked !== false).map(item => isString(item) ? item : item.productVariantId),
      vinWonders: (data.vinWonders || []).filter(item => item.checked !== false).map(item => isString(item) ? item : item.productVariantId),
      vinWondersPromotion,
      toursPromotion,
    }
  },
  getInstallmentMonthlyPayByLimit(limit, price) {
    if (limit && price) {
      const enumLimit = {
        [PAYMENT_ENUM.METHOD.INSTALLMENT.LIMIT.SIX_MONTH]: Math.ceil(price / 6),
        [PAYMENT_ENUM.METHOD.INSTALLMENT.LIMIT.TWELVE_MONTH]: Math.ceil(price / 12),
        [PAYMENT_ENUM.METHOD.INSTALLMENT.LIMIT.SIX_TWELVE_MONTH]: Math.ceil(price / 12),
      }
      return enumLimit[limit]
    }
  }
}
