// import {} from "lodash";
export default class CartItem {
    constructor(params) {
        params = params || {};
        this.itemName = params.itemName || "";
        this.cartCode = params.cartCode || undefined;
        this.lengthOfTour = params.lengthOfTour || undefined;
        this.departureDate = params.departureDate || undefined;
        this.productVariantId = params.productVariantId || undefined;
        this.cartItemType = params.cartItemType || undefined;
        this.limitNumberOfSalesPerUser = params.limitNumberOfSalesPerUser || 0;
        this.inventory = params.inventory || undefined;
        this.urlSlug = params.urlSlug || undefined;
        this.thumbImage = params.thumbImage || {};

        this.quantity = params.quantity || 0;
        this.salePrice = params.salePrice || 0;
        this.originalPrice = params.originalPrice || 0;
        this.childOriginalPrice = params.childOriginalPrice || 0;
        this.childSalePrice = params.childSalePrice || 0;
        this.childQuantity = params.childQuantity || 0;
        this.infantOriginalPrice = params.infantOriginalPrice || 0;
        this.infantSalePrice = params.infantSalePrice || 0;
        this.infantQuantity = params.infantQuantity || 0;
        this.seniorOriginalPrice = params.seniorOriginalPrice || 0;
        this.seniorSalePrice = params.seniorSalePrice || 0;
        this.seniorQuantity = params.seniorQuantity || 0;

        this.ticketItemId = params.ticketItemId || undefined;
        this.childTicketItemId = params.childTicketItemId || undefined;
        this.seniorTicketItemId = params.seniorTicketItemId || undefined;
        this.vinWonderName = params.vinWonderName || params.itemName || undefined;
        this.vinWonderTicketName = params.vinWonderTicketName || undefined;

        this.id = params.id || undefined;
        this.siteCode = params.siteCode || undefined;
        this.usingDate = params.usingDate || undefined;
        this.errorCode = params.errorCode || undefined;
        
        this.checked = data.checked === true ? true : false;
    }

    getTotalPrice(){
        return ((this.quantity || 0) * (this.salePrice || 0))
        + ((this.childQuantity || 0) * (this.childSalePrice || 0))
        + ((this.seniorQuantity || 0) * (this.seniorSalePrice || 0))
        + ((this.infantQuantity || 0) * (this.infantSalePrice || 0))
    }
}