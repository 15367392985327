import React, { useCallback } from 'react'
// lib
import { useDispatch } from 'react-redux'
// action
import bundleAction from '../actions/bundleAction'
import { loadSuccess } from '../actions/commonAction'
import actionTypes from '../actionTypes'
import { bundleServices } from '../services'

/**
 * load air port action
 * @returns {[function(*=)]}
 */
export function loadLocationsPoints() {
  const dispatch = useDispatch()
  const loadLocationsPointsCallback = useCallback(
    (keyword, type) => dispatch(bundleAction.getLocations(keyword, type)),
    [dispatch]
  )
  return [loadLocationsPointsCallback]
}

export function setLocationsPoints() {
  const dispatch = useDispatch()
  const setLocationsPointsCallback = useCallback(
    (locations, type) => dispatch(bundleAction.setLocations(locations, type)),
    [dispatch]
  )
  return [setLocationsPointsCallback]
}

export const loadBundles = () => {
  const dispatch = useDispatch()
  const loadBundleCallback = useCallback(
    (queryClause) => dispatch(bundleAction.getBundles(queryClause)),
    [dispatch]
  )
  return [loadBundleCallback]
}

export const setBundles = () => {
  const dispatch = useDispatch()
  const setBundleCallback = useCallback(
    ({ bundleData, dataSession, bundleParams }) => dispatch(loadSuccess(actionTypes.FETCHING_BUNDLE_SEARCH_SUCCESS, { bundleData, dataSession, bundleParams })),
    [dispatch]
  )
  return [setBundleCallback]
}

export const setBundleParams = () => {
  const dispatch = useDispatch()
  const setBundleParamsCallback = useCallback(
    (queryClause) => dispatch(bundleAction.setSearchBundleParams(queryClause)),
    [dispatch]
  )
  return [setBundleParamsCallback]
}

export const loadHotelBundle = () => {
  const dispatch = useDispatch()
  const loadHotelBundleCallback = useCallback(
    (queryClause) => dispatch(bundleAction.getHotelBundle(queryClause)),
    [dispatch]
  )
  return [loadHotelBundleCallback]
}

export const loadFlightBundle = () => {
  const dispatch = useDispatch()
  const loadFlightBundleCallback = useCallback(
    (queryClause) => dispatch(bundleAction.getFlightBundle(queryClause)),
    [dispatch]
  )
  return [loadFlightBundleCallback]
}

export const setBundleFlight = () => {
  const dispatch = useDispatch()
  const setBundleFlightCallback = useCallback(
    (data) => {
      const bundleParams = bundleServices.getBundleParamsFromData(data)
      dispatch(bundleAction.setSearchBundleParams(bundleParams))
      return dispatch(loadSuccess(actionTypes.FETCHING_BUNDLE_FLIGHT_SUCCESS, data.bundleData))
    },
    [dispatch]
  )
  return [setBundleFlightCallback]
}

export const loadBundleDetail = () => {
  const dispatch = useDispatch()
  const loadBundleDetailCallback = useCallback(
    (queryClause) => dispatch(bundleAction.getBundleDetail(queryClause)),
    [dispatch]
  )
  return [loadBundleDetailCallback]
}

export const resetCounTimePhsBundle = () => {
  const dispatch = useDispatch()
  const resetCounTimePhsBundleCallback = useCallback(
    (queryClause) => dispatch(bundleAction.resetCounTimePhsBundle(queryClause)),
    [dispatch]
  )
  return [resetCounTimePhsBundleCallback]
}

/**
 * load flight detail
 * @returns {[function(*=)]}
 */
export const verifyBundleDetail = () => {
  const dispatch = useDispatch()
  const verifyBundleDetailCallback = useCallback(
    async (obj, loadingOnSuccess) => dispatch(bundleAction.verifyBundleDetail(obj, loadingOnSuccess)),
    [dispatch]
  )

  return [verifyBundleDetailCallback]
}

export function switchPoints() {
  const dispatch = useDispatch()
  return useCallback(
    (departurePointIsDomestic) => dispatch(bundleAction.switchPoints(departurePointIsDomestic)),
    []
  )
}

export const changeDepartureFlightBundle = () => {
  const dispatch = useDispatch()
  const changeDepartureFlightBundleCallback = useCallback(
    (currentFlight) => dispatch(bundleAction.changeDepartureFlightBundle(currentFlight)),
    [dispatch]
  )
  return [changeDepartureFlightBundleCallback]
}

export const changeReturnFlightBundle = () => {
  const dispatch = useDispatch()
  const changeReturnFlightBundleCallback = useCallback(
    (currentFlight) => dispatch(bundleAction.changeReturnFlightBundle(currentFlight)),
    [dispatch]
  )
  return [changeReturnFlightBundleCallback]
}

export const savePathRedirect = () => {
  const dispatch = useDispatch()
  const savePathRedirectCallback = useCallback(
    (path) => dispatch(bundleAction.savePathRedirect(path)),
    [dispatch]
  )
  return [savePathRedirectCallback]
}

export const addRoomsHotel = () => {
  const dispatch = useDispatch()

  const changeHotelCallback = useCallback(
    (rooms) => dispatch(bundleAction.addRoomsHotel(rooms)),
    [dispatch]
  )

  return [changeHotelCallback]
}

export const phsOrderComboRelease = () => {
  const dispatch = useDispatch()
  const phsOrderComboReleaseCallback = useCallback((phsId) => dispatch(bundleAction.releaseComboPhs(phsId)),
    [dispatch]
  )
  return [phsOrderComboReleaseCallback]
}

export const changeHotelBundle = () => {
  const dispatch = useDispatch()
  const changeHotelBundleCallback = useCallback(
    (status) => dispatch(bundleAction.changeHotelBundle(status)),
    [dispatch]
  )
  return [changeHotelBundleCallback]
}

export const checkCodeBundle = () => {
  const dispatch = useDispatch()
  const checkCodeBundleCallback = useCallback(
    (data) => dispatch(bundleAction.checkCodeBundle(data)),
    [dispatch]
  )
  return [checkCodeBundleCallback]
}

export const resetCheckCodeBundle = () => {
  const dispatch = useDispatch()
  const resetCheckCodeBundleCallback = useCallback(
    () => dispatch(bundleAction.resetCheckCodeBundle()),
    [dispatch]
  )
  return [resetCheckCodeBundleCallback]
}

export const setResetCurrentBundle = () => {
  const dispatch = useDispatch()
  const setResetCurrentBundleCallback = useCallback(
    () => dispatch(bundleAction.setResetCurrentBundle()),
    [dispatch]
  )
  return [setResetCurrentBundleCallback]
}

export const getFlightHotelBundle = () => {
  const dispatch = useDispatch()
  return useCallback(
    () =>
      dispatch(bundleAction.getFlightHotelBundle()),
    [dispatch]
  )
}
