import React from 'react'
import { FormattedNumber } from 'react-intl'
import clsx from 'clsx'
import PropTypes from 'prop-types'
// utils
import { getCurrentCurrency } from '../../utils/lang'
import { currencyUnit } from '../../constants/currencyUnit'

const CurrencyNumber = (props) => {
  const {
    value,
    unitCss,
    className,
    defaultCurrency,
    unit,
    suffix,
    showPlus
  } = props

  const getCurrencyUnit = () => {
    if(unit) return unit;
    const currency = defaultCurrency || getCurrentCurrency()
    switch (currency) {
      case currencyUnit.VietnameseDong:
        return 'đ'
      case currencyUnit.Dollar:
        return '$'
      case currencyUnit.Won:
        return '₩'
      default:
        return defaultCurrency
    }
  }

  return (
    <span className={className}>
      {showPlus && '+'}
      <FormattedNumber value={value} maximumFractionDigits={getCurrencyUnit() === 'đ' ? 0 : undefined}  />
      {' '}
      <span
        className={
          clsx({
            [`${unitCss}`]: !!unitCss
          })
        }
      >
        {getCurrencyUnit()}
        {suffix}
      </span>
    </span>
  )
}

CurrencyNumber.propTypes = {
  value: PropTypes.number.isRequired,
  unitCss: PropTypes.string,
  defaultCurrency: PropTypes.string
}

export default CurrencyNumber