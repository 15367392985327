/**
 * COMBO or BUNDLES
 */
import {
  bookingFlight,
  createComboFlightName,
  createComboHotelName,
  createComboHotelPrice,
  createComboName,
  createRoomOccupancy,
  getIncreasePrice,
} from "@utils/gtmHelpers";
import { sumBy } from "lodash";
import moment from "moment";
import {
  DEFAULT_FORMAT_DATE,
  FORMAT_DATE_TIME_AFTER,
  FORMAT_ONLY_DATE_DOT,
  LOCAL_STORAGE_KEY,
} from "src/constants";
import { gtmEvent, TBD } from "src/helpers/gtmEvent";
import { flightUtils, hotelUtils, LocalStorage } from "..";
import { ENUM_COMBO, ENUM_FLIGHT, ENUM_HOTEL } from "./enumGtm";

export const createGtmComboImpression = ({
  dataComboImpresion = [],
  comboInfo = {},
}) => {
  const { adult, child, infant, passenger } = createRoomOccupancy({
    roomOccupancy: comboInfo?.roomOccupancy,
  });
  const bookingRoomNight = hotelUtils.getLengthOfStay(
    moment(comboInfo?.departureDate).format(FORMAT_ONLY_DATE_DOT),
    moment(comboInfo?.returnDate).format(FORMAT_ONLY_DATE_DOT)
  );
  const productImpressionArray = dataComboImpresion.map((el) => {
    const { hotel = {}, flight = {} } = el;
    return {
      name: createComboName({
        bookingRoomNight,
        name: hotel?.name,
        startPointName: comboInfo?.startPointName,
      }),
      id: `${hotel?.hotelId}-${flight?.fightId || ""}`,
      price: el?.pricePerson,
      brand: hotel?.name,
      category: ENUM_COMBO.category,
      list: ENUM_COMBO.list,
      position: el.position,
      // Add these custom dimensions
      dimension29: hotel?.address,
      dimension30: ENUM_COMBO.category,
      dimension50: !!comboInfo?.departureDate
        ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
        : null,
      dimension51: !!comboInfo?.returnDate
        ? moment(comboInfo?.returnDate).format(DEFAULT_FORMAT_DATE)
        : null,
    };
  });

  const ecommerceObject = {
    ecommerce: { impressions: productImpressionArray },
    event: gtmEvent.PRODUCT_IMPRESSIONS,
    // Add these custom dimensions
    booking_service_type: ENUM_COMBO.category,
    booking_origin: comboInfo?.startPointName,
    booking_destination: comboInfo?.endPointName,
    booking_start_date: !!comboInfo?.departureDate
      ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_end_date: !!comboInfo?.returnDate
      ? moment(comboInfo?.returnDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_no_of_adults: adult,
    booking_no_of_children: child,
    booking_no_of_infants: infant,
    booking_flight: bookingFlight({
      startPoint: comboInfo?.startPointName,
      itineraryType: "2",
      endPoint: comboInfo?.endPointName,
    }),
    booking_no_of_rooms: comboInfo?.room,
    booking_room_night: bookingRoomNight,
  };

  return ecommerceObject;
};

export const createComboProductClick = ({
  comboData = {},
  comboInfo = {},
  index = 0,
}) => {
  const { adult, child, infant, passenger } = createRoomOccupancy({
    roomOccupancy: comboInfo?.roomOccupancy,
  });
  const bookingRoomNight = hotelUtils.getLengthOfStay(
    moment(comboInfo?.departureDate).format(FORMAT_ONLY_DATE_DOT),
    moment(comboInfo?.returnDate).format(FORMAT_ONLY_DATE_DOT)
  );
  const { hotel = {}, flight = {} } = comboData;
  const productClickedArray = [
    {
      name: createComboName({
        bookingRoomNight,
        name: hotel?.name,
        startPointName: comboInfo?.startPointName,
      }),
      id: `${hotel?.hotelId}-${flight?.fightId || ""}`,
      price: comboData?.totalPrice,
      brand: hotel?.name,
      category: ENUM_COMBO.category,
      list: ENUM_COMBO.list,
      position: index + 1,
      // Add these custom dimensions
      dimension29: hotel?.address,
      dimension30: ENUM_COMBO.category,
      dimension50: !!comboInfo?.departureDate
        ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
        : null,
      dimension51: !!comboInfo?.returnDate
        ? moment(comboInfo?.returnDate).format(DEFAULT_FORMAT_DATE)
        : null,
    },
  ];

  const ecommerceObject = {
    ecommerce: {
      click: {
        products: productClickedArray,
        actionField: { list: ENUM_COMBO.list },
      },
    },
    event: gtmEvent.PRODUCT_CLICK,
    booking_service_type: ENUM_COMBO.category,
    booking_origin: comboInfo?.startPointName,
    booking_destination: comboInfo?.endPointName,
    booking_start_date: !!comboInfo?.departureDate
      ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_end_date: !!comboInfo?.returnDate
      ? moment(comboInfo?.returnDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_no_of_adults: adult,
    booking_no_of_children: child,
    booking_no_of_infants: infant,
    booking_flight: bookingFlight({
      startPoint: comboInfo?.startPointName,
      itineraryType: "2",
      endPoint: comboInfo?.endPointName,
    }),
    booking_no_of_rooms: comboInfo?.room,
    booking_room_night: bookingRoomNight,
  };
  return ecommerceObject;
};

export const createGtmComboProductDetail = ({
  comboData = {},
  comboInfo = {},
  convertCurrency = undefined
}) => {
  const { hotel = {}, flight = {} } = comboData;
  const { adult, child, infant, passenger } = createRoomOccupancy({
    roomOccupancy: comboInfo?.roomOccupancy,
  });
  const bookingRoomNight = hotelUtils.getLengthOfStay(
    moment(comboInfo?.departureDate).format(FORMAT_ONLY_DATE_DOT),
    moment(comboInfo?.returnDate).format(FORMAT_ONLY_DATE_DOT)
  );
  const productDetailArray = [
    {
      // Fix as below
      name: createComboName({
        bookingRoomNight,
        name: hotel.name,
        startPointName: comboInfo?.startPointName,
      }), // Name or ID is required.
      id: `${hotel?.hotelId}-${flight?.fightId || ""}`,
      price: convertCurrency(comboData?.pricePerson) ?? comboData?.pricePerson,
      brand: hotel?.name,
      category: ENUM_COMBO.category,
      // Add these custom dimensions
      dimension29: hotel?.address,
      dimension30: ENUM_COMBO.category,
      dimension50: !!comboInfo?.departureDate
        ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
        : null,
      dimension51: !!comboInfo?.returnDate
        ? moment(comboInfo?.returnDate).format(DEFAULT_FORMAT_DATE)
        : null,
    },
  ];

  const ecommerceObject = {
    event: gtmEvent.PRODUCT_DETAIL,
    booking_service_type: ENUM_COMBO.category,
    booking_origin: comboInfo?.startPointName,
    booking_destination: comboInfo?.endPointName,
    booking_start_date: !!comboInfo?.departureDate
      ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_end_date: !!comboInfo?.returnDate
      ? moment(comboInfo?.returnDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_no_of_adults: adult,
    booking_no_of_children: child,
    booking_no_of_infants: infant,
    booking_flight: bookingFlight({
      startPoint: comboInfo?.startPointName,
      itineraryType: "2",
      endPoint: comboInfo?.endPointName,
    }),
    booking_no_of_rooms: comboInfo?.room,
    booking_room_night: bookingRoomNight,
    ecommerce: {
      detail: {
        products: productDetailArray,
      },
    },
  };
  return ecommerceObject;
};

export const createGtmComboHotelProductDetail = ({
  hotelData = {},
  roomData = {},
}) => {
  const { hotel = {}, comboInfo = {} } = hotelData;
  const { rates = {}, roomtype = {} } = roomData;
  const { adult, child, infant, passenger } = createRoomOccupancy({
    roomOccupancy: comboInfo?.roomOccupancy,
  });
  const bookingRoomNight = hotelUtils.getLengthOfStay(
    moment(comboInfo?.departureDate).format(FORMAT_ONLY_DATE_DOT),
    moment(comboInfo?.returnDate).format(FORMAT_ONLY_DATE_DOT)
  );
  const price = createComboHotelPrice({
    bookingRoomNight,
    increaseTotalPrice: rates.increaseTotalPrice,
    room: comboInfo.room,
  });
  const productDetailArray = [
    {
      name: createComboHotelName({
        hotelName: hotel?.name,
        roomName: roomtype.name,
      }),
      id: `${hotel?.hotelId}-${roomtype?.id}`,
      price,
      brand: hotel?.name,
      category: ENUM_COMBO.category,
      dimension29: hotel?.address,
      dimension30: ENUM_HOTEL.category,
      dimension39: !!comboInfo?.departureDate
        ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
        : null,
      dimension40: !!comboInfo?.departureDate
        ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
        : null,
      dimension41: hotel?.name,
      dimension42: hotel?.hotelId,
      dimension43: TBD,
      dimension44: hotel?.rating,
      dimension45: hotel?.address,
      dimension46: roomtype?.name,
      dimension47: roomtype?.id,
      dimension48: roomtype?.area,
      dimension49: roomtype?.maxOccupancy,
    },
  ];

  const ecommerceObject = {
    event: gtmEvent?.PRODUCT_DETAIL,
    booking_service_type: ENUM_COMBO.category,
    booking_destination: hotel?.address,
    booking_start_date: !!comboInfo?.departureDate
      ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_end_date: !!comboInfo?.returnDate
      ? moment(comboInfo?.returnDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_no_of_adults: adult,
    booking_no_of_children: child,
    booking_no_of_infants: infant,
    booking_no_of_rooms: comboInfo?.room,
    booking_room_night: bookingRoomNight,
    ecommerce: {
      detail: {
        products: productDetailArray,
      },
    },
  };

  return ecommerceObject;
};

export const createGtmSelectComboRoomRateHotel = ({ dataSelect }) => {
  const { rate = {}, roomtype = {}, urlParam = {}, hotel } = dataSelect;
  // const bookingRoomNight = hotelUtils.getLengthOfStay(
  //   moment(urlParam?.departureDate).format(FORMAT_ONLY_DATE_DOT),
  //   moment(urlParam?.returnDate).format(FORMAT_ONLY_DATE_DOT)
  // );
  return {
    event: gtmEvent.SELECT_ROOM_RATE,
    // room_rate: rate?.increaseTotalPrice / (urlParam.room || 1) / (bookingRoomNight || 1),
    room_rate: rate?.ratePlanName || null,
    hotel_name: hotel?.name,
    room_name: roomtype?.name,
  };
};

export const createGtmAddToCartCombo = ({
  comboData = {},
  comboInfo = {},
  dataRooms = {},
  actionType = "add",
}) => {
  const { hotel = {}, flight = {} } = comboData;
  const { adult, child, infant, passenger } = createRoomOccupancy({
    roomOccupancy: comboInfo?.roomOccupancy,
  });

  const minPriceCombo =
    LocalStorage.get(LOCAL_STORAGE_KEY.MIN_PRICE_COMBO) || 0;

  const bookingRoomNight = hotelUtils.getLengthOfStay(
    moment(comboInfo?.departureDate).format(FORMAT_ONLY_DATE_DOT),
    moment(comboInfo?.returnDate).format(FORMAT_ONLY_DATE_DOT)
  );
  const rooms = Object.values(dataRooms);
  const roomArr = Array(comboInfo.room).fill(0);

  const hotelDataLayer = roomArr.map((e) => {
    const el = rooms[0];
    const price = createComboHotelPrice({
      bookingRoomNight,
      increaseTotalPrice: el.rate.increaseTotalPrice,
      room: comboInfo.room,
    });
    return {
      //Hotel
      name: createComboHotelName({
        hotelName: hotel?.name,
        roomName: el?.roomtype?.name,
      }),
      id: `${hotel?.hotelId}`,
      price,
      brand: hotel?.name,
      category: ENUM_COMBO.category,
      variant: TBD,
      quantity: bookingRoomNight,
      dimension29: hotel?.address,
      dimension30: ENUM_HOTEL.category,
      dimension39: !!comboInfo?.returnDate
        ? moment(comboInfo?.returnDate).format(DEFAULT_FORMAT_DATE)
        : null,
      dimension40: !!comboInfo?.departureDate
        ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
        : null,
      dimension41: hotel?.name,
      dimension42: hotel?.hotelId,
      dimension43: ENUM_HOTEL.category,
      dimension44: hotel?.rating,
      dimension45: hotel?.address,
      dimension46: el?.roomtype?.name,
      dimension47: el?.roomtype?.id,
      dimension48: el?.roomtype?.area,
      dimension49: el?.roomtype?.defaultOccupancy,
    };
  });

  const productAddToCartArray = [
    // Fix as below
    {
      //Combo
      name: createComboName({
        bookingRoomNight,
        name: hotel?.name,
        startPointName: comboInfo?.startPointName,
      }), // Name or ID is required.
      id: `${hotel?.hotelId}-${flight?.flightId}`,
      price: minPriceCombo,
      brand: hotel?.name,
      category: ENUM_COMBO.category,
      quantity: adult + child + infant,
      dimension29: hotel?.address,
      dimension30: ENUM_COMBO.category,
      dimension50: !!comboInfo?.departureDate
        ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
        : null,
      dimension51: !!comboInfo?.returnDate
        ? moment(comboInfo?.returnDate).format(DEFAULT_FORMAT_DATE)
        : null,
    },
    ...hotelDataLayer,
  ];

  const ecommerceObject = {
    event: gtmEvent.PRODUCT_ADD_TO_CART,
    // Add these custom dimensions
    booking_service_type: ENUM_COMBO.category,
    booking_origin: comboInfo?.startPointName,
    booking_destination: comboInfo?.endPointName,
    booking_start_date: !!comboInfo?.departureDate
      ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_end_date: !!comboInfo?.returnDate
      ? moment(comboInfo?.returnDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_no_of_adults: adult,
    booking_no_of_children: child,
    booking_no_of_infants: infant,
    booking_flight: bookingFlight({
      startPoint: comboInfo?.startPointName,
      itineraryType: "2",
      endPoint: comboInfo?.endPointName,
    }),
    booking_no_of_rooms: comboInfo?.room,
    booking_room_night: bookingRoomNight,
    ecommerce: {
      add: {
        products: productAddToCartArray,
      },
    },
  };

  return ecommerceObject;
};

export const createGtmAddFlightToCartCombo = ({
  flight = [],
  comboData = {},
  comboInfo = {},
  actionType,
  isReturnFlight,
  isAll,
}) => {
  const { hotel = {} } = comboData;
  const { adult, child, infant, passenger } = createRoomOccupancy({
    roomOccupancy: comboInfo?.roomOccupancy || "",
  });

  const minPriceCombo =
    LocalStorage.get(LOCAL_STORAGE_KEY.MIN_PRICE_COMBO) || 0;

  const bookingRoomNight = hotelUtils.getLengthOfStay(
    moment(comboInfo?.departureDate).format(FORMAT_ONLY_DATE_DOT),
    moment(comboInfo?.returnDate).format(FORMAT_ONLY_DATE_DOT)
  );

  let productAddToCartArray = (flight || [])
    .filter((el) => el)
    .map((flightData, flIdx) => {
      const { departureName } = createComboFlightName({
        endPoint: flightData?.endPoint,
        startPoint: flightData?.startPoint,
      });
      const name = departureName;
      const price = getIncreasePrice({ flightData });
      return {
        name,
        id: flightData?.flightNumber,
        price,
        brand: flightData?.listSegment?.[0]?.airlineName || "",
        category: ENUM_COMBO.category,
        variant: flightData?.listSegment?.[0]?.class || "",
        quantity: adult + child + infant,
        // Add these custom dimensions
        dimension30: isReturnFlight
          ? ENUM_FLIGHT.flight_return
          : flIdx === 0
            ? ENUM_FLIGHT.flight_depart
            : ENUM_FLIGHT.flight_return,
        dimension33: flightData?.startDate
          ? moment(flightData?.startDate).format(FORMAT_DATE_TIME_AFTER)
          : null,
        dimension34: flightData?.endDate
          ? moment(flightData?.endDate).format(FORMAT_DATE_TIME_AFTER)
          : null,
        dimension35: flightData?.startPoint,
        dimension36: flightData?.endPoint,
        dimension37: flightData?.duration,
        dimension38: flightData?.stops,
      };
    });
  if (isAll) {
    const roomtype = hotel?.roomRates?.[0]?.roomType || hotel?.roomtype;
    const rate = hotel?.roomRates?.[0]?.ratePlan[0] || hotel?.rate;
    const roomArr = Array(comboInfo?.room || 1).fill(0);
    const hotelData = roomArr.map(() => {
      const price = createComboHotelPrice({
        bookingRoomNight,
        increaseTotalPrice: rate.increaseTotalPrice,
        room: comboInfo.room,
      });
      return {
        name: createComboHotelName({
          hotelName: hotel?.name,
          roomName: roomtype.name,
        }),
        id: hotel?.hotelId,
        price,
        brand: hotel?.name,
        category: ENUM_COMBO.category,
        variant: TBD,
        quantity: bookingRoomNight,
        dimension29: hotel?.address,
        dimension30: ENUM_HOTEL.category,
        dimension39: !!comboInfo?.departureDate
          ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
          : null,
        dimension40: !!comboInfo?.departureDate
          ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
          : null,
        dimension41: hotel?.name,
        dimension42: hotel?.hotelId,
        dimension43: ENUM_COMBO.category,
        dimension44: hotel?.rating,
        dimension45: hotel?.address,
        dimension46: roomtype?.name,
        dimension47: roomtype?.id,
        dimension48: roomtype?.area,
        dimension49: roomtype.defaultOccupancy,
      };
    });
    productAddToCartArray = [
      {
        name: createComboName({
          bookingRoomNight,
          name: hotel?.name,
          startPointName: comboInfo?.startPointName,
        }),
        id: `${hotel?.hotelId}-${flight?.fightId || ""}`,
        // price: comboData?.totalPrice,
        price: minPriceCombo,
        brand: hotel?.name,
        category: ENUM_COMBO.category,
        quantity: adult + child + infant,
        // Add these custom dimensions
        dimension29: hotel?.address,
        dimension30: ENUM_COMBO.category,
        dimension50: !!comboInfo?.departureDate
          ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
          : null,
        dimension51: !!comboInfo?.returnDate
          ? moment(comboInfo?.returnDate).format(DEFAULT_FORMAT_DATE)
          : null,
      },
      ...hotelData,
      ...productAddToCartArray,
    ];
  }
  let ecommerceObject = {};
  if (actionType === "add") {
    ecommerceObject.event = gtmEvent.PRODUCT_ADD_TO_CART;
  } else {
    ecommerceObject.event = gtmEvent.PRODUCT_REMOVE_FROM_CART;
    ecommerceObject.booking_no_of_rooms = comboInfo?.room;
    ecommerceObject.booking_room_night = bookingRoomNight;
  }

  ecommerceObject = {
    ...ecommerceObject,
    booking_service_type: ENUM_COMBO.category,
    booking_origin: comboInfo?.startPointName,
    booking_destination: comboInfo?.endPointName,
    booking_start_date: !!comboInfo?.departureDate
      ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_end_date: !!comboInfo?.returnDate
      ? moment(comboInfo?.returnDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_no_of_adults: adult,
    booking_no_of_children: child,
    booking_no_of_infants: infant,
    booking_flight: bookingFlight({
      startPoint: comboInfo?.startPointName,
      endPoint: comboInfo?.endPointName,
      itineraryType: "2",
    }),
    ecommerce: {
      [actionType]: {
        products: productAddToCartArray,
      },
    },
  };

  return ecommerceObject;
};

export const createGtmComboProductViewTrip = ({
  departureFlight = {},
  returnFlight = {},
  comboInfo = {},
  comboData = {},
  gtmType = gtmEvent.PRODUCT_VIEW_TRIP,
}) => {
  const { adult, child, infant, passenger } = createRoomOccupancy({
    roomOccupancy: comboInfo?.roomOccupancy,
  });

  const bookingRoomNight = hotelUtils.getLengthOfStay(
    moment(comboInfo?.departureDate).format(FORMAT_ONLY_DATE_DOT),
    moment(comboInfo?.returnDate).format(FORMAT_ONLY_DATE_DOT)
  );
  const ecommerceObject = {
    event: gtmType,
    booking_service_type: ENUM_COMBO.category,

    booking_origin: comboInfo?.startPointName,
    booking_destination: comboInfo?.endPointName,
    booking_start_date: !!comboInfo?.departureDate
      ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_end_date: !!comboInfo?.returnDate
      ? moment(comboInfo?.returnDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_no_of_adults: adult,
    booking_no_of_children: child,
    booking_no_of_infants: infant,
    booking_flight: `${departureFlight.startPoint}:${departureFlight.endPoint}-${returnFlight.startPoint}:${returnFlight.endPoint}`,
    booking_no_of_rooms: comboInfo?.room,
    booking_room_night: bookingRoomNight,
  };
  return ecommerceObject;
};

export const createGtmComboProductViewCart = ({
  departureFlight = {},
  returnFlight = {},
  comboInfo = {},
  comboData = {},
}) => {
  const { adult, child, infant, passenger } = createRoomOccupancy({
    roomOccupancy: comboInfo?.roomOccupancy,
  });

  const bookingRoomNight = hotelUtils.getLengthOfStay(
    moment(comboInfo?.departureDate).format(FORMAT_ONLY_DATE_DOT),
    moment(comboInfo?.returnDate).format(FORMAT_ONLY_DATE_DOT)
  );
  const ecommerceObject = {
    event: gtmEvent.PRODUCT_VIEW_CART,
    booking_service_type: ENUM_COMBO.category,

    booking_origin: comboInfo?.startPointName,
    booking_destination: comboInfo?.endPointName,
    booking_start_date: !!comboInfo?.departureDate
      ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_end_date: !!comboInfo?.returnDate
      ? moment(comboInfo?.returnDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_no_of_adults: adult,
    booking_no_of_children: child,
    booking_no_of_infants: infant,
    booking_flight: `${departureFlight.startPoint}:${departureFlight.endPoint}-${returnFlight.startPoint}:${returnFlight.endPoint}`,
    booking_no_of_rooms: comboInfo?.room,
    booking_room_night: bookingRoomNight,
  };
  return ecommerceObject;
};

export const createGtmComboProductCheckout = ({
  step = 1,
  comboData = {},
  comboInfo = {},
  roomsData = {},
  contactInfo,
}) => {
  const { hotel = {}, flight = {} } = comboData;
  const roomsInfo = Object.values(roomsData);
  const flights = [flight?.departureFlights, flight?.returnFlights];
  const { adult, child, infant, passenger } = createRoomOccupancy({
    roomOccupancy: comboInfo?.roomOccupancy,
  });
  const bookingRoomNight = hotelUtils.getLengthOfStay(
    moment(comboInfo?.departureDate).format(FORMAT_ONLY_DATE_DOT),
    moment(comboInfo?.returnDate).format(FORMAT_ONLY_DATE_DOT)
    );
    
    const roomArr = Array(comboInfo?.room || 1).fill(1);
    
    const hotelData = roomArr.map(() => {
    const room = roomsInfo[0];
    const { rate = {}, roomtype = {} } = room;
    const price = createComboHotelPrice({
      bookingRoomNight,
      increaseTotalPrice: rate.increaseTotalPrice,
      room: comboInfo.room,
    });
    return {
      name: createComboHotelName({
        hotelName: hotel.name,
        roomName: roomtype?.name,
      }),
      id: `${hotel?.hotelId}-${roomtype?.id}`,
      price,
      brand: hotel?.name,
      category: ENUM_COMBO.category,
      variant: TBD,
      quantity: bookingRoomNight,
      dimension29: hotel?.address,
      dimension30: ENUM_HOTEL.category,
      dimension39: !!comboInfo?.departureDate
        ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
        : null,
      dimension40: !!comboInfo?.departureDate
        ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
        : null,
      dimension41: hotel?.name,
      dimension42: hotel?.hotelId,
      dimension43: ENUM_COMBO.category,
      // dimension44: hotel?.rating,
      dimension44: hotel?.rating || TBD,
      dimension45: hotel?.address,
      dimension46: roomtype?.name,
      dimension47: roomtype?.id,
      dimension48: roomtype?.area,
      dimension49: roomtype?.maxOccupancy,
    };
  });

  const flightData = (flights || []).map((flight, flIdx) => {
    const price = getIncreasePrice({ flightData: flight });
    const { departureName } = createComboFlightName({
      startPoint: flight?.startPoint,
      endPoint: flight?.endPoint,
    });
    let flightProduct = {
      name: departureName,
      id: flight?.flightNumber,
      price,
      brand: flight?.listSegment?.[0]?.airlineName || "",
      category: ENUM_COMBO.category,
      variant: flight?.listSegment?.[0]?.class || "",
      quantity: adult + child + infant,
      // Add these custom dimensions
      dimension30:
        flIdx === 0 ? ENUM_FLIGHT.flight_depart : ENUM_FLIGHT.flight_return,
      dimension33: flight?.startDate
        ? moment(flight?.startDate).format(FORMAT_DATE_TIME_AFTER)
        : null,
      dimension34: flight?.endDate
        ? moment(flight?.endDate).format(FORMAT_DATE_TIME_AFTER)
        : null,
      dimension35: flight?.startPoint,
      dimension36: flight?.endPoint,
      dimension37: flight?.duration,
      dimension38: flight?.stops,
    };

    if (step === 2) {
      const { baggageReturn, baggageDeparture } = flightUtils.getBaggageTotal(
        contactInfo?.listPassengers
      );
      flightProduct.metric5 = flIdx === 0 ? baggageDeparture : baggageReturn;
    }
    return flightProduct;
  });
  const minPriceCombo =
  LocalStorage.get(LOCAL_STORAGE_KEY.MIN_PRICE_COMBO) || 0;

  const productPurchasedArray = [
    {
      name: createComboName({
        bookingRoomNight,
        name: hotel?.name,
        startPointName: comboInfo?.startPointName,
      }),
      id: `${hotel?.hotelId}-${flight?.fightId || ""}`,
      price: comboData?.totalPrice,
      price: minPriceCombo,
      brand: hotel?.name,
      category: ENUM_COMBO.category,
      quantity: adult + child + infant,
      // Add these custom dimensions
      dimension29: hotel?.address,
      dimension30: ENUM_COMBO.category,
      dimension50: !!comboInfo?.departureDate
        ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
        : null,
      dimension51: !!comboInfo?.returnDate
        ? moment(comboInfo?.returnDate).format(DEFAULT_FORMAT_DATE)
        : null,
    },
    ...hotelData,
    ...flightData,
  ];
  const ecommerceObject = {
    event: gtmEvent.PRODUCT_CHECKOUT,
    // Add these custom dimensions
    booking_service_type: ENUM_COMBO.category,
    booking_origin: comboInfo?.startPointName,
    booking_destination: comboInfo?.endPointName,
    booking_start_date: !!comboInfo?.departureDate
      ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_end_date: !!comboInfo?.returnDate
      ? moment(comboInfo?.returnDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_no_of_adults: adult,
    booking_no_of_children: child,
    booking_no_of_infants: infant,
    booking_flight: bookingFlight({
      startPoint: comboInfo?.startPointName,
      endPoint: comboInfo?.endPointName,
      itineraryType: "2",
    }),
    booking_no_of_rooms: comboInfo?.room,
    booking_room_night: bookingRoomNight,

    ecommerce: {
      checkout: {
        products: productPurchasedArray,
        actionField: { step },
      },
    },
  };
  return ecommerceObject;
};
