import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Avatar as AntAvatar } from 'antd'
import { stringExtensions } from '../../extensions'

const Avatar = (props) => {
  const {
    avatarUrl = null,
    fullName = null,
    size
  } = props

  return (
    <AntAvatar
      size={size}
      src={avatarUrl}
      icon={fullName ? null : 'user'}
      className={
        clsx({
          'g-font-size-20 g-line-height-24 g-font-weight-500 vpt-color-primary-v4 g-brd-primary vpt-bg-primary__opacity-0_2 border': !avatarUrl
        })
      }
    >
      {stringExtensions.getAbbreviate(fullName).toUpperCase()}
    </AntAvatar>
  )
}

Avatar.propTypes = {
  avatarUrl: PropTypes.string,
  fullName: PropTypes.string,
  size: PropTypes.number
}

export default Avatar