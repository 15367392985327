import { getMomentDate } from '../utils/common';

export default class FlightWeekPrice {

    constructor(data = {}) {
        this.setData(data);
    }

    setData(data = {}) {
        this.date = getMomentDate(data.date);
        this.airlineCode = data.airlineCode || undefined;
        this.airlineName = data.airlineName || undefined;
        this.airlineImage = data.airlineImage || undefined;
        this.netPrice = data.netPrice || 0;
        this.totalPrice = data.totalPrice || undefined;
        this.startPoint = data.startPoint || undefined;
        this.startPointName = data.startPointName || undefined;
        this.endPoint = data.endPoint || undefined;
        this.endPointName = data.endPointName || undefined;
    }

}