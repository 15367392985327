import React from 'react'
// lib
import PropTypes from 'prop-types'
import { Rate as AntRate } from 'antd'

const Rate = (props) => {
  const {
    count,
    star
  } = props

  return (
    <AntRate
      allowHalf
      disabled
      count={count}
      value={star}
      className='vpt-rate d-flex g-color-primary'
    />
  )
}

Rate.propTypes = {
  count: PropTypes.number.isRequired,
  star: PropTypes.number
}

export default Rate