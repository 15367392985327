import { createSelector } from 'reselect'

const getPageBanner = state => state.banner.banners
const getDownloadApp = state => state.banner.downloadApp

export const selectPageBanner = () =>
  createSelector(
    getPageBanner,
    banners => banners
  )

export const selectDownloadAppBanner = () =>
createSelector(
  getDownloadApp,
  downloadApp => downloadApp
)
