import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

//actions
import voucherActions, { clearAddToCartNotice } from '../actions/voucherAction'
import bannerAction from '../actions/bannerAction'

/**
 *
 * @type {function(*=): *}
 */
export const getCategoriesCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (voucherId) => {
      dispatch(voucherActions.getCategories(voucherId))
    },
    [dispatch]
  )
}

export const getPageBannerCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (bannerId) => {
      dispatch(bannerAction.getPageBanner(bannerId))
    },
    [dispatch]
  )
}

export const clearVoucherNotice = () => {
  const dispatch = useDispatch()
  return useCallback(
    () => dispatch(clearAddToCartNotice()),
    [dispatch]
  )
}

export const getVoucherGroup = () => {
  const dispatch = useDispatch()
  return useCallback(
    (queryClause) => dispatch(voucherActions.getVoucherGroup(queryClause)),
    [dispatch]
  )
}

export const getVoucherDetailCallback = () => {
  const dispatch = useDispatch()

  return useCallback(
    (queryClause) => dispatch(voucherActions.getVoucherDetail(queryClause)),
    [dispatch]
  )
}

export const getVoucherLocation = () => {
  const dispatch = useDispatch()

  return useCallback(
    (queryClause) => dispatch(voucherActions.getLocation(queryClause)),
    [dispatch]
  )
}

export const getVoucherSpecial = () => {
  const dispatch = useDispatch()
  return useCallback(
    () => dispatch(voucherActions.getVoucherSpecial()),
    [dispatch]
  )
}
