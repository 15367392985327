import React, { useEffect, useRef, useState } from 'react'
import { Modal, Spin, Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { checkUserModalCallback, loginModalCallback } from 'src/hooks/auth';
import { useDispatch, useSelector } from 'react-redux';
import { selectErrorCheckUser, selectErrorLoginVpt, selectIsCheckUserSuccess } from 'src/reselect/authSelector';
import { FORMAT_PHONE, validateError } from 'src/constants';
import authAction from 'src/actions/authAction';
import { selectLoadingAuthLoginVpt, selectLoadingCheckUser } from 'src/reselect/fetchingSelector';
import { every, isNull } from 'lodash';
import { getLoginAuth0, getRegisterAuth0 } from 'src/extensions/querystring';
import './modelLogin.scss'
import { getLanguagePath } from 'src/helpers/functions';

const ModalLogin = ({
    visible,
    onCancel,
    isReload = true
}) => {
    const intl = useIntl()
    const dispatch = useDispatch()

    const emailRef = useRef();
    const passwordRef = useRef();

    const [emailOrPhone, setEmailOrPhone] = useState("");
    const [password, setPassword] = useState("");
    const [rememberUser, setRememberUser] = useState(false);
    const [error, setError] = useState(null);
    const [errorEmailOrPhone, setErrorEmailOrPhone] = useState(null);

    const onLoginModal = loginModalCallback();
    const onCheckUserModal = checkUserModalCallback();

    const isLoadingCheckUser = useSelector(selectLoadingCheckUser);
    const isLoading = useSelector(selectLoadingAuthLoginVpt);
    const errorLogin = useSelector(selectErrorLoginVpt());
    const errorCheckUser = useSelector(selectErrorCheckUser());
    const checkIsUserSuccess = useSelector(selectIsCheckUserSuccess());

    const validateForm = () => {
        let err = null;
        if (!emailOrPhone) {
            err = (
                <span>
                * {validateError.requiredEmailOrPhone}
                </span>
            );
            emailRef.current.focus();
        } else if (!password) {
            err = (
                <span>
                * {validateError.requiredPasswordVPT}
                </span>
            );
            passwordRef.current.focus();
        }

        setError(err);
        return err === null;
    };
    
    const handleLogin = () => {
        if (validateForm()) {
            let body = {
                appVersion: "",
                appPlatform: "web",
                username: emailOrPhone,
                password: password,
                deviceId: "",
                channel: "vpt"
            };

            onLoginModal({
                body,
                onSuccess: onCancel,
                isRemember: rememberUser,
                isReload
            });
        }
    };

    const onEnter = event => {
        if (event.keyCode === 13) {
            event.preventDefault();
            handleLogin();
        }
    };

    const handleBlur = () => {
        setEmailOrPhone(FORMAT_PHONE(emailOrPhone));
        onCheckUserModal(FORMAT_PHONE(emailOrPhone));
    };
    
    useEffect(() => {
        if (errorCheckUser) {
            setErrorEmailOrPhone(errorCheckUser.message);
            dispatch(authAction.resetCheckUser());
        } else if (checkIsUserSuccess) {
        if (every(checkIsUserSuccess, isNull)) {
            setErrorEmailOrPhone(<span> <FormattedMessage
                id="Validate.Modal.AccountDoesNotExist"
                defaultMessage="Tài khoản không tồn tại"
              /></span>);
            dispatch(authAction.resetCheckUser());
        } else {
            setErrorEmailOrPhone(null);
            dispatch(authAction.resetCheckUser());
        }
        }
    }, [isLoadingCheckUser])

    const onClickForgotPassword = ({}) => {
        localStorage.setItem('isOpenForgotPassword', true);
        window.open(getLoginAuth0(String(window.location)), "_self")
    }

    const redirectToRegister = ({}) => {
        window.open(getRegisterAuth0(String(window.location)), "_self")
    }

    return (
        <Modal className="login-modal-container"
            title={
                <span className="modal-title">
                    <FormattedMessage
                        id="Login.Login"
                        defaultMessage="Đăng nhập / Login"
                    />
                </span>
            }
            visible={visible}
            destroyOnClose
            maskClosable={false}
            footer={null}
            onCancel={onCancel}
        >
            <Spin spinning={isLoading}>
                <form id="login" className="tab-pane">
                    <div className="form-group">
                        <input
                            name="email"
                            type="email"
                            ref={emailRef}
                            className="login-input-custom"
                            placeholder={
                                intl.formatMessage({
                                    id: 'Login.EmailOrPhone',
                                    defaultMessage: 'Email / Số điện thoại'
                                })
                            }
                            value={emailOrPhone}
                            onKeyUp={onEnter}
                            onChange={e => setEmailOrPhone(e.target.value)}
                            onBlur={() => { handleBlur() }}
                        />
                        {errorEmailOrPhone ? <p className="error-message-register">{errorEmailOrPhone}</p> : null}
                    </div>

                    <div className="form-group">
                        <input
                            type="password"
                            name="password"
                            ref={passwordRef}
                            className="login-input-custom"
                            placeholder={
                                intl.formatMessage({
                                    id: 'Login.Password',
                                    defaultMessage: 'Mật khẩu'
                                })
                            }
                            value={password}
                            onKeyUp={onEnter}
                            onChange={e => setPassword(e.target.value)}
                        />
                        {error ? <p className="error-message">{error}</p> : null}
                        {errorLogin && errorLogin.errorCode && (
                            <p className="error-message">
                                <div className="errorCode">{errorLogin.message}</div>
                            </p>
                        )}
                    </div>

                    <div className="remember">
                        <div className="remember-item">
                            <input
                                type="checkbox"
                                className="login-input-custom"
                                id="exampleCheck1"
                                checked={rememberUser}
                                onClick={() => setRememberUser(!rememberUser)}
                            />
                            <label className="remember-label right" htmlFor="exampleCheck1">
                                <FormattedMessage
                                    id="Login.RememberMe"
                                    defaultMessage={"Ghi nhớ tài khoản"}
                                />
                            </label>
                        </div>
                        <div className="remember-item forgot-pass">
                            <p
                                onClick={onClickForgotPassword}
                                style={{ cursor: 'pointer' }}
                            >
                                <FormattedMessage
                                    id="Login.ResetPassword"
                                    defaultMessage={"Quên mật khẩu"}
                                />
                            </p>
                        </div>
                    </div>

                    <Button
                        type="button"
                        id="btn-login"
                        className="login-form-button"
                        onClick={handleLogin}
                    >
                        <FormattedMessage
                            id="Login.Login"
                            defaultMessage="Đăng nhập"
                        />
                    </Button>

                    <div className="login-footer">
                        <p className="login-footer-question">
                            <FormattedMessage
                                id="LoginTab.AgreePolicy"
                                defaultMessage={
                                    "Bằng việc đăng nhập, tôi đồng ý với Vinpearl về Điều kiện điều khoản và Chính sách bảo mật"
                                }
                                values={{
                                    termsOfService: (
                                        <a href={`https://booking.vinpearl.com/${getLanguagePath()}/dieu-khoan/dieu-khoan-chung?_ga=2.268495160.814835106.1647245081-104127708.1647245081`}>
                                            <br />
                                            <b>
                                                <FormattedMessage
                                                    id="RegisterTab.TermsOfService"
                                                    defaultMessage={"Điều kiện điều khoản"}
                                                />
                                            </b>
                                        </a>
                                    ),
                                    privacyPolicy: (
                                        <a href={`https://booking.vinpearl.com/${getLanguagePath()}/dieu-khoan/chinh-sach-quyen-rieng-tu?_ga=2.268495160.814835106.1647245081-104127708.1647245081`}>
                                            <b className="pl-0">
                                                <FormattedMessage
                                                    id="RegisterTab.PrivacyPolicy"
                                                    defaultMege={"Chính sách bảo mật"}
                                                />
                                            </b>
                                        </a>
                                    ),
                                }}
                            />
                        </p>
                        <p className="login-footer-question">
                            <FormattedMessage
                                id="Login.dontHaveAccountVi"
                                defaultMessage="Chưa có tài khoản?"
                            />
                            {' '}
                            <span id="tabRegister" onClick={redirectToRegister}>
                                <FormattedMessage
                                    id="Login.RegisterVi"
                                    defaultMessage="Đăng ký ngay"
                                />
                            </span>
                        </p>
                    </div>
                </form>
            </Spin>
        </Modal>
    )
}

export default ModalLogin