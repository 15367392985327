import React, { Fragment, useEffect } from 'react'
import { omit } from 'lodash'
const InsertScriptTag = ({
  id,
  children
}) => {

  useEffect(() => {
    if (id) {
      return insertScripts()
    }
  }, [id])

  const insertScripts = () => {
    const scriptTagElms = [];
    const placeholderElm = document.getElementById(id);
    if (!placeholderElm) return
    React.Children.toArray(children).reverse().forEach((child) => {
      if (child.type === 'script') {
        const content = child.props?.dangerouslySetInnerHTML?.__html || '';
        const props = omit(child.props, 'dangerouslySetInnerHTML')
        const elm = document.createElement('script')
        Object.keys(props).forEach(key => {
          elm.setAttribute(key, props[key])
        })
        elm.innerHTML = content
        scriptTagElms.push(elm)
      }
    })
    scriptTagElms.forEach(elm => {
      placeholderElm.after(elm)
    })
    placeholderElm.remove();
    return () => scriptTagElms.forEach(elm => {
      elm && elm.remove()
    })
  }

  return <div className="insert-script-tag"></div>
}
export default InsertScriptTag