import { encryptAES256 } from "@utils/gtmHelpers";
import moment from "moment";
import { gtmEvent } from "src/helpers/gtmEvent";

export const createLoginGA = ({ userData = {} }) => {
  try {
    return {
      event: gtmEvent.LOGIN,
      user_id: userData?.id,
      user_tier: userData?.memberShipCard?.memberShip?.name || "NON",
      user_gender: userData?.gender
        ? userData?.gender === "male"
          ? "Nam"
          : "Nữ"
        : "khác",
      user_age: moment().diff(
        moment(userData.dateOfBirth, "DD-MM-YYYY"),
        "years",
        true
      ),
      user_audience: "travel",
      user_property_1: encryptAES256({ data: userData?.email }),
      user_property_2: encryptAES256({
        data: `${userData?.lastname} ${userData?.firstname}`,
      }),
      user_property_3: encryptAES256({ data: userData?.phoneNumber }),
      method: "Vinpearl Account",
    };
  } catch (error) {
    console.log("createLoginGA", createLoginGA);
  }
};

export const createLogoutGA = () => {
  return {
    event: gtmEvent.LOG_OUT,
  };
};
