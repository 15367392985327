import { getMomentDate } from '../utils/common';
import { FORMAT_ONLY_DATE } from '../constants';

export default class Bundle {

    constructor(data = {}, extendData={}) {
        this.setData(data, extendData);
    }

    setData(data = {}, extendData={}) {
        this.setHotel(data, extendData);
        this.setFlight(data, extendData);
        this.pricePerson = data.pricePerson || 0;
        this.totalPrice = data.totalPrice || 0;
        this.privateInfo = data.privateInfo ? new BundlePrivateInfo(data.privateInfo) : null;
    }

    setHotel(data={}, extendData={}){
        this.hotel = new BundleHotel(data.hotel, extendData, data.promotionCode)
    }

    setFlight(data={}, extendData={}){
        this.flight = new BundleFlight(data.flight, extendData)
    }

}

export class BundleHotel {
    constructor(data = {}, extendData={}, promotionCode = undefined) {
        this.setData(data, extendData, promotionCode);
    }

    setData(data = {}, extendData={}, promotionCode = '') {
        this.id = data.hotelId || undefined;
        this.hotelId = data.hotelId || undefined;
        this.name = data.name || undefined;
        this.types = data.types || [];
        this.star = data.star || 0;
        this.code = data.code || undefined;
        this.description = data.description || undefined;
        this.address = data.address || undefined;
        this.longtitude = data.longtitude || undefined;
        this.latitude = data.latitude || undefined;
        this.review = data.review || 0;
        this.media = data.media || [];
        this.roomTypes = data.roomTypes || [];
        this.departureDate = getDate(extendData.departureDate);
        this.returnDate = getDate(extendData.returnDate);
        this.adult = extendData.adult || 0;
        this.children = extendData.children || 0;
        this.infant = extendData.infant || 0;
        this.isPHS = data.isPHS || false;
        this.promotionCode = promotionCode || '';
        this.hasMarkupPromotion = data.hasMarkupPromotion;
        this.roomRates = data.roomRates;
        this.urlSlug = data.urlSlug;
    }


}

export class BundleFlight {
    constructor(data = {}, extendData={}) {
        this.setData(data, extendData);
    }

    setData(data = {}, extendData={}) {
        this.departureFlights = new BundleFlightDirectionInfo(data.departureFlights || {});
        this.returnFlights = new BundleFlightDirectionInfo(data.returnFlights || {});
        this.itineraryType = data.itineraryType || undefined;
        this.departureDate = getDate(data.departureDate, FORMAT_ONLY_DATE);
        this.returnDate = getDate(data.returnDate, FORMAT_ONLY_DATE);
        this.adult = data.adult || extendData.adult || 0;
        this.children = data.children || extendData.children || 0;
        this.infant = data.infant || extendData.infant || 0;
    }


}

export class BundleFlightDirectionInfo {
    constructor(data = {}) {
        this.setData(data);
    }

    setData(data = {}) {
        this.setFareOptions(data);
        this.startPoint = data.startPoint || undefined;
        this.endPoint = data.endPoint || undefined;
        this.flightNumber = data.flightNumber || undefined;
        this.airlineCode = data.airlineCode || undefined;
        this.startDate = getDate(data.startDate, undefined, false);
        this.endDate = getDate(data.endDate, undefined, false);
        this.stops = data.stops || 0;
        this.duration = data.duration || 0;
        this.operating = data.operating || {};
        this.setListSegment(data);
    }

    setFareOptions(data = {}) {
        this.fareOptions = (data.fareOptions || []).map(item => new BundleFlightFare(item))
    }

    setListSegment(data = {}) {
        this.listSegment = (data.listSegment || []).map(item => new BundleFlightSegment(item))
    }
}

export class BundleFlightFare {
    constructor(data = {}) {
        this.setData(data);
    }

    setData(data = {}) {
        this.increasePrice = data.increasePrice || 0;
        this.isCanHold = data.isCanHold || false;
        this.class = data.class || undefined;
        this.groupClass = data.groupClass || undefined;
        this.fareOptionSession = data.fareOptionSession || undefined;
        this.flightSession = data.flightSession || undefined;
        this.baggages = data.baggages || {}; // Two keys: handBaggage, baggageAllowance
    }


}

export class BundleFlightSegment {
    constructor(data = {}) {
        this.setData(data);
    }

    setData(data = {}) {
        this.airlineCode = data.airlineCode || undefined;
        this.airlineName = data.airlineName || undefined;
        this.airlineImage = data.airlineImage || undefined;
        this.startDate = getDate(data.startDate, undefined, false);
        this.endDate = getDate(data.endDate, undefined, false);
        this.startPoint = data.startPoint || undefined;
        this.startPointName = data.startPointName || undefined;
        this.startPointCityName = data.startPointCityName || undefined;
        this.endPoint = data.endPoint || undefined;
        this.endPointName = data.endPointName || undefined;
        this.endPointCityName = data.endPointCityName || undefined;
        this.flightNumber = data.flightNumber || undefined;
        this.plane = data.plane || undefined;
        this.flightTime = data.flightTime || undefined;
        this.class = data.class || undefined;
    }
}

export class BundlePrivateInfo {
    constructor(data = {}) {
        this.setData(data);
    }

    setData(data = {}) {
        this.hotelPrice = data.hotelPrice || 0;
        this.flightDepartPrice = data.flightDepartPrice || 0;
        this.flightReturnPrice = data.flightReturnPrice || 0;
    }
}

function getDate(dateString, format=undefined, convertUTC=false) {
    return getMomentDate(dateString, format, convertUTC)
}

