import { enumType, LOCAL_STORAGE_KEY } from '../constants'
import actionTypes from '../actionTypes'
import { hotelUtils } from '../utils'

const initState = {
  locations: [],
  allLocations: [],
  hotelsByLocation: [],
  hotelDeals: [],
  hotels: [],
  rooms: null,
  room: {},
  phsOrder: {},
  // filter: {
  //   typeStays: Object.values(enumType.typeStay),
  //   priorityRestingPlaces: Object.values(enumType.priorityRestingPlace)
  // },
  filters: {
    filter: [],
    results: [],
    dataChecked: []
  },
  sort: enumType.sortType.NoSort,
  currentHotel: null,
  isErrorMarkupBundle: false,
  isErrorPromotionMarkup: undefined,
  confirmPrice: {},
  memberRate: {},
  crossSaleBookables: {}
}

const hotelReducer = (state = initState, action) => {
  switch (action.type) {
    // case actionTypes.CHANGE_FILTER_TYPE_STAY:
    //   return Object.assign({}, state, {
    //     filter: {
    //       ...state.filter,
    //       typeStays: action.payload.data
    //     }
    //   })
    // case actionTypes.CHANGE_FILTER_PRIORITY_REST_PLACE:
    //   return Object.assign({}, state, {
    //     filter: {
    //       ...state.filter,
    //       priorityRestingPlaces: action.payload.data
    //     }
    //   })
    case actionTypes.RESET_HOTEL_FILTER:
      return Object.assign({}, state, {
        filter: initState.filter
      })
    case actionTypes.CHANGE_SORT_HOTEL:
      return Object.assign({}, state, {
        sort: action.payload.data
      })
    case actionTypes.FETCHING_HOTEL_LOCATION_SUCCESS:
      return Object.assign({}, state, {
        locations: action.payload.data
      })
    case actionTypes.FETCHING_HOTEL_ALL_LOCATION_SUCCESS:
      return Object.assign({}, state, {
        allLocations: action.payload.data
      })
    case actionTypes.FETCHING_HOTELS_BY_LOCATION_SUCCESS:
      return Object.assign({}, state, {
        hotelsByLocation: action.payload.data
      })
    case actionTypes.FETCHING_HOTELS_DEALS_SUCCESS:
      return Object.assign({}, state, {
        hotelDeals: action.payload.data
      })
    case actionTypes.START_FETCHING_HOTEL:
      return Object.assign({}, state, {
        hotels: []
      })
    case actionTypes.FETCHING_HOTEL_SUCCESS:
      const promotionCodeForHotel = sessionStorage.getItem(LOCAL_STORAGE_KEY.API_SUPPORT_PROMOTION_CODE)
      let newStateHotel = {};
      if(!promotionCodeForHotel || action.payload.data.params.promotionCode === promotionCodeForHotel){
        newStateHotel.hotels = action.payload.data.hotels
      }
      return Object.assign({}, state, newStateHotel)
    case actionTypes.RESET_FETCHING_HOTEL_ROOM:
      return Object.assign({}, state, {
        rooms: null
      })
    case actionTypes.FETCHING_ROOM_SUCCESS:
      const promotionCodeForRoom = sessionStorage.getItem(LOCAL_STORAGE_KEY.API_SUPPORT_PROMOTION_CODE)
      let newStateRoom = {};
      if(!promotionCodeForRoom || action.payload.data.params.promotionCode === promotionCodeForRoom){
        newStateRoom.rooms = action.payload.data.rooms
      }
      return Object.assign({}, state, newStateRoom)
    case actionTypes.FETCHING_ROOM_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        room: action.payload.data
      })
    case actionTypes.CHANGE_HOTEL_SUCCESS:
      return Object.assign({}, state, {
        currentHotel: action.payload.data
      })
    case actionTypes.BOOKING_HOTEL_SUCCESS:
      return {}
    case actionTypes.COMMIT_HOTEL_SUCCESS:
      return {}
    case actionTypes.BOOKING_CHECKOUT_SUCCESS:
      return Object.assign({}, state, {
        paymentUrl: action.payload.data
      })
    case actionTypes.SET_BOOKING_BUNDLE_ERROR:
      return Object.assign({}, state, {
        isErrorMarkupBundle: action.payload.data
      })
    case actionTypes.SET_BOOKING_BUNDLE_PROMOTION_MARKUP_ERROR:
      return Object.assign({}, state, {
        isErrorPromotionMarkup: action.payload.data
      })
    case actionTypes.REMOVE_PAYMENT_URL_SUCCESS:
      return Object.assign({}, state, {
        paymentUrl: null
      })
    case actionTypes.FETCHING_TAG_HOTEL_SUCCESS:
      const results = hotelUtils.mapTagGroupDetailSkus(action.payload.data)
      return {
        ...state,
        filters: {
          ...state.filters,
          filter: action.payload.data,
          results
        }
      }
    case actionTypes.CHANGE_HOTEL_FILTER:
      const dataChecked = hotelUtils.mapDataChecked(action.payload.data)
      return {
        ...state,
        filters: {
          ...state.filters,
          dataChecked
        }
      }
    case actionTypes.PHS_ORDER_HOTEL_SUCCESS: 
      return Object.assign({}, state, {
        phsOrder: action.payload.data
      })
    case actionTypes.FETCHING_PHS_ORDER_SUCCESS: 
      return Object.assign({}, state, {
        phsOrderFetching: action.payload.data
      })
    case actionTypes.PRICE_CHANGED_CONFIRM:
      return Object.assign({}, state, {
        confirmPrice: action.payload.data
      })
    case actionTypes.HOTEL_MEMBER_RATE_SUCCESS:
      return Object.assign({}, state, {
        memberRate: action.payload.data
      })
    case actionTypes.HOTEL_CROSS_SALE_BOOKABLES_SUCCESS:
      return Object.assign({}, state, {
        crossSaleBookables: action.payload.data
      })
    default:
      return state
  }
}

export default hotelReducer
