import actionTypes from '../actionTypes'

const initState = {
  isLoadingResendEmail: false,
  orders: null,
  orderItems: null,
  listOrderItems: null,
  orderInfo: null,
  flightOrder: null,
  hotelOrder: null,
  voucherDetail: null,
  saleReport: null,
  crossSell: null,
  errorCrossSell: null,
}

const orderReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.START_FETCHING_RESEND_EMAIL:
      return Object.assign({}, state, {
        isLoadingResendEmail: true
      })
    case actionTypes.STOP_FETCHING_RESEND_EMAIL:
      return Object.assign({}, state, {
        isLoadingResendEmail: false
      })
    case actionTypes.FETCHING_ORDERS_SUCCESS:
      return Object.assign({}, state, {
        orders: action.payload.data.data
      })
    case actionTypes.FETCHING_ORDER_ITEM_SUCCESS:
      return Object.assign({}, state, {
        orderItems: action.payload.data
      })
    case actionTypes.  FETCHING_LIST_ORDER_ITEM_SUCCESS:
      return Object.assign({}, state, {
        listOrderItems: action.payload.data
      })
    case actionTypes.FETCHING_ORDER_INFO_SUCCESS:
      return Object.assign({}, state, {
        orderInfo: action.payload.data
      })
    case actionTypes.FETCHING_ORDER_FLIGHT_SUCCESS:
      return Object.assign({}, state, {
        flightOrder: action.payload.data
      })
    case actionTypes.FETCHING_ORDER_HOTEL_SUCCESS:
      return Object.assign({}, state, {
        hotelOrder: action.payload.data
      })
    case actionTypes.FETCHING_ORDER_VOUCHER_SUCCESS:
      return Object.assign({}, state, {
        voucherDetail: action.payload.data
      })
    case actionTypes.RESET_ORDERS_SUCCESS:
      return initState
    case actionTypes.RESET_FETCHING_ORDER_ITEM_SUCCESS:
      return Object.assign({}, state, {
        orderItems: null
      })
    case actionTypes.FETCHING_ORDER_SALE_REPORT_SUCCESS:
      return Object.assign({}, state, {
        saleReport: action.payload.data
      })
    case actionTypes.FETCHING_ORDER_CROSS_SELL_SUCCESS:
      return Object.assign({}, state, {
        crossSell: action.payload.data
      })
    case actionTypes.FETCHING_ORDER_CROSS_SELL_ERROR:
      return Object.assign({}, state, {
        errorCrossSell: action.payload.data
      })
    default:
      return state
  }
}

export default orderReducer
