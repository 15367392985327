import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Button, message } from 'antd'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl.macro'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { injectIntl } from 'react-intl'
// hook
import { handleSubmitWithRecaptcha } from '../../hooks/recaptcha'
import { addToCart, addToCartMulti, clearCartNotice } from '../../hooks/carts'
import { redirectPage } from '../../hooks/common'
// reselect
import { selectIsLoading } from '../../reselect/fetchingSelector'
import { selectCart } from '../../reselect/cartSelector'
// constant
import { enumType, routes, LOCAL_STORAGE_KEY, VINWONDER_ENUM } from '../../constants'
import { gtmUtils, experienceUtils, LocalStorage, tourUtils, vinWonderUtils } from '../../utils'
import { sumBy, find, debounce, get } from 'lodash'
import gtmProduceCart from 'src/hooks/gtm/gtmProductCart'
import { gtmEvent, gtmType } from 'src/helpers/gtmEvent'

const ButtonAddToCart = (props) => {

  const {
    item = {},
    buttonClass,
    buttonName = <FormattedMessage
      id="Button.AddToCart"
      defaultMessage="Thêm vào giỏ hàng"
    />,
    index,
    onClick,
    itemType,
    htmlType,
    loading,
    submitForm = undefined,
    onResult = undefined,
    redirectToCart = false,
    redirectIfPassAPI = false,
    useDefautClass = true,
    icon = undefined,
    intl,
    multiple = false,
    buttonId = 'vp-tracking_add-to-cart',
    type = 1,
    tour = {},
    ...restProps
  } = props

  const { executeRecaptcha } = useGoogleReCaptcha()
  const clearNotice = clearCartNotice()
  const currentCart = useSelector(selectCart())
  const isLoading = useSelector(selectIsLoading)
  const [clickedItem, setClickedItem] = useState(undefined);
  const [addResult, setAddResult] = useState(undefined);


  const addToCartCallback = addToCart();
  const addToCartMultiCallback = addToCartMulti();
  const [redirectPageCallback] = redirectPage();

  const handleClickButton = debounce(() => {
    if (onClick) return onClick(item)
    clearNotice()
    if (!submitForm) {
      return addItemToCart();
    }
    submitForm().then((data) => addItemToCart(data));
  }, 300)

  const addItemToCart = async (extend) => {
    const getItemCart = (item) => {
      let itemData = item;

      const extendData = index >= 0 && Array.isArray(extend) ? extend[index] : extend;

      if (extendData && itemType === enumType.BOOKING_TYPE.VINWONDER) {
        // item.ticketItems = extendData.ticketItems;
        itemData = experienceUtils.getCartItemFromVinWonderTicket(item, extendData.bookingDate, extendData.siteCode)
        // console.log('=== EXTEND DATA', itemData)
      } else if (extendData && itemType === enumType.BOOKING_TYPE.TOUR) {
        if (type === enumType.BOOKING_TYPE.VINWONDER_NEW) {
          extendData.siteCode = tour?.tourDetail.siteCode;
          itemData = vinWonderUtils.getCartItemFromVinWonderNewTicket(item, extendData.bookingDate, extendData.siteCode);
          const key = [
            'childOriginalPrice', 'childQuantity', 'childSalePrice', 'childTicketItemId',
            'seniorOriginalPrice', 'seniorQuantity', 'seniorSalePrice', 'seniorTicketItemId'
          ]
          key.forEach(k => { delete itemData[k];})
        } else {
          // item.ticketPrice = extendData.ticketPrice || {};
          itemData = tourUtils.getCartItemFromTourTicket(item, extendData.bookingDate, type)
          // console.log('=== EXTEND DATA', itemData)
        }
      } else if(extendData && itemType === enumType.BOOKING_TYPE.VOUCHER){
        delete itemData.usingDate;
      }

      const quantity = itemType === enumType.BOOKING_TYPE.VINWONDER ? itemData?.adultQuantity : itemData?.quantity || 1;
      const cartItemType = itemData.cartItemType === enumType.BOOKING_TYPE.VINWONDER_NEW ? itemData.cartItemType : itemType || itemData.cartItemType || enumType.BOOKING_TYPE.VOUCHER;
      const productVariantId = itemData.cartItemType === enumType.BOOKING_TYPE.VINWONDER_NEW ? item?.tourLineItemTicketId : itemData.id || itemData.productVariantId;
      return {
        
        productVariantId,
        cartItemType,
        // quantity: itemData.adultQuantity !== undefined ? itemData.quantity : 1,
        // quantity: itemData.quantity || 1,
        quantity,
        originalPrice: itemData.originalPrice,
        salePrice: itemData.salePrice * quantity,
        childOriginalPrice: itemData.childOriginalPrice || 0,
        childSalePrice: itemData.childSalePrice || 0,
        childQuantity: itemData.childQuantity || 0,
        infantOriginalPrice: itemData.infantOriginalPrice || 0,
        infantSalePrice: itemData.infantSalePrice || 0,
        infantQuantity: itemData.infantQuantity || 0,
        seniorOriginalPrice: itemData.seniorOriginalPrice || 0,
        seniorSalePrice: itemData.seniorSalePrice || 0,
        seniorQuantity: itemData.seniorQuantity || 0,
        ticketItemId: itemData.ticketItemId || undefined,
        childTicketItemId: itemData.childTicketItemId || undefined,
        seniorTicketItemId: itemData.seniorTicketItemId || undefined,
        usingDate: itemData.usingDate || undefined,
        siteCode: itemData.siteCode || undefined,
        sessionId: itemData.sessionId || undefined,
        zonePickupId: itemData.zonePickupId || undefined,
        zoneReleaseId: itemData.zoneReleaseId || undefined,
        rateCode: itemData.rateCode || undefined,
        rateCodeId: itemData.rateCodeId || undefined,
        skuType: (itemType === enumType.BOOKING_TYPE.VINWONDER || itemType === enumType.BOOKING_TYPE.TOUR) ? 'sku' : undefined,
        tourId: itemData.tourId
      }
    }

    const cartInfo = !multiple ? getItemCart(item) : {
      cartRequests: (item || []).map((data, index) => getItemCart(data, index))
    }

    if (currentCart && currentCart.cartCode) {
      cartInfo.cartCode = currentCart.cartCode
    }

    const productVariantIds = multiple ? (item || []).map(data => data.id) : item.id
    const cartItemType = !multiple ? cartInfo.cartItemType : get(cartInfo, 'cartRequests[0].cartItemType')

    
    
    // 1. push gtm
    if (cartItemType === enumType.BOOKING_TYPE.VOUCHER || cartItemType === enumType.BOOKING_TYPE.TOUR) {
      const productType = cartItemType === enumType.BOOKING_TYPE.TOUR ? 'tour' : 'voucher';
      // const pushFunction = cartItemType === enumType.BOOKING_TYPE.TOUR ? gtmUtils.pushGtmDataLayerTour : gtmUtils.pushGtmDatalayerVoucher
      // pushFunction(item, 'productCart', productType, 'add', index);
      gtmProduceCart({voucher: tour, index, type: gtmType.ACTION_CART_SKU, arrAdd: item, actionType: 'add'});
    } else if (cartItemType === enumType.BOOKING_TYPE.VINWONDER) {
      gtmProduceCart({voucher: tour, index, type: gtmType.ACTION_CART_SKU, arrAdd: item, actionType: 'add'});
      gtmUtils.pushGtmDataLayerVinWonder(Object.assign(item, {
        price: sumBy(item.ticketItems || [], (ticket) => (ticket.salePrice || 0) * (ticket.quantity)),
        quantity: (find(item.ticketItems || [], (ticket) => ticket.ticketType === VINWONDER_ENUM.TICKET.TYPE.ADULT.id) || {}).quantity || 0,
        childQuantity: (find(item.ticketItems || [], (ticket) => ticket.ticketType === VINWONDER_ENUM.TICKET.TYPE.CHILD.id) || {}).quantity || 0,
        seniorQuantity: (find(item.ticketItems || [], (ticket) => ticket.ticketType === VINWONDER_ENUM.TICKET.TYPE.SENIOR.id) || {}).quantity || 0,
      }), 'productCart', 'vinWonder', 'add', index);
    }
    // 2. add to cart
    const addToCartFn = multiple ? addToCartMultiCallback : addToCartCallback;
    setClickedItem(cartInfo)
    const result = await handleSubmitWithRecaptcha({
      alias: 'homepage',
      executeRecaptcha: executeRecaptcha,
      handleSubmit: (recaptcha) =>
        addToCartFn(
          {
            ...cartInfo,
            googleCaptchaResponse: recaptcha
          },
          productVariantIds,
          redirectIfPassAPI,
          intl
        )
    })
    setAddResult(result || undefined);
  }

  const showAddedMessage = () => {
    setClickedItem(undefined);
    message.success(<FormattedMessage
      id="AddToCartSuccessfully"
      defaultMessage='Thêm vào giỏ hàng thành công'
    />)
  }

  const redirectToCartAfterAdded = () => {
    // console.log(clickedItem, ' clickedItem')
    const checkedItem = multiple ? clickedItem.cartRequests.map(item => ({
      productVariantId: item.id || item.productVariantId,
      usingDate: item.usingDate
    })) : {
        productVariantId: clickedItem.id || clickedItem.productVariantId,
        usingDate: clickedItem.usingDate,
        tourId: clickedItem.tourId
      }
    setClickedItem(undefined);
    // console.log('==== redirectToCartAfterAdded', checkedItem)
    LocalStorage.set(LOCAL_STORAGE_KEY.CART_CHECKED_ITEM, checkedItem)
    // redirectPageCallback(routes.CART)
    setTimeout(() => redirectPageCallback(routes.CART), 300)
  }

  useEffect(
    () => {
      if (addResult && addResult.status === 200 && clickedItem) {
        if (redirectToCart) {
          redirectToCartAfterAdded()
        } else {
          // showAddedMessage()
        }
      }
    },
    [addResult]
  )

  return (

    <Button
      {...restProps}
      id={buttonId}
      htmlType={htmlType}
      loading={loading || (clickedItem && isLoading)}
      onClick={handleClickButton}
      className={
        clsx({
          'g-brd-primary g-bg-primary g-bg-primary--hover-light g-color-white rounded-0 w-100': useDefautClass,
          [`${buttonClass}`]: !!buttonClass,
          'g-opacity-0_7': !!restProps.disabled,
        })
      }
    >
      {icon}{buttonName}
    </Button>
  )
}

ButtonAddToCart.propTypes = {
  item: PropTypes.object,
  buttonClass: PropTypes.string,
  buttonName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  redirectToCart: PropTypes.bool,
  useDefautClass: PropTypes.bool,
}

export default injectIntl(ButtonAddToCart)