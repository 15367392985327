import React from 'react'
import { BENEFIT_MEMBER, enumType, LOCAL_STORAGE_KEY, WHITE_LIST_DOMAIN } from '../constants'
import { getConfig } from '@config'
const {
    publicRuntimeConfig: {
      STATIC_URL
    } 
  } = getConfig()

import moment from "moment";
import xss from "xss";
import { each, keys, find, round } from "lodash";
import { FormattedMessage } from 'react-intl.macro';
import { LocalStorage } from '@utils/index';
export const getMomentTimezone = (ZstrDat, ignoreZone = false) => {
    if(ignoreZone) {
        return moment(ZstrDat, 'YYYY-MM-DDTHH:mm:ss')
    }
    return moment(`+00:00 ${ZstrDat}`, 'Z YYYY-MM-DDTHH:mm:ss')
}
export const getPathWithParams = (path = '', params = {}) => {
    Object.keys(params).forEach(p => {
        const searchStrParam = `\/:${p}`;
        path = path.replace(searchStrParam, `\/${params[p]}`);
    })
    return path;
}

export const formatMoney = (val) => {
    let num = 0;
    let oldVal = val ? val.toString() : "";
    if (oldVal === "" || oldVal === 0) {
        num = 0;
    } else {
        num = isNaN(parseInt(oldVal.replace(/,/g, '')).toString()) ? 0 : parseInt(oldVal.replace(/,/g, ''));
        num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    return num;
};

export const moneyRounding = (val) => {
    let num = 0;
    let oldVal = val ? val.toString() : "";
    if (oldVal === "" || oldVal === 0) {
        num = 0;
    } else {
        num = isNaN(parseInt(oldVal.replace(/,/g, '')).toString()) ? 0 : parseInt(oldVal.replace(/,/g, ''));
        num = Math.round(num/1000) * 1000;
    }
    return num;
};

export const formatDate = (ZstrDat, ignoreZone = false) => {
    let _m = typeof ZstrDat === "string" ? getMomentTimezone(ZstrDat, ignoreZone) : (ZstrDat || moment());
    return `${_m.format('DD')} tháng ${_m.format('MM, YYYY')}`
}

export const formatShortDate = (ZstrDat, ignoreZone = false) => {
    let _m = typeof ZstrDat === "string" ? getMomentTimezone(ZstrDat, ignoreZone) : (ZstrDat || moment());
    return `${_m.format('DD/MM/YYYY')}`
}

export const formatShortYear = (ZstrDat, ignoreZone = false) => {
    let _m = typeof ZstrDat === "string" ? getMomentTimezone(ZstrDat, ignoreZone) : (ZstrDat || moment());
    return `${_m.format('DD/MM/YY')}`
}

export const formatFullDate = (ZstrDat, ignoreZone = false) => {
    let _m = typeof ZstrDat === "string" ? getMomentTimezone(ZstrDat, ignoreZone) : (ZstrDat || moment());
    return `${_m.format('HH:mm, DD/MM/YYYY')}`
}

export const formatFullWithoutYear = (ZstrDat, ignoreZone = false) => {
    let _m = typeof ZstrDat === "string" ? getMomentTimezone(ZstrDat, ignoreZone) : (ZstrDat || moment());
    return `${_m.format('HH:mm, DD MMMM')}`
}

export const formatShortDateExport = (ZstrDat) => {
    let _m = typeof ZstrDat === "string" ? getMomentTimezone(ZstrDat) : (ZstrDat || moment());
    return `${_m.format('YYYY-MM-DD')}`
}

export const formatDayOfWeek = (ZstrDat) => {
    let _m = typeof ZformatDayOfWeekLocalesstrDat === "string" ? getMomentTimezone(ZstrDat) : (ZstrDat || moment());
    let d = _m.day();
    return d == 0 ? `Chủ Nhật` : `Thứ ${d + 1}`;
}

export const formatDayOfWeekLocales = (ZstrDat) => {
    let _m = typeof ZstrDat === "string" ? getMomentTimezone(ZstrDat) : (ZstrDat || moment());
    let d = _m.day();
    return d == 0 ? 
        {
            id: "Order.Date.Sunday",
            defaultMessage: 'Chủ Nhật'
        }
        : 
        {
            id: `Order.Date.Day${d + 1}`,
            defaultMessage: `Thứ ${d + 1}`
        }

}

export const formatTime = (ZstrDat) => {
    let _m = typeof ZstrDat === "string" ? getMomentTimezone(ZstrDat) : (ZstrDat || moment());
    return _m.format("HH:mm");
}

export const compactObj = (obj) => {
    let newObj = {};
    each(keys(obj), (key) => {
        if (key && obj[key] && obj[key] != "all") {
            newObj[key] = obj[key];
        }
    })
    return newObj;
}

export const substr = (str, length) => {
    return str.length >= length ? str.substring(0, length) + "..." : str
}

export function getSlugFromUrl(url) {
    const array = url && url.split('/');
    return array && array[array.length - 1]
}

export function xssFilter(toOutput) {
    const options = {
        whiteList: {
            a: ["href", "title", "target", "style", "class"],
            img: ["style", "class", "src", "alt", "width", "height"],
            p: ["style", "class",],
            span: ["style", "class",],
            mark: ["style", "class",],
            sup: ["style", "class",],
            sub: ["style", "class",],
            s: ["style", "class",],
            ul: ["style", "class",],
            li: ["style", "class"],
            figure: ["style", "class", "width", "height"],
            figcaption: ["style", "class", "width", "height"],
            i: ["style", "class",],
            b: ["style", "class",],
            u: ["style", "class",],
            table: ["style", "class", "width", "height"],
            tr: ["style", "class", "width", "height"],
            td: ["style", "class", "width", "height"],
            thead: ["style", "class", "width", "height"],
            tbody: ["style", "class", "width", "height"],
            strong: ["style", "class",],
            ol: ["style", "class",],
            blockquote: ["style", "class",],
            br: ["style", "class",],
            h1: ["style", "class",],
            h2: ["style", "class",],
            h3: ["style", "class",],
            h4: ["style", "class",],
            h5: ["style", "class",],
            h6: ["style", "class",],
        }
    }
    return xss(toOutput, options);
}

export function removeElements(elms) {
    elms.forEach(el => el.remove());
}

export function getOrderByQueryValue(query) {
    const obj = find(enumType.voucherFilter, sort => sort.orderBy === query.OrderBy && sort.orderByDesc === query.OrderByDesc);
    return obj && obj.value
}

export function changeHttp(str) {
    var replace = `http://${STATIC_URL}`;
    var re = new RegExp(replace,"g");
    return str ? str.replace(re, `https://${STATIC_URL}`) : ''
}

export function isValidURL(string) {
    const pattern = new RegExp("^(?:http(s)?:\\/\\/)?(?:localhost|[\\w-]+(?:\\.[\\w-]+)+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$"); // fragment locator
    return pattern.test(string);
}

export function isValidDomain(url) {
    const pattens = new RegExp(`^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?(${WHITE_LIST_DOMAIN.join('|')})?(\/.*)?$`);
    return pattens.test(url)
}

export function checkIfValidUUID(str) {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  
    return regexExp.test(str);
}

export const getLanguagePath = () =>{
    const language = LocalStorage.get(LOCAL_STORAGE_KEY.LANGUAGE_WESBITE)
    const currency = LocalStorage.get(LOCAL_STORAGE_KEY.CURRENCY_WESBITE)
    return `${language.data || "vi"}-${currency.data || "VND"}`
}

export const vinClubPoint = (member, value) => {
    switch(member) {
        case BENEFIT_MEMBER.PEARL:
            return `+${round((value * 3 / 100) / 1000)} VPoint`
        case BENEFIT_MEMBER.AMBASSADOR_PEARL:
            return `+${round((value * 4 / 100) / 1000)} VPoint`
        case BENEFIT_MEMBER.PEARL_CLUB:
            return `+${round((value * 5 / 100) / 1000)} VPoint`
        default:
          return `+${round((value * 3 / 100) / 1000)} VPoint`
    }
}
