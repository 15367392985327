import { getConfig } from '@config'


let {
  publicRuntimeConfig: {
    NODE_ENV
  } // Available both client and server side
} = getConfig()

export default {
  TOUR_DISABLED: true,
  TOUR_IN_VOUCHER_DISABLED: false,
  DISABLE_HOTEL: false,
  DISABLE_COMBO: false,
  SHOW_VINWONDERS_FILTER: false,
  HIDE_VINWONDER_SITES: false,
  HIDE_VOUCHER_EXPRIRY_DATE: true,
  HIDE_CURRENCIES: true,
  STATIC_HOME_BANNER: true,
  INSIDER_DISABLED: false,
  HIDE_AUTH: false,
  HIDE_VIN3S_INFO: true,
}