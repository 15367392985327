import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import App from "./pages/_app";
// import { DD_RUM, DD_LOGS } from "./hooks/DD_RUM";
// import { datadogRum } from "@datadog/browser-rum";
// DD_RUM();
// DD_LOGS();
// datadogRum.startSessionReplayRecording();

ReactDOM.render(<App />, document.getElementById('root'));
// serviceWorker.unregister();
