import { merge } from "lodash";
import { enumType } from "src/constants";

export const generateVinwonder = (newVinWonders = []) => {
  const mergedArray = [];

  const dataArray = (newVinWonders || []).map((vinWonderTourItem) => {
    const { originalPrice, salePrice, quantity, ...resVinWonderTourItem } = vinWonderTourItem;
    const vinWonder = {
      ...resVinWonderTourItem,
      vinWonderName: vinWonderTourItem?.vinWonderName || "",
      vinWonderTicketName: vinWonderTourItem?.objectType?.name || "",
      // "childOriginalPrice": 906400,
      // "childSalePrice": 906400,
      // "childQuantity": 3,
      // "childTicketItemId": "d21f21e8-a37f-46f5-b3d6-8b6213ea2b5a",

      // "seniorOriginalPrice": 686400,
      // "seniorSalePrice": 686400,
      // "seniorQuantity": 3,
      // "seniorTicketItemId": "79f1ba6e-dd38-4bb7-ad15-18b5af51f6d7",

      // "ticketItemId": "d4050d3f-6b5f-4600-8498-37d7933587fd",
      // "originalPrice": 0,
      // "quantity": 3,
      // "salePrice": 545600,
      siteCode: vinWonderTourItem?.siteCode,
      usingDate: vinWonderTourItem?.usingDate,
      limitNumberOfSalesPerUser: vinWonderTourItem?.maxQuantity,
      vinWonderId: vinWonderTourItem?.tourId,
      type: vinWonderTourItem?.cartItemType,
      sessionId: vinWonderTourItem?.sessionId,
      zonePickupId: vinWonderTourItem?.zonePickupId,
      zoneReleaseId: vinWonderTourItem?.zoneReleaseId,
      sessionInfo: null,
      productVariantId: vinWonderTourItem?.productVariantId,
      cartItemType: vinWonderTourItem?.cartItemType,
      itemName: vinWonderTourItem?.itemName,
      thumbImage: vinWonderTourItem?.thumbImage,
      urlSlug: vinWonderTourItem?.urlSlug,
      inventory: vinWonderTourItem?.inventory,
      currencyCode: vinWonderTourItem?.currencyCode,
      installmentFee: vinWonderTourItem?.installmentFee,
      installmentLimit: vinWonderTourItem?.installmentLimit,
      errorCode: vinWonderTourItem?.errorCode,
    };

    switch (vinWonderTourItem?.objectType?.code) {
      case "NCT":
        vinWonder.seniorOriginalPrice = vinWonderTourItem?.originalPrice || 0;
        vinWonder.seniorSalePrice = vinWonderTourItem?.salePrice || 0;
        vinWonder.seniorQuantity = vinWonderTourItem?.quantity || 0;
        vinWonder.seniorTicketItemId = vinWonderTourItem?.tourLineItemId || "";
        vinWonder.seniorProductVariantId = vinWonderTourItem?.productVariantId;
        
        delete vinWonder.originalPrice;
        delete vinWonder.salePrice;
        delete vinWonder.quantity;
        delete vinWonder.tourLineItemId;
        delete vinWonder.productVariantId;
        break;
      case "TE":
        vinWonder.childOriginalPrice = vinWonderTourItem?.originalPrice || 0;
        vinWonder.childSalePrice = vinWonderTourItem?.salePrice || 0;
        vinWonder.childQuantity = vinWonderTourItem?.quantity || 0;
        vinWonder.childTicketItemId = vinWonderTourItem?.tourLineItemId || "";
        vinWonder.childProductVariantId = vinWonderTourItem?.productVariantId;

        delete vinWonder.originalPrice;
        delete vinWonder.salePrice;
        delete vinWonder.quantity;
        delete vinWonder.tourLineItemId;
        delete vinWonder.productVariantId;
        break;
      default:
        vinWonder.originalPrice = vinWonderTourItem?.originalPrice || 0;
        vinWonder.salePrice = vinWonderTourItem?.salePrice || 0;
        vinWonder.quantity = vinWonderTourItem?.quantity || 0;
        vinWonder.ticketItemId = vinWonderTourItem?.tourLineItemId || "";
        vinWonder.productVariantId = vinWonderTourItem?.productVariantId;
        break;
    }
    return vinWonder;
  }).forEach(e => {
    const {tourTicketId, usingDate, sessionId} = e;
    const findedItem = mergedArray.findIndex(
      (mergItem) =>
        getKeyToGroupCart(
          mergItem.tourTicketId,
          mergItem.usingDate,
          mergItem.sessionId
        ) === getKeyToGroupCart(tourTicketId, usingDate, sessionId)
    );
    if (findedItem === -1) {
      mergedArray.push({tourTicketId,usingDate,sessionId,tickets: [e]})
    } else {
      mergedArray[findedItem].tickets.push(e);
    }
  })
  const result = mergedArray.map(res => {
    const mergedObject = {};
    res.tickets.forEach(e => { merge(mergedObject, e) });
    return mergedObject
  })
  return result;
};

export const getKeyToGroupCart = (tourTicketId, usingDate, sessionId) => {
  const date = usingDate;
  const keys = [tourTicketId, date, sessionId];
  return keys.filter(key => key != null).join("|");
};

export const detachBookingItem = (newVinWonders = [], vinWonderTour = []) => {
  const checkoutData = vinWonderTour.map(tour => {
    const vinWonderTourItem = {...tour};
    const vinWonderItem = newVinWonders.find(el => getKeyToGroupCart(el?.tourTicketId, el?.usingDate, el?.sessionId) === getKeyToGroupCart(tour?.tourTicketId, tour?.usingDate, tour?.sessionId));
    if(vinWonderItem) {
      if (!vinWonderItem.checked && vinWonderItem.cartItemType === enumType.BOOKING_TYPE.VINWONDER_NEW) {
        return null;
      }
      if (tour?.productVariantId === vinWonderItem?.productVariantId) {
        vinWonderTourItem.originalPrice = vinWonderItem?.originalPrice || 0;
        vinWonderTourItem.salePrice = tour?.salePrice * vinWonderItem?.quantity;
        vinWonderTourItem.quantity = vinWonderItem?.quantity || 0;
        vinWonderTourItem.ticketItemId = vinWonderItem?.tourLineItemId || "";
        vinWonderTourItem.productVariantId = vinWonderItem?.productVariantId || "";
      }
      if (tour?.productVariantId === vinWonderItem?.childProductVariantId) {
        vinWonderTourItem.originalPrice = vinWonderItem?.childOriginalPrice || 0;
        vinWonderTourItem.salePrice = tour?.salePrice * vinWonderItem?.childQuantity || 0;
        vinWonderTourItem.quantity = vinWonderItem?.childQuantity || 0;
        vinWonderTourItem.ticketItemId = vinWonderItem?.childTicketItemId || "";
        vinWonderTourItem.productVariantId = vinWonderItem?.childProductVariantId;

        delete vinWonderTourItem.childOriginalPrice;
        delete vinWonderTourItem.childQuantity;
        delete vinWonderTourItem.childTicketItemId;
        delete vinWonderTourItem.childProductVariantId;
        delete vinWonderTourItem.childSalePrice;

      }
      if (tour?.productVariantId === vinWonderItem?.seniorProductVariantId) {
        vinWonderTourItem.originalPrice = vinWonderItem.seniorOriginalPrice || 0;
        vinWonderTourItem.salePrice = tour?.salePrice * vinWonderItem.seniorQuantity || 0;
        vinWonderTourItem.quantity = vinWonderItem.seniorQuantity || 0;
        vinWonderTourItem.ticketItemId = vinWonderItem.seniorTicketItemId || "";
        vinWonderTourItem.productVariantId = vinWonderItem.seniorProductVariantId || "";

        delete vinWonderTourItem.seniorProductVariantId;
        delete vinWonderTourItem.seniorOriginalPrice;
        delete vinWonderTourItem.seniorQuantity;
        delete vinWonderTourItem.seniorTicketItemId;
        delete vinWonderTourItem.seniorSalePrice;
      }
    }
    vinWonderTourItem.vinWonderId = "";
    return vinWonderTourItem
  })
  return checkoutData.filter(e => e && e?.quantity > 0);

}
