import parse from 'html-react-parser'
import truncate from 'html-truncate'
import sanitizeHtml from 'sanitize-html'

const Entities = require('html-entities').AllHtmlEntities;

const sanitizeHtmlOptions = {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'span', 'br']),
  allowedAttributes: {
    a: [],
    img: ['src']
  },
}

export default {
  parseHtmlContent (content) {
    return content
      ? parse(sanitizeHtml(content, sanitizeHtmlOptions))
      : null
  },

  decodeContent (content) {
    const entities = new Entities()
    return content
      ? entities.decode(content)
      : null
  },

  truncateHtmlContent (content, characterCount) {
    if (!content) {
      return null
    }
    const contentDecode = this.decodeContent(content)

    const contentWithoutXss = sanitizeHtml(contentDecode)

    if (!contentWithoutXss || contentWithoutXss.length === 0) {
      return null
    }
    return truncate(contentWithoutXss, characterCount, {
      ellipsis: '...'
    })
  },

  truncateHtmlWithoutXss (content, characterCount) {
    if (!content) {
      return null
    }
    const contentDecode = this.decodeContent(content)

    if (!contentDecode) {
      return null
    }

    const contentWithoutXss = sanitizeHtml(contentDecode, {
      allowedTags: [],
      allowedAttributes: {}
    })

    if (!contentWithoutXss || contentWithoutXss.length === 0) {
      return null
    }

    if (contentWithoutXss.length > characterCount) {
      let truncateContent = contentWithoutXss.substr(0, characterCount)
      truncateContent = truncateContent.substr(0, Math.min(truncateContent.length, truncateContent.lastIndexOf(' ')))
      return `${truncateContent}...`
    }
    return contentWithoutXss
  },

  removeAllHtmlTag (content) {
    if (!content) {
      return null
    }
    const contentDecode = this.decodeContent(content)

    if (!contentDecode) {
      return null
    }

    return sanitizeHtml(contentDecode, {
      allowedTags: [],
      allowedAttributes: {}
    })
  }
}