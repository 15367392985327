import { useDispatch } from 'react-redux'
// lib
import { useCallback } from 'react'
// action
import { loadCartAction } from '../actions/carts'
import { addToCartSuccess } from '../actions/voucherAction'
import cartAction from '../actions/cartAction'
import creditAction from '../actions/creditAction'

/**
 * redirect
 * @returns {[function(*=)]}
 */
export const loadCartHook = () => {
  const dispatch = useDispatch()
  return useCallback(
    () => dispatch(loadCartAction()),
    [dispatch]
  )
}

export const addToCartNotifyHook = () => {
  const dispatch = useDispatch()
  return useCallback(
    () => dispatch(addToCartSuccess()),
    [dispatch]
  )
}

export const addToCart = () => {
  const dispatch = useDispatch()

  return useCallback(
    (data, productVariantId, redirectIfPassAPI, intl) => dispatch(cartAction.addToCart(data, productVariantId, redirectIfPassAPI, intl)),
    [dispatch]
  )
}

export const addToCartMulti = () => {
  const dispatch = useDispatch()

  return useCallback(
    (data, productVariantIds, redirectIfPassAPI, intl) => dispatch(cartAction.addToCartMulti(data, productVariantIds, redirectIfPassAPI, intl)),
    [dispatch]
  )
}

export const clearCartNotice = () => {
  const dispatch = useDispatch()
  return useCallback(
    () => dispatch(cartAction.clearAddToCartNotice()),
    [dispatch]
  )
}

export const loadCartDetail = () => {
  const dispatch = useDispatch()
  return useCallback(
    () => dispatch(cartAction.loadCurrentCart()),
    [dispatch]
  )
}

export const deleteCartItem = () => {
  const dispatch = useDispatch()
  return useCallback(
    (id, usingDate, sessionId) => dispatch(cartAction.deleteCartItem(id, usingDate, sessionId)),
    [dispatch]
  )
}

export const updateCartItem = () => {
  const dispatch = useDispatch()
  return useCallback(
    (data, callbacks) => dispatch(cartAction.updateQuantityCartItem(data, callbacks)),
    [dispatch]
  )
}

export const checkoutCart = () => {
  const dispatch = useDispatch()
  return useCallback(
    (data, callback) => dispatch(cartAction.checkoutCart(data, callback)),
    [dispatch]
  )
}

export const getCoupon = () => {
  const dispatch = useDispatch()

  return useCallback(
    (code, productVariantIds) => dispatch(cartAction.getCoupon(code, productVariantIds)),
    [dispatch]
  )
}

export const updateCoupon = () => {
  const dispatch = useDispatch()

  return useCallback(
    (coupon) => dispatch(cartAction.updateCoupon(coupon)),
    [dispatch]
  )
}

export const getExchangeRate = () => {
  const dispatch = useDispatch()

  return useCallback(
    (coupon) => dispatch(cartAction.getExchangeRate(coupon)),
    [dispatch]
  )
}

export const getCreditPointCalculation = () => {
  const dispatch = useDispatch()

  return useCallback(
    (totalAmount, callback) => dispatch(creditAction.getCreditPointCalculation(totalAmount, callback)),
    [dispatch]
  )
}

export const cancelPendingPayment = () => {
  const dispatch = useDispatch()

  return useCallback(
    (callback) => dispatch(creditAction.cancelPendingPayment(callback)),
    [dispatch]
  )
}

export const useCreditPoint = () => {
  const dispatch = useDispatch()

  return useCallback(
    () => dispatch(creditAction.useCreditPoint()),
    [dispatch]
  )
}

export const unuseCreditPoint = () => {
  const dispatch = useDispatch()

  return useCallback(
    () => dispatch(creditAction.unuseCreditPoint()),
    [dispatch]
  )
}

export const resetCreditPoint = () => {
  const dispatch = useDispatch()

  return useCallback(
    () => dispatch(creditAction.resetCreditPointCalculationData()),
    [dispatch]
  )
}