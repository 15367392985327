import React, { useEffect, useState } from 'react'
import Link from '@utils/router/link'

// lib
import { useSelector } from 'react-redux'
// constant
import { ccdvFooter, navRegulationsFooter, navHomestayFooter } from '../../constants/nav'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl.macro'
// reselect
import { selectSiteInfo } from '../../reselect/siteInfoSelector'
import { getConfig } from '@config'
import { slice } from 'lodash'
import { injectIntl } from 'react-intl'
import utils from '../../utils'
import { useGlobalStore } from '../../hooks/useGlobal'
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom'
import clsx from 'clsx'

const homePageUrl = process.env.REACT_APP_API_BASE_VIN_3S_URL;

const Footer = ({ intl }) => {
  const { pathname } = useLocation()
  const siteInfo = useSelector(selectSiteInfo())
  const { state } = useGlobalStore();
  const [isStaynfun, setIsStaynfun] = useState(false);
  
  useEffect(() => {
    setIsStaynfun(pathname.includes("/staynfun"));
  }, [pathname])

  const staynfunInfo = {
    addressLine1: intl.formatMessage({
      id: 'Footer.Homestay.AddressLine',
      defaultMessage: 'Căn KD5-136, Đường Kinh Đô 5, Khu đô thị sinh thái Dream City, Xã Nghĩa Trụ, Huyện Văn Giang, Tỉnh Hưng Yên, Việt Nam'
    }),
    email: 'v.cskh-SNFOP2@staynfun.vn',
    hotlineNumber: '(+84)2456780116',
  }

  const getFooterLink = (endpoint) => {
    const sep = endpoint && endpoint.startsWith('/') ? '' : '/'
    return endpoint && `${homePageUrl}${sep}${endpoint}`
  }
  const aboutUs = intl.formatMessage({ id: 'Footer.AboutUs', defaultMessage: 'Về chúng tôi' });
  const introduction = intl.formatMessage({ id: 'Menu.Footer.About', defaultMessage: 'Về Vinpearl' });
  const introductionStaynfun = intl.formatMessage({ id: 'Menu.Footer.About.Staynfun', defaultMessage: 'Về StaynFun' });
  const achievement = intl.formatMessage({ id: 'Footer.Achievement', defaultMessage: 'Thành tựu' });
  const question = intl.formatMessage({ id: 'Footer.Question', defaultMessage: 'Câu hỏi thường gặp' });
  const gallery = intl.formatMessage({ id: 'Footer.Gallery', defaultMessage: 'Thư viện ảnh' });
  const recruitment = intl.formatMessage({ id: 'Footer.Recruitment', defaultMessage: 'Tuyển dụng' });
  const contact = intl.formatMessage({ id: 'Footer.Contact', defaultMessage: 'Liên hệ' });
  const sitemap = intl.formatMessage({ id: 'Footer.Sitemap', defaultMessage: 'Bản đồ trang' });
  const emailCustomerService = intl.formatMessage({ id: 'Footer.Email.CustomerService', defaultMessage: 'Email chăm sóc khách hàng' });
  const phoneCustomerService = intl.formatMessage({ id: 'Footer.Phone.CustomerService', defaultMessage: 'Số điện thoại chăm sóc khách hàng' });
  const demoWebsite = intl.formatMessage({ id: 'Footer.DemoWebsite', defaultMessage: 'Website đang chạy thử nghiệm' });
  const events = intl.formatMessage({ id: 'Footer.TopHeader.Events', defaultMessage: 'Sự kiện' });
  const newCompany = intl.formatMessage({ id: 'Footer.Header.NewsCompany', defaultMessage: 'Tin tức công ty' });
  const ebook = intl.formatMessage({ id: 'Footer.Header.EBook', defaultMessage: 'Cẩm nang du lịch' });

  const renderNavLinkItem = (item) => {
    const title = intl.formatMessage({ id: item.titleKey });
    return !item.hide && (
      <li key={item.route}>
        {
          isStaynfun ? 
            <a href={item.route} target='_blank'>
              {item.title}
            </a> : 
            <Link href={item.route}>
              <a title={title || item.title}>{item.title}</a>
            </Link>
        }
      </li>
    )
  }

  return (
    <footer className={clsx("main-footer", {
      'footer_staynfun': (isStaynfun)
    })}>
      <div className="container">
        <img className='footer-bg' alt='' src={require("@assets/include/svg/s43/footer-bg.svg").default} />
        <div className="row main-footer-links align-items-start justify-content-between">
          <div className="col-1st">
            <div className="image-logo-footer">
              {
                (isStaynfun) ?
                  <img src={require("@assets/img/staynfun/logo-staynfun_2.svg").default} alt="" className="logo-footer" /> :
                  <img src={require('@assets/img/logo/logo-footer.svg').default} alt="" className="logo-footer" />
              }
            </div>
            <div className="address">
              <p className="d-flex flex-row align-items-start">
                {
                  (isStaynfun) ?
                    <img className="d-inline-block g-mt-8" src={require('@assets/include/svg/s43/homestay_location2.svg').default} alt="" /> :
                    <img className="d-inline-block g-mt-8" src={require('@assets/include/svg/s43/location2.svg').default} alt="" />
                }
                <span className="g-ml-12 address-info">
                  {(isStaynfun) ? staynfunInfo.addressLine1 : siteInfo.addressLine1}
                </span>
              </p>

              <p>
                {
                  (isStaynfun) ?
                    <img src={require('@assets/include/svg/s43/homestay_mail2.svg').default} alt="" /> :
                    <img src={require('@assets/include/svg/s43/mail2.svg').default} alt="" />
                }
                <a className="g-ml-12 highlight" target="_blank" href={`mailto:${(isStaynfun) ? staynfunInfo.email : siteInfo.email}`} title={emailCustomerService}>
                  {(isStaynfun) ? staynfunInfo.email : siteInfo.email}
                </a>
              </p>

              <p>
                {
                  (isStaynfun) ?
                    <img src={require('@assets/include/svg/s43/homestay_phone2.svg').default} alt="" /> :
                    <img src={require('@assets/include/svg/s43/phone2.svg').default} alt="" />
                }
                <a className="g-ml-12 highlight" target="_blank" href={`tel:${(isStaynfun) ? staynfunInfo.hotlineNumber : siteInfo.hotlineNumber}`} title={phoneCustomerService}>
                  {(isStaynfun) ? staynfunInfo.hotlineNumber : '1900 23 23 28'}
                </a>
              </p>
            </div>
          </div>

          <div className="col-2nd">
            <h3>
              {(isStaynfun) ? introductionStaynfun : introduction}
            </h3>
            <nav className="g-mb-10">
              <ul className='u-list-inline'>
                <li><a href={getFooterLink(`/${state.lang}/about-us`)} title="Về chúng tôi">{aboutUs}</a></li>
                <li><a href={'https://tuyendung.vingroup.net/vinpearl'} title="Tuyển dụng">{recruitment}</a></li>
                {
                  !isStaynfun && 
                  <>
                    <li>
                      <a href={getFooterLink(`/${state.lang}/contact`)} title="Liên hệ">{contact}</a>
                    </li>
                    <li>
                      <a href={getFooterLink(`//${state.lang}/faq-page?_ga=2.243877184.458778064.1620093098-214381622.1617004043`)} title="Câu hỏi thường gặp">{question}</a>
                    </li>
                    <li>
                      <a href={getFooterLink(`/${state.lang}/site-map`)} title="Bản đồ trang">{sitemap}</a>
                    </li>
                  </>
                }
              </ul>
            </nav>
          </div>

          <div className="col-3rd">
            <h3>
              <FormattedMessage
                id="Nav.TopHeader.TermsConditions"
                defaultMessage='Điều khoản & Quy định'
              />

            </h3>
            <nav>
              <ul className='u-list-inline'>
                {
                  isStaynfun ? <>
                    {navHomestayFooter.map(renderNavLinkItem)}
                  </> : 
                  <>
                    {renderNavLinkItem(navRegulationsFooter[0])}
                    {renderNavLinkItem(navRegulationsFooter[1])}
                    {slice(navRegulationsFooter, 2).map(renderNavLinkItem)}
                  </>
                }
              </ul>
            </nav>
          </div>

          <div className="col-4th">
            <a style={{ lineHeight: 0 }} href={getFooterLink('/vi/tin-tuc-du-lich')} title="Tin Tức & Sự Kiện">
              <h3>
                <FormattedMessage
                  id="Nav.TopHeader.NewsEvent"
                  defaultMessage='Tin Tức & Sự Kiện'
                />
              </h3>
            </a>
            <nav className="g-mb-10">
              <ul className='u-list-inline'>
                <li><a href={getFooterLink(`/${state.lang}/news/company`)} title="Tin tức công ty">{newCompany}</a></li>
                <li><a href={getFooterLink(`/${state.lang}/news/travel-guide`)} title="Cẩm nang du lịch">{ebook}</a></li>
                <li><a href={getFooterLink(`/${state.lang}/achievements`)} title="Thành tựu">{achievement}</a></li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="row main-footer-links align-items-start justify-content-between">
          <div className="col-1st">
            <div className='g-mt-5 text g-font-size-12'>
              <span className="d-inline-block">
                <FormattedMessage
                  id="Footer.BankAccount"
                  defaultMessage="Chủ tài khoản"
                />:
              </span> <br />
              {
                isStaynfun ?
                  <FormattedHTMLMessage
                    id="Footer.Homestay.BankAccountContent"
                    defaultMessage="Công ty cổ phần StaynFun Tài khoản ngân hàng số: 068704070230296 (VND) Ngân hàng TMCP Phát Triển Thành phố Hồ Chí Minh – Trung Tâm Kinh Doanh"
                  /> :
                  <FormattedHTMLMessage
                    id="Footer.BankAccountContent"
                    defaultMessage="Công ty cổ phần Vinpearl Tài khoản ngân hàng số: 9124412488166 (VND) Ngân hàng thương mại cổ phần Kỹ Thương Việt Nam - Techcombank"
                  />
              }
            </div>

            <div className='g-mt-20 text g-font-size-12'>
              <span className="d-inline-block">
                <FormattedMessage
                  id="Footer.BusinessLicenseNumber"
                  defaultMessage="Số ĐKKD"
                />:
              </span>
              <br />
              {isStaynfun ?
                <FormattedHTMLMessage
                  id="Footer.Homestay.BusinessLicenseNumberContent"
                  defaultMessage="0901159067. ĐK lần đầu: 19/04/2024. ĐK thay đổi lần thứ nhất: 26/04/2024 <br/> Nơi cấp: Sở kế hoạch và đầu tư tỉnh Hưng Yên"
                /> : 
                <FormattedHTMLMessage
                  id="Footer.BusinessLicenseNumberContent"
                  defaultMessage="4200456848. ĐK lần đầu 26/7/2006. ĐK thay đổi tại từng thời điểm <br/> Nơi cấp: Sở kế hoạch và đầu tư tỉnh Khánh Hòa"
                />
              }
            </div>

            {
              !isStaynfun && 
              <div className="ccdv-footer">
                <a className="" href={ccdvFooter.href}>
                  <img className="g-width-auto g-height-50" alt="" title="" src={ccdvFooter.image.src} />
                </a>
              </div>
            }
          </div>

          <div className={clsx("col-2nd", {
            "hot-destination": isStaynfun,
          })}>
            <div>
              <h3>
                <FormattedHTMLMessage
                  id="Nav.TopHeader.HotDestination"
                  defaultMessage="Địa điểm nổi bật"
                />
              </h3>
              <nav className="g-mb-10">
                <ul className='u-list-inline'>
                  <li>
                    <a href={getFooterLink(`/${state.lang}/phu-quoc`)} title="Phú Quốc">
                      <FormattedHTMLMessage
                        id="Nav.TopHeader.PopulationPhuQuoc"
                        defaultMessage="Phú quốc"
                      />
                    </a>
                  </li>
                  <li>
                    <a href={getFooterLink(`/${state.lang}/nha-trang`)} title="Nha Trang">
                      <FormattedHTMLMessage
                        id="Nav.TopHeader.PopulationNhaTrang"
                        defaultMessage="Nha Trang"
                      />
                    </a>
                  </li>
                  <li>
                    <a href={getFooterLink(`/${state.lang}/nam-hoi-an`)} title="Nam Hội An">
                      <FormattedHTMLMessage
                        id="Nav.TopHeader.PopulationNamHoiAn"
                        defaultMessage="Nam Hội An"
                      />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div className="col-3rd"></div>


          <div className="col-4th">
          {
            (!isStaynfun ) && 
            <>
            <div className='qr-code-container'>
                <img className='qr-code' alt='' src={require("@assets/include/svg/s43/qr-download.svg").default} />
                <div className='qr-text'>
                  <div>
                    <FormattedHTMLMessage
                      id="S43.Hotel.ScanQRCodeToDownload"
                      defaultMessage="Scan QR code to download"
                    />
                  </div>
                  <img alt='' src={require("@assets/include/svg/s43/arrow2.svg").default} />
                </div>
              </div>
              <div className='download-app-container'>
                <a href={`https://apps.apple.com/us/app/vinpearl-experience/id1484921109?l=${state.lang}`}>
                  <img alt='' src={require("@assets/include/svg/s43/appstore.png").default} />
                </a>
                <a href='https://play.google.com/store/apps/details?id=net.cloudhms.booking.vinpearl'>
                  <img alt='' src={require("@assets/include/svg/s43/googleplay.png").default} />
                </a>
              </div>
              </>
            }
          </div>
        </div>
      </div>

      <div className="extra-informations">
        <div className="container">
          <div className="copyright">
            Copyright © 
            {(isStaynfun) ? ' 2024 Staynfun.vn. ' : ' 2022 Vinpearl.com. '}
            All rights reserved
          </div>

          <nav className="nav inline-nav">
            <ul className="menu socials pl-0">
              <li className="menu-item">
                <a href={siteInfo.facebookFanpage} target="_blank" title="facebook">
                  {
                    (isStaynfun) ? 
                    <img src={require("@assets/include/svg/s43/homestay_facebook1.svg").default} /> :
                    <img src={require("@assets/include/svg/s43/facebook1.svg").default} />
                  }
                </a>
              </li>
              <li className="menu-item">
                <a href={siteInfo.instagram} style={{ marginRight: 0 }} target="_blank" title="instagram">
                  {
                    (isStaynfun) ? 
                    <img src={require('@assets/include/svg/s43/homestay_instagram1.svg').default} /> :
                    <img src={require('@assets/include/svg/s43/instagram1.svg').default} />
                  }
                </a>
              </li>
              <li className="menu-item">
                <a href={siteInfo.youtubeChannel} target="_blank" title="youtube">
                  {
                    (isStaynfun) ? 
                    <img src={require('@assets/include/svg/s43/homestay_youtube1.svg').default} /> :
                    <img src={require('@assets/include/svg/s43/youtube1.svg').default} />
                  }
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {/* <div class="experiment_text"><i className="g-color-white">{demoWebsite}</i></div> */}
    </footer>
  )
}

export default injectIntl(Footer)
