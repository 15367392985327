import { createSelector } from 'reselect'

const getSiteInfo = state => state.siteInfo.siteInfo || {};
const getIpLocale = state => state.siteInfo.ipLocale || {};

export const selectSiteInfo = () =>
    createSelector(
        getSiteInfo,
        siteInfo => siteInfo || {},
    )

export const selectIpLocale = () =>
    createSelector(
        getIpLocale,
        siteInfo => siteInfo || {},
    )
