import { formatFullPhoneNumber } from '.';
import { DEFAULT_PAYMENT_TYPE, enumType } from '../constants'
import { objectExtensions } from '../extensions';
import tour from '../utils/tour';

export default {
  getStateDeleteCartItem
    ({
      currentCart = {},
      currentProductVariantId = [],
      coupon = undefined,
      deleteData,
    }) {
    const {
      vouchers, tours, vinWonders,
    } = currentCart;
    if (vouchers && vouchers.length > 0) {
      currentCart.vouchers = vouchers.filter(({ productVariantId }) => productVariantId !== deleteData.id)
    }
    if (vinWonders && vinWonders.length > 0) {
      currentCart.vinWonders = vinWonders.filter(({ productVariantId, usingDate }) =>
        !(productVariantId === deleteData.id && usingDate === deleteData.usingDate))
    }
    if (tours && tours.length > 0) {
      currentCart.tours = tours.filter(({ productVariantId }) => productVariantId !== deleteData.id)
    }
    let productVariantId = currentProductVariantId
    if (currentProductVariantId && currentProductVariantId.length > 0) {
      let productVariantId = deleteData.productVariantId || deleteData.id;
      if (deleteData.usingDate) {
        productVariantId = `${productVariantId}|${deleteData.usingDate}`
      }
      productVariantId = currentProductVariantId.filter(id => id !== productVariantId)
    }
    if (coupon) {
      coupon.productVariantIds = objectExtensions.getCartProductIds(currentCart);
    }
    return {
      currentCart,
      currentCount: productVariantId.length,
      newCoupon: coupon,
      productVariantId
    }
  },

  getStateUpdateCartItem
    ({
      currentCart = {},
      itemUpdate = {},
      coupon = undefined,
    }) {
    const {
      productVariantId, usingDate, sessionId
    } = itemUpdate
    if (productVariantId) {
      const {
        vouchers,
        vinWonders,
        tours,
        vinWonderTour
      } = currentCart
      if (vouchers && vouchers.length > 0) {
        const indexVoucher = vouchers.findIndex(voucher => voucher.productVariantId === productVariantId)
        if (indexVoucher >= 0) {
          vouchers[indexVoucher] = itemUpdate
        }
        currentCart.vouchers = vouchers
      }
      if (vinWonders && vinWonders.length > 0) {
        const indexVinWonder = vinWonders.findIndex(vinWonder =>
          vinWonder.productVariantId === productVariantId && vinWonder.usingDate === usingDate && vinWonder.sessionId === sessionId)
        if (indexVinWonder >= 0) {
          vinWonders[indexVinWonder] = itemUpdate
        }
        currentCart.vinWonders = vinWonders
      }
      if (tours && tours.length > 0) {
        const indexTour = tours.findIndex(tour => tour.productVariantId === productVariantId)
        if (indexTour >= 0) {
          tours[indexTour] = itemUpdate
        }
        currentCart.tours = tours
      }
      if (coupon) {
        coupon.productVariantIds = objectExtensions.getCartProductIds(currentCart);
      }
      if (vinWonderTour && vinWonderTour.length > 0) {
        const indexVinWonderTour = vinWonderTour.findIndex(vinWonderTourItem =>
          vinWonderTourItem.productVariantId === productVariantId && moment(vinWonderTourItem.usingDate).isSame(moment(usingDate)))
          if (indexVinWonderTour >= 0) {
            vinWonders[indexVinWonderTour].quantity = itemUpdate.quantity;
          }
          currentCart.vinWonderTour = vinWonderTour;
      }
    }
    return { currentCart, newCoupon: coupon }
  },

  initCartItem
    ({
      item = {},
      recaptcha = null,
      cartCode = null,
      type,
      quantity = 1,
      productVariantId
    }) {
    const cartItemType = item.cartItemType || type;
    return {
      ...item,
      cartCode: cartCode,
      productVariantId: productVariantId,
      cartItemType,
      quantity: item.quantity !== undefined ? item.quantity : quantity,
      originalPrice: item.originalPrice,
      salePrice: item.salePrice,
      childOriginalPrice: item.childOriginalPrice,
      childSalePrice: item.childSalePrice,
      childQuantity: item.childQuantity,
      infantOriginalPrice: item.infantOriginalPrice,
      infantSalePrice: item.infantSalePrice,
      infantQuantity: item.infantQuantity,
      seniorOriginalPrice: item.seniorOriginalPrice,
      seniorSalePrice: item.seniorSalePrice,
      seniorQuantity: item.seniorQuantity,
      googleCaptchaResponse: recaptcha,
      usingDate: cartItemType === enumType.BOOKING_TYPE.TOUR ? ( item.type === 2 ? item.departureDate : undefined ) : item.usingDate,
    }
  },

  initDataCartCheckout(data, cartCode, affSId = undefined) {
    const voucherItems = (data.vouchers || []).map(voucher => ({ ...voucher, skuId: voucher.tourId }));
    const tourItems = (data.tours || []).map(tour => ({ ...tour, skuId: tour.tourId }));
    const vinWonderItems = (data.vinWonders || []).map(vinwonder => ({ ...vinwonder, skuId: vinwonder.vinWonderId, ticketItemId: vinwonder?.tourTicketId }));
    let cartItems = (voucherItems || []).concat(tourItems).concat(vinWonderItems);
    cartItems = cartItems.filter(item => item.checked !== false);
    return {
      cartCode: cartCode,
      gatewayId: data.gatewayId,
      firstname: data.firstName,
      lastname: data.lastName,
      email: data.email,
      phoneNumber: formatFullPhoneNumber(data.prefixPhone || enumType.prefixPhone.Vn, data.phone),
      note: data.note || '',
      clientId: data.clientId,
      couponCode: data.couponCode,
      paymentType: data.paymentType ? data.paymentType.toLowerCase() : DEFAULT_PAYMENT_TYPE,
      paymentMethod: data.paymentMethod,
      affSId,
      cartItems: cartItems.length ? cartItems : undefined,
      customers: data.customers
    }
  },

}