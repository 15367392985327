import React, { memo } from 'react'
import { Menu, Dropdown, Icon } from 'antd'
// constant
import {
  currencyEnum
} from '../../constants/currencyUnit'
import { getCurrentCurrency, setCurrencyCookie } from '../../utils/lang'
import { objectExtensions } from '../../extensions'
import { useGlobalStore } from '../../hooks/useGlobal'
import clsx from 'clsx'

const CurrencyUnit = ({ className }) => {

  const { state, dispatch } = useGlobalStore();
  // functions
  const handleChangeCurrency = ({ key }) => {
    if (key !== currency) {
      setCurrencyCookie(key)
      dispatch({ type: 'currency', data: key });
      window.location.reload()
    }
  }

  // init variables before render
  const currency = state.currency || getCurrentCurrency()
  const menu = (
    <Menu
      selectable={false}
      onClick={handleChangeCurrency}
      className="g-mt-7 g-mt-0--md"
    >
      {
        currencyEnum.map((currency) => !currency.hide && (
          <Menu.Item
            key={currency.value}
            className='g-py-12'
          >
            <div className='d-flex'>
              <div className="g-px-0 g-width-36">
                <div className="g-font-size-14 g-font-weight-500 vpt-color-v0 ">{currency.icon}</div>
              </div>
              <div className=" g-px-0 g-px-5 vpt-color-v7 g-font-weight-500"> {currency.label}</div>
            </div>
          </Menu.Item>
        ))
      }
    </Menu>
  )
  // render
  return (
    <div className={clsx("d-inline-block g-pr-5--md g-pr-15--lg", className)}>
      <Dropdown overlay={menu} trigger={['hover']} placement="bottomCenter">
        <strong>
          <a className="ant-dropdown-link d-flex flex-row justify-content-center align-items-center">
            <p
              className='g-color-black g-font-size-14 g-font-weight-700 p-0 m-0'>{objectExtensions.getIconFromValue(currency, currencyEnum)}</p>
            <img className='g-px-5' src={require('@assets/img/icons/Drop.png').default} alt="icon" />
          </a>
        </strong>
      </Dropdown>
    </div>
  )
}

export default memo(CurrencyUnit)
