import React from 'react'

// lib
import * as Yup from 'yup'
// constant
import { enumType, validateError, MINIMUM_YEAR } from '../constants'
// extensions
import { datetimeExtensions, objectExtensions } from './index'
// utils
import { flightUtils, experienceUtils } from '../utils'
import moment from 'moment'
import { FormattedMessage } from "react-intl.macro"

const stringRequired = Yup.string()
  .required(validateError.required)
  .trim(validateError.required)
  .typeError(validateError.required)

const string = Yup.string()
  .nullable()

const numberRequired = Yup.number()
  .required(validateError.required)
  .typeError(validateError.required)

const number = Yup.number().nullable()

const bool = Yup.bool()

const objectRequired = Yup.object()
  .required(validateError.required)
  .typeError(validateError.required)

const arrayRequired = Yup.array().nullable(false)
  .required(validateError.required)
  .typeError(validateError.required)

const object = Yup.object().nullable()

const mixedRequired = Yup.mixed()
  .required(validateError.required)
  .typeError(validateError.required)

const endPoint = Yup.mixed()
  .required(validateError.required)
  .typeError(validateError.required)
  .test({
    name: 'endpoint',
    message: validateError.endPoint,
    test: function (endPoint) {
      const endPointValue = objectExtensions.getValueObject(endPoint)
      const startPointValue = objectExtensions.getValueObject(
        this.parent.startPoint
      )
      if (!endPointValue || !startPointValue) {
        return true
      }
      if (endPointValue === startPointValue){
        return false
      }

      const endPointValueSplit = endPointValue.split(" ")
      const startPointValueSplit = startPointValue.split(" ")
      if(endPointValueSplit.length > 1 && startPointValueSplit.length > 1 ){
        return (endPointValueSplit[0] !== startPointValueSplit[0] && endPointValueSplit[1] !== startPointValueSplit[1])
      }

      return true
    }
  })

const phoneNumberRequired = Yup.string()
  .required(validateError.required)
  .trim(validateError.required)
  .typeError(validateError.number)
  .matches(/^\d+$/, validateError.number)
  .min(9, ({ min }) => validateError.min(<FormattedMessage
    id="MyOrder.Detail.BuyerInformation.Phone"
    defaultMessage="Số điện thoại"
  />, min))
  .max(10, ({ max }) => validateError.max(<FormattedMessage
    id="MyOrder.Detail.BuyerInformation.Phone"
    defaultMessage="Số điện thoại"
  />, max))

const phoneNumberWithAreaCodeRequired = Yup.string()
  .required(validateError.required)
  .trim(validateError.required)
  .typeError(validateError.number)
  .matches(/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm, validateError.phoneNumberPattern)
  // .min(9, ({ min }) => validateError.min(<FormattedMessage
  //   id="MyOrder.Detail.BuyerInformation.Phone"
  //   defaultMessage="Số điện thoại"
  // />, min))
  // .max(10, ({ max }) => validateError.max(<FormattedMessage
  //   id="MyOrder.Detail.BuyerInformation.Phone"
  //   defaultMessage="Số điện thoại"
  // />, max))

export default {
  stringRequired: stringRequired,
  string: string,
  emailRequired: stringRequired
    .email(validateError.email),
  email: string
    .email(validateError.email),
  numberRequired: numberRequired,
  number: number,
  bool: bool,
  objectRequired: objectRequired,
  arrayRequired: arrayRequired,
  object: object,
  mixedRequired: mixedRequired,

  passwordPatternRequired: stringRequired.matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W]).{6,}$/,
    validateError.passwordPattern
  ),
  formSignUpPasswordPatternRequired: stringRequired.matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W]).{8,}$/,
    validateError.passwordPattern
  ),
  formResetPasswordPatternRequired: Yup.string()
    .required(validateError.requiredPasswordVPT)
    .trim(validateError.requiredPasswordVPT)
    .typeError(validateError.requiredPasswordVPT)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W]).{8,}$/,
      validateError.resetPasswordVPTPattern
    ),
  formResetPasswordRetype: Yup.string()
    .required(validateError.resetPasswordVPTMismatched)
    .trim(validateError.resetPasswordVPTMismatched)
    .typeError(validateError.resetPasswordVPTMismatched)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W]).{8,}$/,
      validateError.resetPasswordVPTMismatched
    ),

  phoneNumberPattenRequired: stringRequired.matches(
    /^(\+84|0)[0-9]{1}[0-9]{8,9}$/,
    validateError.phoneNumberPattern
  ),

  phoneNumberRequired: phoneNumberRequired,
  phoneNumberWithAreaCodeRequired: phoneNumberWithAreaCodeRequired,

  boolRequired: Yup.bool().oneOf([true], validateError.agreement),
  vinWonderTicketItem: Yup.mixed()
    .test({
      name: 'validate-vinwonder-ticket-item',
      message: validateError.required,
      test: function (ticketItems) {
        let totalQuantity = 0;
        ticketItems.forEach(item => {
          totalQuantity += item.quantity;
        });
        return totalQuantity !== 0
      }
    })
  ,
  vinWonderBookingDate: Yup.string()
    .required(validateError.required)
    .trim(validateError.required)
    .typeError(validateError.required)
    .test({
      name: 'validate-vinwonder-booking-date',
      message: validateError.vinWonderBookingDateQuarantineCovid19,
      test: function (dateString) {
        const quarantineCovid19Range = {
          start: moment('01/04/2020', 'DD/MM/YYYY').startOf('days'),
          end: moment('15/04/2020', 'DD/MM/YYYY').endOf('days'),
        }
        const date = moment(dateString, 'DD/MM/YYYY');
        return !date.isBetween(quarantineCovid19Range.start, quarantineCovid19Range.end)
      }
    })
  ,
  //tour
  tourTicketPrice: Yup.mixed()
    .test({
      name: 'validate-tour-ticket-price',
      message: validateError.required,
      test: function (ticketPrice) {
        let totalQuantity = 0;
        if(ticketPrice && !!ticketPrice.length) {
          ticketPrice.forEach(item => {
            totalQuantity += (item.quantity || 0);
          });
        }
        return totalQuantity !== 0
      }
    })
  ,
  tourBookingDate: Yup.string()
    .required(validateError.required)
    .trim(validateError.required)
    .typeError(validateError.required)
    .test({
      name: 'validate-vinwonder-booking-date',
      message: validateError.vinWonderBookingDateQuarantineCovid19,
      test: function (dateString) {
        const date = moment(dateString, 'DD/MM/YYYY');
        return !date.isAfter(moment().set('year', MINIMUM_YEAR).startOf('year'))
      }
    })
  ,
  // flight
  flightObject: Yup.object().shape({
    itineraryType: stringRequired,
    startPoint: mixedRequired,
    endPoint: endPoint,
    departureDate: stringRequired,
    returnDate: Yup.string()
      .when(['itineraryType'], {
        is: enumType.itineraryType.Return,
        then: stringRequired,
        otherwise: string
      })
      .test({
        name: 'end-date',
        message: validateError.endDate,
        test: function (returnDate) {
          return !(
            this.parent &&
            this.parent.departureDate &&
            datetimeExtensions.checkIsPreviousVnDate(
              returnDate,
              this.parent.departureDate
            )
          )
        }
      }),
    // passenger: Yup.object().shape({
    //   adult: numberRequired
    //     .test({
    //       name: 'passenger-adult',
    //       message: validateError.adultQuantity,
    //       test: function (adult) {
    //         return flightUtils.checkAdultPassenger(
    //           adult,
    //           this.parent.child,
    //           this.parent.infant
    //         )
    //       }
    //     }),
    //   child: numberRequired
    //     .test({
    //       name: 'passenger-child',
    //       message: validateError.childQuantity,
    //       test: function (child) {
    //         return flightUtils.checkAdultPassenger(
    //           this.parent.adult,
    //           child,
    //           this.parent.infant
    //         )
    //       }
    //     }),
    //   infant: numberRequired
    //     .test({
    //       name: 'passenger-infant',
    //       message: validateError.infantQuantity,
    //       test: function (infant) {
    //         return flightUtils.checkInfantPassenger(
    //           this.parent.adult,
    //           this.parent.child,
    //           infant
    //         )
    //       }
    //     })
    // })
  }),
  bundleObject: Yup.object().shape({
    numberOfRoom: numberRequired,
    startPoint: mixedRequired,
    endPoint: endPoint,
    departureDate: stringRequired,
    returnDate: Yup.string()
      .when(['itineraryType'], {
        is: enumType.itineraryType.Return,
        then: stringRequired,
        otherwise: string
      })
      .test({
        name: 'end-date',
        message: validateError.endDate,
        test: function (returnDate) {
          return !(
            this.parent &&
            this.parent.departureDate &&
            datetimeExtensions.checkIsPreviousVnDate(
              returnDate,
              this.parent.departureDate
            )
          )
        }
      }),
    // passenger: Yup.object().shape({
    //   adult: numberRequired
    //     .test({
    //       name: 'passenger-adult',
    //       message: validateError.adultQuantity,
    //       test: function (adult) {
    //         return flightUtils.checkAdultPassenger(
    //           adult,
    //           this.parent.child,
    //           this.parent.infant
    //         )
    //       }
    //     }),
    //   child: numberRequired
    //     .test({
    //       name: 'passenger-child',
    //       message: validateError.childQuantity,
    //       test: function (child) {
    //         return flightUtils.checkAdultPassenger(
    //           this.parent.adult,
    //           child,
    //           this.parent.infant
    //         )
    //       }
    //     }),
    //   infant: numberRequired
    //     .test({
    //       name: 'passenger-infant',
    //       message: validateError.infantQuantity,
    //       test: function (infant) {
    //         return flightUtils.checkInfantPassenger(
    //           this.parent.adult,
    //           this.parent.child,
    //           infant
    //         )
    //       }
    //     })
    // })
  }),
  //hotel
  hotelObject: Yup.object().shape({
    startPoint: Yup.mixed()
      .test({
        name: 'validate-start-point',
        message: validateError.required,
        test: function (value) {
          const isInvalidObject = !value || Object.keys(value).length === 0
          const isValidString = stringRequired.isValidSync(this.parent.startPointName)
          return !(isInvalidObject && !isValidString)

        }
      })
    ,
    startPointName: Yup.string()
      .test({
        name: 'validate-start-point-name',
        message: validateError.required,
        test: function (value) {
          const isValidString = stringRequired.isValidSync(value)
          const isValidObject = mixedRequired.isValidSync(this.parent.startPoint)
          return !(!isValidString && !isValidObject)
        }
      })
      .when(['startPoint'], {
        is: value => value === null,
        then: stringRequired,
        otherwise: string
      }),
    departureDate: stringRequired,
    returnDate: stringRequired,
    // passenger: Yup.object().shape({
    //   adult: numberRequired,
    //   child: numberRequired,
    //   infant: numberRequired
    // }),

    // numberOfRoom: numberRequired
    //   .min(1, ({ min }) => validateError.minNumber('Số phòng', min)),
    lengthOfStay: numberRequired
      .min(1, ({ min }) => validateError.minNumber('Số đêm', min)),
  }, [['startPoint', 'startPointName']])
}
