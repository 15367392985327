import React, { createContext, useContext, useReducer, useMemo } from 'react';
import { IntlProvider } from 'react-intl'
import { cloneDeep } from 'lodash'
import ContextStore from '../model/ContextStore';
import localeVi from 'antd/lib/locale/vi_VN'
import localeEn from 'antd/lib/locale/en_US'
import localeKo from 'antd/lib/locale/ko_KR'
import localeZh from 'antd/lib/locale/zh_CN';
import { language } from '../constants/language';
import { ConfigProvider } from 'antd';

export const globalStore = new ContextStore()

export const GlobalContext = createContext(globalStore)

const globalReducer = (state, action) => {
  switch (action.type) {
    case "lang":
      return Object.assign(cloneDeep(state), {
        lang: action.data
      })
    case "currency":
      return Object.assign(cloneDeep(state), {
        currency: action.data
      })
    case "currencyOnl":
      return Object.assign(cloneDeep(state), {
        currencyOnl: action.data
      })
    case "cartTotalPrice":
      return Object.assign(cloneDeep(state), {
        cartTotalPrice: action.data
      })
    case "auth":
      return Object.assign(cloneDeep(state), {
        auth: action.data
      })
    case "userAgent":
      return Object.assign(cloneDeep(state), {
        userAgent: action.data
      })
    default:
      return state;
  }
}



export const GlobalProvider = ({ children, value, translations }) => {
  const [state, dispatch] = useReducer(globalReducer, value);
  const messages = translations[state.lang] || {};
  // console.log('=====> Global state', state.lang)
  globalStore.dispatch = dispatch;
  globalStore.state = state;

  const locale = useMemo(() => {
    let _locale;
    let _langMoment;
    switch (state.lang) {
      case language.Vietnamese:
        _locale = localeVi;
        _langMoment = "vi";
        break;
      case language.English:
        _locale = localeEn;
        _langMoment = "en";
        break;
      case language.Korean:
        _locale = localeKo;
        _langMoment = "ko";
        break;
      case language.Chinese:
        _locale = localeZh;
        _langMoment = "zh-cn";
        break;
      default:
        _locale = localeVi;
    }

    import(`moment/locale/${_langMoment}`).then();

    return _locale;
  }, [state.lang])

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      <IntlProvider locale={state.lang} key={state.lang} messages={messages}>
        <ConfigProvider locale={locale}>
          {children}
        </ConfigProvider>
      </IntlProvider>
    </GlobalContext.Provider>
  )
}

export const useGlobalStore = () => useContext(GlobalContext);

export default GlobalContext
