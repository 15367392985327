import { checkPageType } from "@utils/gtmHelpers";
import { LOCAL_STORAGE_KEY } from "src/constants";
import { languageEnum } from "src/constants/language";
import { gtmEvent } from "src/helpers/gtmEvent";
import { LocalStorage } from "..";

export const createVirtualPageView = ({ dataPage = {}, type = gtmType.VIRTUAL_PAGE_VIEW, isHomePage = false }) => {
  const {
    page_type,
    page_category_lv1,
    page_category_lv2,
    page_category_lv3,
  } = checkPageType({
    location: dataPage,
    isHomePage: isHomePage,
  });
  const response = {
    event: type,
    page_type,
    page_platform: "Web",
    page_category_lv1,
    page_category_lv2,
    page_category_lv3,
  }
  if (dataPage.href.includes("staynfun")) {
    const language = LocalStorage.get(LOCAL_STORAGE_KEY.LANGUAGE_WESBITE)
    const laguageName = languageEnum.find(el => language.data == el.value)
    return {
      ...response,
      page_language: laguageName?.label?.props?.defaultMessage || 'Tiếng Việt',
    }
  } else {
    return {
      ...response
    };
  }
};

export const createDefaultPageView = ({ dataPage = {} }) => {
  const {
    page_type,
    page_category_lv1,
    page_category_lv2,
    page_category_lv3,
  } = checkPageType({
    location: dataPage,
  });
  return {
    page_type,
    page_platform: "Web",
    page_category_lv1,
    page_category_lv2,
    page_category_lv3,
  };
};
