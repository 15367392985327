import React from 'react'
import { withRouter as withReactRouterDom } from 'react-router-dom'
import { commonUtils } from '../index';
import { parsePathToRoute } from '../common'

let AppRouter = {};

const pushRouter = (originPush) => (path, asPath, options) => {
  const href = asPath || path
  if (commonUtils.isExternalLink(href)) {
    window.location = href;
    return
  }
  return originPush(href)
}

export const withRouter = (Component) => {

  const Wrapped = ({ history, match, location, ...restProps }) => {
    const asPath = location.pathname + location.search;
    const router = { ...history, route: parsePathToRoute(match?.path), asPath };
    AppRouter.push = pushRouter(router.push);
    AppRouter.go = router.go;
    AppRouter.replace = router.replace;

    return (
      <Component
        {...restProps}
        history={history}
        match={match}
        location={location}
        router={router}
      />
    )
  }
  return withReactRouterDom(Wrapped)
}

export const useRouter = () => AppRouter;

export default AppRouter