import { getMomentDate } from '../utils/common';
import { VINWONDER_ENUM } from '../constants';
import { toArray, find } from 'lodash';
import { experienceUtils } from '../utils'
export default class VinWonder {

    constructor(data = {}) {
        this.setData(data);
    }

    setData(data = {}) {
        this.id = data.id || undefined;
        this.name = data.name || undefined
        this.siteCode = data.siteCode || undefined;
        this.vinWonderCode = data.vinWonderCode || undefined;
        this.isAvailable = data.isAvailable || false;
        this.isSpecial = data.isSpecial || false;
        this.isHot = data.isHot || false;
        this.isNotReadyToSell = data.isNotReadyToSell || false;
        this.urlSlug = data.urlSlug || undefined;
        this.thumbImage = data.thumbImage || undefined;
        this.thumbImageView = data.thumbImageView || undefined;
        this.images = data.images || [];
        this.imagesView = data.imagesView || [];
        this.shortDescription = data.shortDescription || undefined;
        this.description = data.description || undefined;
        this.highLight = data.highLight || undefined;
        this.conditionOfUse = data.conditionOfUse || undefined;
        this.operationManuals = data.operationManuals || undefined;
        this.createdDate = getMomentDate(data.createdDate);
        this.ratingValue = data.ratingValue || undefined;
        this.soldQuantity = data.soldQuantity || 0;
        this.saleStartDate = getMomentDate(data.saleStartDate);
        this.saleEndDate = getMomentDate(data.saleEndDate);
        this.setBlackOut(data);
        this.setTickets(data);
        this.salePrice = data.salePrice || this.getPrice() || 0;
        this.originalPrice = experienceUtils.checkVinWonderOriginalPrice(data.originalPrice || 0, this.salePrice);
        this.installmentFee = data.installmentFee || undefined;
        this.installmentLimit = data.installmentLimit || undefined;
        this.type = data.type || undefined;
    }

    setTickets(data = {}) {
        this.tickets = (data.tickets || []).map(item => new VinWonderTicket(item));
        // if (dummyTickets.length) {
        //     this.tickets = dummyTickets.map(item => new VinWonderTicket({ ...item, vinWonderId: this.id }))
        // }
    }

    setBlackOut(data = {}) {
        if (data.blackOutStartDate && data.blackOutEndDate) {
            this.blackOut = new VinWonderBlackOut(data)
        } else {
            this.blackOut = undefined;
        }
    }

    getPrice(priceFieldName = 'salePrice', ticketType = VINWONDER_ENUM.TICKET.TYPE.ADULT.id) {
        let price = undefined;
        this.tickets.forEach(ticket => {
            ticket.ticketItems.forEach(item => {
                if (item[priceFieldName] && (price === undefined || price > item[priceFieldName]) && ticketType === item.ticketType) {
                    price = item[priceFieldName];
                }
            })
        })
        return price;
    }

}

export class VinWonderTicket {
    constructor(data = {}) {
        this.setData(data);
    }

    setData(data = {}) {
        this.id = data.id || undefined;
        this.name = data.name || undefined;
        this.vinWonderId = data.vinWonderId || undefined;
        this.description = data.description || undefined;
        this.shortDescription = data.shortDescription || undefined;
        this.setTicketItems(data)
        this.salePrice = this.getPrice('salePrice', VINWONDER_ENUM.TICKET.TYPE.ADULT.id, true) || 0;
        this.originalPrice = experienceUtils.checkVinWonderOriginalPrice(this.getPrice('originalPrice', VINWONDER_ENUM.TICKET.TYPE.ADULT.id, true) || 0, this.salePrice);
    }

    setTicketItems(data = {}) {
        this.ticketItems = (data.ticketItems || []).map(item => new VinWonderTicketItem(item))
    }

    getPrice(priceFieldName = 'salePrice', ticketType = VINWONDER_ENUM.TICKET.TYPE.ADULT.id, defaultFirst = false) {
        let price = undefined;
        this.ticketItems.forEach(item => {
            if (item[priceFieldName] && (price === undefined || price > item[priceFieldName]) && ticketType === item.ticketType) {
                price = item[priceFieldName];
            }
        })
        if (defaultFirst && price === undefined && this.ticketItems.length) {
            price = this.ticketItems[0][priceFieldName]
        }
        return price;
    }

    getTotalPrice() {
        let price = 0;
        this.ticketItems.forEach(item => {
            price += (item.salePrice * item.quantity)
        })
        return price;
    }
    getTotalItem() {
        let total = 0;
        this.ticketItems.forEach(item => {
            total += item.quantity;
        })
        return total;
    }
}

export class VinWonderTicketItem {
    constructor(data = {}) {
        this.setData(data);
    }

    setData(data = {}) {
        this.id = data.id || undefined;
        this.vinWonderTicketId = data.vinWonderTicketId || undefined;
        this.ticketType = data.ticketType || undefined;
        this.setTicketTypeName(this.ticketType)
        this.refId = data.refId || undefined;
        this.salePrice = data.salePrice || 0;
        this.originalPrice = experienceUtils.checkVinWonderOriginalPrice(data.originalPrice || 0, this.salePrice);
        this.quantity = data.quantity || 0;
    }

    setTicketTypeName(typeId = this.ticketType) {
        const type = find(toArray(VINWONDER_ENUM.TICKET.TYPE), item => item.id === typeId);
        this.ticketTypeName = type && type.name;
        this.ticketTypeNameDescription = type && type.description;
    }
}

export class VinWonderBlackOut {
    constructor(data = {}) {
        this.setData(data);
    }

    setData(data = {}) {
        this.startDate = getMomentDate(data.blackOutStartDate);
        this.endDate = getMomentDate(data.blackOutEndDate);
        this.reason = data.blackOutReason || undefined;
    }
}

export class VinWonderSession {
    constructor(data = {}) {
        this.setData(data);
    }

    setData(data = {}) {
        this.sessionID = data.sessionID || 0;
        this.name = data.name || undefined;
        this.startHour = this.formatDate(data.startHour) || 0;
        this.startMinute = this.formatDate(data.startMinute) || 0;
        this.durationMinute = data.durationMinute || 0;
        this.allotment = data.allotment || 0;
        this.inventory = data.inventory || 0;
        this.totalQuantity = data.allotment > data.inventory ? data.inventory : data.allotment
    }

    formatDate(value) {
        return value < 10 ? `0${value}` : value
    }
}