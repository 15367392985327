import ActionTypes from "../actionTypes";

const INIT_STATE = {
  userInfo: {},
  isLogin: false,
  isRegister: false,
  isVerifyEmail: false,
  isRefresh: false,
  changeToken: false,
  isSignUp: false,
  isSignUpSuccess: false,
  errorLoginVpt: null,
  errorSignupVpt: null,
  upgradePearlClub: false,
  forgotPwd: false,
  isForgotPwdSuccess: false,
  errorForgotPwd: false,
  sendOtp: null,
  errorSendOtp: null,
  isUser: false,
  isCheckUserSuccess: false,
  errorCheckUser: null,
  updateUser: false,
  isUpdateUserSuccess: false,
  errorUpdateUser: false,
  avataUpload: false,
  isUpdloadAvataSuccess: false,
  errorUpdloadAvata: false,
  linkAccount: false,
  isLinkAccountSuccess: false,
  errorLinkAccount: false,
  removeOldPhone: false,
  isremoveOldPhoneSuccess: false,
  errorremoveOldPhone: false,
};

const AuthReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.USER_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isLogin: true,
        changeToken: !state.changeToken
      });
    case ActionTypes.USER_REFRESH_SUCCESS:
      return Object.assign({}, state, {
        isLogin: true,
        isRefresh: true,
        changeToken: !state.changeToken
      });
    case ActionTypes.USER_LOGOUT_SUCCESS:
      return {
        ...INIT_STATE,
        changeToken: !state.changeToken
      };
    case ActionTypes.USER_VERIFY_EMAIL_SUCCESS:
      return Object.assign({}, state, { isVerifyEmail: true });
    case ActionTypes.USER_REGISTER_SUCCESS:
      return Object.assign({}, state, { isRegister: true });
    case ActionTypes.USER_FORGOT_PASSWORD_SUCCESS:
      return INIT_STATE;
    case ActionTypes.USER_CHANGE_PASSWORD_SUCCESS:
      return INIT_STATE;
    case ActionTypes.USER_GET_PROFILE_SUCCESS:
      return Object.assign({}, state, { 
        changeToken: !state.changeToken,
        userInfo: action.payload.data,
      });
    case ActionTypes.USER_UPDATE_PROFILE_SUCCESS:
      return Object.assign({}, state);
    case ActionTypes.SIGN_UP_VPT:
      return Object.assign({}, state, {
        isSignUp: action.payload.data
      });
    case ActionTypes.SIGN_UP_VPT_SUCCESS:
      return Object.assign({}, state, {
        isSignUpSuccess: action.payload.data
      });
    case ActionTypes.LOGIN_VPT_ERROR:
      return Object.assign({}, state, {
        errorLoginVpt: action.payload.data
      });
    case ActionTypes.SIGN_UP_VPT_ERROR: {
      return Object.assign({}, state, {
        errorSignupVpt: action.payload.data
      });
    }
    case ActionTypes.CHECK_PEARL_CLUB: {
      return Object.assign({}, state, {
        upgradePearlClub: action.status
      });
    }
    case ActionTypes.FORGOT_PASSWORD:
      return Object.assign({}, state, {
        forgotPwd: action.payload.data
      });
    case ActionTypes.FORGOT_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        isForgotPwdSuccess: action.payload.data
      });
    case ActionTypes.FORGOT_PASSWORD_ERROR:
      return Object.assign({}, state, {
        errorForgotPwd: action.payload.data
      });
    case ActionTypes.SEND_OTP:
      return Object.assign({}, state, {
        sendOtp: action.payload.data
      });
    case ActionTypes.SEND_OTP_ERROR:
      return Object.assign({}, state, {
        errorSendOtp: action.payload.data
      });
    case ActionTypes.RESET_SEND_OTP:
      return Object.assign({}, state, {
        sendOtp: action.payload.data
      });
    case ActionTypes.CHECK_USER:
      return Object.assign({}, state, {
        isUser: action.payload.data
      });
    case ActionTypes.CHECK_USER_SUCCESS:
      return Object.assign({}, state, {
        isCheckUserSuccess: action.payload.data
      });
    case ActionTypes.CHECK_USER_ERROR:
      return Object.assign({}, state, {
        errorCheckUser: action.payload.data
      });
    case ActionTypes.UPDATE_USER:
      return Object.assign({}, state, {
        updateUser: action.payload.data
      });
    case ActionTypes.UPDATE_USER_SUCCESS:
      return Object.assign({}, state, {
        isUpdateUserSuccess: action.payload.data
      });
    case ActionTypes.UPDATE_USER_ERROR:
      return Object.assign({}, state, {
        errorUpdateUser: action.payload.data
      });
    case ActionTypes.UPLOAD_AVATA:
      return Object.assign({}, state, {
        avataUpload: action.payload.data
      });
    case ActionTypes.UPLOAD_AVATA_SUCCESS:
      return Object.assign({}, state, {
        isUpdloadAvataSuccess: action.payload.data
      });
    case ActionTypes.UPLOAD_AVATA_ERROR:
      return Object.assign({}, state, {
        errorUpdloadAvata: action.payload.data
      });
    case ActionTypes.LINK_ACCOUNT:
      return Object.assign({}, state, {
        linkAccount: action.payload.data
      });
    case ActionTypes.LINK_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        isLinkAccountSuccess: action.payload.data
      });
    case ActionTypes.LINK_ACCOUNT_ERROR:
      return Object.assign({}, state, {
        errorLinkAccount: action.payload.data
      });
    case ActionTypes.REMOVE_OLD_PHONE:
      return Object.assign({}, state, {
        removeOldPhone: action.payload.data
      });
    case ActionTypes.REMOVE_OLD_PHONE_SUCCESS:
      return Object.assign({}, state, {
        isremoveOldPhoneSuccess: action.payload.data
      });
    case ActionTypes.REMOVE_OLD_PHONE_ERROR:
      return Object.assign({}, state, {
        errorremoveOldPhone: action.payload.data
      });
    default:
      return state;
  }
};

export default AuthReducer;
