import ApiService from './../api/ApiService';
import Urls from './../api/Urls';
import config from "./../config"


export function getPageDetail(slug) {
    return new ApiService({
        endpoint: `${Urls.PAGE_DETAIL}/${slug}`,
        baseURL: config.BASE_URL_BLOG_API
    }).get()
}
