import ActionTypes from '../actionTypes'

const initState = {
  departureFlight: null,
  returnFlight: null,
  hotel: null,
  flightSession: null,
  departurePoints: [],
  returnPoints: [],
  dataSession: null,
  bundles: [],
  hotelBundle: null,
  flightBundle: null,
  currentBundle: null,
  flightHotel: [],
  isLoading: false,
  pathRedirect: '/',
  rooms: null,
  allLocationsBundle: [],
  isChangeHotelBundle: false,
  checkCodeBundle: null,
  checkCodeBundleErr: null
}

const ComboReducer = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.SAVE_PATH_REDIRECT:
      return Object.assign({}, state, {
        pathRedirect: action.payload.data
      })
    case ActionTypes.FETCHING_COMBO_START:
      return Object.assign({}, state, {
        isLoading: true
      })
    case ActionTypes.FETCHING_COMBO_STOP:
      return Object.assign({}, state, {
        isLoading: false
      })
    case ActionTypes.CHANGE_RETURN_FLIGHT_SUCCESS:
      return Object.assign({}, state, {
        returnFlight: action.payload.data
      })
    case ActionTypes.CHANGE_DEPARTURE_FLIGHT_SUCCESS:
      return Object.assign({}, state, {
        departureFlight: action.payload.data
      })
    case ActionTypes.CHANGE_FLIGHT_SESSION:
      return Object.assign({}, state, {
        flightSession: action.payload.data
      })
    case ActionTypes.CHANGE_HOTEL_SUCCESS:
      return Object.assign({}, state, {
        hotel: action.payload.data
      })
    case ActionTypes.FETCHING_BUNDLE_LOCATION_SUCCESS:
      return Object.assign({}, state, {
        [action.payload.data.type]: action.payload.data.locations || []
      })
    case ActionTypes.FETCHING_BUNDLE_ALL_LOCATION_SUCCESS:
      return Object.assign({}, state, {
        allLocationsBundle: action.payload.data || []
      })
    case ActionTypes.FETCHING_BUNDLE_SEARCH_SUCCESS:
      return Object.assign({}, state, {
        bundles: action.payload.data.bundleData || [],
        dataSession: action.payload.data.dataSession || null
      })
    case ActionTypes.FETCHING_BUNDLE_HOTEL_SUCCESS: 
      return  Object.assign({}, state, {
        hotelBundle: action.payload.data
      })
    case ActionTypes.FETCHING_BUNDLE_FLIGHT_SUCCESS:
      return  Object.assign({}, state, {
        flightBundle: action.payload.data
      })
    case ActionTypes.SWITCH_POINTS:
      return Object.assign({}, state, {
        departurePoints: state.returnPoints,
        returnPoints: action.payload.data?.departurePointIsDomestic ? state.departurePoints : state.returnPoints
      })
    case ActionTypes.FETCHING_BUNDLE_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        currentBundle: action.payload.data || null,
      })
    case ActionTypes.FETCHING_BUNDLE_BESTRATE_SUCCESS:
      return Object.assign({}, state, {
        flightHotel: action.payload.data
      })
    case ActionTypes.ADD_ROOMS_HOTEL_SUCCESS:
      return Object.assign({}, state, {
        rooms: action.payload.data || {},
      })
    case ActionTypes.RESET_COUNT_TIME_BUNDLE_SUCCESS:
      return Object.assign({}, state, {
        resetCountime: action.payload.data || {},
      }) 
    case ActionTypes.CHANGE_HOTEL_BUNDLE:
      return Object.assign({}, state, {
        isChangeHotelBundle: action.payload.data || {},
      })
    case ActionTypes.CHECK_CODE_BUNDLE:
      return Object.assign({}, state, {
        checkCodeBundle: action.payload.data || {},
      })
    case ActionTypes.CHECK_CODE_BUNDLE_ERROR:
      return {
        ...state,
        checkCodeBundleErr: action.payload.data || {},
      }
    default:
      return state
  }
}

export default ComboReducer