import { COUPON } from '../constants/enumType';
import { getMomentDate } from '../utils/common';

import { getCurrencyCookie } from '../utils/lang'
import { Decimal } from 'decimal.js';

export const EMPTY_COUPON = {
  name: '',
  code: '',
  value: 0,
  type: COUPON.DISCOUNT_TYPE.MONEY,
  startDate: undefined,
  endDate: undefined,
}

export default class Coupon {

  constructor(data = {}) {
    this.setData(data);
  }

  setData(data = {}) {
    this.id = data.id || undefined;
    this.name = data.name || EMPTY_COUPON.name;
    this.code = data.code || EMPTY_COUPON.code;
    this.discountType = data.discountType || EMPTY_COUPON.type;
    this.discountValue = data.discountValue || Number(data.discountValues) || EMPTY_COUPON.value;
    this.maxDiscountValues = data.maxDiscountValues || EMPTY_COUPON.value;
    this.startDate = getMomentDate(data.startDate || EMPTY_COUPON.startDate);
    this.endDate = getMomentDate(data.endDate || EMPTY_COUPON.endDate);
    this.productVariantIds = data.productVariantIds || undefined;;
    this.error = data.error || undefined;
    this.isValid = data.isValid || undefined;
    this.isPromotion = data.isPromotion || false;
    this.productsDiscount = data.productValue || [];
    this.discountMode = data.discountMode || 'ITEM';
    // this.voucherPromotion = data.productType === 'VOUCHER' ? JSON.parse(data.productValue) : [];
    // this.tourPromotion = data.productType === 'TOUR' ? JSON.parse(data.productValue) : [];
    // this.vinWonderPromotion = data.productType === 'VINWONDER' ? JSON.parse(data.productValue) : [];
  }

  idsProductPromotion() {
    // console.log()
  }

  getDiscountPrice(inputPrice, allowLessThanZero = false, exchangeRateList = []) {
    if (inputPrice <= 0 || this.discountValue <= 0) return inputPrice;
    const price = new Decimal(inputPrice);
    let discountPrice;
    if (this.discountType === COUPON.DISCOUNT_TYPE.PERCENT || (this.discountType === COUPON.PROMOTION_DISCONT_TYPE.PERCENT)) {
      discountPrice = price.mul((100 - this.discountValue) / 100);
    } else if (this.discountType === COUPON.DISCOUNT_TYPE.MONEY) {
      const ratioCurrency = exchangeRateList.find(item => item.targetCurrency === getCurrencyCookie())
      discountPrice = ratioCurrency && ratioCurrency.ratio ? price.sub((this.discountValue * ratioCurrency.ratio).toFixed(2)) : price.sub(this.discountValue)
    }
    return Number(discountPrice) < 0 && !allowLessThanZero ? 0 : discountPrice;
  }

  getDiscountValue(totalPrice, exchangeRateList) {
    if (totalPrice <= 0 || this.discountValue <= 0) return 0;
    const discountPrice = this.getDiscountPrice(totalPrice, true, exchangeRateList);
    return new Decimal(totalPrice).sub(discountPrice);
  }

  isPercentType() {
    return this.discountType === COUPON.DISCOUNT_TYPE.PERCENT
  }

  calcPriceDiscount(priceDiscount, quantity) {
    if (this.discountType === COUPON.DISCOUNT_TYPE.MONEY || (this.maxDiscountValues > 0 && this.maxDiscountValues <= priceDiscount)) {
      return priceDiscount * quantity
    }
    return priceDiscount
  }

}