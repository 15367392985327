import actionTypes from '../actionTypes'

const initialState = {
  isChangeRoute: true
}

const NavigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_ROUTE:
      return Object.assign({}, state, {
        isChangeRoute: action.payload
      })
    default:
      return state
  }
}

export default NavigationReducer