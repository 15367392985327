import { Modal } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { getRegisterAuth0 } from "src/extensions/querystring";
import { loginModalCallback } from "src/hooks/auth";
import { ReactComponent as EmailSent } from "@assets/figures/email_sent.svg";
import { FormattedMessage } from "react-intl";

const ModalSendSuccess = ({ visible, onCancel = () => {} }) => {
  return (
    <Modal
      className="login-modal-container"
      title={""}
      header={null}
      visible={visible}
      destroyOnClose
      maskClosable={false}
      footer={null}
      onCancel={onCancel}
      centered
      closable={false}
      width={"auto"}
    >
      <div>
        <EmailSent />
        <p className="paragraph-medium text-center mb-0">
          <FormattedMessage
            id="Login.SendEmailSuccess"
            defaultMessage="Email sent"
          />
        </p>
      </div>
    </Modal>
  );
};

export default ModalSendSuccess;
