import actionTypes from '../actionTypes'

const initState = {
  banners: null,
  downloadApp: null
}

const bannerReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_PAGE_BANNER_SUCCESS:
      return Object.assign({}, state, {
        banners: action.payload.data
      })
    case actionTypes.FETCHING_DOWNLOAD_APP_SUCCESS:
      return Object.assign({}, state, {
        downloadApp: action.payload.data
      })
    default:
      return state
  }
}

export default bannerReducer