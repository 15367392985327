import { useDispatch } from 'react-redux'
// lib
import { useCallback, useMemo } from 'react'
// action
import { redirectPath, modalNotify, reloadPath, stopFetchingAction } from '../actions/commonAction'
import { useLocation, useParams } from 'react-router-dom'
import querystring from 'query-string';

/**
 * redirect
 * @returns {[function(*=)]}
 */
export const redirectPage = () => {
  const dispatch = useDispatch()
  const redirectPathCallback = useCallback(
    (path, asPath, options) => dispatch(redirectPath(path, asPath, options)),
    [dispatch]
  )

  return [redirectPathCallback]
}

/**
 * redirect
 * @returns {[function(*=)]}
 */
export const reloadPage = () => {
  const dispatch = useDispatch()
  const reloadPageCallback = useCallback(
    () => dispatch(reloadPath()),
    [dispatch]
  )

  return [reloadPageCallback]
}

export const notifyMessage = () => {
  const dispatch = useDispatch()
  return useCallback(
    (message) => {
      dispatch(modalNotify(message))
    },
    [dispatch]
  )
}

/**
 * redirect
 * @returns {[function(*=)]}
 */
export const stopLoading = () => {
  const dispatch = useDispatch()
  const stopLoadingCallback = useCallback(
    () => dispatch(stopFetchingAction()),
    [dispatch]
  )

  return [stopLoadingCallback]
}

export const useGetQueryString = () => {
  const location = useLocation();
  const params = useParams();
  const search = location?.search || '';

  const queriesObj = useMemo(() => {
    return querystring.parse(search);
  }, [search]);

  const convertObjToStringUrl = useCallback((obj) => {
    return `?${querystring.stringify(obj)}`;
  }, []);

  const replaceURLWithoutReload = useCallback((newUrl = '') => {
    window.history.replaceState(null, '', newUrl)
  }, []);

  const queriesString = querystring.stringify(queriesObj);

  return { queriesObj, queriesString, convertObjToStringUrl, replaceURLWithoutReload, params };
}