import { getConfig } from "@config/";
import { setCookiesLogin } from "@utils/auth";
import Axios from "axios";
import Cookies from "../utils/cookie";
import Urls from "./Urls";

const {
  publicRuntimeConfig: { API_BASE_AUTH_URL }, // Available both client and server side
} = getConfig();
let isRefreshing = false;
let refreshSubscribers = [];

export const axiosCancelToken = Axios.CancelToken.source();

export default class RetryInterceptor {
  axiosInstance;
  _userRepository;

  constructor(axiosInstance) {
    // super();
    this.axiosInstance = axiosInstance;
    this.auth = Cookies.get(Cookies.STORAGE_KEY.AUTH) || { refreshToken: "" };
    this._userRepository = {
      refreshToken: async (refreshToken) =>
        Axios.post(`${API_BASE_AUTH_URL}/${Urls.REFRESH_TOKEN}`, {
          RefreshToken: this.auth?.refreshToken,
        }),
    };
  }

  requestFulfilled = (config) => {
    return config;
  };

  requestReject = (error) => {
    return Promise.reject(error);
  };

  responseFulfilled = (response) => {
    return response;
  };

  responseReject = async (error) => {
    // const { config, response: { status } } = error;
    const originalRequest = error?.config;
    const status = error?.response?.status || 0;
    if (originalRequest?.url?.includes("logout") || !this.auth?.refreshToken
      // || originalRequest?.url?.includes("refresh")
    ) {
      return error;
    }

    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        // this._userRepository
        //   .refreshToken()
        //   .then((resAuth) => {
        //     isRefreshing = false;
        //     setCookiesLogin(resAuth.data, true);
        //     onRefreshed(resAuth?.data?.accessToken);
        //   })
        //   .catch((er) => {
        //     console.log("responseReject ->", er);
        //   });
          try {
          const resAuth = await this._userRepository.refreshToken();
          setCookiesLogin(resAuth.data, true);
          onRefreshed(resAuth?.data?.accessToken);
          } catch (error) {
            // console.log('RetryInterceptor', error);
          }
          finally {
            isRefreshing = false;
          }
      }

      // if (isRefreshing) {
      //   axiosCancelToken.cancel("user cancel");
      // }

      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh(async (token) => {
          // replace the expired token and retry
          originalRequest.headers["Authorization"] = "Bearer " + token;
          resolve(this.axiosInstance(originalRequest));
        });
      });
      return retryOrigReq;
    } else {
      // console.log('isRefreshing', isRefreshing, originalRequest?.url);
      return Promise.reject(error);
    }
  };
}

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (token) => {
  refreshSubscribers.map((cb) => cb(token));
  refreshSubscribers = [];
};
