import { debounce } from 'lodash'
import React, { useCallback } from 'react'
// lib
import { useDispatch } from 'react-redux'
// action
import flightAction from '../actions/flightAction'

/**
 * load flight
 */
export const loadFlight = () => {
  const dispatch = useDispatch()
  const loadFlightCallback = useCallback(
    obj => dispatch(flightAction.getFlight(obj)),
    [dispatch]
  )
  return [loadFlightCallback]
}

/**
 * load flight detail
 * @returns {[function(*=)]}
 */
export const loadFlightDetail = () => {
  const dispatch = useDispatch()
  const loadFlightDetailCallback = useCallback(
    (obj) => dispatch(flightAction.getFlightDetail(obj)),
    [dispatch]
  )

  return [loadFlightDetailCallback]
}

/**
 * load flight detail
 * @returns {[function(*=)]}
 */
export const verifyFlightDetail = () => {
  const dispatch = useDispatch()
  const verifyFlightDetailCallback = useCallback(
    async (obj, loadingOnSuccess) => dispatch(flightAction.verifyFlightDetail(obj, loadingOnSuccess)),
    [dispatch]
  )

  return [verifyFlightDetailCallback]
}

/**
 * load baggage
 * @returns {[function(*=)]}
 */
export const loadBaggage = () => {
  const dispatch = useDispatch()
  const loadBaggageCallback = useCallback(
    (obj) => dispatch(flightAction.getBaggageInfo(obj)),
    [dispatch]
  )
  return [loadBaggageCallback]
}

/**
 * post dom reservation
 * @returns {[function(*=)]}
 */
export const postDomReservation = () => {
  const dispatch = useDispatch()
  const postDomReservationCallback = useCallback(
    debounce((data, callbacks) => dispatch(flightAction.postDomReservation(data, callbacks)), 500),
    [dispatch]
  )

  return [postDomReservationCallback]
}

/**
 * load order detail
 * @returns {[function(*=)]}
 */
export const loadOrderDetail = () => {
  const dispatch = useDispatch()
  const getOrderDetailCallback = useCallback(
    (orderId) => dispatch(flightAction.getOrderDetail(orderId)),
    [dispatch]
  )

  return [getOrderDetailCallback]
}

/**
 * change departure flight
 * @returns {[*]}
 */
export const changeDepartureFlight = () => {
  const dispatch = useDispatch()

  const changeDepartureFlightCallback = useCallback(
    (flight) => dispatch(flightAction.changeDepartureFlight(flight)),
    [dispatch]
  )

  return [changeDepartureFlightCallback]
}

/**
 * change return flight
 * @returns {[*]}
 */
export const changeReturnFlight = () => {
  const dispatch = useDispatch()
  const changeReturnFlightCallback = useCallback(
    (flight) => dispatch(flightAction.changeReturnFlight(flight)),
    [dispatch]
  )

  return [changeReturnFlightCallback]
}


export const resetFlight = () => {
  const dispatch = useDispatch()
  const changeResetFlightCallback = useCallback(
    (actionType) => dispatch(flightAction.resetFlight(actionType)),
    [dispatch]
  )

  return [changeResetFlightCallback]
}

/**
 * load flight
 */
export const loadFlightWeekPrices = () => {
  const dispatch = useDispatch()
  const loadFlightWeekPricesCallback = useCallback(
    obj => dispatch(flightAction.getFlightWeekPrices(obj)),
    [dispatch]
  )
  return [loadFlightWeekPricesCallback]
}

export const loadFlightMonth = () => {
  const dispatch = useDispatch()
  const loadFlightMonthCallback = useCallback(
    obj => dispatch(flightAction.getFlightMonth(obj)),
    [dispatch]
  )
  return [loadFlightMonthCallback]
}

export const getFareRule = () => {
  const dispatch = useDispatch()
  const getFareRuleCallback = useCallback(
    obj => dispatch(flightAction.getFareRule(obj)),
    [dispatch]
  )
  return [getFareRuleCallback]
}

export default {
  loadFlight,
  loadFlightDetail,
  loadBaggage,
  postDomReservation,
  loadOrderDetail,
  changeDepartureFlight,
  changeReturnFlight,
  resetFlight
}
