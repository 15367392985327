import { enumType, validateError } from "../constants"
import ApiResponse from '../model/ApiResponse';
import { FormattedMessage } from 'react-intl.macro'
import React from "react";

export default {
  getApiMessageError(error, force = false, params) {
    try {
      const errorCode = error ? error.data.errors[0].code : null
      const messageDetail = error ? error.data.errors[0].message : ''
      let message = null
      let title = null
      switch (errorCode) {
        case enumType.apiErrorCode.SESSION_TIMEOUT:
          message = 'Session Timeout'
          break
        case enumType.apiErrorCode.INVALID_PASSENGER:
          message = 'Invalid passenger count'
          break
        case enumType.apiErrorCode.INVALID_ADULT:
          message = 'Invalid adult count'
          break
        case enumType.apiErrorCode.INVALID_CHILD:
          message = 'Invalid children count'
          break
        case enumType.apiErrorCode.INVALID_INF:
          message = 'Invalid infant count'
          break
        case enumType.apiErrorCode.INVALID_START_POINT:
          message = 'Invalid start point'
          break
        case enumType.apiErrorCode.INVALID_END_POINT:
          message = 'Invalid end point'
          break
        case enumType.apiErrorCode.INVALID_ITINERARY:
          message = 'Invalid itinerary'
          break
        case enumType.apiErrorCode.INVALID_ITINERARY_TYPE:
          message = 'Invalid itinerary type'
          break
        case enumType.apiErrorCode.INVALID_DEPARTURE_DATE:
          message = 'Invalid departure date'
          break
        case enumType.apiErrorCode.INVALID_RETURN_DATE:
          message = 'Invalid return date'
          break
        case enumType.apiErrorCode.AIRPORT_NOT_FOUND:
          message = 'Airport code is not found'
          break
        case enumType.apiErrorCode.FLIGHT_NOT_FOUND:
          message = <FormattedMessage
            id="Validate.Modal.FlightNotFound"
            defaultMessage="Không có chuyến bay phù hợp với địa điểm hiện tại, vui lòng tìm kiếm lại"
          />
          break
        case enumType.apiErrorCode.PROMOTION_SIGNIN_REQUIRED:
          message = <FormattedMessage
            id="Modal.ErrorModal.Login"
            defaultMessage="Vui lòng đăng nhập để sử dụng mã giảm giá"
          />
          break
        case enumType.apiErrorCode.ID_USER_NOT_FOUND:
          message = <FormattedMessage
            id="Validate.Modal.AccountDoesNotExist"
            defaultMessage="Tài khoản không tồn tại"
          />
          break
        case enumType.apiErrorCode.ID_USER_NOT_FOUND_OR_LOCKED:
          message = <FormattedMessage
            id="Validate.Modal.AccountIsLocked"
            defaultMessage="Tài khoản không tồn tại hoặc bị khoá"
          />
          break
        case enumType.apiErrorCode.ID_USER_LOCKED:
          message = <FormattedMessage
            id="Validate.Modal.AccountLocked"
            defaultMessage="Tài khoản bị khoá"
          />
          break
        case enumType.apiErrorCode.ID_USER_LOGIN_NOT_ALLOW:
          message = <FormattedMessage
            id="Validate.Modal.NoLoginAllowed"
            defaultMessage="Không cho phép đăng nhập"
          />
          break
        case enumType.apiErrorCode.ID_USER_LOCK_WARNING:
          message = <FormattedMessage
            id="Validate.Modal.AccountWillBeLocked"
            defaultMessage="Tài khoản sẽ bị khóa sau quá nhiều lần thử thất bại"
          />
          break
        case enumType.apiErrorCode.ID_USER_EMAIL_NOT_CONFIRM:
          message = <FormattedMessage
            id="Validate.Modal.AccountNotActivated"
            defaultMessage="Tài khoản chưa kích hoạt qua email"
          />
          break
        case enumType.apiErrorCode.UNCONFIRMED_EMAIL: {
          message = <FormattedMessage
            id="Validate.Modal.UnconfirmEmail"
            defaultMessage="Quý khách vui lòng xác thực Email ({email}) để đăng nhập được vào hệ thống."
            values={params}
          />
          break
        }
        case enumType.apiErrorCode.ID_TOKEN_EXPIRED:
          message = <FormattedMessage
            id="Validate.Modal.LoginSessionExpired"
            defaultMessage="Phiên đăng nhập hết hạn"
          />
          break
        case enumType.apiErrorCode.ID_USER_EXISTED:
          message = <FormattedMessage
            id="Validate.Modal.RegistrationEmailAlreadyExists"
            defaultMessage="Email đăng ký đã tồn tại"
          />
          break
        case enumType.apiErrorCode.PROMOTION_INVALID:
          message = <FormattedMessage
            id="Validate.Modal.PromotionInvalid"
            defaultMessage="Mã không hợp lệ hoặc đã hết hạn"
          />
          break
        case enumType.apiErrorCode.INVALID_LENGTH_OF_STAY:
          message = <FormattedMessage
            id="Validate.Modal.PromotionInvalid"
            defaultMessage="Mã không hợp lệ hoặc đã hết hạn"
          />
          break
        case enumType.apiErrorCode.ID_WRONG_RESET_CODE:
          message = <FormattedMessage
            id="Validate.Modal.ThisLinkHasExpired"
            defaultMessage="Liên kết này đã hết hạn hoặc không khả dụng, vui lòng kiểm tra lại"
          />
          break
        case enumType.apiErrorCode.ID_WRONG_OLD_PASSWORD:
          message = <FormattedMessage
            id="Validate.Modal.WrongOldPassword"
            defaultMessage="Sai mật khẩu cũ"
          />
          break
        case enumType.apiErrorCode.WRONG_PASSWORD:
          message = <FormattedMessage
            id="Validate.Modal.WrongPassword"
            defaultMessage="Mật khẩu của bạn không đúng. Vui lòng thử lại"
          />
          break
        case enumType.apiErrorCode.ID_TOKEN_INVALID:
          title = "Invalid token";
          message = <FormattedMessage
            id="Validate.Modal.TheVerificationCodeIsIncorrect"
            defaultMessage="Mã kiểm tra không đúng hoặc hết hạn"
          />
          break
        case enumType.apiErrorCode.INVALID_TOKEN:
          title = "Invalid token";
          message = <FormattedMessage
            id="Validate.Modal.InvalidToken"
            defaultMessage="Token đã hết hạn.Vui lòng thử lại"
          />
          break
        case enumType.apiErrorCode.UNAUTHORIZED:
          message = <FormattedMessage
            id="Validate.Modal.Unauthorized"
            defaultMessage="Truy cập bị lỗi. Vui lòng thử lại"
          />
          break
        case enumType.apiErrorCode.SAME_PASSWORD:
          message = <FormattedMessage
            id="Validate.Modal.SamePassword"
            defaultMessage="Mật khẩu này đã được sử dụng trước đó. Vui lòng đổi lại mật khẩu"
          />
          break
        case enumType.apiErrorCode.INVALID_PASSWORD:
          message = <FormattedMessage
            id="Validate.Modal.InvalidPassword"
            defaultMessage="Mật khẩu không đủ tiêu chuẩn. Vui lòng kiểm tra lại (gồm 8 Ký tự hoặc nhiều hơn, Chữ in & chữ in thường, Ít nhất một số và ký tự đặ biệt)"
          />
          break

        case enumType.apiErrorCode.QUANTITY_LIMIT:
          title = <FormattedMessage
            id="Validate.Modal.NumberOfTickets"
            defaultMessage="Số lượng vé"
          />;
          // message = messageDetail.replace("Quantity limit", `{<FormattedMessage
          //   id="Validate.Modal.TheNumberOfTicketsInExcess"
          //   defaultMessage= "Số lượng vé đặt quá số vé hiện đang còn "
          // />}`)
          message = <FormattedMessage
            id="Validate.Modal.ThisVoucherIsNotSufficient"
            defaultMessage="Đã mua quá số lượng tối đa cho phép"
          />
          break
        case enumType.apiErrorCode.TOUR_QUANTITY_NOT_ENOUGH:
          title = <FormattedMessage
            id="Validate.Modal.NumberOfTicketsRemaining"
            defaultMessage="Số lượng vé còn lại"
          />;
          message = <FormattedMessage
            id="Validate.Modal.ThisVoucherIsNotSufficient"
            defaultMessage="Đã mua quá số lượng tối đa cho phép"
          />
          break
        case enumType.apiErrorCode.TOUR_EXPIRED:
          title = 'Tour hết hạn'
          message = 'Tour này đã hết hạn'
          title = <FormattedMessage
            id="Validate.Modal.TourExpires"
            defaultMessage="Tour hết hạn"
          />;
          message = <FormattedMessage
            id="Validate.Modal.ThisTourHasExpired"
            defaultMessage="Tour này đã hết hạn"
          />;
          break
        case enumType.apiErrorCode.VOUCHER_WAS_EXPIRED:
          title = 'Voucher hết hạn'
          message = 'Voucher này đã hết hạn'
          title = <FormattedMessage
            id="Validate.Modal.VoucherExpires"
            defaultMessage="Voucher hết hạn"
          />;
          message = <FormattedMessage
            id="Validate.Modal.ThisVoucherHasExpired"
            defaultMessage="Voucher này đã hết hạn"
          />;
          break

        case enumType.apiErrorCode.VOUCHER_LOCKED:
          title = 'Voucher bị khóa'
          message = 'Voucher này đã bị khóa'
          title = <FormattedMessage
            id="Validate.Modal.VoucherIsLocked"
            defaultMessage="Voucher bị khóa"
          />;
          message = <FormattedMessage
            id="Validate.Modal.ThisVoucherIsLocked"
            defaultMessage="Voucher này đã bị khóa"
          />;
          break

        case enumType.apiErrorCode.NOT_ENOUGH_VOUCHER:
          title = <FormattedMessage
            id="Validate.Modal.NotEnoughVoucher"
            defaultMessage="Không đủ Voucher"
          />;
          message = <FormattedMessage
            id="Validate.Modal.ThisVoucherIsNotSufficient"
            defaultMessage="Đã mua quá số lượng tối đa cho phép"
          />;
          break

        case enumType.apiErrorCode.VOUCHER_QUANTITY_NOT_ENOUGH:
          title = <FormattedMessage
            id="Validate.Modal.NotEnoughVoucher"
            defaultMessage="Không đủ Voucher"
          />;
          message = <FormattedMessage
            id="Validate.Modal.ThisVoucherIsNotSufficient"
            defaultMessage="Đã mua quá số lượng tối đa cho phép"
          />;
          break
        case enumType.apiErrorCode.COUPON_NOT_FOUND:
        // case enumType.apiErrorCode.CUTOFF_DATE_VALIDATE_FAIL:
        case enumType.apiErrorCode.INVALID_SCHEDULE:
        case enumType.apiErrorCode.OUT_OF_FREE_NINGHT_STAY:
        case enumType.apiErrorCode.TIME_FOR_USEDATE_FAILED:
        case enumType.apiErrorCode.HAVE_NOT_PROMOTIONAL_ITEM:
        case enumType.apiErrorCode.OUT_OF_USE:
        case enumType.apiErrorCode.HAS_ITEM_NOT_BE_APPLIED:
        case enumType.apiErrorCode.PROMOTION_NOT_APPLIED_FOR_USER:
        case enumType.apiErrorCode.PROMOTION_EXPIRED:
        case enumType.apiErrorCode.PROMOTION_USED:
        case enumType.apiErrorCode.APPLY_CODE_FAIL:
        case enumType.apiErrorCode.PROMOTION_INVALID_CONDITION:
        case enumType.apiErrorCode.PROMOTION_CODE_IS_INVALID:
        case enumType.apiErrorCode.PROMOTION_CODE_NOT_EXISTED:
        case enumType.apiErrorCode.INVALID_CHANNEL:
          title = <FormattedMessage
            id="Validate.Modal.NoCodeFound"
            defaultMessage="Mã không hợp lệ hoặc đã hết hạn"
          />;
          message = <FormattedMessage
            id="Validate.Modal.PROMOTION_CODE_NOT_FOUND"
            defaultMessage='Mã khuyến mại {promotion} không tồn tại, đã hết hạn hoặc đã hết lượt dùng'
            values={{
              promotion: params?.promotionCode || ''
            }}
          />;
          break
        case enumType.apiErrorCode.LOYALTY_NOT_ENOUGH_QUANTITY:
          title = <FormattedMessage
            id="Validate.Modal.LoyaltyNotEnoughQuantity"
            defaultMessage="Số lượng sản phẩm bạn đặt đã vượt quá số lượng ưu đãi có thể được sử dụng"
          />;
          break
        case enumType.apiErrorCode.PROMOTION_NOT_ENOUGH_QUANTITY:
          title = <FormattedMessage
            id="Validate.Modal.PromotionNotEnoughQuantity"
            defaultMessage="Số lượng sản phẩm bạn đặt đã vượt quá số lượng cho phép"
          />;
          // message = <FormattedMessage
          //   id="Validate.Modal.NoDiscountCode"
          //   defaultMessage="Không tìm thấy mã giảm giá này trên hệ thống"
          // />;
          break
        case enumType.apiErrorCode.COUPON_NOT_STARTED:
          title = <FormattedMessage
            id="Validate.Modal.NotUsedYet"
            defaultMessage="Chưa sử dụng được"
          />;
          message = <FormattedMessage
            id="Validate.Modal.DiscountCodeHasNotYet"
            defaultMessage="Mã giảm giá chưa tới thời điểm sử dụng"
          />;
          break;
        case enumType.apiErrorCode.COUPON_EXPIRED:
          title = <FormattedMessage
            id="Validate.Modal.Expired"
            defaultMessage="Hết hạn"
          />;
          message = <FormattedMessage
            id="Validate.Modal.DiscountCodeExpires"
            defaultMessage="Mã giảm giá hết hạn sử dụng"
          />;
          break;
        case enumType.apiErrorCode.COUPON_NOT_APPLY_TO_PRODUCT:
          title = <FormattedMessage
            id="Validate.Modal.NotApplicable"
            defaultMessage="Không thể áp dụng"
          />;
          message = <FormattedMessage
            id="Validate.Modal.CodeNotApplicable"
            defaultMessage="Mã không thể áp dụng cho sản phẩm trong đơn"
          />;
          break;
        case enumType.apiErrorCode.COUPON_USED_UP:
          title = <FormattedMessage
            id="Validate.Modal.NotApplicable"
            defaultMessage="Không thể áp dụng"
          />;
          message = <FormattedMessage
            id="Validate.Modal.CodeUsed"
            defaultMessage="Mã đã sử dụng hoặc hết số lần sử dụng"
          />;
          break;
        case enumType.apiErrorCode.ITEM_NOT_APPLY_INSTALLMENT:
          message = <FormattedMessage
            id="PaymentMethod.Installment.Error.NotApplyToInstallment"
            defaultMessage='Sản phẩm trong giỏ hàng không áp dụng trả góp'
          />;
          break;
        case enumType.apiErrorCode.ONLY_APPLY_INSTALLMENT_FOR_ONE_PRODUCT:
          message = <FormattedMessage
            id="PaymentMethod.Installment.Error.OnlyApplyToInstallentOneProduct"
            defaultMessage='Mỗi đơn hàng chỉ áp dụng trả góp cho 1 loại sản phẩm'
          />;
          break;
        case enumType.apiErrorCode.MIN_INSTALLMENT_ORDER_VALUE:
          message = <FormattedMessage
            id="PaymentMethod.Installment.Error.TotalPriceNotReachedMinimum"
            defaultMessage='Sản phẩm trong giỏ hàng chưa đạt giá trị tối thiểu áp dụng trả góp'
          />;
          break;
        case enumType.apiErrorCode.HAVENOT_ROOM:
          message = <FormattedMessage
            id="Combo.Hotel.HaveNotRoomAvailable"
            defaultMessage='Xin lỗi chúng tôi không còn phòng trống'
          />;
          break;
        case enumType.apiErrorCode.NOT_ALLOW_TO_APPLY_PROMOTION_AND_INSTALLMEMT:
          title = <FormattedMessage
            id="Validate.Modal.NotApplicable"
            defaultMessage="Không thể áp dụng"
          />;
          message = <FormattedMessage
            id="PaymentMethod.Installment.Error.NotAllowToApplyInstallmentWithPromotion"
            defaultMessage='Không áp dụng thanh toán trả góp khi sử dụng mã khuyến mãi'
          />;
          break;
        case enumType.apiErrorCode.QUANTITY_MUST_BE_MULTIPLE_OF_TWO:
          message = <FormattedMessage
            id="Validate.Modal.TourQuantityMustMultipleOfTwo"
            defaultMessage='Số lượng vé người lớn phải là bội số của 2'
          />;
          break;
        case enumType.apiErrorCode.CART_ITEM_NOT_FOUND:
          message = <FormattedMessage
            id="Cart.Item.Message.NotFound"
            defaultMessage='Sản phẩm trong giỏ hàng không hợp lệ hoặc không tìm thấy'
          />;
          break;
        case enumType.apiErrorCode.CREATE_PHS_ERROR:
            message = <FormattedMessage
              id="Validate.Modal.CreatePHSError"
              defaultMessage='Số lượng phòng Quý khách chọn vượt quá số phòng hiện tại'
            />;
          break;
        case enumType.apiErrorCode.PHS_SESSION_EXPIRED:
            message = <FormattedMessage
              id="Validate.Modal.PHSSessionExpired"
              defaultMessage='Số lượng phòng Quý khách chọn vượt quá số phòng hiện tại'
            />;
          break;
          case enumType.apiErrorCode.INVALID_MINIMUM_ORDER_AMOUNT:
            message = <FormattedMessage
              id="Cart.CouponLoyalty.MinimumAmount"
              defaultMessage='Không đủ giá trị tối thiểu để đặt hàng'
            />;
            break;
        case enumType.apiErrorCode.ITEM_DISABLE_SELL:
          message = <FormattedMessage
            id="Validate.Modal.SKUItemDisableSell"
            defaultMessage='Giỏ hàng của bạn có sản phẩm không tồn tại hoặc đã hết hạn. Vui lòng bỏ sản phẩm không tồn tại khỏi giỏ hàng để tiếp tục thanh toán.'
          />;
          break;
        case enumType.apiErrorCode.INVALID_QUANTITY:
          message = <FormattedMessage
            id="Validate.Modal.SKUInvalidQuantityMinMax"
            defaultMessage='Vui lòng kiểm tra điều kiện số lượng hợp lệ của các sản phẩm trong giỏ hàng.'
          />;
          break;
        case enumType.apiErrorCode.PROMOTION_CODE_NOT_FOUND:
          title = <FormattedMessage
            id="Validate.Modal.NoCodeFound"
            defaultMessage="Mã không hợp lệ hoặc đã hết hạn"
          />;
          message = <FormattedMessage
            id="Validate.Modal.PROMOTION_CODE_NOT_FOUND"
            defaultMessage='Mã khuyến mại {promotion} không tồn tại, đã hết hạn hoặc đã hết lượt dùng'
            values={{
              promotion: params?.promotionCode || ''
            }}
          />;
          break;
        case enumType.apiErrorCode.PROMOTION_INVALID_SEARCH_CONDITION:
          message = <FormattedMessage
            id="Validate.Modal.PROMOTION_INVALID_SEARCH_CONDITION"
            defaultMessage='Mã khuyến mại {promotion} không đáp ứng điều kiện khách sạn, thời gian hoặc số phòng, số người đang tìm kiếm'
            values={{
              promotion: params?.promotionCode || ''
            }}
          />;
          break;
        case enumType.apiErrorCode.MKR_PROMOTION_CODE_NOT_MATCH_ANY_MARKUP_CAMPAIGN:
          message = <FormattedMessage
            id="Validate.Modal.MKR_PROMOTION_CODE_NOT_MATCH_ANY_MARKUP_CAMPAIGN"
            defaultMessage='Promotion code not match with any markup campaign'
          />;
          break;
        case enumType.apiErrorCode.MKR_PROMOTION_HOTEL_REQUEST_CONDITIONS_INVALID:
          message = <FormattedMessage
            id="Validate.Modal.MKR_PROMOTION_HOTEL_REQUEST_CONDITIONS_INVALID"
            defaultMessage='Promotion markup hotel request conditions to check invalid'
          />;
          break;
        case enumType.apiErrorCode.MKR_PROMOTION_CHECK_CODE_MISSING_HOTEL_IDS:
          message = <FormattedMessage
            id="Validate.Modal.MKR_PROMOTION_CHECK_CODE_MISSING_HOTEL_IDS"
            defaultMessage='Promotion markup hotel request missing hotel ids'
          />;
          break;
        case enumType.apiErrorCode.MKR_PROMOTION_APPLY_CODE_MISSING_RATEPLAN_IDS:
          message = <FormattedMessage
            id="Validate.Modal.MKR_PROMOTION_APPLY_CODE_MISSING_RATEPLAN_IDS"
            defaultMessage='Promotion markup hotel request missing rateplan ids'
          />;
          break;
        case enumType.apiErrorCode.MKR_PROMOTION_NOT_APPLY_FOR_LIST_HOTEL_REQUEST:
          message = <FormattedMessage
            id="Validate.Modal.MKR_PROMOTION_NOT_APPLY_FOR_LIST_HOTEL_REQUEST"
            defaultMessage='Promotion code not apply for any hotel in request list'
          />;
          break;
        case enumType.apiErrorCode.MKR_PROMOTION_NOT_APPLY_FOR_LIST_RATE_REQUEST:
          message = <FormattedMessage
            id="Validate.Modal.MKR_PROMOTION_NOT_APPLY_FOR_LIST_RATE_REQUEST"
            defaultMessage='Promotion code not apply for any rate in request list'
          />;
          break;
        case enumType.apiErrorCode.LOGIN_FAILED:
          // message = <FormattedMessage
          //   id="Modal.ErrorModal.wrongEmailOrPassword"
          //   defaultMessage='Sai tên đăng nhập hoặc mật khẩu'
          // />;
          message = <div>
            * Sai tên đăng nhập hoặc mật khẩu <br />
            * Wrong email or password.
          </div>;
          break;
        case enumType.apiErrorCode.REQUEST_INVALID:
          message = <div>
            {validateError.wrongUsernameOrPass}
          </div>;
          break;
        case enumType.apiErrorCode.INVALID_EMAIL_OR_PHONE:
          message = <div>
            * {validateError.emailOrPhone}
          </div>
          break
        case enumType.apiErrorCode.ID_GET_PROFILE_FAIL:
          message = <div>
            {validateError.emailOrPhone}
          </div>;
          break;
        case enumType.apiErrorCode.too_many_attempts:
            message = <div>
              * Your account has been blocked after multiple consecutive login attempts. We've sent you an email with instructions on how to unblock it.
            </div>;
            break;
        case enumType.apiErrorCode.invalid_signup:
        case enumType.apiErrorCode.EXISTED_USERNAME:
          message = <div>
            * Tài khoản đã tồn tại. Vui lòng Đăng nhập để sử dụng dịch vụ. <br />
            * Account already exists. Please login to use the service.
          </div>;
          break;
        case enumType.apiErrorCode.invalid_password:
          message = <div>
            * Password is too weak - You should validate the password by using the validation rules before submit to the Auth0
          </div>;
          break;
        case enumType.apiErrorCode.SEND_EMAIL_RESET_PASSWORD_SUCCESS:
          message = <FormattedMessage
            id="Modal.ErrorModal.sendEmailSuccess"
            defaultMessage="We've just sent you an email to reset your password"
          />;
          break;
        case enumType.apiErrorCode.CHECK_PROMOTION_CODE_FAILED:
          message = <FormattedMessage
            id="Validate.Modal.CHECK_PROMOTION_CODE_FAILED"
            defaultMessage='Ưu đãi không đủ điều kiện áp dụng. Vui lòng kiểm tra lại điều kiện sản phẩm!'
          />;
          break;
        case enumType.apiErrorCode.CHECKOUT_DATE_GEATER_THAN_CARD_EXPIRED_DATE:
          message = <FormattedMessage
            id="Validate.Modal.CHECKOUT_DATE_GEATER_THAN_CARD_EXPIRED_DATE"
            defaultMessage='Ngày thanh toán lớn hơn ngày thẻ hết hạn'
          />;
          break;
        case enumType.apiErrorCode.ARRIVAL_DATE_INVALID:
          message = <FormattedMessage
            id="Validate.Modal.ARRIVAL_DATE_INVALID"
            defaultMessage='Ngày đến không hợp lệ'
          />;
          break;
        case enumType.apiErrorCode.FLIGHT_HOLDING_TIMEOUT:
          message = <FormattedMessage
            id="Validate.Modal.FLIGHT_HOLDING_TIMEOUT"
            defaultMessage='Thời gian giữ chỗ đã hết'
          />;
          break;
        case enumType.apiErrorCode.CART_WAS_PAID:
          message = <FormattedMessage
            id="Cart.Item.CART_WAS_PAID"
            defaultMessage='Giỏ hàng đã được thanh toán'
          />;
          break;
        case enumType.apiErrorCode.INVALID_MULTIPLE:
          message = <FormattedMessage
            id="Cart.Item.INVALID_MULTIPLE"
            defaultMessage='INVALID_MULTIPLE'
          />;
          break;
        case enumType.apiErrorCode.CUTOFF_DATE_VALIDATE_FAIL:
          message = <FormattedMessage
            id="Validate.Modal.CUTOFF_DATE_VALIDATE_FAIL"
            defaultMessage='Ngày đặt không hợp lệ. Vui lòng chọn ngày khác'
          />;
          break;
        case enumType.apiErrorCode.ORDER_NOT_MULTIPLE_SITECODE:
          message = <FormattedMessage
            id="Validate.Modal.ORDER_NOT_MULTIPLE_SITECODE"
            defaultMessage='Vui lòng lựa chọn các dịch vụ tại cùng điểm đến để tiến hành thanh toán trong một lần!'
          />;
          break;
        case enumType.apiErrorCode.SALESFORCE_API_UPSERT_ERROR:
          message = <FormattedMessage
            id="Validate.Modal.SALESFORCE_API_UPSERT_ERROR"
            defaultMessage='Cập nhật thông tin lên Loyalty không thành công!'
          />;
          break;
        case enumType.apiErrorCode.ORDER_WAS_OUT_OF_DATE:
          message = <FormattedMessage
            id="Validate.Modal.Cross.Sell.ORDER_WAS_OUT_OF_DATE"
            defaultMessage="Đơn hàng đã hết hạn"
          />
          break
        case enumType.apiErrorCode.ORDER_NOT_PAID_OR_SUCCESS:
          message = <FormattedMessage
            id="Validate.Modal.Cross.Sell.ORDER_NOT_PAID_OR_SUCCESS"
            defaultMessage="Đơn hàng chưa được thanh toán"
          />
          break
        case enumType.apiErrorCode.ORDER_IS_NOT_HOTEL:
          message = <FormattedMessage
            id="Validate.Modal.Cross.Sell.ORDER_IS_NOT_HOTEL"
            defaultMessage="Đơn hàng không phải loại khách sạn"
          />
          break
        case enumType.apiErrorCode.INVALID_PARAMS:
          message = <FormattedMessage
            id="Validate.Modal.Cross.Sell.INVALID_PARAMS"
            defaultMessage="Thông tin phòng không hợp lệ"
          />
          break
        case enumType.apiErrorCode.NOT_FOUND:
          message = <FormattedMessage
            id="Validate.Modal.Cross.Sell.NOT_FOUND"
            defaultMessage="Không tìm thấy thông tin đơn hàng"
          />
          break
        case enumType.apiErrorCode.CANNOT_GET_RESERVATION_INFO:
          message = <FormattedMessage
            id="Validate.Modal.Cross.Sell.CANNOT_GET_RESERVATION_INFO"
            defaultMessage="Không tìm thấy thông tin đặt chỗ"
          />
          break
        case enumType.apiErrorCode.PROMOTION_NOT_ALLOW_LINK_CROSS_SALE:
          message = <FormattedMessage
            id="Validate.Modal.Cross.Sell.PROMOTION_NOT_ALLOW_LINK_CROSS_SALE"
            defaultMessage="Đơn hàng Link CrossSell không được bán kèm khuyến mãi"
          />
          break
        case enumType.apiErrorCode.INVALID_ORDER_ITEM_TYPE:
          message = <FormattedMessage
            id="Validate.Modal.Cross.Sell.INVALID_ORDER_ITEM_TYPE"
            defaultMessage="Đơn hàng không phải loại khách sạn"
          />
          break
        case enumType.apiErrorCode.PRICES_HAD_CHANGED:
          message = <FormattedMessage
            id="Validate.Modal.Cross.Sell.PRICES_HAD_CHANGED"
            defaultMessage="Tổng giá đã thay đổi, vui lòng tìm kiếm lại"
          />
          break
        default:
          message = <FormattedMessage
            id="Validate.Modal.Unknown"
            defaultMessage='Không xác định'
          />
          break;
      }
      return new ApiResponse({
        errorCode,
        message,
        title,
        success: false,
      })
    } catch (e) {
      return force ? new ApiResponse({
        errorCode: enumType.apiErrorCode.UNDEFINED_ERROR,
        message: <FormattedMessage
          id="Validate.Modal.Unknown"
          defaultMessage='Không xác định'
        />,
        title: <FormattedMessage
          id="Validate.Modal.Error!"
          defaultMessage='Đã xảy ra lỗi'
        />,
        success: false,
      }) : null
    }
  }
}
