function getLocationFromUrl(href) {
    var match = href.match(
        /^(https?:|file:|chrome-extension:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/
    );
    return (
        match && {
            href: href,
            protocol: match[1],
            host: match[2],
            hostname: match[3],
            port: match[4],
            pathname: match[5],
            search: match[6],
            hash: match[7]
        }
    );
}

function getOriginFromUrl(url) {
    if (!url) {
        return undefined;
    }
    var parsed = getLocationFromUrl(url);
    if (!parsed) {
        return null;
    }
    var origin = parsed.protocol + '//' + parsed.hostname;
    if (parsed.port) {
        origin += ':' + parsed.port;
    }
    return origin;
}

function redirect(url) {
    getWindow().location = url;
}

function getDocument() {
    return getWindow().document;
}

function getWindow() {
    return window;
}

function getOrigin() {
    var location = getWindow().location;
    var origin = location.origin;

    if (!origin) {
        origin = getOriginFromUrl(location.href);
    }

    return origin;
}

export default {
    redirect: redirect,
    getDocument: getDocument,
    getWindow: getWindow,
    getOrigin: getOrigin
};