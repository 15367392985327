import {axiosProviderCms, axiosProviderVinPearl} from './api/axiosProvider'
//actions
import actionTypes from '../actionTypes'
//utils
//common
import {
  loadSuccess,
  loadFail
} from './commonAction'


export const getPageBanner = () => {
  return async (dispatch) => {
    try {
      const response = await axiosProviderCms.get(`/frontend/banner`)
      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.FETCHING_PAGE_BANNER_SUCCESS,
            response.data.data,
            null,
            null,
            null
          )
        )
      } else {
        dispatch(loadFail(response.errors[0].message))
      }
    } catch (errors) {
      // fail
      dispatch(loadFail(errors.response))
    }
  }
}

export const getDownloadAppBanner = (lang) => {
  return async (dispatch) => {
    try {
      const response = await axiosProviderVinPearl.get(`/${lang}/api/banner`)
      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.FETCHING_DOWNLOAD_APP_SUCCESS,
            response.data
          )
        )
      }
    } catch (errors) {
      // fail
    }
  }
}

export default {
  getPageBanner,
  getDownloadAppBanner
}