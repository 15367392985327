import actionTypes from '../actionTypes'
import {
  loadFail,
  loadSuccess,
} from './commonAction'
import { axiosProviderCms, createAxiosProvider } from './api/axiosProvider'
import IpLocale from '../model/IpLocale'
import { setLanguage, setCurrency } from '../utils/lang';
import { enumType } from '../constants';
import { get } from 'lodash';
import { country } from '../constants/language';

const publicIp = require('public-ip');


export function getSiteInfos(shouldCheck = true) {
  return async (dispatch, getState) => {
    const { siteInfo } = getState();
    try {
      if (shouldCheck && siteInfo && siteInfo.siteInfo
        && siteInfo.siteInfo.shouldUpdate && !siteInfo.siteInfo.shouldUpdate()) {
        return
      }
      const response = await axiosProviderCms.get(`/frontend/siteinfo`)

      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.FETCHING_SITE_INFO_SUCCESS,
            response.data.data
          )
        )
      } else {
        dispatch(loadFail(response.errors[0]))
      }
    } catch (error) {
      console.error(error)
      dispatch(loadFail(error.response))
    }
  }
}

export function getIpLocale() {
  return async (dispatch, getState) => {
    const { siteInfo } = getState()
    const setLocaleInfo = (ipLocale) => {
      const countryLocale = enumType.matchingLocaleData[ipLocale.countryCode];
      if (countryLocale && ipLocale.countryCode === country.Korean) {
        setLanguage(countryLocale.language, true);
        // setCurrency(countryLocale.currency, true);
      }
      dispatch(
        loadSuccess(
          actionTypes.FETCHING_IP_LOCALE_SUCCESS,
          ipLocale
        )
      )
    }
    try {
      const ipaddress = await publicIp.v4();
      const changedIpAddress = ipaddress !== get(siteInfo, 'ipLocale.ipaddress');
      if (!changedIpAddress) {
        return setLocaleInfo(siteInfo.ipLocale)
      }
      const axiosInstance = createAxiosProvider(null, config, 30000);
      const config = { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } }
      const response = await axiosInstance.get('/api/geoip', { params: { ip: ipaddress } });
      if (response.status === 200 && !response.errors) {
        const ipLocale = new IpLocale(response.data)
        // console.log('===== getIpLocale', ipLocale)
        setLocaleInfo(ipLocale)
      } else {
        console.warn('getIpLocale|ERROR:', response.errors)
      }
    } catch (error) {
      console.warn('getIpLocale|ERROR:', error)
    }
  }
}