import { applyMiddleware, createStore, combineReducers } from 'redux'
import { createLogger } from 'redux-logger'
import ReduxThunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import reducers from './reducers'
import ContextStore from './model/ContextStore'

/* ------------- Redux Configuration ------------- */
const middleWare = []

/* ------------- Thunk Middleware ------------- */
middleWare.push(ReduxThunk)

/* ------------- Logger Middleware ------------- */
// const loggerMiddleware = createLogger({
//   level: 'info',
//   collapsed: true
// })
// middleWare.push(loggerMiddleware)

/* ------------- Combine Reducers ------------- */
const combineAllReducers = combineReducers(reducers)

/* ------------- Compose Enhancer ------------- */
const enhancer = composeWithDevTools(applyMiddleware(...middleWare))

/* ------------- Redux Persist Configuration ------------- */
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['fetching', 'flight', 'filter', 'modal', 'carts', 'auth', 'hotel', 'combo', 'credit'],
  whitelist: ['cart', 'search', 'siteInfo']
}

/* ------------- Persisted Reducer ------------- */
const persistedReducer = persistReducer(persistConfig, combineAllReducers)

/* ------------- Create Store ------------- */
const createConfigStore = (reducer, initialState, enhancer) =>
  createStore(persistedReducer, initialState, enhancer)

/* ------------- Initialize Store ------------- */
// export function initializeStore (initialState = {}) {
//   return createStore(
//     persistedReducer,
//     initialState,
//     composeWithDevTools(applyMiddleware())
//   )
// }

export const reduxStore = new ContextStore();

export function initializeStore(initialState = {}) {
  return createConfigStore(combineAllReducers, initialState, enhancer)
}
