// import { CONTACT_INFO } from "./../constants"

const socialNetworks = [
  {
    title: 'Facebook',
    icon: 'img/icons/facebook.png',
    url: 'facebookFanpage',
    iconName: 'fa fa-facebook-f',
    footerPosition: 1,
  },
  {
    title: 'Youtube',
    icon: '',
    url: 'youtubeChannel',
    iconName: 'fa fa-youtube',
    footerPosition: 2
  }
]

export default socialNetworks
