import React, { Fragment } from 'react'
import clsx from 'clsx'
import Images from '../../constants/images'
import { Spin } from 'antd'
import { omit } from 'lodash'
import LazyImage from '../../vendor/lazy-image/LazyImage'

class Image extends React.Component {

  state = {
    loading: true,
    src: this.props.src,
    error: undefined,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.setState({ src: this.props.src })
    }
  }

  render() {
    const { loading, src, error } = this.state
    const newProps = omit(this.props, ['alt', 'errorStyle', 'style'])
    return (
      <Fragment>
        <LazyImage
          src={src}
          alt={!loading ? this.props.alt : undefined}
          className={
            clsx({
              [`${this.props.className}`]: !!this.props.className,
              'd-none': !!error
            })
          }
          onError={this.onLoadError}
          onLoad={this.onLoaded}
        />
        {!loading && error
          ? (
            <div
              style={{
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center', ...this.props.errorStyle
              }}
            >
              <LazyImage
                className={
                  clsx({
                    [`${this.props.className}`]: !!this.props.className
                  })
                }
                onError={this.onLoadError}
                onLoad={this.onLoaded}
                // alt={this.props.alt}
                src={Images.default_image}
              />
            </div>
          )
          : null
        }
        {loading && !error
          ? <div
            className="g-pos-abs g-top-0 g-left-0 text-center w-100 h-100 d-flex justify-content-center align-items-center">
            <Spin />
          </div>
          : null
        }
      </Fragment>
    )
  }

  onLoadError = (event) => {
    this.setState({ loading: false, error: true })
  }

  onLoaded = (event) => {
    this.setState({ loading: false })
  }
}

export default Image