/**
 * Select function
 */

import { gtmEvent } from "src/helpers/gtmEvent";
import moment from "moment";
import { FORMAT_ONLY_DATE } from "src/constants";
import { hotelUtils } from "..";
import { sum } from "lodash";

export const createGtmSelectBookingHotel = ({ hotelName }) => {
  return { event: gtmEvent.SELECT_BOOKING_HOTEL, hotel_name: hotelName };
};

export const createGtmSelectRoomRateHotel = ({ dataSelect }) => {
  const { rate = {}, roomtype = {}, urlParam = {} } = dataSelect;
  return {
    event: gtmEvent.SELECT_ROOM_RATE,
    room_rate: rate?.ratePlan?.description,
    hotel_name: urlParam.hsp,
    room_name: roomtype.name,
  };
};
