import { language } from '../constants/language'
import { useContext } from 'react'
import GlobalContext, { globalStore } from '../hooks/useGlobal'
import { currencyUnit } from '../constants/currencyUnit'
import Cookie from './cookie'

export const getCurrentLanguage = (skipDefault = false) => {
  const context = useContext(GlobalContext)

  if (globalStore.state && globalStore.state.lang) {
    return globalStore.state.lang
  } else if (context.lang) {
    return context.lang
  }
  return !skipDefault ? language.Vietnamese : undefined
}

export const getLanguageCookie = (skipDefault = false) => {
  return Cookie.get(Cookie.STORAGE_KEY.LANGUAGE) ? Cookie.get(Cookie.STORAGE_KEY.LANGUAGE) : !skipDefault ? language.Vietnamese : undefined
}

export const setLanguageCookie = (lang, checkBefore = false) => {
  if (checkBefore && lang === Cookie.get(Cookie.STORAGE_KEY.LANGUAGE)) {
    return
  }
  Cookie.set(Cookie.STORAGE_KEY.LANGUAGE, lang)
}

export const setLanguage = (lang, checkBefore = false) => {
  setLanguageCookie(lang, checkBefore);
  globalStore.dispatch({ type: 'lang', data: lang })
}

export const getCurrencyCookie = (skipDefault = false) => {
  return Cookie.get(Cookie.STORAGE_KEY.CURRENCY) ? Cookie.get(Cookie.STORAGE_KEY.CURRENCY) : !skipDefault ? currencyUnit.VietnameseDong : undefined
}

export const getCurrentCurrency = (skipDefault = false) => {
  const context = useContext(GlobalContext)

  if (globalStore.state && globalStore.state.currency) {
    return globalStore.state.currency
  } else if (context.currency) {
    return context.currency
  }
  return !skipDefault ? currencyUnit.VietnameseDong : undefined
}

export const setCurrencyCookie = (currency, checkBefore = false) => {
  if (checkBefore && currency === Cookie.get(Cookie.STORAGE_KEY.CURRENCY)) {
    return
  }
  Cookie.set(Cookie.STORAGE_KEY.CURRENCY, currency)
}

export const setCurrency = (currency, checkBefore = false) => {
  setCurrencyCookie(currency, checkBefore);
  globalStore.dispatch({ type: 'currency', data: currency })
}

export const getCurrencyOnlCookie = (skipDefault = false) => {
  return Cookie.get(Cookie.STORAGE_KEY.CURRENCYONL) ? Cookie.get(Cookie.STORAGE_KEY.CURRENCYONL) : !skipDefault ? currencyUnit.VietnameseDong : undefined
}

export const getCurrentCurrencyOnl = (skipDefault = false) => {
  const context = useContext(GlobalContext)
  if (globalStore.state && globalStore.state.currencyOnl) {
    return globalStore.state.currencyOnl
  } else if (context.currencyOnl) {
    return context.currencyOnl
  }
  return !skipDefault ? currencyUnit.VietnameseDong : undefined
}

export const setCurrencyOnlCookie = (currency, checkBefore = false) => {
  if (checkBefore && currency === Cookie.get(Cookie.STORAGE_KEY.CURRENCYONL)) {
    return
  }
  Cookie.set(Cookie.STORAGE_KEY.CURRENCYONL, currency)
}

export const setCurrencyOnl = (currency, checkBefore = false) => {
  setCurrencyOnlCookie(currency, checkBefore);
  globalStore.dispatch({ type: 'currencyOnl', data: currency })
}

export const getAcceptLanguage = (lang = globalStore.state.lang) => {
  if (!lang) {
    lang = getLanguageCookie()
  }
  switch (lang) {
    case language.Vietnamese:
      return 'vi-VN'
    case language.English:
      return 'en-US'
    case language.Korean:
      return 'ko-KR'
    case language.Chinese:
      return 'zh-CN'
    default:
      return 'vi-VN'
  }
}

export const getDisplayCurrency = (currency = globalStore.state.currency) => {
  if (!currency) {
    currency = getCurrencyCookie()
  }
  switch (currency) {
    case currencyUnit.VietnameseDong:
      return 'VND'
    case currencyUnit.Dollar:
      return 'USD'
    case currencyUnit.Won:
      return 'KRW'
    default:
      return 'VND'
  }
}