import { useState, useEffect } from 'react'
import { useWindowSize, useWindowScroll, useDebounce } from 'react-use';
import { useUserAgent } from 'next-useragent'
import isMobileDetect from 'ismobilejs';
import { screenWidth } from '../utils/common';
import { globalStore } from './useGlobal';

const isServer = typeof window === 'undefined'

export const useScreenSize = (debounceTimeout = 300) => {
  const { width } = useWindowSize();

  const getScreenSize = (width) => {
    return {
      isSmall: screenWidth.isSmall(width) || false,
      isMedium: screenWidth.isMedium(width) || false,
      isLarge: screenWidth.isLarge(width) || false,
      isXLarge: screenWidth.isXLarge(width) || false,
    }
  }
  const [screenSize, setScreenSize] = useState(getScreenSize(width))

  const setDeviceScreenSize = (width) => {
    setScreenSize(getScreenSize(width))
  }

  useDebounce(() => {
    setDeviceScreenSize(width);
  }, debounceTimeout, [width]);

  return screenSize
}

export const useDeviceInfo = () => {
  const userAgent = isServer ? globalStore.state.userAgent : window.navigator.userAgent
  const { isLarge } = useScreenSize();
  const userAgentData = useUserAgent(userAgent)
  const deviceDetected = isMobileDetect(userAgent)
  const isMobile = deviceDetected.phone || deviceDetected.tablet;
  const isMobileMedium = isMobile && !isLarge;

  return {
    isMobile,
    isTablet: deviceDetected.tablet,
    isMobileOnly: deviceDetected.phone && !deviceDetected.tablet,
    isDesktop: userAgentData.isDesktop,
    isMobileMedium,
    deviceDetected,
    deviceInfo: userAgentData,
    isAppleDevice: deviceDetected.apple.device
  }
}

export const useDebounceWindowScroll = (debounceTimeout = 600) => {
  const windowScroll = useWindowScroll();
  const [returnScroll, setReturnScroll] = useState(windowScroll)

  const setScroll = debounce(() => setReturnScroll(windowScroll), debounceTimeout);

  useEffect(() => {
    setScroll();
  }, [windowScroll]);

  return returnScroll
}