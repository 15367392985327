import React from 'react'
import actionTypes from '../actionTypes'
import {
  loadFail,
  startFetchingAction,
  stopFetchingAction,
} from './commonAction'
import { axiosProviderOrder } from './api/axiosProvider'
import { getAuthCookies } from '../utils/auth'

export default {
  cancelPendingPayment(callback) {
    return async (dispatch) => {
      try {
        dispatch(startFetchingAction())
        const authInfo = getAuthCookies()
        if (authInfo && authInfo.auth && authInfo.auth.accessToken) {
          const config = {
            headers: {
              Authorization: `Bearer ${authInfo.auth.accessToken}`,
            }
          }

          const response = await axiosProviderOrder.get(`/frontend/order/cancel-payment`, config)

          if (response.status === 200 && !response.errors) {

          } else {
            dispatch(loadFail(response.errors[0]))
          }
        }
      } catch (error) {
        dispatch(loadFail(error.response, undefined, undefined, { isReload: false }))
      } finally {
        dispatch(stopFetchingAction())
        if (callback) callback()
      }
    }
  },

  getCreditPointCalculation(totalAmount, callback) {
    return async (dispatch, getState) => {
      const { credit = {} } = getState();
      if (!!credit.isFetchingCreditPointCalculation) return
      dispatch(this.startFetchingCreditPointCalculation())
      try {
        const response = await axiosProviderOrder.get(`/frontend/order/credit-point?totalAmount=${totalAmount}`)

        if (response.status === 200 && !response.errors) {
          dispatch && dispatch({
            type: actionTypes.SET_CREDIT_POINT_CALCULATION_DATA,
            payload: { data: response.data}
          })
          callback && callback(response.data)
        } else {
          dispatch(loadFail(response.errors[0]))
        }

        // console.log("getCreditPointCalculation ===>", response)
      } catch (error) {
        // console.log("getCreditPointCalculation ===>", error, error.response)
        dispatch(loadFail(error.response))
      }
      dispatch(this.stopFetchingCreditPointCalculation())
    }
  },

  useCreditPoint() {
    return dispatch => {
      dispatch({
        type: actionTypes.USE_CREDIT_POINT
      })
    }
  },

  unuseCreditPoint() {
    return dispatch => {
      dispatch({
        type: actionTypes.UNUSE_CREDIT_POINT
      })
    }
  },

  startFetchingCreditPointCalculation() {
    return dispatch => {
      dispatch({
        type: actionTypes.START_FETCHING_CREDIT_POINT_CALCULATION
      })
    }
  },
  
  stopFetchingCreditPointCalculation(){
    return dispatch => {
      dispatch({
        type: actionTypes.STOP_FETCHING_CREDIT_POINT_CALCULATION
      })
    }
  },

  resetCreditPointCalculationData() {
    return dispatch => {
      dispatch({
        type: actionTypes.RESET_CREDIT_POINT_CALCULATION_DATA
      })
    }
  }
}
