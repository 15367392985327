import { LOCAL_STORAGE_KEY } from './../constants';

export const get = (key) => {
    if(typeof localStorage === 'undefined') return
    const storageKey = `${LOCAL_STORAGE_KEY.PREFIX}_${key}`;
    const stringData = localStorage.getItem(storageKey);
    const numberRegex = RegExp('^[1-9.]+$');
    if (stringData === 'true' || stringData === 'false') {
        return stringData === 'true'
    } else if (stringData === 'null') {
        return null;
    } else if (stringData === 'undefined') {
        return undefined;
    } else if (numberRegex.test(stringData)) {
        return Number(stringData)
    } else {
        try {
            const data = JSON.parse(stringData);
            return data;
        } catch (error) {
            console.error(error)
            return stringData
        }
    }
}

export const set = (key, data) => {
    if(typeof localStorage === 'undefined') return
    const storageKey = `${LOCAL_STORAGE_KEY.PREFIX}_${key}`;
    let stringData = undefined;
    try {
        if (typeof data === 'object') {
            stringData = JSON.stringify(data)
        } else {
            stringData = String(data);
        }
    } catch (error) {
        console.error(error)
        stringData = data
    }
    return localStorage.setItem(storageKey, stringData);
}

export const remove = (key) => localStorage.removeItem(`${LOCAL_STORAGE_KEY.PREFIX}_${key}`)