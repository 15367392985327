import React from 'react'
import { createSelector } from 'reselect'
import { FormattedMessage } from "react-intl.macro";
// constant
import { enumType } from '../constants'

const getLocations = state => state.hotel.locations
const getAllLocations = state => state.hotel.allLocations
const getHotelsByLocation = state => state.hotel.hotelsByLocation
const getHotels = state => state.hotel.hotels
const getRooms = state => state.hotel.rooms
const getRoom = state => state.hotel.room
const getFilter = state => state.hotel.filters
const getSort = state => state.hotel.sort
const getPaymentUrl = state => state.hotel.paymentUrl
const phsOrder = state => state.hotel.phsOrder
const phsOrderFetching = state => state.hotel.phsOrderFetching
const getErrorMarkupBundle = state => state.hotel.isErrorMarkupBundle
const getErrorPromotionMarkup = state => state.hotel.isErrorPromotionMarkup
const getConfirmPrice = state => state.hotel.confirmPrice
const getListHotelDeals = state => state.hotel.hotelDeals

export const filterHotels = (listHotels, filters) => {
  const {
    results,
    dataChecked
  } = filters

  let acceptSKUId = []
  let listDataHotel = []

  if (!listHotels || listHotels.length === 0) {
    return []
  }

  // if(dataChecked.length === 0) {
  //   listDataHotel = listHotels
  // } else { 
  //   dataChecked.forEach(data => {
  //     acceptSKUId = [...acceptSKUId, ...results[data]]
  //   })
  
  //   listDataHotel = listHotels.filter(
  //     hotel => acceptSKUId.includes(hotel.hotel.id))
  // }
  if(dataChecked.length === 0) {
    listDataHotel = listHotels
  } else { 
    let dataCheckedMap = dataChecked.map(data => mapDataToArray((data || []).map(item => results[item])));
    listDataHotel = listHotels.filter(
      hotel => dataCheckedMap.filter(check => check.includes(hotel.hotel.id)).length === dataCheckedMap.length
    )
  }

  return listDataHotel
}

const mapDataToArray = (data)=> {
  let arr = []
  Object.keys(data).forEach(key => {
    arr = [...arr, ...data[key]]
  })
  return arr
}

const getTotalAmountAndAvailableRoom = (roomRates) => {

  let totalPrice = 0
  let availableRoom = 0
  if (roomRates) {
    const {
      averageAmount,
      quantity
    } = roomRates
    if (averageAmount) {
      totalPrice = averageAmount.amount.amount
    }
    if (quantity > 0) {
      availableRoom = quantity
    }
  }

  return {
    totalPrice: totalPrice,
    availableRoom: availableRoom
  }
}

const getListHotelInfo = (
  hotels,
  filters,
  sort,
  topHotelId,
) => {

  const listHotels = filterHotels(hotels, filters)

  if (listHotels.length === 0) {
    return []
  }
  
  let resultSearch = listHotels.map(hotel => ({
    responseTime: hotel.responseTime,
    isHotDeal: hotel.isHotDeal,
    ...hotel.hotel,
    ...getTotalAmountAndAvailableRoom(hotel.rate),
    rate: hotel.rate
  }))

  switch (sort) {
    case enumType.sortType.MaxPrice:
      return resultSearch.sort(
        (arrVal, othVal) => {
          return arrVal.totalPrice < othVal.totalPrice ? 1 : -1
        }
      )
    case enumType.sortType.MinPrice:
      return resultSearch.sort(
        (arrVal, othVal) => {
          return arrVal.totalPrice > othVal.totalPrice ? 1 : -1
        }
      )
    case enumType.sortType.NoSort:
      // const results = resultSearch.sort(
      //   (arrVal, othVal) => {
      //     return arrVal.responseTime > othVal.responseTime ? 1 : -1
      //   }
      // )
      if (topHotelId) {
        const filteredHotel = resultSearch.filter(hotel => topHotelId !== hotel.id)
        const matchHotel = resultSearch.find(hotel => topHotelId === hotel.id)
        const hotelSorted = filteredHotel.sort((a, b) => a.totalPrice - b.totalPrice)
        if (matchHotel) {
          hotelSorted.unshift(matchHotel)
        }
        return filteredHotel
      } 
      return resultSearch.sort((a, b) => a.totalPrice - b.totalPrice)
    default:
      break
  }

  return resultSearch
}

const getRoomAmenity = (roomType) => {

  if (!roomType) {
    return []
  }

  const {
    maxOccupancy,
    area,
    amenity
  } = roomType

  let roomAmenities = [
    {
      name: <FormattedMessage
                                id="Combo.Hotel.RoomRate.Amenity.People"
                                defaultMessage="{maxAdult} Người"
                                values={{
                                  maxAdult: maxOccupancy
                                }}
                              />,
      icon: require('@assets/include/svg/user-group.svg').default,
      code: 'users'
    },
    {
      name: `${area} m\u00B2`,
      icon: require('@assets/include/svg/size.svg').default,
      code: 'area'
    }
  ]

  if (amenity && amenity.length > 0) {
    roomAmenities.push(...amenity)
  }
  return roomAmenities
}

const getRoomRates = (listRates, roomTypeId, amenity, image, hotel) => {
  if (!listRates || listRates.length === 0) {
    return []
  }

  return listRates.map(rate => ({
    ratePlanId: rate.ratePlan ? rate.ratePlan.ratePlanId : null,
    roomTypeId: roomTypeId,
    hotelId: hotel.id,
    name: rate.ratePlan.name,
    description: rate.ratePlan.description,
    totalPrice: rate.totalAmountIncludeTax
      ? rate.totalAmountIncludeTax.amount.amount
      : 0,
    amenity: amenity,
    address: hotel.address,
    hotelName: hotel.name,
    hotelStar: hotel.star,
    image: image,
    cancelPolicy: rate.ratePlan.cancelPolicy
  }))
}

const getRoomRateInfo = (
  rooms
) => {

  if (!rooms) {
    return []
  }

  const {
    roomRates,
    hotel
  } = rooms

  if (!roomRates || roomRates.length === 0) {
    return []
  }

  let listRoomRates = []

  roomRates.forEach(item => {
    if (item.roomtype && item.roomtype.isActive) {
      const {
        roomtype
      } = item
      const amenity = getRoomAmenity(roomtype)
      const roomTypeId = roomtype.id
      const media = roomtype.media && roomtype.media.length > 0
        ? roomtype.media[0]
        : null
      listRoomRates.push({
        ...item.roomtype,
        amenity: amenity,
        rates: getRoomRates(item.rates, roomTypeId, amenity, media, hotel)
      })
    }
  })

  return listRoomRates
}

export const selectLocations = () =>
  createSelector(
    getLocations,
    locations => locations
  )

export const selectAllLocations = () =>
  createSelector(
    getAllLocations,
    allLocations => allLocations
  )

export const selectHotelsByLocation = () =>
  createSelector(
    getHotelsByLocation,
    hotelsByLocation => hotelsByLocation
  )

export const selectHotelFilter = () =>
  createSelector(
    getFilter,
    filters => filters
  )

export const selectHotelSort = () =>
  createSelector(
    getSort,
    sort => sort
  )

export const selectHotels = (topHotelId) =>
  createSelector(
    [getHotels, getFilter, getSort],
    (
      hotels,
      filters,
      sort
    ) => getListHotelInfo(hotels, filters, sort, topHotelId))

export const selectRooms = () =>
  createSelector(
    getRooms,
    rooms => rooms
  )

export const selectHotelDetail = () =>
  createSelector(
    getRooms,
    rooms => rooms ? {...rooms.hotel, roomTypeTotal: rooms.roomTypeTotal, isPHS: rooms.isPHS} : null
  )

export const selectHotelRooms = () =>
  createSelector(
    getRooms,
    rooms => rooms ? rooms.rooms : null
  )

export const selectRoomRates = () =>
  createSelector(
    getRooms,
    rooms => getRoomRateInfo(rooms)
  )

export const selectRoom = () => createSelector(
  getRoom,
  room => room
)

export const selectPaymentUrl = () => createSelector(
  getPaymentUrl,
  paymentUrl => paymentUrl
)

export const selectAllHotel = () => createSelector(
  getHotels,
  hotels => hotels
)

export const selectPhsOrder = () => createSelector(
  phsOrder,
  phs => phs
)

export const selectPhsOrderFetch = () => createSelector(
  phsOrderFetching,
  phsFetch => phsFetch
)

export const selectErrorMarkupBundle = () => createSelector(
  getErrorMarkupBundle,
  isErrorMarkupBundle => isErrorMarkupBundle
)

export const selectErrorPromotionMarkup = () => createSelector(
  getErrorPromotionMarkup,
  isErrorPromotionMarkup => isErrorPromotionMarkup
)

export const selectConfirmPrice = () => createSelector(
  getConfirmPrice,
  confirmPrice => confirmPrice
)

export const selectHotelRoomRateHotDeal = () =>
  createSelector(
    getRooms,
    rooms => rooms ? rooms.hotdeal : []
  )

export const selectHotelDeals = () => createSelector(
  getListHotelDeals,
  hotelDeals => hotelDeals
)
