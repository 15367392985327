import React from 'react'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl.macro'
import routes from './routes'
import featureFlags from './featureFlags'
import { getLanguageCookie } from '../utils/lang'
import { PAYMENT_ENUM } from './index'
import { getConfig } from '@config'

const {
  publicRuntimeConfig: {
    HOME_PAGE,
    API_BASE_VINWONDERS_URL
  } // Available both client and server side
} = getConfig()

const homePageUrl = HOME_PAGE || 'https://dev-vinpearl.vin3s.vn'
const homePageVinWondersUrl = API_BASE_VINWONDERS_URL

const nav = [
  {
    route: routes.HOTEL,
    title: <FormattedMessage
      id="Menu.Header.Hotel2"
      defaultMessage="Khách sạn"
    />,
    id: 'vp-tracking_menu-header-hotel-booking',
    key: 'hotel',
    icon: require('@assets/include/svg/fi-br-building.svg').default,
    icon_active: require('@assets/include/svg/fi-br-building-active.svg').default,
    disabled: featureFlags.DISABLE_HOTEL
  },
  {
    route: routes.COMBO,
    title: <FormattedMessage
      id="Menu.Header.Combo"
      defaultMessage="Khách sạn + Vé máy bay"
    />,
    id: 'vp-tracking_menu-header-combo-booking',
    key: 'hotel-and-flight',
    icon: require('@assets/include/svg/fi-br-playing-cards.svg').default,
    icon_active: require('@assets/include/svg/fi-br-playing-cards-active.svg').default,
    disabled: featureFlags.DISABLE_COMBO
  },
  {
    route: routes.TOUR_AND_EXPERIENCE,
    title: <FormattedMessage
      id="Menu.Header.ToursAndExperiences"
      defaultMessage="Tour & Trải nghiệm"
    />,
    id: 'vp-tracking_menu-header-voucher-booking',
    key: 'tours-and-experiences',
    icon: require('@assets/include/svg/fi-br-backpack.svg').default,
    icon_active: require('@assets/include/svg/fi-br-backpack-active.svg').default,
  },
  {
    route: routes.STAYNFUN,
    title: <FormattedMessage
      id="Menu.Header.Staynfun"
      defaultMessage="Staynfun"
    />,
    id: 'vp-tracking_menu-header-voucher-booking',
    key: 'staynfun',
  },
]

export const INSTALLMENT_POLICY_PAGE_SLUG = "chinh-sach-tra-gop"

export const navIntroFooter = [
  {
    route: '../general-terms/ve-vinpearl-travel',
    key: 'about',
    title: <FormattedMessage
      id="Menu.Footer.About"
      defaultMessage="Về Vinpearl"
    />
  },
  {
    route: `..${routes.REGULATIONS}/dieu-khoan-chung`,
    key: 'condition',
    title: <FormattedMessage
      id="Menu.Footer.TermAndCondition"
      defaultMessage="Điều khoản, điều kiện và chính sách quyền riêng tư"
    />
  },
  {
    route: '../general-terms/chinh-sach-bao-mat',
    key: 'security',
    title: <FormattedMessage
      id="Menu.Footer.SecurityPolicy"
      defaultMessage="Chính sách bảo mật"
    />
  },
  {
    route: `../general-terms/${INSTALLMENT_POLICY_PAGE_SLUG}`,
    key: 'condition',
    title: <FormattedMessage
      id="Menu.Footer.InstallmentPolicy"
      defaultMessage="Chính sách trả góp"
    />
  },
  {
    route: '../general-terms/cach-dat-cho',
    key: 'booking',
    title: <FormattedMessage
      id="Menu.Footer.Booking"
      defaultMessage="Cách đặt chỗ"
    />
  },
  {
    route: '../general-terms/tro-giup',
    key: 'help',
    title: <FormattedMessage
      id="Menu.Footer.Help"
      defaultMessage="Trợ giúp"
    />
  }
]

export const navRegulationsFooter = [
  {
    route: `${routes.REGULATIONS}/dieu-khoan-chung`,
    key: 'dieu-khoan-chung',
    titleKey: 'Nav.Footer.GeneralTerms',
    title: <FormattedMessage
      id="Nav.Footer.GeneralTerms"
      defaultMessage="Điều khoản chung"
    />
  },
  {
    route: `${routes.REGULATIONS}/quy-dinh-chung`,
    key: 'quy-dinh-chung',
    titleKey: 'Nav.Footer.GeneralRegulations',
    title: <FormattedMessage
      id="Nav.Footer.GeneralRegulations"
      defaultMessage='Quy định chung'
    />
  },
  {
    route: `${routes.REGULATIONS}/quy-dinh-ve-thanh-toan`,
    key: 'quy-dinh-ve-thanh-toan',
    titleKey: 'Nav.Footer.PaymentProvisions',
    title: <FormattedMessage
      id="Nav.Footer.PaymentProvisions"
      defaultMessage='Quy định về thanh toán'
    />
  },
  {
    route: `${routes.REGULATIONS}/quy-dinh-ve-xac-nhan-dat-phong`,
    key: 'quy-dinh-ve-xac-nhan-dat-phong',
    titleKey: 'Nav.Footer.RulesOfBookingConfirmation',
    title: <FormattedMessage
      id="Nav.Footer.RulesOfBookingConfirmation"
      defaultMessage='Quy định về xác nhận thông tin đặt phòng'
    />
  },
  {
    route: `${routes.REGULATIONS}/chinh-sach-bao-mat`,
    key: 'chinh-sach-bao-mat',
    titleKey: 'Menu.Footer.SecurityPolicy',
    title: <FormattedMessage
      id="Menu.Footer.SecurityPolicy"
      defaultMessage="Chính sách bảo mật"
    />,
    hide: true,
  },
  // {
  //   route: `${routes.REGULATIONS}/quy-che-hoat-dong-website-cung-cap-dich-vu-thuong-mai-dien-tu`,
  //   key: 'quy-che-hoat-dong-website-cung-cap-dich-vu-thuong-mai-dien-tu',
  //   titleKey: 'Nav.Footer.RegulationsForE-commerce',
  //   title: <FormattedMessage
  //     id="Nav.Footer.RegulationsForE-commerce"
  //     defaultMessage='Quy chế hoạt động sàn TMĐT'
  //   />
  // },
  {
    route: `${routes.REGULATIONS}/chinh-sach-giai-quyet-tranh-chap`,
    key: 'chinh-sach-giai-quyet-tranh-chap',
    titleKey: 'Nav.Footer.ProceduresForResolving',
    title: <FormattedMessage
      id="Nav.Footer.ProceduresForResolving"
      defaultMessage='Chính sách giải quyết tranh chấp'
    />
  },
  {
    route: `${routes.REGULATIONS}/chinh-sach-quyen-rieng-tu`,
    key: 'chinh-sach-quyen-rieng-tu',
    titleKey: 'Nav.Footer.PrivatePermissionRegulations',
    title: <FormattedMessage
      id="Nav.Footer.PrivatePermissionRegulations"
      defaultMessage="Chính sách quyền riêng tư"
    />
  },
]

export const navHomestayFooter = [
  {
    route: `https://staynfun.vinpearl.com/vi/dieu-khoan-chung`,
    key: 'dieu-khoan-chung',
    titleKey: 'Nav.Footer.GeneralTerms',
    title: <FormattedMessage
      id="Nav.Footer.GeneralTerms"
      defaultMessage="Điều khoản chung"
    />
  },
  {
    route: `https://staynfun.vinpearl.com/vi/quy-dinh-chung`,
    key: 'quy-dinh-chung',
    titleKey: 'Nav.Footer.GeneralRegulations',
    title: <FormattedMessage
      id="Nav.Footer.GeneralRegulations"
      defaultMessage='Quy định chung'
    />
  },
  {
    route: `https://staynfun.vinpearl.com/vi/chinh-sach-giai-quyet-khieu-nai-tranh-chap`,
    key: 'chinh-sach-giai-quyet-tranh-chap',
    titleKey: 'Nav.Footer.ProceduresForResolving',
    title: <FormattedMessage
      id="Nav.Footer.ProceduresForResolving"
      defaultMessage='Chính sách giải quyết tranh chấp'
    />
  },
  {
    route: `https://staynfun.vinpearl.com/vi/chinh-sach-quyen-rieng-tu`,
    key: 'chinh-sach-quyen-rieng-tu',
    titleKey: 'Nav.Footer.PrivatePermissionRegulations',
    title: <FormattedMessage
      id="Nav.Footer.PrivatePermissionRegulations"
      defaultMessage="Chính sách quyền riêng tư"
    />
  },
]

export const navProductFooter = [
  {
    route: '',
    key: 'holiday',
    title: <FormattedMessage
      id="Menu.Footer.Holiday"
      defaultMessage="Kỳ nghỉ"
    />
  },
  {
    route: '',
    key: 'flight',
    title: <FormattedMessage
      id="Menu.Footer.Flight"
      defaultMessage="Vé máy bay"
    />
  },
  {
    route: '',
    key: 'hotel',
    title: <FormattedMessage
      id="Menu.Footer.Hotel"
      defaultMessage="Khách sạn"
    />
  },
  {
    route: '',
    key: 'combo',
    title: <FormattedMessage
      id="Menu.Footer.Combo"
      defaultMessage="Combo"
    />
  },
  {
    route: '',
    key: 'voucher',
    title: <FormattedMessage
      id="Menu.Footer.Voucher"
      defaultMessage="Voucher"
    />
  }
]

export const navDestinationFooter = [
  {
    route: '',
    key: 'ha-long',
    title: <FormattedMessage
      id="Nav.DestinationHaLong.Footer"
      defaultMessage="Vịnh Hạ Long"
    />
  },
  {
    route: '',
    key: 'da-nang',
    title: <FormattedMessage
      id="Nav.DestinationDaNang.Footer"
      defaultMessage="Đà Nẵng"
    />
  },
  {
    route: '',
    key: 'nha-trang',
    title: <FormattedMessage
      id="Nav.DestinationNhaTrang.Footer"
      defaultMessage="Nha Trang"
    />
  },
  {
    route: '',
    key: 'hcm',
    title: <FormattedMessage
      id="Nav.DestinationHCM.Footer"
      defaultMessage="Hồ Chí Minh"
    />
  },
  {
    route: '',
    key: 'hue',
    title: <FormattedMessage
      id="Nav.DestinationHue.Hue"
      defaultMessage="Huế"
    />
  }
]

export const navVPInfoFooter = {
  title: 'Công ty Cổ phần Vinpearl',
  phone: {
    icon: require('@assets/img/icons/footer/call.svg').default,
    content: '024 3974 9999',
  },
  hotline: {
    content: '1900 23 23 89',
    branch: 'nhánh số 3'
  },
  email: {
    icon: require('@assets/img/icons/footer/envelope.svg').default,
    content: 'callcenter@vinpearl.com',
  },
  building: {
    content: 'Tòa nhà Symphony',
    address: 'Đường Chu Huy Mân, Vinhomes Riverside Long Biên, Hà Nội, Việt Nam',
  },
  address: {
    icon: require('@assets/img/icons/footer/geo.svg').default,
    content: 'Địa chỉ: Số 7, đường Bằng Lăng 1, Khu đô thị sinh thái Vinhomes Riverside, Phường Việt Hưng, Quận Long Biên, Thành phố Hà Nội, Việt Nam',
  },
  license: {
    icon: require('@assets/img/icons/footer/card_checklist.svg').default,
    content: 'Số đăng ký kinh doanh: 4200456848<br />do Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp lần đầu ngày 26 tháng 07 năm 2006',
  },
  representative: {
    icon: require('@assets/img/icons/footer/user.svg').default,
    content: 'Người đại diện pháp luật:<br /> Bà Nguyễn Mai Hoa',
  },
}

export const nav3SInfoFooter = {
  // title: 'Công ty Cổ phần Vin3S',
  title: <FormattedMessage
            id="Footer.Info.Title"
            defaultMessage='Công ty Cổ phần Vin3S'
          />,
  phone: {
    icon: require('@assets/img/icons/footer/call.svg').default,
    content: '1900 23 23 89',
    contentClass: 'g-font-weight-600',
    // description: '(1000 VNĐ/phút)',
    description: <FormattedMessage
                    id="Footer.Info.Phone"
                    defaultMessage='(1000 VNĐ/phút)'
                  />, 
    href: 'tel:1900232389',
  },
  hotline: {
    content: '1900 23 23 89',
    // branch: 'nhánh số 3'
    branch: <FormattedMessage
              id="Footer.Info.Branch"
              defaultMessage='nhánh số 3'
            />
  },
  email: {
    icon: require('@assets/img/icons/footer/envelope.svg').default,
    content: 'domain3s@vingroup.net',
    href: 'mailto:domain3s@vingroup.net',
    hide: true,
  },
  building: {
    // content: 'Tòa nhà Symphony',
    // address: 'Đường Chu Huy Mân, Vinhomes Riverside Long Biên, Hà Nội, Việt Nam',
    content: <FormattedMessage
                id="Footer.Info.BuildingName"
                defaultMessage='Tòa nhà Symphony'
              />,
    address: <FormattedMessage
                id="Footer.Info.BuildingAddress"
                defaultMessage='Đường Chu Huy Mân, Vinhomes Riverside Long Biên, Hà Nội, Việt Nam'
              />
  },
  address: {
    icon: require('@assets/img/icons/footer/geo.svg').default,
    // content: 'Địa chỉ: Số 7, đường Bằng Lăng 1, Khu đô thị sinh thái Vinhomes Riverside, Phường Việt Hưng, Quận Long Biên, Thành phố Hà Nội, Việt Nam',
    content: <FormattedMessage
                id="Footer.Info.Address"
                defaultMessage='Địa chỉ: Số 7, đường Bằng Lăng 1, Khu đô thị sinh thái Vinhomes Riverside, Phường Việt Hưng, Quận Long Biên, Thành phố Hà Nội, Việt Nam'
              />
  },
  license: {
    icon: require('@assets/img/icons/footer/card_checklist.svg').default,
    // content: 'Số đăng ký kinh doanh: 0106892021<br />do Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp lần đầu ngày 03 tháng 07 năm 2015',
    content: <FormattedHTMLMessage
                id="Footer.Info.License"
                defaultMessage="Số đăng ký kinh doanh: 0106892021<br />do Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp lần đầu ngày 03 tháng 07 năm 2015"
              />
  },
  representative: {
    icon: require('@assets/img/icons/footer/user.svg').default,
    // content: 'Người đại diện pháp luật:<br /> Bà Phan Thu Hương – Chủ tịch hội đồng quản trị',
    content: <FormattedHTMLMessage
                id="Footer.Info.Representative"
                defaultMessage="Người đại diện pháp luật:<br /> Bà Phan Thu Hương – Chủ tịch hội đồng quản trị"
              />
  },
}

export const ecoRegulationFooter = [
  {
    route: '../regulations/chinh-sach-va-quy-dinh-chung',
    key: 'general-regulation',
    title: <FormattedMessage
      id="Nav.Footer.GeneralPoliciesAndRegulations"
      defaultMessage='Chính sách và quy định chung'
    />
  },
  {
    route: '../regulations/quy-dinh-ve-thanh-toan',
    key: 'payment-regulation',
    title: <FormattedMessage
      id="Nav.Footer.PaymentProvisions"
      defaultMessage='Quy định về thanh toán'
    />
  },
  {
    route: '../regulations/quy-dinh-ve-xac-nhan-dat-phong',
    key: 'booking-confirm-regulation',
    title: <FormattedMessage
      id="Nav.Footer.RulesOfBookingConfirmation"
      defaultMessage='Quy định về xác nhận thông tin đặt phòng'
    />
  },
  {
    route: '../regulations/chinh-sach-huy-phong-va-hoan-tien',
    key: 'cancelation-regulation',
    title: <FormattedMessage
      id="Nav.Footer.CancellationAndRefundPolicy"
      defaultMessage='Chính sách về hủy đặt phòng và hoàn trả tiền'
    />
  },
  {
    route: '../regulations/chinh-sach-bao-mat',
    key: 'secure-regulation',
    title: <FormattedMessage
      id="Nav.Footer.InformationSecurityPolicy"
      defaultMessage='Chính sách bảo mật thông tin'
    />
  },
  {
    route: '../regulations/quy-trinh-giai-quyet-khieu-nai',
    key: 'complain-regulation',
    title: <FormattedMessage
      id="Nav.Footer.ProceduresForResolving"
      defaultMessage='Quy trình giải quyết tranh chấp, khiếu nại'
    />
  }
]

export const bookingServiceRegulationFooter = [
  {
    route: '',
    key: 'operation-regulation',
    title: <FormattedMessage
      id="Nav.Footer.OperationRegulation"
      defaultMessage='Quy chế hoạt động'
    />
  },
  {
    route: '../page/chinh-sach-bao-mat',
    key: 'secure-policy',
    title: <FormattedMessage
      id="Nav.Footer.PrivacyPolicy"
      defaultMessage='Chính sách bảo mật'
    />
  },
]

export const navFooter = [
  {
    title: <FormattedMessage
      id="Nav.Footer.GeneralTerms"
      defaultMessage="Điều khoản chung"
    />,
    children: navIntroFooter
  },
  {
    title: <FormattedMessage
      id="Nav.Footer.Product"
      defaultMessage="Sản phẩm"
    />,
    children: navProductFooter
  },
  {
    title: <FormattedMessage
      id="Nav.Footer.Destination"
      defaultMessage="Điểm đến"
    />,
    children: navDestinationFooter
  }
]

export const navMyProfile = [
  {
    title: <FormattedMessage
      id="UserProfile.MenuProfile.MyProfile"
      defaultMessage='Thông tin tài khoản'
    />,
    key: 'user-profile',
    route: routes.USER_PROFILE,
    icon: require('@assets/images/icons/Profile.svg').default,
    icon_active: require('@assets/images/icons/Profile.svg').default,
    showOnlyMemberShip: ''
  },
  {
    title: <FormattedMessage
      id="UserProfile.MyOrder.TransactionHistory"
      defaultMessage='Đơn hàng của tôi'
    />,
    key: 'my-order',
    route: routes.MY_ORDER,
    icon: require('@assets/images/icons/Scroll.svg').default,
    icon_active: require('@assets/images/icons/Scroll.svg').default,
    showOnlyMemberShip: 'TEMP'
  },
    // {
  //   title: <FormattedMessage
  //     id="UserProfile.MenuProfile.TransactionHistory"
  //     defaultMessage='Lịch sử giao dịch'
  //   />,
  //   key: 'transaction-history',
  //   route: routes.MY_ORDER,
  //   icon: require('@assets/images/icons/MoreCircle.svg').default,
  //   icon_active: require('@assets/images/icons/MoreCircle.svg').default,
  //   showOnlyMemberShip: ''
  // },
  {
    title: <FormattedMessage
      id="UserProfile.MenuProfile.MyOffer"
      defaultMessage='Ưu đãi của tôi'
    />,
    key: 'my-offer',
    route: routes.MY_OFFER,
    icon: require('@assets/images/icons/Currency2.svg').default,
    icon_active: require('@assets/images/icons/Currency2.svg').default,
    showOnlyMemberShip: 'VIP'
  },
  {
    title: <FormattedMessage
      id="UserProfile.MenuProfile.PearlClubBenefit"
      defaultMessage='Thông tin đặc quyền'
    />,
    key: 'pearl-club-benefit',
    route: routes.PEARL_CLUB_BENEFIT,
    icon: require('@assets/images/icons/Card2.svg').default,
    icon_active: require('@assets/images/icons/Card2.svg').default,
    showOnlyMemberShip: 'VIP'
  },
  {
    title: <FormattedMessage
      id="UserProfile.MenuProfile.CollaboratorsReport"
      defaultMessage='Báo cáo cộng tác viên'
    />,
    key: 'collaborators',
    route: routes.COLLABORATORS,
    icon: require('@assets/images/icons/tag.svg').default,
    icon_active: require('@assets/images/icons/tag.svg').default,
    showOnlyMemberShip: ''
  },
  // {
  //   title: <FormattedMessage
  //     id="Footer.Question"
  //     defaultMessage='Câu hỏi thường gặp'
  //   />,
  //   key: 'faq',
  //   route: routes.FAQ,
  //   icon: require('@assets/images/icons/feather_question.svg').default,
  //   icon_active: require('@assets/images/icons/feather_question.svg').default,
  //   showOnlyMemberShip: ''
  // }
]

export const regulationsFooter = [
  {
    title: <FormattedMessage
      id="Nav.Footer.RulesForSales"
      defaultMessage='Quy định dành cho website TMĐT bán hàng'
    />,
    children: ecoRegulationFooter
  },
  // {
  //   title: <FormattedMessage
  //     id="Nav.Footer.RegulationsForE-commerce"
  //     defaultMessage='Quy định cho sàn GDTMĐT - Dịch vụ đặt phòng và dịch vụ đặt tour du lịch'
  //   />,
  //   children: bookingServiceRegulationFooter
  // }
]

export const topHeaderMenu = [
  // {
  //   title: <FormattedMessage
  //     id="Nav.TopHeader.News"
  //     defaultMessage='Tin tức'
  //   />,
  //   link: undefined,
  // },
  {
    title: 'Vinhomes',
    link: 'https://vinhomes.vn/',
  },
  {
    title: 'VinFast',
    link: 'https://vinfast.vn/',
  },
  {
    title: 'Vinmec',
    link: 'https://vinmec.com/',
  },
  {
    title: 'Vsmart',
    link: 'https://vsmart.net/',
  },
  {
    title: 'Vinpearl',
    link: 'http://vinpearl.com/',
  },
  // {
  //   title: 'Elite Club',
  //   link: 'https://vin3s.vn/vineliteclub',
  // },
  // {
  //   title: 'Elite Shop',
  //   link: 'https://vin3s.vn/vineliteshop',
  // },
];

export const topHeaderRightMenu = [
  {
    title: 'Liên hệ',
    link: `${homePageUrl}/vi/lien-he`,
  },
  {
    title: 'Về chúng tôi',
    link: `${homePageUrl}/vi/ve-chung-toi`,
  },
];

export const headerMenu = [
  // {
  //   title: <FormattedMessage
  //     id="Nav.Header.Hotel"
  //     defaultMessage='Khách sạn'
  //   />,
  //   className: "left-nav hotel",
  //   link: undefined,
  //   children: [
  //     { 
  //       title: <FormattedMessage
  //         id="Nav.Header.Hotel.PhuQuoc"
  //         defaultMessage='Phú Quốc'
  //       />, 
  //       link: '#',
  //       children:[
  //         {
  //           title: <FormattedMessage
  //             id="Nav.Header.Hotel.PhuQuoc.FiestaPhuQuoc"
  //             defaultMessage='VinHolidays Fiesta Phú Quốc'
  //           />,
  //           link: '/hotels/vinholidays-fiesta-phu-quoc',
  //           externalLink: homePageUrl,
  //           className: "s43-sub-menu"
  //         },
  //         {
  //           title: <FormattedMessage
  //             id="Nav.Header.Hotel.PhuQuoc.WonderworldPhuQuoc"
  //             defaultMessage='Vinpearl Wonderworld Phú Quốc'
  //           />,
  //           link: '/hotels/vinpearl-wonderworld-phu-quoc',
  //           externalLink: homePageUrl,
  //           className: "s43-sub-menu"
  //         },
  //         {
  //           title: <FormattedMessage
  //             id="Nav.Header.Hotel.PhuQuoc.ResortSpaPhuQuoc"
  //             defaultMessage='Vinpearl Resort & Spa Phú Quốc'
  //           />,
  //           link: '/hotels/vinpearl-resort-spa-phu-quoc',
  //           externalLink: homePageUrl,
  //           className: "s43-sub-menu"
  //         },
          
  //       ]
  //     },
  //     { 
  //       title: <FormattedMessage
  //         id="Nav.Header.Hotel.NhaTrang"
  //         defaultMessage='Nha Trang'
  //       />, 
  //       link: `/hotels-nha-trang`, 
  //       externalLink: homePageUrl,
  //       id: 'vp-tracking_menu-header-population-NT',
  //       children:[
  //         {
  //           title: <FormattedMessage
  //             id="Nav.Header.Hotel.NhaTrang.ResortSpaNhaTrangBay"
  //             defaultMessage='Vinpearl Resort & Spa Nha Trang Bay'
  //           />,
  //           link: '/hotels/vinpearl-resort-spa-nha-trang-bay',
  //           externalLink: homePageUrl,
  //           className: "s43-sub-menu"
  //         },
  //         {
  //           title: <FormattedMessage
  //             id="Nav.Header.Hotel.NhaTrang.VinpearlResortNhaTrang"
  //             defaultMessage='Vinpearl Resort Nha Trang'
  //           />,
  //           link: '/hotels/vinpearl-resort-nha-trang',
  //           externalLink: homePageUrl,
  //           className: "s43-sub-menu"
  //         },
  //         {
  //           title: <FormattedMessage
  //             id="Nav.Header.Hotel.NhaTrang.VinpearlSealinkNhaTrang"
  //             defaultMessage='Vinpearl Sealink Nha Trang'
  //           />,
  //           link: '/hotels/vinpearl-sealink-nha-trang',
  //           externalLink: homePageUrl,
  //           className: "s43-sub-menu"
  //         },
  //         {
  //           title: <FormattedMessage
  //             id="Nav.Header.Hotel.NhaTrang.VinpearlLuxuryNhaTrang"
  //             defaultMessage='Vinpearl Luxury Nha Trang'
  //           />,
  //           link: '/hotels/vinpearl-luxury-nha-trang',
  //           externalLink: homePageUrl,
  //           className: "s43-sub-menu"
  //         },
  //         {
  //           title: <FormattedMessage
  //             id="Nav.Header.Hotel.NhaTrang.VinpearlBeachfrontNhaTrang"
  //             defaultMessage='Vinpearl Beachfront Nha Trang'
  //           />,
  //           link: '/hotels/vinpearl-beachfront-nha-trang',
  //           externalLink: homePageUrl,
  //           className: "s43-sub-menu"
  //         },
  //         {
  //           title: <FormattedMessage
  //             id="Nav.Header.Hotel.NhaTrang.VinpearlGolflinkNhaTrang"
  //             defaultMessage='Vinpearl Golflink Nha Trang'
  //           />,
  //           link: '/hotels/vinpearl-golflink-nha-trang',
  //           externalLink: homePageUrl,
  //           className: "s43-sub-menu"
  //         },
  //       ]
  //     },
  //     { 
  //       title: <FormattedMessage
  //         id="Nav.Header.Hotel.HoiAn"
  //         defaultMessage='Hoi An'
  //       />, 
  //       link: "/hotels-nam-hoi-an",
  //       externalLink: homePageUrl,
  //       children:[
  //         {
  //           title: <FormattedMessage
  //             id="Nav.Header.Hotel.NhaTrang.ResortSpaHoiAn"
  //             defaultMessage='Vinpearl Resort & Spa Hội An'
  //           />,
  //           link: '/hotels/vinpearl-resort-spa-hoi-an',
  //           externalLink: homePageUrl,
  //           className: "s43-sub-menu"
  //         },
  //         {
  //           title: <FormattedMessage
  //             id="Nav.Header.Hotel.NhaTrang.ResortSpaHoiAn"
  //             defaultMessage='Vinpearl Resort & Golf Nam Hội An'
  //           />,
  //           link: '/hotels/vinpearl-resort-spa-hoi-an',
  //           externalLink: homePageUrl,
  //           className: "s43-sub-menu"
  //         },
  //       ],
  //     },
  //     { 
  //       title: <FormattedMessage
  //         id="Nav.Header.Hotel.DaNang"
  //         defaultMessage='Đà Nẵng'
  //       />, 
  //       link: "/hotels-da-nang",
  //       externalLink: homePageUrl,
  //       children:[
  //         {
  //           title: <FormattedMessage
  //             id="Nav.Header.Hotel.DaNang.ResortSpaDaNang"
  //             defaultMessage='Vinpearl Resort & Spa Đà Nẵng'
  //           />,
  //           link: '/hotels/vinpearl-resort-spa-da-nang',
  //           externalLink: homePageUrl,
  //           className: "s43-sub-menu"
  //         },
  //       ],
  //       className:"sub-group"
  //     },
  //     { 
  //       title: <FormattedMessage
  //         id="Nav.Header.Hotel.QuangNinh"
  //         defaultMessage='Quảng Ninh'
  //       />, 
  //       link: "/hotels-ha-long",
  //       externalLink: homePageUrl,
  //       children:[
  //         {
  //           title: <FormattedMessage
  //             id="Nav.Header.Hotel.QuangNinh.ResortSpaHaLong"
  //             defaultMessage='Vinpearl Resort & Spa Hạ Long'
  //           />,
  //           link: '/hotels/vinpearl-resort-spa-ha-long',
  //           externalLink: homePageUrl,
  //           className: "s43-sub-menu"
  //         },
  //       ],
  //     },
  //   ]
  // },
  {
    title: <FormattedMessage
      id="Nav.Header.Experience"
      defaultMessage='Trải nghiệm'
    />,
    className: "left-nav experience",
    link: undefined,
    children: [
      { 
        title: <FormattedMessage
          id="Nav.TopHeader.PopulationPhuQuoc"
          defaultMessage='Phú Quốc'
        />, 
        link: undefined,
        id: 'vp-tracking_menu-header-population-PQ',
        children:[
          {
            title: <FormattedMessage
              id="Nav.TopHeader.PopulationPhuQuoc.VinWondersPhuQuoc"
              defaultMessage='VinWonders Phú Quốc'
            />,
            id: 'vp-tracking_menu-header-featured-vinWonders-phu-quoc',
            link: '/vinwonders-phu-quoc',
            externalLink: homePageVinWondersUrl,
            className: "s43-sub-menu"
          },
          {
            title: <FormattedMessage
              id="Nav.TopHeader.PopulationPhuQuoc.VinpearlSafariPhuQuoc"
              defaultMessage='Vinpearl Safari Phú Quốc'
            />,
            id: 'vp-tracking_menu-header-featured-vinpearl-safari-phu-quoc',
            link: `${homePageVinWondersUrl}/vinpearl-safari-phu-quoc`, 
            link: '/vinpearl-safari-phu-quoc',
            externalLink: homePageVinWondersUrl,
            className: "s43-sub-menu"
          },
          {
            title: <FormattedMessage
              id="Nav.TopHeader.PopulationPhuQuoc.VinpearlGolfPhuQuoc"
              defaultMessage='Vinpearl Golf Phú Quốc'
            />,
            id: 'vp-tracking_menu-header-featured-vinpearl-Golf-phu-quoc',
            link: '/vinpearl-golf-phu-quoc',
            externalLink: homePageUrl,
            className: "s43-sub-menu"
          },
        ]
      },
      { 
        title: <FormattedMessage
          id="Nav.TopHeader.PopulationNhaTrang"
          defaultMessage='Nha Trang'
        />, 
        link: `/nha-trang`, 
        externalLink: homePageUrl,
        id: 'vp-tracking_menu-header-population-NT',
        children:[
          {
            title: <FormattedMessage
              id="Nav.TopHeader.PopulationNhaTrang.VinWondersNhaTrang"
              defaultMessage='VinWonders Nha Trang'
            />,
            id: 'vp-tracking_menu-header-featured-vinWonders-nha-trang',
            link: '/vinwonders-nha-trang',
            externalLink: homePageVinWondersUrl,
            className: "s43-sub-menu"
          },
          {
            title: <FormattedMessage
              id="Nav.TopHeader.PopulationNhaTrang.VinpearlGolfNhaTrang"
              defaultMessage='Vinpearl Golf Nha Trang'
            />,
            id: 'vp-tracking_menu-header-featured-vinpearl-golf-nha-trang',
            link: '/vinpearl-golf-nha-trang',
            externalLink: homePageUrl,
            className: "s43-sub-menu"
          },
        ]
      },
      { 
        title: <FormattedMessage
          id="Nav.TopHeader.PopulationNamHoiAn"
          defaultMessage='Nam Hoi An'
        />, 
        link: '/nam-hoi-an',
        externalLink: homePageUrl,
        id: 'vp-tracking_menu-header-population-NHA',
        children:[
          {
            title: <FormattedMessage
              id="Nav.TopHeader.PopulationNamHoiAn.VinWondersNamHoiAn"
              defaultMessage='VinWonders Nam Hội An'
            />,
            link: '/vinwonders-nam-hoi-an',
            externalLink: homePageVinWondersUrl,
            className: "s43-sub-menu"
          },
          {
            title: <FormattedMessage
              id="Nav.TopHeader.PopulationNamHoiAn.VinpearlGolfNamHoiAn"
              defaultMessage='Vinpearl Golf Nam Hội An'
            />,
            link: '/vinpearl-golf-nam-hoi-an',
            externalLink: homePageUrl,
            className: "s43-sub-menu"
          },
        ]
      },
      { 
        title: <FormattedMessage
          id="Nav.TopHeader.PopulationHaiPhong"
          defaultMessage='Hải Phòng'
        />, 
        link: '/#',
        externalLink: null,
        children:[
          {
            title: <FormattedMessage
              id="Nav.TopHeader.PopulationHaiPhong.VinpearlGolfHaiPhong"
              defaultMessage='Vinpearl Golf Hải Phòng'
            />,
            link: '/vinpearl-golf-hai-phong',
            externalLink: homePageUrl,
            className: "s43-sub-menu"
          },
        ]
      },
    ]
  },
  {
    title: <FormattedMessage
      id="Nav.Header.Promotions"
      defaultMessage='Ưu đãi khuyến mãi'
    />,
    className: "left-nav",
    id: 'vp-tracking_menu-header-news-promotion',
    link: '/promotion',
    externalLink: homePageUrl
  },
  {
    title: <FormattedMessage
      id="Nav.Header.New"
      defaultMessage='News'
    />,
    className: "right-nav",
    id: 'vp-tracking_menu-header-travel-guide',
    link: '/news/travel-guide',
    externalLink: homePageUrl,
  },
  {
    title: <FormattedMessage
      id="Nav.Header.Meeting"
      defaultMessage='Meeting'
    />,
    className: "right-nav",
    id: 'vp-tracking_menu-header-meeting',
    link: '/meeting-events',
    externalLink: homePageUrl,
  },
  {
    title: <FormattedMessage
      id="Nav.TopHeader.PearlClub"
      defaultMessage='VinClub'
    />,
    className: "right-nav pearl-club",
    link: "/pearlclub",
    externalLink: homePageUrl,
    children: [
      {
        title: <FormattedMessage
          id="Nav.TopHeader.Overview"
          defaultMessage='Tổng quan'
        />,
        link: '/pearlclub',
        externalLink: homePageUrl
      },
      {
        title: <FormattedMessage
          id="Nav.TopHeader.Benefit"
          defaultMessage='Quyền lợi'
        />,
        link: '/pearlclub/benefits',
        externalLink: homePageUrl,
      },
      {
        title: <FormattedMessage
          id="Nav.TopHeader.ApplySystem"
          defaultMessage='Hệ thống áp dụng'
        />,
        link: '/pearlclub/applicable-list',
        externalLink: homePageUrl,
      },
      {
        title: <FormattedMessage
          id="Nav.TopHeader.TermsOfUse"
          defaultMessage='Điều kiện điều khoản'
        />,
        link: '/pearlclub/terms-of-use',
        externalLink: homePageUrl,
      },
    ],
  },
  {
    title: <FormattedMessage
      id="Nav.Header.PhotoGallery"
      defaultMessage='Thư viện ảnh'
    />,
    id: 'vp-tracking_menu-header-gallery',
    link: '/gallery',
    externalLink: homePageUrl,
  },
];

export const ccdvFooter = {
  href: 'http://online.gov.vn/Home/WebDetails/67902',
  image: { src: require('@assets/img/logo/logoSaleNoti.png').default },
}

export default nav
