import { Popover } from 'antd';
import React, { useEffect, useMemo, useState } from 'react'
import { chain } from 'lodash';
import "./HotelLocationSearch.scss";

const HotelLocationSearch = ({ value, startPointName, options, onChange, placeholder, onVisibleChange }) => {
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    const groupHotels = chain(options).groupBy(x => x.locationId).map((items, key) => {
      const location = options.find(y => y.locationId === key && y.type === "Location")
      return {
        ...location,
        hotels: items.filter(x => x.hotelName)
      }
    }).value();

    if (groupHotels?.length > 4) {
      groupHotels[2]["subGroup"] = [groupHotels[4]];
      groupHotels.splice(4, 1);
    }

    setData(groupHotels);
  }, [options])

  const handleSelectLocation = (item) => {
    onChange(item);
    setIsOpen(false);
  }

  const handleVisibleChange = (e) => {
    setIsOpen(e);
    if (onVisibleChange) {
      onVisibleChange(e);
    }
  }

  const renderItem = (l,index) => {
    return (
      <div key={index} className='location-col'>
        <div className="location-name" onClick={() => handleSelectLocation(l)}>
          <img src={require('@assets/include/svg/s43/fi-rr-marker.svg').default} className="g-mr-6" alt="" />
          <span>{l.locationName}</span>
        </div>
        <div className="hotel-list">
          {
            l.hotels.map((h,i) =>
              <div key={i} className="hotel-name" onClick={() => handleSelectLocation(h)}>
                {h.hotelName}
              </div>
            )
          }
        </div>
        {
          l.subGroup?.length && l.subGroup.map(s => renderItem(s,index))
        }
      </div>
    )

  }

  const content = useMemo(() => {
    return (
      <div className='s43-hotel-localtion-container'>
        {
          data.map( (l,index) => renderItem(l,index) )
        }
      </div>
    )
  }, [data]);
  return (
    <Popover
      overlayClassName='hotel-localtion-popover'
      placement="bottomLeft"
      content={content}
      trigger="click"
      visible={isOpen}
      onVisibleChange={handleVisibleChange}
    >
      {value ?
        <div className='hotel-overlay'>
          {value.hotelName || value.locationName || startPointName}
        </div>
        :
        <div style={{ cursor: 'pointer', color: 'rgb(128,128,128,0.6)', fontWeight: 'normal' }}>
          {placeholder}
        </div>
      }
    </Popover>
  )
}

export default HotelLocationSearch;