function HHmmss(second) {
  const d = second;
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);
  return { h, m, s };
}
export function secondsToHms(d) {
  const { h, m, s } = HHmmss(d);
  const hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
  const mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
  const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay + sDisplay;
}

export function minutesToxhymzs(minutes) {
  const { h, m, s } = HHmmss(minutes * 60);
  return `${h}h${m}m${s}s`;
}
