import actionTypes from '../actionTypes'
import { cartServices } from '../services'
import { merge } from 'lodash'
import { objectExtensions } from '../extensions'

const initState = {
  cart: null,
  currentCart: null,
  notice: false,
  count: 0,
  coupon: undefined,
  productVariantId: [],
  isFetchingCreditPointCalculation: false,
  IsUseCreditPoint: false,
  creditPointData: undefined
}

const cartReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_ADD_TO_CART: {
      const productVariantId = state.productVariantId || []
      const cart = state.cart;
      if (action.payload.data.cart.cartCode) {
        localStorage.setItem('cartID', action.payload.data.cart.cartCode);
        if(cart) cart.cartCode = action.payload.data.cart.cartCode;
      }
      if (!productVariantId.find(id => id === action.payload.data.productVariantId)) {
        const data = action.payload.data;
        let addProductVariantId = data.productVariantId;
        if (data.usingDate) {
          addProductVariantId = `${productVariantId}|${data.usingDate}`
        }
        productVariantId.push(addProductVariantId)
      }
      return Object.assign({}, state, {
        currentCart: action.payload.data.cart,
        // notice: true,
        productVariantId: productVariantId,
        cart,
      })
    }
    case actionTypes.FETCHING_COUNT_CART_ITEM: {
      return Object.assign({}, state, {
        count: action.payload.data
      })
    }
    case actionTypes.CLEAR_ADD_TO_CART_NOTICE:
      return Object.assign({}, state, {
        notice: false
      })
    case actionTypes.FETCHING_CART_DETAIL_ACTION: {
      return Object.assign({}, state, {
        cart: null
      })
    }
    case actionTypes.FETCHING_CART_DETAIL_SUCCESS:
      if (action.payload.data.cartCode) {
        localStorage.setItem('cartID', action.payload.data.cartCode);
      }
      return Object.assign({}, state, {
        cart: action.payload.data,
        coupon: state.coupon && merge(state.coupon, {
          // productVariantIds: objectExtensions.getCartProductIds(action.payload.data)
          productVariantIds: action.payload.data
        })
      })
    case actionTypes.DELETE_CART_ITEM_SUCCESS: {
      const {
        currentCart,
        productVariantId,
        currentCount,
        newCoupon,
      } = cartServices.getStateDeleteCartItem({
        currentCart: state.cart,
        currentProductVariantId: state.productVariantId,
        deleteData: action.payload.data,
        coupon: state.coupon,
      })
      return currentCount > 0
        ? Object.assign({}, state, {
          productVariantId: productVariantId,
          cart: currentCart,
          coupon: newCoupon,
        })
        : initState
    }
    case actionTypes.UPDATE_CART_ITEM_SUCCESS: {
      const { currentCart, newCoupon } = cartServices.getStateUpdateCartItem({
        currentCart: state.cart,
        itemUpdate: action.payload.data
      })
      return Object.assign({}, state, {
        cart: currentCart,
        coupon: newCoupon,
      })
    }
    case actionTypes.UPDATE_COUPON: {
      return Object.assign({}, state, {
        coupon: action.payload.data
      })
    }
    case actionTypes.CHECKOUT_CART_SUCCESS:
      return state
    case actionTypes.CLEAR_CART_SUCCESS:
      localStorage.removeItem('cartID');
      return initState
    case actionTypes.START_FETCHING_CREDIT_POINT_CALCULATION:
      return Object.assign({}, state, {
        isFetchingCreditPointCalculation: true,
      })
    case actionTypes.STOP_FETCHING_CREDIT_POINT_CALCULATION:
      return Object.assign({}, state, {
        isFetchingCreditPointCalculation: false,
      })
    case actionTypes.RESET_CREDIT_POINT_CALCULATION_DATA:
      return Object.assign({}, state, {
        isFetchingCreditPointCalculation: false,
        IsUseCreditPoint: false,
        creditPointData: undefined
      })
    case actionTypes.USE_CREDIT_POINT:
      return Object.assign({}, state, {
        IsUseCreditPoint: true,
      })
    case actionTypes.UNUSE_CREDIT_POINT:
      return Object.assign({}, state, {
        IsUseCreditPoint: false,
        isFetchingCreditPointCalculation: false
      })
    case actionTypes.SET_CREDIT_POINT_CALCULATION_DATA:
      return Object.assign({}, state, {
        creditPointData: action.payload.data,
        isFetchingCreditPointCalculation: false
      })
    default:
      return state
  }
}

export default cartReducer