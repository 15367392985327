import React from 'react'
// lib
import clsx from 'clsx'
import PropTypes from 'prop-types'
// component
import { Popover, Button } from 'antd'

const PopoverCustom = ({ visible, customClass, overlayClassName, buttonIcon, buttonCss, buttonName, title, children, handleVisibleChange }) => (
  <Popover
    className={clsx({
      [`${customClass}`]: !!customClass
    })}
    overlayClassName={clsx({
      [`${overlayClassName}`]: !!overlayClassName
    })}
    content={children}
    title={title || null}
    placement={'bottom'}
    trigger={'click'}
    visible={visible}
    onVisibleChange={handleVisibleChange}
  >
    <Button
      onClick={handleVisibleChange}
      className={clsx({
        [`${buttonCss}`]: !!buttonCss
      })}
    >
      {buttonIcon ?
        (
          <span className='g-mr-5'>
          {buttonIcon}
        </span>
        )
        : null}
      <span className='text-truncate vpt-overflow-unset'>
      {buttonName}
      </span>
    </Button>
  </Popover>
)

PopoverCustom.propTypes = {
  title: PropTypes.element,
  children: PropTypes.element,
  visible: PropTypes.bool,
  customClass: PropTypes.string,
  overlayClassName: PropTypes.string,
  buttonIcon: PropTypes.element,
  buttonCss: PropTypes.string,
  buttonName: PropTypes.string.isRequired,
  handleVisibleChange: PropTypes.func
}

export default PopoverCustom