// import {} from "lodash";
export default class IpLocale {
    constructor(data={}) {
        this.ipaddress = data.query || undefined;
        this.country = data.country || undefined;
        this.countryCode = data.countryCode || undefined;
        this.region = data.region || undefined;
        this.regionName = data.regionName | undefined;
        this.city = data.city || undefined;
        this.isp = data.isp || undefined;
        this.lat = data.lat || undefined;
        this.lon = data.lon || undefined;
        this.org = data.org || undefined;
        this.timezone = data.timezone || undefined;
        this.zip = data.zip || undefined;
    }
}