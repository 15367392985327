import React from 'react'
import { connectModal } from 'redux-modal'
import { Button, Modal } from 'antd'
import { FormattedMessage } from 'react-intl.macro'

const NotifyModal = (props) => {

  const {show, handleHide, message, children} = props

  return (
    <Modal
      title={ null }
      footer={ null }
      maskClosable={ false }
      closable={ false }
      wrapClassName='vpt-error-modal'
      visible={ show }
      onOk={ handleHide }
      onCancel={ handleHide }
    >
      {
        children ? children : null
      }
      {
        message ? (
            <div className='text-center vpt-form'>
              <div className='g-mb-15'>
                { message }
              </div>
              <Button
                className='g-bg-primary g-brd-primary g-color-white'
                onClick={ handleHide }
              >
                <FormattedMessage
                  id="Modal.ErrorModal.Buttom.Closed"
                  defaultMessage='Đóng'
                />
              </Button>
            </div>
          )
          : null
      }
    </Modal>
  )
}


export default connectModal ({name: 'notifyModal', destroyOnHide: true}) (NotifyModal)
