import { currencyFormat } from "@utils/currencyFormat";
import { minutesToxhymzs } from "@utils/time";

export class FlightAddToCart {
  constructor(data = {}, extendsData = {}) {
    this.setData(data, extendsData);
  }

  setData(data, extendsData) {
    this.price = currencyFormat(data?.price);
    this.brand = data?.brand;
    this.category = data?.category;
    this.variant = data?.variant;
    this.product_subcategory = data?.dimension30;
    this.flight_duration = minutesToxhymzs(data?.dimension37);
    this.booking_origin = data?.dimension35;
    this.booking_destination = data?.dimension36;
    this.booking_start_date = data?.dimension33;
    this.booking_end_date = data?.dimension34;
    this.booking_no_of_adults = `${extendsData?.booking_no_of_adults}`;
    this.booking_no_of_children = `${extendsData?.booking_no_of_children}`;
    this.booking_no_of_infants = `${extendsData?.booking_no_of_infants}`;
    this.product_url = extendsData?.flightProductUrl;
    this.flight_1_thumbnail_url = extendsData?.flightThumbnailUrlFlight1;
    this.flight_2_thumbnail_url = extendsData?.flightThumbnailUrlFlight2;
  }
}
