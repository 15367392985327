import {axiosProviderCms} from './api/axiosProvider'
import {show} from 'redux-modal'
import actionTypes from '../actionTypes'

import {
  startFetchingAction,
  stopFetchingAction,
  loadSuccess,
  loadFail,
} from './commonAction'
import {getAuthCookies} from '../utils/auth'
import { getConfig } from '@config'

/**
 *
 * @returns {Function}
 */

 const {
  publicRuntimeConfig: {
    RECAPTCHA_KEY
  } // Available both client and server side
} = getConfig()

export const getRatings = (query) => {
  return async (dispatch) => {
    dispatch(startFetchingAction())
    try {
      const response = await axiosProviderCms.get(`/frontend/rating?${query}`)
      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.FETCHING_REVIEW_SUCCESS,
            response.data.data
          )
        )
      } else {
        dispatch(loadFail(response.errors[0].message))
      }
    } catch (errors) {
      // fail
      dispatch(loadFail(errors.response))
    }
    dispatch(stopFetchingAction())
  }
}


export const createRating = (data, executeRecaptcha) => {
  return async (dispatch) => {
    dispatch(startFetchingAction())
    try {
      const {auth} = getAuthCookies()
      let captchaResult
      if (RECAPTCHA_KEY) {
        if (!executeRecaptcha) {
          return
        }
        captchaResult = await executeRecaptcha('rating')
      }
      const response = await axiosProviderCms.post(`/frontend/rating`, {...data, CaptchaToken: captchaResult}, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`
        }
      })
      if (response.status === 201 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.CREATE_REVIEW_SUCCESS,
            response.data,
            null,
            null,
            [
              show('notifyModal', {message: 'Gửi đánh giá thành công'})
            ]
          )
        )
      } else {
        dispatch(loadFail(response.errors[0].message))
      }
    } catch (errors) {
      // fail
      dispatch(loadFail(errors.response))
    }
    dispatch(stopFetchingAction())
  }
}

export const replyRating = (data) => {
  return async (dispatch) => {
    dispatch(startFetchingAction())
    try {
      const response = await axiosProviderCms.post(`/frontend/rating`, {
        ...data,
      })
      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.REPLY_REVIEW_SUCCESS,
            response.data.data
          )
        )
      } else {
        dispatch(loadFail(response.errors[0].message))
      }
    } catch (errors) {
      // fail
      dispatch(loadFail(errors.response))
    }
    dispatch(stopFetchingAction())
  }
}

export default {
  getRatings,
  createRating,
  replyRating
}
