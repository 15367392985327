import actionTypes from '../actionTypes'
import RedirectLink from 'src/model/RedirectLink'

const initState = {
  footers: null,
  redirectInfo: new RedirectLink()
}

const footerReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FOOTER_URL_NAV_LINK:
      return Object.assign({}, state, {
        footers: action.payload.data
      })
    case actionTypes.FETCHING_REDIRECT_LINK_SUCCESS:
      return Object.assign({}, state, {
        redirectInfo: new RedirectLink(action.payload.data)
      })
    default:
      return state
  }
}

export default footerReducer 