import React, { useEffect, useRef } from 'react'
import {
    Switch,
    Route,
    useLocation
  } from "react-router-dom";
import Routes from './Routes'
import gtmPageView from '../../hooks/gtm/gtmPageView';
import { gtmEvent } from 'src/helpers/gtmEvent';
import { getConfig } from '@config/';
const {publicRuntimeConfig: { GA_CODE }} = getConfig()

const RoutesWithLocale = () => {
    // const history = useHistory();
    let location = useLocation();
    const refLocation = useRef(false);

    useEffect(() => {
      const url = window.location;

      if (url.pathname.includes('staynfun')) {
        if (url.pathname.split('/').length === 3 || url.pathname.includes('payment')) {
          gtmPageView({ 
            type: gtmEvent.PAGE_VIEW, 
            dataPage: url,
            isHomePage: true,
          });
        } else if (!url.pathname.includes('search')) {
          gtmPageView({ type: gtmEvent.VIRTUAL_PAGE_VIEW, dataPage: url });
        }
      } else {
        if (refLocation.current) {
          gtmPageView({ type: gtmEvent.VIRTUAL_PAGE_VIEW, dataPage: url });
        } else {
          refLocation.current = true;
        }
      }
    }, [location]);

    return (
        <Switch>
            <Route path={`/:locale`}>
                <Routes />
            </Route>
            <Route path="/">
                <Routes />
            </Route>
        </Switch>
        // <Routes />
    )
}

export default RoutesWithLocale