import { getMomentDate } from "../utils/common";

// import {} from "lodash";
export default class Tour {
    constructor(params) {
        params = params || {};
        this.tourName = params.tourName || params.tourDetail.tourName || undefined;
        this.tourCode = params.tourCode || undefined;
        this.id = params.id || undefined;
        this.departure = params.departure || {};
        this.destination = params.destination || {};
        this.airline = params.airline || undefined;
        this.adultOriginalPrice = params.adultOriginalPrice || undefined;
        this.adultSalePrice = params.adultSalePrice || undefined;
        this.lengthOfTour = params.lengthOfTour || undefined;
        this.urlSlug = params.urlSlug || undefined;
        this.thumbImage = params.thumbImage || undefined;
        this.thumbImageView = params.thumbImageView || {};
        this.isSpecial = params.isSpecial || false;
        this.isHotTour = params.isHotTour || false;
        this.shortDescription = params.shortDescription || null;
        this.slideShowImagesView = params.slideShowImagesView || [];
        this.tourDetail = {};
        this.tourLine = {
            items: []
        };
        this.tourTicketIds = params.tourTicketIds || [];
        this.tag = params.tag || undefined;
        this.tourTypes = params.tourTypes || [];
        this.cutOffDate = params.cutOffDate;
        this.properties = params.properties || [];
        this.saleEndDate = params.saleEndDate || undefined;
        this.saleStartDate = params.saleStartDate || undefined;
        this.stayEndDate = getMomentDate(params.stayEndDate);
        this.stayStartDate = getMomentDate(params.stayStartDate)
        this.installmentFee = params.installmentFee || undefined;
        this.installmentLimit = params.installmentLimit || undefined;
        this.soldQuantity = params.soldQuantity || undefined;
        this.productTypes = params.productTypes || [];
        this.seats = params.seats === 0 ? 0 : (params.seats || undefined);
        this.tourTicket = params?.tourTicket || []
    }
    updateDetail(paramsDetail) {
        this.tourDetail.tourName = paramsDetail.tourName || "";
        this.tourDetail.tourCode = paramsDetail.tourCode || "";
        this.tourDetail.lengthOfTour = paramsDetail.lengthOfTour || "";
        this.tourDetail.highlight = paramsDetail.highlight || "";
        this.tourDetail.tripItinerary = paramsDetail.tripItinerary || [];
        this.tourDetail.serviceIncluded = paramsDetail.serviceIncluded || [];
        this.tourDetail.serviceExcluded = paramsDetail.serviceExcluded || [];
        this.tourDetail.extraInfos = paramsDetail.extraInfos || [];
        this.tourDetail.note = paramsDetail.note || [];
        this.tourDetail.checklist = paramsDetail.checklist || [];
        this.tourDetail.cancellationPolicy = paramsDetail.cancellationPolicy || "";
        this.tourDetail.description = paramsDetail.description || "";
        this.tourDetail.groupSize = paramsDetail.groupSize || "";
        this.tourDetail.hotelRating = paramsDetail.hotelRating || "";
        this.tourDetail.airline = paramsDetail.airline || "";
        this.tourDetail.type = paramsDetail.type || undefined;
        this.tourDetail.siteCode = paramsDetail.siteCode || undefined;
    }
    updateTourLine(tourLineData = {}) {
        this.tourLine.queryFromDate = tourLineData.queryFromDate || undefined;
        this.tourLine.queryToDate = tourLineData.queryToDate || undefined;
        this.tourLine.items = tourLineData.items || [];
    }
}

export class FilterParams {
    constructor(data) {
        const {
            lengthOfTour,
            departure,
            tourTypeId,
            productTypes,
            priceRange = []
        } = data || {};
        const [minPrice, maxPrice] = priceRange;
        
        this.lengthOfTour = lengthOfTour;
        this.departure = departure;
        this.tourTypeId = tourTypeId;
        this.productTypes = productTypes;
        this.minPrice = minPrice;
        this.maxPrice = maxPrice;

    }
}