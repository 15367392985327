import React, { Fragment } from 'react'
import { getConfig } from '@config'

const {
  publicRuntimeConfig: {
    NODE_ENV
  } // Available both client and server side
} = getConfig()

const DEV = NODE_ENV === 'development'

const Ga = ({ code, trackingCode }) => {
  return (
    DEV
      ? null
      : <Fragment>
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${code}');`
          }}
        />
        {/* Global site tag (gtag.js) - Google Analytics */}
        {!!trackingCode && (
          <Fragment>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${trackingCode}`}></script>
            <script dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          
            gtag('config', '${trackingCode}');`
            }}
            />
            {/* Google Analytics */}
            <script dangerouslySetInnerHTML={{
              __html: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject'] = r;i[r]=i[r]||function(){
            (i[r].q = i[r].q || []).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

            ga('create', '${trackingCode}', 'auto');
            ga('send', 'pageview');`
            }}
            />
            {/* End Google Analytics */}
          </Fragment>
        )}
      </Fragment>
  )
}
export default Ga