
import React from 'react'
import { Link } from 'react-router-dom'
import { get, set } from 'lodash'
import Router from '@utils/router'
import { Fragment } from 'react'
import { commonUtils } from '..'
import { useGlobalStore } from 'src/hooks/useGlobal'
import { convertLinkLocale } from '@utils/common'

export default ({
  href,
  as,
  children,
  to,
  component,
  ...restProps
}) => {

  const { state } = useGlobalStore()

  const toPath = as || href;
  const isATag = children?.type === 'a';
  const childProps = isATag ? get(children, 'props') || {} : {};
  const childContent = get(children, 'props.children')
  const linkProps = { ...restProps, ...childProps };

  const handleOnClick = (params = [], onClick) => {
    if (onClick) {
      onClick(...params)
    }
    return handleGoToPath();
  }

  const handleGoToPath = () => {
    const path = convertLinkLocale(state.lang, (to || toPath), state.currencyOnl)
    if (commonUtils.isExternalLink(path)) {
      window.location = path;
      return
    }
    return Router.push(convertLinkLocale(state.lang, (to || toPath), state.currencyOnl))
  }

  if (!isATag) {
    return React.Children.map(children, (child, index) => {
      return React.cloneElement(child, {
        onClick: (...params) => handleOnClick(params, get(child, 'props.onClick')),
      });
    });
  }

  return (
    <Link {...linkProps} to={convertLinkLocale(state.lang, (to || toPath), state.currencyOnl)} component={component} >
      {isATag ? childContent : children}
    </Link>
  )
}