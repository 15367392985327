import objectExtensions from './object'
import { enumType, FORMAT_ONLY_DATE, FORMAT_ONLY_DATE_3S, GUID_EMPTY, routes } from '../constants'
import { hotelUtils } from '../utils'
import moment from 'moment'
import config from "./../config"
import queryString from '@utils/querystring'


export const getParamsFromAsPath = asPath => {
  let params = {}
  const pathAsArray = asPath && asPath.split('?')
  // check has param
  if (pathAsArray && pathAsArray.length > 1) {
    params = queryString.parse(pathAsArray[1], { parseBooleans: true })
  }
  return params
}

export const getHashFromAsPath = asPath => {
  const arr = asPath.split('#');
  return arr.length > 1 ? arr[arr.length - 1] : undefined;
}

export const getFlightQueryString = (flight) => {
  const {
    startPoint,
    endPoint,
    departureDate,
    returnDate,
    passenger,
    clientVia,
    flightFilter,
    searchMonth,
  } = flight
  return `/flight?startPoint=${objectExtensions.getValueObject(startPoint)}&
      endPoint=${objectExtensions.getValueObject(endPoint)}&
      departureDate=${departureDate ? moment(departureDate, FORMAT_ONLY_DATE).format(FORMAT_ONLY_DATE_3S) : ''}&
      returnDate=${returnDate ? moment(returnDate, FORMAT_ONLY_DATE).format(FORMAT_ONLY_DATE_3S) : ''}&
      adult=${passenger && passenger.adult ? passenger.adult : 1}&
      children=${passenger && passenger.child ? passenger.child : 0}&
      infant=${passenger && passenger.infant ? passenger.infant : 0}&
      clientVia=${clientVia ? clientVia : ''}&
      searchMonth=${searchMonth ? 'true' : 'false'}&
      flightFilter=${flightFilter ? flightFilter : ''}`.replace(/\s/g, '')
}

const getBookingQueryString = (dataSession, dataReturnSession, departureFlightSession, returnFlightSession) => {
  return `/booking?dataSession=${dataSession}&dataReturnSession=${dataReturnSession}&departureFlightSession=${departureFlightSession}&returnFlightSession=${returnFlightSession}`
}

const getComboQueryString = (baseUrl, combo) => {
  const {
    flight,
    hotel
  } = combo
  let queryFlight = ``
  let queryHotel = ``
  if (flight) {
    const {
      startPoint,
      endPoint,
      departureDate,
      returnDate,
      passenger
    } = flight
    queryFlight = `fsp=${objectExtensions.getValueObject(startPoint)}&`
    queryFlight += `fep=${objectExtensions.getValueObject(endPoint)}&`
    queryFlight += `fdd=${departureDate ? departureDate : ''}&`
    queryFlight += `frd=${returnDate ? returnDate : ''}&`
    queryFlight += `fa=${passenger.adult ? passenger.adult : 1}&`
    queryFlight += `fc=${passenger.child ? passenger.child : 0}&`
    queryFlight += `fi=${passenger.infant ? passenger.infant : 0}`

  }
  if (hotel) {
    const {
      departureDate,
      lengthOfStay,
      dateAdd,
      numberOfRoom,
      passenger,
      startPoint,
      startPointName
    } = hotel
    let keyword = startPointName
    let locationId = ''
    let hotelId = ''
    if (startPoint) {
      if (startPoint.type === enumType.locationType.Hotel) {
        keyword = startPoint.hotelName
        hotelId = startPoint.hotelId
      } else {
        keyword = startPoint.locationName
      }
      locationId = startPoint.locationId
    }
    queryHotel = `locationId=${locationId || ''}&`
    queryHotel += `hotelId=${hotelId || ''}&`
    queryHotel += `hsp=${keyword || ''}&`
    queryHotel += `hdd=${departureDate ? departureDate : ''}&`
    queryHotel += `hr=${numberOfRoom ? numberOfRoom : 1}&`
    queryHotel += `hs=${lengthOfStay ? lengthOfStay : 1}&`
    queryHotel += `hs=${dateAdd ? dateAdd : 0}&`
    queryHotel += `ha=${passenger.adult ? passenger.adult : 1}&`
    queryHotel += `hc=${passenger.child ? passenger.child : 0}&`
    queryHotel += `hi=${passenger.infant ? passenger.infant : 0} `
  }
  return `${baseUrl}?${queryFlight}&${queryHotel}`
}

const getBundleQueryString = (baseUrl, bundle) => {
  const {
    startPoint,
    startPointName,
    endPoint,
    endPointName,
    departureDate,
    returnDate,
    passenger = [],
    numberOfRoom,
    roomOccupancy,
    promotionCode
  } = bundle
  let queryString = '';
  if (bundle) {
    queryString += `startPointId=${startPoint ? startPoint.id || '' : ''}&`
    queryString += `startAirportCode=${startPoint ? startPoint.airportCode || '' : ''}&`
    queryString += `startPointName=${encodeURI(startPoint && startPoint.name ? startPoint.name : startPointName || '')}&`
    queryString += `endPointId=${endPoint ? endPoint.id || '' : ''}&`
    queryString += `endAirportCode=${endPoint ? endPoint.airportCode || '' : ''}&`
    queryString += `endPointName=${encodeURI(endPoint && endPoint.name ? endPoint.name : endPointName || '')}&`
    queryString += `departureDate=${departureDate ? moment(departureDate, FORMAT_ONLY_DATE).format(FORMAT_ONLY_DATE_3S) : ''}&`
    queryString += `returnDate=${returnDate ? moment(returnDate, FORMAT_ONLY_DATE).format(FORMAT_ONLY_DATE_3S) : ''}&`
    // queryString += `adult=${passenger.adult ? passenger.adult : 1}&`
    // queryString += `child=${passenger.child ? passenger.child : 0}&`
    // queryString += `infant=${passenger.infant ? passenger.infant : 0}&`
    // queryString += `roomOccupancy=${ !passenger ? roomOccupancy : (passenger ? hotelUtils.stringifyPassenger(passenger) : '1_0_0')}&`
    queryString += `roomOccupancy=${(passenger ? hotelUtils.stringifyPassenger(passenger) : '1_0_0')}&`
    queryString += `room=${numberOfRoom || 1}&`
    queryString += `promotionCode=${promotionCode || ''}`
  }
  return `${baseUrl}?${queryString}`
}

const getBundleCartQueryString = (baseUrl, bundle) => {
  const {
    DataSession,
    HotelId,
    RoomTypeId,
    RatePlanId,
    DepartSession,
    ReturnSession,
    DepartFareOptionSession,
    ReturnFareOptionSession,
    RoomRates,
    RoomOccupancy
  } = bundle
  let queryString = '';
  if (bundle) {
    queryString += `dataSession=${DataSession ? DataSession || '' : ''}&`
    queryString += `hotelId=${HotelId ? HotelId || '' : ''}&`
    queryString += `roomTypeId=${RoomTypeId ? RoomTypeId || '' : ''}&`,
      queryString += `ratePlanId=${RatePlanId ? RatePlanId || '' : ''}&`
    queryString += `departSession=${DepartSession ? DepartSession || '' : ''}&`
    queryString += `returnSession=${ReturnSession ? ReturnSession : ''}&`
    queryString += `departFareOptionSession=${DepartFareOptionSession ? DepartFareOptionSession || '' : ''}&`
    queryString += `returnFareOptionSession=${ReturnFareOptionSession ? ReturnFareOptionSession : ''}&`
    queryString += `roomRates=${RoomRates ? RoomRates : ''}&`,
    queryString += `roomOccupancy=${RoomOccupancy ? RoomOccupancy : '1_0_0'}`
  }
  return `${baseUrl}?${queryString}`
}
const getBundleBookingPaymentQueryString = (baseUrl, bundle) => {
  const {
    DataSession,
    HotelId,
    RoomTypeId,
    RatePlanId,
    DepartSession,
    ReturnSession,
    DepartFareOptionSession,
    ReturnFareOptionSession,
    RoomRates,
    RoomOccupancy,
    PromotionCode
  } = bundle
  let queryString = '';
  if (bundle) {
    queryString += `dataSession=${DataSession ? DataSession || '' : ''}&`
    queryString += `hotelId=${HotelId ? HotelId || '' : ''}&`
    queryString += `roomTypeId=${RoomTypeId ? RoomTypeId || '' : ''}&`
    queryString += `ratePlanId=${RatePlanId ? RatePlanId || '' : ''}&`
    queryString += `departSession=${DepartSession ? DepartSession || '' : ''}&`
    queryString += `returnSession=${ReturnSession ? ReturnSession : ''}&`
    queryString += `departFareOptionSession=${DepartFareOptionSession ? DepartFareOptionSession || '' : ''}&`
    queryString += `returnFareOptionSession=${ReturnFareOptionSession ? ReturnFareOptionSession : ''}&`,
    queryString += `roomRates=${RoomRates ? RoomRates : ''}&`,
    queryString += `roomOccupancy=${RoomOccupancy ? RoomOccupancy : ''}&`,
    queryString += `promotionCode=${PromotionCode ? PromotionCode : ''}`
  }
  return `${baseUrl}?${queryString}`
}

const getBundleQueryStringWithHotel = (baseUrl, hotelId, params) => {
  let queryString = ''
  if (params) {
    queryString = `dataSession=${params.dataSession || ''}&`
    queryString += `roomOccupancy=${params.roomOccupancy || ''}`
  }
  if (params?.room) {
    queryString += `&room=${params?.room || ''}`
  }
  return `${baseUrl}/${hotelId}?${queryString}`
}

const getBundleQueryParamsWithHotel = (baseUrl, hotelId, params) => {
  let queryString = ''
  if (params) {
    queryString = `dataSession=${params.dataSession || ''}&`
    queryString += `roomOccupancy=${params.roomOccupancy || ''}`
  }
  return {
    pathNameCombo: `${baseUrl}/${hotelId}`,
    searchCombo: `?${queryString}`
  }
}

const getBundleQueryStringFlight = (baseUrl, params) => {
  let queryString = ''
  const roomRates = typeof (params.roomRates) === 'string' ? params.roomRates : params.roomRates.join(',')
  if (params) {
    queryString = `dataSession=${params.dataSession || ''}&`
    queryString += `hotelId=${params.hotelId || ''}&`
    // queryString += `roomTypeId=${params.roomTypeId || ''}&`
    // queryString += `ratePlanId=${params.ratePlanId || ''}`
    queryString += `roomRates=${roomRates || ''}&`,
    queryString += `roomOccupancy=${params.roomOccupancy || ''}`
  }
  if (params?.room) {
    queryString += `&room=${params?.room || ''}`
  }
  return `${baseUrl}/flight?${queryString}`
}

const getComboQueryStringWithHotel = (baseUrl, hotelId, params, hotelName = '', anotherParams = {}) => {
  let queryString = ''
  const stringAnotherParams = Object.keys(anotherParams)
    .map(key => `${key}=${anotherParams[key]}`)
    .join('&');
  if (params) {
    // queryString = `fsp=${params.fsp || ''}&`
    // queryString += `fep=${params.fep || ''}&`
    // queryString += `fdd=${params.fdd || ''}&`
    // queryString += `frd=${params.frd || ''}&`
    // queryString += `fa=${params.fa || ''}&`
    // queryString += `fc=${params.fc || ''}&`
    // queryString += `fi=${params.fi || ''}&`
    queryString = !!stringAnotherParams ? `${stringAnotherParams}&` : '',
    queryString += `hsp=${encodeURIComponent(hotelName || params.hsp || '') || ''}&`
    queryString += `locationId=${params.locationId || ''}&`
    queryString += `hotelId=${hotelId || ''}&`
    queryString += `arrivalDate=${params.arrivalDate || ''}&`
    queryString += `dateAdd=${params.dateAdd || 0}&`
    queryString += `numberOfRoom=${params?.roomOccupancy.split(',').length || ''}&`
    queryString += `lengthOfStay=${params.lengthOfStay || ''}&`
    // queryString += `ha=${params.ha || ''}&`
    // queryString += `hc=${params.hc || ''}&`
    // queryString += `hi=${params.hi || ''}&`
    queryString += `source=${params.source || ''}&`
    queryString += `deviceType=${params.deviceType || ''}&`
    queryString += `sourceRefId=${params.sourceRefId || ''}&`
    queryString += `channel=${params.channel || ''}&`
    queryString += `roomOccupancy=${params.roomOccupancy || ''}&`
    queryString += `promotionCode=${params.promotionCode || ''}&`
    queryString += `roomtype=${params.roomType || ''}&`
    queryString += `ratecode=${params.rateCode || ''}&`
    queryString += `packagerateid=${params.packageRateId || ''}&`
    queryString += `redirectFromListing=${params.redirectFromListing || ''}&`
    queryString += `isBestPrice=${params.isBestPrice || ''}`
  }
  return `${baseUrl}/${hotelId}?${queryString}`
}

const getComboQueryStringWithFlight = (params) => {
  let queryString = ''
  if (params) {
    queryString = `fsp=${params.fsp || ''}&`
    queryString += `fep=${params.fep || ''}&`
    queryString += `fdd=${params.fdd || ''}&`
    queryString += `frd=${params.frd || ''}&`
    queryString += `fa=${params.fa || ''}&`
    queryString += `fc=${params.fc || ''}&`
    queryString += `fi=${params.fi || ''}&`
    queryString += `hsp=${params.hsp || ''}&`
    queryString += `locationId=${params.locationId || ''}&`
    queryString += `hotelId=${params.hotelId || ''}&`
    queryString += `hdd=${params.hdd || ''}&`
    queryString += `hr=${params.hr || ''}&`
    queryString += `hs=${params.hs || ''}&`
    queryString += `ha=${params.ha || ''}&`
    queryString += `hc=${params.hc || ''}&`
    queryString += `hi=${params.hi || ''} `
  }
  return `${routes.COMBO_FLIGHT}?${queryString}`
}

const getHotelQueryString = (data) => {

  const {
    hotel
  } = data

  let queryHotel = ``
  if (hotel) {
    const {
      departureDate,
      lengthOfStay,
      dateAdd = 0,
      numberOfRoom,
      passenger,
      startPoint,
      startPointName,
      promotionCode,
      source = '',
      deviceType = '',
      sourceRefId = '',
      channel = ''
    } = hotel
    let keyword = startPointName
    let locationId = ''
    let hotelId = ''
    if (startPoint) {
      if (startPoint?.hotelId && startPoint?.hotelId !== GUID_EMPTY) {
        keyword = startPoint.hotelName
        hotelId = startPoint.hotelId
      } else {
        keyword = startPoint.locationName
      }
      locationId = startPoint.locationId
    }
    queryHotel = `locationId=${locationId}&`
    queryHotel += `hotelId=${hotelId}&`
    queryHotel += `hsp=${encodeURIComponent(keyword || '')}&`
    queryHotel += `arrivalDate=${departureDate ? moment(departureDate, FORMAT_ONLY_DATE).format(FORMAT_ONLY_DATE_3S) : ''}&`
    queryHotel += `dateAdd=${dateAdd ? dateAdd : 0}&`
    queryHotel += `numberOfRoom=${numberOfRoom ? numberOfRoom : 1}&`
    queryHotel += `lengthOfStay=${lengthOfStay ? lengthOfStay : 1}&`
    // queryHotel += `ha=${passenger.adult ? passenger.adult : 1}&`
    // queryHotel += `hc=${passenger.child ? passenger.child : 0}&`
    // queryHotel += `hi=${passenger.infant ? passenger.infant : 0}&`
    queryHotel += `roomOccupancy=${hotelUtils.stringifyPassenger(passenger)}&`
    queryHotel += `source=${source}&`
    queryHotel += `deviceType=${deviceType}&`
    queryHotel += `sourceRefId=${sourceRefId}&`
    queryHotel += `channel=${channel}&`
    queryHotel += `promotionCode=${promotionCode}`
  }
  return `/hotel/search?${queryHotel}`
}

const getHotelQueryStringWithRoom = (hotelId, roomTypeID, ratePlanId, params) => {
  let queryString = ''
  hotelId ? queryString += `hId=${hotelId}&` : ``
  roomTypeID ? queryString += `rTId=${roomTypeID}&` : ``
  ratePlanId ? queryString += `rPId=${ratePlanId}&` : ``

  if (params) {
    queryString += `locationId=${params.locationId || ''}&`
    queryString += `hdd=${params.hdd || ''}&`
    queryString += `hrd=${params.hrd || ''}&`
    queryString += `hr=${params.hr || ''}&`
    queryString += `hs=${params.hs || ''}&`
    queryString += `ha=${params.ha || ''}&`
    queryString += `hc=${params.hc || ''}&`
    queryString += `hi=${params.hi || ''} `
  }
  return `/hotel/cart?${queryString}`
}

const getComboQueryStringWithStaynfun = (baseUrl, hotelId, params, hotelName = '', anotherParams = {}) => {
  let queryString = ''
  const stringAnotherParams = Object.keys(anotherParams)
    .map(key => `${key}=${anotherParams[key]}`)
    .join('&');
  if (params) {
    queryString = !!stringAnotherParams ? `${stringAnotherParams}&` : '',
    queryString += `hsp=${encodeURIComponent(hotelName || params.hsp || '') || ''}&`
    queryString += `locationId=${params.locationId || ''}&`
    queryString += `hotelId=${hotelId || ''}&`
    queryString += `arrivalDate=${params.arrivalDate || ''}&`
    queryString += `dateAdd=${params.dateAdd || 0}&`
    queryString += `numberOfRoom=${params.roomOccupancy.split(',').length || ''}&`
    queryString += `lengthOfStay=${params.lengthOfStay || ''}&`
    queryString += `source=${params.source || ''}&`
    queryString += `deviceType=${params.deviceType || ''}&`
    queryString += `sourceRefId=${params.sourceRefId || ''}&`
    queryString += `channel=${params.channel || ''}&`
    queryString += `roomOccupancy=${params.roomOccupancy || ''}&`
    queryString += `promotionCode=${params.promotionCode || ''}&`
    queryString += `roomtype=${params.roomType || ''}&`
    queryString += `ratecode=${params.rateCode || ''}&`
    queryString += `packagerateid=${params.packageRateId || ''}&`
    queryString += `redirectFromListing=${params.redirectFromListing || ''}`
  }
  return `${baseUrl}/${hotelId}?${queryString}`
}

const getStaynfunQueryString = (data) => {
  const {
    homestay
  } = data

  let queryHomestay = ``
  if (homestay) {
    const {
      departureDate,
      lengthOfStay,
      dateAdd = 0,
      numberOfRoom,
      passenger,
      startPoint,
      startPointName,
      promotionCode,
      source = '',
      deviceType = '',
      sourceRefId = '',
      channel = ''
    } = homestay
    let keyword = startPointName
    let locationId = ''
    let hotelId = ''
    if (startPoint) {
      if (startPoint?.hotelId && startPoint?.hotelId !== GUID_EMPTY) {
        keyword = startPoint.hotelName
        hotelId = startPoint.hotelId
      } else {
        keyword = startPoint.locationName
      }
      locationId = startPoint.locationId
    }
    queryHomestay = `locationId=${locationId}&`
    queryHomestay += `hotelId=${hotelId}&`
    queryHomestay += `hsp=${encodeURIComponent(keyword || '')}&`
    queryHomestay += `arrivalDate=${departureDate ? moment(departureDate, FORMAT_ONLY_DATE).format(FORMAT_ONLY_DATE_3S) : ''}&`
    queryHomestay += `dateAdd=${dateAdd ? dateAdd : 0}&`
    queryHomestay += `numberOfRoom=${numberOfRoom ? numberOfRoom : 1}&`
    queryHomestay += `lengthOfStay=${lengthOfStay ? lengthOfStay : 1}&`
    queryHomestay += `roomOccupancy=${hotelUtils.stringifyPassenger(passenger)}&`
    queryHomestay += `source=${source}&`
    queryHomestay += `deviceType=${deviceType}&`
    queryHomestay += `sourceRefId=${sourceRefId}&`
    queryHomestay += `channel=${channel}&`
    queryHomestay += `promotionCode=${promotionCode}`
  }
  return `/staynfun/search?${queryHomestay}`
}

const getStaynfunQueryStringWithRoom = (hotelId, roomTypeID, ratePlanId, params) => {
  let queryString = ''
  hotelId ? queryString += `hId=${hotelId}&` : ``
  roomTypeID ? queryString += `rTId=${roomTypeID}&` : ``
  ratePlanId ? queryString += `rPId=${ratePlanId}&` : ``

  if (params) {
    queryString += `locationId=${params.locationId || ''}&`
    queryString += `hdd=${params.hdd || ''}&`
    queryString += `hrd=${params.hrd || ''}&`
    queryString += `hr=${params.hr || ''}&`
    queryString += `hs=${params.hs || ''}&`
    queryString += `ha=${params.ha || ''}&`
    queryString += `hc=${params.hc || ''}&`
    queryString += `hi=${params.hi || ''} `
  }
  return `/staynfun/cart?${queryString}`
}

const getBookingComboQueryString = (dataSession, dataReturnSession, departureFlightSession, returnFlightSession) => {
  return `${routes.COMBO_BOOKING}?dataSession=${dataSession}&dataReturnSession=${dataReturnSession}&departureFlightSession=${departureFlightSession}&returnFlightSession=${returnFlightSession}`
}

const getCartComboQueryString = (dataSession, dataReturnSession, departureFlightSession, returnFlightSession) => {
  return `${routes.COMBO_BOOKING}?dataSession=${dataSession}&dataReturnSession=${dataReturnSession}&departureFlightSession=${departureFlightSession}&returnFlightSession=${returnFlightSession}`
}

const getVoucherQueryString = (params) => {
  objectExtensions.deleteEmptyProps(params)
  const queryClause = queryString.stringify(params)
  const searchClause = queryClause === ''
    ? ''
    : `?${queryClause}`
  return `/voucher/search${searchClause}`

}

const getListingQueryString = (params) => {
  objectExtensions.deleteEmptyProps(params)
  const queryClause = queryString.stringify(params)
  const searchClause = queryClause === ''
    ? ''
    : `?${queryClause}`
  return `/tours-and-experiences/search${searchClause}`

}

const getVinWonderQueryString = (params) => {
  objectExtensions.deleteEmptyProps(params)
  const queryClause = queryString.stringify(params)
  const searchClause = queryClause === ''
    ? ''
    : `?${queryClause}`
  return `/experience/search${searchClause}`

}
const getVinWonderDetailSlug = (urlSlug, params = {}) => {
  objectExtensions.deleteEmptyProps(params)
  const queryClause = queryString.stringify(params)
  const searchClause = queryClause === ''
    ? ''
    : `?${queryClause}`
  return `/experience/${urlSlug}${searchClause}`
}

const getTourQueryString = (params) => {
  objectExtensions.deleteEmptyProps(params)
  const queryClause = queryString.stringify(params)
  const searchClause = queryClause === ''
    ? ''
    : `?${queryClause}`
  return `${routes.TOUR}${searchClause}`

}

const isStaticPath = (path) => {
  return !!path && (path.match(/^\/(_next|static)/) ||
    path.match(/\.(js|map|css|scss)$/))
}

export const getAuthErrorPagePath = () => {
  const params = {
    statusCode: 403
  };
  return `${routes.ERROR}?${queryString.stringify(params)}`;
}

export const getLoginAuth0 = (redirectUri) => {
  const envConfig = {
    response_type: config.AUTH0_RESPONSE_TYPE,
    scope: "openid profile email",
    client_id: config.AUTH0_CLIENT_ID,
    redirect_uri: queryString.stringifyUrl({ url: config.AUTH0_REDIRECT_URL, query: { redirectUri } }),
    state: config.AUTH0_STATE,
    pnl: 'vinpearl'
  };
  return `${config.AUTH0_LOGOUT}${routes.LOGIN_VPT}`;
};

export const getLoginVpt = (redirectUri) => {
  const envConfig = {
    callback: redirectUri
  };
  return `${window.location.origin}${routes.LOGIN_VPT}?${queryString.stringify(envConfig)}`;
};

export const getRegisterAuth0 = (redirectUri) => {
  const envConfig = {
    response_type: config.AUTH0_RESPONSE_TYPE,
    scope: "openid profile email",
    client_id: config.AUTH0_CLIENT_ID,
    redirect_uri: queryString.stringifyUrl({ url: config.AUTH0_REDIRECT_URL, query: { redirectUri } }),
    state: config.AUTH0_STATE,
    pnl: 'vinpearl'
  };
  return `${config.AUTH0_LOGOUT}${routes.LOGIN_VPT}?${queryString.stringify(envConfig)}&type=register`;
};
export const getLogoutAuth0 = (redirectUri) => {
  const envConfig = {
    client_id: config.AUTH0_CLIENT_ID,
    returnTo: redirectUri
      ? queryString.stringifyUrl({ url: config.AUTH0_LOGOUT, query: { redirectUri } })
      : config.AUTH0_LOGOUT,
  };
  return `${config.AUTH0_URL}${routes.LOGOUT}?${queryString.stringify(envConfig)}`;
};

export default {
  getParamsFromAsPath,
  getHashFromAsPath,
  getFlightQueryString,
  getBookingQueryString,
  getComboQueryString,
  getBundleQueryString,
  getBundleCartQueryString,
  getBundleQueryStringWithHotel,
  getBundleQueryStringFlight,
  getComboQueryStringWithHotel,
  getBundleQueryParamsWithHotel,
  getComboQueryStringWithFlight,
  getHotelQueryString,
  getHotelQueryStringWithRoom,
  getComboQueryStringWithStaynfun,
  getStaynfunQueryString,
  getStaynfunQueryStringWithRoom,
  getBookingComboQueryString,
  getVoucherQueryString,
  getVinWonderQueryString,
  getVinWonderDetailSlug,
  getBundleBookingPaymentQueryString,
  getTourQueryString,
  isStaticPath,
  getListingQueryString,
  getLoginAuth0,
  getRegisterAuth0,
  getLogoutAuth0,
  getAuthErrorPagePath,
}
