import React, { Fragment, useEffect } from 'react'
import { getConfig } from '@config'
import { chatbotVinpearlInfo } from '../../constants/enumType'

const {
  publicRuntimeConfig: {
    NODE_ENV
  } // Available both client and server side
} = getConfig()


const DEV = NODE_ENV === 'development'

let loaded = false

const md_width = 720;

const Chatbot = () => {

    useEffect(() => {
        if(!DEV) {
          if(!loaded) {
            const agent = window.innerWidth < md_width ? chatbotVinpearlInfo.agent_mobile : chatbotVinpearlInfo.agent
            const baseUrl = chatbotVinpearlInfo.baseUrl
            const ws_host = chatbotVinpearlInfo.ws_host
            const widgetConfig = chatbotVinpearlInfo.widgetConfig
            const script = document.createElement('script')
            script.src = baseUrl + '/livechat/main.min.js'
            script.async = true;
            var c = document.getElementsByTagName('body')[0]
            c.appendChild(script)
            script.onload = function() {
                AIPChatBox.init({
                    agent,
                    baseUrl,
                    ws_host,
                    widgetConfig
                })
            }
            loaded = true
          }
        }
    }, [])
  return (
    // DEV
    // ? null
    // : <Fragment>
        // <script type="text/javascript" dangerouslySetInnerHTML={{
        //   __html: `(function() {
        //     console.log('connect')
        //     const agent = ${JSON.stringify(chatbotVinpearlInfo.agent)}
        //     const baseUrl = '${chatbotVinpearlInfo.baseUrl}'
        //     const ws_host = '${chatbotVinpearlInfo.ws_host}'
        //     const widgetConfig = ${JSON.stringify(chatbotVinpearlInfo.widgetConfig)}
        //     const r = document.createElement('script')
        //     r.src = baseUrl + '/livechat/main.min.js'
        //     var c = document.getElementsByTagName('body')[0]
        //     c.appendChild(r)
        //     r.onload = function() {
        //       AIPChatBox.init({
        //         agent,
        //         baseUrl,
        //         ws_host,
        //         widgetConfig
        //       })
        //     }
        //   })()`
        // }}>
        // </script>
        <Fragment>
        </Fragment>
    //   </Fragment>
  )
}

export default Chatbot