import { axiosProviderHotel } from './api/axiosProvider'
//actions
import actionTypes from '../actionTypes'
//utils
//common
import {
  loadSuccess,
  loadFail,
  startFetchingAction,
  stopFetchingAction,
} from './commonAction'
import { bundleServices, flightServices } from '../services'
import { modalUtils } from './../utils'
import Bundle from '../model/Bundle'
import { bundleDetailResponse } from '../constants/fakedata'
import { omit, get, set, cloneDeep, merge } from 'lodash'
import { enumType, LOCAL_STORAGE_KEY } from '../constants'
import { gtmUtils, LocalStorage, SessionStorage } from '../utils'
import Coupon from '../model/Coupon'
import { COUPON } from '../constants/enumType';
import flightAction from './flightAction';

export const getLocations = (keyword = '', type = '') => {
  return async (dispatch) => {
    try {
      let prefix = (type === "returnPoints" ? "domestic" : "all")
      let queryParam = {};
      if(keyword?.trim()?.length){
        queryParam.keyword = keyword.trim();
      }
      const response = await axiosProviderHotel.get(`/api/bundle/locations/${prefix}`, { params: queryParam})

      if (response.status === 200 && !response.errors) {
        const locations = response.data;
        dispatch(
          loadSuccess(
            actionTypes.FETCHING_BUNDLE_LOCATION_SUCCESS,
            { locations, type }
          )
        )
      } else {
        dispatch(loadFail(response.errors[0].message))
      }
    } catch (errors) {
      // fail
      dispatch(loadFail(errors.response))
    }
  }
}

export const setLocations = (locations = [], type = '') => {
  return async (dispatch) => {
    return dispatch(
      loadSuccess(
        actionTypes.FETCHING_BUNDLE_LOCATION_SUCCESS,
        { locations, type }
      )
    )
  }
}

const getBundles = (query) => {
  return async (dispatch, getState) => {
    let returnValue = null;
    const setBundleData = (bundleData = [], dataSession = null, bundleParams = null) => {
      returnValue = cloneDeep({ bundleData, dataSession, bundleParams });
      dispatch(loadSuccess(
        actionTypes.FETCHING_BUNDLE_SEARCH_SUCCESS, { bundleData, dataSession, bundleParams }
      ))
    }

    // dispatch(startFetchingAction())
    dispatch(setSearchBundleParams(bundleServices.getBundleParamsFromData(query)))
    dispatch(startFetchingBundleAction())
    dispatch(resetCheckCodeBundle())
    try {
      const endpoint = '/api/bundle/v2/search';
      const params = bundleServices.initBundleListData(query);
      // dispatch(setSearchBundleParams(query))
      setBundleData();
      const response = await axiosProviderHotel.get(endpoint, { params })
      // console.log('===== getBundles', response)
      if (response.status === 200 && !response.errors) {
        const bundleData = (response.data.data.bundleData || []); // TODO: mapping model here
        const dataSession = response.data.data.dataSession;
        const searchParams = bundleServices.getBundleParamsFromData({ ...query, ...response.data.data })
        dispatch(setSearchBundleParams(searchParams))
        setBundleData(bundleData, dataSession, searchParams);
        const hotels = bundleData.map(item => gtmUtils.dataLayerParsers.bundleHotels(item))
        gtmUtils.pushGtmDatalayerHotel({ hotels, params: searchParams }, 'productImpressions');
      } else {
        dispatch(loadFail(response.errors[0].message), undefined, undefined, { promotionCode: query.promotionCode || ''})
      }
    } catch (errors) {
      dispatch(setSearchBundleParams(bundleServices.getBundleParamsFromData(query)))
      if (get(errors, 'response.data.errors[0].code') !== 'NOT_FOUND') {
        dispatch(loadFail(errors.response, undefined, undefined, { promotionCode: query.promotionCode || ''}))
      }
    }
    // dispatch(stopFetchingAction())
    dispatch(stopFetchingBundleAction())
    return returnValue;
  }
}

const getHotelBundle = (query) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingAction())
    dispatch(flightAction.resetBookingMarkupError())
    let returnData = null;
    const setData = (query = null) => {
      returnData = cloneDeep(query)
      dispatch(loadSuccess(
        actionTypes.FETCHING_BUNDLE_HOTEL_SUCCESS, query
      ))
    }

    const { search } = getState()
    try {
      const endpoint = '/api/bundle/v2/hotel';
      const params = { dataSession: query.dataSession, hotelId: query.hotelId }
      const response = await axiosProviderHotel.get(endpoint, { params })
      if (response.status === 200 && !response.errors) {
        const bundleParams = bundleServices.getBundleParamsFromData(response.data.data)
        bundleParams.roomOccupancy = search.roomOccupancy;
        returnData = cloneDeep(response.data.data.bundleData);
        const currencyOnl = LocalStorage.get(LOCAL_STORAGE_KEY.CURRENCY_WESBITE)?.data;
        setData({...response.data.data.bundleData, currencyOnl})
        dispatch(setSearchBundleParams(Object.assign(bundleParams, search.bundle)))
        const hotels = gtmUtils.dataLayerParsers.bundleHotels([response.data.data.bundleData])
        gtmUtils.pushGtmDatalayerHotel({ hotels, params: bundleParams }, 'productDetail', 'hotel', 'detail')
      } else {
        setData()
        dispatch(loadFail(response.errors[0].message))
      }
    } catch (errors) {
      console.log("errorsGetHotelBundle",errors);
      setData()
      dispatch(loadFail(errors.response))
    }
    dispatch(stopFetchingAction())
    return returnData;
  }
}

const getBundleDetail = (data) => {
  return async (dispatch, getState) => {
    let returnValue = {};
    const setBundleData = (bundleData = null) => {
      returnValue = cloneDeep(bundleData) || {}
      dispatch(loadSuccess(
        actionTypes.FETCHING_BUNDLE_DETAIL_SUCCESS, bundleData
      ))
    }
    // dispatch(resetCheckCodeBundle())
    dispatch(startFetchingAction())
    try {
      const endpoint = '/api/bundle/v2/detail';
      const params = bundleServices.initBundleDetailData(data);
      setBundleData();
      const response = await axiosProviderHotel.get(endpoint, { params })
      // console.log('===== getBundleDetail', response)
      if (response.status === 200 && !response.errors) {
        const bundleExtendData = omit(response.data.data, 'bundleData')
        const bundleData = new Bundle({...response.data.data.bundleData, promotionCode: response.data.data.promotionCode} || undefined, bundleExtendData);
        const bundleParams = bundleServices.getBundleParamsFromData(response.data.data)
        setBundleData(bundleData);
        // dispatch(setSearchBundleParams(bundleParams))
      } else {
        dispatch(loadFail(response.errors[0].message))
        setBundleData()
      }
    } catch (errors) {
      // console.warn('===== getBundleDetail Error', errors.response.errors[0].message)
      dispatch(loadFail(errors.response))
      setBundleData()
    }
    dispatch(stopFetchingAction())
    return returnValue;
  }
}

/**
 * load flight detail
 * @param obj
 * @returns {Function}
 */
const verifyBundleDetail = (obj, loadingOnSuccess = true) => {
  return async (dispatch, getState) => {
    // if (getState().fetching.isLoading) return;
    dispatch(startFetchingAction())
    dispatch(
      loadSuccess(
        actionTypes.FETCHING_FLIGHT_VERIFY_DETAIL_SUCCESS,
        null
      )
    )
    let isSuccess = false;
    try {
      const response = await axiosProviderHotel.get(`/api/bundle/v2/verify-flight`, { params: obj })
      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.FETCHING_FLIGHT_VERIFY_DETAIL_SUCCESS,
            response.data
          )
        )
        isSuccess = true;
      } else {
        dispatch(loadFail(response.errors[0].message))
      }
    } catch (errors) {
      // fail
      const errorGet = get(errors, 'response.data.errors[0].code');
      if (errorGet  === enumType.apiErrorCode.PRICES_HAD_CHANGED){ 
        dispatch(loadSuccess(
          actionTypes.PRICE_CHANGED_CONFIRM,
          {...get(errors, 'response.data.errors[0]'), value: '{"newTotalOrderPrice":1}'}, //for show modal search again
        ))
      } else if(errorGet === enumType.apiErrorCode.MRU_BUNDLE_PRICES_HAD_CHANGED) {
        dispatch(loadSuccess(
          actionTypes.SET_BOOKING_BUNDLE_ERROR,
          errors.response.data.errors[0]
        ))

        dispatch(stopFetchingAction())

        return setTimeout(() => {
          dispatch(loadSuccess(
            actionTypes.SET_BOOKING_BUNDLE_ERROR,
            null
          ))
        }, 1000)
      } else if (get(errors, 'response.data.errors[0].code') === 'NOT_FOUND') {
        set(errors, 'response.data.errors[0].code', enumType.apiErrorCode.FLIGHT_SESSION_NOT_FOUND)
      }
      // if(errorGet === enumType.apiErrorCode.MKR_PROMOTION_NOT_APPLY_FOR_LIST_HOTEL_REQUEST || 
      //   errorGet === enumType.apiErrorCode.MKR_PROMOTION_NOT_APPLY_FOR_LIST_RATE_REQUEST || 
      //   errorGet === enumType.apiErrorCode.CHECK_PROMOTION_CODE_FAILED
      // ){
      //   dispatch(stopFetchingAction())
      //   return dispatch(loadSuccess(
      //     actionTypes.SET_BOOKING_BUNDLE_PROMOTION_MARKUP_ERROR,
      //     errorGet === enumType.apiErrorCode.CHECK_PROMOTION_CODE_FAILED ? 'notFound' : 'invalid'
      //   ))
      // }
      // console.warn('===== verifyFlightDetail Error', errors.response)
      else {
        dispatch(loadFail(errors.response))
      }
    }
    if (loadingOnSuccess || !isSuccess) dispatch(stopFetchingAction())
  }
}

const getFlightBundle = (data) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingAction())
    let returnValue = null;
    const { search } = getState()
    try {
      const endpoint = '/api/bundle/v2/flight';
      const params = { DataSession: data.dataSession, HotelId: data.hotelId, RoomRates: data.roomRates }
      const response = await axiosProviderHotel.get(endpoint, { params })
      if (response.status === 200 && !response.errors) {
        const bundleParams = bundleServices.getBundleParamsFromData(response.data.data)
        returnValue = response.data.data || null;
        dispatch(loadSuccess(
          actionTypes.FETCHING_BUNDLE_FLIGHT_SUCCESS,
          response.data.data.bundleData
        ))
        // dispatch(setSearchBundleParams(search.bundle))
        const flightData = gtmUtils.dataLayerParsers.bundleFlight(get(response, 'data.data.bundleData'))
        gtmUtils.pushGtmDatalayerFlight(flightData, 'productImpressions')
      } else {
        dispatch(loadFail(response.errors[0].message))
      }
    } catch (errors) {
      dispatch(loadFail(errors.response))
    }
    dispatch(stopFetchingAction())
    return returnValue;
  }
}

export const getFlightHotelBundle = () => {
  return async (dispatch) => {
    dispatch(startFetchingAction())
    let data = [];
    try {
      const response = await axiosProviderHotel.get(
        `/api/bundle/v2/insider/best-price`,
      )
      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.FETCHING_BUNDLE_BESTRATE_SUCCESS,
            response.data.data
          )
        )
        data = response.data.data.result
      } else {
        dispatch(loadFail(response.errors[0].message))
      }
    } catch (errors) {
      dispatch(loadFail(errors.response))
    }
    dispatch(stopFetchingAction())
    return data;
  }
}

const resetCounTimePhsBundle = (data) => {
  return async (dispatch) => {
    dispatch(startFetchingAction())
    try {
      const endpoint = '/api/phsOrder/ResetCountTime';
      const params = { phsCode: data.phsCode, isBack: data.isBack }
      const response = await axiosProviderHotel.get(endpoint, { params })
      if (response.status === 200 && !response.errors) {
        dispatch(loadSuccess(
          actionTypes.RESET_COUNT_TIME_BUNDLE_SUCCESS,
          response.data
        ))
        dispatch(
          loadSuccess(actionTypes.PHS_ORDER_HOTEL_SUCCESS, response.data)
        )
        SessionStorage.set(LOCAL_STORAGE_KEY.PHS_ORDER_BUNDLE, response.data)
      } else {
        dispatch(loadFail(response.errors[0].message))
      }
    } catch (errors) {
      dispatch(loadFail(errors.response))
    }
    dispatch(stopFetchingAction())
  }
}

const checkCodeBundle = (data) => {
  return async (dispatch) => {
    dispatch(startFetchingAction())
    dispatch(resetCheckCodeBundleError())
    try {
      const endpoint = '/api/bundle/v2/check-code';
      const response = await axiosProviderHotel.post(endpoint, data)
      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.CHECK_CODE_BUNDLE,
            response.data
          )
        )
      } else {
        // dispatch(loadFail(response.errors[0].message))
        const dataError = {
          data: {
            errors: [{
              code: response?.data?.errorCode || "COUPON_NOT_FOUND",
              message: response?.data?.errorMessage || "Cannot find coupon "
            }]
          }
        }
        const errorModel = modalUtils.getApiMessageError(dataError, {promotionCode: data.promotionCode});
        dispatch(loadSuccess(
          actionTypes.CHECK_CODE_BUNDLE_ERROR,
          errorModel
        ))
      }
    } catch (errors) {
      const errorGet = get(errors, 'response');
      const errorModel = modalUtils.getApiMessageError(errorGet, {promotionCode: data.promotionCode});
      dispatch(loadSuccess(
        actionTypes.CHECK_CODE_BUNDLE_ERROR,
        errorModel
      ))
    } finally {
      dispatch(stopFetchingAction())
    }
  }
}

const resetCheckCodeBundle = ()=>{
  return dispatch => {
    dispatch(
      loadSuccess(
        actionTypes.CHECK_CODE_BUNDLE,
        null
      )
    )
    dispatch(
      loadSuccess(
        actionTypes.CHECK_CODE_BUNDLE_ERROR,
        null
      )
    )
  }
}

const resetCheckCodeBundleError = ()=>{
  return dispatch => {
    dispatch(
      loadSuccess(
        actionTypes.CHECK_CODE_BUNDLE_ERROR,
        null
      )
    )
  }
}

const setSearchBundleParams = (params) => {
  return async (dispatch) => {
    dispatch(
      loadSuccess(
        actionTypes.CHANGE_SEARCH_BUNDLE_PARAMS,
        params
      )
    )
  }
}

const setResetCurrentBundle = () => {
  return async (dispatch) => {
    dispatch(loadSuccess(
      actionTypes.FETCHING_BUNDLE_DETAIL_SUCCESS, null
    ))
  }
}

const switchPoints = (departurePointIsDomestic = true) => {
  return dispatch => {
    dispatch(
      loadSuccess(
        actionTypes.SWITCH_POINTS,
        { departurePointIsDomestic: departurePointIsDomestic }
      )
    )
  }
}

const changeDepartureFlightBundle = (currentFlight) => {
  return (dispatch) => {
    dispatch(
      loadSuccess(
        actionTypes.CHANGE_DEPARTURE_FLIGHT_SUCCESS,
        currentFlight
      )
    )
    dispatch(stopFetchingAction())
  }
}

const changeReturnFlightBundle = (currentFlight) => {
  return (dispatch) => {
    dispatch(
      loadSuccess(
        actionTypes.CHANGE_RETURN_FLIGHT_SUCCESS,
        currentFlight
      )
    )
    dispatch(stopFetchingAction())
  }
}

/**
 * start fetching action
 */
export const startFetchingBundleAction = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCHING_COMBO_START
    })
  }
}

/**
 * stop fetching action
 */
export const stopFetchingBundleAction = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCHING_COMBO_STOP
    })
  }
}

export const savePathRedirect = (path) => {
  return async (dispatch) => {
    dispatch(
      loadSuccess(
        actionTypes.SAVE_PATH_REDIRECT,
        path
      )
    )
  }
}

export const addRoomsHotel = (rooms) => {
  return dispatch => {
    dispatch(
      loadSuccess(
        actionTypes.ADD_ROOMS_HOTEL_SUCCESS,
        rooms
      )
    )
  }
}
export const releaseComboPhs = (phsId) => {
  return async (dispatch) => {
    dispatch(startFetchingAction())
    try {
      const endpoint = `api/phsorder/release/${phsId}`;
      const response = await axiosProviderHotel.post(endpoint, {})
      if (response.status === 200) {
        SessionStorage.remove(LOCAL_STORAGE_KEY.PHS_ORDER_BUNDLE)
        dispatch(loadSuccess(actionTypes.PHS_ORDER_HOTEL_SUCCESS, undefined))
      } else {
        dispatch(loadFail(response.errors[0].message))
      }
    } catch (errors) {
      dispatch(loadFail(errors.response))
    } finally {
      dispatch(stopFetchingAction())
    }
  }
}

export const changeHotelBundle = (status) => {
  return dispatch => {
    dispatch(
      loadSuccess(
        actionTypes.CHANGE_HOTEL_BUNDLE,
        status
      )
    )
  }
}

export default {
  getLocations,
  getBundles,
  getHotelBundle,
  getBundleDetail,
  verifyBundleDetail,
  switchPoints,
  setSearchBundleParams,
  getFlightBundle,
  getFlightHotelBundle,
  changeDepartureFlightBundle,
  changeReturnFlightBundle,
  savePathRedirect,
  addRoomsHotel,
  setLocations,
  resetCounTimePhsBundle,
  releaseComboPhs,
  changeHotelBundle,
  checkCodeBundle,
  resetCheckCodeBundle,
  setResetCurrentBundle
}
