import React from 'react'
import { FormattedMessage } from 'react-intl.macro'
import featureFlags from './featureFlags'

export const currencyUnit = {
  VietnameseDong: 'VND',
  Dollar: 'USD',
  Won: 'KRW',
  EUR: 'EUR', 
  JPY: 'JPY', 
  CAD: 'CAD', 
  AUD: 'AUD', 
  SGD: 'SGD', 
  HKD: 'HKD', 
  RUB: 'RUB', 
  CNY: 'CNY', 
  GBP: 'GBP', 
  CHF: 'CHF', 
  TWD: 'TWD', 
  THB: 'THB'
}

export const currencyPath = ['VND', 'USD', 'KRW', 'EUR', 'JPY', 'CAD', 'AUD', 'SGD', 'HKD', 'RUB', 'CNY', 'GBP', 'CHF', 'TWD', 'THB']

export const currencyFind = /\-(VND|USD|KRW|EUR|JPY|CAD|AUD|SGD|HKD|RUB|CNY|GBP|CHF|TWD|THB)/g
export const currencyFindSpecial = /\-(VND|USD|KRW|EUR|JPY|CAD|AUD|SGD|HKD|RUB|CNY|GBP|CHF|TWD|THB)\//g

export const currencyEnum = [
  {
    value: currencyUnit.VietnameseDong,
    country: <FormattedMessage
      id="Curency.Vietnamese"
      defaultMessage="Vietnamese"
    />,
    label: <FormattedMessage
      id="Curency.Vietnamdong"
      defaultMessage="Dong"
    />,
    icon: 'VND',
    symbol: '₫'
  },
  {
    value: currencyUnit.Dollar,
    country:<FormattedMessage
      id="Curency.UnitedStates"
      defaultMessage="United States"
    />,
    label: <FormattedMessage
      id="Curency.Dollar"
      defaultMessage="Dollar Mỹ"
    />,
    icon: 'USD',
    symbol: '$',
    hide: featureFlags.HIDE_CURRENCIES,
  },
  {
    value: currencyUnit.Won,
    country: <FormattedMessage
      id="Curency.SouthKorean"
      defaultMessage="South Korean"
    />,
    label: <FormattedMessage
      id="Curency.Won"
      defaultMessage="Won Hàn Quốc"
    />,
    icon: 'KRW',
    symbol: '₩',
    hide: featureFlags.HIDE_CURRENCIES,
  },
  {
    value: currencyUnit.EUR,
    country: <FormattedMessage
      id="Curency.Euro"
      defaultMessage="Euro"
    />,
    label: <FormattedMessage
      id="Curency.EUR"
      defaultMessage="EUR"
    />,
    icon: 'EUR',
    symbol: '€',
    hide: featureFlags.HIDE_CURRENCIES,
  },
  {
    value: currencyUnit.JPY,
    country: <FormattedMessage
      id="Curency.Japanese"
      defaultMessage="Japanese"
    />,
    label: <FormattedMessage
      id="Curency.JPY"
      defaultMessage="Yen"
    />,
    icon: 'JPY',
    symbol: '¥',
    hide: featureFlags.HIDE_CURRENCIES,
  },
  {
    value: currencyUnit.CAD,
    country: <FormattedMessage
      id="Curency.Canadian"
      defaultMessage="Canadian"
    />,
    label: <FormattedMessage
      id="Curency.CAD"
      defaultMessage="Dollar"
    />,
    icon: 'CAD',
    symbol: '$',
    hide: featureFlags.HIDE_CURRENCIES,
  },
  {
    value: currencyUnit.AUD,
    country: <FormattedMessage
      id="Curency.Australian"
      defaultMessage="Australian"
    />,
    label: <FormattedMessage
      id="Curency.AUD"
      defaultMessage="Dollar"
    />,
    icon: 'AUD',
    symbol: '$',
    hide: featureFlags.HIDE_CURRENCIES,
  },
  {
    value: currencyUnit.SGD,
    country: <FormattedMessage
      id="Curency.Singapore"
      defaultMessage="Singapore"
    />,
    label: <FormattedMessage
      id="Curency.SGD"
      defaultMessage="Dollar"
    />,
    icon: 'SGD',
    symbol: 'S$',
    hide: featureFlags.HIDE_CURRENCIES,
  },
  {
    value: currencyUnit.HKD,
    country: <FormattedMessage
      id="Curency.HongKong"
      defaultMessage="Hong Kong"
    />,
    label: <FormattedMessage
      id="Curency.HKD"
      defaultMessage="Dollar"
    />,
    icon: 'HKD',
    symbol: '$',
    hide: featureFlags.HIDE_CURRENCIES,
  },
  {
    value: currencyUnit.RUB,
    country: <FormattedMessage
      id="Curency.Russian"
      defaultMessage="Russian"
    />,
    label: <FormattedMessage
      id="Curency.RUB"
      defaultMessage="Ruble"
    />,
    icon: 'RUB',
    symbol: '₽',
    hide: featureFlags.HIDE_CURRENCIES,
  },
  {
    value: currencyUnit.CNY,
    country: <FormattedMessage
      id="Curency.Chinese"
      defaultMessage="Chinese"
    />,
    label: <FormattedMessage
      id="Curency.CNY"
      defaultMessage="Yuan"
    />,
    icon: 'CNY',
    symbol: '¥',
    hide: featureFlags.HIDE_CURRENCIES,
  },
  {
    value: currencyUnit.GBP,
    country: <FormattedMessage
      id="Curency.PoundSterling"
      defaultMessage="Pound Sterling"
    />,
    label: <FormattedMessage
      id="Curency.GBP"
      defaultMessage="GBP"
    />,
    icon: 'GBP',
    symbol: '£',
    hide: featureFlags.HIDE_CURRENCIES,
  },
  {
    value: currencyUnit.CHF,
    country: <FormattedMessage
      id="Curency.SwissFranc"
      defaultMessage="Swiss Franc"
    />,
    label: <FormattedMessage
      id="Curency.CHF"
      defaultMessage="CHF"
    />,
    icon: 'CHF',
    symbol: 'FR',
    hide: featureFlags.HIDE_CURRENCIES,
  },
  {
    value: currencyUnit.TWD,
    country: <FormattedMessage
      id="Curency.NewTaiwanDollar"
      defaultMessage="New Taiwan Dollar"
    />,
    label: <FormattedMessage
      id="Curency.TWD"
      defaultMessage="TWD"
    />,
    icon: 'TWD',
    symbol: 'NT$',
    hide: featureFlags.HIDE_CURRENCIES,
  },
  {
    value: currencyUnit.THB,
    country: <FormattedMessage
      id="Curency.ThaiBaht"
      defaultMessage="Thai Baht"
    />,
    label: <FormattedMessage
      id="Curency.THB"
      defaultMessage="THB"
    />,
    icon: 'THB',
    symbol: 'NT$',
    hide: featureFlags.HIDE_CURRENCIES,
  },
]