import { GENDER_MAP } from "src/constants/enumType";
import { FORMAT_DATE, FORMAT_ONLY_DATE_REVERSE, LIST_ID_INSIDER } from "../constants";
import moment from "moment";

export function pushUserInsObject(userData = {}) {
  // try {
    userData = userData || {};
    window.insider_object = {
      "user": {
        "uuid": userData.crmId || '',
        "gdpr_optin": true,
        "name": userData.firstname || userData.lastname || userData.firstName || userData.lastName ?
          `${userData.lastname || userData.lastName || ''}${userData.firstname || userData.firstName ? ' ' + (userData.firstname || userData.firstName) : ''}` || ''
          : userData.fullname || userData.name,
        "email": userData.email,
        "email_optin": true,
        "phone_number": userData.phoneNumber || userData.phone,
        "sms_optin": true,
        "member_type": userData.memberShipCode || '',
        "Gia_tri_tich_luy": userData.insider && userData.insider.Gia_tri_tich_luy || 0,
        "gender": userData.gender ? GENDER_MAP[userData.gender] : "",
        "birthday":  userData?.dateOfBirth ? moment(userData.dateOfBirth, FORMAT_DATE).format(FORMAT_ONLY_DATE_REVERSE) : "",
        // "list_id": userData.listId || [],z
        "list_id": userData.list_id || LIST_ID_INSIDER,
        "custom": {
          "ma_chien_dich": userData.insider && userData.insider.ma_chien_dich || [],
          "ngay_bat_dau_uu_dai": userData.insider && userData.insider.ngay_bat_dau_uu_dai || [],
          "ngay_het_han_the": userData.insider && userData.insider.ngay_het_han_the || '',
          "ngay_ket_thuc_uu_dai": userData.insider && userData.insider.ngay_ket_thuc_uu_dai || [],
          "tong_so_luong_uu_dai": userData.insider && userData.insider.tong_so_luong_uu_dai || 0,
          "ten_uu_dai": userData.insider && userData.insider.ten_uu_dai || [],
          "trang_thai_the": userData.insider && userData.insider.trang_thai_the || false,
          "voucher_apply_for": userData.insider && userData.insider.voucher_apply_for || [],
        },
      }
    }

    
  // } catch (error) {
  //   console.warn('ERROR pushUserInsObject', error)
  // }
}