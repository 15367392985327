import { gtmPushDataUser } from "@utils/gtm";
import { createLoginGA, createLogoutGA } from "@utils/gtmConvert/authConvert";
import { isGtmDisabled } from "@utils/gtmHelpers";
import { gtmType } from "src/helpers/gtmEvent";

const gtmAuth = async ({ type = gtmType.LOGIN, dataUser = {}}) => {
  // return;
  // if (isGtmDisabled()) return;
  let dataPush;
  switch (type) {
    case gtmType.LOG_OUT: 
      dataPush = createLogoutGA();
      break;
    case gtmType.LOGIN:
      default:
      dataPush = createLoginGA({ userData: dataUser });
      break;
  }
  await gtmPushDataUser({ dataUser: dataPush });
};
export default gtmAuth;
