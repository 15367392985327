import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

//actions
import { modalNotify, modalLogin } from '../actions/commonAction'
import ratingAction from '../actions/ratingAction'

/**
 *
 * @type {function(*=): *}
 */
export const getRatingCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (query) => {
      dispatch(ratingAction.getRatings(query))
    },
    [dispatch]
  )
}

export const notifyLogin = () => {
  const dispatch = useDispatch()
  return useCallback(
    (message) => {
      dispatch(modalNotify(message))
    },
    [dispatch]
  )
}

export const notifyModalLogin = () => {
  const dispatch = useDispatch()
  return useCallback(
    () => {
      dispatch(modalLogin())
    },
    [dispatch]
  )
}

export const notifySwitchLanguage = () => {
  const dispatch = useDispatch()
  return useCallback(
    (mess, children) => {
      dispatch(modalNotify(mess, children))
    },
    [dispatch]
  )
}

export const feedBackCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (data, executeRecaptcha) => {
      dispatch(ratingAction.createRating(data, executeRecaptcha))
    },
    [dispatch]
  )
}
