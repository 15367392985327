import actionTypes from '../actionTypes'

const initState = {
  blogDetail: null,
  blogs: []
}

const blogReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_BLOG_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        blogDetail: action.payload.data
      })
    case actionTypes.FETCHING_BLOG_POSTS_SUCCESS:
      return Object.assign({}, state, {
        blogs: action.payload.data
      })
    default:
      return state
  }
}

export default blogReducer