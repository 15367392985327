import React from 'react'
// lib
import { getConfig } from '@config'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

// const {
//   publicRuntimeConfig: { GOOGLE_MAP_API_KEY },  // Available both client and server side
// } = getConfig()

const GOOGLE_MAP_API_KEY = ''

const Map = withScriptjs(
  withGoogleMap((props) => {
    const {
      lat,
      lng,
      isMarkerShown
    } = props

    return (
      <GoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: lat, lng: lng }}
      >
        {
          isMarkerShown
            ? <Marker position={{ lat: lat, lng: lng }}/>
            : null
        }
      </GoogleMap>

    )
  })
)

const CustomMap = (props) => {
  const {
    lat,
    lng,
    isMarkerShown,
    customClass
  } = props

  return (
    <Map
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: `100%` }}/>}
      containerElement={
        <div style={{ height: '230px' }} className={
          clsx({
            [`${customClass}`]: !!customClass,
            'g-height-250': !customClass
          })
        }
        />
      }
      mapElement={<div style={{ height: `100%` }}/>}
      lat={Number(lat)}
      lng={Number(lng)}
      isMarkerShown={isMarkerShown}
    />
  )
}

CustomMap.propTypes = {
  lat: PropTypes.string,
  lng: PropTypes.string,
  isMarkerShown: PropTypes.bool
}

export default CustomMap