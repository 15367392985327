import { createSelector } from 'reselect'

const getIsLoading = state => state.fetching.isLoading
const getLoadingFlight = state => state.fetching.loadingFlight
const getLoadingHotel = state => state.fetching.loadingHotel
const getLoadingHotelRoom = state => state.fetching.loadingHotelRoom
const getLoadingHomestay = state => state.fetching.loadingHomestay
const getLoadingHomestayRoom = state => state.fetching.loadingHomestayRoom
const getLoadingVoucher = state => state.fetching.loadingVoucher
const getLoadingCart = state => state.fetching.loadingCart
const getLoadingCoupon = state => state.fetching.loadingCoupon
const getLoadingOrder = state => state.fetching.loadingOrder
const getExchangeRate = state => state.listing.exchangeRate
const getLoadingVinWonder = state => state.fetching.loadingVinWonder;
const getLoadingAuth = state => state.fetching.loadingAuth;
const getLoadingTour = state => state.fetching.loadingTour;
const getLoadingTourTicket = state => state.fetching.loadingTourTicket;
const getLoadingTourLine = state => state.fetching.loadingTourLine;
const getLoadingListing = state => state.fetching.loadingListing
const getLoadingFilter = state => state.fetching.loadingFilter
const getLoadingAuthLoginVpt = state => state.fetching.loadingAuthLoginVpt
const getLoadingRegister = state => state.fetching.loadingRegister
const getLoadingForgotPwd = state => state.fetching.loadingForgotPwd
const getLoadingSendOtp = state => state.fetching.loadingSendOtp
const getLoadingCheckUser = state => state.fetching.loadingCheckUser
const getLoadingLinkAccount = state => state.fetching.loadingLinkAccount
const getLoadingUploadAvata = state => state.fetching.loadingUploadAvata
const getLoadingRemoveOldPhone = state => state.fetching.loadingRemoveOldPhone
const getLoadingUpdateUser = state => state.fetching.loadingUpdateUser
const getLoadingPromotionAutoFill = state => state.fetching.loadingPromotionAutoFill
const getFlightHold = state => state.flight.hold

export const selectExchangeRate = createSelector(
  getExchangeRate,
  exchangeRate => exchangeRate
)

export const selectIsLoading = createSelector(
  getIsLoading,
  isLoading => {
    return isLoading
  }
)

export const selectLoadingFlight = createSelector(
  getLoadingFlight,
  loadingFlight => loadingFlight
)

export const selectLoadingHotel = createSelector(
  getLoadingHotel,
  loadingHotel => loadingHotel
)

export const selectLoadingHotelRoom = createSelector(
  getLoadingHotelRoom,
  loadingHotelRoom => loadingHotelRoom
)

export const selectLoadingVoucher = createSelector(
  getLoadingVoucher,
  loadingVoucher => loadingVoucher
)

export const selectLoadingCart = createSelector(
  getLoadingCart,
  loadingCart => loadingCart
)

export const selectLoadingCoupon = createSelector(
  getLoadingCoupon,
  loadingCoupon => loadingCoupon
)

export const selectLoadingOrder = createSelector(
  getLoadingOrder,
  loadingOrder => loadingOrder
)

export const selectLoadingVinWonder = createSelector(
  getLoadingVinWonder,
  loadingVinWonder => loadingVinWonder
)

export const selectLoadingAuth = createSelector(
  getLoadingAuth,
  loadingAuth => loadingAuth
)

export const selectLoadingTour = createSelector(
  getLoadingTour,
  loadingTour => loadingTour
)

export const selectLoadingTourTicket = createSelector(
  getLoadingTourTicket,
  loading => loading
)

export const selectLoadingTourLine = createSelector(
  getLoadingTourLine,
  loading => loading
)

export const selectLoadingListing = createSelector(
  getLoadingListing,
  loadingListing => loadingListing
)

export const selectLoadingFilter = createSelector(
  getLoadingFilter,
  loadingFilter => loadingFilter
)

export const selectLoadingAuthLoginVpt = createSelector(
  getLoadingAuthLoginVpt,
  loadingAuthLoginVpt => loadingAuthLoginVpt
)

export const selectLoadingPromotionAutoFill = createSelector(
  getLoadingPromotionAutoFill,
  loadingPromotionAutoFill => loadingPromotionAutoFill
)

export const selectFlightHold = () => createSelector(
  getFlightHold,
  hold => hold
)

export const selectLoadingRegister = createSelector(
  getLoadingRegister,
  loadingRegister => loadingRegister
)

export const selectLoadingForgotPwd = createSelector(
  getLoadingForgotPwd,
  loadingForgotPwd => loadingForgotPwd
)

export const selectLoadingSendOTP = createSelector(
  getLoadingSendOtp,
  loadingSendOtp => loadingSendOtp
)

export const selectLoadingCheckUser = createSelector(
  getLoadingCheckUser,
  loadingCheckUser => loadingCheckUser
)

export const selectLoadingUpdateUser = createSelector(
  getLoadingUpdateUser,
  loadingUpdateUser => loadingUpdateUser
)

export const selectLoadingLinkAccount = createSelector(
  getLoadingLinkAccount,
  loadingLinkAccount => loadingLinkAccount
)

export const selectLoadingUploadAvata = createSelector(
  getLoadingUploadAvata,
  loadingUploadAvata => loadingUploadAvata
)

export const selectLoadingRemoveOldPhone = createSelector(
  getLoadingRemoveOldPhone,
  loadingRemoveOldPhone => loadingRemoveOldPhone
)

export const selectLoadingHomestay = createSelector(
  getLoadingHomestay,
  loadingHomestay => loadingHomestay
)

export const selectLoadingHomestayRoom = createSelector(
  getLoadingHomestayRoom,
  loadingHomestayRoom => loadingHomestayRoom
)
