import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Switch, Route, useParams } from 'react-router-dom';
import { LOCAL_STORAGE_KEY } from 'src/constants';
import { languagePath } from 'src/constants/language';
import { currencyPath } from '../../constants/currencyUnit'
import { useGlobalStore } from 'src/hooks/useGlobal';
import { commonUtils, LocalStorage } from '../../utils';

const pages = process.env.PAGES || [];
const pageComponents = pages.map(page => ({
  path: commonUtils.parseRouteToPath(page),
  component: React.lazy(() => import(`./../../pages${page === '/' ? '/index' : page}`)),
  exact: !(page.indexOf('[') >= 0 && page.indexOf(']') >= 0)
}))
// console.log('===== PAGE:', orderBy(pageComponents, ['exact'], ['desc']))

let isLoaded = false 
let isCurrencyLoaded = false;

const Routes = () => {

  let { locale } = useParams();
  const { state, dispatch } = useGlobalStore()
  let localeLang = null;
  let currency = null;
  if(locale){
    if(locale.length === 2 || locale.length === 6){
      localeLang = locale.substr(0, 2);
    }
    if(locale.length === 6){
      currency = locale.substr(3, 6);
    }
  }
  useEffect(() => {
      const isPathLanguage = languagePath.includes(localeLang)
      if(isPathLanguage) {
        if(localeLang !== state.lang) {
          dispatch({ type: 'lang', data: localeLang})
          LocalStorage.set(LOCAL_STORAGE_KEY.LANGUAGE_WESBITE, { type: 'lang', data: localeLang} )
        } 
      } else {
        if(!isLoaded) {
          dispatch({ type: 'lang', data: 'vi'})
          LocalStorage.set(LOCAL_STORAGE_KEY.LANGUAGE_WESBITE, { type: 'lang', data: 'vi'} )
          isLoaded = true
        }
      }
      const isPathCurrency = currencyPath.includes(currency)
      if(isPathCurrency) {
        if(currency !== state.currencyOnl) {
          dispatch({ type: 'currencyOnl', data: currency})
          LocalStorage.set(LOCAL_STORAGE_KEY.CURRENCY_WESBITE, { type: 'currencyOnl', data: currency} )
        } 
      } else {
        if(!isCurrencyLoaded) {
          dispatch({ type: 'currencyOnl', data: 'VND'})
          LocalStorage.set(LOCAL_STORAGE_KEY.CURRENCY_WESBITE, { type: 'currencyOnl', data: 'VND'} )
          isCurrencyLoaded = true
        }
      }
  }, [])

  const pages = orderBy(pageComponents, ['exact'], ['desc'])

  const renderComponent = Component => props => <Component {...props} />
  return (
    <Switch>
      {pages.map(page => (
        <Route key={commonUtils.parseRouterLocaleToPath(page.path, locale)} path={commonUtils.parseRouterLocaleToPath(page.path, locale, localeLang)} exact={page.exact} render={renderComponent(page.component)} />
      ))}
      {/* <Route path={'/'} exact component={React.lazy(() => import(`./../../pages/index`))} /> */}
      <Route path='' exact={false} component={React.lazy(() => import(`./../../pages/error`))} />
    </Switch>
  );
}

export default Routes;

