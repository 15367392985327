import actionTypes from '../actionTypes'

const initState = {
  categories: null,
  vinWonder: null,
  vinWonderDetail: null,
  locations: [],
  specialVinWonder: [],
  dataCode: null,
  tickets: [],
  listSession: [],
  listZone: [],
  identityType: [],
  nationality: []
}

const exprienceReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_CATEGORY_VINWONDER_SUCCESS:
      return Object.assign({}, state, {
        categories: action.payload.data
      })
    case actionTypes.FETCHING_VINWONDER_SUCCESS:
      return Object.assign({}, state, {
        vinWonder: action.payload.data
      })
    case actionTypes.FETCHING_VINWONDER_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        vinWonderDetail: action.payload.data
      })
    case actionTypes.FETCHING_VINWONDER_LOCATION_SUCCESS:
      return Object.assign({}, state, {
        locations: action.payload.data
      })
    case actionTypes.FETCHING_VINWONDER_SPECIAL_SUCCESS:
      return Object.assign({}, state, {
        specialVinWonder: action.payload.data
      })
    case actionTypes.FETCHING_CODE_VINWONDER_SUCCESS:
      return Object.assign({}, state, {
        dataCode: action.payload.data
      })
    case actionTypes.FETCHING_TICKETS_VINWONDER_SUCCESS:
      return Object.assign({}, state, {
        tickets: action.payload.data
      })
    case actionTypes.FETCHING_SESSION_VINWONDER_SUCCESS:
      return Object.assign({}, state, {
        listSession: action.payload.data
      })
    case actionTypes.FETCHING_ZONE_VINWONDER_SUCCESS:
      return Object.assign({}, state, {
        listZone: action.payload.data
      })
    case actionTypes.FETCHING_IDENTITY_TYPE_VINWONDER_SUCCESS:
      return Object.assign({}, state, {
        identityType: action.payload.data
      })
    case actionTypes.FETCHING_NATIONALITY_VINWONDER_SUCCESS:
      return Object.assign({}, state, {
        nationality: action.payload.data
      })
    default:
      return state
  }
}

export default exprienceReducer
