import { datetimeExtensions } from '../extensions'
import { filterFlights, sortFlights } from '../reselect/flightSelectors'

export default {
  checkValidParams (params) {
    const listValidParams = [
      'startPointId',
      'startPointName',
      'endPointId',
      'endPointName',
      'departureDate',
      'returnDate',
      'room',
    ]

    const validParams = listValidParams.every(props => Object.keys(params).includes(props))

    if (validParams) {
      const departureDate = params['departureDate']
      return !datetimeExtensions.checkIsPreviousVnDate(departureDate, datetimeExtensions.formatVnDate(new Date()))
    }
    return false
  },

  mergeDataFlight(dataFlightBundle, keyFlight) {
    let listFlight = [];

    dataFlightBundle && dataFlightBundle.flight ?
            dataFlightBundle.flight.map(item => {
                    return item[keyFlight].map(data => {
                        const flight = {
                          ...data,
                          dataSession: item.dataSession
                        }
                        return listFlight.push(flight)
                    })
                })
            : null
    return listFlight
  },

  filteredFlight(flights, filters) {
    let filteredFlights = filterFlights(flights, filters)
    let sortedFlight = sortFlights(filteredFlights, filters.sort, 'increasePrice')
    return sortedFlight
  }
}