export class ListingItem {

    constructor(data = {}) {
        this.setData(data);
    }

    setData(data = {}) {
        this.id = data.id;
        this.installmentFee = data.installmentFee;
        this.installmentLimit = data.installmentLimit;
        this.lengthOfStay = data.lengthOfStay;
        this.name = data.name;
        this.originalPrice = data.originalPrice;
        this.salePrice = data.salePrice;
        this.shortDescription = data.shortDescription;
        this.skuCode = data.skuCode;
        this.soldQuantity = data.soldQuantity;
        this.thumbImageUri = data.thumbImageUri;
        this.type = data.type;
        this.urlSlug = data.urlSlug;
        this.saleEndDate = data.saleEndDate;
        this.saleStartDate = data.saleStartDate;
    }
}

export class FilterParams {
    constructor(data) {
        const {
            keyword,
            place,
            tagGroupIds,
            priceRange = []
        } = data || {};
        const [minPrice, maxPrice] = priceRange;
        
        this.tagGroupIds = tagGroupIds;
        this.locationId = place;
        this.keyword = keyword;
        this.minPrice = minPrice;
        this.maxPrice = maxPrice;
    }
}

export class OutstandingProductListing {

    constructor(data = {}) {
        this.setData(data);
    }

    setData(data = {}) {
        this.id = data.id,
        this.code = data.code,
        this.name = data.name,
        this.originalPrice = data.originalPrice,
        this.salePrice = data.salePrice,
        this.saleStartDate = data.saleStartDate,
        this.saleEndDate = data.saleEndDate,
        this.thumbImage = data.thumbImageView,
        this.filename = data.filename,
        this.fileUri = data.fileUri,
        this.urlSlug = data.urlSlug,
        this.shortDescription = data.shortDescription,
        this.lengthOfTour = data.lengthOfTour,
        this.soldQuantity = data.soldQuantity,
        this.createdDate = data.createdDate,
        this.productType = data.productType,
        this.order = data.order
    }
}

export class AutocompleteListing {

    constructor(data = {}) {
        this.setData(data);
    }

    setData(data = {}) {
        this.type = data.type;
        this.skuCode = data.skuCode;
        this.urlSlug = data.urlSlug;
        this.name = data.name;
        this.destination = data.destination;
    }
}