import queryString from '@utils/querystring'
import ApiService from './../api/ApiService'
import Urls from './../api/Urls'
import config from './../config'
import { compactObj } from './../helpers/functions'
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, MAX_STAR } from '../constants'
import { numberExtensions, objectExtensions } from '../extensions'

export function getVouchers (params) {
  params = params || {}
  return new ApiService({
    endpoint: Urls.VOUCHER_LIST,
    baseURL: config.BASE_URL_VOUCHER_API,
    params: compactObj(params),
  }).get()
}

export function getVoucher (slug) {
  return new ApiService({
    endpoint: Urls.VOUCHER_DETAIL,
    endpointParams: { slug },
    baseURL: config.BASE_URL_VOUCHER_API,
  }).get()
}

export function getLocations (params) {
  return new ApiService({
    endpoint: Urls.LOCATION_LIST_ALL,
    baseURL: config.BASE_URL_VOUCHER_API,
    params: params
  }).get()
}

export async function getExchangeRate() {
  return new ApiService({
    endpoint: `${Urls.EXCHANGERATE_LIST}/`,
    baseURL: config.BASE_URL_VOUCHER_API,
  }).get()
}

export const getRatingVoucher = (ratings = {}) => {

  const ratingList = Array(MAX_STAR).fill(null).map((index, value) => ({
    star: value + 1,
    review: 0,
    percentage: 0
  }))

  if (ratings) {
    const {
      ratingValue = {},
      totalCount = 0
    } = ratings

    if (ratingValue?.one) {
      ratingList[0].review = ratingValue.one
      ratingList[0].percentage = numberExtensions.getPercentage(ratingValue.one,
        totalCount)
    }

    if (ratingValue?.two) {
      ratingList[1].review = ratingValue.two
      ratingList[1].percentage = numberExtensions.getPercentage(ratingValue.two,
        totalCount)
    }

    if (ratingValue?.three) {
      ratingList[2].review = ratingValue.three
      ratingList[2].percentage = numberExtensions.getPercentage(
        ratingValue.three, totalCount)
    }

    if (ratingValue?.four) {
      ratingList[3].review = ratingValue.four
      ratingList[3].percentage = numberExtensions.getPercentage(
        ratingValue.four, totalCount)
    }

    if (ratingValue?.five) {
      ratingList[4].review = ratingValue.five
      ratingList[4].percentage = numberExtensions.getPercentage(
        ratingValue.five, totalCount)
    }

  }

  return ratingList.reverse()
}

export default {
  initQueryVoucherList (value = {}) {
    const params = {
      Name: value.keyword || '',
      PropertyId: value.place || '',
      CategoryIds: value.categoryId || [],
      ProductTypes: value.productTypes || [],
      PageSize: value.pageSize || DEFAULT_PAGE_SIZE,
      PageIndex: value.pageIndex || DEFAULT_PAGE_INDEX,
      OrderByDesc: value.orderByDesc || false,
      OrderBy: value.orderBy,
      MinPrice: value.minPrice,
      MaxPrice: value.maxPrice
    }

    objectExtensions.deleteEmptyProps(params)
    return queryString.stringify(params)
  }
}
