/**
 * Transaction
 */

import {
  bookingFlight,
  createComboFlightName,
  createComboHotelName,
  createComboHotelPrice,
  createComboName,
  createFlightName,
  createRoomOccupancy,
  getIncreasePrice,
} from "@utils/gtmHelpers";
import { gtmEvent, TBD } from "src/helpers/gtmEvent";
import { flightUtils, hotelUtils } from "..";
import moment from "moment";
import {
  DEFAULT_FORMAT_DATE,
  enumType,
  FORMAT_DATE_TIME_AFTER,
  FORMAT_ONLY_DATE,
  FORMAT_ONLY_DATE_DOT,
  LOCAL_STORAGE_KEY,
} from "src/constants";
import { ENUM_COMBO, ENUM_FLIGHT, ENUM_HOTEL, ENUM_SKU } from "./enumGtm";
import {LocalStorage} from '@utils/'
export const createDataTransaction = ({
  transactionData = {
    siteInfo: {},
    order: {},
    skuData,
  },
}) => {
  const { siteInfo, order, skuData } = transactionData;
  const productPurchasedArray = (skuData?.cartItems || []).map((el) => {
    return {
      name: `TnE: ${el?.itemName || null}`,
      // id: el?.tourId || el?.vinWonderId || null,
      // id: el?.id || null,
      id: el?.tourLineItemId || el?.id || null,
      price: el?.salePrice || 0,
      brand: TBD,
      category: ENUM_SKU.category,
      variant: el?.objectType?.name || el?.vinWonderTicketName || TBD,
      quantity: el?.quantity,
      dimension29: el?.destination?.name || TBD,
      dimension30: TBD,
      dimension31: el?.originalPrice,
      dimension32: `${(1 - el?.salePrice / el?.originalPrice) * 100}%`,
      // dimension52: "TnE_start_date",
      dimension52:
        el.departureDate || el.usingDate
          ? (el.departureDate || el.usingDate).includes("9999")
            ? TBD
            : moment(el.departureDate || el.usingDate).format(FORMAT_ONLY_DATE)
          : "",
      // dimension53: "TnE_end_date",
      dimension53:
        el.departureDate || el.usingDate
          ? (el.departureDate || el.usingDate).includes("9999")
            ? TBD
            : moment(el.departureDate || el.usingDate)
                .add((el.lengthOfTour || 1) - 1, "days")
                .format(FORMAT_ONLY_DATE)
          : "",
    };
  });
  const actionField = {
    id: order.orderCode,
    affiliation: null,
    revenue: order?.totalAmount,
    tax: 0,
    shipping: 0,
    coupon: order?.couponCode,
  };
  const ecommerceObject = {
    event: gtmEvent.TRANSACTION_SUCCESS,
    payment_method: order?.paymentType,
    payment_card: skuData?.paymentMethod,
    booking_service_type: ENUM_SKU.category,
    ecommerce: {
      currencyCode: order?.currencyCode,
      purchase: {
        products: productPurchasedArray,
        actionField: actionField,
      },
    },
  };
  return ecommerceObject;
};

export const createGtmComboTransacsionSuccess = ({
  comboData = {},
  comboInfo = {},
  roomsData = {},
  order = {},
  siteInfo = {},
  contactInfo = {},
}) => {
  const minPriceCombo =
  LocalStorage.get(LOCAL_STORAGE_KEY.MIN_PRICE_COMBO) || 0;

  const { hotel = {}, flight = {} } = comboData;
  const roomsInfo = Object.values(roomsData);
  const flights = [flight?.departureFlights, flight?.returnFlights];
  const { adult, child, infant, passenger } = createRoomOccupancy({
    roomOccupancy: comboInfo?.roomOccupancy,
  });
  const bookingRoomNight = hotelUtils.getLengthOfStay(
    moment(comboInfo?.departureDate).format(FORMAT_ONLY_DATE_DOT),
    moment(comboInfo?.returnDate).format(FORMAT_ONLY_DATE_DOT)
  );

  const roomArr = Array(comboInfo?.room || 1).fill(1);

  const hotelData = roomArr.map(() => {
    const room = roomsInfo?.[0] || {}
    const { rate = {}, roomtype = {} } = room;
    const price = createComboHotelPrice({
      bookingRoomNight,
      increaseTotalPrice: rate.increaseTotalPrice,
      room: comboInfo.room,
    });
    return {
      name: createComboHotelName({
        hotelName: hotel.name,
        roomName: roomtype?.name,
      }),
      id: `${hotel?.hotelId}-${roomtype?.id}`,
      price,
      brand: hotel?.name,
      category: ENUM_COMBO.category,
      variant: TBD,
      quantity: bookingRoomNight,
      dimension29: hotel?.address,
      dimension30: ENUM_HOTEL.category,
      dimension39: !!comboInfo?.departureDate
        ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
        : null,
      dimension40: !!comboInfo?.departureDate
        ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
        : null,
      dimension41: hotel?.name,
      dimension42: hotel?.hotelId,
      dimension43: TBD,
      dimension44: hotel?.rating,
      dimension45: hotel?.address,
      dimension46: roomtype?.name,
      dimension47: roomtype?.id,
      dimension48: roomtype?.area,
      dimension49: roomtype?.maxOccupancy,
    };
  });

  const { baggageReturn, baggageDeparture } = flightUtils.getBaggageTotal(
    contactInfo?.listPassengers
  );

  const flightData = (flights || []).map((flight, flIdx) => {
    const { departureName } = createComboFlightName({
      startPoint: flight.startPoint,
      endPoint: flight.endPoint,
    });
    const name = departureName;
    const price = getIncreasePrice({ flightData: flight });
    return {
      name,
      id: flight?.flightNumber,
      price,
      brand: flight?.listSegment?.[0]?.airlineName || "",
      category: ENUM_COMBO.category,
      variant: flight?.listSegment?.[0]?.class || "",
      quantity: adult + child + infant,
      dimension30:
        flIdx === 0 ? ENUM_FLIGHT.flight_depart : ENUM_FLIGHT.flight_return,
      dimension33: flight?.startDate
        ? moment(flight?.startDate).format(FORMAT_DATE_TIME_AFTER)
        : null,
      dimension34: flight?.endDate
        ? moment(flight?.endDate).format(FORMAT_DATE_TIME_AFTER)
        : null,
      dimension35: flight?.startPoint,
      dimension36: flight?.endPoint,
      dimension37: flight?.duration,
      dimension38: flight?.stops,
      metric5: flIdx === 0 ? baggageDeparture : baggageReturn,
    };
  });

  let baggageArr = [];
  (contactInfo?.listPassengers || []).forEach((element) => {
    baggageArr = [
      ...baggageArr,
      ...[element.baggageDeparture, element.baggageReturn],
    ].filter((e) => e);
  });

  const baggage = (baggageArr || []).map((baggageData) => {
    return {
      name: "Ancillary: Baggage",
      id: baggageData?.id,
      price: baggageData?.price,
      brand: baggageData?.airlineCode,
      category: "Ancillary",
      quantity: 1,
      dimension30: "Baggage",
    };
  });

  const productPurchasedArray = [
    {
      name: createComboName({
        bookingRoomNight,
        name: hotel?.name,
        startPointName: comboInfo.startPointName,
      }),
      id: hotel?.hotelId,
      price: minPriceCombo,
      brand: hotel?.name,
      category: ENUM_COMBO.category,
      quantity: adult + child + infant,
      dimension29: hotel?.address,
      dimension30: ENUM_COMBO.category,
      dimension50: !!comboInfo?.departureDate
        ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
        : null,
      dimension51: !!comboInfo?.returnDate
        ? moment(comboInfo?.returnDate).format(DEFAULT_FORMAT_DATE)
        : null,
    },
    ...hotelData,
    ...flightData,
    ...baggage,
  ];
  const actionField = {
    id: order.orderCode,
    affiliation: null,
    revenue: order?.totalAmount,
    tax: 0,
    shipping: 0,
    coupon: order?.couponCode,
  };

  var ecommerceObject = {
    event: gtmEvent.TRANSACTION_SUCCESS,
    payment_method: order?.paymentType,
    payment_card: comboData?.paymentMethod,
    booking_service_type: ENUM_COMBO.category,
    booking_origin: comboInfo?.startPointName,
    booking_destination: comboInfo?.endPointName,
    booking_start_date: !!comboInfo?.departureDate
      ? moment(comboInfo?.departureDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_end_date: !!comboInfo?.returnDate
      ? moment(comboInfo?.returnDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_no_of_adults: adult,
    booking_no_of_children: child,
    booking_no_of_infants: infant,
    booking_flight: bookingFlight({
      startPoint: comboInfo?.startPointName,
      endPoint: comboInfo?.endPointName,
      itineraryType: "2",
    }),
    booking_no_of_rooms: comboInfo?.room,
    booking_room_night: bookingRoomNight,
    ecommerce: {
      currencyCode: order?.currencyCode,
      purchase: {
        products: productPurchasedArray,
        actionField: actionField,
      },
    },
  };
  return ecommerceObject;
};

export const createGtmHotelTransacsionSuccess = ({
  order = {},
  roomBooking = {},
  hotelParam = {},
  infoBooking = {},
  paymentMethod = "",
}) => {
  const { hotel = {}, rooms = {} } = roomBooking;
  const roomArray = Object.values(rooms);
  const { adult, child, infant, passenger } = createRoomOccupancy({
    roomOccupancy: hotelParam?.roomOccupancy,
  });

  const productPurchasedArray = roomArray.map((room) => {
    return {
      name: `Hotel: ${hotel?.name} - ${room?.roomtype?.name}`, // Name or ID is required.
      id: hotel?.id,
      price: room?.rate?.averageAmount?.amount || 0,
      brand: hotel?.name,
      category: ENUM_HOTEL.category,
      variant: room?.rate?.ratePlan?.name,
      quantity: hotelParam?.lengthOfStay,
      // Add these custom dimensions & metrics
      dimension29: hotel?.name,
      dimension30: TBD,
      dimension31: room?.rate?.averageAmount?.amount || 0,
      dimension32: order?.couponValue,
      dimension39: !!hotelParam?.arrivalDate
        ? moment(hotelParam?.arrivalDate).format(FORMAT_ONLY_DATE)
        : null,
      dimension40: !!hotelParam?.lengthOfStay
        ? moment(hotelParam?.arrivalDate)
            .add(hotelParam?.lengthOfStay, "days")
            .format(FORMAT_ONLY_DATE)
        : null,
      dimension41: hotel?.name,
      dimension42: hotel?.id,
      dimension43: TBD,
      dimension44: hotel?.rating,
      dimension45: hotel.address,
      dimension46: room?.rate?.ratePlan?.name,
      dimension47: room?.roomtype?.id,
      dimension48: room?.roomtype?.area,
      dimension49: room?.roomtype?.defaultOccupancy,
    };
  });

  const actionField = {
    id: order?.orderCode,
    affiliation: null,
    revenue: order?.totalAmount,
    tax: 0,
    shipping: 0,
    coupon: order?.couponCode,
  };
  const ecommerceObject = {
    event: gtmEvent.TRANSACTION_SUCCESS,
    payment_method: order?.paymentType,
    // Add these custom dimensions
    payment_card: paymentMethod,
    booking_service_type: ENUM_HOTEL.category,
    booking_destination: hotel?.address,
    booking_start_date: !!hotelParam?.arrivalDate
      ? moment(hotelParam?.arrivalDate).format(DEFAULT_FORMAT_DATE)
      : null,
    booking_end_date: !!hotelParam?.lengthOfStay
      ? moment(hotelParam?.arrivalDate)
          .add(hotelParam?.lengthOfStay, "days")
          .format(DEFAULT_FORMAT_DATE)
      : null,
    booking_no_of_adults: adult,
    booking_no_of_children: child,
    booking_no_of_infants: infant,
    booking_no_of_rooms: hotelParam?.numberOfRoom,
    booking_room_night: hotelParam?.lengthOfStay,
    ecommerce: {
      currencyCode: order?.currencyCode,
      purchase: {
        products: productPurchasedArray,
        actionField: actionField,
      },
    },
  };
  return ecommerceObject;
};
