import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const WhiteSpace = (props) => {
  const { marginTop, marginBottom, children } = props
  return (
    <div className={clsx({
      [`g-mt-${marginTop}`]: marginTop,
      [`g-mb-${marginBottom}`]: marginBottom
    })}>
      {
        children ? children : null
      }
    </div>
  )
}
WhiteSpace.propTypes = {
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number
}
export default WhiteSpace