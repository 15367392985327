import homestay from 'src/services/homestay'
import actionTypes from '../actionTypes'

const initState = {
  flight: {
    startPoint: null,
    endPoint: null,
    departureDate: null,
    returnDate: null,
    adult: null,
    children: null,
    infant: null,
    clientVia: null,
    flightFilter: null
  },
  hotel: {
    startPoint: null,
    locationId: null,
    hotelId: null,
    departureDate: null,
    returnDate: null,
    adult: null,
    children: null,
    infant: null
  },
  homestay: {
    startPoint: null,
    locationId: null,
    hotelId: null,
    departureDate: null,
    returnDate: null,
    adult: null,
    children: null,
    infant: null
  },
  bundle: {
    startPointId: null,
    startPointName: null,
    endPointId: null,
    endPointName: null,
    departureDate: null,
    returnDate: null,
    roomOccupancy: null,
    adult: null,
    child: null,
    infant: null,
    room: null,
  },
}

const SearchParamsReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_SEARCH_FLIGHT_PARAMS:
      return Object.assign({}, state, {
        flight: action.payload.data
      })
    case actionTypes.CHANGE_SEARCH_HOTEL_PARAMS:
      return Object.assign({}, state, {
        hotel: action.payload.data
      })
    case actionTypes.CHANGE_SEARCH_HOMESTAY_PARAMS:
      return Object.assign({}, state, {
        homestay: action.payload.data
      })
    case actionTypes.CHANGE_SEARCH_BUNDLE_PARAMS:
      return Object.assign({}, state, {
        bundle: action.payload.data
      })
    case actionTypes.FETCHING_BUNDLE_SEARCH_SUCCESS:
      return Object.assign({}, state, {
        bundle: action.payload.data.bundleParams
      })
    default:
      return state
  }
}

export default SearchParamsReducer