import React from 'react'
import clsx from 'clsx'
import ReactQRCode from 'qrcode.react'

const QRCode = ({
  className,
  value,
  renderAs = 'svg',
  size = 128,
  ...restProps
}) => {
  return (
    <div className={clsx('vpt-qrcode', className)}>
      <ReactQRCode value={value} renderAs={renderAs} size={size} {...restProps} />
    </div>
  )
}

export default QRCode