import React from 'react'
import { FormattedMessage } from 'react-intl.macro'

export const CART_ERROR = {
    CART_INVALID: <FormattedMessage
      id="Cart.CartHasChanged"
      defaultMessage='Giỏ hàng đã thay đổi'
    />,
    ITEM: {
        TOUR_EXPIRED: <FormattedMessage
          id="Cart.TourHasExpired"
          defaultMessage='Tour đã hết hạn'
        />,
        GROUP_EXPIRED: <FormattedMessage
          id="Cart.VoucherGroupHasExpired"
          defaultMessage='Nhóm voucher đã hết hạn'
        />,
        PRICE_CHANGED: <FormattedMessage
          id="Cart.PriceHasChanged"
          defaultMessage='Giá đã thay đổi'
        />,
        NOT_ENOUGH_QUANTITY: <FormattedMessage
          id="Cart.NotEnoughQuantity"
          defaultMessage='Không đủ số lượng'
        />,
        OUT_OF_QUANTITY: <FormattedMessage
          id="Cart.OutOfStock"
          defaultMessage='Hết số lượng'
        />,
        ITEM_NOT_FOUND: <FormattedMessage
          id="Cart.NoProductsFound"
          defaultMessage='Không tìm thấy sản phẩm này'
        />,
        UNDEFINED_ERROR: <FormattedMessage
          id="Cart.AnUnknownError"
          defaultMessage='Lỗi không xác định'
        />,
    }
}

export const UNDEFINED_ERROR = 'Lỗi không xác định'