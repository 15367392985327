import React, { useEffect } from 'react'
import { routes, SHOPPING_CART_ICON } from '../../constants'
import { loadCartAction } from '../../actions/carts'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Popover } from 'antd'
import Router from '@utils/router'
import { FormattedMessage } from 'react-intl.macro'
import { selectCartCount, selectCurrentCart, selectVoucherCart, selectTourCart, selectVinWonderCart } from '../../reselect/cartSelector'
import DetailPopup from '../Cart/DetailPopup'
import { loadCartDetail } from '../../hooks/carts'
import clsx from 'clsx'
import { redirectPage } from 'src/hooks/common'
import { generateVinwonder } from 'src/helpers/cart.helper'

const PopoverTitle = ({ handleClosePopover }) => (
  <div className='vpt-color-v1 g-font-size-13'>
    <div className='d-flex align-items-center'>
      <div className='g-py-7 overflow-hidden'>
        <FormattedMessage
          id="AddToCartSuccessfully"
          defaultMessage='Thêm vào giỏ hàng thành công'
        />
      </div>
      <div className='g-py-7 float-right ml-auto'>
        <a onClick={handleClosePopover}>
          <img className='img-fluid' alt='close' src={require('@assets/include/svg/close.svg').default} />
        </a>
      </div>
    </div>
  </div>
)

const Cart = ({
  icon = SHOPPING_CART_ICON,
  className,
}) => {

  const dispatch = useDispatch()

  const cartCount = useSelector(selectCartCount())
  const vouchers = useSelector(selectVoucherCart())
  const tours = useSelector(selectTourCart())
  const vinWonders = useSelector(selectVinWonderCart())
  const cart = useSelector(selectCurrentCart())
  const vinWonderTours = cart?.vinWonderTour ? generateVinwonder(cart?.vinWonderTour || []) : [];
  const [redirectPageCallback] = redirectPage();
  
  // hook
  const loadCurrentCartCallback = loadCartDetail()

  useEffect(() => {
    dispatch(fetchData())
  }, [])

  const fetchData = () => {
    return dispatch => {
      // dispatch(loadCartAction())
    }
  }

  const fetchCart = () => {
    const cartId = localStorage.getItem("cartID")
    if (cartId) {
      loadCurrentCartCallback()
    }
  }


  return (
    <div>
      <Popover
        placement="bottomRight"
        overlayClassName='info-cart-popup'
        onMouseEnter={() => fetchCart()}
        content={
          <DetailPopup
            vouchers={vouchers}
            tours={tours}
            vinWonders={vinWonders}
            vinWonderTours={vinWonderTours}
            allItems={[...vouchers, ...tours, ...vinWonders, ...vinWonderTours]}
          />
        }
        trigger="hover">
        <Button
          className={clsx('vpt-tracking_my-cart border-0 g-box-shadow-none g-bg-transparent', className)}
          onClick={() => redirectPageCallback(routes.MY_RESERVE)}
        >
          <div className='g-pos-rel vpt-tracking_my-cart'>
            <div className='g-mr-5 vpt-tracking_my-cart'>
              {icon}
            </div>
            {
              cartCount
                ? <div
                  className="u-badge-v1 g-color-white g-bg-red g-rounded-50x flag-number-cart">
                  {cartCount}
                </div>
                : null
            }
          </div>
        </Button>
      </Popover>
    </div>
  )
}

export default Cart