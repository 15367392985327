export class TagGroup {

    constructor(data = {}) {
        this.setData(data);
    }

    setData(data = {}) {
        this.id = data.id;
        this.name = data.name;
        this.isActive = data.isActive;
        this.tagGroupDetails = data.tagGroupDetails || [];
        this.createdDate = data.createdDate;
        this.order = data.order;
        this.code = data.code;
        this.productType = data.productType;
        this.type = data.type;
    }
}