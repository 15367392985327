
export default class ContextStore {

    constructor(initState = {}) {
        this.state = initState;
    }

    dispatch(){

    }

    getState(){
        return this.state
    }

}