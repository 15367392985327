import ApiService from './../api/ApiService';
import Urls from './../api/Urls';
import config from "./../config"
import Tour from "./../model/Tour"
import moment from "moment";
import { each } from "lodash";
import { compactObj } from "./../helpers/functions"
import { objectExtensions } from '../extensions';
import queryString from '@utils/querystring'
import { FORMAT_MONTH, DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX, FORMAT_ONLY_DATE, FORMAT_ONLY_MONTH } from '../constants';


export function getCollectionTours(params) {
    return new ApiService({
        endpoint: Urls.TOUR_COLLECTION,
        baseURL: config.BASE_URL_TOUR_API,
        params: params
    }).get()
}

export function getTours(params) {
    return new ApiService({
        endpoint: Urls.TOUR_LIST,
        baseURL: config.BASE_URL_TOUR_API,
        params: compactObj(params),
        parser: listParser
    }).get()
}

const listParser = (data) => ({
    result: (data.result || []).map(item => new Tour(item)),
    pageIndex: data.pageIndex,
    pageSize: data.pageSize,
    totalCount: data.totalCount
});

export function getTour(slug) {
    return new ApiService({
        endpoint: `${Urls.TOUR_DETAIL}/${slug}`,
        baseURL: config.BASE_URL_TOUR_API,
        params: {Channel: 1},
        parser: itemParser
    }).get()
}

const itemParser = (tourParam) => {
    if (tourParam && tourParam.tourDetail && tourParam.tourDetail.tripItinerary) {
        const tripItinerary = [];
        try {
            each(tourParam.tourDetail.tripItinerary, (item) => {
                tripItinerary.push(JSON.parse(item))
            })
        } catch (error) {

        }
        tourParam.tourDetail.tripItinerary = tripItinerary;
    }
    const tour = new Tour(tourParam);
    tour.updateDetail(tourParam.tourDetail);
    return tour;
};

export async function getTourLines(tour, monthStr, endMonthStr) {
    monthStr = monthStr || moment().format(FORMAT_ONLY_MONTH);
    const _moment = moment(monthStr, FORMAT_ONLY_MONTH);
    const _endMoment = endMonthStr ? moment(endMonthStr, FORMAT_ONLY_MONTH) : _moment;
    let params = {
        FromDate: _moment.startOf('month').format("MM-DD-YYYY"),
        ToDate: _endMoment.endOf('month').format("MM-DD-YYYY")
    }
    return new ApiService({
        endpoint: `${Urls.TOUR_LINES}/${tour.id}`,
        baseURL: config.BASE_URL_TOUR_API,
        params: params,
        parser: (data) => Object.assign(data, { monthStr }),
    }).get()
}

export function getLocations(params) {
    return new ApiService({
        endpoint: Urls.LOCATION_LIST_ALL,
        baseURL: config.BASE_URL_TOUR_API,
        params: params
    }).get()
}

export function getStateprovince(countryCode, params = {}) {
    return new ApiService({
        endpoint: Urls.TOUR_STATE_PROVINCE,
        baseURL: config.BASE_URL_TOUR_API,
        params: Object.assign(params, { countryCode }),
    }).get()
}

export function getTourType(params) {
    return new ApiService({
        endpoint: Urls.TOUR_TYPE_LIST,
        baseURL: config.BASE_URL_TOUR_API,
        params: params
    }).get()
}

export function getTourTickets(tourId, departureDate) {
    return new ApiService({
        endpoint: Urls.TOUR_TICKETS,
        baseURL: config.BASE_URL_TOUR_API,
        params: { tourId, departureDate: departureDate ? moment(departureDate, FORMAT_ONLY_DATE).format('YYYY-MM-DD') : undefined },
        parser: (data) => data,
    }).get()
}

export function getTourNewWonderTickets(vinWonderId, vinWonderTicketId, departureDate) {
    return new ApiService({
        endpoint: Urls.TOUR_VINWONDER_TICKETS,
        baseURL: config.BASE_URL_TOUR_API,
        params: {
            VinWonderId: vinWonderId,
            VinWonderTicketId: vinWonderTicketId,
            DepatureDate: departureDate ? moment(departureDate, FORMAT_ONLY_DATE).format('YYYY-MM-DD') : undefined,
            // adultQuantity: null,
            // childQuantity: null,
            // elderQuantity: null
        },
        parser: (data) => data,
    }).get()
}


export const initQueryTourList = (value = {}, stringify = true) => {
    const params = {
        SearchTerm: value.keyword || '',
        TourTypeId: value.tourTypeId || '',
        MinPrice: value.minPrice || '',
        MaxPrice: value.maxPrice || '',
        LengthOfTour: value.lengthOfTour || '',
        Departure: value.departure || '',
        Destination: value.destination || '',
        PropertyId: value.place || '',
        ProductTypes: value.productTypes || '',
        FromDate: value.month ? moment(value.month, FORMAT_MONTH).startOf('month').format(FORMAT_ONLY_DATE) : '',
        ToDate: value.month ? moment(value.month, FORMAT_MONTH).endOf('month').format(FORMAT_ONLY_DATE) : '',
        PageSize: value.pageSize || DEFAULT_PAGE_SIZE,
        PageIndex: value.pageIndex || DEFAULT_PAGE_INDEX,
        OrderByDesc: value.orderByDesc || false,
        OrderBy: value.orderBy,
    }
    objectExtensions.deleteEmptyProps(params)
    return stringify ? queryString.stringify(params) : params
}