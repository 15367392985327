import actionTypes from '../actionTypes'
import { cloneDeep } from 'lodash'

const initState = {
  departureAirports: [],
  returnAirports: [],
  flights: {},
  verifyDetail: {},
  currentFlight: null,
  departureFlight: null,
  returnFlight: null,
  baggage: {},
  order: null,
  weekPrices: [],
  monthFlights: [],
  fareRule: null,
  reloadPhs: false,
  hold: false,
}

const flightReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_AIRPORT_SUCCESS:
      return Object.assign({}, state, {
        [`${action.payload.data.type}`]: action.payload.data.airports
      })
    case actionTypes.FETCHING_FLIGHT_SEARCH_SUCCESS:
      return Object.assign({}, state, {
        flights: action.payload.data
      })
    case actionTypes.FETCHING_FLIGHT_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        currentFlight: action.payload.data
      })
    case actionTypes.FETCHING_FLIGHT_BAGGAGE_SUCCESS:
      return Object.assign({}, state, {
        baggage: action.payload.data
      })
    case actionTypes.FETCHING_FLIGHT_WEEKPRICES_SUCCESS:
      return Object.assign({}, state, {
        weekPrices: action.payload.data
      })
    case actionTypes.FETCHING_FLIGHT_MONTH_SUCCESS:
      return Object.assign({}, state, {
        monthFlights: action.payload.data,
      })
    case actionTypes.POST_ORDER_DOM_RESERVATION_SUCCESS:
      return Object.assign({}, state, {})
    case actionTypes.FETCHING_ORDER_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        order: action.payload.data
      })
    case actionTypes.CHANGE_DEPARTURE_FLIGHT_SUCCESS:
      return Object.assign({}, state, {
        departureFlight: action.payload.data
      })
    case actionTypes.CHANGE_RETURN_FLIGHT_SUCCESS:
      return Object.assign({}, state, {
        returnFlight: action.payload.data
      })
    case actionTypes.SWITCH_AIRPORT:
      return Object.assign({}, state, {
        departureAirports: state.returnAirports,
        returnAirports: state.departureAirports
      })
    case actionTypes.FETCHING_FLIGHT_FARERULE_SUCCESS:
      return {
        ...state,
        fareRule: action.payload.data
      }
    case actionTypes.FETCHING_FLIGHT_VERIFY_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        verifyDetail: action.payload.data
      })
    case actionTypes.PHS_ORDER_HOTEL_RELOAD:
      return Object.assign({}, state, {
        reloadPhs: action.payload.data
      })
    case actionTypes.START_FETCHING_HOLD:
      return Object.assign({}, state, {
        hold: true
      })
    case actionTypes.STOP_FETCHING_HOLD:
      return Object.assign({}, state, {
        hold: false
      })
    default:
      return state
  }
}

export default flightReducer
