const ENUM_HOTEL = {
  category: "Hotel",
  list: "Room results",
  variant: null,
};
const ENUM_FLIGHT = {
  bookingServiceType: "Flight",
  category: "Flight",
  list: "Flight search results",
  list_depart: "Flight search results - Depart",
  list_return: "Flight search results - Return",
  flight_depart: "Round trip - Depart",
  flight_return: "Round trip - Return",
  variant: null,
  brand: null,
};
const ENUM_COMBO = {
  category: "Combo",
  list: "Combo search results",
};

const ENUM_SKU = {
  category: "Tour & Experience",
  list: "Tour Search Results",
  bestSellerList: "Recommendation_Similar_Products",
  specialSellerList: "Recommendation_Products_For_You",
};

export { ENUM_HOTEL, ENUM_FLIGHT, ENUM_COMBO, ENUM_SKU };
