import actionTypes from '../actionTypes'

const initState = {
  group: [],
  mapping: []
}

const tagReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_TAG_GROUP_SUCCESS:
      return Object.assign({}, state, {
        group: action.payload.data
      })
    case actionTypes.FETCHING_MAPPING_TAG_SUCCESS:
      // Data aggregation for tags-SKUs mapping to achieve result -> {skuId1: [tag1, tag2], skuId2: [tag3,tag4]...}
      const tagGroups = action.payload.data.result;
      const allTagGroupDetails = tagGroups.reduce((acc, next) => {
        return acc.concat(next.tagGroupDetails);
      }, [])

      const result = allTagGroupDetails.reduce((acc, next) => {
        const skus = next.tagGroupDetailSkus;
        skus.forEach((sku) => {
          if(!acc[sku.skuId]) {
            acc[sku.skuId] = []
          };
          acc[sku.skuId].push({
            bgColor: next.bgColor,
            color: next.color,
            createdDate: next.createdDate,
            id: next.id,
            name: next.name
          })
        })
        return acc;
      }, {})
      return Object.assign({}, state, {
        mapping: result
    })
    default:
      return state
  }
}

export default tagReducer
