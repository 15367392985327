import React, { useEffect, useState } from 'react'
import clsx from 'clsx';
import $ from 'jquery'
import { debounce } from 'lodash';


const BackTopButton = ({ className, visibilityHeight = 400, ...restProps }) => {

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    $(document.body).on('scroll', e => {
      const scrollTop = e.target.scrollTop;
      visibleToggle(scrollTop)
    })
  }, []);

  const visibleToggle = (scrollTop) => {
    debounce(() => setVisible(scrollTop > visibilityHeight), 600)()
  }

  const onClick = (e) => {
    if (visible) {
      $('html, body').animate({ scrollTop: 0 }, '100');
    }
  }

  return (
    <div className={clsx('btn-back-top', className, { 'show': visible })} {...restProps} onClick={onClick}>
      <i className="fa fa-angle-up btn-back-top-icon" aria-hidden="true"></i>
    </div>
  )
}

export default BackTopButton
