import actionTypes from '../actionTypes'

const initState = {
  cart: undefined,
  coupon: null,
  count: 0,
}

const cartReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CART:
      return Object.assign({}, state, {
        cart: action.cart,
        count: action.count || state.count,
      })
    case actionTypes.UPDATE_CART_COUNT:
      return Object.assign({}, state, {
        count: action.count,
      })
    case actionTypes.UPDATE_COUPON:
      return Object.assign({}, state, {
        coupon: action.coupon
      })
    default:
      return state
  }
}

export default cartReducer
