import React, { useEffect, useState } from 'react'
import { setAcceptCookie } from '@utils/cookie';
import { FormattedMessage } from 'react-intl'
import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons';
import './CookieSection.scss'
import { Cookie } from '@utils/index';
import cookie from 'js-cookie'
import { getLanguagePath } from 'src/helpers/functions';

const CookieSection = () => {
    const [isHidden, setIsHidden] = useState(false);

    const isAcceptInCookie = cookie.get(Cookie.STORAGE_KEY.ACCEPT_COOKIES)
    const isAcceptInSession = sessionStorage.getItem('hms_accept_cookie')
    
    useEffect(() => {
        const isAcceptCookie = isAcceptInCookie ? isAcceptInCookie : isAcceptInSession;
        if(isAcceptCookie){
            setIsHidden(true);
        }
    }, [isAcceptInCookie, isAcceptInSession]);

    const handleHidenModal = () => {
        setIsHidden(true);
        sessionStorage.setItem('hms_accept_cookie', true);
    }

    const handleAcceptCookies = () => {
        setIsHidden(true);
        setAcceptCookie();
    }

    return (
        <div
            className='cookie-section'
            style={{ display: isHidden ? 'none' : 'block' }}
        >
            <div className='container'>
                <div className='closeModal' onClick={handleHidenModal}>
                    <CloseOutlined />
                </div>
                <h4>
                    <FormattedMessage
                        id="Cookie.Title"
                        defaultMessage='Trang WEB sử dụng cookies'
                    />
                </h4>
                <p>
                    <FormattedMessage
                        id="Cookie.Content"
                        defaultMessage='Chúng tôi sử dụng cookies hoặc các cộng nghệ tương tự để nâng cao trải nghiệm trình duyệt web của bạn và cung cấp các đề xuất được cá nhân hóa. Bạn có thể đọc thêm các thông tin chính sách tại đây'
                        values={{
                            link: (
                                <a
                                    href={`https://booking.vinpearl.com/${getLanguagePath()}/dieu-khoan/chinh-sach-quyen-rieng-tu`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FormattedMessage
                                        id="Cookie.Content.Link"
                                        defaultMessage="tại đây"
                                    />
                                </a>
                            ),
                        }}
                    />
                </p>

                <div className='button-group'>
                    <Button className='disagree' onClick={handleHidenModal}>
                        <FormattedMessage
                            id="Cookie.Button.Disagree"
                            defaultMessage='Không đồng ý'
                        />
                    </Button>
                    <Button className='agree' onClick={handleAcceptCookies}>
                        <FormattedMessage
                            id="Cookie.Button.Agree"
                            defaultMessage='Đồng ý'
                        />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default CookieSection