import { enumType } from '../constants'
import actionTypes from '../actionTypes'

const initState = {
  stopover: [enumType.stopover.All],
  takeOffTime: [enumType.takeOff.All],
  landingTime: [enumType.takeOff.All],
  airlines: [enumType.airline.All],
  groupClasses: [enumType.seatSelection.All],
  sort: {
    [enumType.flightSort.TakeOff]: enumType.sortDirection.NONE,
    [enumType.flightSort.Landing]: enumType.sortDirection.NONE,
    [enumType.flightSort.Duration]: enumType.sortDirection.NONE,
    [enumType.flightSort.Price]: enumType.sortDirection.ASC,
  },
}

const filterReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_FILTER_FLIGHT:
      return Object.assign({}, state, {
        [`${action.payload.data.option}`]: action.payload.data.value && action.payload.data.value.length
          ? action.payload.data.value
          : [enumType.optionAll]
      })
    case actionTypes.RESET_FILTER_FLIGHT:
      return Object.assign({}, action?.payload?.data || initState)
    case actionTypes.CHANGE_SORT_FLIGHT:
      return Object.assign({}, state, {
        sort: {
          ...initState.sort,
          [`${action.payload.data.option}`]: action.payload.data.value
        }
      })
    default:
      return state
  }
}

export default filterReducer

