import React, { Fragment, useEffect, useRef, useState } from "react";

import { useGlobalStore } from "../../hooks/useGlobal";
import { headerMenu } from "../../constants/nav";
import "./Menu.scss";

import { useDeviceInfo } from "src/hooks/deviceInfo";
import { Popover, Tooltip } from "antd";
import { getConfig } from "@config/index";
import { useSelector } from "react-redux";
import { selectHotelsByLocation } from "src/reselect/hotelSelector";
import { hotelUtils } from "@utils/index";
import { FormattedMessage } from "react-intl.macro";
import HotelLocationSearch from "../Hotel/Components/HotelLocationSearch";
import { chain } from "lodash";
import clsx from "clsx";

const NavHeader = (props) => {
  const { isScrolled, isHomstay } = props;
  const {
    publicRuntimeConfig: { HOME_PAGE }, // Available both client and server side
  } = getConfig();
  const { isMobileMedium } = useDeviceInfo();

  const hotelRef = useRef(null);
  const homePageUrl = HOME_PAGE || "https://dev-vinpearl.vin3s.vn";
  const homePageStaynFunUrl = 'https://staynfun.vinpearl.com'

  const hotelLocations = useSelector(selectHotelsByLocation());
  const [data, setData] = useState([]);

  const getExternalLink = (menu, mapLinkHref = "link", isCurrency = false) => {
    const { state } = useGlobalStore();
    let linkreal = menu.link;
    if (mapLinkHref === "route") {
      linkreal = `/${state.lang}${isCurrency ? `-${state.currencyOnl}` : ""}${
        menu[mapLinkHref]
      }`;
    } else {
      if (menu.externalLink) {
        linkreal = `${menu.externalLink}/${state.lang}${
          isCurrency ? `-${state.currencyOnl}` : ""
        }${menu.link}`;
      }
    }

    return linkreal;
  };

  useEffect(() => {
    const groupHotels = chain(hotelLocations)
      .groupBy((x) => x.locationId)
      .map((items, key) => {
        const location = hotelLocations.find(
          (y) => y.locationId === key && y.type === "Location"
        );
        return {
          ...location,
          hotels: items.filter((x) => x.hotelName),
        };
      })
      .value();

    if (groupHotels?.length > 4) {
      groupHotels[2]["subGroup"] = [groupHotels[4]];
      groupHotels.splice(4, 1);
    }

    setData(groupHotels);
  }, [hotelLocations]);

  const renderHotelItem = (l, index) => {
    return (
      <div key={index} className="location-col">
        <div className="location-name">
          <img
            src={require("@assets/include/svg/s43/fi-rr-marker.svg").default}
            className="g-mr-6"
            alt=""
          />
          <a href={l.urlSlug}>{l.locationName}</a>
        </div>
        <div className="hotel-list">
          {l.hotels.map((h, i) => (
            <div
              key={i}
              className="hotel-name"
            >
               <a href={h.urlSlug}>{h.hotelName}</a>
            </div>
          ))}
        </div>
        {l.subGroup?.length && l.subGroup.map((s) => renderHotelItem(s,index))}
      </div>
    );
  };
  const renderItem = (data, index) => {
    return (
      <div key={index} className="location-col">
        <div className="location-name">
          <img
            src={require("@assets/include/svg/s43/fi-rr-marker.svg").default}
            className="g-mr-6"
            alt=""
          />
          <a href={getExternalLink(data)}>{data.title}</a>
        </div>
        {data?.children && (
          <div className="hotel-list">
            {data?.children.map((r, i) => (
              <div key={i} className="hotel-name">
                <a href={getExternalLink(r)}>{r.title}</a>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const content = (data = [], ref = null) => {
    let elementLeft;
    if (ref?.current) {
      const element = ref.current;
      elementLeft = element.offsetLeft;
    }
    return (
      <div
        className="s43-hotel-location-container"
        style={ref?.current && { width: 1170 - 118 - 45 - elementLeft }}
      >
        {data.map((l, index) =>
          ref?.current ? renderHotelItem(l, index) : renderItem(l, index)
        )}
      </div>
    );
  };

  return (
    <div className="main_menu_wrapper header-center-block">
      {isMobileMedium || (
        <div className="navbar-nav left-nav">
          {
            <Popover
              overlayClassName="s43-hotel-location-popover dropdown-w homepage-dropdown"
              placement="bottomLeft"
              content={content(data, hotelRef)}
            >
              <Tooltip>
                <div className={clsx("s43-hotel-location-btn", {
                  "nav_homestay": isHomstay
                })} ref={hotelRef}>
                  <FormattedMessage
                    id="Nav.Header.Hotel"
                    defaultMessage="Khách sạn"
                  />
                </div>
              </Tooltip>
            </Popover>
          }
          {headerMenu
            .filter((r) => r?.className?.includes("left-nav"))
            .map((record, index) => (
              <Fragment key={index}>
                {record?.className?.includes("experience") ? (
                  <Popover
                    overlayClassName="s43-hotel-location-popover dropdown-w homepage-dropdown"
                    placement="bottomLeft"
                    content={content(record.children)}
                  >
                    <Tooltip>
                      <div className={clsx("s43-hotel-location-btn", {
                        "nav_homestay": isHomstay
                      })}>
                        {record.title}
                      </div>
                    </Tooltip>
                  </Popover>
                ) : (
                  <div className={clsx("s43-hotel-location-btn", {
                    "nav_homestay": isHomstay
                  })} key={index}>
                    <a href={getExternalLink(record)}>{record.title}</a>
                  </div>
                )}
              </Fragment>
            ))}
        </div>
      )}

      <div className="center-menu-icon">
        {
          isHomstay ? 
            <a title="homepage" href={homePageStaynFunUrl}>
              {isScrolled ? (
                <img
                  style={{ marginBottom: -3, width: 137 }}
                  src={
                    require("@assets/img/staynfun/logo-staynfun_2.svg").default
                  }
                  alt="Vinpearl"
                />
              ) : (
                <img
                  style={{ marginBottom: -4, width: 137 }}
                  src={
                    require("@assets/img/staynfun/logo-staynfun_1.svg").default
                  }
                  alt="Vinpearl"
                />
              )}
            </a> : 
            <a title="homepage" href={homePageUrl}>
              {isScrolled ? (
                <img
                  style={{ marginBottom: 5 }}
                  src={
                    require("@assets/img/hotel-logos/vinpearl-logo@2x.svg").default
                  }
                  alt="Vinpearl"
                />
              ) : (
                <img
                  style={{ marginBottom: -8, width: 95 }}
                  src={
                    require("@assets/img/hotel-logos/vinpearl-logo-1@2x.svg")
                      .default
                  }
                  alt="Vinpearl"
                />
              )}
            </a>
        }
      </div>

      {isMobileMedium || (
        <div className="navbar-nav right-nav">
          {headerMenu
            .filter((r) => r?.className?.includes("right-nav"))
            .map((record, index) => (
              <Fragment key={index}>
                {record?.className?.includes("pearl-club") ? (
                  <Popover
                    overlayClassName="s43-hotel-location-popover dropdown-w homepage-dropdown"
                    placement="bottomLeft"
                    content={record.children.map((child, index) => (
                      <div className="pearl-club-list" key={index}>
                        <a href={getExternalLink(child)}>{child.title}</a>
                      </div>
                    ))}
                  >
                    <div className={clsx("s43-hotel-location-btn", {
                      "nav_homestay": isHomstay
                    })}>
                      <a href={getExternalLink(record)}>{record.title}</a>
                    </div>
                  </Popover>
                ) : (
                  <div className={clsx("s43-hotel-location-btn", {
                    "nav_homestay": isHomstay
                  })} key={index}>
                    <a href={getExternalLink(record)}>{record.title}</a>
                  </div>
                )}
              </Fragment>
            ))}
        </div>
      )}
    </div>
  );
};

export default NavHeader;
