import moment from 'moment'
import { MINIMUM_YEAR } from '../constants'
import { getConfig } from '@config'
import {
  productTypeEnumMapping,
  routingTypeEnumMapping,
  routingWithSlugTypeEnumMapping
} from '../constants/enumType';
import { languagePath } from 'src/constants/language';
import {
  currencyFind,
  currencyFindSpecial
} from 'src/constants/currencyUnit'

const {
  publicRuntimeConfig: {
    HOME_PAGE
  } // Available both client and server side
} = getConfig()

export function getMomentDate(dateString, format = undefined, convertUTC = true) {
  const utcDate = convertUTC ? moment.utc(dateString, format).toDate() : moment(dateString, format);
  const date = dateString ? new moment(utcDate) : undefined;
  return date && date.isAfter(`${MINIMUM_YEAR}`, 'year') ? date : undefined
}

export function formatPhoneNumber(phoneString) {
  if (phoneString && phoneString.startsWith('+840')) {
    return phoneString.replace('+840', '+84').replace(' ', '')
  }
  return phoneString
}

export function getNumOfItemsInCart(cart) {
  return (cart ? ((cart.tours ? cart.tours.length : 0) + (cart.vouchers ? cart.vouchers.length : 0) || '') : '');
}

export function formatImageSrc(src) {
  if (src && !src.startsWith('http://') && !src.startsWith('https://') && !src.startsWith('//')) {
    return '//' + src;
  }
  return src;
}

export const checkArrayParams = (param) => {
  if (typeof param === 'string') {
    return [param]
  }
  return param || []
}

export function retryRequest(maxRetry = 2, requestAsyncFunc, isContinueCheck, status = 200, delay = 100, retry = 1) {
  return new Promise((resolve, reject) => {
    const wait = ms => new Promise(r => setTimeout(r, ms));
    const errorHandle = (error) => {
      if ((!isContinueCheck || isContinueCheck()) && retry < maxRetry) {
        return wait(delay)
          .then(retryRequest.bind(null, maxRetry, requestAsyncFunc, isContinueCheck, status, delay, retry + 1))
          .then(resolve, reject);
      }
      return reject(error);
    }
    return requestAsyncFunc().then(response => {
      if (response.status === status && !response.errors) {
        return resolve(response)
      } else {
        return errorHandle(response.errors);
      }
    }).catch(errorHandle);
  })
}

export function getDatesArray(start, end) {
  for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
};

export function replaceHrefLocalhostWithDev(href) {
  return href && href.replace('http://localhost:3000', HOME_PAGE || 'https://vpt-frontend-website-v2.dev.cloudhms.io')
}

export function getProductPathByType(slug, productType) {
  const itemType = productTypeEnumMapping[parseInt(productType) || 1];
  const routePrefix = routingTypeEnumMapping[itemType];
  const asPath = `${routePrefix}/${slug}`
  const detailSlug = routingWithSlugTypeEnumMapping[itemType]
  return { asPath, detailSlug, itemType }
}

export const screenWidth = {
  sm: 768, md: 992, xl: 1280,
  isSmall(width) {
    return width ? width < this.sm : typeof window !== undefined ? matchMedia(`(max-width: ${this.sm - 1}px)`).matches : false;
  },
  isMedium(width) {
    return width ? width >= this.sm && width < this.md : typeof window !== undefined ? matchMedia(`(min-width: ${this.sm}px) and (max-width: ${this.md - 1}px)`).matches : false;
  },
  isLarge(width) {
    return width ? width >= this.md : typeof window !== undefined ? matchMedia(`(min-width: ${this.md}px)`).matches : false;
  },
  isXLarge(width) {
    return width ? width >= this.xl : typeof window !== undefined ? matchMedia(`(min-width: ${this.xl}px)`).matches : false;
  }
}

export const snakeToCamel = (str) => str.toLowerCase().replace(
  /([-_][a-z])/g,
  (group) => group.toUpperCase()
    .replace('-', '')
    .replace('_', '')
);

export const camelToSnake = (str) => str.split(/(?=[A-Z])/).join('_').toLowerCase();

export const objectKeysSnakeToCamel = (obj) => obj && reduce(Object.keys(obj), (result, key) => {
  result[snakeToCamel(key)] = obj[key];
  return result;
}, {})

export const objectKeysCamelToSnake = (obj) => obj && Object.keys(obj).reduce((result, key) => {
  result[camelToSnake(key)] = obj[key];
  return result;
}, {})

export function vietnameseStringToUnicode(vnString) {
  vnString = vnString.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
  vnString = vnString.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
  vnString = vnString.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
  vnString = vnString.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
  vnString = vnString.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
  vnString = vnString.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
  vnString = vnString.replace(/đ/gi, 'd');
  return vnString;
}

export function specialCharacter (specialChar) {
  specialChar = specialChar.replace(/0|1|2|3|4|5|6|7|8|9/gi, '')
  specialChar = specialChar.replace(/[^\w\s]/gi, '');
  return specialChar;
}

export function specialCharacterNote (sourceString) {
  let outString = sourceString.replace(/[^a-zA-Z0-9 \nÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼẾỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽếềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ/.,]/g, "");
  return outString
}

export function handleWindowUnload(callback) {
  if (typeof window !== 'undefined') {
    window.onbeforeunload = callback;
    return () => window.onbeforeunload = null
  }
  return () => { }
}

export const parsePathToRoute = (path = '') => {
  return path.replace(/\/:[a-z0-9_-]+\/*/g, (match) => {
    const isEndPath = match.endsWith('/');
    const param = match.replace('/', '').replace(':', '')
    const result = `/[${param}]` + (isEndPath ? '/' : '')
    return result
  })
}

export const parseRouterLocaleToPath = (path = '', locale = '', localeLang = null) => {
  if(languagePath.includes(localeLang || locale)) {
    return `/${locale}${path}`
  }
  return path
}

export const parseRouteToPath = (path = '') => {
  return path && path.replace('/[', '/:').replace(']', '')
}

export function isExternalLink(href) {
  return typeof href === 'string' && href.startsWith('http')
}

export function convertLinkLocale(languageSelect = '', url = '', currency = 'VND', isCurrency = false) {

  const location = window.location
  const path = url ? url : location.pathname
  const params = url ? '' : location.search
  let newPath = ''
  // const currentLocale = path.length === 3 ? path.match(/\/(vi|en|kr|cn)/g) : path.match(/\/(vi|en|kr|cn)\//g)
  const currentLocale = path.match(/\/(vi|en|ko|zh)/g);
  const currentCurrency = path.length === 7 ? path.match(currencyFind) 
  : path.match(currencyFindSpecial);
  let indexLocale = -1;
  if(currentLocale && currentLocale.length > 0) {
    indexLocale = path.search(currentLocale[0]);
    if(indexLocale === 0 ){
      newPath = path.replace(currentLocale[0], `/${languageSelect}`);
      if(isCurrency){
        if(currentCurrency && currentCurrency.length > 0) {
          newPath = newPath.replace(currentCurrency[0], newPath.length === 7 ? `-${currency}` : `-${currency}/`);
        }else{
          newPath = newPath.replace(languageSelect, newPath.length === 3 ? `${languageSelect}-${currency}/` : `${languageSelect}-${currency}` );
        }
      }
    }
    // newPath = path.replace(currentLocale[0], path.length === 3 ? `/${languageSelect}` : `/${languageSelect}/`);
  } 
  if(indexLocale !== 0) {
    newPath = `/${languageSelect}-${currency}${path}`
  }
  return newPath + params
}

export const checkRoleCollaborators = (navKey, userRole) => {
  return navKey !== "collaborators" || userRole === "Collaborator";
}
