import axios from 'axios'
import { getConfig } from '@config'
import { clone } from 'lodash'

import { getAuthCookies, getAuthHeader, setCookiesLogin } from '../../utils/auth'
import { getAcceptLanguage, getDisplayCurrency } from '../../utils/lang'
import RetryInterceptor, { axiosCancelToken } from 'src/api/RetryInterceptor'
import AuthorRequestInterceptor, { isLoadingRefreshToken, refreshTokenSubscribers, requestFullfill } from 'src/api/AuthorRequestInterceptor'
import Urls from 'src/api/Urls'
import moment from 'moment'

const { auth } = getAuthCookies()

const {
  publicRuntimeConfig: {
    API_BASE_URL,
    API_BASE_AUTH_URL,
    API_BASE_PAYMENT_URL,
    API_BASE_ORDER_URL,
    API_BASE_HOTEL_URL,
    API_BASE_CMS_URL,
    API_BASE_VOUCHER_URL,
    API_BASE_PROMOTION_URL,
    API_BASE_CART_URL,
    API_BASE_VIN_3S_URL,
    LOGIN_BASE_URL,
    VIN3S_BASE_URL
  } // Available both client and server side
} = getConfig()

export const axiosConfig = {
  cancelToken: axiosCancelToken.token,
  // withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Headers': 'Accept, X-Requested-With, Content-Type, Authorization, Access-Control-Allow-Headers',
    'accept-language': getAcceptLanguage(),
    'x-display-currency': getDisplayCurrency(),
    'Authorization': getAuthHeader()
  },
  // timeout: 30000
  timeout: 180000
}

export const axiosProviderLoginConfig  = {
    // withCredentials: true,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Credentials': true,
      // 'Access-Control-Allow-Headers': 'Accept, X-Requested-With, Content-Type, Authorization, Access-Control-Allow-Headers',
      'accept-language': getAcceptLanguage(),
      // 'x-display-currency': getDisplayCurrency(),
      'Authorization': getAuthHeader()
    },
    // timeout: 30000
    timeout: 180000
}



export const createAxiosProvider = (baseURL = API_BASE_URL, config = axiosConfig, timeout = undefined) => {
  const provider = axios.create({
    baseURL,
    ...(config || {}),
  })
  if ((config && config.timeout) || timeout) provider.defaults.timeout = timeout || config.timeout;
  const result = clone(provider)
  const rebindHeaders = (method, headers = {}) => {
    return (...params) => {
      Object.keys(headers).forEach(headerName => {
        if (provider.defaults.headers[headerName]) {
          provider.defaults.headers[headerName] = headers[headerName]();
        }
      })
      return provider[method](...params)
    }
  }
  const bindingHeaders = {
    'accept-language': getAcceptLanguage,
    'x-display-currency': getDisplayCurrency,
    'Authorization': getAuthHeader
  }
  // bindingHeaders.Authorization = getAuthHeader
  result.get = rebindHeaders('get', bindingHeaders)
  result.post = rebindHeaders('post', bindingHeaders)
  result.put = rebindHeaders('put', bindingHeaders)
  result.delete = rebindHeaders('delete', bindingHeaders)
  // interceptor
  const _addDefaultInterceptors = () => {
    // const authenticationInterceptor = new AuthorRequestInterceptor(provider);
    // provider.interceptors.request.use(authenticationInterceptor.requestFullfill);
    provider.interceptors.request.use(
      (config) => requestFullfill(config, provider),
      error => Promise.reject(error)
    );
    
    // const retryInterceptor = new RetryInterceptor(provider);
    // provider.interceptors.response.use(
    //     retryInterceptor.responseFulfilled,
    //     retryInterceptor.responseReject
    // );
  }
  _addDefaultInterceptors();
  return result;
}
// console.log('axiosConfig............', axiosConfig)
export const axiosProvider = createAxiosProvider(API_BASE_URL || 'https://vpt-flight-api-v2.dev.cloudhms.io/api')

export const axiosProviderAuth = createAxiosProvider(API_BASE_AUTH_URL || 'https://vpt-identity-api-v2.dev.cloudhms.io/api')

export const axiosProviderFaceBookAuth = createAxiosProvider(API_BASE_AUTH_URL || 'https://vpt-identity-api-v2.dev.cloudhms.io/api')

export const axiosProviderPayment = createAxiosProvider(API_BASE_PAYMENT_URL || 'https://vpt-payment-api-v2.dev.cloudhms.io/api', {}, axiosConfig.timeout)

export const axiosProviderOrder = createAxiosProvider(API_BASE_ORDER_URL || 'https://vpt-order-api-v2.dev.cloudhms.io/api')

export const axiosProviderHotel = createAxiosProvider(API_BASE_HOTEL_URL || 'https://vpt-hotel-api-v2.dev.cloudhms.io')

export const axiosProviderVoucher = createAxiosProvider(API_BASE_VOUCHER_URL || 'https://vpt-voucher-api-v2.dev.cloudhms.io/api')

export const axiosProviderCms = createAxiosProvider(API_BASE_CMS_URL || 'https://vpt-cms-api-v2.dev.cloudhms.io/api')

export const axiosProviderCart = createAxiosProvider(API_BASE_CART_URL || 'https://vpt-cart-api-v2.dev.cloudhms.io/api')

export const axiosProviderPromotion = createAxiosProvider(API_BASE_PROMOTION_URL || 'https://vpt-promotion-api-v2.dev.cloudhms.io/api/')

export const axiosProviderVin3S = API_BASE_VIN_3S_URL || 'https://www.dev.vp-web.cloudhms.io/'

export const axiosProviderLogin = createAxiosProvider(LOGIN_BASE_URL || 'https://api.dev.cloudhms.io/', axiosProviderLoginConfig)

export const axiosProviderVin3SDomain = createAxiosProvider(VIN3S_BASE_URL || 'https://vin3s-dev.au.auth0.com/', axiosProviderLoginConfig)

export const axiosProviderVinPearl = createAxiosProvider(axiosProviderVin3S, {}, axiosConfig.timeout)

export default axiosProvider
