import { find, get } from 'lodash'
import { enumType, VINWONDER_ENUM, FORMAT_ONLY_DATE } from '../constants';
import moment from 'moment'

export default {
    getCartItemFromVinWonderTicket(ticket={}, usingDate = undefined, siteCode = undefined) {
        const ticketItems = (ticket.ticketItems || [])
        const adult = find(ticketItems, item => item.ticketType === VINWONDER_ENUM.TICKET.TYPE.ADULT.id);
        const child = find(ticketItems, item => item.ticketType === VINWONDER_ENUM.TICKET.TYPE.CHILD.id);
        const senior = find(ticketItems, item => item.ticketType === VINWONDER_ENUM.TICKET.TYPE.SENIOR.id);

        return {
            id: ticket.id,
            cartItemType: enumType.BOOKING_TYPE.VINWONDER,
            adultQuantity: adult && adult.quantity ? adult.quantity : 0,
            originalPrice: adult && adult.originalPrice,
            salePrice: adult && adult.salePrice,
            childOriginalPrice: child && child.originalPrice,
            childSalePrice: child && child.salePrice,
            childQuantity: child && child.quantity,
            seniorOriginalPrice: senior && senior.originalPrice,
            seniorSalePrice: senior && senior.salePrice,
            seniorQuantity: senior && senior.quantity,
            ticketItemId: adult && adult.id,
            childTicketItemId: child && child.id,
            seniorTicketItemId: senior && senior.id,
            usingDate: moment(usingDate, FORMAT_ONLY_DATE).utcOffset(0, true).toISOString(),
            siteCode: siteCode,
            sessionId: ticket.sessionId,
            zonePickupId: ticket.zonePickupId,
            zoneReleaseId: ticket.zoneReleaseId,
            tourId: ticket.tourId
        }
    },
    getTotalQuantityTicketItems(items){

    },
    checkVinWonderOriginalPrice(originalPrice, salePrice){
        if(salePrice && originalPrice && originalPrice <= salePrice){
            return 0;
        }
        return originalPrice;
    },
    formatFullTime(value) {
        return value < 10 ? `0${value}` : value
    },
    checkBlackOutDate(currentDate, blackOut = {}) {
        if (blackOut.startDate && blackOut.endDate) {
            return currentDate.isBetween(blackOut.startDate, blackOut.endDate, 'dates', '[]'); // included start date and end date also
        }
        return false;
    }
}