import React, { useEffect, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from "react-helmet";
import MetaTags from 'react-meta-tags';
import { getConfig } from '@config'

//component

import Header from './Header'
import Footer from './Footer'
import References from './References'
import Ga from '../Ga/Ga'
import Gtm from '../Ga/Gtm'
import AT from '../AT/AT';

import ErrorModal from '../Modal/ErrorModal'
import AuthErrorModal from '../Modal/AuthErrorModal'
import NotifyModal from '../Modal/NotifyModal'
import LoginModal from '../Modal/LoginModal'
import StickyAds from '../HomePage/StickyAds'
import { getPageBannerCallback } from '../../hooks/voucher'
import { selectPageBanner } from '../../reselect/bannerSelector'
import BackTopButton from '../Elements/BackTopButton'
import { getCurrentLanguage } from '@utils/lang';
import { ACCESSTRADE, HomepagePathname } from 'src/constants';
import { toArray } from 'lodash';
import { getRedirectLinkCallback } from 'src/hooks/siteInfo';
import { selectRedirectLink } from 'src/reselect/footerSelector';
import { useDeepCompareEffect } from 'react-use';
import { isValidDomain } from 'src/helpers/functions';
import Chatbot from '../../components/Chatbox/Chatbox'
import { useLocation } from 'react-router-dom';
import HomepageHeader from './HomepageHeader';
import CookieSection from '../Cookies/CookieSection';


const {
  publicRuntimeConfig: {
    FB_APP_ID, HOME_PAGE, GA_CODE, GG_TAGS
  } // Available both client and server side
} = getConfig()

const GA_CODE_ENUM = GA_CODE || 'GMT-5MS48N2'
const GA_TRACKING_CODE = null //process.env.GA_CODE || 'GMT-5MS48N2' //process.env.GA_TRACKING_CODE || 'UA-7565194-45'
const GG_TAGS_ENUM = GG_TAGS || 'AW-10966934706'

const Document = ({ children, metaTag, head }) => {
  const {pathname} = useLocation()
  const isHomepage = HomepagePathname.some(path=>pathname.endsWith(path))

  const metas = metaTag && Object.assign(metaTag, {
    url: HOME_PAGE + metaTag.url,
  })

  const loadPageBanner = getPageBannerCallback()
  const getRedirectLink = getRedirectLinkCallback()
  const banners = useSelector(selectPageBanner())
  const infoRedirectLink = useSelector(selectRedirectLink())
  const currentLang = getCurrentLanguage()
  
  useEffect(() => {
    if (!(banners && banners.length)) {
      loadPageBanner()
    }
    getRedirectLink(window.location.href)
  }, [])  

  useDeepCompareEffect(() => {
    if(!!infoRedirectLink && infoRedirectLink.isActive && isValidDomain(infoRedirectLink.toLink)) {
      window.location.href = infoRedirectLink.toLink
    }
  }, [infoRedirectLink])

  const renderSeoMeta = (fieldName, content, propertyPrefix = 'og') => {
    if (!fieldName || !content) return null
    const property = `${propertyPrefix}:${fieldName}`;
    const metaProps = { property };

    return Array.isArray(content)
      ? (
        <Fragment>
          {(content).map(item => <meta key={property} {...metaProps} content={String(item.url || item)} />)}
        </Fragment>
      )
      : <meta key={property} {...metaProps} content={String(content)} />
  }

  return (
    <Fragment>
      <Helmet>
        <script async defer crossOrigin="anonymous"
          src={`https://connect.facebook.net/vi_VN/sdk.js#xfbml=1&version=v8.0&appId=${FB_APP_ID || ''}&autoLogAppEvents=1`}>
        </script>
        <title>Vinpearl</title>
        <Ga code={GA_CODE_ENUM} trackingCode={GA_TRACKING_CODE} />
        {head ? head : null}
      </Helmet>
      {metas ? (
        <MetaTags>
          {Object.keys(metas).map(key => renderSeoMeta(key, metas[key]))}
          {renderSeoMeta('locale', currentLang, 'og')}
          {renderSeoMeta('app_id', FB_APP_ID, 'fb')}
        </MetaTags>
      ) : null}
      <div className="vpt-main">
        {isHomepage ? <HomepageHeader /> : <Header />}
        {children}
        {/* 4952: hide References */}
        {/* <References /> */}
        <Footer />
        <StickyAds position="bottom" />
        <ErrorModal />
        <AuthErrorModal />
        <NotifyModal />
        <LoginModal />
        <BackTopButton />
        <AT campaignId={ACCESSTRADE.CAMPAIGN_ID} isReoccur={ACCESSTRADE.IS_REOCCUR}
          isLastclick={ACCESSTRADE.IS_LASTCLICK} />
        <CookieSection />
      </div>
      {/* 4952: hide chatbot */}
      {/* <Chatbot /> */}
    </Fragment>
  )
}

export default Document
