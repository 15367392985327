import gtm, { gtmPushDataProduceCart } from "@utils/gtm";
import {
  actionCartSKUClick,
  createHotelCart,
  createGtmFlightCart,
  createGtmAddToCartCombo,
  createGtmAddFlightToCartCombo,
} from "@utils/gtmEventConvert";
import { gtmEvent, gtmType } from "src/helpers/gtmEvent";
import { createProductClick } from "@utils/gtmEventConvert";
import { cloneDeep } from "lodash";
import moment from "moment";
import e from "cors";
import { FlightAddToCart } from "src/model/insiderModel/FlightAddToCart.model";

const gtmProduceCart = ({
  voucher = {},
  index = 0,
  type = gtmType.TOUR_AND_EXPERIENCE,
  arrAdd = [],
  actionType = "add",
  quantity = 0,
}) => {
  const flightProductUrl = window.location.href;
  // Thumbnail Flight [product_add_to_cart];
  const flightThumbnailUrlFlight1 = voucher?.segments && voucher?.segments[0]?.airlineImage || undefined;
  const flightThumbnailUrlFlight2 = voucher?.segments && voucher?.listSegment[0]?.airlineImage || undefined;
  let produceClickData;
  let event_name = 'flight_add_to_cart';
  switch (type) {
    case gtmType.ACTION_CART_SKU:
      produceClickData = actionCartSKUClick({
        arrItem: arrAdd,
        voucher: voucher,
        eventType:
          actionType === "add"
            ? gtmEvent.PRODUCT_ADD_TO_CART
            : gtmEvent.PRODUCT_REMOVE_FROM_CART,
        actionType,
        quantity,
      });
      event_name = actionType === "add"
        ? gtmEvent.PRODUCT_ADD_TO_CART
        : gtmEvent.PRODUCT_REMOVE_FROM_CART
      break;
    case gtmType.HOTEL_ADD_TO_CART:
      produceClickData = createHotelCart({
        data: voucher,
        action: actionType,
      });
      event_name = 'hotel_add_to_cart'
      break;
    case gtmType.FLIGHT_ADD_TO_CART:
      produceClickData = createGtmFlightCart({
        productDetail: voucher,
        action: actionType,
      });
      event_name = 'flight_add_to_cart'
      break;
    case gtmType.FLIGHT_REMOVE_FROM_CART:
      produceClickData = createGtmFlightCart({
        productDetail: voucher,
        action: actionType,
      });
      event_name = 'flight_remove_from_cart'
      break;
    case gtmType.COMBO_ADD_TO_CART:
      produceClickData = createGtmAddToCartCombo({
        comboInfo: voucher?.comboInfo,
        comboData: voucher?.comboData,
        dataRooms: voucher?.dataRooms,
        actionType,
      });
      event_name = 'combo_add_to_cart';
      break;
    case gtmType.COMBO_FLIGHT_ADD_TO_CART:
      produceClickData = createGtmAddFlightToCartCombo({
        flight: voucher?.flight,
        comboInfo: voucher?.comboInfo,
        comboData: voucher?.comboData,
        isAll: voucher?.isAll,
        isReturnFlight: voucher?.isReturnFlight,
        actionType,
      });
      event_name = 'flight_add_to_cart';
      break;
    default:
      // produceClickData = createProductClick(voucher, index);
      break;
  }
  //Flight Insider Add To Cart
  const ecommerceObjectInsider = cloneDeep(produceClickData);
  const flightAddToCartArray = ecommerceObjectInsider && ecommerceObjectInsider?.ecommerce?.[actionType]?.products;
  const flightAddToCartArrayResult =
    flightAddToCartArray &&
    flightAddToCartArray.map(
      (el) =>
        new FlightAddToCart(el, {
          ...ecommerceObjectInsider,
          flightProductUrl,
          flightThumbnailUrlFlight1,
          flightThumbnailUrlFlight2,
        })
  )
  
  var currentTime = new Date();
  if(window?.Insider){
    window.Insider.track('events', (flightAddToCartArrayResult || []).map(e =>({
      event_name,
      "timestamp": currentTime.toISOString().slice(0, -5)+"Z",
      "event_params": {
        source: "web",
        custom: e
      }
    })))
  }
  gtmPushDataProduceCart({ produceClickData, actionType: actionType, type });
};
export default gtmProduceCart;
