import moment from 'moment'
import {
  DATE_RANGE,
  enumType,
  FORMAT_DATE_TIME,
  FORMAT_DATE_TIME_AFTER,
  FORMAT_ONLY_DATE,
  FORMAT_ONLY_DATE_REVERSE,
  FORMAT_ONLY_HOUR,
  FORMAT_ONLY_MONTH,
  LOCAL_STORAGE_KEY
} from '../constants'
import { stringExtensions } from './index'
import { getLanguageCookie, getCurrentLanguage } from '../utils/lang'
import { getMomentDate } from '../utils/common'
import { language } from '../constants/language'
import { cloneDeep } from 'lodash'
import { LocalStorage } from '../utils';

const currentLanguage = getLanguageCookie()

const LOCALE = currentLanguage;

/**
 * get start date of range from current date
 * @param currentDate
 * @returns {moment.Moment}
 */
const getStartDate = (currentDate) => {
  if (!currentDate) {
    return moment(new Date())
  }
  if (moment(currentDate).diff(moment(new Date()), 'days') > 2) {
    return moment(currentDate).subtract(3, 'days')
  }
  return moment(new Date())
}

export default {

  /**
   * init new date range with specific date
   * @param currentDate
   * @param range
   * @returns {moment.Moment[]}
   */
  getDataRangeFromSpecificDate(currentDate, range) {
    const startDate = getStartDate(currentDate)

    const duration = range || DATE_RANGE
    return Array.from(Array(duration),
      (date, index) => moment(startDate).add(index, 'days'))
  },

  /**
   * convert min to time
   * @param minute
   * @returns {string|null}
   */
  convertMinToTime(minute, delimiter = '', unitDelimiter = '') {

    const lang = LocalStorage.get(LOCAL_STORAGE_KEY.LANGUAGE_WESBITE)

    if (!minute) {
      return null
    }
    const localeUnits = {
      [language.English]: {
        'y': 24 * 60 * 365,
        'mon': 24 * 60 * 30,
        'w': 24 * 60 * 7,
        'd': 24 * 60,
        'h': 60,
        'm': 1
      },
      [language.Vietnamese]: {
        'năm': 24 * 60 * 365,
        'tháng': 24 * 60 * 30,
        'tuần': 24 * 60 * 7,
        'ngày': 24 * 60,
        'giờ': 60,
        'phút': 1
      },
      [language.Korean]: {
        '년도': 24 * 60 * 365,
        '월': 24 * 60 * 30,
        '주': 24 * 60 * 7,
        '일': 24 * 60,
        '시간': 60,
        '분': 1
      },
      [language.Chinese]: {
        '年': 24 * 60 * 365,
        '月': 24 * 60 * 30,
        '星期': 24 * 60 * 7,
        '日': 24 * 60,
        '小时': 60,
        '分钟': 1
      },
    }
    const units = localeUnits[lang.data] || localeUnits[language.English]

    let result = []

    for (let name in units) {
      const p = Math.floor(minute / units[name])
      if (p > 0) {
        result.push(`${p}${unitDelimiter}${name}`)
      }
      minute %= units[name]
    }

    return result.join(delimiter)
  },

  checkIsPreviousMoment(currentDate, dateCheck) {
    if (currentDate && dateCheck) {
      return moment(currentDate).isBefore(moment(dateCheck))
    }
    return false
  },

  checkIsFutureMoment(currentDate, dateCheck) {
    if (currentDate && dateCheck) {
      return !!(currentDate && dateCheck &&
        (moment(currentDate).isAfter(moment(dateCheck)))
      )
    }
    return false
  },

  checkIsPreviousDate(currentDate, dateCheck) {
    if (currentDate && dateCheck) {
      return moment(currentDate).isBefore(moment(dateCheck), 'day')
    }
    return false
  },

  checkIsFutureDate(currentDate, dateCheck) {
    if (currentDate && dateCheck) {
      return !!(currentDate && dateCheck &&
        (moment(currentDate).isAfter(moment(dateCheck), 'day'))
      )
    }
    return false
  },

  /**
   * check current date is before date check
   * @param currentDate
   * @param dateCheck
   * @returns {boolean}
   */
  checkIsPreviousVnDate(currentDate, dateCheck) {
    if (currentDate && dateCheck) {
      return !!(currentDate && dateCheck &&
        moment(currentDate, FORMAT_ONLY_DATE).isBefore(moment(dateCheck, FORMAT_ONLY_DATE)))
    }
    return false
  },

  /**
   * check current date is after date check
   * @param currentDate
   * @param dateCheck
   * @returns {boolean}
   */
  checkIsFutureVnDate(currentDate, dateCheck) {
    if (currentDate && dateCheck) {
      return !!(currentDate && dateCheck &&
        moment(currentDate, FORMAT_ONLY_DATE).isAfter(moment(dateCheck, FORMAT_ONLY_DATE)))
    }
    return false
  },

  checkIsSameVnDate(currentDate, dateCheck) {
    if (currentDate && dateCheck) {
      return !!(currentDate && dateCheck &&
        moment(currentDate, FORMAT_ONLY_DATE).isSame(moment(dateCheck, FORMAT_ONLY_DATE)))
    }
    return false
  },

  /**
   * check is same day
   * @param currentDate
   * @param dateCheck
   * @returns {boolean}
   */
  checkIsSameDay(currentDate, dateCheck) {
    if (currentDate && dateCheck) {
      return moment(currentDate).isSame(dateCheck, 'day')
    }
    return false
  },

  formatVnDateToISO(currentDate) {
    return currentDate
      ? moment(currentDate, FORMAT_ONLY_DATE).format()
      : null
  },

  /**
   * format date: DD/MM/YYYY
   * @param currentDate
   * @returns {any}
   */
  formatVnDate(currentDate) {
    return currentDate
      ? moment(currentDate).format(FORMAT_ONLY_DATE)
      : null
  },

  /**
   * format vn date: HH:mm
   * @param currentDate
   * @returns {any}
   */
  formatOnlyTime(currentDate) {
    return currentDate
      ? moment(currentDate).format(FORMAT_ONLY_HOUR)
      : null
  },

  /**
   * format vn date time: HH:mm DD/MM/YYYY
   * @param currentDate
   * @returns {any}
   */
  formatVnDateTime(currentDate) {
    return currentDate
      ? moment(currentDate).format(FORMAT_DATE_TIME)
      : null
  },

  /**
   * @param date
   * @param formatOutput
   * @param fotmatInput
   * @returns {string}
   */
  formatMomentDate(date, formatOutput, formatInput) {
    const momentDate = getMomentDate(date, formatInput);
    return momentDate && momentDate.format(formatOutput);
  },

  /**
   *
   * @param currentDate
   * @returns {any}
   */
  formatVnDateTimeAfter(currentDate) {
    return currentDate
      ? moment(currentDate).format(FORMAT_DATE_TIME_AFTER)
      : null
  },
  /**
   * format vn time and day: HH:mm dddd
   * @param currentDate
   * @returns {any}
   */
  formatVnTimeAndDay(currentDate) {
    return currentDate
      ? stringExtensions.capitalizeWords(
        moment(currentDate).locale(getCurrentLanguage()).format('DD:mm dddd'))
      : null
  },

  /**
   * format vn full date: dddd DD/MM/YYYY
   * @param currentDate
   * @returns {any}
   */
  formatVnFullDate(currentDate) {
    return currentDate
      ? stringExtensions.capitalizeWords(moment(currentDate, FORMAT_ONLY_DATE).locale(getCurrentLanguage()).format('dddd, DD/MM/YYYY'))
      : null
  },

  /**
   * format only day: dddd
   * @param currentDate
   * @returns {*}
   */
  formatOnlyDay(currentDate) {
    return currentDate
      ? stringExtensions.capitalizeWords(
        moment(currentDate).locale(getCurrentLanguage()).format('dddd'))
      : null
  },

  /**
   * format day and month: DD/MM
   * @param currentDate
   * @returns {any}
   */
  formatDayAndMonth(currentDate) {
    return currentDate
      ? moment(currentDate).format('DD/MM')
      : null
  },

  /**
   * format with short day and full date: dd - DD/MM/YYYY
   * @param currentDate
   * @returns {any}
   */
  formatShortDayAndDate(currentDate) {
    return currentDate
      ? moment(currentDate).locale(getCurrentLanguage()).format('dd - DD/MM/YYYY')
      : null
  },

  formatShortDayAndDateTime(currentDate) {
    return currentDate
      ? moment(currentDate).locale(getCurrentLanguage()).format('dd - DD/MM/YYYY hh:mm')
      : null
  },

  /**
   * format en date: YYYY-MM-DD
   * @param currentDate
   * @returns {any}
   */
  formatEnFromVnDate(currentDate) {
    return currentDate
      ? moment(currentDate, FORMAT_ONLY_DATE).format('YYYY-MM-DD')
      : null
  },

  /**
   * get time duration range
   * @param timeDuration
   * @returns {{startTime: *, endTime: *}}
   */
  getTimeDuration(timeDuration) {
    let startTime = undefined
    let endTime = undefined
    switch (timeDuration) {
      case enumType.takeOff.All:
        startTime = moment('00:00', FORMAT_ONLY_HOUR)
        endTime = moment('24:00', FORMAT_ONLY_HOUR)
        break
      case enumType.takeOff.QuarterPass:
        startTime = moment('00:00', FORMAT_ONLY_HOUR)
        endTime = moment('06:00', FORMAT_ONLY_HOUR)
        break
      case enumType.takeOff.HalfPass:
        startTime = moment('06:00', FORMAT_ONLY_HOUR)
        endTime = moment('12:00', FORMAT_ONLY_HOUR)
        break
      case enumType.takeOff.QuarterTo:
        startTime = moment('12:00', FORMAT_ONLY_HOUR)
        endTime = moment('18:00', FORMAT_ONLY_HOUR)
        break
      case enumType.takeOff.OClock:
        startTime = moment('18:00', FORMAT_ONLY_HOUR)
        endTime = moment('24:00', FORMAT_ONLY_HOUR)
        break
      default:
        break
    }
    return {
      startTime,
      endTime
    }
  },

  /**
   * check time in duration
   * @param currentDate
   * @param startTime
   * @param endTime
   * @returns {boolean}
   */
  checkTimeInDuration(currentDate, startTime, endTime, unit = undefined, inclusivity = undefined) {

    const timeOfDate = moment(currentDate || undefined).format(FORMAT_ONLY_HOUR)

    const momentStartTime = moment(startTime, FORMAT_ONLY_HOUR)
    const momentEndTime = moment(endTime, FORMAT_ONLY_HOUR)

    return moment(timeOfDate, FORMAT_ONLY_HOUR).isBetween(momentStartTime, momentEndTime, unit, inclusivity)
  },

  /**
   *
   * @param currentDate
   * @returns {moment.Moment}
   */
  initNewDate(currentDate) {
    return moment(currentDate ? currentDate : new Date())
  },

  /**
   *
   * @param currentDate
   * @returns {moment.Moment}
   */
  initNewVnDate(currentDate) {
    const current = moment(new Date())
    let newVnDate = currentDate
      ? moment(currentDate, FORMAT_ONLY_DATE)
      : current

    newVnDate = newVnDate.set({
      'hour': current.get('hour'),
      'minute': current.set('minute'),
      'second': current.set('second')
    })

    return newVnDate
  },

  initStartDateOfPreviousYear(duration) {
    return moment(new Date()).subtract(duration, 'years').startOf('years')
  },

  initStartOfDay(currentDate) {
    return currentDate
      ? moment(currentDate).startOf('days')
      : moment(new Date()).startOf('days')
  },

  initEndOfDay(currentDate) {
    return currentDate
      ? moment(currentDate).endOf('days')
      : moment(currentDate).endOf('days')
  },

  calculateDateDiff(currentDate, dateDiff) {
    if (currentDate && dateDiff) {
      return moment(currentDate).diff(moment(dateDiff))
    }
    return 0
  },

  calculateOnlyDayDiff(currentDate, dateDiff, unit = 'days') {
    if (currentDate && dateDiff) {
      return moment(currentDate).diff(moment(dateDiff), unit)
    }
    return 0
  },

  calculateVnDateDiff(currentDate, dateDiff) {
    if (currentDate && dateDiff) {
      return moment(currentDate, FORMAT_ONLY_DATE).diff(moment(dateDiff, FORMAT_ONLY_DATE), 'days')
    }
    return 0
  },

  calculateVnYearDiff(currentDate, dateDiff) {
    if (currentDate && dateDiff) {
      return moment(currentDate, FORMAT_ONLY_DATE).diff(moment(dateDiff, FORMAT_ONLY_DATE), 'years')
    }
    return null
  },

  addDate(currentDate, number, duration) {
    return currentDate
      ? moment(currentDate).add(number, duration)
      : null
  },

  addVnDate(currentDate, number, duration) {
    return currentDate
      ? moment(currentDate, FORMAT_ONLY_DATE).add(number, duration).format(FORMAT_ONLY_DATE)
      : null
  },

  addVnDateTime(currentDate, number, duration) {
    return currentDate
      ? moment(currentDate).add(number, duration).format(FORMAT_DATE_TIME_AFTER)
      : null
  },

  addDateReverse(currentDate, number, duration) {
    return currentDate
      ? moment(currentDate, FORMAT_ONLY_DATE_REVERSE).add(number, duration).format(FORMAT_ONLY_DATE_REVERSE)
      : null
  },

  subtractVnDate(currentDate, number, duration) {
    return currentDate
      ? moment(currentDate, FORMAT_ONLY_DATE).subtract(number, duration).format(FORMAT_ONLY_DATE)
      : null
  },

  formatFromString(currentDate) {
    if (!currentDate) {
      return null
    }
    const timeZone = new Date().getTimezoneOffset()
    const dateWithTimeZone = moment(currentDate).subtract(timeZone, 'minutes')
    return currentDate ? moment(dateWithTimeZone).locale(getCurrentLanguage()).fromNow() : null
  },

  getUserTime(currentDate) {
    if (!currentDate) {
      return null
    }
    if (moment.isMoment(currentDate)) {
      return currentDate
    }
    const timeZone = new Date().getTimezoneOffset()
    return moment(currentDate).subtract(timeZone, 'minutes')
  },
  getMonthStringsFromDateRange(fromDate, toDate) {
    if (!toDate || fromDate.format(FORMAT_ONLY_MONTH) === toDate.format(FORMAT_ONLY_MONTH) || fromDate.isAfter(toDate, 'month')) {
      return [fromDate.format(FORMAT_ONLY_MONTH)]
    }
    let monthData = [];
    let tempMonth = cloneDeep(fromDate);
    while (tempMonth.isBefore(toDate, 'month') || tempMonth.format(FORMAT_ONLY_MONTH) === toDate.format(FORMAT_ONLY_MONTH)) {
      monthData.push(tempMonth.format(FORMAT_ONLY_MONTH));
      tempMonth.add(1, 'month');
    }
    return monthData
  },
}
