import { axiosProviderVoucher } from './api/axiosProvider'
//actions
import actionTypes from '../actionTypes'
//utils
//common
import {
  loadSuccess,
  loadFail, startFetchingHotelAction, startFetchingAction, startFetchingVoucherAction, stopFetchingVoucherAction
} from './commonAction'
import { gtmUtils } from '../utils'
import { voucherServices } from '../services'
import { cloneDeep } from 'lodash'

export const getCategories = () => {
  return async (dispatch) => {
    try {
      const response = await axiosProviderVoucher.get(`/frontend/category`)

      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.FETCHING_CATEGORY_VOUCHER_SUCCESS,
            response.data.data,
            null,
            null,
            null
          )
        )
      } else {
        dispatch(loadFail(response.errors[0].message))
      }
    } catch (errors) {
      // fail
      dispatch(loadFail(errors.response))
    }
  }
}

export const getVoucherGroup = (queryClause = '') => {
  return async (dispatch) => {
    let returnValue = [];
    dispatch(startFetchingVoucherAction())
    try {
      const response = await axiosProviderVoucher.get(`/frontend/group?${queryClause}`)
      if (response.status === 200 && !response.errors) {
        returnValue = cloneDeep(response.data.result || []);
        dispatch(
          loadSuccess(
            actionTypes.FETCHING_VOUCHER_GROUP_SUCCESS,
            response.data
          )
        )
        gtmUtils.pushGtmDatalayerVoucher(response.data.result || [], 'productImpressions')
      } else {
        dispatch(
          loadFail(response.errors[0].message)
        )
      }
    } catch (error) {
      loadFail(error.response)
    }
    dispatch(stopFetchingVoucherAction())
    return returnValue;
  }
}

export const getVoucherSpecial = () => {
  return async (dispatch) => {
    try {
      const response = await axiosProviderVoucher.get(`/frontend/group?isSpecial=true`)

      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.FETCHING_VOUCHER_SPECIAL_SUCCESS,
            response.data
          )
        )
        gtmUtils.pushGtmDatalayerVoucher(response.data.result || [], 'productImpressions')
      } else {
        dispatch(loadFail(response.errors[0].message))
      }
    } catch (errors) {
      // fail
      dispatch(loadFail(errors.response))
    }
  }
}

export const getVoucherDetail = (queryClause) => {
  return async (dispatch) => {
    let returnValue = {};
    dispatch(startFetchingVoucherAction())
    const setData = (data) => dispatch(loadSuccess(actionTypes.FETCHING_VOUCHER_DETAIL_SUCCESS, data))
    try {
      setData(null)
      const response = await axiosProviderVoucher.get(`/frontend/group/${queryClause}`)
      if (response.status === 200 && !response.errors) {
        returnValue = cloneDeep(response.data);
        setData(response.data)
        gtmUtils.pushGtmDatalayerVoucher(response.data, 'productDetail', 'voucher', 'detail');
      } else {
        dispatch(
          loadFail(response.errors[0].message)
        )
      }
    } catch (error) {
      loadFail(error.response)
    }
    dispatch(stopFetchingVoucherAction())
  }
}

export const addToCartSuccess = () => {
  return dispatch => {
    dispatch(
      loadSuccess(
        actionTypes.ADD_TO_CART_SUCCESS
      )
    )
  }
}

export const clearAddToCartNotice = () => {
  return dispatch => {
    dispatch(
      loadSuccess(actionTypes.CLEAR_ADD_TO_CART_NOTICE)
    )
  }
}

export const getLocation = () => {
  return async (dispatch) => {
    try {
      const response = await axiosProviderVoucher.get(`/property/all`)
      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.FETCHING_VOUCHER_LOCATION_SUCCESS,
            response.data.data
          )
        )
      } else {
        dispatch(
          loadFail(response.errors[0].message)
        )
      }
    } catch (error) {
      loadFail(error.response)
    }
  }
}
export default {
  getCategories,
  getVoucherGroup,
  getVoucherDetail,
  getLocation,
  getVoucherSpecial
}
