import { DEFAULT_PAYMENT_TYPE, enumType, FORMAT_ONLY_DATE, FORMAT_ONLY_DATE_3S } from '../constants'
import moment from 'moment'
import { get } from 'lodash'
import { formatMoney } from '../helpers/functions'
import { getCurrentCurrency } from '../utils/lang'
import { formatFullPhoneNumber } from './index'

const formatPassengerInfo = (listPassenger) => {
  if (!listPassenger || listPassenger.length === 0) {
    return []
  }

  return listPassenger.map(passenger => ({
    firstName: passenger.lastName || '',
    lastName: passenger.firstName || '',
    type: passenger.type || '',
    gender: passenger.gender || '',
    email: passenger.email || '',
    phone: passenger.phone || '',
    birthday: passenger.birthday || '',
    passportExpiryDate: passenger.passportExpiryDate || '',
    passportIssueCountry: passenger.passportIssueCountry || '',
    passportNumber: passenger.passportNumber || '',
    baggageDeparture: passenger.baggageDeparture
      ? passenger.baggageDeparture.value || null
      : null,
    baggageReturn: passenger.baggageReturn
      ? passenger.baggageReturn.value || null
      : null
  }))
}

const formatContactInfo = (contactInfo) => {
  return {
    firstName: contactInfo.firstName || '',
    lastName: contactInfo.lastName || '',
    gender: contactInfo.gender || '',
    email: contactInfo.email || '',
    phone: formatFullPhoneNumber(contactInfo.prefixPhone || enumType.prefixPhone.Vn, contactInfo.phone),
    address: contactInfo.address || '',
    company: contactInfo.company || '',
    note: contactInfo.note || ''
  }
}
export default {

  /**
   * get flight detail
   * @param flight
   * @returns {object}
   */
  initFlightDetailObject(flight) {
    return {
      dataSession: flight.dataSession
        ? flight.dataSession
        : '',
      dataReturnSession: flight.dataReturnSession
        ? flight.dataReturnSession
        : '',
      departureFlightSession: flight.departureFlightSession
        ? flight.departureFlightSession
        : '',
      returnFlightSession: flight.returnFlightSession
        ? flight.returnFlightSession
        : ''
    }
  },

  /**
   * init flight list query
   * @param obj
   * @returns {string}
   */
  initFlightQueryString(obj) {

    return `ItineraryType=${obj.departureDate && obj.returnDate ? 2 : 1}&
        StartPoint=${obj.startPoint ? obj.startPoint : ''}&
        EndPoint=${obj.endPoint ? obj.endPoint : ''}&
        DepartureDate=${obj.departureDate ? moment(obj.departureDate, FORMAT_ONLY_DATE_3S).format(FORMAT_ONLY_DATE) : ''}&
        ReturnDate=${obj.returnDate ? moment(obj.returnDate, FORMAT_ONLY_DATE_3S).format(FORMAT_ONLY_DATE) : ''}&
        Adult=${obj.adult ? obj.adult : ''}&
        Children=${obj.children ? obj.children : ''}&
        Infant=${obj.infant ? obj.infant : ''}&
        ClientVia=${obj.clientVia ? obj.clientVia : ''}&
        FlightFilter=${obj.flightFilter ? obj.flightFilter : ''}&
        Airline=${obj.airline && obj.airline !== enumType.airline.All ? obj.airline : ''}&
        ViewMode=${obj.viewMode !== undefined && obj.viewMode !== null ? obj.viewMode : ''}
        `.replace(
      /\s/g,
      ''
    )
  },

  /**
   * init flight list object
   * @param {*} obj
   */
  initFlightListObject(obj) {
    return {
      ItineraryType: obj.departureDate && obj.returnDate ? 2 : 1,
      StartPoint: obj.startPoint ? obj.startPoint : '',
      EndPoint: obj.endPoint ? obj.endPoint : '',
      DepartureDate: obj.departureDate ? obj.departureDate : '',
      ReturnDate: obj.returnDate ? obj.returnDate : '',
      Adult: obj.adult ? obj.adult : '',
      Children: obj.children ? obj.children : '',
      Infant: obj.infant ? obj.infant : '',
      ClientVia: obj.clientVia ? obj.clientVia : '',
      FlightFilter: obj.flightFilter ? obj.flightFilter.replace(/\s/g, '') : '',
      Airline: obj.airline && obj.airline !== enumType.airline.All ? obj.airline : '',
      ViewMode: 1, // obj.viewMode,
    }
  },

  /**
   * init flight list object
   * @param {*} obj
   */
  initFlightSearchWeekParams(obj) {
    return {
      StartPoint: obj.startPoint ? obj.startPoint : '',
      EndPoint: obj.endPoint ? obj.endPoint : '',
      Date: obj.date ? moment(obj.date, FORMAT_ONLY_DATE_3S).format(FORMAT_ONLY_DATE) : '',
    }
  },

  /**
   * init flight list object
   * @param {*} obj
   */
  initFlightSearchMonthParams(obj) {
    return {
      StartPoint: obj.startPoint ? obj.startPoint : '',
      EndPoint: obj.endPoint ? obj.endPoint : '',
      Month: obj.date ? moment(obj.date, FORMAT_ONLY_DATE).month() + 1 : '',
      Year: obj.date ? moment(obj.date, FORMAT_ONLY_DATE).year() : '',
    }
  },

  /**
   * init baggage query
   * @param obj
   * @returns {string}
   */
  initBaggageQuery(obj) {
    let query = `DataSession=${obj.dataSession ? obj.dataSession : ''}&`
    query += `DataReturnSession=${obj.dataReturnSession
      ? obj.dataReturnSession
      : ''}&`
    query += `DepartureFlightSession=${obj.departureFlightSession
      ? obj.departureFlightSession
      : ''}&`
    query += `ReturnFlightSession=${obj.returnFlightSession
      ? obj.returnFlightSession
      : ''}`
    return query
  },

  /**
   * init domestic reservation
   * @param data
   * @returns {{departureFlightSession: *, clientVia: *, contactInfo: *, returnFlightSession: *, dataSession: *, listPassengers: *}}
   */
  initDomesticReservationData(data, affSId = undefined, params = undefined, isUseCreditPoint = false, creditPointData = {}) {
    let orderItemsInfo = {
      dataSession: data.dataSession,
      dataReturnSession: data.dataReturnSession,
      departureFlightSession: data.departureFlightSession,
      returnFlightSession: data.returnFlightSession,
      listPassengers: formatPassengerInfo(data.listPassengers),
      contactInfo: formatContactInfo(data),
      clientVia: data.clientVia
    }
    orderItemsInfo = params ? Object.assign(orderItemsInfo, {
      dataSession: params.departSession,
      dataReturnSession: params.returnSession,
      departureFlightSession: params.departFareOptionSession,
      returnFlightSession: params.returnFareOptionSession,
    }) : orderItemsInfo;

    let submitData = {
      firstName: data.firstName,
      lastName: data.lastName,
      fullname: `${data.firstName} ${data.lastName}` || '',
      email: data.email || '',
      phoneNumber: formatFullPhoneNumber(data.prefixPhone || enumType.prefixPhone.Vn, data.phone),
      note: data.note || '',
      gatewayId: data.gatewayId || '',
      paymentType: data.paymentType ? data.paymentType.toLowerCase() : DEFAULT_PAYMENT_TYPE,
      paymentMethod: data.paymentMethod,
      orderItem: [
        {
          orderItemType: 3,
          quantity: data.listPassengers.length,
          bookingData: JSON.stringify(orderItemsInfo)
        }
      ],
      affSId,
    }
    
    if (isUseCreditPoint && creditPointData?.data?.exchangeCash > 0 && creditPointData?.isSuccess) {
      submitData = {
        ...submitData,
        IsUseCreditPoint: isUseCreditPoint, // trong trường hợp user chọn thanh toán bằng điểm (on/off toggle) 
        creditPointCalculation: creditPointData?.data // data được lấy từ api credit-point, mục đích recheck thông tin
      }
    }

    return submitData;
  },

  getFlightSeatTypeByGroupClass(groupClass) {
    if (!enumType.seatGroupClass.BUSINESS.includes(groupClass)) {
      return enumType.seatSelectionEnum[0];
    }
    return enumType.seatSelectionEnum[1]
  },

  getFlightSession(flight, isReturn) {
    let startPoint = null
    let endPoint = null

    if (flight && flight.flights) {
      const {
        flights
      } = flight

      startPoint = {
        cityId: flights.startPoint,
        cityName: flights.startPointCityName,
        pointName: flights.startPointName
      }

      endPoint = {
        cityId: flights.endPoint,
        cityName: flights.endPointCityName,
        pointName: flights.endPointName
      }
    }

    return {
      startPoint: isReturn ? endPoint : startPoint,
      endPoint: isReturn ? startPoint : endPoint
    }
  },

  parseFlightComment(data, flight) {

    const startPoint = get(flight, 'departureFlights.startPoint');
    const endPoint = get(flight, 'departureFlights.endPoint');
    const departAirlineCode = get(flight, 'departureFlights.airlineCode');
    const returnAirlineCode = get(flight, 'returnFlights.airlineCode');
    const departFlightNumber = get(flight, 'departureFlights.flightNumber');
    const returnFlightNumber = get(flight, 'returnFlights.flightNumber');
    const departureDate = moment(get(flight, 'departureFlights.startDate')).format('HH:mm DD/MM');
    const returnDate = moment(get(flight, 'returnFlights.startDate')).format('HH:mm DD/MM');
    const departureBaggage = (get(data, 'listPassengers') || [])
      .map(passenger => (get(passenger, 'baggageDeparture.value') || 0) + 'kg').join('+');
    const returnBaggage = (get(data, 'listPassengers') || [])
      .map(passenger => (get(passenger, 'baggageReturn.value') || 0) + 'kg').join('+');
    const bookingNote = data.note ? `${data.note}\n` : '';

    return `${bookingNote}${startPoint}-${endPoint} ${departAirlineCode} ${departFlightNumber} ${departureDate} ${departureBaggage} | ${endPoint}-${startPoint} ${returnAirlineCode} ${returnFlightNumber} ${returnDate} ${returnBaggage}`
  }
}