export const gtmEvent = {
  PRODUCT_IMPRESSIONS: "product_impressions",
  PRODUCT_CLICK: "product_click",
  PRODUCT_DETAIL: "product_detail",
  TRANSACTION_SUCCESS: "transaction_success",
  PRODUCT_ADD_TO_CART: "product_add_to_cart",
  PRODUCT_REMOVE_FROM_CART: "product_remove_from_cart",
  PRODUCT_CART: "product_cart",
  PRODUCT_CHECKOUT: "product_checkout",
  SELECT_BOOKING_HOTEL: "select_booking_hotel",
  SELECT_ROOM_RATE: "select_room_rate",
  PRODUCT_VIEW_CART: "product_view_cart",
  PRODUCT_VIEW_TRIP: "product_view_trip",
  SKU_SELECT_DATE: "select_date",
  SEARCH_HOTEL: "search_hotels",
  SEARCH_TOUR: "search_tours",
  SEARCH_COMBO: "search_combo",
  SEARCH_FLIGHT: "search_flights",
  DEFAULT_PAGE_VIEW: "default_page_view",
  VIRTUAL_PAGE_VIEW: "virtual_page_view",
  PAGE_VIEW: "page_view",
  SELECT_TOUR_OPTION: "select_tour_option",
  LOGIN: 'login',
  LOG_OUT: 'log_out',
  VIEW_ITEM_LIST: 'view_item_list',
  VIEW_ITEM: 'view_item',
  SELECT_ITEM_STAYNFUN: "select_item",
  SELECT_ROOM_RATE_STAYNFUN: "select_room_rate",
  ADD_T0_CART: 'add_to_cart',
  BEGIN_CHECKOUT: 'begin_checkout',
  ADD_SHIPPING_INFO: 'add_shipping_info',
  ADD_PAYMENT_INFO: 'add_payment_info',
  STAYNFUN_PURCHASE: "purchase",
};

export const gtmType = {
  HOTEL: "hotel",
  TOUR_AND_EXPERIENCE: "tour and experience",
  ACTION_CART_SKU: "actionCartSKU",
  HOTEL_PRODUCE_DETAIL: "hotel proceduce detail",
  TOUR_DETAIL: "tour detail",
  EXPERIENCE_DETAIL: "experience detail",
  PURCHASE: "purchase",
  HOTEL_ADD_TO_CART: "hotel_add_to_cart",
  HOTEL_PRODUCE_CHECKOUT: "hotel_produce_checkout",
  SELECT_BOOKING_HOTEL: "select_booking_hotel",
  SELECT_ROOM_RATE: "select_room_rate",
  PRODUCT_CHECKOUT: "product_checkout",
  FLIGHT_IMPRESSIONS: "flight impressions",
  FLIGHT_PRODUCT_CLICK: "flight product click",
  FLIGHT_PRODUCT_DETAIL: "flight product detail",
  FLIGHT_ADD_TO_CART: "flight add to cart",
  PRODUCT_VIEW_CART: "product_view_cart",
  ADD_TO_CART_SKU: 'addToCartSKU',
  REMOVE_FROM_CART_SKU: 'removeFromCartSKU',
  FLIGHT_REMOVE_FROM_CART: "flight_remove_from_cart",
  FLIGHT_PRODUCT_CHECKOUT: "flight_product_checkout",
  FLIGHT_PURCHASE: "fight purchase",
  COMBO_IMPRESSIONS: "combo_impressions",
  COMBO_PRODUCT_CLICK: "combo_product_click",
  COMBO_PRODUCT_DETAIL: "combo_product_detail",
  COMBO_HOTEL_PRODUCT_DETAIL: 'combo_hotel_product_detail',
  COMBO_SELECT_ROOM_RATE: 'combo_select_room_rate',
  COMBO_ADD_TO_CART: "combo_add_to_cart",
  COMBO_FLIGHT_ADD_TO_CART: "combo_flight_add_to_cart",
  PRODUCT_VIEW_TRIP: 'product_view_trip',
  COMBO_PRODUCT_VIEW_CART: 'combo_product_view_cart',
  COMBO_PRODUCT_CHECKOUT: 'combo_product_checkout',
  COMBO_PURCHASE: 'combo_purchase',
  HOTEL_PURCHASE: "hotel_purchase",
  SKU_SELECT_DATE: 'sku_select_date',
  SEARCH_HOTEL: "search_hotels",
  SEARCH_TOUR: "search_tours",
  SEARCH_COMBO: "search_combo",
  SEARCH_FLIGHT: "search_flights",
  DEFAULT_PAGE_VIEW: "default_page_view",
  VIRTUAL_PAGE_VIEW: "virtual_page_view",
  PAGE_VIEW: "page_view",
  SELECT_TOUR_OPTION: "select_tour_option",
  LOGIN: 'login',
  LOG_OUT: 'log_out',
  VIEW_ITEM_LIST: 'view_item_list',
  VIEW_ITEM: 'view_item',
  SELECT_ITEM_STAYNFUN: "select_item",
  SELECT_ROOM_RATE_STAYNFUN: "select_room_rate",
  ADD_T0_CART: 'add_to_cart',
  BEGIN_CHECKOUT: 'begin_checkout',
  ADD_SHIPPING_INFO: 'add_shipping_info',
  ADD_PAYMENT_INFO: 'add_payment_info',
  STAYNFUN_PURCHASE: "purchase",
};

export const TBD = 'tbd'