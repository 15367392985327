import { getMomentDate } from '../utils/common';
import { get, find } from 'lodash';
import { CONTACT_INFO } from '../constants';

export default class SiteInfo {

    constructor(data) {
        this.__data__ = this.parseData(data);
        this.setData(this.__data__);
    }

    setData(data = []) {
        this.email = this.getValue('email', data) || CONTACT_INFO.EMAIL;
        this.hotlineNumber = this.getValue('hotline_number', data) || CONTACT_INFO.HOTLINE;
        this.hotlineBranch = this.getValue('hotline_branch', data) || CONTACT_INFO.HOTLINE_BRANCH;
        this.hotlinePrice = this.getValue('hotline_price', data) || CONTACT_INFO.HOTLINE_PRICE;
        this.addressBuilding = this.getValue('address_building', data) || CONTACT_INFO.ADDRESS_BUILDING;
        this.addressLine1 = this.getValue('address_line_1', data) || CONTACT_INFO.ADDRESS_LINE1;
        this.addressLine2 = this.getValue('address_line_2', data) || CONTACT_INFO.ADDRESS_LINE2;
        this.facebookFanpage = this.getValue('facebook_fanpage', data) || CONTACT_INFO.FACEBOOK_FANPAGE;
        this.youtubeChannel = this.getValue('youtube_channel', data) || CONTACT_INFO.YOUTUBE_CHANNEL;
        this.instagram = this.getValue('instagram', data) || CONTACT_INFO.INSTAGRAM;
        this.lastUpdated = Date.now();
    }

    getValue(key, data = this.__data__) {
        const info = find(data || [], item => (item.Key || item.key) && (item.Key || item.key).toLowerCase() === key.toLowerCase());
        return !!info ? (info.Value || info.value) : undefined;
    }

    parseData(data) {
        if (typeof data === 'string') {
            try {
                return JSON.parse(data);
            } catch (error) {
                return []
            }
        }
        return []
    }

    shouldUpdate(){
        return Date.now() - (this.lastUpdated || 0) >=  30000//(864e5) // 1day
    }

}