import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import authAction from '../actions/authAction'
import {loadFail} from "../actions/commonAction";

/**
 *
 * @returns {function(*=, *=)}
 */
export const loginCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (...params) =>
      dispatch(authAction.login(...params)),
    [dispatch]
  )
}
/**
 *
 * @returns {function(*=, *=)}
 */
export const loginFail = () => {
  const dispatch = useDispatch()
  return useCallback(
    () =>
        dispatch(loadFail()),
    [dispatch]
  )
}
/**
 *
 * @returns {function(*=, *=)}
 */
export const loginSuccessCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (...params) =>
      dispatch(authAction.loginSuccess(...params)),
    [dispatch]
  )
}


export const loginFaceBookCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (token, refreshPage) =>
      dispatch(authAction.loginFaceBook(token, refreshPage)),
    [dispatch]
  )
}

export const loginGoogleCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (token, refreshPage) =>
      dispatch(authAction.loginGoogle(token, refreshPage)),
    [dispatch]
  )
}

/**
 * Logout callback
 * @returns {function(*=, *=)}
 */
export const logoutCallback = () => {
  const dispatch = useDispatch()
  return useCallback((...params) => dispatch(authAction.logout(...params)), [
    dispatch
  ])
}

/**
 * Register callback
 * @returns {function(*=, *=, *=, *=, *=)}
 */
export const registerCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (email, phoneNumber, password, firstName, lastName) =>
      dispatch(
        authAction.register(email, phoneNumber, password, firstName, lastName)
      ),
    [dispatch]
  )
}

export const refreshTokenCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (token) =>
      dispatch(
        authAction.refresh(token)
      ),
    [dispatch]
  )
}

/**
 * Confirm email callback
 * @returns {function(*=, *=)}
 */
export const confirmEmailCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (userId, activationCode) =>
      dispatch(authAction.confirmEmail(userId, activationCode)),
    [dispatch]
  )
}

/**
 * Forgot password callback
 * @returns {function(*=)}
 */
export const forgotPasswordCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (...params) =>
      dispatch(authAction.forgotPassword(...params)),
    [dispatch]
  )
}

/**
 * Reset password callback
 * @returns {function(*=, *=, *=)}
 */
export const resetPasswordCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (userId, password, token) =>
      dispatch(authAction.resetPassword(userId, password, token)),
    [dispatch]
  )
}

export const updatePasswordCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (oldPassword, newPassword, token) =>
      dispatch(authAction.updatePassword(oldPassword, newPassword, token)),
    [dispatch]
  )
}

/**
 * Get Infomation Profile User
 * @returns {function(*=)}
 */
export const getProfileCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (accessToken, pushIns) => dispatch(authAction.getProfile(accessToken, pushIns)),
    [dispatch]
  )
}

/**
 * Update Infomation Profile User
 * @returns {function(*=)}
 */
// export const updateProfileCallback = () => {
//   const dispatch = useDispatch()
//   return useCallback(
//     (firstName, lastName, phoneNumber, avatar, accessToken) =>
//       dispatch(
//         authAction.updateProfile(
//           firstName,
//           lastName,
//           phoneNumber,
//           avatar,
//           accessToken
//         )
//       ),
//     [dispatch]
//   )
// }

/**
 * Update Infomation Profile User
 * @returns {function(*=)}
 */
 export const updateProfileCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (newProfile, accessToken, msg) =>
      dispatch(
        authAction.updateProfile(
          newProfile,
          accessToken,
          msg
        )
      ),
    [dispatch]
  )
}

export const uploadAvataCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (avatarUpload, accessToken) =>
      dispatch(
        authAction.uploadAvata(
          avatarUpload,
          accessToken
        )
      ),
    [dispatch]
  )
}

export const lickAccountCallBack = () => {
  const dispatch = useDispatch()
  return useCallback(
    (data, accessToken) =>
      dispatch(
        authAction.linkAccount(
          data,
          accessToken
        )
      ),
    [dispatch]
  )
}

export const removeOldPhoneCallBack = () => {
  const dispatch = useDispatch()
  return useCallback(
    (email, accessToken) =>
      dispatch(
        authAction.removeOldPhone(
          email,
          accessToken
        )
      ),
    [dispatch]
  )
}

/**
 *
 * @returns {function(*=, *=)}
 */
export const checkAuth0Callback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (...params) =>
      dispatch(authAction.checkAuth(...params)),
    [dispatch]
  )
}

export const loginModalCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (...params) =>
      dispatch(authAction.loginModalFunction(...params)),
    [dispatch]
  )
}

export const checkUserModalCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (...params) =>
      dispatch(authAction.checkUser(...params)),
    [dispatch]
  )
}

export const sendOtpModalCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (...params) =>
      dispatch(authAction.sendOTP(...params)),
    [dispatch]
  )
}

export const forgotPassModalCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (...params) =>
      dispatch(authAction.forgotPassModalFunction(...params)),
    [dispatch]
  )
}

export const signUpVptFunctionCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (...params) =>
      dispatch(authAction.signUpVptFunction(...params)),
    [dispatch]
  )
}

export const changePasswordVptFunctionCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (...params) =>
      dispatch(authAction.changePasswordVptFunction(...params)),
    [dispatch]
  )
}

/**
 * Upsert User
 * @returns {function(*=, *=)}
 */
 export const upsertUserCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (data) =>
      dispatch(authAction.upsertUser(data)),
    [dispatch]
  )
}
