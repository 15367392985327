export default class FlightFareRule {

    constructor(data = {}) {
        this.setData(data);
    }

    setData(data = {}) {
        this.airlineCode = data.airlineCode || undefined;
        this.className = data.className || undefined;
        this.cancellationRefund = data.cancellationRefund || [];
        this.timeChange = data.timeChange || [];
        this.itineraryChange = data.itineraryChange || [];
        this.passengerInfoChange = data.passengerInfoChange || [];
        this.remarks = data.remarks || undefined;
    }

}