import ApiService from './../api/ApiService';
import Urls from './../api/Urls';
import config from "./../config"
import Coupon from '../model/Coupon';

export function getOrder(idOrder) {
    return new ApiService({
        endpoint: `${Urls.ORDER_DETAIL}/${idOrder}`,
        baseURL: config.BASE_URL_ORDER_API
    }).get()
}

export function getOrderItem(orderId) {
    return new ApiService({
        endpoint: Urls.ORDER_INFO,
        endpointParams: { orderId },
        baseURL: config.BASE_URL_ORDER_API
    }).get()
}

export function getListOrderItem(orderId) {
    return new ApiService({
        endpoint: Urls.ORDER_ITEM,
        endpointParams: { orderId },
        baseURL: config.BASE_URL_ORDER_API
    }).get()
}

export function getFlightOrder(productVariantId) {
    return new ApiService({
        endpoint: Urls.GET_ORDER,
        endpointParams: { id: productVariantId },
        baseURL: config.API_BASE_URL
    }).get()
}

export function getCrossSellOrder (slug, clientId) {
    return new ApiService({
        endpoint: `${Urls.GET_CROSS_SELL}/${slug}`,
        baseURL: config.BASE_URL_ORDER_API,
        params: {
            Channel: 'VPT',
            ClientId: clientId,
        },
    }).get()
}

export function getHotelOrder(productVariantId) {
    return new ApiService({
        endpoint: Urls.BOOKING_DETAIL,
        baseURL: config.BASE_HOTEL_URL
    }).post([productVariantId])
}

export function getHotelOrderMvp(productVariantIds) {
  return new ApiService({
      endpoint: Urls.BOOKING_DETAIL,
      baseURL: config.BASE_HOTEL_URL
  }).post(productVariantIds)
}

export function getCoupon(couponCode, productVariantIds = {}) {
    return new ApiService({
        baseURL: config.BASE_URL_ORDER_API,
        endpoint: Urls.ORDER_COUPON,
        receiveErrorModel: true,
        parser: data => new Coupon({ ...data, code: couponCode }),
    }).post({
        couponCode,
        voucherProductVariantIds: productVariantIds.vouchers,
        tourProductVariantIds: productVariantIds.tours,
        vinWonderProductVariantIds: productVariantIds.vinWonders
    })
}