import actionTypes from '../actionTypes'
import SiteInfo from '../model/SiteInfo'
import IpLocale from '../model/IpLocale'

const initState = {
  siteInfo: new SiteInfo(),
  ipLocale: new IpLocale()
}

const siteInfoReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_SITE_INFO_SUCCESS:
      return Object.assign({}, state, {
        siteInfo: new SiteInfo(action.payload.data)
      })
    case actionTypes.FETCHING_IP_LOCALE_SUCCESS:
      return Object.assign({}, state, {
        ipLocale: (action.payload.data)
      })
    default:
      return state
  }
}

export default siteInfoReducer