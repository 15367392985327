import { datetimeExtensions, numberExtensions } from '../extensions'
import { FORMAT_ONLY_DATE, FORMAT_ONLY_DATE_3S } from '../constants'
import moment from 'moment'
import { data } from 'jquery'
import { get } from 'lodash'

export default {
  checkValidParams(params) {
    const listValidParams = [
      'locationId',
      'hdd',
      'ha',
      'hc',
      'hi',
      'hr',
      'hs'
    ]

    const validParams = listValidParams.every(props => Object.keys(params).includes(props))

    if (validParams) {
      const hotelDepartureDate = params['hdd']
      return !datetimeExtensions.checkIsPreviousVnDate(hotelDepartureDate, datetimeExtensions.formatVnDate(new Date()))
    }
    return false
  },

  isBetweenDate(compareDate, dataStartDate, dataEndDate) {
    const startDate = moment(dataStartDate)
    const endDate = moment(dataEndDate)
    return moment(compareDate, FORMAT_ONLY_DATE).isBetween(moment(startDate, FORMAT_ONLY_DATE), moment(endDate, FORMAT_ONLY_DATE), undefined, '[]')
  },

  stringifyPassenger(passenger) {
    return passenger.map(item => `${item['adult']}_${item['child']}_${item['infant']}`).join(',')
  },

  parsePassenger(roomOccupancy = '') {
    const room = roomOccupancy.split(',')
    const passenger = room.map(item => {
      const quantity = item.split('_')
      return {
        adult: quantity[0],
        child: quantity[1],
        infant: quantity[2]
      }
    })
    return {
      passenger,
      numberOfRoom: room.length
    }
  },
  getTotalQuantity(passenger) {
    let adult = 0;
    let child = 0;
    let infant = 0;
    passenger.forEach(item => {
      adult += parseInt(item.adult);
      child += parseInt(item.child);
      infant += parseInt(item.infant);
    })

    return { adult, child, infant }
  },

  mapTagGroupDetailSkus(dataFilter) {
    const dataSKU = {}
    dataFilter.forEach(tagGroup => {
      tagGroup.tagGroupDetails.forEach(groupDetail => {
        dataSKU[groupDetail.id] = groupDetail.tagGroupDetailSkus.map(detailSKU => {
          return detailSKU.skuId
        })
      })
    })

    return dataSKU
  },

  mapDataChecked(dataChecked) {
    let arr = []
    Object.keys(dataChecked).forEach(key => {
      // arr = [...arr, ...dataChecked[key]]
      if (dataChecked[key] && dataChecked[key].length > 0) {
        arr = [...arr, dataChecked[key]]
      }
    })

    return arr
  },

  getLengthOfStay(departureDate, returnDate) {
    let checkReturnDate = returnDate
    if (datetimeExtensions.checkIsFutureVnDate(departureDate, returnDate) ||
      datetimeExtensions.checkIsSameVnDate(departureDate, returnDate)
    ) {
      checkReturnDate = datetimeExtensions.addVnDate(departureDate, 1, 'days')
    }
    return datetimeExtensions.calculateVnDateDiff(checkReturnDate, departureDate)
  },

  initParamsShortLink(hotel) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let addDays = params?.des || 0;
    if(addDays > 60){
      addDays = 60;
    }

    return {
      hotelId: hotel.id,
      hsp: hotel.name,
      locationId: hotel.locationId,
      roomOccupancy: '2_0_0',
      arrivalDate: moment().add(addDays, "days").format(FORMAT_ONLY_DATE_3S),
      numberOfRoom: 1,
      lengthOfStay: 1,
      promotionCode: ''
    }
  },

  totalPriceBooking(rooms, fieldPrice, initPrice = 0) {
    let total = initPrice;
    if (rooms && Object.keys(rooms).length > 0) {
      Object.keys(rooms).forEach(key => {
        total += (get(rooms[key], fieldPrice) || 0)
      })
      if (0 < total && total < 1000) {
        return 1000;
      }
      return total
    }
    return null
  },

  formatHotelData(hotels){
    const locations = [];
    const locationMap = {};
  
    hotels.forEach((item) => {
      const { locationId, locationName, type, hotelName,urlSlug  } = item;
  
      if (type === 'Location') {
        if (!locationMap[locationId]) {
          locationMap[locationId] = {
            title: locationName,
            link: urlSlug,
            children: [],
          };
          locations.push(locationMap[locationId]);
        }
      } else {
        const parentLocationId = item.locationId;
  
        if (locationMap[parentLocationId]) {
          locationMap[parentLocationId].children.push({title: hotelName,link: urlSlug });
        }
      }
    });
  
    return locations;
  }
}