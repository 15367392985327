import queryString from '@utils/querystring'
import ApiService from './../api/ApiService'
import Urls from './../api/Urls'
import config from './../config'
import { compactObj } from './../helpers/functions'
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, MAX_STAR } from '../constants'
import { numberExtensions, objectExtensions } from '../extensions'
import { TagGroup } from "./../model/Tag"
import IframeHandler from '../utils/iframe'

export function getLogin(params) {
  params = params || {}
  return new ApiService({
    endpoint: Urls.AUTH_LOGIN,
    baseURL: config.API_BASE_AUTH_URL,
    receiveErrorModel: true,
    parser: (data) => data
  }).post(params)
}

export function getAuthorizeSSOData(params) {
  params = params || {};
  return new Promise((resolve, reject) => {
    if (typeof window === 'undefined') reject({ error: 'window undefined' })
    const paramsStr = queryString.stringify({
      response_type: params.responseType || config.AUTH0_RESPONSE_TYPE,
      response_mode: 'web_message',
      client_id: config.AUTH0_CLIENT_ID,
      redirect_uri: params.redirectUri || config.AUTH0_REDIRECT_URL,
      state: config.AUTH0_STATE,
      scope: params.scope || 'openid profile email',
      prompt: params.prompt || 'none',
    })
    const authorizeUrl = `${config.AUTH0_URL}${Urls.AUTH0_AUTHORIZE}?${paramsStr}`;
    const handler = new IframeHandler({
      url: authorizeUrl,
      eventListenerType: 'message',
      callback: ({ event }) => resolve(event?.data?.response),
      timeout: 30000,
      eventValidator: {
        isValid: function (eventData) {
          return (
            eventData?.event?.data?.type === 'authorization_response' &&
            config.AUTH0_STATE === eventData?.event?.data?.response?.state
          );
        }
      },
      timeoutCallback: () => reject({
        error: 'timeout',
        error_description: 'Timeout during executing web_message communication',
        state: config.AUTH0_STATE
      }),
    });
    handler.init();
  })
}
