import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx';

import { withLazyImageContext } from './LazyImageContext'

const LazyImage = ({ className, alt, src, lazyLoad, onError, onLoad, onFinish, ...resProps }) => {

  const [isFinished, setFinished] = useState(false);

  useEffect(() => {
    if (lazyLoad) lazyLoad.update()
  }, [src, lazyLoad])

  // Set the placeholder size on our wrapper div

  return (
    <img
      alt={alt}
      className={clsx(className, 'lazy')}
      data-src={src}
      onError={onError}
      onLoad={onLoad}
    />
  )
}

LazyImage.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string,
  src: PropTypes.string,
  onError: PropTypes.func,
  onLoad: PropTypes.func
}

export default withLazyImageContext(LazyImage)