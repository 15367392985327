import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
// lib

// Component
import Drawer from "rc-drawer";
import TopHeader from "./TopHeader";
import Menu from "./Menu";
import { featureFlags } from "../../constants";
import { useDeviceInfo, useScreenSize } from "../../hooks/deviceInfo";
import { getConfig } from '@config'
import DownloadAppBanner from "./DownloadAppBanner";
import clsx from "clsx";
import { logoutCallback } from "src/hooks/auth";
import Currency from "./Currency";
import Language from "./Language";
import LoginContainer from "./LoginContainer";
import Cart from "./Cart";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const {
  publicRuntimeConfig: {
    HOME_PAGE
  } // Available both client and server side
} = getConfig()

const homePageUrl = HOME_PAGE || 'https://dev-vinpearl.vin3s.vn'
const homePageStaynFunUrl = 'https://staynfun.vinpearl.com'

const HeaderDesktop = ({ }) => (
  <div className="d-flex flex-column w-100 ">
    {!featureFlags.HIDE_VIN3S_INFO && (
      <TopHeader
        mode={"horizontal"}
        showCart={true}
        showLogin={true}
        showLanguage={true}
      />
    )}
    <div className="order-lg-2">
      <Menu />
    </div>
  </div>
);

const Header = () => {
  const { pathname } = useLocation()

  const [openMenu, setOpenMenu] = useState(false);
  const [hasDownloadApp, setHasDownloadApp] = useState(false);
  const [isStaynfun, setIsStaynfun] = useState(false);
  const { isLarge } = useScreenSize()
  const { isTablet, isMobileMedium } = useDeviceInfo();

  useEffect(() => {
    setIsStaynfun(pathname.includes("/staynfun"));
  }, [pathname])

  const handleToggleOpen = useCallback(() => {
    setOpenMenu(!openMenu);
  }, [openMenu]);

  const setHasDownloadAppCallback = useCallback((has) => {
    setHasDownloadApp(has);
  }, [])

  return (
    <header
      className={clsx("s43-header g-font-EBGaramond", { "has-download-app-header": hasDownloadApp })}
    >
      {!featureFlags.HIDE_VIN3S_INFO && (
        <TopHeader
          mode="horizontal"
          showCart={true}
          showLogin={isTablet && !isLarge}
          showLanguage={isTablet && isLarge}
        />
      )}
      <div className={clsx("mobile-header", { "has-download-app": hasDownloadApp })}>
        <div className="container-header">
          <div className="container">
            {isMobileMedium && (
              <DownloadAppBanner setHasDownloadApp={setHasDownloadAppCallback} />
            )}
            <div id="header-nav-container" className="header-nav">
              <div className="menu-icon" onClick={handleToggleOpen}>
                <img alt="" src={require('@assets/include/svg/menu_fries.svg').default} />
              </div>
              {isStaynfun ?
                <a className="logo vinpearl-logo" title="homepage" href={homePageStaynFunUrl}>
                  <img
                    style={{ width: 137 }}
                    src={require('@assets/img/staynfun/logo-staynfun_2.svg').default}
                    alt="Vinpearl"
                  />
                </a>
              :
                <a className="logo vinpearl-logo" title="homepage" href={homePageUrl}>
                  <img
                    src={require('@assets/img/hotel-logos/vinpearl-logo@2x.svg').default}
                    alt="Vinpearl"
                  />
                </a>
              }
              <div className={clsx("cart-wrapper", {
                'homestay': isStaynfun,
              })}>
                {
                  isMobileMedium ? (
                    <Cart
                      className="g-font-size-16 g-pr-0"
                      icon={<i className="fas fa-shopping-cart"></i>}
                    />
                  ) : (
                    <>
                      <div className="cart-item login">
                        <LoginContainer
                          containerSelector="#header-nav-container"
                          isHomstay={isStaynfun}
                        />
                      </div>
                      {(!isStaynfun ) &&
                        <span className='s43-header-divider'>/</span>
                      }
                      <div className="cart-item language-currency">
                        {<Currency containerSelector="#header-nav-container" />}
                        {(isStaynfun) &&
                          <img alt='' src={require("@assets/include/svg/fi-rr-angle-small-right-59595B.svg").default} />
                        }
                        {<Language containerSelector="#header-nav-container" />}
                      </div>
                    </>
                  )
                }
              </div>
            </div>

            <Drawer
              getContainer={null}
              level={null}
              open={openMenu}
              onClose={handleToggleOpen}
              handler={null}
            >
              <Menu onToggleMenu={handleToggleOpen} />
            </Drawer>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
