import { datetimeExtensions } from '../extensions'
import { flightServices, formatFullPhoneNumber } from './index'
import {
  CHILD_OCCUPANCY_CODE,
  DEFAULT_PAYMENT_TYPE,
  enumType, FORMAT_ONLY_DATE, FORMAT_ONLY_DATE_3S, FORMAT_ONLY_DATE_REVERSE, INFANT_OCCUPANCY_CODE, LOCAL_STORAGE_KEY
} from '../constants'
import { cloneDeep, get } from 'lodash'
import moment from 'moment'
import { hotelUtils } from '@utils/'
import { SessionStorage } from '@utils/index'

const getOtherOccupancyInfo = (child, infant) => {
  let otherOccupancies = []

  if (child > 0) {
    otherOccupancies.push({
      otherOccupancyRefID: '',
      otherOccupancyRefCode: CHILD_OCCUPANCY_CODE,
      quantity: Number(child)
    })
  }

  if (infant > 0) {
    otherOccupancies.push({
      otherOccupancyRefID: '',
      otherOccupancyRefCode: INFANT_OCCUPANCY_CODE,
      quantity: Number(infant)
    })
  }

  return otherOccupancies
}

const initProfile = (data, type, isSwap = false) => {
  return {
    profileRefID: '',
    email: data.email || '',
    firstName: isSwap ? (data.lastName || '') : (data.firstName || ''),
    middleName: data.middleName || '',
    lastName: isSwap ? (data.firstName || '') : (data.lastName || ''),
    title: data.title || '',
    phoneNumber: formatFullPhoneNumber(data.prefixPhone || enumType.prefixPhone.Vn, data.phone),
    profileType: type,
    passport: data.passport || '',
    passportExpiredDate: data.passportExpiredDate || '',
    gender: data.gender,
    address: data.address || '',
    city: data.city || '',
    state: data.state || '',
    country: data.country || '',
    nationality: data.nationality || '',
    note: data.note || '',
  }
}

const initHotelReservation = (hotel, rooms, profiles, isBundle = false, comment = undefined, bundleInfo = undefined, couponCode = '') => {
  let roomsTmp = rooms;
  if(isBundle){
    roomsTmp = {};
    let countRoomOccupancy = 0;
    if(rooms[0].roomOccupancy.length > 0){
      rooms[0].roomOccupancy.map((item)=>{
        let lengthRoom = item.numberOfRoom || 1;
        for(let i = 1; i <= lengthRoom; i ++){
          roomsTmp[countRoomOccupancy] = {
            ...rooms[0],
            roomOccupancy: item
          };
          countRoomOccupancy = countRoomOccupancy + 1;
        }
      })
    }
    // let lengthRoom = rooms[0].numberOfRoom || 1;
    // if(lengthRoom > 1){
    //   for(let i = 1; i < lengthRoom; i ++){
    //     roomsTmp[i] = rooms[0];
    //   }
    // }
  }
  return {
    hotelId: hotel.hotelId,
    isBundle: isBundle,
    bundleInfo: bundleInfo,
    comment: comment,
    promotionCode: couponCode,
    arrivalDate: datetimeExtensions.formatEnFromVnDate(hotel.departureDate),
    lengthOfStay: hotel.lengthOfStay || datetimeExtensions.calculateVnDateDiff(hotel.returnDate, hotel.departureDate),
    roomOccupancy: {
      numberOfAdult: hotel.adult,
      otherOccupancies: getOtherOccupancyInfo(hotel.child, hotel.infant)
    },
    // reservations: Array(hotel.numberOfRoom).fill({
    //   roomRates: [
    //     {
    //       stayDate: datetimeExtensions.initNewVnDate(hotel.departureDate),
    //       roomtypeId: room.roomTypeId || room.id,
    //       ratePlanId: room.ratePlanId || room.rates[0].id
    //     }
    //   ],
    //   profiles: profiles
    // })
    reservations: Object.keys(roomsTmp).map(key => {
      return {
        roomOccupancy: {
            numberOfAdult: roomsTmp[key].roomOccupancy.numberOfAdult,
            numberOfChild: roomsTmp[key].roomOccupancy.numberOfChild,
            NumberOfInfant: roomsTmp[key].roomOccupancy.numberOfInfant
        },
        roomRates: [
          {
            stayDate: datetimeExtensions.formatEnFromVnDate(hotel.departureDate),
            roomtypeId: roomsTmp[key].roomtype.id,
            ratePlanId: roomsTmp[key].rate.ratePlanId
          }
        ],
        profiles: profiles
      }
    })
  }
}

export default {
  initHotelData(data) {
    const {
      locationId,
      hotelId,
      hdd,
      hr,
      hs,
      ha,
      hc,
      hi,
      hsp,
      promotionCode
    } = data

    return {
      locationId: locationId || '',
      topHotelId: hotelId || '',
      textSearch: hsp || '',
      isHotelIdsSpecified: false,
      hotelIds: [],
      arrivalDate: datetimeExtensions.initNewVnDate(hdd),
      lengthOfStay: Number(hs),
      numberOfRoom: Number(hr),
      roomOccupancy: {
        numberOfAdult: Number(ha),
        otherOccupancies: getOtherOccupancyInfo(hc, hi)
      },
      promotionCode: promotionCode || ''
    }
  },

  initHotelListIds(data) {
    const { locationId, hsp } = data
    return {
      locationId: locationId || '',
      textSearch: hsp || '',
    }
  },

  initRoomData(hotelId, data) {
    const {
      arrivalDate,
      dateAdd,
      hr,
      numberOfRoom,
      lengthOfStay,
      roomOccupancy,
      promotionCode,
      source,
      deviceType,
      sourceRefId,
      channel
    } = data

    return {
      hotelId: hotelId,
      arrivalDate: moment(arrivalDate, FORMAT_ONLY_DATE_3S).format(FORMAT_ONLY_DATE_REVERSE),
      dateAdd: Number(dateAdd),
      lengthOfStay: Number(lengthOfStay),
      numberOfRoom: Number(hr) || Number(numberOfRoom),
      promotionCode: promotionCode || '',
      roomOccupancy: roomOccupancy || '',
      source: source || '',
      deviceType: deviceType || '',
      sourceRefId: sourceRefId || '',
      channel: channel || ''
    }
  },

  getFlightAndHotelParams(data) {

    const flight = {
      startPoint: data.fsp,
      endPoint: data.fep,
      departureDate: data.fdd,
      returnDate: data.frd,
      adult: Number(data.fa),
      child: Number(data.fc),
      children: Number(data.fc),
      infant: Number(data.fi)
    }

    Object.keys(flight).forEach((key) => (flight[key] == null) && delete flight[key])

    const hotel = {
      startPoint: data.hsp,
      locationId: data.locationId,
      hotelId: data.hotelId,
      departureDate: data.hdd,
      adult: Number(data.ha),
      child: Number(data.hc),
      infant: Number(data.hi),
      lengthOfStay: Number(data.hs),
      numberOfRoom: Number(data.hr),
      promotionCode: data.promotionCode
    }

    Object.keys(hotel).forEach((key) => (hotel[key] == null) && delete hotel[key])

    const room = {
      hotelId: data.hotelId,
      roomTypeId: data.roomTypeId,
      roomPlanId: data.roomPlanId
    }

    Object.keys(room).forEach((key) => (room[key] == null) && delete room[key])

    return {
      flight: Object.keys(flight).length > 0 ? flight : null,
      hotel: Object.keys(hotel).length > 0 ? hotel : null,
      room: Object.keys(room).length > 0 ? room : null
    }
  },

  initComboBookingData(data, hotel, rooms, flight, affSId = undefined, params = undefined) {

    let dataSubmit = flightServices.initDomesticReservationData(data, undefined, params)

    let profiles = [
      initProfile(data, enumType.PROFILE_TYPE.Booker, true)
    ]
    let addonPrice = 0;

    const flightBookingCmtForHotel = flightServices.parseFlightComment(cloneDeep(data), flight);

    if (data.listPassengers && data.listPassengers.length > 0) {
      data.listPassengers.forEach(passenger => {
        profiles.push(initProfile(passenger, enumType.PROFILE_TYPE.Guest))
        addonPrice += (
          (get(passenger, 'baggageDeparture.price') || 0)
          + (get(passenger, 'baggageReturn.price') || 0)
        )
      })
    } else {
      profiles.push(initProfile(data, enumType.PROFILE_TYPE.Guest, true))
    }

    const promotionInfos = get(hotel, 'roomRates[0].ratePlan[0].promotionInfos');
    const couponCode = (promotionInfos && promotionInfos.isHasPromotion) ? hotel.promotionCode : ''

    const roomStr = (Object.keys(rooms).map(keyRoom => `${rooms[keyRoom].roomtype.id}_${rooms[keyRoom].rate.ratePlanId}`)).join(',')

    const bundleInfo = Object.assign(params, { addonPrice, roomRates: roomStr })

    const hotelData = initHotelReservation(hotel, rooms, profiles, true, flightBookingCmtForHotel, bundleInfo, couponCode)

    let orderItem = dataSubmit.orderItem

    orderItem.push({
      orderItemType: enumType.BOOKING_TYPE.HOTEL,
      bookingData: JSON.stringify(hotelData)
    })

    dataSubmit.orderItem = orderItem
    dataSubmit.affSId = affSId;
    dataSubmit.couponCode = couponCode;
    dataSubmit = params ? Object.assign(dataSubmit, {
      dataSession: params.departSession,
      dataReturnSession: params.returnSession,
      departureFlightSession: params.departFareOptionSession,
      returnFlightSession: params.returnFareOptionSession,
    }) : dataSubmit;

    return dataSubmit
  },

  initHotelBookingData(data, hotel, room, affSId = undefined, totalAmount = 0) {
    let profiles = []
    let profileObj = {}
    let comment = '';

    if(data.profiles && data.profiles.length > 0){
      profiles = [
        initProfile(data.profiles[0], enumType.PROFILE_TYPE.Booker),
        initProfile(data.profiles[!data.isFirstBooker ? 1 : 0], enumType.PROFILE_TYPE.Guest)
      ]
      profileObj = {
        firstName: data.profiles[0].firstName,
        lastName: data.profiles[0].lastName,
        fullname: `${data.profiles[0].firstName} ${data.profiles[0].lastName}` || '',
        email: data.profiles[0].email || '',
        phoneNumber: formatFullPhoneNumber(data.profiles[0].prefixPhone || enumType.prefixPhone.Vn, data.profiles[0].phone),
        nationality: data.profiles[0].nationality || '',
      }
      comment = data.profiles[0].specialRequirementsNote
    }

    const couponCode = hotel?.promotionCode || data?.couponCode || ''

    const hotelData = room.isCrossSellBooking       
      ? initHotelReservationWithCrossSell(hotel, room, couponCode, comment, totalAmount)
      : initHotelReservation3s(hotel, room, profiles, couponCode, comment, totalAmount)

    let dataSubmit = {
      ...profileObj,
      note: comment,
      channel: hotel.channel ? parseInt(hotel.channel) : undefined,
      source: hotel.source ? parseInt(hotel.source) : undefined,
      sourceRefId: hotel.sourceRefId || undefined,
      deviceType: hotel.deviceType ? parseInt(hotel.deviceType) : undefined,
      gatewayId: data.gatewayId || '',
      paymentType: data.paymentType ? data.paymentType.toLowerCase() : DEFAULT_PAYMENT_TYPE,
      paymentMethod: data.paymentMethod,
      couponCode: couponCode,
      orderItem: [
        {
          orderItemType: room.isCrossSellBooking ? enumType.BOOKING_TYPE.CROSSSELL : enumType.BOOKING_TYPE.HOTEL,
          bookingData: JSON.stringify(hotelData)
        }
      ],
      affSId,
    }
    return dataSubmit
  },

  phsBookingHotel(rooms, paramsHotel, affSId, phsOrderHotelCallback, executeRecaptcha) {
    const phsOrderStorage = SessionStorage.get(LOCAL_STORAGE_KEY.PHS_ORDER_HOTEL)
    const data = {
      itineraryType: enumType.itineraryType.Return,
      profiles: [],
      paymentMethod: null,
      paymentType: null,
      gatewayId: null,
      agreement: true,
      isCompleted: false,
      isEditing: true,
      isFirstBooker: true
    }
    const bookingData = this.initHotelBookingData(
      data,
      paramsHotel,
      {rooms: rooms},
      affSId
    );
    if(bookingData.orderItem[0] && bookingData.orderItem[0]?.bookingData){
      let phsOrderTmp = null;
      if(phsOrderStorage && Object.keys(phsOrderStorage).length > 0){
        phsOrderTmp = phsOrderStorage?.phsCode;
        let dateNow = moment.utc();
        if(dateNow.diff(moment(new Date(phsOrderStorage?.onPageTime))) > 0){
          SessionStorage.remove(LOCAL_STORAGE_KEY.PHS_ORDER_HOTEL)
          phsOrderTmp = undefined;
        }
      }
      phsOrderHotelCallback({
        bookingData: JSON.parse(bookingData.orderItem[0].bookingData),
        phsCode: phsOrderTmp
      }, executeRecaptcha)
    }
  }
}

const initHotelReservation3s = (hotel, infoRoom, profiles, couponCode, comment, totalAmount = 0) => {
  const { rooms } = infoRoom;

  let data = {
    hotelId: hotel.hotelId,
    comment: comment,
    arrivalDate: hotel.arrivalDate,
    lengthOfStay: hotel.lengthOfStay || datetimeExtensions.calculateVnDateDiff(hotel.returnDate, hotel.departureDate),
    promotionCode: couponCode,
    // roomOccupancy: {
    //   numberOfAdult: hotel.adult,
    //   otherOccupancies: getOtherOccupancyInfo(hotel.child, hotel.infant)
    // },

    reservations: Object.keys(rooms).map(key => {
      let additional = {};

      // for package rate
      if(rooms[key].rate?.isHotDeal) {
        additional = {
          packageRateId: rooms[key].rate?.packageRateDetail?.packageRateId
        }
      }

      //for cross sale bookable
      if(rooms[key].rate?.crossSaleIds?.length > 0 && rooms[key].rate?.packageRateDetail?.bookablePackageRates?.length > 0) {
        additional = {
          packageRateId: rooms[key].rate?.packageRateDetail?.packageRateIdForCrossSale,
          bookableRefIds: rooms[key].rate?.packageRateDetail?.bookablePackageRates?.map(x=>x.refId),
        }
      }

      return {
        roomOccupancy: {
            numberOfAdult: rooms[key].roomOccupancy.numberOfAdult,
            numberOfChild: rooms[key].roomOccupancy.numberOfChild,
            NumberOfInfant: rooms[key].roomOccupancy.numberOfInfant
        },
        roomRates: [
          {
            stayDate: hotel.arrivalDate,
            roomtypeId: rooms[key].roomtype.id,
            ratePlanId: rooms[key].rate.ratePlan.ratePlanId,
            ...additional
          }
        ],
        profiles: profiles
      }
    })

  }

  if (totalAmount > 0) {
      data.totalAmount = totalAmount
  }
  return data
}

const initHotelReservationWithCrossSell = (hotel, infoRoom, couponCode, comment, totalAmount = 0) => {
  const { rooms } = infoRoom;
  
  let data = {
    hotelId: hotel.hotelId,
    comment: comment,
    arrivalDate: hotel.arrivalDate,
    lengthOfStay: hotel.lengthOfStay,
    promotionCode: couponCode,
    parentOrderId: infoRoom.orderId,
    reservations: Object.keys(rooms).map(key => {
      let additional = {};

      if(rooms[key].rate?.crossSaleIds?.length > 0 && rooms[key].rate?.packageRateDetail?.bookablePackageRates?.length > 0) {
        additional = {
          packageRateId: rooms[key].rate?.packageRateDetail?.packageRateIdForCrossSale,
          bookableRefIds: rooms[key].rate?.packageRateDetail?.bookablePackageRates?.map(x=>x.refId),
        }
      }

      return {
        roomOccupancy: {
            numberOfAdult: rooms[key].roomOccupancy.numberOfAdult,
            numberOfChild: rooms[key].roomOccupancy.numberOfChild,
            NumberOfInfant: rooms[key].roomOccupancy.numberOfInfant
        },
        roomRates: {
          reservationId: rooms[key].reservationId,
          roomtypeId: rooms[key].rate.roomTypeId,
          ratePlanId: rooms[key].rate.ratePlanId,
          ...additional
        },
      }
    })    
  }

  if (totalAmount > 0) {
      data.totalAmount = totalAmount
  }
  return data
}

