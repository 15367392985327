import actionTypes from '../actionTypes'
import { LocalStorage } from '../utils'
import { LOCAL_STORAGE_KEY } from '../constants'

const initState = {
  paymentGates: [],
  contactInfo: typeof localStorage !== 'undefined' ? LocalStorage.get(LOCAL_STORAGE_KEY.PAYMENT_CONTACT_INFO) || {} : {}
}

const paymentReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        paymentGates: action.payload.data
      })
    case actionTypes.SET_CONTACT_INFO_SUCCESS:
      return Object.assign({}, state, {
        contactInfo: action.payload.data
      })
    default:
      return state
  }
}

export default paymentReducer