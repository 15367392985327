import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { DEFAULT_FORMAT_DATE } from '../../constants'
import $ from 'jquery'
import { useImperativeHandle } from 'react'

const range = (start, end) => {
  const result = []
  for (let index = start; index < end; index++) {
    result.push(index)
  }
  return result
}

const DateTimePicker = (props, ref) => {
  const {
    customClass,
    customDropdownClass,
    calendarClass = 'vpt-date-picker__calendar',
    pickerClass = 'vpt-date-picker',
    value,
    disabled,
    onChange,
    onBlur,
    timePicker,
    minDate,
    maxDate,
    formatDateInput,
    formatDateOutput,
    allowClear,
    placeholder,
    onFocus,
    icon = require('@assets/include/svg/calendar.svg').default,
    panelRender,
    replaceHeaderButtonTooltip = /\([^\(]*[\)$]/g,
    iconPlacement = "left" || "right",
    ...restProps
  } = props

  const [currentDate, setCurrentDate] = useState(null)
  const [displayMonth, setDisplayMonth] = useState(null)

  const datePickerRef = useRef(null)

  useEffect(() => {
    if (restProps.open) {
      replaceHeaderButtonTitle()
    }
  }, [restProps.open])

  /**
   * update state when props change
   */
  useEffect(() => setCurrentDate(formatDateSelected(value)), [value])

  /**
   * reformat date selected
   * @param dateSelected
   */
  const formatDateSelected = dateSelected => {
    return dateSelected
      ? moment(dateSelected, formatDateOutput)
      : null
  }

  /**
   * handle change date
   * @param value
   */
  const handleChangeDate = (value, datestring) => {
    const dateSelected = formatDateSelected(value)

    setCurrentDate(dateSelected)

    if (onChange) {
      if (dateSelected) {
        onChange(moment(dateSelected, datestring).format(formatDateOutput))
      } else {
        onChange(null)
      }

    }
  }

  const replaceHeaderButtonTitle = () => {
    if (replaceHeaderButtonTooltip) {
      setTimeout(() => {
        $(`.${calendarClass} .ant-calendar-next-month-btn,
          .${calendarClass} .ant-calendar-prev-month-btn,
          .${calendarClass} .ant-calendar-next-year-btn,
          .${calendarClass} .ant-calendar-prev-year-btn`).each(function() {
            const elm = $(this);
            const title = elm.attr('title');
            const newTitle = title.replace(replaceHeaderButtonTooltip, '');
            elm.attr('title', newTitle);
          });
      }, 500)
    }
  }

  const onOpenChange = (open) => {

    if (open) {
      replaceHeaderButtonTitle()
    }
    if (props.onOpenChange) {
      return props.onOpenChange(open)
    }
  }

  /**
   * handle blur date
   */
  const handleBlurDate = () => {
    if (onBlur) {
      onBlur()
    }
  }

  const handleFocusDate = () => {
    if (onFocus) {
      onFocus()
    }
  }

  /**
   * disable date
   * @param current
   */
  const disabledDate = (current, today) => {
    // return false is enable
    // return true is disable
    let propDisabledDate = false;
    const returnResult = (boolResult) => {
      return boolResult || propDisabledDate
    }
    if (props.disabledDate) {
      propDisabledDate = props.disabledDate(current, today)
    }
    if (!maxDate && !minDate) {
      return returnResult(false)
    }

    // can not select days before min day
    if (minDate && !maxDate) {
      return returnResult(current < moment(minDate).startOf('days'))
    }

    // can not select days after max day
    if (maxDate && !minDate) {
      return returnResult(current > moment(maxDate).endOf('days'))
    }

    return returnResult(!(
      current >= moment(minDate).startOf('days') &&
      current <= moment(maxDate).endOf('days')
    ))
  }

  /**
   * disable time
   */
  const disabledDateTime = () => {
    let enableHour = []
    let enableMinute = []
    let enableSecond = []

    if (minDate) {
      const momentMinDate = moment(minDate)

      enableHour.push(momentMinDate.hour())
      enableMinute.push(momentMinDate.minute())
      enableSecond.push(momentMinDate.second())
    }

    if (maxDate) {
      const momentMaxDate = moment(maxDate)

      enableHour.push(momentMaxDate.hour())
      enableMinute.push(momentMaxDate.minute())
      enableSecond.push(momentMaxDate.second())
    }

    const hourRange = range(0, 24)
    const enableHourRange = hourRange.splice(enableHour)

    const minuteRange = range(0, 60)
    const enableMinuteRange = minuteRange.splice(enableMinute)

    const secondRange = range(0, 60)
    const enableSecondRange = secondRange.splice(enableSecond)

    return {
      disabledHours: () =>
        hourRange.filter(hour => !enableHourRange.includes(hour)),
      disabledMinutes: () => [],
      disabledSeconds: () => []
    }
  }

  const customDateFormat = formatDateInput ? formatDateInput : DEFAULT_FORMAT_DATE

  const locale = {
    lang: {
      today: 'Hôm nay',
      ok: 'Chọn',
      clear: 'Xoá',
      timeSelect: 'Chọn giờ',
      dateSelect: 'Chọn ngày',
      yearFormat: 'YYYY',
      dateFormat: customDateFormat,
      dayFormat: 'D',
      dateTimeFormat: customDateFormat,
      monthFormat: 'MMMM'
    },
    dateFormat: customDateFormat,
    dateTimeFormat: customDateFormat,
    weekFormat: 'YYYY-wo',
    monthFormat: 'YYYY-MMMM'
  }

  const valueSelected = formatDateSelected(currentDate)

  const dateRender = (current, today) => {
    if (current.date() === 15 && props.onChangeDisplayMonth) {
      const month = current.format('MM-YYYY');
      if (month !== displayMonth) {
        props.onChangeDisplayMonth(current.startOf('months'))
      }
      setDisplayMonth(month)
    }
    if (props.dateRender) {
      return props.dateRender(current, today)
    }
    return (
      <div className="ant-calendar-date">
        {current.date()}
      </div>
    );
  }

  useImperativeHandle(ref, () => ({
    open: () => {
      if(datePickerRef.current && !document.getElementsByClassName('vpt-date-picker__calendar')?.length){
        datePickerRef.current.focus();
        datePickerRef.current.picker?.input?.click();
      };
    }
  }));

  return (
    <DatePicker
      {...restProps}
      ref={datePickerRef}
      placeholder={placeholder}
      className={clsx({
        [pickerClass]: true,
        [`${customClass}`]: customClass,
        ["icon-right"] : iconPlacement === "right"
      })}
      dropdownClassName={clsx({
        [calendarClass]: true,
        [`${customDropdownClass}`]: customDropdownClass
      })}
      pick
      locale={locale}
      disabled={disabled}
      value={valueSelected}
      format={props.format || customDateFormat}
      allowClear={allowClear}
      showTime={timePicker === true}
      suffixIcon={icon && <img src={icon} alt='calendar' />}
      onChange={handleChangeDate}
      onBlur={handleBlurDate}
      disabledDate={disabledDate}
      disabledTime={disabledDateTime}
      onFocus={handleFocusDate}
      onOpenChange={onOpenChange}
      dateRender={dateRender}
      panelRender={panelRender}
    />
  )
}

DateTimePicker.propTypes = {
  value: PropTypes.string,
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
  formatDateOutput: PropTypes.string,
  formatDateInput: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  timePicker: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  allowClear: PropTypes.bool,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  disabledDate: PropTypes.func,
  dateRender: PropTypes.func,
  panelRender: PropTypes.func,
  open: PropTypes.bool,
  onChangeDisplayMonth: PropTypes.func,
}

export default forwardRef(DateTimePicker)
