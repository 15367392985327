import React, { Fragment } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const Button = ({ buttonSize, buttonType, disabled, isLoading }) => {
  const btnClass = clsx({
    btn: true,
    [`btn-${buttonSize}`]: true,
    [`u-btn-${buttonType}`]: true,
    'rounded-0': true,
    'g-mr-10': true,
    'g-mb-15': true,
    disabled: disabled || isLoading
  })
  return (
    <button className={btnClass}>
      {
        isLoading
          ? (<Fragment>
            <i class="fa fa-spinner fa-spin"/>
            <span class="sr-only">Loading...</span>
          </Fragment>)
          : { text }
      }
    </button>
  )
}

Button.propTypes = {
  buttonSize: PropTypes.string.isRequired,
  buttonType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool
}

export default Button
