import ApiService from './../api/ApiService'
import Urls from './../api/Urls'
import config from './../config'

export function getBlog(params) {
  return new ApiService({
    endpoint: Urls.BLOG_LIST,
    baseURL: config.BASE_URL_BLOG_API,
    params: params,
  }).get()
}

export function getBlogDetail(slug) {
  return new ApiService({
    endpoint: Urls.BLOG_DETAIL,
    endpointParams: { slug },
    baseURL: config.BASE_URL_BLOG_API,
  }).get()
}
