import { COUPON } from '../constants/enumType';

export default {
  formatNumberWithDot (number) {
    const numberCeil = number
      ? Math.ceil(number)
      : null
    return number
      ? numberCeil.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      : 0
  },

  updateQuantity (number, isPlus, step=1) {
    const quantityChange = isPlus
      ? Number(number) + step
      : Number(number) - step

    return quantityChange > 0 ? quantityChange : 0
  },

  getPercentage (number, total) {
    return (number / total) * 100
  },

  roundNumber (num, decimal) {
    const pow = Math.pow(10, decimal ? decimal : 0)
    return num ? Math.round(num * pow) / pow : 0
  },

  getPriceHasPromotion (type = 'PERCENTAGE', number = 0, total = 0){
    let priceDiscount = 0;
    if(type && number && total){
      if(type === COUPON.PROMOTION_MARKUP_DISCONT_TYPE.PERCENTAGE){
        priceDiscount = (total*(100 - number))/100;
      }else{
        priceDiscount = total - number;
      }
    }
    
    return Number(priceDiscount)
  }
}