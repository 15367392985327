import React, { memo, useEffect, useState } from 'react'
import { Menu, Dropdown } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import {
  languageEnum
} from '../../constants/language'
import { objectExtensions } from '../../extensions'
import { Cookie, LocalStorage } from '../../utils'
import { getCurrentLanguage, setLanguageCookie, setCurrencyOnlCookie, getCurrentCurrencyOnl } from '../../utils/lang'
import { useGlobalStore } from '../../hooks/useGlobal'
import clsx from 'clsx'
import { featureFlags, LOCAL_STORAGE_KEY } from '../../constants'
import { convertLinkLocale } from '@utils/common'
import Router from '@utils/router'

import { getCountryByIP } from 'src/services/ExperienceService'
import { axiosProviderCms } from 'src/actions/api/axiosProvider'
import { FormattedMessage } from 'react-intl'
import "./Language.scss";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const Language = ({containerSelector = null, isLoginVPT= false, isLoginTab= false}) => {
  const { state, dispatch } = useGlobalStore();
  const search = useLocation().search;
  const currency = state.currencyOnl || getCurrentCurrencyOnl()
  //creating function to load ip address from the API
  const getData = async () => {
    const checkLangCodeByIp = Cookie.get("langCodeByIP");
    try {
      if (!checkLangCodeByIp) {
        const resIp = await axiosProviderCms.get('https://api.ipgeolocation.io/ipgeo?apiKey=9b0f16f82246493eaad59865248850b4')
        const languageCodeFromIp = await getCountryByIP({ ipAddress: resIp.data.ip });
        let langCode = languageCodeFromIp.languageCode;
        const checkLangCode = languageEnum.some((el) => {
          return langCode === el.value
        })
        if (!checkLangCode) {
          langCode = 'en'
        }
        Cookie.set("langCodeByIP", "success", { secure: true })
        handleChangeLanguage({ key: langCode })
      }
    } catch (error) {
      console.log('getData', error);
    }
  }

  // off getData
  // useEffect(() => {
  //   //passing getData method to the lifecycle method
  //   getData();
  // }, [])

  const handleChangeLanguage = ({ key }) => {
    if (key !== locale) {
      setLanguageCookie(key)
      dispatch({ type: 'lang', data: key });
      LocalStorage.set(LOCAL_STORAGE_KEY.LANGUAGE_WESBITE, { type: 'lang', data: key })
      let languagePath = convertLinkLocale(key, null, 'VND', true)
      if (key === 'en') {
        setCurrencyOnlCookie('USD')
        dispatch({ type: 'currencyOnl', data: 'USD' });
        LocalStorage.set(LOCAL_STORAGE_KEY.CURRENCY_WESBITE, { type: 'currencyOnl', data: 'USD' })
        languagePath = convertLinkLocale(key, null, 'USD', true)
      }
      if (key === 'ko') {
        setCurrencyOnlCookie('KRW')
        dispatch({ type: 'currencyOnl', data: 'KRW' });
        LocalStorage.set(LOCAL_STORAGE_KEY.CURRENCY_WESBITE, { type: 'currencyOnl', data: 'KRW' })
        languagePath = convertLinkLocale(key, null, 'KRW', true)
      }
      if (key === 'zh') {
        setCurrencyOnlCookie('CNY')
        dispatch({ type: 'currencyOnl', data: 'CNY' });
        LocalStorage.set(LOCAL_STORAGE_KEY.CURRENCY_WESBITE, { type: 'currencyOnl', data: 'CNY' })
        languagePath = convertLinkLocale(key, null, 'CNY', true)
      }

      if(isLoginVPT){
        const typeLogin = new URLSearchParams(search);
        const hasType = typeLogin.get("type");

        if ((isLoginTab && hasType)) {
          languagePath = languagePath.replace("?type=register", "");
        } else if (!isLoginTab && !hasType) {
          languagePath += `?type=register`;
        }
      }
      Router.push(languagePath)
    }
  }

  const locale = state.lang || getCurrentLanguage();
  const menu = (
    <Menu
      selectable={false}
      onClick={handleChangeLanguage}
      className="s43-lang-menu g-font-EBGaramond"
    >
      <Menu.ItemGroup className='lang-group' title={
        <FormattedMessage
          id="Hotel.Search.OtherInfo.Language"
          defaultMessage='Ngôn ngữ'
        />
      }>
        {
          languageEnum.map((lang) => (
            <Menu.Item
              key={lang.value}
              className='lang-item'
            >
              <img src={lang.icon} alt="lang" />
              {lang.label}
            </Menu.Item>
          ))
        }
      </Menu.ItemGroup>
    </Menu>
  )
  // render
  return (
    <div className="s43-lang-btn-container">
      <Dropdown
        overlay={menu} trigger={['hover']} placement="bottomRight"
        className='d-flex justify-content-center align-items-center'
        getPopupContainer={!!containerSelector && (triggerNode => triggerNode.closest(containerSelector))}
      >
        <strong>
          <a className="ant-dropdown-link d-flex justify-content-center align-items-center">
            <img src={objectExtensions.getIconFromValue(locale, languageEnum)} alt="lang"
                className="rounded-circle g-width-24 g-height-24 vpt-object-fit-cover" /> 
            {isLoginVPT && 
              <span className='language-suffix ml-2'>
                {(locale === "vi" ? "vie" : locale || "vie").toUpperCase()} 
                <RightOutlined />
              </span>
             }
          </a>
        </strong>
      </Dropdown>
    </div>
  )
}

export default memo(Language)
