import { Button } from 'antd'
import React, { Fragment, useState, useEffect } from 'react'
import ItemPopup from './ItemPopup'
import { cartUtils } from '../../utils'
import { FormattedMessage, injectIntl } from 'react-intl'
import { CurrencyNumber } from '../Elements'
import Router from '@utils/router'
import { routes } from '../../constants'
import { xssFilter } from '../../helpers/functions'
import { convertLinkLocale } from '@utils/common'
import { useGlobalStore } from 'src/hooks/useGlobal'

const DetailPopup = (props) => {
    const {
        vouchers = [],
        tours = [],
        vinWonders = [],
        allItems=[],
        vinWonderTours=[],
        intl
    } = props

    const { state } = useGlobalStore()
    
    const [dataTours, setDataTours] = useState([])
    const [dataVouchers, setDataVouchers] = useState([])

    useEffect(() => {
        getDataMappingProduct(tours, setDataTours)
    }, [tours])

    useEffect(() => {
        getDataMappingProduct(vouchers, setDataVouchers)
    }, [tours])

    const getDataMappingProduct = (data, callbaclFunc) => {
        const arrData = cartUtils.mappingTour(data)
        callbaclFunc(arrData)
    }
    
    const totalPriceProduct = (item) => {
        return cartUtils.getTotalPrice(item)
    }

    const totalPriceCart = () => {
        let priceCart = 0;
        allItems.forEach(product => {
            priceCart += totalPriceProduct(product)
        })

        return priceCart
    }

    const totalQuantityCart = () => {
        let quantityCart = 0;
        allItems.forEach(product => {
            quantityCart += cartUtils.getTotalQuantity(product)
        })

        return quantityCart
    }


    return (
        <div>
            <div className="info-cart-popup--list-product">
                {
                    allItems.length > 0 ?
                        <Fragment>
                            {/* {
                                vouchers.map(voucher => 
                                    <ItemPopup 
                                        key={voucher.productVariantId}
                                        title={voucher.itemName}
                                        thumbImage={voucher.thumbImage}
                                        quantity={cartUtils.getTotalQuantity(voucher)}
                                        price={totalPriceProduct(voucher)}
                                        urlProduct='VOUCHER_DETAIL'
                                        slugProduct={voucher.urlSlug}
                                        item={voucher}
                                    />
                                )
                            } */}
                            {
                                dataVouchers.map(tour => 
                                    <ItemPopup 
                                        key={tour.productVariantId}
                                        title={tour.itemName}
                                        thumbImage={tour.thumbImage}
                                        quantity={cartUtils.getTotalQuantity(tour)}
                                        price={totalPriceProduct(tour)}
                                        urlProduct='TOUR_DETAIL'
                                        slugProduct={tour.urlSlug}
                                        item={tour}
                                    />
                                )
                            }
                            {
                                dataTours.map(tour => 
                                    <ItemPopup 
                                        key={tour.productVariantId}
                                        title={tour.itemName}
                                        thumbImage={tour.thumbImage}
                                        quantity={cartUtils.getTotalQuantity(tour)}
                                        price={totalPriceProduct(tour)}
                                        urlProduct='TOUR_DETAIL'
                                        slugProduct={tour.urlSlug}
                                        item={tour}
                                    />
                                )
                            }
                            {
                                [...vinWonders, ...vinWonderTours].map(vinWonder => 
                                    <ItemPopup 
                                        key={vinWonder.productVariantId}
                                        title={vinWonder.itemName}
                                        thumbImage={vinWonder.thumbImage}
                                        quantity={cartUtils.getTotalQuantity(vinWonder)}
                                        price={totalPriceProduct(vinWonder)}
                                        urlProduct='EXPERIENCE_DETAIL'
                                        slugProduct={vinWonder.urlSlug}
                                        item={vinWonder}
                                    />
                                )

                            }
                        </Fragment>
                    :

                    <p className="cart-empty-message">
                        <FormattedMessage
                            id="Cart.Empty.YouHaveNotSelected"
                            defaultMessage='Bạn chưa chọn sản phẩm nào trong giỏ hàng'
                        />
                    </p>
                }
                
            </div>
            <div className="info-cart-popup--total">
                <div className="total-content">
                    <p 
                        className="g-mb-0"
                        dangerouslySetInnerHTML={{ 
                            __html: xssFilter(intl.formatMessage({
                              id: 'Cart.TotalPrice',
                              defaultMessage: "Tổng tiền <span>({totalQuantity} items)</span>"
                            }, {
                                totalQuantity: totalQuantityCart()
                              }
                            ))
                        }}></p>
                    <span className="price">
                        <CurrencyNumber value={totalPriceCart()} />
                    </span>
                </div>
                <Button 
                    onClick={() => Router.push(convertLinkLocale(state.lang, routes.MY_RESERVE, state.currencyOnl))}
                    className='ant-btn btn btn-md g-color-white g-brd-primary g-bg-primary py-0 g-rounded-2 total-button'>
                    <FormattedMessage id="Cart.ViewCart" defaultMessage="Xem giỏ hàng"/>
                </Button>
            </div>
        </div>
    )
}

export default injectIntl(DetailPopup)