import { createSelector } from 'reselect'

const getFooter = state => state.footer.footers
const getRedirectLink = state => state.footer.redirectInfo || {};

export const selectFooters = () =>
  createSelector(
    getFooter,
    footers => footers
)


export const selectRedirectLink = () =>
  createSelector(
    getRedirectLink,
    redirectInfo => redirectInfo || {},
)