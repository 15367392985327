import actionTypes from '../actionTypes'

const initState = {
  categories: null,
  group: [],
  voucher: null,
  locations: [],
  special: [],
  exchangeRate: []
}

const voucherReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_CATEGORY_VOUCHER_SUCCESS:
      return Object.assign({}, state, {
        categories: action.payload.data
      })
    case actionTypes.FETCHING_VOUCHER_GROUP_SUCCESS:
      return Object.assign({}, state, {
        group: action.payload.data
      })
    case actionTypes.FETCHING_VOUCHER_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        voucher: action.payload.data
      })
    case actionTypes.FETCHING_VOUCHER_LOCATION_SUCCESS:
      return Object.assign({}, state, {
        locations: action.payload.data
      })
    case actionTypes.FETCHING_VOUCHER_SPECIAL_SUCCESS:
      return Object.assign({}, state, {
        special: action.payload.data
      })
    case actionTypes.FETCHING_EXCHANGERATE_SUCCESS:
      return Object.assign({}, state, {
        exchangeRate: action.payload.data
      })
    default:
      return state
  }
}

export default voucherReducer
