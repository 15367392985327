import { enumType } from "src/constants";
import { get } from "lodash";
import { TBD } from "src/helpers/gtmEvent";
import { getConfig } from "@config/";
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import CryptoJS from 'crypto-js';

const {
  publicRuntimeConfig: {
    NODE_ENV,
    AES_KEY,
  }
} = getConfig()

export const isGtmDisabled = () => {
  try {
    const {
      publicRuntimeConfig: {
        NODE_ENV,
        AES_KEY,
      }
    } = getConfig();
    return NODE_ENV === 'development' || NODE_ENV === 'staging' || typeof window === 'undefined'
  } catch (error) {
    console.log('isGtmDisabled', error);
  }
}

export const createRoomOccupancy = ({ roomOccupancy = "1_0_0" }) => {
  let adult = 0;
  let child = 0;
  let infant = 0;
  let passenger = [];

  const rooms = roomOccupancy.split(",");
  if (rooms.length > 1) {
    rooms.forEach((room) => {
      passenger = room.split("_");
      adult += parseInt(passenger[0]);
      child += parseInt(passenger[1]);
      infant += parseInt(passenger[2]);
    });
  } else {
    passenger = roomOccupancy.split("_");
    adult = parseInt(passenger[0]);
    child = parseInt(passenger[1]);
    infant = parseInt(passenger[2]);
  }
  return { adult, child, infant, passenger };
};

export const getPaymentMethod = ({ paymentGates = [], data = {} }) => {
  let paymentMethod = "";
  switch (data?.paymentType) {
    case enumType.paymentType.ONEPAY:
      let dataFind = [];
      Object.values(paymentGates[data?.paymentType].methods).forEach((ar) => {
        dataFind = [...dataFind, ...ar];
      });
      const paymentFind = (dataFind || []).find(
        (el) => el.payment_method === data.paymentMethod
      );
      if (paymentFind) {
        switch (paymentFind.type) {
          case enumType.paymentMethod.QRCode:
          case enumType.paymentMethod.International:
            paymentMethod = paymentFind.payment_method;
            break;
          case enumType.paymentMethod.Domestic:
          default:
            paymentMethod = paymentFind.title;
            break;
        }
      } else {
        paymentMethod = data.paymentMethod;
      }
      break;
    case enumType.paymentType.ONEPAY_INSTALLMENT:
      paymentMethod = paymentGates[data?.paymentType].methods.name;
      break;
    case enumType.paymentMethod.PayAtHotel:
      paymentMethod = "Thanh toán tại khách sạn";
      break;
    default:
      break;
  }
  return paymentMethod;
};

export const getMinPriceRate = (rates, isCombo = false) => {
  const allPriceRate = rates.filter(item => !item.isSoldOut)
    .map(rate => isCombo ? rate.increaseTotalPrice : rate.averageAmount.amount)

  return Math.min(...allPriceRate);
};

export const flightTicketPrice = (flight = {}, passenger = 1) => {
  const { fare = {}, fareOptions } = flight;
  return (fare.totalPrice || fareOptions?.[0].totalPrice || 0) / passenger;
  // return (
  //   (fare?.feeAdult || fareOptions?.[0].feeAdult || 0) +
  //   (fare?.priceAdult || fareOptions?.[0].priceAdult || 0) +
  //   (fare?.taxAdult || fareOptions?.[0].taxAdult || 0) +
  //   (fare?.vptServiceFee || fareOptions?.[0].vptServiceFee || 0)
  // );
};

export const bookingFlight = (flightData = {}) => {
  let booking_flight = `${flightData?.startPoint}:${flightData?.endPoint}`;
  if (flightData?.itineraryType === "2") {
    booking_flight += `-${flightData?.endPoint}:${flightData?.startPoint}`;
  }
  return booking_flight;
};

export const calcQuantityFlightTicket = ({ productDetail = {} }) => {
  return (
    (productDetail?.passenger?.adult || productDetail?.adult || 0) +
    (productDetail?.passenger?.child || productDetail?.child || 0) +
    (productDetail?.passenger?.infant || productDetail?.infant || 0)
  );
};

export const calcTax = ({ productDetail = {}, flightInfo = {} }) => {
  const adult = Number(flightInfo?.adult || 0);
  const children = Number(flightInfo?.children || 0);
  const infant = Number(flightInfo?.infant || 0);
  const segment = get(productDetail, "listSegment[0]") || {};
  const lastSegment =
    productDetail.stops > 0
      ? get(
          productDetail,
          `listSegment[${productDetail.listSegment.length - 1}]`
        ) || {}
      : segment;
  const fare = get(productDetail, "fareOptions[0]") || {};
  const priceAdult = adult * (fare.priceAdult || 0);
  const priceChild = children * (fare.priceChild || 0);
  const totalInfant = infant * (fare.priceInfant || 0);
  const ticketPrice = priceAdult + priceChild + totalInfant;

  const totalTax =
    fare.taxAdult * adult + fare.taxChild * children + fare.taxInfant * infant;
  const totalFee =
    fare.feeAdult * adult + fare.feeChild * children + fare.feeInfant * infant;
  const totalIssueFee = fare.issueFee * (adult + children + infant);
  const totalServiceFee = fare.serviceFee * (adult + children + infant);
  const vptFee = fare.vptServiceFee * (adult + children); // except vpt fee for infant only
  const totalTaxesAndFees =
    totalTax + totalFee + totalIssueFee + totalServiceFee + vptFee;
  return {
    totalTax,
    totalFee,
    totalServiceFee,
    vptFee,
    ticketPrice,
    totalTaxesAndFees,
  };
};

export const getIncreasePrice = ({ flightData = {} }) => {
  const fare =
    flightData && flightData.fareOptions && flightData.fareOptions.length > 0
      ? flightData.fareOptions[0]
      : {};
  return fare?.increasePrice || 0;
};

const ACCOUNT_TYPE = "Account";

const ACCOUNT_PAGE = [
  { page: "user-profile", type: ACCOUNT_TYPE },
  { page: "pearl-club-benefit", type: ACCOUNT_TYPE },
  { page: "my-offer", type: ACCOUNT_TYPE },
  { page: "my-loyalty-point", type: ACCOUNT_TYPE },
  { page: "point-usage-history", type: ACCOUNT_TYPE },
  { page: "night-usage-history", type: ACCOUNT_TYPE },
  { page: "my-order", type: ACCOUNT_TYPE },
  { page: "referral-member", type: ACCOUNT_TYPE },
];

const INFORMATION_TYPE = "Information";

const INFORMATION_PAGE = [
  {
    page: "dieu-khoan/",
    type: INFORMATION_TYPE,
    page_category_lv1: "Term & Conditions",
  },
  {
    page: "staynfun",
    type: INFORMATION_TYPE,
    page_category_lv1: "Homestay",
  },
];

const SEARCH_TYPE = "Search";

const SEARCH_PAGE = [
  { page: "hotel/search?", type: SEARCH_TYPE, page_category_lv1: "Hotels" },
  {
    page: "flight?startPoint",
    type: SEARCH_TYPE,
    page_category_lv1: "Flights",
  },
  {
    page: "combo/search?",
    type: SEARCH_TYPE,
    page_category_lv1: "Combo",
    page_category_lv2: "Hotels",
  },
  {
    page: "combo/flight?",
    type: SEARCH_TYPE,
    page_category_lv1: "Combo",
    page_category_lv2: "Flights",
  },
  {
    page: "tours-and-experiences/search?",
    type: SEARCH_TYPE,
    page_category_lv1: "Tours & Experiences",
  },
  {
    page: "staynfun/search?",
    type: SEARCH_TYPE,
    page_category_lv1: "Homestay",
  },
];

const CATEGORY_TYPE = "Category";

const CATEGORY_PAGE = [
  { page: "hotel", type: CATEGORY_TYPE, page_category_lv1: "Hotels" },
  { page: "flight", type: CATEGORY_TYPE, page_category_lv1: "Flights" },
  { page: "combo", type: CATEGORY_TYPE, page_category_lv1: "Combo" },
  {
    page: "tours-and-experiences",
    type: CATEGORY_TYPE,
    page_category_lv1: "Tours & Experiences",
  },
];

const PRODUCT_TYPE = "Product";

const PRODUCT_PAGE = [
  { page: "hotel/", type: PRODUCT_TYPE, page_category_lv1: "Hotels" },
  {
    page: "combo/",
    type: PRODUCT_TYPE,
    page_category_lv1: "Combo",
    page_category_lv2: "Hotels",
  },
  {
    page: "tour/",
    type: PRODUCT_TYPE,
    page_category_lv1: "Tours & Experiences",
  },
  {
    page: "experience/",
    type: PRODUCT_TYPE,
    page_category_lv1: "Tours & Experiences",
  },
  {
    page: "staynfun/",
    type: PRODUCT_TYPE,
    page_category_lv1: "Homestay",
  },
];

const CART_TYPE = "Cart";

const CART_PAGE = [
  { page: "flight?", type: CART_TYPE, page_category_lv1: "Flights" },
  { page: "combo/flight?", type: CART_TYPE, page_category_lv1: "Combo" },
  { page: "combo/cart?", type: CART_TYPE, page_category_lv1: "Combo" },
];

const CHECKOUT_TYPE = "Checkout";

const CHECKOUT_PAGE = [
  { page: "hotel/booking", type: CHECKOUT_TYPE, page_category_lv1: "Hotels" },
  { page: "booking?", type: CHECKOUT_TYPE, page_category_lv1: "Flights" },
  { page: "combo/booking?", type: CHECKOUT_TYPE, page_category_lv1: "Combo" },
  {
    page: "cart",
    type: CHECKOUT_TYPE,
    page_category_lv1: "Tours & Experiences",
  },
  {
    page: "staynfun/booking",
    type: CHECKOUT_TYPE,
    page_category_lv1: "Homestay",
  },
];

const ARTICLE_TYPE = "Article";

const ARTICLE_PAGE = [
  { page: "/blog", type: ARTICLE_TYPE, page_category_lv1: "Blog" },
];

export function checkPageType({location = {}, isHomePage = false}) {
  try {
    const locationPath = location?.pathname?.toLowerCase();
    const PAGE = [
      ...ACCOUNT_PAGE,
      ...INFORMATION_PAGE,
      ...SEARCH_PAGE,
      ...CATEGORY_PAGE,
      ...PRODUCT_PAGE,
      ...CART_PAGE,
      ...CHECKOUT_PAGE,
      ...ARTICLE_PAGE,
    ];
    const indexOfType = PAGE.find((el) => {
      let isSearch;

      if (Boolean(location.search)) {
        if (isHomePage) {
          isSearch = [INFORMATION_TYPE];
        } else {
          isSearch = [SEARCH_TYPE, PRODUCT_TYPE, CART_TYPE];
        }
      } else if (location.pathname.includes('location')) {

      } else {
        if (locationPath.includes("booking") || locationPath.includes("cart")) {
          isSearch = [CHECKOUT_TYPE];
        } else {
          isSearch = [CATEGORY_TYPE, PRODUCT_TYPE, INFORMATION_TYPE, ACCOUNT_TYPE];
        }
      }

      return (
        locationPath.includes(el.page?.toLowerCase()) &&
        isSearch.includes(el.type)
      );
    });

    return {
      page_type: indexOfType?.type || null,
      page_category_lv1: indexOfType?.page_category_lv1 || null,
      page_category_lv2: indexOfType?.page_category_lv2 || null,
      page_category_lv3: indexOfType?.page_category_lv3 || null,
    };
  } catch (error) {
    console.log("error", error);
  }
}


export const checkPageCategoryLv1 = ({ location = {} }) => {};

export const createComboName = ({
  startPointName = "",
  name = "",
  bookingRoomNight = 1,
}) => {
  return `Combo: ${startPointName} - ${name} ${
    +bookingRoomNight + 1
  } Days ${bookingRoomNight} Nights`;
};
export const createComboHotelName = ({ hotelName, roomName }) => {
  return `Combo: ${hotelName} - ${roomName}`;
};

export const createComboHotelPrice = ({
  increaseTotalPrice = 0,
  room = 1,
  bookingRoomNight = 1,
}) => {
  return increaseTotalPrice / room / bookingRoomNight;
};

export const createFlightName = ({startPoint, endPoint, type = 1}) => {
  const departureName = `Flight: ${startPoint}-${endPoint}`;
  const returnName = `Flight: ${endPoint}-${startPoint}`;
  return {departureName, returnName}
}
export const createComboFlightName = ({startPoint, endPoint, type = 1}) => {
  const departureName = `Combo: ${startPoint}-${endPoint}`;
  const returnName = `Combo: ${endPoint}-${startPoint}`;
  return {departureName, returnName}
}

export const encryptAES256 = ({data = ''}) => {
  const {
    publicRuntimeConfig: {
      NODE_ENV,
      AES_KEY,
    }
  } = getConfig();
  const key = CryptoJS.enc.Hex.parse(AES_KEY);
  const iv = CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
  const encrypt = AES.encrypt(data, key, {iv, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC});
  return (encrypt || "").toString();
}

export const decryptAES256 = ({data = ''}) => {
  const {
    publicRuntimeConfig: {
      NODE_ENV,
      AES_KEY,
    }
  } = getConfig();
  const key = CryptoJS.enc.Hex.parse(AES_KEY);
  const iv = CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
  const decrypt = AES.decrypt(data, key, {iv, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC});
  return (decrypt || "").toString(Utf8);
}