import { find, get, every } from 'lodash'
import { OrderItemType } from '../constants'
import Coupon from '../model/Coupon'

function getCartDiscountValueSKU(coupon, price, productId, cartItem, exchangeRateList = []) {
  const isCouponModel = coupon instanceof Coupon;
  if (coupon && isCouponModel && coupon.isPromotion && coupon.productsDiscount.includes(productId)) {
      return getPriceDiscountTicket(cartItem, exchangeRateList, coupon)
  } else {
    return 0
  }
}

function getPriceDiscountTicket(item, exchangeRateList, coupon) {
  const decrementAdultPrice = getFinalPrice(calcPrice(get(item, 'salePrice', 0), exchangeRateList, coupon), coupon) * get(item, 'quantity', 0)
  const decrementChildPrice = getFinalPrice(calcPrice(get(item, 'childSalePrice', 0), exchangeRateList, coupon), coupon) * get(item, 'childQuantity', 0)
  const decrementSeniorPrice = getFinalPrice(calcPrice(get(item, 'seniorSalePrice', 0), exchangeRateList, coupon), coupon) * get(item, 'seniorQuantity', 0)
  const decrementIfantrice = getFinalPrice(calcPrice(get(item, 'infantSalePrice', 0), exchangeRateList, coupon), coupon) * get(item, 'infantQuantity', 0)
  return decrementAdultPrice + decrementChildPrice + decrementSeniorPrice + decrementIfantrice
}

function calcPrice(price, exchangeRateList, coupon) {
  return Number((coupon.getDiscountValue(price, exchangeRateList)).toFixed())
}

function getFinalPrice(priceDiscount, coupon) {
  return coupon.maxDiscountValues > 0 && coupon.maxDiscountValues < priceDiscount ? coupon.maxDiscountValues : priceDiscount
}

function getCartTotalPrice({ vouchers = [], tours = [], vinWonders = [] }, coupon, exchangeRateList = [], isUseCreditPoint = false, creditPointData = {}) {
  let totalPrice = 0
  let promotionDiscountValue = 0

  if (vouchers.length > 0) {
    vouchers.forEach(voucher => {
      promotionDiscountValue += getCartDiscountValueSKU(coupon, voucher.price, voucher.tourId, voucher, exchangeRateList)
      totalPrice += voucher.price
    })
  }
  if (tours.length > 0) {
    tours.forEach(tour => {
      promotionDiscountValue += getCartDiscountValueSKU(coupon, tour.price, tour.tourId, tour, exchangeRateList)
      totalPrice += tour.price
    })
  }
  if (vinWonders.length > 0) {
    vinWonders.forEach(vinWonder => {
      promotionDiscountValue += getCartDiscountValueSKU(coupon, vinWonder.salePrice, vinWonder.vinWonderId, vinWonder, exchangeRateList)
      totalPrice += vinWonder.price
    })
  }

  if(coupon ){
    const isCouponModel = coupon instanceof Coupon;
    if(coupon.discountMode === 'ALL' && isCouponModel){
      promotionDiscountValue = getFinalPrice(Number((coupon.getDiscountValue(totalPrice, exchangeRateList)).toFixed()), coupon);
    }
  }

  const isCouponModel = coupon instanceof Coupon;
  const discountValue = coupon && isCouponModel ? (!coupon.isPromotion ? coupon.getDiscountValue(totalPrice, exchangeRateList) : promotionDiscountValue) : 0
  const viewPrice = coupon && !coupon.isPromotion && isCouponModel ? coupon.getDiscountPrice(totalPrice, false, exchangeRateList) : (totalPrice - promotionDiscountValue)

  if (isUseCreditPoint) {
    const creditPointCalculation = creditPointData?.data || {}
    const isSuccess = creditPointData?.isSuccess || false
    if (isSuccess) {
      const exchangeCash = creditPointCalculation?.exchangeCash ?? 0
      return { totalPrice, viewPrice: viewPrice - exchangeCash < 0 ? 0 : viewPrice - exchangeCash, discountValue, exchangeCash, priceAfterCoupon: viewPrice }
    }
  }

  return { totalPrice, viewPrice, discountValue, exchangeCash: 0, priceAfterCoupon: viewPrice }
}

function isSameProducts(products = []) {
  if (products.length > 1) {
    const firstProductType = products[0].cartItemType
    const isSameType = every(products, item => item.cartItemType === firstProductType)
    if (isSameType) {
      if (firstProductType === OrderItemType.voucher.key) {
        return every(products, item => item.productVariantId === products[0].productVariantId)
      }
      if (firstProductType === OrderItemType.tour.key) {
        return every(products, item => item.tourId === products[0].tourId)
      }
      if (firstProductType === OrderItemType.vinWonderTicket.key) {
        return every(products, item => item.vinWonderId === products[0].vinWonderId)
      }
    }
    return false
  }
  return true;
}

function isInCartPage(path = window.location.pathname) {
  const regex = /.*(\/cart).*/gs
  return regex.test(path)
}

function getTotalPrice(item) {
  return ((item.quantity || 0) * (item.salePrice || 0))
    + ((item.childQuantity || 0) * (item.childSalePrice || 0))
    + ((item.seniorQuantity || 0) * (item.seniorSalePrice || 0))
    + ((item.infantQuantity || 0) * (item.infantSalePrice || 0))
}

function getTotalQuantity(item = tour) {
  return (item.quantity || 0)
    + (item.adultQuantity || 0)
    + (item.childQuantity || 0)
    + (item.seniorQuantity || 0)
    + (item.infantQuantity || 0)
}

function getAllProductVariantIds(cartData, keySKU) {
  const skuFieldId = {
    vouchers: 'tourId',
    vinWonders: 'vinWonderId',
    tours: 'tourId'
  }
  let skuData = []
  keySKU.forEach(typeSKU => {
    cartData[typeSKU].forEach(item => {
      const resultId = skuData.filter(sku => sku.id === item[skuFieldId[typeSKU]])
      if(resultId.length === 0) {
        skuData.push({ id: item[skuFieldId[typeSKU]], cartItemType: item.cartItemType })
      }
    })
  })
  return skuData
}

function getTotalPriceObjectTypes(objectTypes) {
  return objectTypes.reduce((a, b) => a + (b.salePrice * b.quantity), 0)
}

function mappingTour(tours) {
  let keyCompare = ''
  const toursList = [...tours]
  const arrTour = []
  toursList.forEach(tour => {
    const key = `${tour.ticketId}-${tour.departureDate}`
    let objectTypes = []
    let price = 0
    if(keyCompare !== key) {
      objectTypes = toursList.filter(item => {
        return item.ticketId === tour.ticketId && item.departureDate === tour.departureDate
      })

      price = getTotalPriceObjectTypes(objectTypes)

      arrTour.push({...tour, objectTypes, price})
      keyCompare = key
    }
  })
  return arrTour
}

export default {
  getCartDiscountValueSKU,
  getCartTotalPrice,
  isSameProducts,
  isInCartPage,
  getTotalPrice,
  getTotalQuantity,
  getTotalPriceObjectTypes,
  mappingTour,
  getAllProductVariantIds,
  getPriceDiscountTicket
}