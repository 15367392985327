import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

//actions
import { getRedirectLink } from '../actions/commonAction'

/**
 *
 * @type {function(*=): *}
 */

export const getRedirectLinkCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    (link) => dispatch(getRedirectLink(link)),
    [dispatch]
  )
}
