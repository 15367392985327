import axios from 'axios'
import queryString from '@utils/querystring'
import { compactObj, getPathWithParams } from './../helpers/functions'
import config, { getConfig } from '../config'
import Methods from './Methods'
import { notification } from 'antd'
import { modalUtils, Cookie } from './../utils'
import { get } from 'lodash'
import {
  getAcceptLanguage, getDisplayCurrency,
  getCurrencyCookie, getLanguageCookie
} from '../utils/lang'
import { globalStore } from '../hooks/useGlobal'
// import { axiosProviderAuth } from 'src/actions/api/axiosProvider'
// import Cookies from '../utils/cookie'
import RetryInterceptor, {axiosCancelToken} from './RetryInterceptor'
import AuthorRequestInterceptor, { isLoadingRefreshToken, refreshTokenSubscribers, requestFullfill } from './AuthorRequestInterceptor'
// import { getAuthCookies, setCookiesLogin } from '@utils/auth'
// import moment from 'moment'
// import Urls from './Urls'

const {
  publicRuntimeConfig: {
    API_BASE_AUTH_URL,
  } // Available both client and server side
} = getConfig();
export default class ApiService {

  /**
     * Create instance service for every API service.
     * @param {Object} options - The options to create.
     * @param {string} options.baseURL - setting the host of the request, required.
     * @param {Object} [options.params] - url params of the request instance (ex: {pageIndex: 1, pageSize: 10}).
     * @param {string} options.endpoint - endpoint  to call the request to (ex: 'api/admin/login').
     * @param {Object} [options.headers] - headers of the request instance (ex: {'Content-Type' : 'application/json'}).
     * @param {Object} [options.endpointParams] - headers of the request instance (ex: {id: 'some-identity-id-string'}).
     * @param {function} [options.parser] - function to parse response body to whatever you what (ex: function(body) { return JSON.parse(body) } ).
     * @param {function} [options.dummy] - function to return a dumy data when api service is not ready yet (ex: function() { return 'some-fake-body-data' } ).
     */
  constructor(options) {
    this.instance = axios.create({
      baseURL: config.API_URL,
      timeout: 30000,
      cancelToken: axiosCancelToken.token,
    })
    if (options.baseURL && options.baseURL !== config.API_URL) this.instance.defaults.baseURL = options.baseURL
    this.endpointParams = options.endpointParams || {}
    this.endpoint = getPathWithParams(options.endpoint || undefined, this.endpointParams)
    this.params = options.params || {}
    this.headers = this.getRequestHeaders(options.headers || {});
    this.parser = options.parser || undefined
    this.responseType = options.isDownload && 'blob'
    this.receiveErrorModel = options.receiveErrorModel || false
    this.forceNotifyError = options.forceNotifyError || false
    this.dummy = options.dummy || undefined;
    // add interceptor
    this.addDefaultInterceptors(this.instance);
  }

  addDefaultInterceptors = () => {
    const authenticationInterceptor = new AuthorRequestInterceptor(this.instance);
    // this.instance.interceptors.request.use(authenticationInterceptor.requestFullfill);
    this.instance.interceptors.request.use(
      (config) => requestFullfill(config, this.instance),
      error => Promise.reject(error)
    );

    // const retryInterceptor = new RetryInterceptor(this.instance);
    // this.instance.interceptors.response.use(
    //     retryInterceptor.responseFulfilled,
    //     retryInterceptor.responseReject
    // );
  }

  getRequestData(data) {
    if (data && typeof data.export === 'function') {
      return data.export()
    }
    return data
  }

  getRequestHeaders(headers = {}) {
    const result = {};
    Object.keys(headers).forEach(header => {
      let key = header.toLowerCase();
      result[key] = headers[header];
    });

    const lang =  getLanguageCookie(true) || globalStore.state.lang;
    const currency = getCurrencyCookie(true) || globalStore.state.currency;
    result['accept-language'] = result['accept-language'] || getAcceptLanguage();
    result['x-display-currency'] = result['x-display-currency'] || getDisplayCurrency(currency);
    return result;
  }

  request(method, reqData) {
    const data = this.getRequestData(reqData)
    return new Promise((resolve, reject) => {
      if (this.dummy) {
        const result = this.dummy();
        resolve(result);
        return result;
      } else {
        const config = {
          params: this.params,
          paramsSerializer: function (params) {
            return queryString.stringify(params)
          },
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...this.headers
          },
          data: method === Methods.POST || method === Methods.PUT ? undefined : data,
          responseType: this.responseType,
        }
        // console.log('=====> accept-language', Cookie.get('lang'), config.headers["accept-language"], globalStore.state.lang)

        const secondParam = method === Methods.POST || method === Methods.PUT ? data : config
        const thirdParam = method === Methods.POST || method === Methods.PUT ? config : undefined
        this.instance[method](this.endpoint, secondParam, thirdParam).then(response => {
          if (response && response.data && response.data.errors && response.data.errors.length) {
            const message = typeof response.data.errors[0] === 'string' ? response.data.errors[0] : response.data.errors.title || response.message
            const errorResp = { ...response, message, errors: response.data.errors, success: true }
            resolve(errorResp)
            return errorResp
          }
          // format response {data: {}} or {}
          const data = get(response, 'data.data') !== undefined && get(response, 'data.data') !== null ? response.data.data : (response.data || response)
          resolve(this.parser ? this.parser(data) : data)
          return data
        }).catch(error => {

          if (!error.response || error.message === 'user cancel') return;
          let errObj = modalUtils.getApiMessageError(error.response, this.receiveErrorModel)
          try {
            if (errObj && errObj.message && (!this.receiveErrorModel || this.forceNotifyError)) {
              notification.error({ message: errObj.title, description: errObj.message })
            }
          } catch { }
          resolve(this.receiveErrorModel ? errObj : null)
          return this.receiveErrorModel ? errObj : null
        })
      }

    })
  }

  get = (body) => this.request(Methods.GET, body)
  post = (data) => this.request(Methods.POST, data)
  put = (data) => this.request(Methods.PUT, data)
  delete = () => this.request(Methods.DELETE)
}
