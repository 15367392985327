// lib
import { createSelector } from 'reselect'
import { FormattedNumber } from 'react-intl'
// constant, utils and extensions
import { computeOffset } from '../utils/paging'
import { enumType } from '../constants'
import { datetimeExtensions, numberExtensions } from '../extensions'
import { FormattedMessage } from 'react-intl.macro'
import React from 'react'
import { get, toArray, orderBy, uniqBy, merge, groupBy } from 'lodash'
import moment from 'moment'

const getFlights = state => state.flight.flights
const getExtendFilter = state => state.filter
const getCurrentFlight = state => state.flight.currentFlight
const getBaggage = state => state.flight.baggage
const getOrder = state => state.flight.order
const getDepartureFlight = state => state.flight.departureFlight
const getReturnFlight = state => state.flight.returnFlight
const getFlightWeekPrices = state => state.flight.weekPrices
const getMonthFlights = state => state.flight.monthFlights
const getFareRule = state => state.flight.fareRule
const getVerifyDetailFlight = state => state.flight.verifyDetail
const reloadPhs = state => state.flight.reloadPhs

/**
 * filter flights
 * @param flights
 * @param filter
 * @returns {Array|*}
 */
export const filterFlights = (flights, filter) => {
  if (!flights || flights.length === 0) {
    return []
  }

  const {
    stopover,
    takeOffTime,
    landingTime,
    airlines,
    groupClasses,
  } = filter
  // 1. filter by stopover
  if (stopover && !stopover.includes(enumType.stopover.All)) {
    flights = flights.filter(flight => {
      let check = false;
      for (let stop of stopover) {
        const stopNumber = null;
        switch (stop) {
          case enumType.stopover.Direct:
            stopNumber = 0;
            break
          case enumType.stopover.TransitOneStop:
            stopNumber = 1;
            break
          case enumType.stopover.TransitMultiStop:
            stopNumber = 2;
            break
          default:
            break
        }
        if (stopNumber <= flight.stops || stopNumber === null) {
          check = true;
          break;
        }
      }
      return check;
    })
  }

  // 2. filter by take off time
  if (takeOffTime && !takeOffTime.includes(enumType.takeOff.All)) {
    flights = flights.filter(flight => {
      let check = false;
      for (let time of takeOffTime) {
        const { startTime, endTime } = datetimeExtensions.getTimeDuration(time);
        // console.log('===takeOffTime:', startTime, endTime, moment(flight.startDate || undefined).format('HH:mm'))
        const found = datetimeExtensions.checkTimeInDuration(flight.startDate, startTime, endTime, null, '[]');
        if (found) {
          check = true;
          break;
        }
      }
      return check;
    })
  }

  // 3. filter by landing time
  if (landingTime && !landingTime.includes(enumType.takeOff.All)) {
    flights = flights.filter(flight => {
      let check = false;
      for (let time of landingTime) {
        const { startTime, endTime } = datetimeExtensions.getTimeDuration(time);
        const found = datetimeExtensions.checkTimeInDuration(flight.endDate, startTime, endTime);
        if (found) {
          check = true;
          break;
        }
      }
      return check;
    })
  }
  // if (landingTime) {
  //   const { startTime, endTime } = datetimeExtensions.getTimeDuration(landingTime)
  //   flights = flights.filter(
  //     flight => datetimeExtensions.checkTimeInDuration(flight.endDate,
  //       startTime, endTime))
  // }

  // 4. filter by airlines
  if (airlines && !airlines.includes(enumType.airline.All)) {
    flights = flights.filter(flight => airlines.includes(flight.airlineCode))
  }

  //5. filter by groupClasses
  if (groupClasses && !groupClasses.includes(enumType.seatSelection.All)) {
    flights = (flights || []).reduce((result, flight) => {
      const fareOptionsGroup = groupBy(flight.fareOptions || [], (fare => {
        let seatClass = fare.groupClass;
        if (!enumType.seatGroupClass.BUSINESS.includes(seatClass)) {
          seatClass = enumType.seatGroupClass.ECONOMY[0];
        }
        const classCheck = groupClasses.reduce((classes, enumClass) =>
          classes.concat(enumType.seatGroupClass[enumClass] || []), [])
        return classCheck.includes(seatClass);
      }))
      if (!!(fareOptionsGroup.true || []).length) {
        flight.fareOptions = orderBy(fareOptionsGroup.true || [], ['totalPrice'], ['asc'])
          .concat(orderBy(fareOptionsGroup.false || [], ['totalPrice'], ['asc']))
        result.push(flight)
      }
      return result;
    }, [])
  } else if (groupClasses.includes(enumType.seatSelection.All)) {
    flights = flights.map(flight => {
      flight.fareOptions = orderBy(flight.fareOptions || [], ['totalPrice'], ['asc'])
      return flight
    })
  }

  return flights
}

/**
 * filter flights
 * @param flights
 * @param sort
 * @returns {Array|*}
 */
export const sortFlights = (flights, sort, keySortPrice = 'totalPrice') => {
  if (!flights || flights.length === 0) {
    return []
  }

  const getKeyFromSortType = (type) => {
    if (type === enumType.flightSort.TakeOff) {
      return flight => moment(flight.startDate).toDate().getTime();
    }
    if (type === enumType.flightSort.Landing) {
      return flight => moment(flight.endDate).toDate().getTime();
    }
    if (type === enumType.flightSort.Duration) {
      return flight => flight.duration;
    }
    if (type === enumType.flightSort.Price) {
      return flight => get(flight, `fareOptions[0].${keySortPrice}`);
    }
  }

  const sortKeys = [];
  const sortDirection = [];
  toArray(enumType.flightSort).forEach(sortType => {
    const currentSortDir = sort[sortType];
    if (currentSortDir && currentSortDir !== enumType.sortDirection.NONE) {
      sortKeys.push(getKeyFromSortType(sortType));
      sortDirection.push(currentSortDir.toLowerCase())
    }
  })

  if (!sortKeys.length && !sortDirection.length) {
    return flights
  }
  return orderBy(flights, sortKeys, sortDirection)
}

const getFlightInfo = (data, isDepartureFlight) => {
  return {
    startPoint: isDepartureFlight ? data.startPoint : data.endPoint,
    startPointName: isDepartureFlight ? data.startPointName : data.endPointName,
    startPointCityName: isDepartureFlight
      ? data.startPointCityName
      : data.endPointCityName,
    endPoint: isDepartureFlight ? data.endPoint : data.startPoint,
    endPointName: isDepartureFlight ? data.endPointName : data.startPointName,
    endPointCityName: isDepartureFlight
      ? data.endPointCityName
      : data.startPointCityName,
    departureDate: isDepartureFlight ? data.departureDate : data.returnDate,
    returnDate: data.returnDate,
    dataSession: data.dataSession
  }
}

const checkIsValidDate = (flight, startDate, endDate, isDepartureFlight) => {
  if (isDepartureFlight) {
    if (startDate && flight.endDate) {
      return datetimeExtensions.checkIsPreviousMoment(flight.endDate, startDate)
    }
    return true
  }
  if (endDate && flight.startDate) {
    return datetimeExtensions.checkIsFutureMoment(flight.startDate, endDate)
  }
  return true
}

/**
 * get list flight
 * @param isDepartureFlight
 * @param data
 * @param flights
 * @param filter
 * @param activeFlight
 * @param pageIndex
 * @param pageSize
 * @returns {{endPointCityName: *, startPointName: *, startPoint: *, pageSize: *, startPointCityName: *, dataSession: *, endPoint: *, returnDate: *, total: *, pageIndex: *, endPointName: *, departureDate: *, flights: (*)}}
 */
const mixFlightList = (
  isDepartureFlight,
  data,
  flights,
  filter,
  activeFlight,
  pageIndex,
  pageSize
) => {

  if (Object.keys(data).length === 0) {
    return null
  }

  const startDate = activeFlight ? activeFlight.startDate : null
  const endDate = activeFlight ? activeFlight.endDate : null

  // 1. filter by stopover and airline
  let filteredFlights = filterFlights(flights, filter)
  let currentFlights = sortFlights(filteredFlights, filter.sort)
  let list = []
  currentFlights.forEach(flight => {
    if (checkIsValidDate(flight, startDate, endDate, isDepartureFlight) &&
      flight.fareOptions &&
      flight.fareOptions.length > 0
    ) {
      // flight.fareOptions.forEach(fare => {
      //   const flightByFare = {
      //     segments: flight.listSegment,
      //     isTransit: flight.listSegment.length > 1,
      //     fare,
      //     faresOptions: flight.fareOptions,
      //     stops: flight.stops,
      //     startDate: flight.startDate,
      //     endDate: flight.endDate,
      //     flightSession: flight.flightSession,
      //     dataSession: flight.dataSession,
      //   }
      //   list.push(flightByFare)
      // })
      list.push(merge(flight, {
        segments: flight.listSegment,
        isTransit: flight.listSegment.length > 1,
        fare: flight.fareOptions[0],
        faresOptions: flight.fareOptions,
        stops: flight.stops,
        startDate: flight.startDate,
        endDate: flight.endDate,
        flightSession: flight.flightSession,
        dataSession: flight.dataSession,
      }))
    }
  })

  // calculate offset
  const offset = computeOffset(pageIndex, pageSize)

  return {
    flights:
      pageSize && pageSize ? list.slice(offset, offset + pageSize) : list, // has pageIndex & pageSize
    total: list.length,
    pageIndex,
    pageSize,
    ...getFlightInfo(data, isDepartureFlight)
  }
}

/**
 * get current flight info
 * @param currentFlight
 * @returns {null}
 */
const getCurrentFlightInfo = (currentFlight) => {
  if (!currentFlight) {
    return null
  }
  const {
    departureFlights,
    returnFlights,
  } = currentFlight

  const departureFlightSession = departureFlights &&
    departureFlights.fareOptions && departureFlights.fareOptions.length > 0
    ? departureFlights.fareOptions[0].fareOptionSession
    : ''
  const returnFlightSession = returnFlights && returnFlights.fareOptions &&
    returnFlights.fareOptions.length > 0
    ? returnFlights.fareOptions[0].fareOptionSession
    : ''

  return {
    ...currentFlight,
    departureFlightSession,
    returnFlightSession
  }
}

/**
 * select departure flight list
 * @param pageIndex
 * @param pageSize
 * @returns {OutputSelector<unknown, {endPointCityName: *, startPointName: *, startPoint: *, pageSize: *, startPointCityName: *, dataSession: *, endPoint: *, returnDate: *, total: *, pageIndex: *, endPointName: *, departureDate: *, flights: *}, (res1: (*[] | [] | initState.flights | {}), res2: *) => {endPointCityName: *, startPointName: *, startPoint: *, pageSize: *, startPointCityName: *, dataSession: *, endPoint: *, returnDate: *, total: *, pageIndex: *, endPointName: *, departureDate: *, flights: *}>}
 */
export const selectDepartureFlight = (
  pageIndex,
  pageSize
) =>
  createSelector(
    [getFlights, getExtendFilter, getReturnFlight],
    (flights, extendFilters, returnFlight) => {
      return mixFlightList(
        true,
        flights,
        flights.departureFlights,
        extendFilters,
        returnFlight
        // pageIndex,
        // pageSize
      )
    }
  )

/**
 * select return flight lists
 * @param pageIndex
 * @param pageSize
 * @returns {OutputSelector<unknown, {endPointCityName: *, startPointName: *, startPoint: *, pageSize: *, startPointCityName: *, dataSession: *, endPoint: *, returnDate: *, total: *, pageIndex: *, endPointName: *, departureDate: *, flights: *}, (res1: (*[] | [] | initState.flights | {}), res2: *) => {endPointCityName: *, startPointName: *, startPoint: *, pageSize: *, startPointCityName: *, dataSession: *, endPoint: *, returnDate: *, total: *, pageIndex: *, endPointName: *, departureDate: *, flights: *}>}
 */
export const selectReturnFlight = (
  pageIndex,
  pageSize
) =>
  createSelector(
    [getFlights, getExtendFilter, getDepartureFlight],
    (flights, extendFilters, departureFlight) => {
      return mixFlightList(
        false,
        flights,
        flights.returnFlights,
        extendFilters,
        departureFlight
        // pageIndex,
        // pageSize
      )
    }
  )

/**
 * select current flight detail
 * @returns {OutputSelector<unknown, *, (res: null) => *>}
 */
export const selectCurrentFlight = () =>
  createSelector(
    [getCurrentFlight],
    (flight) => getCurrentFlightInfo(flight)
  )

export const selectVerifyFlight = () =>
  createSelector(
    [getVerifyDetailFlight],
    (verifyDetail) => verifyDetail
  )

/**
 * select departure baggage list
 * @returns {OutputSelector<unknown, Array, (res: (initState.baggage|{})) => Array>}
 */
export const selectDepartureBaggage = () =>
  createSelector(
    getBaggage,
    (baggage) => baggage.departureBaggages
      ? baggage.departureBaggages.map(baggage => ({
        ...baggage,
        id: baggage.code,
        name: <FormattedMessage
          id="Baggages"
          defaultMessage={`{name} - {price} {currency}`}
          values={{
            price: <FormattedNumber value={baggage.price} />,
            name: baggage.name,
            currency: baggage.currency
          }}
        />,
        price: baggage.price
      }))
      : []
  )

/**
 * select return baggage lists
 * @returns {OutputSelector<unknown, Array, (res: (initState.baggage|{})) => Array>}
 */
export const selectReturnBaggage = () =>
  createSelector(
    getBaggage,
    (baggage) => baggage.returnBaggages
      ? baggage.returnBaggages.map(baggage => ({
        ...baggage,
        id: baggage.code,
        name: <FormattedMessage
          id="Baggages"
          defaultMessage={`{name} - {price} {currency}`}
          values={{
            price: <FormattedNumber value={baggage.price} />,
            name: baggage.name,
            currency: baggage.currency
          }}
        />,
        price: baggage.price
      }))
      : []
  )

/**
 * select order detail
 * @returns {OutputSelector<unknown, *, (res: *) => *>}
 */
export const selectOrderDetail = () =>
  createSelector(
    getOrder,
    (order) => order
  )

/**
 * select current departure flight
 * @type {OutputSelector<unknown, null, (res: null) => null>}
 */
export const selectCurrentDepartureFlight = () => (
  createSelector(
    getDepartureFlight,
    flight => flight
  )
)

/**
 * select current return flight
 * @type {OutputSelector<unknown, null, (res: null) => null>}
 */
export const selectCurrentReturnFlight = () => (
  createSelector(
    getReturnFlight,
    flight => flight
  )
)
/**
 * select current return flight
 * @type {OutputSelector<unknown, null, (res: null) => null>}
 */
export const selectFlights = () => (
  createSelector(
    getFlights,
    flights => flights
  )
)

/**
 * select current return flight
 * @type {OutputSelector<unknown, null, (res: null) => null>}
 */
export const selectFlightWeekPrices = () => (
  createSelector(
    getFlightWeekPrices,
    weekPrices => weekPrices
  )
)

/**
 * select current return flight
 * @type {OutputSelector<unknown, null, (res: null) => null>}
 */
export const selectMonthFlights = () => (
  createSelector(
    getMonthFlights,
    monthFlights => monthFlights
  )
)

/**
 * select current fare rule
 * @type {OutputSelector<unknown, null, (res: null) => null>}
 */
export const selectFlightFareRule = () => (
  createSelector(
    getFareRule,
    fareRule => fareRule
  )
)

export const selectReloadPhs = () => (
  createSelector(
    reloadPhs,
    reloadPhs => reloadPhs
  )
)

