import { get } from 'lodash';
import { globalStore } from '../hooks/useGlobal';
import Cookie from './cookie';
import moment from 'moment';
import { getConfig } from '@config'
import UserProfle from 'src/model/UserProfle';
import { LOCAL_STORAGE_KEY } from 'src/constants';


const {
  publicRuntimeConfig: {
    COOKIE_DOMAIN
  }
} = getConfig()

console.log("COOKIE_DOMAIN ===>", COOKIE_DOMAIN)

export const getAuthCookies = (initAuth = globalStore.state.auth) => {
  const auth = initAuth || Cookie.get(Cookie.STORAGE_KEY.AUTH) || null;
  const user = Cookie.get(Cookie.STORAGE_KEY.PROFILE) || null;
  const auth0 = Cookie.get(Cookie.STORAGE_KEY.AUTH0_AUTH, { domain: COOKIE_DOMAIN }) || null;
  return {
    auth,
    user,
    auth0,
  }
}

export const setCookiesLogin = (user, isRemember) => {
  // let newDate = moment(new Date()).add(15, 'm').toDate();
  // document.cookie = `${Cookie.getKeyWithPrefix(Cookie.STORAGE_KEY.AUTH)}=${JSON.stringify(user)};expires=${newDate}`;
  globalStore.state.auth = user;
  if (isRemember) {
    Cookie.set(Cookie.STORAGE_KEY.AUTH, user, {
      expires: 0.010416,
      secure: true,
    });
  } else {
    Cookie.set(Cookie.STORAGE_KEY.AUTH, user, { secure: true });
  }
  // login vinpearl
  if (user && user.accessToken) {
    Cookie.set(Cookie.STORAGE_KEY.AUTH0_AUTH, user.accessToken, { domain: COOKIE_DOMAIN, secure: true, }, true);
    Cookie.set(Cookie.STORAGE_KEY.AUTH0_AUTH_REFRESH, user.refreshToken, { domain: COOKIE_DOMAIN, secure: true, }, true);
  }
}

export const setCookieProfile = (profile, isRemember) => {
  // let newDate = moment(new Date()).add(15, 'm').toDate();
  // document.cookie = `${Cookie.getKeyWithPrefix(Cookie.STORAGE_KEY.AUTH)}=${JSON.stringify(user)};expires=${newDate}`;
  const cookieProfile = new UserProfle(profile);

  // Remove unuse keys
  delete cookieProfile.memberShipCard?.memberShipId;
  delete cookieProfile.memberShipCard?.email;
  delete cookieProfile.memberShipCard?.customerId;
  delete cookieProfile.memberShipCard?.memberShipCode;
  delete cookieProfile.memberShipCard?.memberShip?.description;
  delete cookieProfile.memberShipCard?.memberShip?.imageUrl;
  delete cookieProfile.memberShipCard?.memberShip?.pnlCode;
  delete cookieProfile.avatarImageView?.filename;

  if(isRemember) {
    Cookie.set(Cookie.STORAGE_KEY.PROFILE, cookieProfile, { expires: 0.010416, secure: true })
  } else {
    Cookie.set(Cookie.STORAGE_KEY.PROFILE, cookieProfile, { secure: true })
  }
}

export const removeCookiesLogout = () => {
  Cookie.remove(Cookie.STORAGE_KEY.AUTH)
  Cookie.remove(Cookie.STORAGE_KEY.AUTH0_AUTH, { domain: COOKIE_DOMAIN}, true)
  Cookie.remove(Cookie.STORAGE_KEY.AUTH0_AUTH_REFRESH, { domain: COOKIE_DOMAIN}, true)
  Cookie.remove(Cookie.STORAGE_KEY.PROFILE)
}

export const getAuthHeader = (initAuth = getAuthCookies()) => {
  if (initAuth) {
    return `Bearer ${get(initAuth, 'auth0')}`
  }
}

export const getClientId = () => {
  try {
    const clienId = document.cookie.match(/_ga=(.+?);/)[1].split('.').slice(-2).join(".");
    return clienId;
  } catch  {
    return "";
  }
}

export const getClientIdLinkCrossSale = () => {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      try {
        const clientId = document.cookie.match(/_ga=(.+?);/)[1].split('.').slice(-2).join(".");
        if(clientId){
          clearInterval(interval);
          resolve(clientId);
        }
      } catch (error) {
        clearInterval(interval);
        reject(error);
      }
    }, 500);
  });
}
