import actionTypes from '../actionTypes'

const initState = {
  categories: null,
  list: [],
  detail: null,
  locations: [],
  special: [],
  exchangeRate: [],
  provinces: [],
  types: [],
  listAutocomplete: [],
  bestSeller: [],
  recommendation: [],
  loadingSpecial: false,
}

const listingReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_CATEGORY_LISTING_SUCCESS:
      return Object.assign({}, state, {
        categories: action.payload.data
      })
    case actionTypes.FETCHING_LISTING_SUCCESS:
      return Object.assign({}, state, {
        list: action.payload.data
      })
    case actionTypes.START_LISTING_AUTOCOMPLETE:
      return Object.assign({}, state, {
        listAutocomplete: []
      })
    case actionTypes.FETCHING_LISTING_AUTOCOMPLETE_SUCCESS:
      return Object.assign({}, state, {
        listAutocomplete: action.payload.data.result 
      })
    case actionTypes.RESET_LISTING_AUTOCOMPLETE:
      return Object.assign({}, state, {
        listAutocomplete: []
      })
    case actionTypes.FETCHING_LISTING_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        detail: action.payload.data
      })
    case actionTypes.FETCHING_LISTING_LOCATION_SUCCESS:
      return Object.assign({}, state, {
        locations: action.payload.data
      })
    case actionTypes.FETCHING_LISTING_SPECIAL_SUCCESS:
      return Object.assign({}, state, {
        special: action.payload.data
      })
    case actionTypes.FETCHING_LISTING_RECOMMENDATION_SUCCESS:
      return Object.assign({}, state, {
        recommendation: action.payload.data
      })
    case actionTypes.FETCHING_LISTING_BEST_SELLER_SUCCESS:
      return Object.assign({}, state, {
        bestSeller: action.payload.data
      })
    case actionTypes.FETCHING_EXCHANGERATE_SUCCESS:
      return Object.assign({}, state, {
        exchangeRate: action.payload.data
      })
    case actionTypes.FETCHING_LISTING_STATE_PROVINCE_SUCCESS:
      return Object.assign({}, state, {
        provinces: action.payload.data || [],
      })
    case actionTypes.FETCHING_LISTING_TYPE_SUCCESS:
        return Object.assign({}, state, {
          types: action.payload.data || [],
    })
    case actionTypes.START_LISTING_SPECIAL:
        return Object.assign({}, state, {
          loadingSpecial: true,
          special: []
    })
    case actionTypes.STOP_LISTING_SPECIAL:
        return Object.assign({}, state, {
          loadingSpecial: false,
    })
    default:
      return state
  }
}

export default listingReducer
