import {axiosProviderCms} from './api/axiosProvider'
//actions
import actionTypes from '../actionTypes'
//utils
//common
import {
  loadSuccess,
  loadFail
} from './commonAction'

// const API_KEY = "a9c221079ae02d3b3dc2190a"; //Genarate from https://app.exchangerate-api.com/

export const getCurrencyRate = (value) => {
    return async (dispatch) => {
      try {
        const response = await axiosProviderCms.get('/frontend/currency')
        if (response.status === 200 && !response.errors) {
            if(response.data.result === 'success'){
                dispatch(
                    loadSuccess(
                      actionTypes.CURRENCY_RATE_GET_FROM_WEB,
                      response.data.conversionRates,
                      null,
                      null,
                      null
                    )
                )
            }
        } else {
          // dispatch(loadFail(response.errors[0].message))
        }
      } catch (errors) {
        // fail
        // dispatch(loadFail(errors.response))
      }
    }
  }
  
  export default {
    getCurrencyRate
  }