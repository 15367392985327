import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import bannerAction from 'src/actions/bannerAction'

//actions
import footerAction from '../actions/footerAction'

export const getFooterCallback = () => {
    const dispatch = useDispatch()
    return useCallback(
        (lang) => {
        dispatch(footerAction.getFooter(lang))
        },
        [dispatch]
    )
}

export const getDownloadAppCallback = () => {
    const dispatch = useDispatch()
    return useCallback(
        (lang) => {
            dispatch(bannerAction.getDownloadAppBanner(lang))
        },
        [dispatch]
    )
}
