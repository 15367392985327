import { createSelector } from 'reselect'

const searchFlight = state => state.search.flight
const searchHotel = state => state.search.hotel
const searchHomestay = state => state.search.homestay
const searchBundle = state => state.search.bundle

export const selectSearchFlightParams = () =>
  createSelector(
    searchFlight,
    search => search
      ? {
        ...search,
        passenger: {
          adult: search.adult,
          child: search.child,
          infant: search.infant
        }
      }
      : null
  )

export const selectSearchHotelParams = () =>
  createSelector(
    searchHotel,
    search => search
      ? {
        ...search,
        passenger: {
          adult: search.adult,
          child: search.child,
          infant: search.infant
        },
        startPoint: {
          locationName: search.startPoint,
          locationId: search.locationId,
          hotelId: search.hotelId
        }
      }
      : {}
  )

export const selectSearchHomestayParams = () =>
  createSelector(
    searchHomestay,
    search => search
      ? {
        ...search,
        passenger: {
          adult: search.adult,
          child: search.child,
          infant: search.infant
        },
        startPoint: {
          locationName: search.startPoint,
          locationId: search.locationId,
          hotelId: search.hotelId
        }
      }
      : {}
  )

export const selectSearchFlightParamsFromHotelParams = () =>
  createSelector(
    searchHotel,
    search => search
      ? {
        departureDate: search.departureDate,
        lengthOfStay: search.lengthOfStay,
        passenger: {
          adult: search.adult,
          child: search.child,
          infant: search.infant
        }
      }
      : {}
  )

export const selectSearchBundleParams = () =>
  createSelector(
    searchBundle,
    search => search
  )