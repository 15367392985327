/**
 * Tour & Experience
 * ---------------------------------------------
 * */

import _get from "lodash/get";
import moment from "moment";
import { DEFAULT_FORMAT_DATE, enumType, FORMAT_ONLY_DATE } from "src/constants";
import { gtmEvent, TBD } from "src/helpers/gtmEvent";
import { getMomentDate } from "../common";
import { ENUM_SKU } from "./enumGtm";

export const createProductImpressions = (
  dataProductImpressions = [],
  listName
) => {
  const productImpressionArray = dataProductImpressions.map((elm) => {
    return {
      /**
       * 04/01/2022
       * https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.g10a292a84e5_0_26
       */
      name: `TnE: ${elm.name}`,
      id: elm.id,
      price: elm.salePrice,
      // BE missing field brand
      brand: null,
      category: ENUM_SKU.category,
      /**
       * Sync list name with product_click
       * 07/01/2022
       * https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.g10a292a84e5_0_32
       */
      list: listName || ENUM_SKU.list,
      position: elm.position,
      // BE missing field product_location
      dimension29: TBD,
      dimension30: TBD,
      dimension31: elm.originalPrice,
      dimension32: !!elm.salePrice
        ? `${(1 - elm.salePrice / elm.originalPrice) * 100}`
        : 0,
    };
  });
  const ecommerceObject = {
    ecommerce: { impressions: productImpressionArray },
    event: gtmEvent.PRODUCT_IMPRESSIONS,
    // Add these custom dimensions
    booking_service_type: ENUM_SKU.category,
    // booking_destination: "booking_destination",
  };
  return ecommerceObject;
};

export const createProductClick = (
  productImpressions = {},
  index = 0,
  listName
) => {
  const productClick = {
    /**
     * 04/01/2022
     * https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.g10a292a84e5_0_26
     */
    name: `TnE: ${productImpressions.name}`, // Name or ID is required.
    id: productImpressions.id,
    price: productImpressions.salePrice,
    brand: TBD,
    category: ENUM_SKU.category,
    /**
     * SLIDE 100
     * 31/12/2021
     * https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.g10a292a84e5_0_32
     */
    list: listName || ENUM_SKU.list,
    position: index + 1,
    // product_location,
    dimension29: TBD,
    dimension30: TBD,
    dimension31: productImpressions?.originalPrice,
    dimension32: !!productImpressions.salePrice
      ? `${
          (1 -
            productImpressions.salePrice / productImpressions.originalPrice) *
          100
        }`
      : 0,
  };

  const ecommerceObject = {
    ecommerce: {
      click: {
        products: [productClick],
        actionField: { list: listName || ENUM_SKU.list },
      },
    },
    event: gtmEvent.PRODUCT_CLICK,
    booking_service_type: ENUM_SKU.category,
    // booking_destination: "booking_destination",
  };

  return ecommerceObject;
};

export const createGtmTourPageView = ({ productDetail = {} }) => {
  const productDetailArray = [
    {
      /**
       * 04/01/2022
       * https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.g10a292a84e5_0_26
       */
      name: `TnE: ${productDetail?.tourDetail?.tourName}`, // Name or ID is required.
      // Already had
      id: productDetail?.id,
      // Add these dimensions
      price: productDetail?.adultSalePrice
        ? productDetail?.adultSalePrice
        : productDetail?.adultOriginalPrice,
      brand: productDetail?.tourDetail?.tourName,
      category: ENUM_SKU.category,
      // Add these custom dimensions
      // 'dimension29': 'product_location',
      dimension29: productDetail?.destination?.name || TBD,
      // 'dimension30': 'product_subcategory',
      dimension30: TBD,
      // 'dimension31': 'product_original_price',
      dimension31: productDetail?.adultOriginalPrice,
      // 'dimension32': 'product_discount'
      dimension32: !!productDetail?.adultSalePrice
        ? `${
            (1 -
              productDetail?.adultSalePrice /
                productDetail.adultOriginalPrice) *
            100
          }`
        : 0,
    },
  ];

  const ecommerceObject = {
    event: gtmEvent.PRODUCT_DETAIL,
    // Add these custom dimensions
    booking_service_type: ENUM_SKU.category,
    booking_destination: productDetail?.destination?.name || null,
    ecommerce: {
      detail: {
        products: productDetailArray,
      },
    },
  };

  return ecommerceObject;
};

export const createGtmExperiencePageView = ({ productDetail = {} }) => {
  const productDetailArray = [
    {
      /**
       * 04/01/2022
       * https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.g10a292a84e5_0_26
       */
      name: `TnE: ${productDetail?.name}`, // Name or ID is required.
      // Already had
      id: productDetail?.id,
      // Add these dimensions
      price: productDetail?.salePrice
        ? productDetail?.salePrice
        : productDetail?.originalPrice,
      brand: productDetail?.name,
      category: ENUM_SKU.category,
      // Add these custom dimensions
      // 'dimension29': 'product_location',
      dimension29: productDetail?.name || TBD,
      // 'dimension30': 'product_subcategory',
      dimension30: TBD,
      // 'dimension31': 'product_original_price',
      dimension31: productDetail?.originalPrice,
      // 'dimension32': 'product_discount'
      dimension32:
        !!productDetail?.salePrice && productDetail.originalPrice > 0
          ? `${
              (1 - productDetail?.salePrice / productDetail.originalPrice) * 100
            }`
          : 0,
    },
  ];

  const ecommerceObject = {
    event: gtmEvent.PRODUCT_DETAIL,
    // Add these custom dimensions
    booking_service_type: ENUM_SKU.category,
    booking_destination: productDetail?.destination?.name || null,
    ecommerce: {
      detail: {
        products: productDetailArray,
      },
    },
  };

  return ecommerceObject;
};

export const createCartProductCheckout = ({ dataCart }) => {
  const productCheckoutArray = (dataCart || []).map((elm) => {
    return {
      /**
       * 04/01/2022
       * https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.g10a292a84e5_0_26
       */
      name: `TnE: ${elm.itemName}`,
      id: elm?.tourLineItemId || elm?.productVariantId || null,
      // id: elm?.tourId || elm?.vinWonderId  || null,
      price: elm.salePrice,
      brand: elm.itemName,
      category: ENUM_SKU.category,
      variant: null,
      quantity:
        elm.quantity + (elm?.childQuantity || 0) + (elm?.seniorQuantity || 0),
      dimension29: elm?.destination?.name || null,
      dimension30: TBD,
      dimension31: elm.originalPrice,
      dimension32: !!elm.salePrice
        ? `${(1 - elm.salePrice / elm.originalPrice) * 100}`
        : 0,
      dimension52:
        (elm.departureDate || elm.usingDate) &&
        moment(elm.departureDate || elm.usingDate).isValid()
          ? moment(elm.departureDate || elm.usingDate).format(FORMAT_ONLY_DATE)
          : null,
      dimension53:
        (elm.departureDate || elm.usingDate) &&
        moment(elm.departureDate || elm.usingDate).isValid()
          ? moment(elm.departureDate || elm.usingDate)
              .add(Math.floor(elm.lengthOfTour || 1) - 1, "days")
              .format(FORMAT_ONLY_DATE)
          : null,
    };
  });
  var ecommerceObject = {
    event: gtmEvent.PRODUCT_CHECKOUT,
    // Add these custom dimensions
    booking_service_type: ENUM_SKU.category,
    ecommerce: {
      checkout: {
        products: productCheckoutArray,
        actionField: { step: 1 },
      },
    },
  };
  return ecommerceObject;
};

export const actionCartSKUClick = ({
  arrItem = [],
  voucher = {},
  eventType = gtmEvent.PRODUCT_ADD_TO_CART,
  actionType = "add",
  quantity,
}) => {
  let productActionCartArray = [];
  if (arrItem && arrItem.length > 0) {
    productActionCartArray = arrItem.map((item) => {
      return {
        name: `TnE: ${item?.itemName || null}`,
        // id: item?.id || null,
        // id: item?.tourId || item?.vinWonderId || null,
        id: item?.tourLineItemId || item?.id || null,
        price: item.salePrice || item.originalPrice || item.originPrice || null,
        brand: TBD,
        category: ENUM_SKU.category,
        variant: item?.name || TBD,
        /**
         * SLIDE 111 - 113
         * 31/12/2021
         * https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.g10a292a84e5_5_10
         *
         * 05/01/2022
         * https://docs.google.com/presentation/d/1eLq25W0Olzu2-LlVcf-swuuMc711V07wxyNa2GSu6pQ/present?slide=id.g10b8e927482_0_17
         */
        quantity: item?.quantity,
        dimension29: item?.departure?.name || TBD,
        dimension30: TBD,
        dimension31: item.originalPrice || null,
        dimension32: (1 - item.salePrice / item.originalPrice) * 100,
        dimension52:
          (voucher?.stayStartDate || voucher?.departureDate) &&
          getMomentDate(
            voucher?.stayStartDate || voucher?.departureDate
          ).format(DEFAULT_FORMAT_DATE),
        dimension53: voucher.stayEndDate
          ? getMomentDate(voucher.stayEndDate).format(DEFAULT_FORMAT_DATE)
          : voucher?.departureDate
          ? moment(voucher?.departureDate)
              .add(voucher.lengthOfTour, "days")
              .format(DEFAULT_FORMAT_DATE)
          : null,
      };
    });
  }
  let ecommerce = {};
  if (actionType === "add") {
    ecommerce = {
      add: {
        products: productActionCartArray,
      },
    };
  }
  if (actionType === "remove") {
    ecommerce = {
      remove: {
        products: productActionCartArray,
      },
    };
  }
  let ecommerceObject = {
    event: eventType,
    booking_service_type: ENUM_SKU.category,
    booking_destination: _get(voucher, "destination.name"),
    booking_start_date:
      (voucher?.stayStartDate || voucher?.departureDate) &&
      getMomentDate(voucher?.stayStartDate || voucher?.departureDate).format(
        DEFAULT_FORMAT_DATE
      ),
    booking_end_date: voucher.stayEndDate
      ? getMomentDate(voucher.stayEndDate).format(DEFAULT_FORMAT_DATE)
      : voucher?.departureDate
      ? moment(voucher?.departureDate)
          .add(voucher.lengthOfTour, "days")
          .format(DEFAULT_FORMAT_DATE)
      : null,
    ecommerce: ecommerce,
  };
  return ecommerceObject;
};

export const removeFromCartSKUClick = ({}) => {
  let productRemoveFromCartArray = [
    {
      name: "name",
      id: "id",
      price: "price",
      brand: "brand",
      category: "category",
      variant: "variant",
      quantity: quantity,
      dimension29: "product_location",
      dimension30: "product_subcategory",
      dimension31: "product_original_price",
      dimension32: "product_discount",
      dimension52: "TnE_start_date",
      dimension53: "TnE_end_date",
    },
  ];

  let ecommerceObject = {
    event: "product_remove_from_cart",
    booking_service_type: "booking_service_type",
    booking_destination: "booking_destination",
    booking_start_date: "booking_start_date",
    booking_end_date: "booking_end_date",
    ecommerce: {
      remove: {
        products: productRemoveFromCartArray,
      },
    },
  };
  return ecommerceObject;
};

export const createGtmSelectDateSKU = ({
  skuDetail = {},
  selectDate = moment(),
  type = enumType.BOOKING_TYPE.TOUR,
}) => {
  let booking_start_date = selectDate;
  let lengthOfTour = parseFloat(_get(skuDetail, "tourDetail.lengthOfTour"));
  let tour_name = _get(skuDetail, "tourDetail.tourName");
  let booking_destination = skuDetail?.destination?.name;

  if (type !== enumType.BOOKING_TYPE.TOUR) {
    booking_start_date = selectDate;
    lengthOfTour = 1;
    tour_name = _get(skuDetail, "name");
  }

  const booking_end_date = moment(booking_start_date, FORMAT_ONLY_DATE)
    .add(Math.floor(lengthOfTour) - 1, "days")
    .format(FORMAT_ONLY_DATE);
  const ecommerceObject = {
    event: gtmEvent.SKU_SELECT_DATE,
    booking_service_type: ENUM_SKU.category,
    booking_destination,
    booking_start_date,
    booking_end_date,
    tour_name: `TnE: ${tour_name}`,
  };
  return ecommerceObject;
};

export const createSelectTourOption = ({
  tour = {},
  name = "",
  bookingDate,
  isExperience,
}) => {
  const ecommerceObject = {
    event: gtmEvent.SELECT_TOUR_OPTION,
    booking_service_type: ENUM_SKU.category,
    booking_destination: _get(tour, "destination.name") || null,
    booking_start_date: bookingDate?.startDate || null,
    booking_end_date: bookingDate?.endDate || null,
    tour_name: isExperience
      ? `TnE: ${_get(tour, "name")}`
      : `TnE: ${_get(tour, "tourDetail.tourName")}`,
    tour_option: name,
  };

  return ecommerceObject;
};
