import actionTypes from '../actionTypes'

const initState = {
  dataFill: null,
  couponLoyalty: null,
  promotionCodeSKU: null,
  promotionBanks: null,
  autoFillPromotionCode: true,
}

const promotionReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.START_FETCHING_PROMOTION_DATA_FILL:
      return Object.assign({}, state, {
        dataFill: null
      })
    case actionTypes.FETCHING_PROMOTION_DATA_FILL_SUCCESS:
      return Object.assign({}, state, {
        dataFill: action.payload.data
      })
    case actionTypes.PROMOTION_DATA_NO_FILL_SUCCESS:
      return Object.assign({}, state, {
        autoFillPromotionCode: false
      })
    case actionTypes.FETCHING_PROMOTION_COUPON_LOYALTY_SUCCESS:
      return Object.assign({}, state, {
        couponLoyalty: action.payload.data.data
      })
    case actionTypes.MY_OFFER_PROMOTION_CODE_SKU:
      return Object.assign({}, state, {
        promotionCodeSKU: action.data
      })
    case actionTypes.FETCHING_PROMOTION_DATA_PROMOTION_BANK_SUCCESS:
      return Object.assign({}, state, {
        promotionBanks: action.payload.data
      })
    default:
      return state
  }
}

export default promotionReducer