import { createSelector } from 'reselect'

const getUserInfo = state => state.auth.userInfo
const getAuth = state => state.auth
const getIsSignUp = state => state.auth.isSignUp
const getIsSignUpSuccess = state => state.auth.isSignUpSuccess
const getErrorLoginVpt = state => state.auth.errorLoginVpt
const getErrorSignupVpt = state => state.auth.errorSignupVpt
const getForgotPwd = state => state.auth.forgotPwd
const getIsForgotPwdSuccess = state => state.auth.isForgotPwdSuccess
const getErrorForgotPwd = state => state.auth.errorForgotPwd
const getSendOtp = state => state.auth.sendOtp
const getErrorSendOtp = state => state.auth.errorSendOtp
const getIsUser = state => state.auth.isUser
const getIsCheckUserSuccess = state => state.auth.isCheckUserSuccess
const getErrorCheckUser = state => state.auth.errorCheckUser
const getUpdateUser = state => state.auth.updateUser
const getIsUpdateUserSuccess = state => state.auth.isUpdateUserSuccess
const getErrorUpdateUser = state => state.auth.errorUpdateUser
const getLinkAccount = state => state.auth.linkAccount
const getIsLinkAccountSuccess = state => state.auth.isLinkAccountSuccess
const getErrorLinkAccount = state => state.auth.errorLinkAccount
const getremoveOldPhone = state => state.auth.removeOldPhone
const getIsremoveOldPhoneSuccess = state => state.auth.isremoveOldPhoneSuccess
const getErrorremoveOldPhone = state => state.auth.errorremoveOldPhone
const getUploadAvata = state => state.auth.avataUpload
const getIsUploadAvataSuccess = state => state.auth.isUpdloadAvataSuccess
const getErrorUploadAvataPhone = state => state.auth.errorUpdloadAvata

export const selectUserInfo = () => 
  createSelector(
    getUserInfo,
    userInfo => userInfo
  )

export const selectIsChangeToken = () =>
  createSelector(
    getAuth,
    auth => !!auth.changeToken
  )

export const selectAuth = () =>
  createSelector(
    getAuth,
    auth => auth
  )

export const selectIsLogin = () =>
  createSelector(
    getAuth,
    auth => !!(auth.user && auth.isLogin)
  )

export const selectIsSignUp = () =>
  createSelector(
    getIsSignUp,
    isSignUp => isSignUp
  )
export const selectIsSignUpSuccess = () =>
  createSelector(
    getIsSignUpSuccess,
    isSignUpSuccess => isSignUpSuccess
  )

export const selectErrorLoginVpt = () =>
  createSelector(
    getErrorLoginVpt,
    errorLoginVpt => errorLoginVpt
  )

export const selectErrorSignupVpt = () =>
  createSelector(
    getErrorSignupVpt,
    errorSignupVpt => errorSignupVpt
  )

export const selectCheckPearlClub = () =>
  createSelector(getAuth, (auth) => auth.upgradePearlClub);

export const selectForgotPwd = () =>
  createSelector(
    getForgotPwd,
    forgotPwd => forgotPwd
  )

export const selectIsForgotPwdSuccess = () =>
  createSelector(
    getIsForgotPwdSuccess,
    isForgotPwdSuccess => isForgotPwdSuccess
  )

export const selectErrorForgotPwd = () =>
  createSelector(
    getErrorForgotPwd,
    errorForgotPwd => errorForgotPwd
  )

export const selectSendOtp = () =>
  createSelector(
    getSendOtp,
    sendOtp => sendOtp
  )

export const selectErrorSendOtp = () =>
  createSelector(
    getErrorSendOtp,
    errorSendOtp => errorSendOtp
  )

export const selectUpdateUser = () =>
  createSelector(
    getUpdateUser,
    updateUser => updateUser
  )

export const selectIsUpdateUserSuccess = () =>
  createSelector(
    getIsUpdateUserSuccess,
    isUpdateUserSuccess => isUpdateUserSuccess
  )

export const selectErrorUpdateUser = () =>
  createSelector(
    getErrorUpdateUser,
    errorUpdateUser => errorUpdateUser
  )

export const selectCheckUser = () =>
  createSelector(
    getIsUser,
    isUser => isUser
  )

export const selectIsCheckUserSuccess = () =>
  createSelector(
    getIsCheckUserSuccess,
    isCheckUserSuccess => isCheckUserSuccess
  )

export const selectErrorCheckUser = () =>
  createSelector(
    getErrorCheckUser,
    errorCheckUser => errorCheckUser
  )

export const selectLinkAccount = () =>
  createSelector(
    getLinkAccount,
    LinkAccount => LinkAccount
  )

export const selectIsLinkAccountSuccess = () =>
  createSelector(
    getIsLinkAccountSuccess,
    isLinkAccountSuccess => isLinkAccountSuccess
  )

export const selectErrorLinkAccount = () =>
  createSelector(
    getErrorLinkAccount,
    errorLinkAccount => errorLinkAccount
  )

export const selectremoveOldPhone = () =>
  createSelector(
    getremoveOldPhone,
    removeOldPhone => removeOldPhone
  )

export const selectIsremoveOldPhoneSuccess = () =>
  createSelector(
    getIsremoveOldPhoneSuccess,
    isremoveOldPhoneSuccess => isremoveOldPhoneSuccess
  )

export const selectErrorremoveOldPhone = () =>
  createSelector(
    getErrorremoveOldPhone,
    errorremoveOldPhone => errorremoveOldPhone
  )

export const selectUploadAvata = () =>
  createSelector(
    getUploadAvata,
    avataUpload => avataUpload
  )

export const selectIsUploadAvataSuccess = () =>
  createSelector(
    getIsUploadAvataSuccess,
    isUpdloadAvataSuccess => isUpdloadAvataSuccess
  )

export const selectErrorUploadAvata = () =>
  createSelector(
    getErrorUploadAvataPhone,
    errorUpdloadAvata => errorUpdloadAvata
  )