import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
// lib
import { useLocation } from 'react-router-dom';
// Component
import Drawer from "rc-drawer";
import TopHeader from "./TopHeader";
import Menu from "./Menu";
import { featureFlags } from "../../constants";
import { useDeviceInfo, useScreenSize } from "../../hooks/deviceInfo";
import { getConfig } from "@config";
import DownloadAppBanner from "./DownloadAppBanner";
import clsx from "clsx";
import Language from "./Language";
import LoginContainer from "./LoginContainer";
import Cart from "./Cart";
import './HomepageHeader.scss'

import NavHeader from "./NavHeader";
import Currency from "./Currency";

const {
  publicRuntimeConfig: { HOME_PAGE }, // Available both client and server side
} = getConfig();

const homePageUrl = HOME_PAGE || "https://dev-vinpearl.vin3s.vn";

const HomepageHeader = () => {
  const { pathname } = useLocation()
  const scrollShowHeader = 700; // where to show header

  const [openMenu, setOpenMenu] = useState(false);
  const [hasDownloadApp, setHasDownloadApp] = useState(false);
  const { isLarge } = useScreenSize();
  const { isTablet, isMobileMedium } = useDeviceInfo();
  const [isScrolled, setIsScrolled] = useState(window.scrollY);
  const [isHideHeader, setIsHideHeader] = useState(false);
  const [isHomstay, setIsHomestay] = useState(false);

  useEffect(() => {
    setIsHomestay(pathname.includes("/staynfun"));
  }, [pathname])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos === 0) {
      setIsScrolled(false);
      setIsHideHeader(false);
      return;
    }
    if(currentScrollPos < scrollShowHeader){
      setIsHideHeader(true);
    } else {
      setIsScrolled(true);
      setIsHideHeader(false);
    }
  };

  const setHasDownloadAppCallback = useCallback((has) => {
    setHasDownloadApp(has);
  }, []);

  const handleToggleOpen = useCallback(() => {
    setOpenMenu(!openMenu);
  }, [openMenu]);

  return (
    <header
      className={clsx("homepageHeader s43-header g-font-EBGaramond", {
        "has-download-app-header": hasDownloadApp,
      })}
    >
      {!featureFlags.HIDE_VIN3S_INFO && (
        <TopHeader
          mode="horizontal"
          showCart={true}
          showLogin={isTablet && !isLarge}
          showLanguage={isTablet && isLarge}
        />
      )}
      <div
        className={clsx(
          "mobile-header",
          "fixed-top",
          { "has-download-app": hasDownloadApp },
          { "header--hide": isHideHeader },
          { "bg-homestay": isHomstay },
        )}
      >
        <div
          className={clsx("container-header", {
            "header--scroll": !isScrolled,
          })}
        >
          <div className="container g-width-1170-xl">
            {isMobileMedium && (
              <DownloadAppBanner
                setHasDownloadApp={setHasDownloadAppCallback}
              />
            )}
            <div id="header-nav-container" className="header-nav">
              <div
                className="menu-icon header-left-block"
                onClick={handleToggleOpen}
              >
                {isScrolled ? (
                  <img
                    alt=""
                    src={require("@assets/include/svg/menu_fries.svg").default}
                  />
                ) : (
                  <img
                    alt=""
                    src={
                      require("@assets/include/svg/fi-rr-menu-burger.svg")
                        .default
                    }
                  />
                )}
              </div>

              <NavHeader
                isScrolled={isScrolled}
                isHomstay={isHomstay}
              />

              <div className="cart-wrapper header-right-block">
                {isMobileMedium ? (
                  <Cart
                    className="g-font-size-16 g-pr-0"
                    icon={<i className="fas fa-shopping-cart"></i>}
                  />
                ) : (
                  <>
                    {
                      <LoginContainer
                        containerSelector="#header-nav-container"
                        isHomepage={!isScrolled}
                      />
                    }
                    <span
                      className={clsx("s43-header-divider", {
                        "text-white": !isScrolled,
                      })}
                    >
                      /
                    </span>
                    {
                      <Currency
                        containerSelector="#header-nav-container"
                        isHomepage={!isScrolled}
                      />
                    }
                    {
                      <Language
                        containerSelector="#header-nav-container"
                      />
                    }
                  </>
                )}
              </div>
            </div>

            <Drawer
              getContainer={null}
              level={null}
              open={openMenu}
              onClose={handleToggleOpen}
              handler={null}
            >
              <Menu onToggleMenu={handleToggleOpen} />
            </Drawer>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HomepageHeader;
