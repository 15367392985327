export default class ApiResponse {
    constructor(data={}){
        this.data = data.data || undefined;
        this.status = data.status || undefined;
        this.errorCode = data.errorCode || undefined;
        this.message = data.message || undefined;
        this.title = data.title || false;
        this.success = data.success || false;
        this._createdDate = Date.now();
    }

    isCouponError(){
        return this.success === false && this.errorCode && this.errorCode.startsWith('COUPON')
    }
}