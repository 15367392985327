import React from 'react'
import { CurrencyNumber } from './index'

//lib
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { FormattedMessage } from "react-intl.macro"
//component
import { Button } from 'antd'
import CurrencyFormatNumber from '../Currency'

const ButtonPrice = (props) => {

  const {
    actionExpand,
    totalPrice,
    buttonName,
    priceCss,
    buttonCss,
    onChange,
    id, btnViewId,
    toggleFares,
    showDetailPrice,
    openListFare,
    fareOptions,
    passenger,
    isFlightCombo
  } = props

  const countPassenger = passenger ? (passenger.adult || 0) + (passenger.child || 0) + (passenger.infant || 0) : 0

  return (
    <div className='d-flex align-items-md-center flex-column w-100'>
      <div className='d-flex align-items-center w-100'>
        {showDetailPrice && actionExpand}
        <div className={
          clsx({
            'g-font-weight-500 g-font-size-20 flex-column-start': true,
            'g-color-primary': !priceCss,
            [`${priceCss}`]: !!priceCss,
            
          })
        }>
          <span className='g-font-size-20'>
              {isFlightCombo && `+ `}<CurrencyNumber
                value={totalPrice}
                unitCss='g-font-size-17 g-text-underline'
              />
              <span
                className="g-color-gray-light-v1 g-font-size-12"
              >
              { isFlightCombo && <FormattedMessage
                id={`Flight.BasicInfo.TicketInFo.TotalPassenger`}
                defaultMessage={`/{count} người`}
                values={{
                  count: countPassenger
                }}
              />
              }
              </span>
            </span>
          <span>
            <CurrencyFormatNumber 
                totalPrice={totalPrice}
                className='g-font-size-14 c-fontweight-600'
            />
            {/* <span
              className="g-color-gray-light-v1 g-font-size-12"
            >
            { isFlightCombo && <FormattedMessage
              id={`Flight.BasicInfo.TicketInFo.TotalPassenger`}
              defaultMessage={`/{count} người`}
              values={{
                count: countPassenger
              }}
            />
            }
            </span> */}
          </span>
        </div>
      </div>
      <div className='g-my-10 w-100'>
        <Button
          id={id}
          className={
            clsx({
              'g-color-white w-100 rounded-0 vpt-height-auto g-py-10': true,
              'vpt-bg-v7': !buttonCss,
              [`${buttonCss}`]: !!buttonCss
            })
          }
          onClick={() => {
            if (onChange) {
              onChange()
            }
          }}
        >
          {buttonName}
        </Button>
      </div>
      { (fareOptions && fareOptions.length > 1) && 
        <button 
          id={btnViewId} 
          className="btn-flight-view-more vpt-color-support-info g-font-size-14 g-font-weight-500" 
          onClick={() => toggleFares()}>
            {
              !openListFare ?
                <FormattedMessage
                  id='Flight.BasicInfo.TicketInFo.ViewMore'
                  defaultMessage='Thêm lựa chọn'
                />
              : 
                <FormattedMessage
                  id='Flight.BasicInfo.TicketInFo.ViewLess'
                  defaultMessage='Thu gọn lựa chọn'
                />
            }
           <img src={require('@assets/include/svg/expand_more_blue.svg').default} alt="expand_more_blue" className={`icon_expand ${openListFare ? 'active' : ''}`}/>
        </button>
      }
    </div>
  )
}

ButtonPrice.propTypes = {
  actionExpand: PropTypes.element,
  totalPrice: PropTypes.number,
  unit: PropTypes.string,
  buttonName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  priceCss: PropTypes.string,
  buttonCss: PropTypes.string,
  onChange: PropTypes.func,
  openListFare: PropTypes.bool,
  fareOptions: PropTypes.array,
  isFlightCombo: PropTypes.bool
}

export default ButtonPrice