import {axiosProviderVin3S} from './api/axiosProvider'
//actions
import actionTypes from '../actionTypes'
//utils
//common
import {
  loadSuccess,
  loadFail
} from './commonAction'


export const getFooter = (lang) => {
  return async (dispatch) => {
    try {
        let url = `${axiosProviderVin3S}/${lang}/api/footer`;
        fetch(url)
        .then(response => response.json())
        .then(res => 
            {
                if (res) {
                    dispatch(
                      loadSuccess(
                        actionTypes.FOOTER_URL_NAV_LINK,
                        res,
                        null,
                        null,
                        null
                      )
                    )
                  } else {
                    dispatch(loadFail(response.errors[0].message))
                  }
            });
    //   const response = await axiosProviderVin3S.get(`${lang}/api/footer`)
    } catch (errors) {
      // fail
      dispatch(loadFail(errors.response))
    }
  }
}

export default {
    getFooter
}