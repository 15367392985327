import React from 'react'
import Link from '@utils/router/link'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { Button, Col, Form, Input, Row } from 'antd'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin } from 'react-google-login'
import { getConfig } from '@config'
import { loginGoogleCallback, loginFaceBookCallback } from '../../hooks/auth'

const {
  publicRuntimeConfig: {
    GOOGLE_CLIENT_ID,
    FB_APP_ID
  }
} = getConfig()

// const
import { routes } from '../../constants'

// utils
import utils from '../../utils'

//extensions
import { yupExtensions } from '../../extensions'
import { FormattedMessage } from 'react-intl.macro'

const FormItem = Form.Item

const customFormik = withFormik({
  validationSchema: Yup.object().shape({
    username: yupExtensions.stringRequired,
    password: yupExtensions.stringRequired
  }),
  handleSubmit (data, { props }) {
    props.onLogin(data)
  }
})

const LoginForm = ({
  errors,
  touched,
  values,
  setFieldValue,
  setFieldTouched,
  handleSubmit,
  isLoading,
  refreshPage = true,
  handleHide
}) => {

  const onLoginFacebook = loginFaceBookCallback()
  const onLoginGoogle = loginGoogleCallback()

  const responseGoogle = async (response) => {

    if (!response.tokenId) {
      return
    }
    const result = await onLoginGoogle(response.tokenId, refreshPage)
    hideForm(result)
  }

  const responseFacebook = async (response) => {
    if (!response.accessToken) {
      return
    }
    const result = await onLoginFacebook(response.accessToken, refreshPage)
    hideForm(result)
  }

  const hideForm = (result) => {
    if(result && handleHide) {
      handleHide()
    }
  }

  return (
    <section className='vpt-bg-light-v2'>
      <div className='container g-py-100--lg g-py-50 g-py-50--sm'>
        <div className='row justify-content-center'>
          <div className='col-sm-8 col-lg-8 col-md-10 custom-col-modal'>
            <div className='u-shadow-v21 g-bg-white g-rounded-7 g-py-25 g-px-25'>
              <header className='mb-3'>
                <h2 className='h2 g-color-black g-font-size-18 g-font-weight-700'>
                  <FormattedMessage
                    id="Nav.Login"
                    defaultMessage="Đăng nhập"
                  />
                </h2>
              </header>
              <hr className='vpt-brd-v5 mb-2 mt-0 g-mb-20'/>
              <Form onSubmit={handleSubmit} className='vpt-auth-form login-form'>
                <Row gutter={15}>
                  <Col md={9}
                       className='g-z-index-1 g-font-size-14 g-pr-0--md g-order-2 g-order-1--md g-mt-30 g-mt-0--md'>
                    <div className="border-right g-brd-0 g-brd-1--md g-pr-15--md">
                      <p className="g-mb-5--md g-mb-10">
                        <FormattedMessage
                          id="LoginForm.LoginWith"
                          defaultMessage='Đăng nhập với'
                        />
                      </p>

                      {/*facebook*/}
                      <div className="row g-px-15 g-mb-50--md g-mb-20">
                        <FacebookLogin
                          appId={FB_APP_ID}
                          autoLoad={true}
                          fields="name,email,picture"
                          callback={responseFacebook}
                          onFailure={responseFacebook}
                          render={renderProps => (
                            <div className="col-md-12 g-cursor-pointer">
                              <div className="vpt-tracking_log-in-fb row vpt-bg-blue-v2 g-px-15 g-color-white g-py-13"
                                   onClick={renderProps.onClick}>
                                <div className="col-3 vpt-tracking_log-in-fb">
                                  <div className="d-flex justify-content-center vpt-tracking_log-in-fb">
                                    <i className="g-font-size-22 fa fa-facebook-f vpt-tracking_log-in-fb"/>
                                  </div>
                                </div>
                                <div className="col-6 vpt-tracking_log-in-fb">
                                  <div className="d-flex justify-content-center">
                                    <span className="vpt-tracking_log-in-fb">Facebook</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        />
                      </div>

                      {/*google*/}
                      <div className="row g-px-15">
                        <div className="col-md-12 g-cursor-pointer">
                          <GoogleLogin
                            clientId={GOOGLE_CLIENT_ID}
                            buttonText="Login"
                            render={renderProps => (
                              <div
                                className="vpt-tracking_log-in-gg row vpt-bg-extra-red-v7 g-px-15 g-color-white g-py-12"
                                onClick={renderProps.onClick}
                              >
                                <div className="col-3 vpt-tracking_log-in-gg">
                                  <div className="d-flex justify-content-center">
                                    <img
                                      src={require('@assets/img/icons/google.png').default}
                                      alt="google"
                                      className="vpt-tracking_log-in-gg"
                                    />
                                  </div>
                                </div>
                                <div className="col-6 vpt-tracking_log-in-gg">
                                  <div className="d-flex justify-content-center">
                                    <span className="vpt-tracking_log-in-gg">Google</span>
                                  </div>
                                </div>
                              </div>
                            )}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                          />

                        </div>
                      </div>
                    </div>
                    <div className="g-mt-80--md g-mt-10 g-mb-10 g-mb-0--md d-flex justify-content-center d-md-block">
                      <span>
                        <FormattedMessage
                          id="ForgotPassword.Register"
                          defaultMessage='Bạn chưa có tài khoản?'
                        />
                        <Link href={routes.REGISTER}>
                          <a className='g-font-weight-500 vpt-color-v3 g-color-primary'>
                             {` `}
                            <FormattedMessage
                              id="Nav.Register"
                              defaultMessage="Đăng ký"
                            />
                          </a>
                        </Link>
                      </span>

                    </div>
                  </Col>
                  <Col md={14} className='g-z-index-1 g-pl-15--md g-mt-20 g-mt-0--md g-order-1 g-order-2--md'>
                    <FormItem
                      help={
                        utils.checkFieldError('username', errors, touched)
                          ? errors.username
                          : ''
                      }
                      validateStatus={
                        utils.checkFieldError('username', errors, touched)
                          ? 'error'
                          : undefined
                      }
                      colon={false}
                      label={
                        <FormattedMessage
                          id="LoginForm.EmailLogin"
                          defaultMessage='Email đăng nhập'
                        />
                      }
                      required={true}
                    >
                      <Input
                        className='g-py-10 rounded-0'
                        value={values.username}
                        onChange={event =>
                          setFieldValue('username', event.target.value)
                        }
                        onBlur={() => setFieldTouched('username', true)}
                      />
                    </FormItem>
                    <FormItem
                      help={
                        utils.checkFieldError('password', errors, touched)
                          ? errors.password
                          : null
                      }
                      validateStatus={
                        utils.checkFieldError('password', errors, touched)
                          ? 'error'
                          : undefined
                      }
                      colon={false}
                      label={
                        <FormattedMessage
                          id="RegisterForm.Password"
                          defaultMessage='Mật khẩu'
                        />
                      }
                      required={true}
                    >
                      <Input.Password
                        className='rounded-0'
                        value={values.password}
                        onChange={event =>
                          setFieldValue('password', event.target.value)
                        }
                        onBlur={() => setFieldTouched('password', true)}
                      />
                    </FormItem>


                    <div className="d-flex justify-content-end g-mb-20">
                      {/*<Checkbox onChange={ onSaveLogin }>*/}
                      {/*  <FormattedMessage*/}
                      {/*    id="LoginForm.SavePassword"*/}
                      {/*    defaultMessage='Lưu mật khẩu'*/}
                      {/*  />*/}
                      {/*</Checkbox>*/}
                      <span>
                        <Link href={routes.FORGOT_PASSWORD}>
                          <a className='g-font-weight-500 vpt-color-v3'>
                            {` `}
                            <FormattedMessage
                              id="ForgotPassword.Title"
                              defaultMessage='Quên mật khẩu'
                            />
                          </a>
                        </Link>
                      </span>
                    </div>
                    <Button
                      className='btn btn-md g-bg-primary g-color-white rounded g-py-13 rounded-0 g-font-size-14 g-font-weight-500 w-100'
                      htmlType='submit'
                      disabled={isLoading}
                    >
                      <FormattedMessage
                        id="Nav.Login"
                        defaultMessage="Đăng nhập"
                      />
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default customFormik(LoginForm)