import actionTypes from '../actionTypes'

const initState = {
  currencyRates: null
}

const currencyRateReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.CURRENCY_RATE_GET_FROM_WEB:
      return Object.assign({}, state, {
        currencyRates: action.payload.data
      })
    default:
      return state
  }
}

export default currencyRateReducer