import React, { Fragment } from "react";
import { useIntl } from 'react-intl'

const ReferencesGroup = (props) => {
  const {
    groupData,
    colClass
  } = props;
  const {
    groupName,
    items,
  } = groupData;
  return (
    <Fragment>
      <div className="d-flex--md align-items-center">
        <div className="heading d-flex--md">
          <span>{groupName}</span>
        </div>
        <div className="d-flex flex-wrap align-items-center w-100">
          {
            items.map((item, index) => {
              return (
                <div key={index} className={`${colClass}`}>
                  <div className="hotel-brand">
                    <div className="logo g-flex-centered">
                      <a href={item.imgHref} title="#title">
                        <picture>
                          <img
                            className="w-100 h-100 vpt-object-fit-cover"
                            src={item.imgSrc}
                            alt={item.imgAlt}
                          />
                        </picture>
                      </a>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </Fragment>
  )
}
const References = () => {
  const intl = useIntl();
  const data = [
    {
      groupName: intl.formatMessage({
        id: "References.Group.Hotel",
        defaultMessage: "Khách sạn & Nghỉ dưỡng",
      }),
      items: [
        {
          imgSrc: require('@assets/img/hotel-logos/references/vp_luxury.svg').default,
          imgHref: '#'
        },
        {
          imgSrc: require('@assets/img/hotel-logos/references/vp_hotel_resort.svg').default,
          imgHref: '#'
        },
        {
          imgSrc: require('@assets/img/hotel-logos/references/vp_discovery.svg').default,
          imgHref: '#'
        },
        {
          imgSrc: require('@assets/img/hotel-logos/references/vp_oasis.svg').default,
          imgHref: '#'
        },
        {
          imgSrc: require('@assets/img/hotel-logos/references/vinholidays.svg').default,
          imgHref: '#'
        },
      ]
    },
    {
      groupName: intl.formatMessage({
        id: "References.Group.Event",
        defaultMessage: "Hội họp & sự kiện",
      }),
      items: [
        {
          imgSrc: require('@assets/img/hotel-logos/references/vp_convention_center.svg').default,
          imgHref: '#'
        },
        {
          imgSrc: require('@assets/img/hotel-logos/references/almaz.svg').default,
          imgHref: '#'
        }
      ]
    },
    {
      groupName: intl.formatMessage({
        id: "References.Group.Entertainment",
        defaultMessage: "Vui chơi & giải trí",
      }),
      items: [
        {
          imgSrc: require('@assets/img/hotel-logos/references/vinwonder.svg').default,
          imgHref: '#'
        },
        {
          imgSrc: require('@assets/img/hotel-logos/references/vp_golf.svg').default,
          imgHref: '#'
        },
        {
          imgSrc: require('@assets/img/hotel-logos/references/vp_safari.svg').default,
          imgHref: '#'
        },
        {
          imgSrc: require('@assets/img/hotel-logos/references/pq_united_center.svg').default,
          imgHref: '#'
        },
      ]
    }
  ]


  return (
    <div className="hotel-brands g-py-60--md g-py-10">
      <div className="container p-0">
        <div className="row">
          <div className="col-md-12 g-mb-10 g-mb-70--md">
            <ReferencesGroup
              groupData={data[0]}
              colClass='brand-col'
            />
          </div>

          <div className="col-md-12 g-mb-10 g-mb-70--md">
            <ReferencesGroup
              groupData={data[1]}
              colClass='brand-col'
            />

          </div>

          <div className="col-md-12">
            <ReferencesGroup
              groupData={data[2]}
              colClass='brand-col'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default References;
