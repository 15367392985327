import { notification } from 'antd'

export const handleSubmitWithRecaptcha = async ({
  alias,
  executeRecaptcha,
  handleSubmit
}) => {

  if (!executeRecaptcha) {
    notification.error({
      message: 'Fail to verify reCaptcha!'
    })
  } else {
    const result = await executeRecaptcha(alias)
    if (handleSubmit) {
      return handleSubmit(result)
    }
  }
}