import truncate from 'html-truncate'
import { enumType } from '../constants'

function getPhoneNumberPrefix(phoneNumber) {
  let result = undefined;
  if (typeof phoneNumber === 'string' && phoneNumber.length) {
    for (let prefix of enumType.prefixPhoneEnum) {
      if (phoneNumber.startsWith(prefix.value)) {
        result = prefix;
        break;
      }
    }
  }
  return result && result.value;
}

function getPhoneNumberWithoutPrefix(phoneNumber) {
  const prefix = getPhoneNumberPrefix(phoneNumber);
  if (prefix) {
    return phoneNumber.substring(prefix.length, phoneNumber.length)
  }
  return undefined;
}

export default {
  capitalizeWords(string) {
    return string
      ? string.replace(/(?:^|\s)\S/g,
        (firstCharacter) => firstCharacter.toUpperCase())
      : null
  },
  compareString(firstString, secondString) {
    const firstStringWithoutUppercase = firstString
      ? firstString.toUpperCase()
      : ''
    const secondStringWithoutUppercase = secondString
      ? secondString.toUpperCase()
      : ''

    if (firstStringWithoutUppercase < secondStringWithoutUppercase) {
      return -1
    }
    if (firstStringWithoutUppercase > secondStringWithoutUppercase) {
      return 1
    }
    return 0
  },

  compareAccentString(firstString, secondString) {
    if (firstString && secondString) {
      return firstString.localeCompare(secondString)
    }
    return 0
  },

  getAbbreviate(string) {
    let abbreviate = ''
    if (string) {
      let outString = string.replace(/[^\w\s]/gi, '')
      let words = outString.split(' ')
      for (let i = 0; i <= words.length - 1; i++) {
        abbreviate += words[i] !== ' ' ? words[i].charAt(0) : ''
      }
    }
    return abbreviate.toLowerCase()
  },
  truncateContent(
    content,
    characterCount
  ) {

    if (!content || content.length === 0) {
      return null
    }
    return truncate(content, characterCount, {
      ellipsis: '...'
    })
  },
  removeHtmlTagFromString(htmlString, maxLength = null) {
    const removed = htmlString && htmlString.replace(/<\/?[^>]+(>|$)/g, "")
    const result = removed.length > maxLength && maxLength ? `${removed.substring(0, maxLength)}...` : removed;
    return result
  },
  getPhoneNumberPrefix,
  getPhoneNumberWithoutPrefix,

}
