import React from 'react'

// lib
import { useSelector } from 'react-redux'
import { useRouter } from '@utils/router'
import PropTypes from 'prop-types'
import { connectModal } from 'redux-modal'

// component
import { Button, Modal } from 'antd'

// constant and service
import { modalUtils } from '../../utils'
import { enumType, routes } from '../../constants'
import { queryStringExtensions } from '../../extensions'

//hook and selector
import { redirectPage } from '../../hooks/common'
import { selectSearchFlightParams, selectSearchBundleParams } from '../../reselect/searchSelector'
import { changeDepartureFlight, changeReturnFlight } from '../../hooks/flight'
import { FormattedMessage } from 'react-intl.macro'
import { bundleServices } from '../../services'

const SessionTimeOut = (props) => {
  const [redirectPageCallback] = redirectPage()
  const [changeDepartureFlightCallback] = changeDepartureFlight()
  const [changeReturnFlightCallback] = changeReturnFlight()

  const router = useRouter()
  const searchParams = useSelector(selectSearchFlightParams())
  const bundleParams = useSelector(selectSearchBundleParams())

  const { handleCloseModal } = props

  const message = props.message || (
    <FormattedMessage
      id="Modal.ErrorModal.YourSessionHasExpired"
      defaultMessage='Phiên làm việc của bạn đã hết hạn'
    />
  )

    const text = props.text || (
      <FormattedMessage
        id="Modal.ErrorModal.PleasePerformTheSearchAgain"
        defaultMessage="Vui lòng thực hiện tìm kiếm lại"
      />
    );

  // const isInBundle = router.route.startsWith(routes.COMBO);
  const isInBundle = window.location.pathname.includes(routes.COMBO);

  const handleRedirect = (path) => {
    handleCloseModal()
    redirectPageCallback(path)
    changeDepartureFlightCallback(null)
    changeReturnFlightCallback(null)
  }

  return (
    <div className='text-center'>
      <p>
        {message}
        <br />
        {text}
      </p>
      <div className='text-center vpt-form'>
        <Button
          icon="search"
          className='d-block g-color-white g-bg-primary g-brd-primary mx-auto d-flex align-items-center g-mb-20'
          onClick={() => handleRedirect(
            isInBundle
              ? queryStringExtensions.getBundleQueryString(routes.COMBO_SEARCH, bundleServices.parseBundleParams(bundleParams))
              : queryStringExtensions.getFlightQueryString(searchParams)
          )}
        >
          <FormattedMessage
            id="Modal.ErrorModal.Buttom.SearchAgain"
            defaultMessage='Tìm kiếm lại'
          />
        </Button>

        <a
          onClick={() => handleRedirect('/')}
          className='g-text-underline'>
          <FormattedMessage
            id="Modal.ErrorModal.Buttom.BackToHomePage"
            defaultMessage='Về trang chủ'
          />
        </a>
      </div>
    </div>
  )
}

const Default = (props) => {
  const {
    message = <FormattedMessage
      id="Modal.Error.ProcessingError"
      defaultMessage='Đã có lỗi xảy ra trong quá trình xử lý dữ liệu. Vui lòng thử lại'
    />,
    handleCloseModal,
    params = {}
  } = props

  const actionClose = ()=>{
    handleCloseModal()
    if(params.isReload){
      window.location.reload()
    }
  }

  return (
    <div className='text-center vpt-form'>
      <div className='g-mb-15'>
        {message}
      </div>
      <Button
        className='g-bg-primary g-brd-primary g-color-white'
        onClick={actionClose}
      >
        <FormattedMessage
          id="Modal.ErrorModal.Buttom.Closed"
          defaultMessage='Đóng'
        />
      </Button>
    </div>
  )
}

const ErrorModal = (props) => {

  const { show, handleHide, message, params = {} } = props

  const getContentModal = (error) => {
    if (error) {
      switch (error.errorCode) {
        case enumType.apiErrorCode.SESSION_TIMEOUT:
          return (
            <SessionTimeOut
              handleCloseModal={handleHide}
            />
          )
        case enumType.apiErrorCode.FLIGHT_SESSION_NOT_FOUND:
          return (
            <SessionTimeOut
              message={
                <FormattedMessage
                  id="Modal.ErrorModal.YourBookingFlightNotFound"
                  defaultMessage='Chuyến bay bạn chọn đã hết chỗ'
                />
              }
              handleCloseModal={handleHide}
            />
          )
        case enumType.apiErrorCode.FLIGHT_HOLDING_TIMEOUT:
          return (
            <div>
              <div className="text-center vpt-form">
                <img
                  className="g-width-200"
                  src={require("@assets/img/logo/iconHoldFail.png").default}
                />
              </div>
              <SessionTimeOut
                message={
                  <b>
                    <FormattedMessage
                      id="Modal.ErrorModal.Opps"
                      defaultMessage="Opps... Lỗi rồi"
                    />
                  </b>
                }
                text={
                  <div className="textErrorModal">
                    <FormattedMessage
                      id="Modal.ErrorModal.Search.Again"
                      defaultMessage={"Rất tiếc đã có lỗi xảy ra trong quá trình xử lý dữ liệu. \n Vui lòng thử lại!"}
                    />
                  </div>
                }
                handleCloseModal={handleHide}
              />
            </div>
          );
        default:
          return (
            <Default
              message={error.message}
              handleCloseModal={handleHide}
              isReload={params.isReload}
              params={params}
            />
          )
      }
    }
    
    return (
      <Default
        message={<FormattedMessage
          id="Modal.ErrorModal.PleaseTryAgain"
          defaultMessage='Đã có lỗi xảy ra trong quá trình xử lý dữ liệu. Vui lòng thử lại'
        />}
        handleCloseModal={handleHide}
      />
    )

  }

  const error = modalUtils.getApiMessageError(message, false, params)

  return (
    <Modal
      title={null}
      footer={null}
      maskClosable={false}
      closable={false}
      wrapClassName='vpt-error-modal'
      visible={show}
      onOk={handleHide}
      onCancel={handleHide}
    >
      {getContentModal(error)}
    </Modal>
  )
}

Default.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleCloseModal: PropTypes.func.isRequired
}

SessionTimeOut.propTypes = {
  handleCloseModal: PropTypes.func.isRequired
}

ErrorModal.propTypes = {
  handleHide: PropTypes.func.isRequired
}

export default connectModal({ name: 'modal', destroyOnHide: true })(ErrorModal)
