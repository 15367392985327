import actionType from '../actionTypes'
import Coupon from '../model/Coupon'
import { show } from 'redux-modal'
import { CartService, OrderService, VoucherService } from './../services'

/**
 * update cart
 */
export const updateCart = (cart) => {
  return dispatch => {
    return dispatch({
      type: actionType.UPDATE_CART,
      cart: cart
    })
  }
}

/**
 * load cart
 */
export const loadCartAction = () => {
  return async (dispatch) => {
    const cartData = await CartService.getCart()
    dispatch({
      type: actionType.UPDATE_CART,
      cart: cartData || {}
    })
  }
}

export const loadCartCountAction = () => {
  return async (dispatch) => {
    const countData = await CartService.getCartCount()
    dispatch({
      type: actionType.UPDATE_CART_COUNT,
      count: countData,
    })
  }
}

export const getCoupon = (code, productVariantIds = {}) => {
  return async (dispatch) => {
    const resp = await OrderService.getCoupon(code, productVariantIds)
    if (resp instanceof Coupon) {
      dispatch({
        type: actionType.UPDATE_COUPON,
        coupon: resp,
      })
      return Promise.resolve(resp)
    }
    return Promise.reject(resp)
  }
}

export const updateCoupon = (coupon) => {
  return dispatch => {
    return dispatch({
      type: actionType.UPDATE_COUPON,
      coupon: coupon
    })
  }
}
