import moment from "moment";
import { FORMAT_DATE } from "src/constants";
export default class UserProfle {
  constructor(data = {}) {
    this.setData(data);
  }

//   comment to remove the object so as not to exceed the size of the cookie
  setData(data = {}) {
    this.identityCard = data.identityCard;
    this.customerPoints = data.customerPoints;
    this.dateOfBirth = data.dateOfBirth;
    this.memberShipCard = data.memberShipCard;
    this.customerId = data.customerId;
    this.state = data.state;
    this.identityType = data.identityType;
    this.gender = data.gender;
    this.phoneCode = data.phoneCode;
    this.referralLink = data.referralLink;
    this.point = data.point;
    this.id = data.id;
    this.userName = data.userName;
    this.fullName = data.fullName;
    this.email = data.email;
    this.phoneNumber = data.phoneNumber;
    this.avatarImageView = data.avatarImageView;
    this.roleId = data.roleId;
    this.deactivated = data.deactivated;
    this.roleName = data.roleName;
    this.crmId = data.crmId;
    this.memberShipCode = data.memberShipCode;
    this.referralCode = data.referralCode;
    this.firstName = data.userInfoCdp.firstName;
    this.lastName = data.userInfoCdp.lastName;
    this.customerRoomNights = data.customerRoomNights;
    this.waitingPoint = data.waitingPoint;
  }
}
