import ActionTypes from '../actionTypes'

const INIT_STATE = {
  isLoading: false,
  loadingFlight: false,
  loadingHotel: true,
  loadingHotelIndex: 0,
  loadingHotelRoom: false,
  loadingVoucher: false,
  loadingCart: false,
  loadingCoupon: false,
  loadingOrder: false,
  loadingVinWonder: false,
  loadingAuth: false,
  loadingTour: false,
  loadingTourTicket: false,
  loadingTourLine: false,
  loadingFilter: false,
  loadingAuthLoginVpt: false,
  loadingPromotionAutoFill: false,
  loadingRegister: false,
  loadingSendOtp: false,
  loadingCheckUser: false,
  loadingForgotPwd: false,
  loadingUpdateUser: false,
  loadingLinkAccount: false,
  loadingUploadAvata: false,
  loadingRemoveOldPhone: false,
  loadingHomestay: false,
  loadingHomestayIndex: 0,
  loadingHomestayRoom: false,
}

const FetchingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.START_FETCHING:
      return Object.assign({}, state, {
        isLoading: true
      })
    case ActionTypes.STOP_FETCHING:
      return Object.assign({}, state, {
        isLoading: false
      })
      case ActionTypes.START_FETCHING_AUTH: {
        return Object.assign({}, state, {
          loadingAuth: true
        })
      }
      case ActionTypes.START_FETCHING_AUTH_LOGIN_VPT: {
        return Object.assign({}, state, {
          loadingAuthLoginVpt: true
        })
      }
      case ActionTypes.STOP_FETCHING_AUTH_LOGIN_VPT: {
        return Object.assign({}, state, {
          loadingAuthLoginVpt: false
        })
      }
      case ActionTypes.STOP_FETCHING_AUTH: {
        return Object.assign({}, state, {
          loadingAuth: false
        })
      }
    case ActionTypes.START_FETCHING_REGISTER: {
      return Object.assign({}, state, {
        loadingRegister: true
      })
    }
    case ActionTypes.STOP_FETCHING_REGISTER: {
      return Object.assign({}, state, {
        loadingRegister: false
      })
    }
    case ActionTypes.START_FETCHING_FORGOT_PWD: {
      return Object.assign({}, state, {
        loadingForgotPwd: true
      })
    }
    case ActionTypes.STOP_FETCHING_FORGOT_PWD: {
      return Object.assign({}, state, {
        loadingForgotPwd: false
      })
    }
    case ActionTypes.START_FETCHING_UPDATE_USER: {
      return Object.assign({}, state, {
        loadingUpdateUser: true
      })
    }
    case ActionTypes.STOP_FETCHING_UPDATE_USER: {
      return Object.assign({}, state, {
        loadingUpdateUser: false
      })
    }
    case ActionTypes.START_FETCHING_UPLOAD_AVATA: {
      return Object.assign({}, state, {
        loadingUploadAvata: true
      })
    }
    case ActionTypes.STOP_FETCHING_UPLOAD_AVATA: {
      return Object.assign({}, state, {
        loadingUploadAvata: false
      })
    }
    case ActionTypes.START_FETCHING_SEND_OTP: {
      return Object.assign({}, state, {
        loadingSendOtp: true
      })
    }
    case ActionTypes.STOP_FETCHING_SEND_OTP: {
      return Object.assign({}, state, {
        loadingSendOtp: false
      })
    }
    case ActionTypes.START_FETCHING_CHECK_USER: {
      return Object.assign({}, state, {
        loadingCheckUser: true
      })
    }
    case ActionTypes.STOP_FETCHING_CHECK_USER: {
      return Object.assign({}, state, {
        loadingCheckUser: false
      })
    }
    case ActionTypes.START_FETCHING_LINK_ACCOUNT: {
      return Object.assign({}, state, {
        loadingLinkAccount: true
      })
    }
    case ActionTypes.STOP_FETCHING_LINK_ACCOUNT: {
      return Object.assign({}, state, {
        loadingLinkAccount: false
      })
    }
    case ActionTypes.START_FETCHING_REMOVE_OLD_PHONE: {
      return Object.assign({}, state, {
        loadingRemoveOldPhone: true
      })
    }
    case ActionTypes.STOP_FETCHING_REMOVE_OLD_PHONE: {
      return Object.assign({}, state, {
        loadingRemoveOldPhone: false
      })
    }
    case ActionTypes.START_FETCHING_FLIGHT: {
      return Object.assign({}, state, {
        loadingFlight: true
      })
    }
    case ActionTypes.STOP_FETCHING_FLIGHT: {
      return Object.assign({}, state, {
        loadingFlight: false
      })
    }
    case ActionTypes.START_FETCHING_HOTEL: {
      return Object.assign({}, state, {
        loadingHotel: true,
        loadingHotelIndex: state.loadingHotelIndex + 1
      })
    }
    case ActionTypes.STOP_FETCHING_HOTEL: {
      return Object.assign({}, state, {
        loadingHotel: state.loadingHotelIndex - 1 <= 0 ? false : true,
        loadingHotelIndex: state.loadingHotelIndex - 1 <= 0 ? 0 : state.loadingHotelIndex - 1
      })
    }
    case ActionTypes.START_FETCHING_HOTEL_ROOM: {
      return Object.assign({}, state, {
        loadingHotelRoom: true
      })
    }
    case ActionTypes.STOP_FETCHING_HOTEL_ROOM: {
      return Object.assign({}, state, {
        loadingHotelRoom: false
      })
    }
    case ActionTypes.START_FETCHING_VOUCHER: {
      return Object.assign({}, state, {
        loadingVoucher: true
      })
    }
    case ActionTypes.STOP_FETCHING_VOUCHER: {
      return Object.assign({}, state, {
        loadingVoucher: false
      })
    }
    case ActionTypes.START_FETCHING_CART: {
      return Object.assign({}, state, {
        loadingCart: true
      })
    }
    case ActionTypes.STOP_FETCHING_CART: {
      return Object.assign({}, state, {
        loadingCart: false
      })
    }
    case ActionTypes.START_FETCHING_COUPON: {
      return Object.assign({}, state, {
        loadingCoupon: true
      })
    }
    case ActionTypes.STOP_FETCHING_COUPON: {
      return Object.assign({}, state, {
        loadingCoupon: false
      })
    }
    case ActionTypes.START_FETCHING_ORDER: {
      return Object.assign({}, state, {
        loadingOrder: true
      })
    }
    case ActionTypes.STOP_FETCHING_ORDER: {
      return Object.assign({}, state, {
        loadingOrder: false
      })
    }
    case ActionTypes.START_FETCHING_VINWONDER: {
      return Object.assign({}, state, {
        loadingVinWonder: true
      })
    }
    case ActionTypes.STOP_FETCHING_VINWONDER: {
      return Object.assign({}, state, {
        loadingVinWonder: false
      })
    }
    case ActionTypes.START_FETCHING_TOUR: {
      return Object.assign({}, state, {
        loadingTour: true
      })
    }
    case ActionTypes.STOP_FETCHING_TOUR: {
      return Object.assign({}, state, {
        loadingTour: false
      })
    }
    case ActionTypes.START_FETCHING_TOUR_TICKET: {
      return Object.assign({}, state, {
        loadingTourTicket: true
      })
    }
    case ActionTypes.STOP_FETCHING_TOUR_TICKET: {
      return Object.assign({}, state, {
        loadingTourTicket: false
      })
    }
    case ActionTypes.START_FETCHING_TOUR_LINE: {
      return Object.assign({}, state, {
        loadingTourLine: true
      })
    }
    case ActionTypes.STOP_FETCHING_TOUR_LINE: {
      return Object.assign({}, state, {
        loadingTourLine: false
      })
    }
    case ActionTypes.START_FETCHING_LISTING: {
      return Object.assign({}, state, {
        loadingListing: true
      })
    }
    case ActionTypes.STOP_FETCHING_LISTING: {
      return Object.assign({}, state, {
        loadingListing: false
      })
    }
    case ActionTypes.START_FETCHING_TAG_HOTEL: {
      return Object.assign({}, state, {
        loadingFilter: true
      })
    }
    case ActionTypes.STOP_FETCHING_TAG_HOTEL: {
      return Object.assign({}, state, {
        loadingFilter: false
      })
    }
    case ActionTypes.START_FETCHING_PROMOTION_DATA_FILL:
      return Object.assign({}, state, {
        loadingPromotionAutoFill: true
      })
    case ActionTypes.STOP_FETCHING_PROMOTION_DATA_FILL:
      return Object.assign({}, state, {
        loadingPromotionAutoFill: false
      })
    case ActionTypes.START_FETCHING_HOMESTAY: {
      return Object.assign({}, state, {
        loadingHomestay: true,
        loadingHomestayIndex: state.loadingHomestayIndex + 1
      })
    }
    case ActionTypes.STOP_FETCHING_HOMESTAY: {
      console.log('state', state)
      return Object.assign({}, state, {
        loadingHomestay: state.loadingHomestayIndex - 1 <= 0 ? false : true,
        loadingHomestayIndex: state.loadingHomestayIndex - 1 <= 0 ? 0 : state.loadingHomestayIndex - 1
      })
    }
    case ActionTypes.START_FETCHING_HOMESTAY_ROOM: {
      return Object.assign({}, state, {
        loadingHomestayRoom: true
      })
    }
    case ActionTypes.STOP_FETCHING_HOMESTAY_ROOM: {
      return Object.assign({}, state, {
        loadingHomestayRoom: false
      })
    }
    
    default:
      return state
  }
}

export default FetchingReducer