import { getConfig } from "@config/";
import { getAuthCookies, removeCookiesLogout, setCookiesLogin } from "@utils/auth";
import Axios from "axios";
import moment from "moment";
import { axiosCancelToken } from "./RetryInterceptor";
import Urls from "./Urls";

const {
  publicRuntimeConfig: { API_BASE_AUTH_URL }, // Available both client and server side
} = getConfig();

export let isLoadingRefreshToken = false;
export let refreshTokenSubscribers = [];

export default class AuthorRequestInterceptor {
  axiosInstance;
  //   _userRepository;
  constructor(axiosInstance) {
    // super();
    this.axiosInstance = axiosInstance;
    // this.auth = Cookies.get(Cookies.STORAGE_KEY.AUTH) || { refreshToken: "" };
    // this._userRepository = {
    //   refreshToken: async (rfToken) =>
    //     Axios.post(`${API_BASE_AUTH_URL}/${Urls.REFRESH_TOKEN}`, {
    //       RefreshToken: rfToken,
    //     }),
    // };
  }

  onRefreshed(token) {
    refreshTokenSubscribers.map((cb) => cb(token));
    refreshTokenSubscribers = [];
  }

  subscribeTokenRefresh(cb) {
    refreshTokenSubscribers.push(cb);
  }

  async requestFullfill(config) {
    const cookies = getAuthCookies();
    const isBefore = moment(cookies?.auth?.expires).isBefore(
      moment().subtract(30, "seconds")
    );
    if (isLoadingRefreshToken) {
      const retryOrigReq = new Promise((resolve, reject) => {
        this.subscribeTokenRefresh(async (token) => {
          // replace the expired token and retry
          config.headers["Authorization"] = "Bearer " + token;
          resolve(this.axiosInstance(config));
        });
      });
      axiosCancelToken.cancel('user cancel')
      return config;
    }
    if (!isLoadingRefreshToken && cookies?.auth?.expires && isBefore) {
      try {
        isLoadingRefreshToken = true;
        const refreshResponse = await Axios.post(
          `${API_BASE_AUTH_URL}/${Urls.REFRESH_TOKEN}`,
          {
            RefreshToken: cookies?.auth?.refreshToken,
          }
        );
        // await this._userRepository.refreshToken(
        //   cookies?.auth?.refreshToken
        // );
        // console.log("refreshResponse", refreshResponse);
        setCookiesLogin(refreshResponse.data, true);
        this.onRefreshed(refreshResponse?.data?.accessToken);
        debugger;
      } catch (error) {
        // console.log("error", error);
      } finally {
        isLoadingRefreshToken = false;
      }
    } else {
      return config;
    }
  }
}

function onRefreshed(token) {
  refreshTokenSubscribers.map((cb) => cb(token));
  refreshTokenSubscribers = [];
}

function subscribeTokenRefresh(cb) {
  refreshTokenSubscribers.push(cb);
}
export const requestFullfill = async (config, provider) => {
  // console.log('.url?.includes("logout")', config);
  if (config?.url?.includes("logout")) return config;
  const cookies = getAuthCookies();
  const isBefore = moment(cookies?.auth?.expires).isBefore(
    moment().subtract(30, "seconds")
    );
    if (isLoadingRefreshToken) {
      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh(async (token) => {
          // replace the expired token and retry
          config.headers["Authorization"] = "Bearer " + token;
          resolve(provider(config));
        });
      });
      // console.log('heressss');
      // axiosCancelToken.cancel('user cancel');
    return {...config, method: undefined};
  }
  if (!isLoadingRefreshToken && cookies?.auth?.expires && isBefore) {
    try {
      isLoadingRefreshToken = true;
      const refreshResponse = await Axios.post(
        `${API_BASE_AUTH_URL}/${Urls.REFRESH_TOKEN}`,
        {
          RefreshToken: cookies?.auth?.refreshToken,
        }
      );
      // console.log("refreshResponse", refreshResponse);
      setCookiesLogin(refreshResponse.data, true);
      onRefreshed(refreshResponse?.data?.accessToken);
    } catch (error) {
      removeCookiesLogout();
      window.location.href = '/login-vpt';
      console.log("error", error);
    } finally {
      isLoadingRefreshToken = false;
    }
  } else {
    return config;
  }
};
