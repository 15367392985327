import { Button } from 'antd'
import Link from '@utils/router/link'
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { formatMoney } from '../../helpers/functions'
import { routes } from '../../constants'
import Image from '../Elements/Image'
import { CurrencyNumber } from '../Elements'
import { defaultMessageTicketCart } from '../../constants/enumType'

const ItemPopup = (props) => {
    const {
        thumbImage,
        title = '',
        quantity = 0,
        slugProduct = '',
        urlProduct = '',
        price = 0,
        item={}
    } = props

    
    const typeTicket = ['quantity', 'childQuantity', 'seniorQuantity']

    const renderTicketQuantity = (item) => {
        return <Fragment>
            { typeTicket.map(type => {
                if(item[type] && item[type] > 0) {
                    return <span className="quantity-ticket" key={type}>
                        <FormattedMessage 
                            id={`Cart.Ticket.${type}`}
                            defaultMessage={defaultMessageTicketCart[type]}
                            values={{
                                value: item[type]
                            }} 
                        />
                    </span>
                }
            }) }
        </Fragment>
    }

    const renderTicketTourQuantity = (objectTypes) => {
        return (
            <Fragment>
            { objectTypes.map(ticket => {
                if(ticket && ticket.quantity > 0) {
                    return <span className="quantity-ticket" key={ticket.productVariantId}>
                        {`${ticket.objectType.name} x ${ticket.quantity}`}
                    </span>
                }
            }) }
        </Fragment>
        )
    }

    return (
        <div className="product-box">
            <Image
                className="img-experience img-fluid g-cursor-pointer product-box-image"
                src={thumbImage ? `//${thumbImage.fileUri}` : require('@assets/img/bg/default-image.jpg').default}
            />
            <div className="product-box-content">
                <Link 
                    href={routes[`${urlProduct}_SLUG`]} 
                    as={`${routes[urlProduct]}/${slugProduct}`}>
                    <p className="g-mb-0 g-cursor-pointer title">
                        {title}
                    </p>
                </Link>
                <p className="g-mb-0 quantity">
                    { urlProduct === 'VOUCHER_DETAIL' && (
                        <span>
                            <FormattedMessage 
                                id="Cart.TotalQuantity" 
                                defaultMessage="số lượng: {quantity} vé"
                                values={{
                                    quantity
                                }} 
                            />
                        </span>
                    )
                    }
                    { urlProduct === 'EXPERIENCE_DETAIL' && renderTicketQuantity(item) }
                    { urlProduct === 'TOUR_DETAIL' && renderTicketTourQuantity(item.objectTypes) }
                </p>
                <span className="price">
                    <CurrencyNumber value={price} />
                </span>
            </div>
        </div>
    )
}

export default ItemPopup