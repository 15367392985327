import ApiService from './../api/ApiService'
import Urls from './../api/Urls'
import config from './../config'
import { getVouchers, getLocations } from './VoucherService';
import { getBlog } from './BlogService';
import { getStateprovince, getCollectionTours } from './TourService';
import { getSpecialAndTopSeller } from './ListingService';

import { get } from 'lodash';

export function getHomeData(params = {}) {
  const { isVoucherSpecial = true, isVoucherBestSeller = true, isHotTour = true, blogPageSize = 5 } = params;
  return new Promise((resolve, reject) => {
    return Promise.all([
      // getSpecialAndTopSeller(true),
      getBlog({ pageSize: blogPageSize }),
      getLocations(),
      getStateprovince(),
      getCollectionTours({ IsHotTour: isHotTour }),
      // getSpecialAndTopSeller(false),
    ]).then((responses) => {

      const specialVouchers = responses[0] || [];
      const blog = get(responses[1], 'result') || [];
      const locationOptions = responses[2] || [];
      const tourOptions = responses[3] || [];
      const hotTours = responses[4] || [];
      const bestSellerVouchers = responses[5] || [];

      const result = { specialVouchers, locationOptions, tourOptions, hotTours, blog, bestSellerVouchers }
      return resolve(result);
    }).catch(() => resolve({}))
  })
}
