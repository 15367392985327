import React from 'react'
import { Card, Icon, Skeleton, Spin } from 'antd'
import Document from './Document'
import WhiteSpace from '../WhiteSpace'
import { DEFAULT_WHITE_SPACE } from '../../constants'

const PageLoading = ({ spinning = true, loadingSize = "large" }) => {

  const iconSpin = <Icon type="loading-3-quarters" spin={spinning} />

  return (
    <Document>
      <WhiteSpace marginTop={DEFAULT_WHITE_SPACE} marginBottom={DEFAULT_WHITE_SPACE}>
        <div className='container'>
          <Card>
            <Skeleton active={spinning} />
          </Card>
        </div>
      </WhiteSpace>
    </Document>
  )
}

export default PageLoading