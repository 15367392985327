// import { datadogRum } from "@datadog/browser-rum";
// import { datadogLogs } from "@datadog/browser-logs";
// import { getConfig } from "@config/";

// const {
//   publicRuntimeConfig: {
//     NODE_ENV,
//     AES_KEY,
//     DATADOG_ID,
//     DATADOG_CLIENT,
//     DATADOG_SITE,
//   },
// } = getConfig();

// export const DD_RUM = () => {
//   datadogRum.init({
//     applicationId: '3f4900f9d39881753261d3476e85ae32a75970bb',
//     clientToken: 'pubd0ec8a2ae6961247780581d37d6cb53d',
//     site: 'datadoghq.com',
//     env: NODE_ENV,
//     service: "vpt",
//     version: "1.0.0",
//     sampleRate: 100,
//     trackViewsManually: true,
//     silentMultipleInit: true,
//     replaySampleRate: 100,
//     trackInteractions: true,
//     useCrossSiteSessionCookie: true,
//     trackSessionAcrossSubdomains: true,
//     beforeSend: (event, context) => {
//       console.log('DD_RUM', event, context);
//     },
//   });
// };

// export const DD_LOGS = () => {
//   return datadogLogs.init({
//     clientToken: 'pubd0ec8a2ae6961247780581d37d6cb53d',
//     site: 'datadoghq.com',
//     forwardErrorsToLogs: true,
//     sampleRate: 100,
//     beforeSend: (event, context) => {
//       console.log('DD_LOGS', event, context);
//     },
//   });
// };

// export const DDAddAction = (action = "", data = {}) => {
//   datadogLogs.logger.info(action, data);
//   datadogLogs.logger.error(action, data)
// };

import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { getConfig } from "@config/";
import { axiosProviderVoucher } from "src/actions/api/axiosProvider";

const {
  publicRuntimeConfig: {
    NODE_ENV,
    AES_KEY,
    DATADOG_ID,
    DATADOG_CLIENT,
    DATADOG_SITE,
    DATADOG_SERVICE,
  },
} = getConfig();

export const DD_RUM = () => {
  return datadogRum.init({
    applicationId: DATADOG_ID,
    clientToken: DATADOG_CLIENT,
    site: DATADOG_SITE,
    service: DATADOG_SERVICE,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    beforeSend: (event, context) => {
      // console.log('DD_RUM updated', event, context);
    },
  });
};

export const DD_LOGS = () => {
  return datadogLogs.init({
    clientToken: DATADOG_CLIENT,
    site: DATADOG_SITE,
    service: DATADOG_SERVICE,
    sampleRate: 100,
    trackInteractions: true,
    forwardErrorsToLogs: true,
    useCrossSiteSessionCookie: true,
    trackSessionAcrossSubdomains: true,
    silentMultipleInit: true,
    beforeSend: (event, context) => {
      // console.log("DD_LOGS", event, context);
    },
  });
};

const LogEventLevel = {
  Verbose: 0,
  Debug: 1,
  Information: 2,
  Warning: 3,
  Error: 4,
  Fatal: 5,
};

export const DDAddAction = async (action = "", data = {}) => {
  try {
    const loggging = await axiosProviderVoucher.post(
      "/frontend/logging",
      {
        level: LogEventLevel.Information,
        message: JSON.stringify(data),
        exception: "",
        function: data?.event,
      },
      {
        headers: {
          "Content-Type": "application/json-patch+json",
        },
      }
    );
    // console.log('loggging', loggging);
    // debugger;
  } catch (error) {
    console.log(error);
  }
};
