// import getNextConfig from 'next/config';
import md5 from 'md5';

export const removeEnvPrefixReactApp = (env) => {
    const newEnv = {};
    const prefix = 'REACT_APP_';
    Object.keys(env).forEach(key => {
        let newKey = key
        if (key.startsWith(prefix)) {
            newKey = key.substring(prefix.length, key.length)
        }
        newEnv[newKey] = env[key]
    })
    return newEnv
}

export const getConfig = () => {
    const config = { publicRuntimeConfig: removeEnvPrefixReactApp(process.env) }
    return Object.assign(config, {
        publicRuntimeConfig: config.publicRuntimeConfig || {},
    })
}

const nextConfig = getConfig();

const {
    publicRuntimeConfig: {
        API_URL,
        BASE_URL_VOUCHER_API, // VOUCHER// VOUCHER
        BASE_URL_TOUR_API,
        BASE_URL_CART_API,
        BASE_URL_PAYMENT_API,
        BASE_URL_ORDER_API,
        BASE_URL_BLOG_API,
        FB_APP_ID,
        AUTH0_URL,
        AUTH0_CLIENT_ID,
        AUTH0_REDIRECT_URL,
        AUTH0_RESPONSE_TYPE,
        AUTH0_RETURN_URL,
        AUTH0_LOGOUT,
        API_BASE_AUTH_URL,
        API_BASE_VIN_3S_URL,
        API_BASE_URL,
        API_BASE_HOTEL_URL,
        API_BASE_PROMOTION_URL
    } // Available both client and server side
} = nextConfig;



export default {
    ...(nextConfig.publicRuntimeConfig || {}),
    API_URL: API_URL || 'https://vpt-voucher-api-v2.dev.cloudhms.io',
    BASE_URL_VOUCHER_API: BASE_URL_VOUCHER_API || 'https://vpt-voucher-api-v2.dev.cloudhms.io',
    BASE_URL_TOUR_API: BASE_URL_TOUR_API || 'https://vpt-tour-api-v2.dev.cloudhms.io',
    BASE_URL_CART_API: BASE_URL_CART_API || 'https://vpt-cart-api-v2.dev.cloudhms.io',
    BASE_URL_PAYMENT_API: BASE_URL_PAYMENT_API || 'https://vpt-payment-api-v2.dev.cloudhms.io',
    BASE_URL_ORDER_API: BASE_URL_ORDER_API || 'https://vpt-order-api-v2.dev.cloudhms.io',
    BASE_URL_BLOG_API: BASE_URL_BLOG_API || 'https://vpt-cms-api-v2.dev.cloudhms.io',
    API_BASE_VIN_3S_URL: API_BASE_VIN_3S_URL || 'https://dev-vinpearl.vin3s.vn',
    API_BASE_AUTH_URL: API_BASE_AUTH_URL || 'https://vpt-identity-api-v2.dev.cloudhms.io/api',
    FB_APP_ID: FB_APP_ID || '367658371028090',
    AUTH0_URL: AUTH0_URL || "",
    AUTH0_CLIENT_ID: AUTH0_CLIENT_ID || "",
    AUTH0_RESPONSE_TYPE: AUTH0_RESPONSE_TYPE || "",
    AUTH0_RETURN_URL: AUTH0_RETURN_URL || "",
    AUTH0_LOGOUT: AUTH0_LOGOUT || "",
    AUTH0_REDIRECT_URL: AUTH0_REDIRECT_URL || "https://vpt-frontend-website-v2.dev.cloudhms.io",
    AUTH0_STATE: md5(new Date()),
    API_BASE_URL: API_BASE_URL || 'https://vpt-flight-api-v2.dev.cloudhms.io',
    BASE_HOTEL_URL: API_BASE_HOTEL_URL || 'https://vpt-hotel-api-v2.dev.cloudhms.io',
    BASE_PROMOTION_URL: API_BASE_PROMOTION_URL || 'https://vpt-promotion-api-v2.dev.cloudhms.io'
}