import { cloneDeep, find, get, merge } from 'lodash'
import { enumType, VINWONDER_ENUM, FORMAT_ONLY_DATE, FORMAT_ONLY_MONTH } from '../constants';
import moment from 'moment'

export default {
    getCartItemFromTourTicket(data = {}, bookingDate, type) {
        const productVariantId = data.tourLineItemTicketId ||data.id;
        const bookDate = !bookingDate ? moment() : moment(bookingDate, FORMAT_ONLY_DATE)
        return {
            productVariantId,
            cartItemType: enumType.BOOKING_TYPE.TOUR,
            originalPrice: data.originalPrice,
            salePrice: data.salePrice,
            quantity: data.quantity,
            usingDate: type === 2 ? bookDate.add(7, 'hours').toISOString() : undefined,
            rateCode: data.rateCode,
            rateCodeId: data.rateCodeId,
        }
    },
    mergeTourLineData(lineData = {}, items = [], months = []) {
        const data = cloneDeep(lineData)
        months.forEach(month => {
            data[month.monthString] = merge(data[month.monthString], month, { items: data[month.monthString]?.items || [] })
        })
        items.forEach(item => {
            const monthString = moment(item.departureDate).format(FORMAT_ONLY_MONTH);
            data[monthString] = data[monthString] || {};
            data[monthString].items = data[monthString].items || [];
            data[monthString].items.push(item);
        })
        return data
    },
}