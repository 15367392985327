import { getMomentDate } from '../utils/common';
import moment from 'moment'
export default class FlightCalendarItem {

    constructor(data = {}) {
        this.setData(data);
    }

    setData(data = {}) {
        this.date = data.date ? moment(data.date) : undefined;
        this.listFlight = (data.listFlight || []).map(item => new FlightCalendarItemPrice(item))
    }

}

export class FlightCalendarItemPrice {
    constructor(data = {}) {
        this.setData(data);
    }

    setData(data = {}) {
        this.airlineCode = data.airlineCode || undefined;
        this.airlineName = data.airlineName || undefined;
        this.airlineImage = data.airlineImage || undefined;
        this.netPrice = data.netPrice || 0;
        this.totalPrice = data.totalPrice || undefined;
        this.startPoint = data.startPoint || undefined;
        this.startPointName = data.startPointName || undefined;
        this.endPoint = data.endPoint || undefined;
        this.endPointName = data.endPointName || undefined;
        this.startCityName = data.startCityName || undefined;
        this.endCityName = data.endCityName || undefined;
        this.startDate = data.startDate ? moment(data.startDate) : undefined;
        this.endDate = data.endDate ? moment(data.endDate) : undefined;
    }
}