import { LOCAL_STORAGE_KEY } from 'src/constants';
import actionTypes from '../actionTypes'

const initState = {
  homestaysByLocation: [],
  homestays: [],
  rooms: null,
  isHomestayPage: false,
  phsOrder: {},
}

const homestayReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.START_FETCHING_HOMESTAY:
      return Object.assign({}, state, {
        homestays: []
      })
    case actionTypes.FETCHING_HOMESTAYS_BY_LOCATION_SUCCESS:
      return Object.assign({}, state, {
        homestaysByLocation: action.payload.data
      })
    case actionTypes.FETCHING_HOMESTAY_SUCCESS:
      const promotionCodeForHotel = sessionStorage.getItem(LOCAL_STORAGE_KEY.API_SUPPORT_PROMOTION_CODE_HOMESTAY)
      let newStateHomestays = {};
      if(!promotionCodeForHotel || action.payload.data.params.promotionCode === promotionCodeForHotel){
        newStateHomestays.homestays = action.payload.data.homestays
      }
      return Object.assign({}, state, newStateHomestays)
    case actionTypes.FETCHING_PHS_ORDER_HOMESTAY_SUCCESS:
      return Object.assign({}, state, {
        phsOrderFetching: action.payload.data
      })
    case actionTypes.RESET_FETCHING_HOMESTAY_ROOM:
      return Object.assign({}, state, {
        rooms: null
      })
    case actionTypes.FETCHING_HOMESTAY_ROOM_SUCCESS:
      const promotionCodeForRoom = sessionStorage.getItem(LOCAL_STORAGE_KEY.API_SUPPORT_PROMOTION_CODE_HOMESTAY)
      let newStateRoom = {};
      if(!promotionCodeForRoom || action.payload.data.params.promotionCode === promotionCodeForRoom){
        newStateRoom.rooms = action.payload.data.rooms
      }
      return Object.assign({}, state, newStateRoom)
    case actionTypes.PHS_ORDER_HOMESTAY_SUCCESS:
      return Object.assign({}, state, {
        phsOrder: action.payload.data
      })
    case actionTypes.SET_HOMESTAY_PAGE:
      
    default:
      return state
  }
}

export default homestayReducer
