import { DEFAULT_PAYMENT_TYPE } from '../constants'
import { getAcceptLanguage, getDisplayCurrency } from '../utils/lang'
import { Cookie } from '../utils'

export const VoucherService = require('./VoucherService')
export const TourService = require('./TourService')
export const CartService = require('./CartService')
export const OrderService = require('./OrderService')
export const BlogService = require('./BlogService')
export const PageService = require('./PageService')
export const HomeService = require('./HomeService')
export const ListingService = require('./ListingService')
export const AuthService = require('./AuthService')

export { default as flightServices } from './flight'
export { default as hotelServices } from './hotel'
export { default as homestayServices } from './homestay'
export { default as voucherServices } from './VoucherService'
export { default as listingService } from './ListingService'
export { default as experienceServices } from './ExperienceService'
export { default as cartServices } from './cart'
export { default as bundleServices } from './bundle'

export const initDataReservation = (data, orderItems) => {
  return {
    firstname: data.lastName,
    lastname: data.firstName,
    fullname: `${data.lastName} ${data.firstName}` || '',
    email: data.email || '',
    phoneNumber: `${data.prefixPhone || ''}${data.phone || ''}`,
    note: data.note,
    gatewayId: data.paymentMethod || '',
    paymentType: DEFAULT_PAYMENT_TYPE,
    orderItem: orderItems
  }
}


export const initHeaderAxiosWithLanguage =
  ({
    headers = {},
    context
  }) => {
    const lang = Cookie.get(Cookie.STORAGE_KEY.LANGUAGE)
    const currency = Cookie.get(Cookie.STORAGE_KEY.CURRENCY)
    return {
      ...headers,
      [`accept-language`]: getAcceptLanguage(lang),
      [`x-display-currency`]: getDisplayCurrency(currency)
    }
  }

export const formatFullPhoneNumber = (prefixPhone, phone) => {
  const phoneNumber = phone || '';
  const phoneNumberWithoutPrefix = phoneNumber.startsWith("0") ? phoneNumber.substring(1) : phoneNumber;
  return `${prefixPhone || ''}${phoneNumberWithoutPrefix}`;
}
