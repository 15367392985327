import { FORMAT_ONLY_DATE_REVERSE } from "src/constants";
import moment from "moment";

export class InsiderModel {
  constructor(data) {
    this.gdpr_optin = undefined;
    this.name = undefined;
    this.email = undefined;
    this.email_optin = undefined;
    this.phone_number = undefined;
    this.sms_optin = undefined;
    this.list_id = undefined;
    this.uuid = undefined;
    this.voucher_apply_for = undefined;
    this.remaining_amount = undefined;
    this.remaining_item = undefined;
    this.ma_chien_dich = undefined;
    this.trang_thai_the = undefined;
    this.ngay_het_han_the = undefined;
    this.member_type = undefined;
    this.tong_so_luong_uu_dai = undefined;
    this.ten_uu_dai = undefined;
    this.ngay_bat_dau_uu_dai = undefined;
    this.ngay_ket_thuc_uu_dai = undefined;
    this.Gia_tri_tich_luy = undefined;
    this.birthday = undefined;
    this.gender = undefined;
    this.setData(data);
  }
  setData(userData) {
    this.uuid = userData.vgId;
    this.gdpr_optin = true;
    this.name =
      userData.firstName ||
      userData.lastName ||
      userData.firstName ||
      userData.lastName
        ? `${userData.lastName || userData.lastName || ""}${
            userData.firstName || userData.firstName
              ? " " + (userData.firstName || userData.firstName)
              : ""
          }` || ""
        : userData.fullname || userData.name;
    this.email = userData.emailLogin;
    this.email_optin = true;
    this.phone_number = userData?.phoneLogin;
    this.sms_optin = true;
    this.list_id = userData?.propertyIds || [];
    this.member_type = userData.memberShipCode;
    this.trang_thai_the = userData.memberShipCard?.active
      ? "active"
      : "deactivated";
    this.voucher_apply_for = (userData?.benefit || []).map(
      (el) => el?.benefitType
    );
    this.remaining_amount = (userData?.benefit || []).map(
      (el) => el?.amount || 0
    );
    this.remaining_item = (userData?.benefit || []).map(
      (el) => el?.remaining_amount || 0
    );
    this.ma_chien_dichs = (userData?.benefit || []).map((el) => el.loyaltyCode);
    this.ngay_het_han_the = userData?.memberShipCard?.dueDate || "";
    this.Gia_tri_tich_luy =
      userData?.memberShipCard?.customerPoints[0]?.cumulativePoints;
    this.tong_so_luong_uu_dai = (userData?.benefit || []).length;
    this.ten_uu_dai = (userData?.benefit || []).map((el) => el.campaignName);
    this.ngay_bat_dau_uu_dai = (userData?.benefit || []).map(
      (el) => el?.startDate
    );
    this.ngay_ket_thuc_uu_dai = (userData?.benefit || []).map(
      (el) => el?.expirationDate
    );
    this.birthday = userData?.dob
      ? moment(userData.dob).format(FORMAT_ONLY_DATE_REVERSE)
      : "";
    this.gender = userData?.gender || "";
  }

  exportJson() {
    return {
      uuid: this.uuid,
      gdpr_optin: this.gdpr_optin,
      name: this.name,
      email: this.email,
      email_optin: this.email_optin,
      phone_number: this.phone_number,
      sms_optin: this.sms_optin,
      member_type: this.member_type,
      Gia_tri_tich_luy: this.Gia_tri_tich_luy,
      birthday: this.birthday,
      gender: this.gender,
      list_id: this.list_id,

      custom: {
        ma_chien_dich: this.ma_chien_dich,
        ngay_bat_dau_uu_dai: this.ngay_bat_dau_uu_dai,
        ngay_het_han_the: this.ngay_het_han_the,
        ngay_ket_thuc_uu_dai: this.ngay_ket_thuc_uu_dai,
        trang_thai_the: this.trang_thai_the,
        voucher_apply_for: this.voucher_apply_for,
        remaining_amount: this.remaining_amount,
        remaining_item: this.remaining_item,
        tong_so_luong_uu_dai: this.tong_so_luong_uu_dai,
        ten_uu_dai: this.ten_uu_dai,
      },
    };
  }
}
