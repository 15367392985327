import React from 'react'
import { FormattedMessage } from 'react-intl.macro'

export default {
  required: <FormattedMessage
    id="Validate.Required"
    defaultMessage="Trường bắt buộc nhập"
  />,
  passwordPattern: <FormattedMessage
    id="Validate.Required.passwordPattern"
    defaultMessage='Mật khẩu có độ dài ít nhất 8 ký tự. Chứa các loại ký tự sau: chữ thường (a-z), chữ hoa (A-Z), ít nhất một số (0-9) và một ký tự đặc biệt'
  />,
resetPasswordVPTPattern: <span>
    *Mật khẩu có độ dài ít nhất 8 ký tự. Chứa các loại ký tự sau: chữ thường (a-z), chữ hoa (A-Z), ít nhất một số (0-9) và một ký tự đặc biệt <br />
    *Password must contain at least 8 characters, including the following character types: lowercase letter (a-z), UPPERCASE letter (A-Z), at least one number (0-9) and one special character
  </span>,
  resetPasswordVPTMismatched: <span>
    * Mật khẩu nhập lại không trùng khớp
    <br />* Password doesn’t match
  </span>,
  passwordMismatched: <FormattedMessage
    id="Validate.Required.passwordMismatched"
    defaultMessage='Mật khẩu không khớp'
  />,
  retypePassword: <FormattedMessage
    id="Authentication.Popup.Change.Password.Error"
    defaultMessage='*Mật khẩu không trùng khớp. vui lòng nhập lại mật khẩu.'
  />,
  phoneNumberPattern: <FormattedMessage
    id="Validate.Required.phoneNumberPattern"
    defaultMessage='Vui lòng nhập đúng định dạng'
  />,
  email: <FormattedMessage
    id="Validate.Required.email"
    defaultMessage='Định dạng email không hợp lệ'
  />,
  emailOrPhone: <FormattedMessage
    id="Validate.Required.Email.Or.Phone"
    defaultMessage='Email / Số điện thoại sai định dạng'
  />,
  requiredEmail_vpt: <div>
    * Vui lòng nhập email <br />
    * Please enter your email address.
  </div>,
  requiredEmailOrPhone: <FormattedMessage
    id="Validate.Required.EmailOrPhone"
    defaultMessage='Vui lòng nhập email / số điện thoại'
  />,
  requiredPasswordVPT: <FormattedMessage
    id="Validate.Required.Password"
    defaultMessage='Vui lòng nhập mật khẩu'
  />,
  requiredRePasswordVPT: <FormattedMessage
    id="Validate.Required.RePassword"
    defaultMessage='Vui lòng nhập lại mật khẩu'
  />,
  requiredFirstName: <FormattedMessage
    id="Validate.Required.FirstName"
    defaultMessage='Vui lòng nhập tên đệm và tên'
  />,
  requiredLastName: <FormattedMessage
    id="Validate.Required.LastName"
    defaultMessage='Vui lòng nhập họ'
  />,
  passwordNotMatch: <FormattedMessage
    id="Validate.PasswordNotMatch"
    defaultMessage='Mật khẩu nhập lại không trùng khớp'
  />,
  passwordContain: <FormattedMessage
    id="Validate.PasswordContain"
    defaultMessage='Mật khẩu có độ dài ít nhất 8 ký tự. Chứa các loại ký tự sau: chữ thường (a-z), chữ hoa (A-Z), ít nhất một số (0-9) và một ký tự đặc biệt'
  />,
  wrongUsernameOrPass: <FormattedMessage
    id="Validate.WrongUsernameOrPass"
    defaultMessage='Sai tên đăng nhập hoặc mật khẩu'
  />,
  email_vpt: <div>
    <span>* Email sai định dạng</span> <br />
    <span>* Please enter a valid email.</span>
  </div>,
  number: <FormattedMessage
    id="Validate.Required.number"
    defaultMessage='Định dạng số không hợp lệ'
  />,
  validAge: <FormattedMessage
    id="Validate.Required.validAge"
    defaultMessage='Ngày sinh không hợp lệ'
  />,
  adultQuantity: <FormattedMessage
    id="Validate.Required.adultQuantity"
    defaultMessage='Số lượng hành khách là người lớn không hợp lệ'
  />,
  childQuantity: <FormattedMessage
    id="Validate.Required.childQuantity"
    defaultMessage='Số lượng hành khách là trẻ em không hợp lệ'
  />,
  infantQuantity: <FormattedMessage
    id="Validate.Required.infantQuantity"
    defaultMessage='Số lượng hành khách là em bé không hợp lệ'
  />,
  endDate: <FormattedMessage
    id="Validate.Required.endDate"
    defaultMessage='Ngày về không thể sớm hơn ngày đi'
  />,
  endPoint: <FormattedMessage
    id="Validate.Required.endPoint"
    defaultMessage='Điểm xuất phát và điểm đến không được trùng nhau'
  />,
  length: (fieldName, length) => <FormattedMessage
    id="Validate.Required.length"
    defaultMessage=' phải có {length} ký tự'
    values={{
      fieldName: fieldName,
      length: length
    }}
  />,
  startWithCharacter: (
    fieldName,
    character) => <FormattedMessage
      id="Validate.Required.startWithCharacter"
      defaultMessage=' không được bắt đầu bằng ký tự {character}'
      values={{
        fieldName: fieldName,
        character: character
      }}
    />,
  min: (fieldName, min) => <FormattedMessage
    id="Validate.Required.min"
    defaultMessage=' phải có ít nhất {min} ký tự'
    values={{
      fieldName: fieldName,
      min: min
    }}
  />,
  max: (fieldName, max) => <FormattedMessage
    id="Validate.Required.max"
    defaultMessage=' chỉ chứa tối đa {max} ký tự'
    values={{
      fieldName: fieldName,
      max: max
    }}
  />,
  minNumber: (fieldName, min) => <FormattedMessage
    id="Validate.Required.minNumber"
    defaultMessage='Phone number phải lớn hơn hoặc bằng {min}'
    values={{
      fieldName: fieldName,
      min: min
    }}
  />,
  maxNumber: (fieldName, max) => <FormattedMessage
    id="Validate.Required.maxNumber"
    defaultMessage='Phone number phải nhỏ hơn hoặc bằng {max}'
    values={{
      fieldName: fieldName,
      max: max
    }}
  />,
  agreement: <FormattedMessage
    id="Validate.Required.YouMustChooseToAgree"
    defaultMessage="Bạn phải chọn đồng ý"
  />,
  vinWonderBookingDateQuarantineCovid19: <FormattedMessage
    id="Experience.Detail.BookingDate.QuarantineCovid19"
    defaultMessage="VinWonders đang được đóng cửa theo Quy định của Chính phủ, mọi yêu cầu đặt từ 01/4/2020 tới 15/4/2020 đều được hủy bỏ. Xin quý khách vui lòng không đặt vé trong giai đoạn này!"
  />
}