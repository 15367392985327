import { find, get } from 'lodash'
import { enumType, VINWONDER_ENUM, FORMAT_ONLY_DATE } from '../constants';
import moment from 'moment'

export default {
    getCartItemFromVinWonderNewTicket(ticket={}, usingDate = undefined, siteCode = undefined) {
        const ticketItems = (ticket.ticketItems || [])
        const adult = ticket.objectType?.code === "NL";
        const child = ticket.objectType?.code === "TE";
        const senior = ticket.objectType?.code === "NCT";
        return {
            id: ticket.id,
            cartItemType: enumType.BOOKING_TYPE.VINWONDER_NEW,
            adultQuantity: adult && ticket.quantity ? ticket.quantity : 0,
            childOriginalPrice: child && ticket.originalPrice,
            childSalePrice: child && ticket.salePrice,
            childQuantity: child && ticket.quantity,
            seniorOriginalPrice: senior && ticket.originalPrice,
            seniorSalePrice: senior && ticket.salePrice,
            seniorQuantity: senior && ticket.quantity,
            childTicketItemId: child && ticket.id,
            seniorTicketItemId: senior && ticket.id,
            usingDate: moment(usingDate, FORMAT_ONLY_DATE).utcOffset(0, true).format('YYYY/MM/DD'),
            siteCode: siteCode,
            sessionId: ticket.sessionId,
            zonePickupId: ticket.zonePickupId,
            zoneReleaseId: ticket.zoneReleaseId,
            vinWonderType: 1,
            // 4 field quan trong
            quantity: ticket.quantity,
            salePrice: ticket.salePrice,
            originalPrice: ticket.originalPrice,
            ticketItemId: ticket.ticketItemId,
            tourId: ticket.tourId
        }
    },
    getTotalQuantityTicketItems(items){

    },
    checkVinWonderOriginalPrice(originalPrice, salePrice){
        if(salePrice && originalPrice && originalPrice <= salePrice){
            return 0;
        }
        return originalPrice;
    },
    formatFullTime(value) {
        return value < 10 ? `0${value}` : value
    },
    checkBlackOutDate(currentDate, blackOut = {}) {
        if (blackOut.startDate && blackOut.endDate) {
            return currentDate.isBetween(blackOut.startDate, blackOut.endDate, 'dates', '[]'); // included start date and end date also
        }
        return false;
    }
}