import React from 'react'
//lib
import clsx from 'clsx'
import { getIn } from 'formik'
import { get } from 'lodash'
// constant
import { enumType } from '../constants'
// extension
import { objectExtensions } from '../extensions'

export { default as flightUtils } from './flight'
export { default as comboUtils } from './combo'
export { default as hotelUtils } from './hotel'
export { default as experienceUtils } from './experience'
export { default as cartUtils } from './cart'
export { default as tourUtils } from './tour'
export { default as modalUtils } from './modal'
export { default as gtmUtils } from './gtm'
export { default as currencyUtils } from './currency'
export * as insUtils from './ins'
export { default as vinWonderUtils } from './vinWonder'


import * as COMMON from './common'
import { getAuthCookies } from "./auth";
import { mappingMembershipPromotion } from "../constants/enumType";
export const commonUtils = COMMON;

export const LocalStorage = require('./localStorage');
export const SessionStorage = require('./sessionStorage');
export const Cookie = require('./cookie');

export default {
  LocalStorage,
  /**
   * get list passenger
   * @param adult
   * @param child
   * @param infant
   * @param priceAdult
   * @param priceChild
   * @param priceInfant
   * @returns {[]}
   */
  getListPassenger(adult, child, infant, priceAdult, priceChild, priceInfant) {
    let listPassenger = []
    if (adult > 0) {
      listPassenger.push({
        type: objectExtensions.getLabelFromValue(enumType.passenger.Adult, enumType.passengerType),
        quantity: adult,
        price: priceAdult
      })
    }

    if (child > 0) {
      listPassenger.push({
        type: objectExtensions.getLabelFromValue(enumType.passenger.Children, enumType.passengerType),
        quantity: child,
        price: priceChild
      })
    }

    if (infant > 0) {
      listPassenger.push({
        type: objectExtensions.getLabelFromValue(enumType.passenger.Infant, enumType.passengerType),
        quantity: infant,
        price: priceInfant
      })
    }

    return listPassenger
  },

  checkFieldError(fieldName, errors, touched) {
    return !!(getIn(errors, fieldName) && getIn(touched, fieldName))
  },

  specialcharecter(strValue, errors) {
    let iChars = "`@#$%^&*()+=-[]\\\'/{}|\"<>?~_";   
    for (var i = 0; i < strValue.length; i++){      
      if (iChars.indexOf(strValue.charAt(i)) != -1){    
        return false; 
      } 
    }
    return true;
  },

  getFormFieldClass(fieldName, errors, touched) {
    return clsx({
      'vpt-color-v2 vpt-payment-form__item g-mb-0': true,
      'has-error': this.checkFieldError(fieldName, errors, touched)
    })
  },

  // Get phone number
  getPhoneNumberFromPhoneNumberField(fieldPhoneNumber) {
    return fieldPhoneNumber.replace(/(\+84|0*)/, '')
  },

  isEmpty(value) {
    return value === undefined || value === null || value === '';
  },

  scrollToId(elmId) {
    const elmnt = document.getElementById(elmId)
    if (elmnt) elmnt.scrollIntoView();
  },

  getBoundingClientRectById(elmId) {
    if (!document) return {}
    const elem = document.getElementById(elmId)
    if (!elem) return {}
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top = box.top + scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left), width: box.width, height: box.height };
  },

  getMappingMemberShip(module) {
    if (module) {
      const moduleKey = module.toUpperCase();

      const defaultCodeKey = 'LoggedIn';

      const authCookie = getAuthCookies();
      const authUser = getAuthCookies().user;
      const memberShipCode = get(authCookie, 'user.memberShipCode');
      const memberShipCodeKey = memberShipCode ? memberShipCode.toUpperCase() : authUser ? defaultCodeKey : undefined;
      const defaultPromoCode = authUser ? mappingMembershipPromotion[moduleKey][defaultCodeKey] || undefined : undefined;

      // console.log(mappingMembershipPromotion[moduleKey][memberShipCodeKey])

      return memberShipCodeKey ? mappingMembershipPromotion[moduleKey][memberShipCodeKey] : defaultPromoCode
    } else {
      return undefined
    }
  },

  getHotlineFromSiteInfo(siteInfo={}){
    return `${siteInfo.hotlineNumber || ''}${siteInfo.hotlineBranch ? ` (${siteInfo.hotlineBranch})` : ''}`
  },

  generateStringId(length) {
    let result           = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },

  paymentMethodSwapper(methods) {
    if (methods && methods.length >= 2) {
      let tempMethods = [...methods]
      if (tempMethods[1] === enumType.paymentMethod.International) {
        [tempMethods[0], tempMethods[1]] = [tempMethods[1], tempMethods[0]]
        return tempMethods
      }
    }
    return methods
  }
}
