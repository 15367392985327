import React, { Fragment } from 'react'
// import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
// hook
// constant
import { nav } from '../../constants'
// component
import Logo from './Logo'
import Cart from './Cart'
import CurrencyUnit from './CurrencyUnit'
import { topHeaderMenu, topHeaderRightMenu } from '../../constants/nav'
import Vin3SLogo from './Vin3SLogo'

const TopHeader = (props) => {
  const {
    showCart,
    showLogin,
    showLanguage
  } = props
  // return (
  //   <nav className='g-py-5--md order-2 order-lg-1'>
  //     <div className='container'>
  //       <div
  //         className='g-py-10--md d-flex align-items-center justify-content-between'>
  //         <Logo/>
  //         <div className='d-flex align-items-center'>
  //           {
  //             showCart
  //               ? <Cart/>
  //               : null
  //           }
  //           {
  //             showLanguage
  //               ?
  //               <div className="d-flex align-items-center">
  //                 <Language/>
  //                 <CurrencyUnit/>
  //               </div>
  //               : null
  //           }
  //           {
  //             showLogin
  //               ? <DynamicLoginContainer
  //                 onLogout={logoutCallback()}
  //               />
  //               : null
  //           }
  //         </div>
  //       </div>
  //     </div>
  //   </nav>
  // )
  return (
    <div className="header-top">
      <div className="container">
        <div className="row-flex align-items-center h-100">
          <Vin3SLogo />
          <div className='vertical-divider'></div>
          <div className="nav inline-nav h-100">
            <ul className='d-flex align-items-center h-100 g-pl-0'>
              {topHeaderMenu.map((item, index) => (
                <li key={index} className="nav-item">
                  <a href={item.link} title={typeof item.title === 'string' ? item.title : ''}>{item.title}</a>
                </li>
              ))}
            </ul>
          </div>

          <div className="nav inline-nav top-right-nav align-items-center h-100">
            <ul className='d-flex align-items-center h-100'>
              {topHeaderRightMenu.map(item => (
                <li className="nav-item">
                  <a href={item.link} title={typeof item.title === 'string' ? item.title : ''}>{item.title}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

TopHeader.propTypes = {
  showCart: PropTypes.bool,
  showLogin: PropTypes.bool,
  showLanguage: PropTypes.bool
}

export default TopHeader