import React from 'react'
import { getConfig } from '@config'
import InsertScriptTag from '../HeadTag/InsertScriptTag'

const {
  publicRuntimeConfig: {
    NODE_ENV
  } // Available both client and server side
} = getConfig()

const DEV = false //NODE_ENV === 'development'

const AT = ({ src, campaignId, isReoccur, isLastclick }) => {
  return (
    DEV
      ? null
      : <InsertScriptTag id="at-tag">
        {/* <script src={src} /> */}
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
                        AT.init({"campaign_id":${campaignId}, "is_reoccur": ${isReoccur},"is_lastclick": ${isLastclick}});
                        AT.track();
                        `
          }}
        />
      </InsertScriptTag>
  )
}
export default AT