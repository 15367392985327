import React from 'react'
// lib
import clsx from 'clsx'
import PropTypes from 'prop-types'
// css
import 'react-image-gallery/styles/scss/image-gallery.scss'
// component
import ImageGallery from 'react-image-gallery'
import { formatImageSrc } from '../../utils/common'

const getValidImageUrl = (imageUrl) => {
  if (!imageUrl) {
    return null
  }
  if (!imageUrl.startsWith('https') && !imageUrl.startsWith('http')) {
    return `//${imageUrl}`
  }
  return imageUrl
}

const Gallery = (props) => {
  const {
    images,
    urlField,
    thumbnailField,
    originalClass,
    thumbnailClass,
    showThumbnails = true,
    lazyLoad = false,
    preIcon,
    nextIcon,
  } = props

  const gallery = images && images.length > 0
    ? images.map(image => ({
      ...image,
      original: formatImageSrc(image[urlField]),
      thumbnail: formatImageSrc(image[thumbnailField]),
      thumbnailClass: clsx({
        'vpt-gallery__thumbnail': true,
        [`${thumbnailClass}`]: !!thumbnailClass
      }),
      originalClass: clsx({
        [`${originalClass}`]: !!originalClass
      })
    }))
    : []

  return (
    <ImageGallery
      items={gallery}
      lazyLoad={lazyLoad}
      showNav={true}
      showBullets={false}
      showPlayButton={false}
      showFullscreenButton={false}
      showThumbnails={showThumbnails}
      renderLeftNav={(onClick, disabled) => (
        <button
          className='image-gallery-left-nav vpt-gallery__left-nav'
          disabled={disabled}
          onClick={onClick}
        >
          <img
            alt='arrow-left'
            className='g-height-16'
            src={preIcon || require('@assets/include/svg/left.svg').default}
          />
        </button>
      )}
      renderRightNav={(onClick, disabled) => (
        <button
          className='image-gallery-right-nav vpt-gallery__right-nav'
          disabled={disabled}
          onClick={onClick}
        >
          <img
            alt='arrow-left'
            className='g-height-16'
            src={nextIcon || require('@assets/include/svg/right.svg').default}
          />
        </button>
      )}
    />
  )
}

Gallery.propTypes = {
  images: PropTypes.array,
  urlField: PropTypes.string,
  thumbnailField: PropTypes.string,
  originalClass: PropTypes.string,
  thumbnailClass: PropTypes.string,
  showThumbnails: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  preIcon: PropTypes.string,
  nextIcon: PropTypes.string
}
export default Gallery