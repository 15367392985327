import React from 'react'
import { getConfig } from '@config'

const {
  publicRuntimeConfig: {
    NODE_ENV
  } // Available both client and server side
} = getConfig()

const DEV = NODE_ENV === 'development'

// console.log('dev............', DEV)

const Gtm = ({ code }) => {
  return (
    DEV
      ? null
      : <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${code}`}
          height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}>
        </iframe>
      </noscript>
  )
}

export default Gtm
