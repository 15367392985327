import React, { Fragment, useEffect, useState } from "react"
import { currencyUtils } from "@utils/index";
import { useGlobalStore } from "src/hooks/useGlobal";
import { selectCurrencyRate } from "src/reselect/currencySelector";
import { useSelector } from "react-redux";
import { FormattedNumber } from "react-intl";
import clsx from "clsx";
import { formatMoney } from "src/helpers/functions";

const CurrencyFormatNumber = (props) => {
    const {
        totalPrice,
        unitCss,
        className,
        isAbleVND,
        cssTilde,
        hideIcon = false
    } = props
    const { state } = useGlobalStore();
    const currencyRate = useSelector(selectCurrencyRate())
    const [currencySymbol, setCurrencySymbol] = useState("");
    const [unitPlacement, setUnitPlacement] = useState("right");
    

    const convertCurrency = (totalPrice) => {
        if(isAbleVND && state.currencyOnl === "VND") {
            return totalPrice;
        }
        return currencyUtils.convertCurrencyFollow(totalPrice, state.currencyOnl, currencyRate)
    }

    useEffect(() => {
        if (state.currencyOnl === "USD") {
            setCurrencySymbol("$");
            return setUnitPlacement("left");
        } else if (state.currencyOnl === "VND") {
            setCurrencySymbol("đ");
        } else {
            setCurrencySymbol(state.currencyOnl);
        }
        setUnitPlacement("right");
    }, [state])

    
    return (
        <Fragment>
            {
                typeof convertCurrency(totalPrice) === 'number' &&
                <Fragment>
                    <span className={className}>
                        {
                            (state.currencyOnl !== "VND" && !hideIcon) &&  <span className={`g-font-size-16 font-custom-member-rate ${cssTilde}`}>~</span>
                        }
                        {
                            unitPlacement === "left" && (
                                <span className={clsx(!hideIcon && "g-ml-5", unitCss || className)}>
                                    {`${currencySymbol}`}
                                </span>
                            )
                        }
                        {/* {<FormattedNumber value={convertCurrency(totalPrice)} />} */}
                        {formatMoney(convertCurrency(totalPrice))}
                        {
                            unitPlacement === "right" && (
                                <span className={clsx("g-ml-5", unitCss || className)}>
                                    {`${currencySymbol}`}
                                </span>
                            )
                        }
                    </span>
                </Fragment>
            }
        </Fragment>
    )

}

export default CurrencyFormatNumber
