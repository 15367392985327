import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Tooltip } from 'antd'
import clsx from 'clsx'

const CheckboxGroup = ({ listCheckboxes, checkedList, showCheckAll, onChange, checkboxClass, renderItemSuffix, showTooltip = false }) => {
  const [currentCheckList, setCurrentCheckList] = useState(checkedList || [])
  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(true)

  /**
   * update state when props change
   */
  useEffect(
    () => {
      handleResetState(checkedList)
    },
    [checkedList]
  )

  /**
   * reset all state
   * @param checkedValues
   */
  const handleResetState = (checkedValues) => {

    const indeterminate = !!checkedValues.length && checkedValues.length < listCheckboxValues.length
    const checkAll = checkedValues.length === listCheckboxValues.length

    setIndeterminate(indeterminate)
    setCheckAll(checkAll)
    setCurrentCheckList(checkedValues)
  }

  /**
   * handle change checked status of each checkbox
   * @param checkedValues
   */
  const handleChangeCheck = (checkedValues) => {
    handleResetState(checkedValues)
    if (onChange) {
      onChange(checkedValues)
    }
  }

  /**
   * handle change checked status of check box all
   * @param value
   */
  const handleChangeCheckAll = (value) => {
    const isChecked = value.target.checked
    const checkedList = isChecked ? listCheckboxValues : []
    handleResetState(checkedList)

    if (onChange) {
      onChange(checkedList)
    }
  }

  const listCheckboxValues = listCheckboxes && listCheckboxes.length > 0
    ? listCheckboxes.map(item => item.value)
    : []

  const customCheckboxClass = clsx({
    'd-block g-mb-10 g-ml-0 vpt-checkbox': true,
    [`${checkboxClass}`]: !!checkboxClass
  })

  return (
    <div>
      {
        showCheckAll &&
        (
          <Checkbox
            onChange={handleChangeCheckAll}
            indeterminate={indeterminate}
            checked={checkAll}
            className={customCheckboxClass}
          >
            Select all
          </Checkbox>
        )
      }
      <Checkbox.Group
        onChange={handleChangeCheck}
        value={currentCheckList}
        className='w-100'
      >
        {listCheckboxes.map((item, index) => (
          <Checkbox
            key={index}
            value={item.value}
            className={customCheckboxClass}
          >
            <Tooltip title={showTooltip ? item.label : null}>
              {item.label}
              {renderItemSuffix && renderItemSuffix(item, index)}
            </Tooltip>
          </Checkbox>
        ))}
      </Checkbox.Group>
    </div>
  )
}

CheckboxGroup.propTypes = {
  listCheckboxes: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired])
  })),
  checkedList: PropTypes.arrayOf(PropTypes.string),
  showCheckAll: PropTypes.bool,
  onChange: PropTypes.func,
  checkboxClass: PropTypes.string
}

export default CheckboxGroup