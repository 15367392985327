import { createSelector } from 'reselect'

const getNotice = state => state.cart.notice
const getCount = state => state.cart.count
const getCurrentCart = state => state.cart.cart
const getCart = state => state.cart.currentCart
const getCoupon = state => state.cart.coupon
export const selectCartNotice = () =>
  createSelector(
    getNotice,
    notice => notice
  )

export const selectCartCount = () =>
  createSelector(
    getCount,
    count => count
  )

export const selectCurrentCart = () =>
  createSelector(
    getCurrentCart,
    cart => cart
  )

export const selectVoucherCart = () =>
  createSelector(
    getCurrentCart,
    cart => cart && cart.vouchers
      ? cart.vouchers
      : []
  )

export const selectTourCart = () =>
  createSelector(
    getCurrentCart,
    cart => cart && cart.tours
      ? cart.tours
      : []
  )

export const selectVinWonderCart = () =>
  createSelector(
    getCurrentCart,
    cart => cart && cart.vinWonders
      ? cart.vinWonders
      : []
  )

export const selectCart = () =>
  createSelector(
    getCart,
    cart => cart
  )

export const selectCoupon = () =>
  createSelector(
    getCoupon,
    coupon => coupon
  )

export const selectCartProducts = () =>
  createSelector(
    getCurrentCart,
    cart => cart
      ? (cart.vouchers || []).concat((cart.vinWonders || []).concat(cart.tours || []))
      : []
  )
