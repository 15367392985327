import React from 'react'
import Link from '@utils/router/link'

const Logo = () => {
  return (
    <Link href={'/'}>
      <a className="navbar-brand">
        <img className={'logo'} src={require('@assets/include/svg/logo.svg').default} alt="vinpearl-travel-logo"/>
      </a>
    </Link>
  )
}

export default Logo
