import actionTypes from '../actionTypes'

const initState = {
  isFetchingCreditPointCalculation: false,
  IsUseCreditPoint: false,
  creditPointData: undefined
}

const creditReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.START_FETCHING_CREDIT_POINT_CALCULATION:
      return Object.assign({}, state, {
        isFetchingCreditPointCalculation: true,
      })
    case actionTypes.STOP_FETCHING_CREDIT_POINT_CALCULATION:
      return Object.assign({}, state, {
        isFetchingCreditPointCalculation: false,
      })
    case actionTypes.RESET_CREDIT_POINT_CALCULATION_DATA:
      return Object.assign({}, state, {
        isFetchingCreditPointCalculation: false,
        IsUseCreditPoint: false,
        creditPointData: undefined
      })
    case actionTypes.USE_CREDIT_POINT:
      return Object.assign({}, state, {
        IsUseCreditPoint: true,
      })
    case actionTypes.UNUSE_CREDIT_POINT:
      return Object.assign({}, state, {
        IsUseCreditPoint: false,
        isFetchingCreditPointCalculation: false
      })
    case actionTypes.SET_CREDIT_POINT_CALCULATION_DATA:
      return Object.assign({}, state, {
        creditPointData: {
          ...state.creditPointData,
          ...action.payload.data
        },
        isFetchingCreditPointCalculation: false
      })
    default:
      return state
  }
}

export default creditReducer